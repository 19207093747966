import React, { useContext, useState } from "react";
import {
  AutocompleteInput,
  Datagrid,
  DateField,
  ReferenceField,
  ReferenceInput,
  Show,
  ShowButton,
  SimpleShowLayout,
  TextField,
} from "react-admin";
import { JsonField } from "react-admin-json-view";
import type {
  ListProps,
  ShowProps,
} from "react-admin";
import { DateTimeInput as MaterialDateTimeInput } from "./MaterialDatePicker";
import { MeetingsStateContext } from "../App";
import { CustomList, ListPagination } from "./CustomList";


const defaultQuery = "listMeetingRoomEventAuditLogs";

const filters = [
  <ReferenceInput
    source="profileId"
    reference="profiles"
    label="User"
    filterToQuery={(searchText) => ({
      listProfiles: { fullName: searchText },
    })}
    perPage={700}
    alwaysOn
    resettable
  >
    <AutocompleteInput optionText="fullName" />
  </ReferenceInput>,
  <MaterialDateTimeInput
    label="Begin Date Range"
    source="date"
    options={{ format: 'MM/dd/yyyy, hh:mm a', ampm: true, clearable: true }}
  />,
  <MaterialDateTimeInput
    label="End Date Range"
    source="endDate"
    options={{ format: 'MM/dd/yyyy, hh:mm a', ampm: true, clearable: true }}
  />,
];

export const MeetingRoomEventAuditLogList = (props: ListProps) => {
  const [query, setQuery] = useState(defaultQuery);
  const meetingsState = useContext(MeetingsStateContext);
  const {
    currentProfile,
  } = meetingsState;

  return (
    <CustomList
      {...props}
      filters={filters}
      filterDefaultValues={{ profileId: currentProfile?.id }}
      bulkActionButtons={false}
      pagination={<ListPagination />}
    >
      <Datagrid>
        <DateField source="date" showTime={true} sortable={true} />
        <TextField source="author" sortable={false} />
        <TextField source="meetingRoom.name" sortable={false} label="Meeting Room" />
        <TextField source="resource" sortable={false} />
        <TextField source="action" sortable={false} />
        <ShowButton />
      </Datagrid>
    </CustomList>
  );
};

export const MeetingRoomEventAuditLogShow = (props: ShowProps) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" label="ID" />
      <DateField source="date" showTime={true} />
      <TextField source="author" />
      <TextField source="resource" />
      <TextField source="action" />
      <JsonField
        source="payload"
        addLabel={true}
        jsonString={true}
        reactJsonOptions={{
          theme: "shapeshifter",
          name: null,
          collapsed: true,
          enableClipboard: false,
          displayDataTypes: false,
        }}
      />
      <TextField source="ipAddress" />
      <ReferenceField
        source="meetingRoomId"
        reference="meetingRooms"
        label="Room"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        source="profileId"
        reference="profiles"
        label="User"
        link="show"
      >
        <TextField source="fullName" />
      </ReferenceField>
      <DateField source="createdAt" showTime={true} />
      <DateField source="updatedAt" showTime={true} />
    </SimpleShowLayout>
  </Show>
);
