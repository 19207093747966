import React from "react";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import type { TypographyProps } from "@material-ui/core/Typography";
import { SITE_TITLE, SITE_URL } from "../constants";

export function Copyright(props: TypographyProps) {
  return (
    <Typography {...props} >
      {"Copyright © "}
      <Link color="inherit" href={SITE_URL}>
        {SITE_TITLE}
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
