import {
  API,
  GRAPHQL_AUTH_MODE
} from "@aws-amplify/api";
import type { GraphQLResult } from "@aws-amplify/api";
import { Auth } from "@aws-amplify/auth";
import { Logger } from "@aws-amplify/core";
import type {
  CreateParams,
  CreateResult,
  DeleteManyParams,
  DeleteManyResult,
  DeleteParams,
  DeleteResult,
  GetListParams,
  GetListResult,
  GetManyParams,
  GetManyReferenceParams,
  GetManyReferenceResult,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  UpdateManyParams,
  UpdateManyResult,
  UpdateParams,
  UpdateResult,
} from "ra-core";
import type {
  // Event,
  RecordListEvent,
  // UseLockOptions,
  SubscriptionCallback,
  // UseHasLock,
  // UseLock,
  // LocksDataProvider,
  // useLock,
} from "@react-admin/ra-realtime";
import { DateTime } from "luxon";
import { AdminQueries } from "./AdminQueries";
import { Pagination } from "./Pagination";
import type {
  Meeting,
  Organization,
  Product,
  Profile,
  ProfileDevice,
  Team,
  TeamMeetingRelationship,
  TeamMemberRelationship,
  Transcription,
  TranscriptionJob,
} from "../API";
import {
  MeetingStatus,
  ModelSortDirection,
} from "../API";
import { Filter } from "./Filter";
import { AWS_TIMESTAMP_AS_LUXON_FORMAT } from "../constants";
import { searchOrganizations, searchProducts, searchProfiles, searchTeams } from "../lib/helpers";


const LOCKS_RESOURCE_NAME = "locks";
const logger = new Logger("DataProvider");
const emailRegex = /\w+@\w+\.\w+/g;

export interface Operations {
  queries: Record<string, string>;
  mutations: Record<string, string>;
}

export interface DataProviderOptions {
  authMode?: GRAPHQL_AUTH_MODE;
  storageBucket?: string;
  storageRegion?: string;
  enableAdminQueries?: boolean;
}

const defaultOptions = {
  authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
  enableAdminQueries: false,
};

export interface AmplifyGetListParams extends GetListParams {
  gqlFilter?: any,
}

export interface AmplifyGetManyReferenceParams extends GetManyReferenceParams {
  gqlFilter?: any,
}

export class DataProvider {

  public locksResourceName: string = LOCKS_RESOURCE_NAME;

  public queries: Record<string, string>;
  public mutations: Record<string, string>;
  public subscriptions: any[];

  public authMode: GRAPHQL_AUTH_MODE;
  public enableAdminQueries: boolean;

  static storageBucket?: string;
  static storageRegion?: string;

  public constructor(operations: Operations, options?: DataProviderOptions) {
    this.queries = operations.queries;
    this.mutations = operations.mutations;
    this.subscriptions = [];

    this.authMode = options?.authMode || defaultOptions.authMode;
    this.enableAdminQueries =
      options?.enableAdminQueries || defaultOptions.enableAdminQueries;

    DataProvider.storageBucket = options?.storageBucket;
    DataProvider.storageRegion = options?.storageRegion;
  }

  public mergeLists = (
    list1: GetListResult,
    list2: GetListResult,
  ): GetListResult => {
    return {
      data: list1.data.concat(list2.data),
      total: list1.total + list2.total,
    };
  }

  public getList = async <RecordType>(
    resource: string,
    params: AmplifyGetListParams
    // @ts-ignore
  ): Promise<GetListResult<RecordType>> => {
    if (this.enableAdminQueries && resource === "cognitoUsers") {
      return AdminQueries.listCognitoUsers(params);
    }

    if (this.enableAdminQueries && resource === "cognitoGroups") {
      return AdminQueries.listCognitoGroups(params);
    }

    // const { filter, sort, gqlFilter } = params;
    const { gqlFilter } = params;
    let { filter, sort } = params;
    logger.info("^ params", params);
    logger.info("^ filter", filter);
    logger.info("^ sort", sort);
    logger.info("^ gqlFilter", gqlFilter);

    let queryName: string | null = null;
    let queryVariables: Record<string, unknown> | null = {};

    try {
      queryName = Filter.getQueryName(this.queries, filter);
      queryVariables = Filter.getQueryVariables(filter);
      logger.info("^ queryName 1", queryName);
      logger.info("^ queryVariables 1", queryVariables);
      if (queryName) {
        if (queryVariables) {
          if (
            queryName === 'listProfiles' &&
            // @ts-ignore
            Object.keys(queryVariables).includes("fullName")
          ) {
            // @ts-ignore
            if (queryVariables["fullName"]) {
              // @ts-ignore
              const fullName = `${queryVariables["fullName"]}`;
              console.info('^ searchProfiles fullName found', fullName);
              return searchProfiles({ name: fullName }).then(
                (searchResults) => {
                  console.info('^ searchProfiles searchResults', searchResults);
                  return {
                    data:  searchResults as any,
                    total: searchResults.length,
                  };
                }
              ).catch(
                (err) => {
                  console.warn('^ searchProfiles error', err);
                  return {
                    data: [],
                    total: 0,
                  };
                }
              );
            }
            // @ts-ignore
            if (queryVariables["email"]) {
              // @ts-ignore
              const email = `${queryVariables["email"]}`;
              console.info('^ searchProfiles email found', email);
              return searchProfiles({ email }).then(
                (searchResults) => {
                  console.info('^ searchProfiles searchResults', searchResults);
                  return {
                    data:  searchResults as any,
                    total: searchResults.length,
                  };
                }
              ).catch(
                (err) => {
                  console.warn('^ searchProfiles error', err);
                  return {
                    data: [],
                    total: 0,
                  };
                }
              );
            }
          } else if (
            queryName === 'listProducts' &&
            // @ts-ignore
            Object.keys(queryVariables).includes("name")
          ) {
            // @ts-ignore
            if (queryVariables["name"]) {
              // @ts-ignore
              const productName = `${queryVariables["name"]}`;
              console.info('^ searchProducts productName found', productName);
              return searchProducts(productName).then(
                (searchResults) => {
                  console.info('^ searchProducts searchResults', searchResults);
                  return {
                    data:  searchResults as any,
                    total: searchResults.length,
                  };
                }
              ).catch(
                (err) => {
                  console.warn('^ searchProducts error', err);
                  return {
                    data: [],
                    total: 0,
                  };
                }
              );
            }
          } else if (
            queryName === 'listOrganizations' &&
            // @ts-ignore
            Object.keys(queryVariables).includes("name")
          ) {
            // @ts-ignore
            if (queryVariables["name"]) {
              // @ts-ignore
              const organizationName = `${queryVariables["name"]}`;
              console.info('^ searchOrganizations organizationName found', organizationName);
              return searchOrganizations(organizationName).then(
                (searchResults) => {
                  console.info('^ searchOrganizations searchResults', searchResults);
                  return {
                    data:  searchResults as any,
                    total: searchResults.length,
                  };
                }
              ).catch(
                (err) => {
                  console.warn('^ searchOrganizations error', err);
                  return {
                    data: [],
                    total: 0,
                  };
                }
              );
            }
          } else if (
            queryName === 'listTeams' &&
            // @ts-ignore
            Object.keys(queryVariables).includes("name")
          ) {
            // @ts-ignore
            if (queryVariables["name"]) {
              // @ts-ignore
              const teamName = `${queryVariables["name"]}`;
              console.info('^ searchTeams teamName found', teamName);
              return searchTeams(teamName).then(
                (searchResults) => {
                  console.info('^ searchTeams searchResults', searchResults);
                  return {
                    data:  searchResults as any,
                    total: searchResults.length,
                  };
                }
              ).catch(
                (err) => {
                  console.warn('^ searchTeams error', err);
                  return {
                    data: [],
                    total: 0,
                  };
                }
              );
            }
          }
        } else if (['listProfiles', 'listProducts', 'listOrganization', 'listTeams'].includes(queryName)) {
          logger.info("^ queryVariables is empty");
          return {
            data: [],
            total: 0,
          };
        }
      }
    } catch (err) {
      logger.warn("^ error getting queryName or queryVariables", err);
    }
    // if (!queryName || !queryVariables) { // TODO implement
      // Default list query without filter
    //  queryName = this.getQueryName("list", resource);
    // }

    if (!queryName) {
      // logger.info("^^ resource", resource);
      queryName = this.getQueryName("list", resource, filter);
      logger.info("^^ queryName 2", queryName);
      logger.info("^^ queryName 2 filter", filter);
      if (!filter || Object.keys(filter).length === 0) {
        if (queryName === 'listProducts') {
          queryName = 'listProductsBySortByName';
          filter = {
            ...filter,
            ...{
              sortByName: 1
            }
          };
        } else if (queryName === 'listProfiles') {
          queryName = 'listProfilesBySortByFullName';
          filter = {
            ...filter,
            ...{
              sortByFullName: 1
            }
          };
        } else if (queryName === 'listOrganizations') {
          queryName = 'listOrganizationsBySortByName';
          filter = {
            ...filter,
            ...{
              sortByName: 1
            }
          };
        } else if (queryName === 'listTeams') {
          queryName = 'listTeamsBySortByName';
          filter = {
            ...filter,
            ...{
              sortByName: 1
            }
          };
        }
      }
      logger.info("^ queryName 3", queryName);
      if (queryName.startsWith("list") && filter && Object.keys(filter).length > 0) {
        // NOTE: converting query by ID to filter
        queryVariables = this.getQueryVariables(queryName, resource, filter, sort);
        // logger.info("^^ queryVariables 2", queryVariables);
      }
    }

    if (!queryName) {
      // TODO log to Sentry
      // Sentry.captureException(err);
      throw Error("queryName is empty");
    }

    const query = this.getQuery(queryName);

    if (!queryVariables) {
      queryVariables = {};
    }

    if (gqlFilter) {
      queryVariables = {
        ...queryVariables,
        ...{
          filter: gqlFilter
        }
      };
    }

    let { page, perPage } = params.pagination;

    // NOTE: override perPage for search filters
    //  TODO get pagination from filter instead
    if (
      queryVariables &&
      (
        Object.keys(queryVariables).includes("filter") &&
        queryVariables["filter"] &&
        ( // @ts-ignore
          Object.keys(queryVariables["filter"]).includes("fullName") || Object.keys(queryVariables["filter"]).includes("fullNameLowerCase") || // @ts-ignore
          Object.keys(queryVariables["filter"]).includes("name") || Object.keys(queryVariables["filter"]).includes("nameLowerCase") || // @ts-ignore
          Object.keys(queryVariables["filter"]).includes("title") || Object.keys(queryVariables["filter"]).includes("titleLowerCase") || // @ts-ignore
          Object.keys(queryVariables["filter"]).includes("email")
        )
      )
    ) {
      page = 1;
      perPage = 2000;
      logger.info("^ overridding perPage", perPage);
    }

    // Defines a unique identifier of the query
    const querySignature = JSON.stringify({
      queryName,
      queryVariables,
      perPage,
    });

    const nextToken = Pagination.getNextToken(querySignature, page);

    // Checks if page requested is out of range
    if (typeof nextToken === "undefined") {
      logger.info("^ typeof nextToken is undefined");
      return {
        data: [],
        total: 0,
      }; // React admin will redirect to page 1
    }

    // Adds sorting if requested
    // if (params.sort.field === queryName) {
    if (sort) {
      // logger.info("sort =", sort);
      const {
        order,
        field,
      } = sort;
      if (
        order &&
        field &&
        field === queryName &&
        queryName.startsWith("list")
      ) {
        queryVariables["sortDirection"] = order;
      }
      logger.info("^ queryVariables with sort", queryVariables);
    }

    logger.info(
      `^ graphql query for ${queryName}`,
      {
        ...queryVariables,
        limit: perPage,
        nextToken,
      },
    );

    // Executes the query
    const queryData = (
      await this.graphql(query, {
        ...queryVariables,
        limit: perPage,
        nextToken,
      })
    )[queryName];

    logger.info("^ queryData", queryData);

    // Saves next token
    if (queryData) {
      Pagination.saveNextToken(queryData.nextToken, querySignature, page);
    }
    // Computes total
    let total = 0;
    let items = [];
    if (queryData && queryData?.items) {
      // NOTE: filter deleted records
      items = queryData.items.filter(
        (item: any) => {
          const {
            _deleted
          } = item;
          return ! _deleted;
        }
      )
      if (items.length > 0) {
        logger.info("queryName", queryName);
        if (queryName === 'listProducts') {
          logger.info('sort listProducts', queryName);
          items.sort((a: Product, b: Product) => (a.nameLowerCase || '').localeCompare(b.nameLowerCase ? b.nameLowerCase : ''));
        } else if (queryName === 'listProfiles') {
          logger.info('sort listProfiles', queryName);
          items.sort((a: Profile, b: Profile) => (a.fullNameLowerCase || '').localeCompare(b.fullNameLowerCase ? b.fullNameLowerCase : ''));
        } else if (queryName === 'listProfileDevices') {
          logger.info('sort listProfileDevices', queryName);
          items.sort((a: ProfileDevice, b: ProfileDevice) => (a.profile.fullNameLowerCase || '').localeCompare(b.profile.fullNameLowerCase ? b.profile.fullNameLowerCase : ''));
        } else if (queryName === 'listOrganizations') {
          logger.info('sort listOrganizations', queryName);
          items.sort((a: Organization, b: Organization) => (a.nameLowerCase || '').localeCompare(b.nameLowerCase ? b.nameLowerCase : ''));
        } else if (queryName === 'listTeams') {
          logger.info('sort listTeams', queryName);
          items.sort((a: Team, b: Team) => (a.nameLowerCase || '').localeCompare(b.nameLowerCase ? b.nameLowerCase : ''));
        } else if (queryName === 'listTeamMemberRelationshipsByTeamId') {
          logger.info('sort listTeamMemberRelationshipsByTeamId', queryName);
          items.sort((a: TeamMemberRelationship, b: TeamMemberRelationship) => (a.member.fullNameLowerCase || '').localeCompare(b.member.fullNameLowerCase ? b.member.fullNameLowerCase : ''));
        } else if (queryName === 'listTeamMeetingRelationshipsByTeamId') {
          logger.info('sort listTeamMeetingRelationshipsByTeamId', queryName);
          items.sort( // TODO enable reverse sort
            (a: TeamMeetingRelationship, b: TeamMeetingRelationship) => {
              const startDateTimeA = DateTime.fromFormat(
                a.meeting.startDateTime,
                AWS_TIMESTAMP_AS_LUXON_FORMAT,
                { zone: "utc" },
              );
              const startDateTimeB = DateTime.fromFormat(
                b.meeting.startDateTime,
                AWS_TIMESTAMP_AS_LUXON_FORMAT,
                { zone: "utc" },
              );
              if (startDateTimeA.toUnixInteger() < startDateTimeB.toUnixInteger()) {
                return -1;
              }
              if (startDateTimeA.toUnixInteger() > startDateTimeB.toUnixInteger()) {
                return 1;
              }
              return 0;
            }
          );
        } else if (queryName === 'listTransactionJobs') {
          logger.info('sort listTransactionJobs', queryName);
          items.sort( // TODO enable reverse sort
            (a: TranscriptionJob, b: TranscriptionJob) => {
              const startDateTimeA = DateTime.fromFormat(
                a.date,
                AWS_TIMESTAMP_AS_LUXON_FORMAT,
                { zone: "utc" },
              );
              const startDateTimeB = DateTime.fromFormat(
                b.date,
                AWS_TIMESTAMP_AS_LUXON_FORMAT,
                { zone: "utc" },
              );
              if (startDateTimeA.toUnixInteger() < startDateTimeB.toUnixInteger()) {
                return -1;
              }
              if (startDateTimeA.toUnixInteger() > startDateTimeB.toUnixInteger()) {
                return 1;
              }
              return 0;
            }
          );
        } else if (queryName === 'listTranscriptions') {
          logger.info('sort listTranscriptions', queryName);
          items.sort(
            (a: Transcription, b: Transcription) => {
              const startDateTimeA = DateTime.fromFormat(
                a.date,
                AWS_TIMESTAMP_AS_LUXON_FORMAT,
                { zone: "utc" },
              );
              const startDateTimeB = DateTime.fromFormat(
                b.date,
                AWS_TIMESTAMP_AS_LUXON_FORMAT,
                { zone: "utc" },
              );
              // NOTE: reverse sort
              if (startDateTimeA.toUnixInteger() < startDateTimeB.toUnixInteger()) {
                return 1;
              }
              if (startDateTimeA.toUnixInteger() > startDateTimeB.toUnixInteger()) {
                return -1;
              }
              return 0;
            }
          );
        }
      }
      total = (page - 1) * perPage + items.length;
    }
    if (!queryData) {
      if (queryName) {
        logger.warn("empty queryData", queryName);
      } else {
        logger.warn("empty queryData empty queryName");
      }
    }
    if (queryData && queryData.nextToken) {
      total++; // Tells react admin that there is at least one more page
    }

    if (
      queryName === 'listMeetingsByStatus' &&
      queryVariables &&
      queryVariables.status &&
      queryVariables.status === MeetingStatus.SCHEDULED
    ) {
      let scheduledMeetings = {
        data: items,
        total,
      };
      try {
        const readyFilter = {
          ...params.filter,
          status: MeetingStatus.READY,
        };
        const startedFilter = {
          ...params.filter,
          status: MeetingStatus.STARTED,
        };
        const readyParams = {
          ...params,
          filter: readyFilter,
        };
        const startedParams = {
          ...params,
          filter: startedFilter,
        };
        const readyMeetings: GetListResult<Meeting> = await this.getList(
          resource,
          readyParams,
        );
        const startedMeetings: GetListResult<Meeting> = await this.getList(
          resource,
          startedParams,
        );
        scheduledMeetings = this.mergeLists(scheduledMeetings, readyMeetings) as GetListResult<Meeting>;
        scheduledMeetings = this.mergeLists(scheduledMeetings, startedMeetings) as GetListResult<Meeting>;
        const scheduledMeetingsDataSorted = scheduledMeetings.data;
        scheduledMeetingsDataSorted.sort(
          (a: Meeting, b: Meeting) => {
            const startDateTimeA = DateTime.fromFormat(
              a.startDateTime,
              AWS_TIMESTAMP_AS_LUXON_FORMAT,
              { zone: "utc" },
            );
            const startDateTimeB = DateTime.fromFormat(
              b.startDateTime,
              AWS_TIMESTAMP_AS_LUXON_FORMAT,
              { zone: "utc" },
            );
            if (startDateTimeA.toUnixInteger() < startDateTimeB.toUnixInteger()) {
              return -1;
            }
            if (startDateTimeA.toUnixInteger() > startDateTimeB.toUnixInteger()) {
              return 1;
            }
            return 0;
          }
        );
        scheduledMeetings.data = scheduledMeetingsDataSorted;
        logger.info("listMeetingsByStatus merge was successful");
      } catch (err) {
        logger.error("failed to merge meetings list", err);
      }
      return scheduledMeetings;
    }
    return {
      data: items,
      total,
    };
  };

  public getOne = async <RecordType>(
    resource: string,
    params: GetOneParams
    // @ts-ignore
  ): Promise<GetOneResult<RecordType>> => {
    if (this.enableAdminQueries && resource === "cognitoUsers") {
      return AdminQueries.getCognitoUser(params);
    }

    const queryName = this.getQueryName("get", resource);
    const query = this.getQuery(queryName);

    // Executes the query
    let queryData = (await this.graphql(query, { id: params.id }))[queryName];
    if (queryData) {
      // NOTE: filter deleted
      const {
        _deleted
      } = queryData;
      if (_deleted) {
        queryData = null;
      }
    }
    return {
      data: queryData,
    };
  };

  public getMany = async <RecordType>(
    resource: string,
    params: GetManyParams
    // @ts-ignore
  ): Promise<GetManyResult<RecordType>> => {
    if (this.enableAdminQueries && resource === "cognitoUsers") {
      return AdminQueries.getManyCognitoUsers(params);
    }
    const queryName = this.getQueryName("get", resource);
    const query = this.getQuery(queryName);

    const queriesData = [];

    // Executes the queries
    for (const id of params.ids) {
      try {
        const queryData = (await this.graphql(query, { id }))[queryName];
        // TODO filter deleted
        queriesData.push(queryData);
      } catch (e) {
        logger.error(e);
      }
    }

    return {
      data: queriesData,
    };
  };

  public getManyReference = async <RecordType>(
    resource: string,
    params: AmplifyGetManyReferenceParams
    // @ts-ignore
  ): Promise<GetManyReferenceResult<RecordType>> => {
    // logger.info("getManyReference input", { resource, params });
    let { filter } = params;
    // logger.debug("getManyReference filter 0", JSON.stringify(filter, null,2));
    const { id, pagination, sort, target, gqlFilter } = params;

    const splitTarget = target.split(".");
    // splitTarget is used to build the filter
    // It must be like: queryName.resourceID
    if (splitTarget.length === 2) {
      // logger.info("getManyReference splitTarget.length === 2");
      if (!filter[splitTarget[0]]) {
        filter[splitTarget[0]] = {};
      }
      filter[splitTarget[0]][splitTarget[1]] = id;
    } else {
      const queryName = this.getQueryNameMany("list", resource, target);
      // logger.info("getManyReference queryName", queryName);
      // logger.info("getManyReference filter 1", JSON.stringify(filter, null,2));
      if (!filter[queryName]) {
        filter[queryName] = {};
      }
      // logger.info("getManyReference filter 2", JSON.stringify(filter, null,2));
      filter[queryName][target] = id;
      // logger.info("getManyReference filter 3", JSON.stringify(filter, null, 2));
    }

    return this.getList(resource, { pagination, sort, filter, gqlFilter });
  };

  public create = async <RecordType>(
    resource: string,
    params: CreateParams
    // @ts-ignore
  ): Promise<CreateResult<RecordType>> => {
    const queryName = this.getQueryName("create", resource);
    const query = this.getQuery(queryName);
    const { data } = params;
    const { owner } = data;
    Object.entries(data).forEach(([key, value]) => {
      if (
        [
          "__typename",
        ].includes(key)
      ) {
        delete data[key];
      }
    });
    if (!owner) {
      // logger.debug("resource", resource);
      try {
        data.owner = await Auth.currentAuthenticatedUser().then((user) => user.username);
      } catch(err) {
        logger.error(err);
      }
    }
    // logger.debug("data", data);
    // Executes the query
    const queryData = (await this.graphql(query, { input: data }))[
      queryName
    ];
    return {
      data: queryData,
    };
  };

  public update = async <RecordType>(
    resource: string,
    params: UpdateParams
    // @ts-ignore
  ): Promise<UpdateResult<RecordType>> => {
    const queryName = this.getQueryName("update", resource);
    const query = this.getQuery(queryName);

    // Removes non editable fields
    const { data } = params;
    delete data._deleted;
    delete data._lastChangedAt;
    delete data.createdAt;
    delete data.updatedAt;
    delete data.owner;
    // TODO validation for updating meeting
    Object.entries(data).forEach(([key, value]) => {
      if (
        value &&
        (Object.prototype.hasOwnProperty.call(value, "nextToken") ||
          Object.prototype.hasOwnProperty.call(value, "id"))
      ) {
        delete data[key];
      } else if (
        [
          "auditLogs",
          "organization",
          "product",
          "locations",
          "images",
          "questionResponses",
          "room",
          "preferredRoomProvider",
          "salesTeam",
          "__typename",
        ].includes(key)
      ) {
        delete data[key];
      }
    });
    // Executes the query
    const queryData = (await this.graphql(query, { input: data }))[queryName];

    return {
      data: queryData,
    };
  };

  // This may not work for API that uses DataStore because
  // DataStore works with a _version field that needs to be properly set
  public updateMany = async ( // TODO disable updateMany due to DataStore conflict
    resource: string,
    params: UpdateManyParams
  ): Promise<UpdateManyResult> => {
    const queryName = this.getQueryName("update", resource);
    const query = this.getQuery(queryName);

    // Removes non editable fields
    const { data } = params;
    delete data._deleted;
    delete data._lastChangedAt;
    delete data.createdAt;
    delete data.updatedAt;
    delete data.owner;

    Object.entries(data).forEach(([key, value]) => {
      if (
        value &&
        (Object.prototype.hasOwnProperty.call(value, "nextToken") ||
          Object.prototype.hasOwnProperty.call(value, "id"))
      ) {
        delete data[key];
      } else if (
        [
          "auditLogs",
          "organization",
          "product",
          "locations",
          "images",
          "questionResponses",
          "room",
          "preferredRoomProvider",
          "salesTeam",
          "__typename",
        ].includes(key)
      ) {
        delete data[key];
      }
    });

    const ids = [];

    // Executes the queries
    for (const id of params.ids) {
      try {
        await this.graphql(query, { input: { ...data, id } });
        ids.push(id);
      } catch (e) {
        console.log(e);
      }
    }

    return {
      data: ids,
    };
  };

  public delete = async <RecordType>(
    resource: string,
    params: DeleteParams
    // @ts-ignore
  ): Promise<DeleteResult<RecordType>> => {
    const queryName = this.getQueryName("delete", resource);
    const query = this.getQuery(queryName);
    const { id } = params;
    let { previousData } = params;

    // logger.info("delete params", params);
    // logger.info("delete queryName", queryName);

    const data = { id } as Record<string, unknown>;

    if (!previousData) {
      const previousRecord = await this.getOne(
        resource,
        {
          id
        }
      ).then(
        (getOneResult) => {
          // logger.info("delete getOneResult", getOneResult);
          const {
            data
          } = getOneResult;
          if (data) {
            return data as RecordType;
          }
          return { id } as unknown as RecordType;
        }
      );
      // @ts-ignore
      if (previousRecord._version) {
        // @ts-ignore
        data._version = previousRecord._version;
      }
      // logger.info("delete data", data);
      // Executes the query
      const queryData = (await this.graphql(query, { input: data }))[queryName];
      // logger.info("delete queryData", queryData);
      // logger.info("delete previousRecord", previousRecord);
      return {
        data: previousRecord ? previousRecord : { id } as unknown as RecordType
      };
    }
    // @ts-ignore
    if (previousData._version) {
      // @ts-ignore
      data._version = previousData._version;
    }
    // Executes the query
    const queryData = (await this.graphql(query, { input: data }))[queryName];
    // logger.info("delete queryData", queryData);
    // logger.info("delete previousData", previousData);
    return {
      data: previousData ? previousData as unknown as RecordType : { id } as unknown as RecordType
    };
  };

  public deleteMany = async (
    resource: string,
    params: DeleteManyParams
  ): Promise<DeleteManyResult> => {
    const queryName = this.getQueryName("delete", resource);
    const query = this.getQuery(queryName);
    const ids = [];
    // Executes the queries
    for (const id of params.ids) {
      try {
        await this.graphql(query, { input: { id } });
        ids.push(id);
      } catch (e) {
        console.log(e);
      }
    }
    return {
      data: ids,
    };
  };

  public getQuery(queryName: string): string {
    if (this.queries[queryName]) {
      return this.queries[queryName];
    }
    if (this.mutations[queryName]) {
      return this.mutations[queryName];
    }
    logger.error(`Could not find query ${queryName}`);
    throw new Error("Data provider error");
  }

  public getQueryVariables(queryName: string, resource: string, filter: any, sort: any = null): any {
    let queryVariables = {};
    let queryFilter: any = {};
    if (filter && Object.keys(filter).length > 0) {
      // TODO autodetection
      if (
        resource === "meetings" ||
        resource === "meetingInvites"
      ) {
        const {
          title,
          status,
          startDateTime,
          endDateTime,
          meetingInvitationType,
          sellerTeamName,
          sellerOrganizationName,
          buyerTeamName,
        } = filter;
        if (status) {
          queryVariables = {
            status,
            sortDirection: ModelSortDirection.ASC,
          };
          if (startDateTime && endDateTime) {
            queryVariables = {
              ...queryVariables,
              ...{ startDateTime: { between: [startDateTime, endDateTime] } },
            };
          }
          if (sort) { // TODO add to end
            const {
              field,
              order
            } = sort;
            if (field && order) {
              queryVariables = {
                ...queryVariables,
                ...{
                  sortDirection: `${order}`.toUpperCase() === "DESC" ? ModelSortDirection.DESC : ModelSortDirection.ASC,
                },
              };
            }
          }
        }
        if (title) {
          queryFilter = {
            ...queryFilter,
            ...{ titleLowerCase: { contains: title.trim().toLowerCase() } },
          }
        }
        if (buyerTeamName) {
          queryFilter = {
            ...queryFilter,
            ...{ buyerTeamNameLowerCase: { contains: buyerTeamName.trim().toLowerCase() } },
          }
        }
        if (sellerTeamName) {
          queryFilter = {
            ...queryFilter,
            ...{ sellerTeamNameLowerCase: { contains: sellerTeamName.trim().toLowerCase() } },
          }
        }
        if (sellerOrganizationName) {
          queryFilter = {
            ...queryFilter,
            ...{ sellerOrganizationNameLowerCase: { contains: sellerOrganizationName.trim().toLowerCase() } },
          }
        }
        if (meetingInvitationType) {
          queryFilter = {
            ...queryFilter,
            ...{  meetingInvitationType: { eq: meetingInvitationType } },
          }
        }

      } else if (resource === "meetingRoomEventAuditLogs") {
        const {
          profileId,
          meetingRoomId,
          date,
          endDate,
        } = filter;
        if (profileId) {
          queryVariables = {
            profileId,
            sortDirection: ModelSortDirection.DESC,
          };
        } else if (meetingRoomId) {
          queryVariables = {
            meetingRoomId,
            sortDirection: ModelSortDirection.DESC,
          };
        }
        if ((profileId || meetingRoomId) && date && endDate) {
          queryVariables = {
            ...queryVariables,
            ...{ date: { between: [date, endDate] } },
          };
        }
        if (sort) {
          const {
            field,
            order
          } = sort;
          if (field && order) {
            queryVariables = {
              ...queryVariables,
              ...{
                sortDirection: `${order}`.toUpperCase() === "DESC" ? ModelSortDirection.DESC : ModelSortDirection.ASC,
              },
            };
          }
        }
      } else if (resource === "exportJobs") {
        const {
          profileId,
          status,
          startDateTime,
          endDateTime,
        } = filter;
        if (profileId) {
          queryVariables = {
            profileId,
          };
        } else if (status) {
          queryVariables = {
            status,
            sortDirection: ModelSortDirection.ASC,
          };
          if (startDateTime && endDateTime) {
            queryVariables = {
              ...queryVariables,
              ...{ startDateTime: { between: [startDateTime, endDateTime] } },
            };
          }
          if (sort) { // TODO add to end TODO sort event if not indexed
            const {
              field,
              order
            } = sort;
            if (field && order) {
              queryVariables = {
                ...queryVariables,
                ...{
                  sortDirection: `${order}`.toUpperCase() === "DESC" ? ModelSortDirection.DESC : ModelSortDirection.ASC,
                },
              };
            }
          }
        }
      } else if (resource === "transcriptions") {
        const {
          meetingId,
          teamId,
          startDateTime,
          endDateTime,
        } = filter;
        if (meetingId) {
          queryVariables = {
            meetingId,
            sortDirection: ModelSortDirection.ASC,
          };
        }
        if (teamId) {
          queryVariables = {
            teamId,
            sortDirection: ModelSortDirection.ASC,
          };
        }
        if (meetingId || teamId) {
          if (startDateTime && endDateTime) {
            queryVariables = {
              ...queryVariables,
              ...{ startDateTime: { between: [startDateTime, endDateTime] } },
            };
          }
          logger.info("^ jzj queryVariables", queryVariables);
          if (sort) { // TODO add to end TODO sort event if not indexed
            const {
              field,
              order
            } = sort;
            if (field && order) {
              queryVariables = {
                ...queryVariables,
                ...{
                  sortDirection: `${order}`.toUpperCase() === "DESC" ? ModelSortDirection.DESC : ModelSortDirection.ASC,
                },
              };
            }
          }
        }
      } else if (resource === "exportJobAuditLogs") {
        const {
          profileId,
          exportJobId,
          date,
          endDate,
        } = filter;
        // logger.info("exportJobAuditLogs filter", filter);
        if (exportJobId) {
          queryVariables = {
            exportJobId,
            sortDirection: ModelSortDirection.ASC,
          };
          if (date && endDate) {
            queryVariables = {
              ...queryVariables,
              ...{ date: { between: [date, endDate] } },
            };
          }
          if (profileId) {
            queryFilter = {
              ...queryFilter,
              ...{
                "profileId": {
                  eq: profileId
                }
              },
            }
          }
        } else if (profileId) {
          queryVariables = {
            profileId,
            sortDirection: ModelSortDirection.ASC,
          };
          if (date && endDate) {
            queryVariables = {
              ...queryVariables,
              ...{ date: { between: [date, endDate] } },
            };
          }
          if (exportJobId) {
            queryFilter = {
              ...queryFilter,
              ...{
                "exportJobId": {
                  eq: exportJobId
                }
              },
            }
          }
        }
        if (sort) { // TODO add to end TODO sort event if not indexed
          const {
            field,
            order
          } = sort;
          if (field && order) {
            queryVariables = {
              ...queryVariables,
              ...{
                sortDirection: `${order}`.toUpperCase() === "DESC" ? ModelSortDirection.DESC : ModelSortDirection.ASC,
              },
            };
          }
        }
      } else if (resource === "importJobs") {
        const {
          profileId,
          status,
          lastStartDateTime,
          lastEndDateTime,
        } = filter;
        if (profileId) {
          queryVariables = {
            profileId,
          };
        } else if (status) {
          queryVariables = {
            status,
            sortDirection: ModelSortDirection.ASC,
          };
          if (lastStartDateTime && lastEndDateTime) { // TODO validate dates
            queryVariables = {
              ...queryVariables,
              ...{ lastStartDateTime: { between: [lastStartDateTime, lastEndDateTime] } },
            };
          }
          if (sort) { // TODO add to end TODO sort event if not indexed
            const {
              field,
              order
            } = sort;
            if (field && order) {
              queryVariables = {
                ...queryVariables,
                ...{
                  sortDirection: `${order}`.toUpperCase() === "DESC" ? ModelSortDirection.DESC : ModelSortDirection.ASC,
                },
              };
            }
          }
        }
      } else if (resource === "profileDevices") {
        const {
          profileId,
          lastMeetingStatus,
          micStatus,
          cameraStatus,
        } = filter;
        if (profileId) {
          queryVariables = {
            profileId,
          };
        } else if (micStatus || cameraStatus || lastMeetingStatus) {
          if (micStatus) {
            queryVariables = {
              micStatus,
              sortDirection: ModelSortDirection.ASC,
            };
          }
          if (cameraStatus) {
            queryVariables = {
              cameraStatus,
              sortDirection: ModelSortDirection.ASC,
            };
          }
          if (lastMeetingStatus) {
            queryVariables = {
              lastMeetingStatus,
              sortDirection: ModelSortDirection.ASC,
            };
          }
          if (sort) {
            const {
              field,
              order
            } = sort;
            if (field && order) {
              queryVariables = {
                ...queryVariables,
                ...{
                  sortDirection: `${order}`.toUpperCase() === "DESC" ? ModelSortDirection.DESC : ModelSortDirection.ASC,
                },
              };
            }
          }
        }
      } else if (resource === "importJobAuditLogs") {
        const {
          profileId,
          importJobId,
          date,
          endDate,
        } = filter;
        // logger.info("importJobAuditLogs filter", filter);
        if (importJobId) {
          queryVariables = {
            importJobId,
            sortDirection: ModelSortDirection.ASC,
          };
          if (date && endDate) {
            queryVariables = {
              ...queryVariables,
              ...{ date: { between: [date, endDate] } },
            };
          }
          if (profileId) {
            queryFilter = {
              ...queryFilter,
              ...{
                "profileId": {
                  eq: profileId
                }
              },
            }
          }
        } else if (profileId) {
          queryVariables = {
            profileId,
            sortDirection: ModelSortDirection.ASC,
          };
          if (date && endDate) {
            queryVariables = {
              ...queryVariables,
              ...{ date: { between: [date, endDate] } },
            };
          }
          if (importJobId) {
            queryFilter = {
              ...queryFilter,
              ...{
                "importJobId": {
                  eq: importJobId
                }
              },
            }
          }
        }
        if (sort) { // TODO add to end TODO sort event if not indexed
          const {
            field,
            order
          } = sort;
          if (field && order) {
            queryVariables = {
              ...queryVariables,
              ...{
                sortDirection: `${order}`.toUpperCase() === "DESC" ? ModelSortDirection.DESC : ModelSortDirection.ASC,
              },
            };
          }
        }
      } else if (resource === "transcriptionAdminJobAuditLogs") {
        const {
          // profileId,
          transcriptionAdminJobId,
          date,
          endDate,
        } = filter;
        // logger.info("transcriptionAdminJobAuditLogs filter", filter);
        if (transcriptionAdminJobId) {
          queryVariables = {
            transcriptionAdminJobId,
            sortDirection: ModelSortDirection.ASC,
          };
          if (date && endDate) {
            queryVariables = {
              ...queryVariables,
              ...{ date: { between: [date, endDate] } },
            };
          }
        }
        if (sort) { // TODO add to end TODO sort event if not indexed
          const {
            field,
            order
          } = sort;
          if (field && order) {
            queryVariables = {
              ...queryVariables,
              ...{
                sortDirection: `${order}`.toUpperCase() === "DESC" ? ModelSortDirection.DESC : ModelSortDirection.ASC,
              },
            };
          }
        }
      } else if (resource === "products") {
        let {
          name,
          organizationId,
          sortByName,
        } = filter;
        if (sortByName) {
          queryVariables = {
            sortByName,
          };
          queryVariables = {
            ...queryVariables,
            ...{
              sortDirection: ModelSortDirection.ASC,
            },
          };
        } else if (organizationId) {
          queryVariables = {
            organizationId,
          };
        }
        if (name) {
          name = name.trim().toLowerCase();
          if (name) {
            queryFilter = {
              ...queryFilter,
              ...{
                nameLowerCase: {
                  contains: name
                }
              },
            }
          }
        }
      } else if (resource === "productQuestions") {
        let {
          question,
          productId,
        } = filter;
        if (productId) {
          queryVariables = {
            productId,
          };
        }
        if (question) {
          question = question.trim();
          if (question) {
            queryFilter = {
              ...queryFilter,
              ...{
                question: {
                  contains: question
                }
              },
            }
          }
        }
      } else if (resource === "profiles") {
        let {
          fullName,
          email,
          sortByFullName,
        } = filter;

        if (sortByFullName) {
          queryVariables = {
            sortByFullName,
          };
          queryVariables = {
            ...queryVariables,
            ...{
              sortDirection: ModelSortDirection.ASC,
            },
          };
        } else if (email) {
          email = email.trim();
          if (email.match(emailRegex)) {
            queryVariables = {
              email,
            };
          } else if (email) {
            queryFilter = {
              ...queryFilter,
              ...{
                email: {
                  contains: email
                }
              },
            }
          }
        }
        if (fullName) {
          fullName = fullName.trim().toLowerCase();
          if (fullName) {
            queryFilter = {
              ...queryFilter,
              ...{
                fullNameLowerCase: { // TODO sort by fullName
                  contains: fullName
                }
              },
            }
          }
        }
      } else if (resource === "organizations") {
        let {
          name,
          sortByName,
        } = filter;

        if (sortByName) {
          queryVariables = {
            sortByName,
          };
          queryVariables = {
            ...queryVariables,
            ...{
              sortDirection: ModelSortDirection.ASC,
            },
          };
        }

        if (name) {
          name = name.trim().toLowerCase();
          if (name) {
            queryFilter = {
              ...queryFilter,
              ...{
                nameLowerCase: {
                  contains: name
                }
              },
            }
          }
        }
      } else if (resource === "teams") {
        let {
          name,
          sortByName,
        } = filter;

        if (sortByName) {
          queryVariables = {
            sortByName,
          };
          queryVariables = {
            ...queryVariables,
            ...{
              sortDirection: ModelSortDirection.ASC,
            },
          };
        }

        if (name) {
          name = name.trim().toLowerCase();
          if (name) {
            queryFilter = {
              ...queryFilter,
              ...{
                nameLowerCase: {
                  contains: name
                }
              },
            }
          }
        }
      } else if (resource === "teamMeetingRelationships") {
        // logger.info("teamMeetingRelationships filter", filter);
        // logger.info("teamMeetingRelationships queryName", queryName);
        const {
          teamId,
          meetingId,
          relationshipType
        } = filter;

        if (meetingId && typeof meetingId === "string") {
          if (queryName.toUpperCase().includes("MEETINGID")) {
              queryVariables = {
                ...queryVariables,
                ...{
                  meetingId,
                },
              };
          } else {
            queryFilter = {
              ...queryFilter,
              ...{
                "meetingId": {
                  eq: meetingId
                }
              },
            }
          }
        }

        if (relationshipType && typeof relationshipType === "string") {
          if (queryName.toUpperCase().includes("RELATIONSHIPTYPE")) {
              queryVariables = {
                ...queryVariables,
                ...{
                  relationshipType,
                },
              };
          } else {
            queryFilter = {
              ...queryFilter,
              ...{
                "relationshipType": {
                  eq: relationshipType
                }
              },
            }
          }
        }

        if (teamId && typeof teamId === "string") {
          if (queryName.toUpperCase().includes("TEAMID")) {
              queryVariables = {
                ...queryVariables,
                ...{
                  teamId,
                },
              };
          } else {
            queryFilter = {
              ...queryFilter,
              ...{
                "teamId": {
                  eq: teamId
                }
              },
            }
          }
        }

      }
    }
    if (queryFilter && Object.keys(queryFilter).length > 0) {
      // logger.info(`queryFilter=${JSON.stringify(queryFilter, null, 2)}`);
      queryVariables = {
        ...queryVariables,
        ...{
          filter: queryFilter,
        }
      }
    }
    return queryVariables;
  }

  public getQueryName(operation: string, resource: string, filter: any = null): string {
    const pluralOperations = ["list"];
    const pluralResources: string[] = [ // TODO deprecate
      // "meetingAttendees",
      // "meetingInvitees",
      // "meetingOrganizers",
    ];

    // TODO autodetection
    if (filter && Object.keys(filter).length > 0) {
      if (
        resource === "meetings" ||
        resource === "meetingInvites"
      ) {
        const {
          status,
        } = filter;
        if (status) {
          return `${operation}${
            resource.charAt(0).toUpperCase() + resource.slice(1) + "ByStatus"
          }`;
        }
      } else if (resource === "meetingRoomEventAuditLogs") {
        const {
          profileId,
          meetingRoomId,
        } = filter;
        if (profileId) {
          return `${operation}${
            resource.charAt(0).toUpperCase() + resource.slice(1) + "ByProfileId"
          }`;
        } else if (meetingRoomId) {
          return `${operation}${
            resource.charAt(0).toUpperCase() + resource.slice(1) + "ByMeetingRoomId"
          }`;
        }
      } else if (resource === "exportJobs") {
        const {
          profileId,
          status,
        } = filter;
        if (profileId) {
          return `${operation}${
            resource.charAt(0).toUpperCase() + resource.slice(1) + "ByProfileId"
          }`;
        } else if (status) {
          return `${operation}${
            resource.charAt(0).toUpperCase() + resource.slice(1) + "ByStatus"
          }`;
        }
      } else if (resource === "exportJobAuditLogs") {
        const {
          profileId,
          exportJobId,
        } = filter;
        if (exportJobId) {
          return `${operation}${
            resource.charAt(0).toUpperCase() + resource.slice(1) + "ByExportJobId"
          }`;
        } else if (profileId) {
          return `${operation}${
            resource.charAt(0).toUpperCase() + resource.slice(1) + "ByProfileId"
          }`;
        }
      } else if (resource === "importJobs") {
        const {
          profileId,
          status,
        } = filter;
        if (profileId) {
          return `${operation}${
            resource.charAt(0).toUpperCase() + resource.slice(1) + "ByProfileId"
          }`;
        } else if (status) {
          return `${operation}${
            resource.charAt(0).toUpperCase() + resource.slice(1) + "ByStatus"
          }`;
        }
      } else if (resource === "importJobAuditLogs") {
        const {
          profileId,
          importJobId,
        } = filter;
        if (importJobId) {
          return `${operation}${
            resource.charAt(0).toUpperCase() + resource.slice(1) + "ByImportJobId"
          }`;
        } else if (profileId) {
          return `${operation}${
            resource.charAt(0).toUpperCase() + resource.slice(1) + "ByProfileId"
          }`;
        }
      } else if (resource === "transcriptionAdminJobAuditLogs") {
        const {
          // profileId,
          transcriptionAdminJobId,
        } = filter;
        if (transcriptionAdminJobId) {
          return `${operation}${
            resource.charAt(0).toUpperCase() + resource.slice(1) + "ByTranscriptionAdminJobId"
          }`;
        }
      } else if (resource === "transcriptions") {
        const {
          meetingId,
          teamId,
        } = filter;
        if (meetingId) {
          return `${operation}${
            resource.charAt(0).toUpperCase() + resource.slice(1) + "ByMeetingId"
          }`;
        }
        if (teamId) {
          return `${operation}${
            resource.charAt(0).toUpperCase() + resource.slice(1) + "ByTeamId"
          }`;
        }
      } else if (resource === "products") {
        const {
          organizationId
        } = filter;
        if (organizationId) {
          return `${operation}${
            resource.charAt(0).toUpperCase() + resource.slice(1) + "ByOrganizationId"
          }`;
        }
      } else if (resource === "productQuestions") {
        const {
          productId,
        } = filter;
        if (productId) {
          return `${operation}${
            resource.charAt(0).toUpperCase() + resource.slice(1) + "ByProductId"
          }`;
        }
      } else if (resource === "profiles") {
        let {
          email
        } = filter;
        if (email) {
          email = email.trim();
          if (email.match(emailRegex)) {
            return `${operation}${
              resource.charAt(0).toUpperCase() + resource.slice(1) + "ByEmail"
            }`;
          }
        }
      } else if (resource === "profileDevices") {
        const {
          profileId,
          cameraStatus,
          micStatus,
          lastMeetingStatus,
        } = filter;
        if (profileId) {
          return `${operation}${
            resource.charAt(0).toUpperCase() + resource.slice(1) + "ByProfileId"
          }`;
        } else if (cameraStatus) {
          return `${operation}${
            resource.charAt(0).toUpperCase() + resource.slice(1) + "ByCameraStatus"
          }`;
        } else if (micStatus) {
          return `${operation}${
            resource.charAt(0).toUpperCase() + resource.slice(1) + "ByMicStatus"
          }`;
        } else if (lastMeetingStatus) { // listProfileDevicesByMeetingStatus
          return `${operation}${
            resource.charAt(0).toUpperCase() + resource.slice(1) + "ByMeetingStatus"
          }`;
        }
      /* } else if (resource === "teams") {
        const {
          name,
        } = filter;
        if (name) {
          return `${operation}${
            resource.charAt(0).toUpperCase() + resource.slice(1) + "ByName"
          }`;
        } */
      } else if (resource === "teamMeetingRelationships") {
        const {
          relationshipType,
          meetingId,
        } = filter;
        if (meetingId && relationshipType) {
          return `${operation}${
            resource.charAt(0).toUpperCase() + resource.slice(1) + "ByMeetingIdAndRelationshipType"
          }`;
        } else if (relationshipType) {
          return `${operation}${
            resource.charAt(0).toUpperCase() + resource.slice(1) + "ByRelationshipType"
          }`;
        }
      }
    }

    if (
      pluralOperations.includes(operation) ||
      pluralResources.includes(resource)
    ) {
      return `${operation}${
        resource.charAt(0).toUpperCase() + resource.slice(1)
      }`;
    }
    // else singular operations ["create", "delete", "get", "update"]
    return `${operation}${
      resource.charAt(0).toUpperCase() + resource.slice(1, -1)
    }`;
  }

  public getQueryNameMany( // TODO deprecate
    operation: string,
    resource: string,
    target: string
  ): string {
    // logger.log("& getQueryNameMany", {operation, resource, target});
    const queryName = this.getQueryName(operation, resource);
    // logger.log("& getQueryNameMany queryName", queryName);
    // NOTE: Do not add "By" ID to the query name
    // user filter instead of query By index
    /* if (
      [ // TODO deprecate
        "listMeetingAttendees",
        "listMeetingInvitees",
        "listMeetingOrganizers",
      ].includes(queryName)
    ) {
      return queryName;
    } */
    /* // logger.info("& expected Query Name", `${queryName}By${
      target.charAt(0).toUpperCase() + target.slice(1, -2)
    }Id`); */
    return `${queryName}By${
      target.charAt(0).toUpperCase() + target.slice(1, -2)
    }Id`;
  }

  public async graphql(
    query: string,
    variables: Record<string, unknown>
  ): Promise<any> {
    const queryResult = (await API.graphql({
      query,
      variables,
      authMode: this.authMode,
    })) as GraphQLResult;

    if (queryResult.errors || !queryResult.data) {
      throw new Error("Data provider error");
    }
    return queryResult.data;
  }

  public subscribe(topic: string, subscriptionCallback: SubscriptionCallback): Promise<any> {
    this.subscriptions.push({ topic, subscriptionCallback });
    return Promise.resolve({ data: null });
  }

  public unsubscribe(topic: string, subscriptionCallback: SubscriptionCallback): Promise<any> {
    this.subscriptions = this.subscriptions.filter(
      (subscription) =>
        subscription.topic !== topic ||
        subscription.subscriptionCallback !== subscriptionCallback
    );
    return Promise.resolve({ data: null });
  }

  public publish(topic: string, event: RecordListEvent): Promise<any> {
    if (!topic) {
      return Promise.reject(new Error("ra-realtime.error.topic"));
    }
    if (!event.type) {
      return Promise.reject(new Error("ra-realtime.error.type"));
    }
    this.subscriptions.map(
      (subscription) =>
        topic === subscription.topic && subscription.subscriptionCallback(event)
    );
    return Promise.resolve({ data: null });
  }

  /**
   * Create a lock on a record
   *
   * @param {string} resource A React-Admin resource name
   * @param {Lock} data The data used to create a lock
   *
   * @returns A promise resolved with the created lock
   *
   * @example
   *
   * const locksDataProvider = addLocksMethods(dataProvider);
   *
   * await locksDataProvider.lock('post', {
   *     recordId: 143,
   *     identity: 'adrien' // It could be an authentication token
   * });
   *
   */
  public async lock(resource: string, data: Lock): Promise<any> {
    // @ts-ignore
    const { recordId, identity } = data;
    const createdAt = new Date();
    logger.warn("lock called");
    const { total } = await this.getList(this.locksResourceName, {
        pagination: { page: 1, perPage: 1 },
        sort: { field: 'id', order: 'ASC' },
        filter: {
            resource,
            recordId,
        },
    });

    if (total > 0) {
        return Promise.reject(
            new Error('ra-realtime.error.lock.lockedBySomeoneElse')
        );
    }

    return this.create(this.locksResourceName, {
        data: {
            identity,
            resource,
            recordId,
            createdAt,
        },
    });
  }

  /**
   * Unlock a record
   *
   * @param {string} resource A React-Admin resource name
   * @param {Lock} data The data used to remove a lock
   *
   * @returns A promise resolved with the removed lock
   *
   * @example
   *
   * const locksDataProvider = addLocksMethods(dataProvider);
   *
   * await locksDataProvider.unlock('post', {
   *     recordId: 143,
   *     identity: 'adrien' // It could be an authentication token
   * });
   *
   */
  public async unlock(resource: string, data: Lock): Promise<any> {
    return Promise.reject(
      new Error('ra-realtime.error.lock.noLock')
    );
    // @ts-ignore
    const { recordId, identity } = data;
    const { data: locks, total } = await this.getList(
      this.locksResourceName,
      {
        pagination: { page: 1, perPage: 1 },
        sort: { field: 'id', order: 'ASC' },
        filter: {
          resource,
          recordId,
        },
      }
    );

    if (total === 0) {
      return Promise.reject(
        new Error('ra-realtime.error.lock.noLock')
      );
    }

    // @ts-ignore
    if (locks[0].identity !== identity) {
        return Promise.reject(
            new Error('ra-realtime.error.lock.cannotUnlock')
        );
    }

    const currentLock = locks[0];
    return this.delete(
      this.locksResourceName,
      { // @ts-ignore
        id: currentLock.id,
        previousData: { // @ts-ignore
            ...currentLock,
        },
      }
    );
  }

  /**
   * Get an existing lock on a record
   *
   * @param {string} resource A React-Admin resource name
   * @param {Lock} data The data used to get a lock
   *
   * @returns A promise resolved with the lock
   *
   * @example
   *
   * const locksDataProvider = addLocksMethods(dataProvider);
   *
   * await locksDataProvider.getLock('post', {
   *     recordId: 143,
   * });
   *
   */
  public async getLock(resource: string, data: Lock): Promise<any> {
    return Promise.reject(
      new Error('ra-realtime.error.lock.noLock')
    );
    // @ts-ignore
    const { recordId } = data;
    const { data: locks, total } = await this.getList(
      this.locksResourceName,
      {
        pagination: { page: 1, perPage: 1 },
        sort: { field: 'id', order: 'ASC' },
        filter: {
          resource,
          recordId,
        },
      }
    );
    if (total === 0) {
      return Promise.reject(
        new Error('ra-realtime.error.lock.noLock')
      );
    }
    return Promise.resolve({ data: locks[0] });
  }

  /**
   * Get the list of locks for a resource
   *
   * @param {string} resource A React-Admin resource name
   *
   * @returns A promise resolved with the locks
   *
   * @example
   *
   * const locksDataProvider = addLocksMethods(dataProvider);
   *
   * await locksDataProvider.getLocks('post');
   *
   */
  public getLocks(resource: string): Promise<GetListResult<any>> {
    logger.warn("getLocks called");
    return this.getList(
      this.locksResourceName,
      {
        // "pagination" and "sort" field are required by getList
        pagination: { page: 1, perPage: 1000 },
        sort: { field: 'id', order: 'ASC' },
        filter: {
          resource,
        },
      }
    );
  }
}
