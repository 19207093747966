import React, { useState } from "react";
import {
  AutocompleteInput,
  Datagrid,
  DateField,
  FilterButton,
  ReferenceField,
  ReferenceInput,
  Show,
  ShowButton,
  SimpleShowLayout,
  TextField,
} from "react-admin";
import type { ListProps, ShowProps } from "react-admin";
import { Logger } from "aws-amplify";
import { CustomList, ListPagination } from "./CustomList";
import TopToolbar from "./TopToolbar";


const logger = new Logger("MeetingAttendee");
const defaultQuery = "listMeetingAttendees";

const filters = [
  <ReferenceInput
    source="profileId"
    reference="profiles"
    label="Profile"
    filterToQuery={(searchText) => ({
      listProfiles: { name: searchText },
    })}
    perPage={2000}
    resettable
  >
    <AutocompleteInput optionText="name" />
  </ReferenceInput>,
  <ReferenceInput
    source="meetingId"
    reference="meetings"
    label="Meeting"
    filterToQuery={(searchText) => ({
      listMeetings: { title: searchText },
    })}
    perPage={2000}
    resettable
  >
    <AutocompleteInput optionText="title" />
  </ReferenceInput>,
];


const ListActions = (props: any) => {
  const {
    className,
  } = props;
  return (
    <TopToolbar className={className}>
      <FilterButton />
    </TopToolbar>
  );
};

export const MeetingAttendeeList = (props: ListProps) => {
  const [query, setQuery] = useState(defaultQuery);
  return (
    <CustomList
      {...props}
      filters={filters}
      actions={<ListActions />}
      bulkActionButtons={false}
      pagination={<ListPagination />}
    >
      <Datagrid>
        <TextField source="profile.fullName" sortable={false} label="Attendee name" />
        <TextField source="meeting.title" sortable={false} label="Meeting title" />
        <DateField source="updatedAt" showTime={true} sortable={false} />
        <ShowButton />
      </Datagrid>
    </CustomList>
  );
};

export const MeetingAttendeeShow = (props: ShowProps) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" label="ID" fullWidth />
      <ReferenceField
        source="profileId"
        reference="profiles"
        label="Profile"
        link="show"
        fullWidth
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        source="meetingId"
        reference="meetings"
        label="Meeting"
        link="show"
        fullWidth
      >
        <TextField source="title" />
      </ReferenceField>
      <DateField source="createdAt" showTime={true} />
    </SimpleShowLayout>
  </Show>
);
