import React, { useState } from "react";
import {
  Create,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  ReferenceManyField,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
} from "react-admin";
import type {
  CreateProps,
  EditProps,
  ListProps,
  ShowProps,
} from "react-admin";
import DeleteWithConfirmToolbar from "./DeleteWithConfirmToolbar";
import { CustomList, ListPagination } from "./CustomList";

const defaultQuery = "listMeetingRoomProviders";

export const MeetingRoomProviderList = (props: ListProps) => {
  const [query, setQuery] = useState(defaultQuery);

  return (
    <CustomList
      {...props}
      bulkActionButtons={false}
      pagination={<ListPagination />}
    >
      <Datagrid>
        <TextField source="name" sortable={false} />
        <TextField source="componentName" sortable={false} />
        <DateField source="updatedAt" showTime={true} sortable={false} />
        <ShowButton />
        <EditButton />
      </Datagrid>
    </CustomList>
  );
};

export const MeetingRoomProviderShow = (props: ShowProps) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" label="ID" />
      <TextField source="name" />
      <TextField source="componentName" />
      <TextField source="description" />
      <TextField source="configuration" />

      {false && (
      <ReferenceManyField
        reference="meetings"
        target="preferredRoomProviderId"
        label="Meetings"
        perPage={10}
        fullWidth
        filter={{ listMeetingsByPreferredRoomProviderId: {} }}
      >
        <Datagrid>
          <TextField source="title" sortable={false} />
          <TextField source="status" sortable={false} />
          <DateField source="createdAt" sortable={false} />
          <DateField source="updatedAt" sortable={false} />
          <ShowButton />
          <EditButton />
        </Datagrid>
      </ReferenceManyField>
      )}

      {false && (
      <ReferenceManyField
        reference="meetingRooms"
        target="providerId"
        label="Meeting Rooms"
        perPage={10}
        fullWidth
        filter={{ listMeetingRoomByProviderId: {} }}
      >
        <Datagrid>
          <TextField source="name" sortable={false} />
          <TextField source="accessCode" sortable={false} />
          <DateField source="createdAt" sortable={false} />
          <DateField source="updatedAt" sortable={false} />
          <ShowButton />
          <EditButton />
        </Datagrid>
      </ReferenceManyField>
      )}
      <DateField source="createdAt" showTime={true} />
      <DateField source="updatedAt" showTime={true} />
    </SimpleShowLayout>
  </Show>
);

export const MeetingRoomProviderEdit = (props: EditProps) => (
  <Edit
    {...props}
    undoable={false}
    mutationMode="pessimistic"
  >
    <SimpleForm
      toolbar={<DeleteWithConfirmToolbar />}
    >
      <TextInput source="id" label="ID" disabled />
      <TextInput source="name" required />
      <TextInput source="componentName" required />
      <TextInput source="description" fullWidth />
      <TextInput source="configuration" fullWidth />
    </SimpleForm>
  </Edit>
);

export const MeetingRoomProviderCreate = (props: CreateProps) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" required />
      <TextInput source="componentName" required />
      <TextInput source="description" fullWidth />
      <TextInput source="configuration" fullWidth />
    </SimpleForm>
  </Create>
);
