import React, {
  useCallback,
  useContext,
  useState,
} from "react";
import {
  Create,
  CreateButton,
  Datagrid,
  DateField,
  DeleteWithConfirmButton,
  FilterButton,
  ReferenceManyField,
  SelectInput,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  Tab,
  TabbedShowLayout,
  TextField,
  useNotify,
  usePermissions,
  useQuery,
  useRedirect,
  useShowController,
  required,
  useMutation,
  TextInput,
} from "react-admin";
import type {
  CreateProps,
  ListProps,
  ShowProps,
} from "react-admin";
import { DateTimeInput as MaterialDateTimeInput } from "./MaterialDatePicker";
import type { TranscriptionAdminJob } from "../API";
import { JobStatus } from "../API";
import { Logger } from "@aws-amplify/core";
import { Auth } from "aws-amplify";
import { MeetingsStateContext } from "../App";
import { LogPayloadField } from "./CustomFields";
import { Typography } from "@material-ui/core";
import {
  Timeline,
  TimelineSkeleton,
} from "@react-admin/ra-audit-log";
import { CustomTimelineGroup } from "./CustomTimeline";
import { CustomList, ListPagination } from "./CustomList";
import { DateTime } from "luxon";
import { AWS_TIMESTAMP_AS_LUXON_FORMAT } from "../constants";
import { AmplifyFileField } from "./AmplifyFileField";
import { JsonField } from "react-admin-json-view";
import TopToolbar from "./TopToolbar";

const defaultQuery = "listTranscriptionAdminJobs";
const jobStatuses = [
  { name: "Pending", id: "PENDING" },
  { name: "Processing", id: "PROCESSING" },
  { name: "Failed", id: "FAILED" },
  { name: "Cancelled", id: "CANCELLED" },
  { name: "Completed", id: "COMPLETED" },
];

const logger = new Logger("TranscriptionAdminJob");
const validateRequired = [required()];

interface AsideProps extends ShowProps {
  record?: TranscriptionAdminJob;
}

const filters = [
  <TextInput
    source="meetingId"
    label="Meeting ID"
  />,
  <SelectInput label="Status" source="status" choices={jobStatuses} filter="listTranscriptionAdminJobsByStatus" />,
  <MaterialDateTimeInput
    label="Begin Date Range"
    source="startDate"
    options={{ format: 'MM/dd/yyyy, hh:mm a', ampm: true, clearable: true }}
  />,
  <MaterialDateTimeInput
    label="End Date Range"
    source="endDate"
    options={{ format: 'MM/dd/yyyy, hh:mm a', ampm: true, clearable: true }}
  />,
];

const ListActions = (props: any) => {
  const {
    className,
    basePath,
    resource,
    filters,
    filterValues,
    displayedFilters,
    showFilter,
  } = props;
  return (
    <TopToolbar className={className}>
      <FilterButton
        resource={resource}
        filters={filters}
        filterValues={filterValues}
        displayedFilters={displayedFilters}
        showFilter={showFilter}
      />
      <CreateButton basePath={basePath} />
    </TopToolbar>
  );
};

export const TranscriptionAdminJobList = (props: ListProps) => {
  const [query, setQuery] = useState(defaultQuery);
  const { loaded, permissions } = usePermissions();

  return (
    <CustomList
      {...props} filters={filters}
      actions={<ListActions />}
      bulkActionButtons={false}
      pagination={<ListPagination />}
    >
      <Datagrid>
        <DateField source="date" showTime={true} sortable={true} />
        <TextField source="status" sortable={false} />
        <TextField source="meetingId" label="Meeting ID" sortable={false} />
        {(loaded && permissions.includes("superuser")) && (
          <DeleteWithConfirmButton
            undoable={false}
            mutationMode="optimistic"
          />
        )}
        <ShowButton />
      </Datagrid>
    </CustomList>
  );
};

const Aside = (props: AsideProps) => { 

  const {
    record,
  } = props;
  
  const { data, loading, error } = useQuery(
    { 
      type: "getList",
      resource: "transcriptionAdminJobAuditLogs",
      payload: {
        pagination: { page: 1, perPage: 200 },
        sort: {
          field: "listTranscriptionAdminJobAuditLogsByTranscriptionAdminJobId",
          order: "ASC"
        },
        filter: {
          listTranscriptionAdminJobAuditLogsByTranscriptionAdminJobId: {
            transcriptionAdminJobId: record?.id,
          },
        },
      },
    }
  );

  return (
    <SimpleShowLayout>
      <Typography variant="h6">Log</Typography>
      <Timeline
        loaded={!loading && !error && data}
        records={data}
        skeleton={<TimelineSkeleton length={50} />}
      >
        <CustomTimelineGroup />
      </Timeline>
    </SimpleShowLayout>
  );
};

export const TranscriptionAdminJobShow = (props: ShowProps) => {
  
  const { record } = useShowController(props);  
  const propsWithAside = {
    ...props,
    ...(
      (record && record.id) &&
      { aside: <Aside {...props} record={record as TranscriptionAdminJob} /> }
    ),
  };
  return (
    <Show
      {... propsWithAside}
    >
      <TabbedShowLayout>
        <Tab label="Details">
          <TextField source="id" label="ID" fullWidth />
          <DateField source="date" showTime={true} />
          <TextField source="status" />
          <TextField source="meetingId" label="Meeting ID" />
          <AmplifyFileField
            source="result"
            download
            storageOptions={{ level: "public" }}
          />
          <JsonField
            source="result"
            addLabel={true}
            jsonString={false}
            reactJsonOptions={{
              theme: "shapeshifter",
              name: null,
              collapsed: true,
              enableClipboard: false,
              displayDataTypes: false,
            }}
          />
          <TextField source="identityId" label="Identity ID" />
          <DateField source="createdAt" showTime={true} />
          <DateField source="updatedAt" showTime={true} />
        </Tab>
        <Tab label="Log">
          <ReferenceManyField
            reference="transcriptionAdminJobAuditLogs"
            target="transcriptionAdminJobId"
            label="Audit Logs"
            perPage={25}
            fullWidth
            filter={{ listTranscriptionAdminJobAuditLogsByTranscriptionAdminJobId: {} }}
          >
            <Datagrid>
              <DateField source="date" showTime={true} sortable={false} />
              <TextField source="author" label="Author" sortable={false} />
              <TextField source="resource" label="Resource" sortable={false} />
              <TextField source="action" label="Action" sortable={false} />
              <LogPayloadField source="payload" label="Message" sortable={false} />
              <ShowButton />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
}

const validateTranscriptionAdminJob = (data: any) => {
  let errors: any = {};

  let {
    meetingId
  } = data;

  if (!meetingId) {
    const errorMessage = "Meeting ID is required.";
    errors = { meetingId: errorMessage };
    return errors;
  }

  return errors;
};

export const TranscriptionAdminJobCreate = (props: CreateProps) => {

  const meetingsState = useContext(MeetingsStateContext);
  const notify = useNotify();
  const redirect = useRedirect();
  const [ mutate ] = useMutation();
  const { loaded, permissions } = usePermissions();

  const {
    isAdmin,
    currentProfile,
  } = meetingsState;

  
  const transform = async (data: any) => {
    logger.info("data", data);
    return Auth.currentUserCredentials().then(
      async (creds) => {
        logger.info("creds", creds);
        const currentDateTime = DateTime.utc();
        const currentDateTimeAsString = currentDateTime.toFormat(AWS_TIMESTAMP_AS_LUXON_FORMAT);
        let updateData = {
          ...data,
          ...{
            date: currentDateTimeAsString,
            status: JobStatus.PROCESSING,
            identityId: creds.identityId,
          }
        };
        let {
          profileId,
        } = data;
        logger.info("profileId", profileId);
        if (!isAdmin || profileId) {
          if (!profileId && !isAdmin) {
            profileId = `${currentProfile?.id}`;
          }
          updateData = {
            ...updateData,
            ...{
              profileId,
            },
          }
        }
        return updateData;
      }
    );
  };

  // See: https://marmelab.com/react-admin/doc/3.19/CreateEdit.html#submission-validation
  const save = useCallback(
    async (jobData: any) => {
      const data = await transform(jobData);
      const errors = validateTranscriptionAdminJob(data);
      if (Object.keys(errors).length > 0) {
        logger.warn("found validation errors", errors);
        return errors;
      }
      try {
        if (data["date"].getSeconds() > 0) {
          data["date"].setSeconds(0);
        }
      } catch(err) {
        logger.warn("failed to set seconds");
      }
      try {
        if (data["date"].getMilliseconds() > 0) {
          data["date"].setMilliseconds(0);
        }
      } catch(err) {
        logger.warn("failed to set milliseconds");
      }
      await mutate(
        {
          type: "create",
          resource: "transcriptionAdminJobs",
          payload: { data },
        },
        { 
          returnPromise: true,
          onSuccess
        }
      );
    },
    [mutate],
  );

  const onSuccess = async (response: any) => {
    const {
      data,
    } = response;
    logger.info("onSuccess.data", data);
    const successMessage = "Export job is processing...";
    notify(successMessage, { type: "success" });
    redirect(`/transcriptionAdminJobAuditLogs?displayedFilters=%7B%22transcriptionAdminJobId%22%3Atrue%7D&filter=%7B%22transcriptionAdminJobId%22%3A%22${data.id}%22%7D&order=ASC&page=1&perPage=200&sort=id`);
  };

  return (
    <Create
      {...props}
    >
      <SimpleForm
        save={save}
      >
        <TextInput source="meetingId" label="Meeting ID" />
      </SimpleForm>
    </Create>
  );
};
