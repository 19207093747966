import React, { useCallback, useState } from "react";
import {
  Create,
  CreateButton,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  Link,
  Show,
  ShowButton,
  SimpleForm,
  TextField,
  TextInput,
  EmailField,
  ReferenceInput,
  AutocompleteInput,
  ReferenceManyField,
  BooleanField,
  BooleanInput,
  SingleFieldList,
  ChipField,
  ListButton,
  RefreshButton,
  TabbedShowLayout,
  Tab,
  SimpleShowLayout,
  SelectInput,
  setAutomaticRefresh,
  NumberField,
} from "react-admin";
import * as Sentry from "@sentry/react";
import {
  required,
  email as emailValidator,
  useNotify,
  useRedirect,
  useDataProvider,
  usePermissions,
  useMutation,
} from "ra-core";
import type {
  CreateProps,
  EditProps,
  ListProps,
  ShowProps,
} from "react-admin";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import { customAlphabet } from "nanoid/async"; // @ts-ignore
import { ShowSplitter } from "ra-compact-ui";
import { useDispatch } from "react-redux";
import { AmplifyImageField } from "./AmplifyImageField";
import { AmplifyImageAvatar } from "../components/AmplifyImageAvatar";
import { Profile } from "../API";
import { AdminQueries } from "../providers/AdminQueries";
import { Logger } from "aws-amplify";
import { getProfileByEmail } from "../lib/helpers";
import { MeetingRelationshipTypeField } from "./CustomFields";
// import NoDeleteToolbar from "../components/NoDeleteToolbar";
import { CustomList, ListPagination } from "./CustomList";
import { TIME_ZONE_CHOICES } from "../constants";
import { ProfileDeviceIconField } from "./ProfileDeviceFields";
import DeleteWithConfirmToolbar from "./DeleteWithConfirmToolbar";
import TopToolbar from "./TopToolbar";

const logger = new Logger("Profile");
const defaultQuery = "listProfiles";
const filters = [
  <TextInput label="Name" source="fullName" alwaysOn resettable />,
  <TextInput label="Email" source="email" alwaysOn resettable />,
];

export interface ProfileShowProps extends ShowProps {
  data: Profile;
  record?: Profile;
}

export interface ProfileEditProps extends EditProps {
  data: Profile;
  record?: Profile;
}

const AddNewTeamMembershipRelationshipButton = (props: ProfileShowProps | ProfileEditProps) => (
  <CreateButton
    component={Link}
    to={{
      pathname: "/teamMemberRelationships/create",
      state: { record: { memberProfileId: props.id as string } },
      search: `?source=${JSON.stringify({ memberProfileId: props.id as string })}`,
    }}
    label="Add to team"
  >
    <ContactMailIcon />
  </CreateButton>
);

const AddNewProfileLocationButton = (props: ProfileShowProps | ProfileEditProps) => (
  <CreateButton
    component={Link}
    to={{
      pathname: "/profileLocations/create",
      state: { record: { profileId: props.id as string } },
      search: `?source=${JSON.stringify({ profileId: props.id as string })}`,
    }}
    label="Add location"
  >
    <ContactMailIcon />
  </CreateButton>
);

const AddNewProfileImageButton = (props: ProfileShowProps | ProfileEditProps) => (
  <CreateButton
    component={Link}
    to={{
      pathname: "/profileImages/create",
      state: { record: { profileId: props.id as string } },
      search: `?source=${JSON.stringify({ profileId: props.id as string })}`,
    }}
    label="Add image"
  >
    <ContactMailIcon />
  </CreateButton>
);

const AddNewForwardEmailButton = (props: ProfileShowProps | ProfileEditProps) => (
  <CreateButton
    component={Link}
    to={{
      pathname: "/forwardEmails/create", // @ts-ignore
      state: { record: { profileId: props.id as string, privateEmail: props.email as string } }, // @ts-ignore
      search: `?source=${JSON.stringify({ profileId: props.id as string, privateEmail: props.email as string })}`,
    }}
    label="Add forward email"
  >
    <ContactMailIcon />
  </CreateButton>
);

const ShowActions = (props: ProfileShowProps) => {
  const {
    className,
    basePath,
    data,
    id,
  } = props;
  const {
    email,
  } = data;
  const forwardProps = {
    email,
    ...props,
  };
  return (
    <TopToolbar className={className}>
      <ListButton basePath={basePath} />
      <RefreshButton />
      <AddNewTeamMembershipRelationshipButton {...props} />
      <AddNewProfileLocationButton {...props} />
      <AddNewProfileImageButton {...props} />
      <AddNewForwardEmailButton {...forwardProps} />
      <EditButton basePath={basePath} label="Edit" record={{ id: id as string }} />
    </TopToolbar>
  );
};

const EditActions = (props: ProfileEditProps) => {
  const {
    className,
    basePath,
    data,
    id,
  } = props;
  const {
    email,
  } = data;
  const forwardProps = {
    email,
    ...props,
  };
  return (
    <TopToolbar className={className}>
      <ListButton basePath={basePath} />
      <AddNewTeamMembershipRelationshipButton {...props} />
      <AddNewProfileLocationButton {...props} />
      <AddNewProfileImageButton {...props} />
      <AddNewForwardEmailButton {...forwardProps} />
      <ShowButton basePath={basePath} label="Show" record={{ id: id as string }} />
    </TopToolbar>
  );
};

export const ProfileList = (props: ListProps) => {
  const [query, setQuery] = useState(defaultQuery);

  return (
    <CustomList
      {...props}
      filters={filters}
      bulkActionButtons={false}
      perPage={300}
      pagination={<ListPagination rowsPerPageOptions={
        [300, 400, 500, 600, 700, 800, 1000]
      } />}
    >
      <Datagrid>
        <TextField source="fullName" label="User name" sortable={false} />
        <TextField source="email" sortable={false} />
        <TextField source="externalId" label="External ID" />
        <DateField source="updatedAt" showTime={true} />
        <ShowButton />
        <EditButton />
      </Datagrid>
    </CustomList>
  );
};

// TODO link to view "Meetings"
// TODO link to remove user from meeting
// TODO link to remove user from team
export const ProfileShow = (props: ProfileShowProps) => {

  const { loaded, permissions } = usePermissions();

  return (
    <Show
    {...props}
    actions={<ShowActions {...props} />}
    component="div"
  >
    <ShowSplitter
      leftSide={
        <SimpleShowLayout>
          <ReferenceManyField
            reference="profileImages"
            target="profileId"
            label=""
            perPage={1}
            page={1}
            fullWidth
            filter={
              {
                listProfileImagesByProfileId: {
                  filter: {
                    isDefault: {
                      eq: true
                    }
                  }
                }
              }
            }
          >
            <Datagrid
              rowClick="edit"
              hover={false}
              header={() => <></>}
              hasBulkActions={false}
            >
              <AmplifyImageAvatar
                source="image"
                title="title"
                size="96px"
              />
            </Datagrid>
          </ReferenceManyField>
          <TextField source="fullName" />
          <TextField source="email" />
          <TextField source="occupation" />
          <TextField source="profile" />
          <TextField source="phoneNumber" />
          <TextField source="timeZone" />
          <TextField source="organization.name" label="Company" />
          <ReferenceManyField
            reference="profileLocations"
            target="profileId"
            label="Locations"
            perPage={10}
            fullWidth
            filter={{ listProfileLocationsByProfileId: {} }}
          >
            <SingleFieldList>
              <ChipField source="title" />
            </SingleFieldList>
          </ReferenceManyField>
          <ReferenceManyField
            reference="profileDevices"
            target="profileId"
            label="Devices"
            perPage={10}
            fullWidth
            filter={{ listProfileDevicesByProfileId: {} }}
            sort={{
              field: "listProfileDevicesByProfileId",
              order: "ASC",
            }}
          >
            <Datagrid rowClick="show">
              <ProfileDeviceIconField source="id" label="" sortable={false} />
              <DateField source="lastSeen" showTime={true} sortable={false} />
            </Datagrid>
          </ReferenceManyField>
        </SimpleShowLayout>
      }
      rightSide={
        <TabbedShowLayout>
          <Tab label="Teams">
            <ReferenceManyField
              reference="teamMemberRelationships"
              target="memberProfileId"
              label="Teams"
              perPage={10}
              fullWidth
              filter={{ listTeamMemberRelationshipsByMemberProfileId: {} }}
            >
              <SingleFieldList>
                <ChipField source="team.name" />
              </SingleFieldList>
            </ReferenceManyField>
            <AddNewTeamMembershipRelationshipButton {...props} />
          </Tab>
          <Tab label="Scheduled Meetings">
            <ReferenceManyField
                reference="meetingInvites"
                target="profileId"
                label="Meetings"
                perPage={50}
                fullWidth
                filter={
                  {
                    listMeetingInvitesByProfileId: {
                      filter: {
                        status: {
                          eq: "SCHEDULED"
                        }
                      }
                    }
                  }
                }
                sort={{
                  field: "listMeetingInvitesByProfileId",
                  order: "ASC",
                }}
              >
                <Datagrid>
                  <TextField source="title" sortable={false} />
                  {(false && <TextField source="sellerOrganizationName" label="Company" sortable={false} /> )}
                  <TextField source="sellerTeamName" label="Seller" sortable={false} />
                  <TextField source="buyerTeamName" label="Buyer" sortable={false} />
                  <DateField source="startDateTime" showTime={true} sortable={false} />
                  <DateField source="endDateTime" showTime={true} sortable={false} />
                  <MeetingRelationshipTypeField source="meetingInvitationType" label="Type" sortable={false} />
                  {(loaded && (permissions.includes("superuser") || permissions.includes("admin"))) && (
                    <ShowButton label="Show Invite" />
                  )}
                </Datagrid>
              </ReferenceManyField>
          </Tab>
          <Tab label="Completed Meetings">
            <ReferenceManyField
                reference="meetingInvites"
                target="profileId"
                label="Meetings"
                perPage={50}
                fullWidth
                filter={
                  {
                    listMeetingInvitesByProfileId: {
                      filter: {
                        status: {
                          eq: "ENDED"
                        }
                      }
                    }
                  }
                }
                sort={{
                  field: "listMeetingInvitesByProfileId",
                  order: "DESC",
                }}
              >
                <Datagrid>
                  <TextField source="title" sortable={false} />
                  {(false && <TextField source="sellerOrganizationName" label="Company" sortable={false} /> )}
                  <TextField source="sellerTeamName" label="Seller" sortable={false} />
                  <TextField source="buyerTeamName" label="Buyer" sortable={false} />
                  <DateField source="startDateTime" showTime={true} sortable={false} />
                  <DateField source="endDateTime" showTime={true} sortable={false} />
                  <MeetingRelationshipTypeField source="meetingInvitationType" label="Type" sortable={false} />
                  {(loaded && (permissions.includes("superuser") || permissions.includes("admin"))) && (
                    <ShowButton label="Show Invite" />
                  )}
                </Datagrid>
              </ReferenceManyField>
          </Tab>
          <Tab label="Attendance">
            <ReferenceManyField
                reference="meetingAttendees"
                target="profileId"
                label="Meetings"
                perPage={50}
                fullWidth
                filter={
                  {
                    listMeetingAttendeesByProfileId: {}
                  }
                }
              >
                <Datagrid>
                  <TextField source="meeting.title" sortable={false} label="Title" />
                  <DateField source="meeting.startDateTime" showTime={true} sortable={false} label="Start date" />
                  <DateField source="createdAt" showTime={true} sortable={false} label="Joined at" />
                </Datagrid>
              </ReferenceManyField>
          </Tab>
          <Tab label="Locations">
            <ReferenceManyField
              reference="profileLocations"
              target="profileId"
              label="Locations"
              perPage={10}
              fullWidth
              filter={{ listProfileLocationsByProfileId: {} }}
            >
              <SingleFieldList>
                <ChipField source="title" />
              </SingleFieldList>
            </ReferenceManyField>
            <AddNewProfileLocationButton {...props} />
          </Tab>
          <Tab label="Images">
            <ReferenceManyField
              reference="profileImages"
              target="profileId"
              label="Images"
              perPage={10}
              fullWidth
              filter={{ listProfileImagesByProfileId: {} }}
            >
              <SingleFieldList>
                <AmplifyImageField source="image" title="title" />
              </SingleFieldList>
            </ReferenceManyField>
            <AddNewProfileImageButton {...props} />
          </Tab>
          <Tab label="Details">
            <TextField source="id" label="ID" fullWidth />
            <TextField source="fullName" />
            <EmailField source="email" />
            <TextField source="externalId" label="External ID" />
            <TextField source="userId" label="Cognito User ID" />
            <TextField source="occupation" />
            <TextField source="profile" />
            <TextField source="phoneNumber" />
            <TextField source="timeZone" />
            <TextField source="organization.name" label="Company" />
            <BooleanField
              source="privacyEnabled"
              label="Enable privacy mode?"
              defaultValue="No"
            />
            <DateField source="createdAt" showTime={true} />
            <DateField source="updatedAt" showTime={true} />
          </Tab>
          <Tab label="Forward Emails">
            <ReferenceManyField
              reference="forwardEmails"
              target="profileId"
              label="Forward Emails"
              perPage={10}
              fullWidth
              filter={{ listForwardEmailsByProfileId: {} }}
            >
              <Datagrid>
                <EmailField source="publicEmail" sortable={false} />
                <NumberField source="received" sortable={false} />
                <NumberField source="remaining" sortable={false} />
                <DateField source="expireAt" sortable={false} showTime />
                <ShowButton />
              </Datagrid>
            </ReferenceManyField>
          </Tab>
          {false && (
          <Tab label="Log">
            <ReferenceManyField
              reference="meetingRoomEventAuditLogs"
              target="profileId"
              label="Audit Logs"
              perPage={10}
              fullWidth
              filter={{ listMeetingRoomEventAuditLogsByProfileId: {} }}
            >
              <Datagrid>
                <DateField source="date" showTime sortable={true} />
                <TextField source="resource" sortable={false} />
                <TextField source="action" sortable={false} />
                <DateField source="createdAt" sortable={false} showTime />
                <ShowButton />
              </Datagrid>
            </ReferenceManyField>
          </Tab>
          )}
        </TabbedShowLayout>
      }
      >
      </ShowSplitter>
    </Show>
  );
}

const validateFullName = [required()];
const validateEmail = [required(), emailValidator()];

export const ProfileEdit = (props: ProfileEditProps) => (
  <Edit
    {...props} actions={<EditActions {...props} />}
    undoable={false}
    mutationMode="pessimistic"
  >
    <SimpleForm
      toolbar={<DeleteWithConfirmToolbar />}
    >
      <TextInput source="id" label="ID" fullWidth disabled />
      <TextInput source="fullName" validate={validateFullName} fullWidth />
      <TextInput source="email" validate={validateEmail} fullWidth />
      <TextInput source="phoneNumber" />
      <SelectInput
          source="timeZone"
          choices={TIME_ZONE_CHOICES}
          resettable={true}
        />
      <BooleanInput
        source="privacyEnabled"
        label="Enable privacy mode?"
        defaultValue={false}
      />
      <ReferenceInput
        source="organizationId"
        reference="organizations"
        label="Company"
        filterToQuery={(searchText) => ({
          listOrganizations: { name: searchText },
        })}
        perPage={2000}
        fullWidth
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <TextInput source="occupation" label="Occupation" fullWidth />
      <TextInput source="profile" label="User profile" multiline fullWidth />
      <TextInput source="userId" label="Cognito user ID" fullWidth disabled />
      <TextInput source="externalId" label="External ID" />
    </SimpleForm>
  </Edit>
);

export const ProfileCreate = (props: CreateProps) => {

  const notify = useNotify();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const [mutate] = useMutation();
  const dispatch = useDispatch();
  dispatch(setAutomaticRefresh(false));

  const validateNewProfile = (data: any) => {
    let errors: any = {};
    logger.info("validateNewProfile called...");
    let {
      email,
      fullName,
      privacyEnabled,
    } = data;
    if (!fullName) {
      const errorMessage = "Name is required.";
      errors = { fullName: errorMessage };
      return errors;
    }
    if (!email) {
      const errorMessage = "Email is required.";
      errors = { email: errorMessage };
      return errors;
    }
    return errors;
  };

  const onSuccess = (profileData: { data: Profile }) => {
    const {
      data,
    } = profileData;
    let message = "Profile created";
    notify(message, { type: "success" });
    redirect(`/profiles/${data.id}`);
  };
  // See: https://marmelab.com/react-admin/doc/3.19/CreateEdit.html#submission-validation
  const save = useCallback(
    async (data: any) => {
      let errors = validateNewProfile(data);
      if (Object.keys(errors).length > 0) {
        logger.warn("found validation errors #1", errors);
        return errors;
      }
      let {
        email,
        fullName,
        privacyEnabled,
      } = data;
      const normalizedEmail = `${email}`.trim().toLowerCase();
      const normalizedFullName = `${fullName}`.trim();
      const normalizedPrivacyEnabled = privacyEnabled ? true : false;
      const nanoid = customAlphabet("123456789ABCDEFGHIJKLMNPQRSTUVWXYZ~!@#$%^&*()_+", 9);
      await AdminQueries.listCognitoUsers(
        {
          pagination: { page: 1, perPage: 10 },
          sort: { field: "id", order: "ASC" },
          filter: {
            listUsersByEmail: {
              email: normalizedEmail,
            }
          }
        }
      ).then(
        async (usersResults) => {
          logger.info("usersResults", usersResults);
          let userId = "";
          const {
            total,
          } = usersResults;
          if (total > 0 && usersResults.data.length > 0) {
            // @ts-ignore
            userId = usersResults.data[0].sub;
          }
          await getProfileByEmail(normalizedEmail).then(
            async (profileByEmail) => {
              logger.info("profileByEmail", profileByEmail);
              if (profileByEmail) {
                if (userId && (!profileByEmail.userId || profileByEmail.userId != profileByEmail.owner)) {
                  logger.info(`setting userId for ${profileByEmail.id}`, userId);
                  // NOTE: update Profile and set userId and owner
                  await dataProvider.update(
                    "profiles",
                    {
                      id: profileByEmail.id,
                      data: {
                        id: profileByEmail.id,
                        userId,
                        owner: userId,
                        _version: profileByEmail._version
                      },
                      previousData: {
                        id: profileByEmail.id,
                        userId: profileByEmail.userId,
                        owner: profileByEmail.owner,
                      },
                    }
                  ).then(
                    (providerUpdateResults) => {
                      logger.info("providerUpdateResults", providerUpdateResults);
                    }
                  ).catch(
                    (err) => {
                      logger.error("failed to update profile", err);
                    }
                  );
                }
                const errorMessage = "A profile with that email already exists.";
                errors = { email: errorMessage };
              } else {
                if (userId) {
                  // NOTE: Cognito user already exists.
                  data = {
                    ...data,
                    ...{
                      email: normalizedEmail,
                      fullName: normalizedFullName,
                      privacyEnabled: normalizedPrivacyEnabled,
                      userId,
                      owner: userId,
                    }
                  };
                  await AdminQueries.enableCognitoUser(userId).then(
                    (enableUserResult) => {
                      logger.info('enableUserResult', enableUserResult);
                    }
                  ).catch(
                    (err: any) => { }
                  );
                  logger.info("updatedData", data);
                } else {
                  await nanoid().then(
                    async (temporaryPassword1) => {
                      const params = {
                        Username: normalizedEmail,
                        DesiredDeliveryMediums: [
                          "EMAIL",
                        ],
                        MessageAction: "SUPPRESS",
                        TemporaryPassword: temporaryPassword1,
                        UserAttributes: [
                          {
                            Name: 'email',
                            Value: normalizedEmail
                          },
                          {
                            Name: 'name',
                            Value: normalizedFullName
                          },
                          {
                            Name: 'email_verified',
                            Value: 'true'
                          },
                        ]
                      };
                      await AdminQueries.createCognitoUser({ data: params }).then(
                        async (createResult) => {
                          console.info('createCognitoUser result', createResult);
                          const { // @ts-ignore
                            Enabled, // @ts-ignore
                            sub,
                          } = createResult.data;
                          userId = `${sub}`;
                          logger.info("Enabled", Enabled);
                          const isEnabled = Enabled ? true : false;
                          logger.info("isEnabled", isEnabled);
                          await AdminQueries.addCognitoUserToGroup(
                            {
                              data: {
                                username: userId,
                                groupname: "attendee",
                              }
                            }
                          ).then(
                            (addResult) => {
                              logger.info('addResult', addResult);
                            }
                          ).catch(
                            (err: any) => {
                              logger.warn("failed add user to group");
                              const { errors } = err;
                              if (errors && errors.length > 0) {
                                const { message } = err.errors[0];
                                const errorMessage = message || "Unknown error";
                                Sentry.captureMessage(errorMessage);
                              } else {
                                Sentry.captureException(err);
                              }
                            }
                          );
                          // NOTE: Default UserStatus is "FORCE_CHANGE_PASSWORD"
                          await nanoid().then(
                            async (temporaryPassword2) => {
                              const setPasswordResult = await AdminQueries.setCognitoUserPassword(
                                {
                                  data: {
                                    Password: temporaryPassword2,
                                    Permanent: true,
                                    Username: userId,
                                  }
                                }
                              );
                              logger.info('setPasswordResult', setPasswordResult);
                            }
                          ).catch(
                            (err: any) => {
                              logger.warn("failed to setPassword");
                              const { errors } = err;
                              if (errors && errors.length > 0) {
                                const { message } = err.errors[0];
                                const errorMessage = message || "Unknown error";
                                Sentry.captureMessage(errorMessage);
                              } else {
                                Sentry.captureException(err);
                              }
                            }
                          );
                          if (!isEnabled) {
                            await AdminQueries.confirmCognitoUserSignUp(userId).then(
                              async (confirmSignUpResult) => {
                                logger.info('confirmSignUpResult', confirmSignUpResult);
                                await AdminQueries.enableCognitoUser(userId).then(
                                  (enableUserResult) => {
                                    logger.info('enableUserResult', enableUserResult);
                                  }
                                ).catch(
                                  (err: any) => { }
                                );
                              }
                            ).catch(
                              (err: any) => {
                                logger.warn("failed to confirmCognitoUserSignUp");
                                const { errors } = err;
                                if (errors && errors.length > 0) {
                                  const { message } = err.errors[0];
                                  const errorMessage = message || "Unknown error";
                                  Sentry.captureMessage(errorMessage);
                                } else {
                                  Sentry.captureException(err);
                                }
                              }
                            );
                          }
                          data = {
                            ...data,
                            ...{
                              email: normalizedEmail,
                              fullName: normalizedFullName,
                              privacyEnabled: normalizedPrivacyEnabled,
                              userId,
                              owner: userId,
                            }
                          }
                          logger.info("updatedData", data);
                        }
                      ).catch(
                        (err: any) => {
                          logger.warn("failed create user");
                          const { errors } = err;
                          if (errors && errors.length > 0) {
                            const { message } = err.errors[0];
                            const errorMessage = message || "Unknown error";
                            Sentry.captureMessage(errorMessage);
                            throw new Error(errorMessage);
                          } else {
                            Sentry.captureException(err);
                            throw err;
                          }
                        }
                      );
                    }
                  );
                }
              }
            }
          );
        }
      );
      if (Object.keys(errors).length > 0) {
        logger.warn("found validation errors #2", errors);
        return errors;
      }
      // return { email: "TESTING" };
      await mutate(
        {
          type: "create",
          resource: "profiles",
          payload: { data },
        },
        {
          returnPromise: true,
          onSuccess
        }
      );
    },
    [mutate],
  );

  const transform = async (data: any) => {
    const {
      email,
      fullName,
      privacyEnabled,
    } = data;
    if (email && fullName) {
      const normalizedEmail = `${email}`.trim().toLowerCase();
      const normalizedFullName = `${fullName}`.trim();
      const normalizedPrivacyEnabled = privacyEnabled ? true : false;
      logger.info("transform called...");
      return {
        ...data,
        ...{
          email: normalizedEmail,
          fullName: normalizedFullName,
          privacyEnabled: normalizedPrivacyEnabled,
        }
      }
    }
    return data;
  };
  return (
    <Create {...props} transform={transform}>
      <SimpleForm
        save={save}
      >
        <TextInput source="fullName" validate={validateFullName} fullWidth />
        <TextInput source="email" validate={validateEmail} fullWidth />
        <TextInput
          source="phoneNumber"
          // validate={Promise.resolve((pN: string) => isValidPhoneNumber(pN))}
        />
        <SelectInput
          source="timeZone"
          choices={TIME_ZONE_CHOICES}
          resettable={true}
        />
        <BooleanInput
          source="privacyEnabled"
          label="Enable privacy mode?"
          defaultValue={false}
        />
        <ReferenceInput
          source="organizationId"
          reference="organizations"
          label="Company"
          filterToQuery={(searchText) => ({
            listOrganizations: { name: searchText },
          })}
          perPage={2000}
          fullWidth
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <TextInput source="occupation" label="Occupation" fullWidth />
        <TextInput source="profile" label="User profile" multiline fullWidth />
        <TextInput source="externalId" label="External ID" />
      </SimpleForm>
    </Create>
  );
}

