/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createProductQuestion = /* GraphQL */ `
  mutation CreateProductQuestion(
    $input: CreateProductQuestionInput!
    $condition: ModelProductQuestionConditionInput
  ) {
    createProductQuestion(input: $input, condition: $condition) {
      id
      question
      product {
        id
        name
        nameLowerCase
        description
        serviceInformation
        images {
          nextToken
          startedAt
          __typename
        }
        questions {
          nextToken
          startedAt
          __typename
        }
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        salesTeam {
          id
          name
          nameLowerCase
          description
          labels
          privacyEnabled
          privacyEnableOtherTeams
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        salesTeamId
        meetings {
          nextToken
          startedAt
          __typename
        }
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        labels
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProductsId
        teamProductsId
        __typename
      }
      productId
      responses {
        items {
          id
          response
          privateNotes
          questionId
          profileId
          meetingId
          meetingInviteId
          owner
          contactRequestTimeframe
          contactRequestDateTime
          contactPhoneNumber
          contactEmail
          contactName
          providePersonalEmail
          providePersonalPhoneNumber
          textOnly
          followupMeetingRequested
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productQuestionResponsesId
          profileQuestionResponsesId
          meetingQuestionResponsesId
          meetingInviteQuestionResponsesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      productQuestionsId
      __typename
    }
  }
`;
export const updateProductQuestion = /* GraphQL */ `
  mutation UpdateProductQuestion(
    $input: UpdateProductQuestionInput!
    $condition: ModelProductQuestionConditionInput
  ) {
    updateProductQuestion(input: $input, condition: $condition) {
      id
      question
      product {
        id
        name
        nameLowerCase
        description
        serviceInformation
        images {
          nextToken
          startedAt
          __typename
        }
        questions {
          nextToken
          startedAt
          __typename
        }
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        salesTeam {
          id
          name
          nameLowerCase
          description
          labels
          privacyEnabled
          privacyEnableOtherTeams
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        salesTeamId
        meetings {
          nextToken
          startedAt
          __typename
        }
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        labels
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProductsId
        teamProductsId
        __typename
      }
      productId
      responses {
        items {
          id
          response
          privateNotes
          questionId
          profileId
          meetingId
          meetingInviteId
          owner
          contactRequestTimeframe
          contactRequestDateTime
          contactPhoneNumber
          contactEmail
          contactName
          providePersonalEmail
          providePersonalPhoneNumber
          textOnly
          followupMeetingRequested
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productQuestionResponsesId
          profileQuestionResponsesId
          meetingQuestionResponsesId
          meetingInviteQuestionResponsesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      productQuestionsId
      __typename
    }
  }
`;
export const deleteProductQuestion = /* GraphQL */ `
  mutation DeleteProductQuestion(
    $input: DeleteProductQuestionInput!
    $condition: ModelProductQuestionConditionInput
  ) {
    deleteProductQuestion(input: $input, condition: $condition) {
      id
      question
      product {
        id
        name
        nameLowerCase
        description
        serviceInformation
        images {
          nextToken
          startedAt
          __typename
        }
        questions {
          nextToken
          startedAt
          __typename
        }
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        salesTeam {
          id
          name
          nameLowerCase
          description
          labels
          privacyEnabled
          privacyEnableOtherTeams
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        salesTeamId
        meetings {
          nextToken
          startedAt
          __typename
        }
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        labels
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProductsId
        teamProductsId
        __typename
      }
      productId
      responses {
        items {
          id
          response
          privateNotes
          questionId
          profileId
          meetingId
          meetingInviteId
          owner
          contactRequestTimeframe
          contactRequestDateTime
          contactPhoneNumber
          contactEmail
          contactName
          providePersonalEmail
          providePersonalPhoneNumber
          textOnly
          followupMeetingRequested
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productQuestionResponsesId
          profileQuestionResponsesId
          meetingQuestionResponsesId
          meetingInviteQuestionResponsesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      productQuestionsId
      __typename
    }
  }
`;
export const createQuestionResponse = /* GraphQL */ `
  mutation CreateQuestionResponse(
    $input: CreateQuestionResponseInput!
    $condition: ModelQuestionResponseConditionInput
  ) {
    createQuestionResponse(input: $input, condition: $condition) {
      id
      response
      privateNotes
      question {
        id
        question
        product {
          id
          name
          nameLowerCase
          description
          serviceInformation
          organizationId
          salesTeamId
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProductsId
          teamProductsId
          __typename
        }
        productId
        responses {
          nextToken
          startedAt
          __typename
        }
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productQuestionsId
        __typename
      }
      questionId
      profile {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      profileId
      meeting {
        id
        title
        titleLowerCase
        startDateTime
        endDateTime
        status
        description
        preferredRoomProvider {
          id
          name
          componentName
          description
          configuration
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        preferredRoomProviderId
        actualStartDateTime
        actualEndDateTime
        room {
          id
          name
          meetingId
          providerId
          providerMeetingId
          accessCode
          status
          startDateTime
          endDateTime
          actualStartDateTime
          actualEndDateTime
          roomCreatedAt
          roomDestroyedAt
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          isReadinessTest
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          meetingRoomProviderMeetingRoomsId
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        videoEnabled
        transcriptionEnabled
        product {
          id
          name
          nameLowerCase
          description
          serviceInformation
          organizationId
          salesTeamId
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProductsId
          teamProductsId
          __typename
        }
        productId
        isReadinessTest
        labels
        owner
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        transcriptions {
          nextToken
          startedAt
          __typename
        }
        sellerOrganizationName
        sellerTeamName
        buyerTeamName
        sellerOrganizationNameLowerCase
        sellerTeamNameLowerCase
        buyerTeamNameLowerCase
        externalId
        metrics
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productMeetingsId
        meetingRoomProviderMeetingsId
        meetingRoomId
        __typename
      }
      meetingId
      meetingInvite {
        id
        meetingId
        provider {
          id
          name
          componentName
          description
          configuration
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        providerId
        title
        startDateTime
        endDateTime
        status
        description
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        videoEnabled
        transcriptionEnabled
        product {
          id
          name
          nameLowerCase
          description
          serviceInformation
          organizationId
          salesTeamId
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProductsId
          teamProductsId
          __typename
        }
        productId
        isReadinessTest
        meetingRoomId
        providerSessionId
        providerJoinData
        sessionStatus
        actualStartDateTime
        actualEndDateTime
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        owner
        meetingInvitationType
        teamId
        isInterested
        feedbackSummary
        contactRequestTimeframe
        contactPhoneNumber
        contactEmail
        sellerOrganizationName
        sellerTeamName
        buyerTeamName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productMeetingInvitesId
        profileMeetingInvitesId
        meetingRoomProviderMeetingInvitesId
        __typename
      }
      meetingInviteId
      owner
      contactRequestTimeframe
      contactRequestDateTime
      contactPhoneNumber
      contactEmail
      contactName
      providePersonalEmail
      providePersonalPhoneNumber
      textOnly
      followupMeetingRequested
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      productQuestionResponsesId
      profileQuestionResponsesId
      meetingQuestionResponsesId
      meetingInviteQuestionResponsesId
      __typename
    }
  }
`;
export const updateQuestionResponse = /* GraphQL */ `
  mutation UpdateQuestionResponse(
    $input: UpdateQuestionResponseInput!
    $condition: ModelQuestionResponseConditionInput
  ) {
    updateQuestionResponse(input: $input, condition: $condition) {
      id
      response
      privateNotes
      question {
        id
        question
        product {
          id
          name
          nameLowerCase
          description
          serviceInformation
          organizationId
          salesTeamId
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProductsId
          teamProductsId
          __typename
        }
        productId
        responses {
          nextToken
          startedAt
          __typename
        }
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productQuestionsId
        __typename
      }
      questionId
      profile {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      profileId
      meeting {
        id
        title
        titleLowerCase
        startDateTime
        endDateTime
        status
        description
        preferredRoomProvider {
          id
          name
          componentName
          description
          configuration
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        preferredRoomProviderId
        actualStartDateTime
        actualEndDateTime
        room {
          id
          name
          meetingId
          providerId
          providerMeetingId
          accessCode
          status
          startDateTime
          endDateTime
          actualStartDateTime
          actualEndDateTime
          roomCreatedAt
          roomDestroyedAt
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          isReadinessTest
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          meetingRoomProviderMeetingRoomsId
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        videoEnabled
        transcriptionEnabled
        product {
          id
          name
          nameLowerCase
          description
          serviceInformation
          organizationId
          salesTeamId
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProductsId
          teamProductsId
          __typename
        }
        productId
        isReadinessTest
        labels
        owner
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        transcriptions {
          nextToken
          startedAt
          __typename
        }
        sellerOrganizationName
        sellerTeamName
        buyerTeamName
        sellerOrganizationNameLowerCase
        sellerTeamNameLowerCase
        buyerTeamNameLowerCase
        externalId
        metrics
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productMeetingsId
        meetingRoomProviderMeetingsId
        meetingRoomId
        __typename
      }
      meetingId
      meetingInvite {
        id
        meetingId
        provider {
          id
          name
          componentName
          description
          configuration
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        providerId
        title
        startDateTime
        endDateTime
        status
        description
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        videoEnabled
        transcriptionEnabled
        product {
          id
          name
          nameLowerCase
          description
          serviceInformation
          organizationId
          salesTeamId
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProductsId
          teamProductsId
          __typename
        }
        productId
        isReadinessTest
        meetingRoomId
        providerSessionId
        providerJoinData
        sessionStatus
        actualStartDateTime
        actualEndDateTime
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        owner
        meetingInvitationType
        teamId
        isInterested
        feedbackSummary
        contactRequestTimeframe
        contactPhoneNumber
        contactEmail
        sellerOrganizationName
        sellerTeamName
        buyerTeamName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productMeetingInvitesId
        profileMeetingInvitesId
        meetingRoomProviderMeetingInvitesId
        __typename
      }
      meetingInviteId
      owner
      contactRequestTimeframe
      contactRequestDateTime
      contactPhoneNumber
      contactEmail
      contactName
      providePersonalEmail
      providePersonalPhoneNumber
      textOnly
      followupMeetingRequested
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      productQuestionResponsesId
      profileQuestionResponsesId
      meetingQuestionResponsesId
      meetingInviteQuestionResponsesId
      __typename
    }
  }
`;
export const deleteQuestionResponse = /* GraphQL */ `
  mutation DeleteQuestionResponse(
    $input: DeleteQuestionResponseInput!
    $condition: ModelQuestionResponseConditionInput
  ) {
    deleteQuestionResponse(input: $input, condition: $condition) {
      id
      response
      privateNotes
      question {
        id
        question
        product {
          id
          name
          nameLowerCase
          description
          serviceInformation
          organizationId
          salesTeamId
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProductsId
          teamProductsId
          __typename
        }
        productId
        responses {
          nextToken
          startedAt
          __typename
        }
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productQuestionsId
        __typename
      }
      questionId
      profile {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      profileId
      meeting {
        id
        title
        titleLowerCase
        startDateTime
        endDateTime
        status
        description
        preferredRoomProvider {
          id
          name
          componentName
          description
          configuration
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        preferredRoomProviderId
        actualStartDateTime
        actualEndDateTime
        room {
          id
          name
          meetingId
          providerId
          providerMeetingId
          accessCode
          status
          startDateTime
          endDateTime
          actualStartDateTime
          actualEndDateTime
          roomCreatedAt
          roomDestroyedAt
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          isReadinessTest
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          meetingRoomProviderMeetingRoomsId
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        videoEnabled
        transcriptionEnabled
        product {
          id
          name
          nameLowerCase
          description
          serviceInformation
          organizationId
          salesTeamId
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProductsId
          teamProductsId
          __typename
        }
        productId
        isReadinessTest
        labels
        owner
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        transcriptions {
          nextToken
          startedAt
          __typename
        }
        sellerOrganizationName
        sellerTeamName
        buyerTeamName
        sellerOrganizationNameLowerCase
        sellerTeamNameLowerCase
        buyerTeamNameLowerCase
        externalId
        metrics
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productMeetingsId
        meetingRoomProviderMeetingsId
        meetingRoomId
        __typename
      }
      meetingId
      meetingInvite {
        id
        meetingId
        provider {
          id
          name
          componentName
          description
          configuration
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        providerId
        title
        startDateTime
        endDateTime
        status
        description
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        videoEnabled
        transcriptionEnabled
        product {
          id
          name
          nameLowerCase
          description
          serviceInformation
          organizationId
          salesTeamId
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProductsId
          teamProductsId
          __typename
        }
        productId
        isReadinessTest
        meetingRoomId
        providerSessionId
        providerJoinData
        sessionStatus
        actualStartDateTime
        actualEndDateTime
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        owner
        meetingInvitationType
        teamId
        isInterested
        feedbackSummary
        contactRequestTimeframe
        contactPhoneNumber
        contactEmail
        sellerOrganizationName
        sellerTeamName
        buyerTeamName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productMeetingInvitesId
        profileMeetingInvitesId
        meetingRoomProviderMeetingInvitesId
        __typename
      }
      meetingInviteId
      owner
      contactRequestTimeframe
      contactRequestDateTime
      contactPhoneNumber
      contactEmail
      contactName
      providePersonalEmail
      providePersonalPhoneNumber
      textOnly
      followupMeetingRequested
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      productQuestionResponsesId
      profileQuestionResponsesId
      meetingQuestionResponsesId
      meetingInviteQuestionResponsesId
      __typename
    }
  }
`;
export const createProductImage = /* GraphQL */ `
  mutation CreateProductImage(
    $input: CreateProductImageInput!
    $condition: ModelProductImageConditionInput
  ) {
    createProductImage(input: $input, condition: $condition) {
      id
      title
      alt
      width
      height
      image {
        bucket
        region
        key
        __typename
      }
      product {
        id
        name
        nameLowerCase
        description
        serviceInformation
        images {
          nextToken
          startedAt
          __typename
        }
        questions {
          nextToken
          startedAt
          __typename
        }
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        salesTeam {
          id
          name
          nameLowerCase
          description
          labels
          privacyEnabled
          privacyEnableOtherTeams
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        salesTeamId
        meetings {
          nextToken
          startedAt
          __typename
        }
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        labels
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProductsId
        teamProductsId
        __typename
      }
      productId
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      productImagesId
      __typename
    }
  }
`;
export const updateProductImage = /* GraphQL */ `
  mutation UpdateProductImage(
    $input: UpdateProductImageInput!
    $condition: ModelProductImageConditionInput
  ) {
    updateProductImage(input: $input, condition: $condition) {
      id
      title
      alt
      width
      height
      image {
        bucket
        region
        key
        __typename
      }
      product {
        id
        name
        nameLowerCase
        description
        serviceInformation
        images {
          nextToken
          startedAt
          __typename
        }
        questions {
          nextToken
          startedAt
          __typename
        }
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        salesTeam {
          id
          name
          nameLowerCase
          description
          labels
          privacyEnabled
          privacyEnableOtherTeams
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        salesTeamId
        meetings {
          nextToken
          startedAt
          __typename
        }
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        labels
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProductsId
        teamProductsId
        __typename
      }
      productId
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      productImagesId
      __typename
    }
  }
`;
export const deleteProductImage = /* GraphQL */ `
  mutation DeleteProductImage(
    $input: DeleteProductImageInput!
    $condition: ModelProductImageConditionInput
  ) {
    deleteProductImage(input: $input, condition: $condition) {
      id
      title
      alt
      width
      height
      image {
        bucket
        region
        key
        __typename
      }
      product {
        id
        name
        nameLowerCase
        description
        serviceInformation
        images {
          nextToken
          startedAt
          __typename
        }
        questions {
          nextToken
          startedAt
          __typename
        }
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        salesTeam {
          id
          name
          nameLowerCase
          description
          labels
          privacyEnabled
          privacyEnableOtherTeams
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        salesTeamId
        meetings {
          nextToken
          startedAt
          __typename
        }
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        labels
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProductsId
        teamProductsId
        __typename
      }
      productId
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      productImagesId
      __typename
    }
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      id
      name
      nameLowerCase
      description
      serviceInformation
      images {
        items {
          id
          title
          alt
          width
          height
          productId
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productImagesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      questions {
        items {
          id
          question
          productId
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productQuestionsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      organization {
        id
        externalId
        name
        nameLowerCase
        description
        url
        phoneNumber
        locations {
          nextToken
          startedAt
          __typename
        }
        profiles {
          nextToken
          startedAt
          __typename
        }
        products {
          nextToken
          startedAt
          __typename
        }
        labels
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      organizationId
      salesTeam {
        id
        name
        nameLowerCase
        description
        memberRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingRelationships {
          nextToken
          startedAt
          __typename
        }
        products {
          nextToken
          startedAt
          __typename
        }
        labels
        privacyEnabled
        privacyEnableOtherTeams
        images {
          nextToken
          startedAt
          __typename
        }
        transcriptions {
          nextToken
          startedAt
          __typename
        }
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      salesTeamId
      meetings {
        items {
          id
          title
          titleLowerCase
          startDateTime
          endDateTime
          status
          description
          preferredRoomProviderId
          actualStartDateTime
          actualEndDateTime
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          productId
          isReadinessTest
          labels
          owner
          sellerOrganizationName
          sellerTeamName
          buyerTeamName
          sellerOrganizationNameLowerCase
          sellerTeamNameLowerCase
          buyerTeamNameLowerCase
          externalId
          metrics
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productMeetingsId
          meetingRoomProviderMeetingsId
          meetingRoomId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      meetingInvites {
        items {
          id
          meetingId
          providerId
          title
          startDateTime
          endDateTime
          status
          description
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          productId
          isReadinessTest
          meetingRoomId
          providerSessionId
          providerJoinData
          sessionStatus
          actualStartDateTime
          actualEndDateTime
          profileId
          owner
          meetingInvitationType
          teamId
          isInterested
          feedbackSummary
          contactRequestTimeframe
          contactPhoneNumber
          contactEmail
          sellerOrganizationName
          sellerTeamName
          buyerTeamName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productMeetingInvitesId
          profileMeetingInvitesId
          meetingRoomProviderMeetingInvitesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      labels
      owner
      sortByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      organizationProductsId
      teamProductsId
      __typename
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      id
      name
      nameLowerCase
      description
      serviceInformation
      images {
        items {
          id
          title
          alt
          width
          height
          productId
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productImagesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      questions {
        items {
          id
          question
          productId
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productQuestionsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      organization {
        id
        externalId
        name
        nameLowerCase
        description
        url
        phoneNumber
        locations {
          nextToken
          startedAt
          __typename
        }
        profiles {
          nextToken
          startedAt
          __typename
        }
        products {
          nextToken
          startedAt
          __typename
        }
        labels
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      organizationId
      salesTeam {
        id
        name
        nameLowerCase
        description
        memberRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingRelationships {
          nextToken
          startedAt
          __typename
        }
        products {
          nextToken
          startedAt
          __typename
        }
        labels
        privacyEnabled
        privacyEnableOtherTeams
        images {
          nextToken
          startedAt
          __typename
        }
        transcriptions {
          nextToken
          startedAt
          __typename
        }
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      salesTeamId
      meetings {
        items {
          id
          title
          titleLowerCase
          startDateTime
          endDateTime
          status
          description
          preferredRoomProviderId
          actualStartDateTime
          actualEndDateTime
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          productId
          isReadinessTest
          labels
          owner
          sellerOrganizationName
          sellerTeamName
          buyerTeamName
          sellerOrganizationNameLowerCase
          sellerTeamNameLowerCase
          buyerTeamNameLowerCase
          externalId
          metrics
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productMeetingsId
          meetingRoomProviderMeetingsId
          meetingRoomId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      meetingInvites {
        items {
          id
          meetingId
          providerId
          title
          startDateTime
          endDateTime
          status
          description
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          productId
          isReadinessTest
          meetingRoomId
          providerSessionId
          providerJoinData
          sessionStatus
          actualStartDateTime
          actualEndDateTime
          profileId
          owner
          meetingInvitationType
          teamId
          isInterested
          feedbackSummary
          contactRequestTimeframe
          contactPhoneNumber
          contactEmail
          sellerOrganizationName
          sellerTeamName
          buyerTeamName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productMeetingInvitesId
          profileMeetingInvitesId
          meetingRoomProviderMeetingInvitesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      labels
      owner
      sortByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      organizationProductsId
      teamProductsId
      __typename
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) {
      id
      name
      nameLowerCase
      description
      serviceInformation
      images {
        items {
          id
          title
          alt
          width
          height
          productId
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productImagesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      questions {
        items {
          id
          question
          productId
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productQuestionsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      organization {
        id
        externalId
        name
        nameLowerCase
        description
        url
        phoneNumber
        locations {
          nextToken
          startedAt
          __typename
        }
        profiles {
          nextToken
          startedAt
          __typename
        }
        products {
          nextToken
          startedAt
          __typename
        }
        labels
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      organizationId
      salesTeam {
        id
        name
        nameLowerCase
        description
        memberRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingRelationships {
          nextToken
          startedAt
          __typename
        }
        products {
          nextToken
          startedAt
          __typename
        }
        labels
        privacyEnabled
        privacyEnableOtherTeams
        images {
          nextToken
          startedAt
          __typename
        }
        transcriptions {
          nextToken
          startedAt
          __typename
        }
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      salesTeamId
      meetings {
        items {
          id
          title
          titleLowerCase
          startDateTime
          endDateTime
          status
          description
          preferredRoomProviderId
          actualStartDateTime
          actualEndDateTime
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          productId
          isReadinessTest
          labels
          owner
          sellerOrganizationName
          sellerTeamName
          buyerTeamName
          sellerOrganizationNameLowerCase
          sellerTeamNameLowerCase
          buyerTeamNameLowerCase
          externalId
          metrics
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productMeetingsId
          meetingRoomProviderMeetingsId
          meetingRoomId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      meetingInvites {
        items {
          id
          meetingId
          providerId
          title
          startDateTime
          endDateTime
          status
          description
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          productId
          isReadinessTest
          meetingRoomId
          providerSessionId
          providerJoinData
          sessionStatus
          actualStartDateTime
          actualEndDateTime
          profileId
          owner
          meetingInvitationType
          teamId
          isInterested
          feedbackSummary
          contactRequestTimeframe
          contactPhoneNumber
          contactEmail
          sellerOrganizationName
          sellerTeamName
          buyerTeamName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productMeetingInvitesId
          profileMeetingInvitesId
          meetingRoomProviderMeetingInvitesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      labels
      owner
      sortByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      organizationProductsId
      teamProductsId
      __typename
    }
  }
`;
export const createOrganizationLocation = /* GraphQL */ `
  mutation CreateOrganizationLocation(
    $input: CreateOrganizationLocationInput!
    $condition: ModelOrganizationLocationConditionInput
  ) {
    createOrganizationLocation(input: $input, condition: $condition) {
      id
      title
      address
      city
      state
      country
      organization {
        id
        externalId
        name
        nameLowerCase
        description
        url
        phoneNumber
        locations {
          nextToken
          startedAt
          __typename
        }
        profiles {
          nextToken
          startedAt
          __typename
        }
        products {
          nextToken
          startedAt
          __typename
        }
        labels
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      organizationId
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      organizationLocationsId
      __typename
    }
  }
`;
export const updateOrganizationLocation = /* GraphQL */ `
  mutation UpdateOrganizationLocation(
    $input: UpdateOrganizationLocationInput!
    $condition: ModelOrganizationLocationConditionInput
  ) {
    updateOrganizationLocation(input: $input, condition: $condition) {
      id
      title
      address
      city
      state
      country
      organization {
        id
        externalId
        name
        nameLowerCase
        description
        url
        phoneNumber
        locations {
          nextToken
          startedAt
          __typename
        }
        profiles {
          nextToken
          startedAt
          __typename
        }
        products {
          nextToken
          startedAt
          __typename
        }
        labels
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      organizationId
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      organizationLocationsId
      __typename
    }
  }
`;
export const deleteOrganizationLocation = /* GraphQL */ `
  mutation DeleteOrganizationLocation(
    $input: DeleteOrganizationLocationInput!
    $condition: ModelOrganizationLocationConditionInput
  ) {
    deleteOrganizationLocation(input: $input, condition: $condition) {
      id
      title
      address
      city
      state
      country
      organization {
        id
        externalId
        name
        nameLowerCase
        description
        url
        phoneNumber
        locations {
          nextToken
          startedAt
          __typename
        }
        profiles {
          nextToken
          startedAt
          __typename
        }
        products {
          nextToken
          startedAt
          __typename
        }
        labels
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      organizationId
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      organizationLocationsId
      __typename
    }
  }
`;
export const createOrganization = /* GraphQL */ `
  mutation CreateOrganization(
    $input: CreateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    createOrganization(input: $input, condition: $condition) {
      id
      externalId
      name
      nameLowerCase
      description
      url
      phoneNumber
      locations {
        items {
          id
          title
          address
          city
          state
          country
          organizationId
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationLocationsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      profiles {
        items {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      products {
        items {
          id
          name
          nameLowerCase
          description
          serviceInformation
          organizationId
          salesTeamId
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProductsId
          teamProductsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      labels
      owner
      sortByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateOrganization = /* GraphQL */ `
  mutation UpdateOrganization(
    $input: UpdateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    updateOrganization(input: $input, condition: $condition) {
      id
      externalId
      name
      nameLowerCase
      description
      url
      phoneNumber
      locations {
        items {
          id
          title
          address
          city
          state
          country
          organizationId
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationLocationsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      profiles {
        items {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      products {
        items {
          id
          name
          nameLowerCase
          description
          serviceInformation
          organizationId
          salesTeamId
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProductsId
          teamProductsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      labels
      owner
      sortByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteOrganization = /* GraphQL */ `
  mutation DeleteOrganization(
    $input: DeleteOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    deleteOrganization(input: $input, condition: $condition) {
      id
      externalId
      name
      nameLowerCase
      description
      url
      phoneNumber
      locations {
        items {
          id
          title
          address
          city
          state
          country
          organizationId
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationLocationsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      profiles {
        items {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      products {
        items {
          id
          name
          nameLowerCase
          description
          serviceInformation
          organizationId
          salesTeamId
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProductsId
          teamProductsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      labels
      owner
      sortByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createProfileLocation = /* GraphQL */ `
  mutation CreateProfileLocation(
    $input: CreateProfileLocationInput!
    $condition: ModelProfileLocationConditionInput
  ) {
    createProfileLocation(input: $input, condition: $condition) {
      id
      title
      address
      city
      state
      country
      profile {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      profileId
      isPrimary
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      profileLocationsId
      __typename
    }
  }
`;
export const updateProfileLocation = /* GraphQL */ `
  mutation UpdateProfileLocation(
    $input: UpdateProfileLocationInput!
    $condition: ModelProfileLocationConditionInput
  ) {
    updateProfileLocation(input: $input, condition: $condition) {
      id
      title
      address
      city
      state
      country
      profile {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      profileId
      isPrimary
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      profileLocationsId
      __typename
    }
  }
`;
export const deleteProfileLocation = /* GraphQL */ `
  mutation DeleteProfileLocation(
    $input: DeleteProfileLocationInput!
    $condition: ModelProfileLocationConditionInput
  ) {
    deleteProfileLocation(input: $input, condition: $condition) {
      id
      title
      address
      city
      state
      country
      profile {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      profileId
      isPrimary
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      profileLocationsId
      __typename
    }
  }
`;
export const createProfileImage = /* GraphQL */ `
  mutation CreateProfileImage(
    $input: CreateProfileImageInput!
    $condition: ModelProfileImageConditionInput
  ) {
    createProfileImage(input: $input, condition: $condition) {
      id
      title
      alt
      width
      height
      image {
        bucket
        region
        key
        __typename
      }
      profile {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      profileId
      isDefault
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      profileImagesId
      __typename
    }
  }
`;
export const updateProfileImage = /* GraphQL */ `
  mutation UpdateProfileImage(
    $input: UpdateProfileImageInput!
    $condition: ModelProfileImageConditionInput
  ) {
    updateProfileImage(input: $input, condition: $condition) {
      id
      title
      alt
      width
      height
      image {
        bucket
        region
        key
        __typename
      }
      profile {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      profileId
      isDefault
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      profileImagesId
      __typename
    }
  }
`;
export const deleteProfileImage = /* GraphQL */ `
  mutation DeleteProfileImage(
    $input: DeleteProfileImageInput!
    $condition: ModelProfileImageConditionInput
  ) {
    deleteProfileImage(input: $input, condition: $condition) {
      id
      title
      alt
      width
      height
      image {
        bucket
        region
        key
        __typename
      }
      profile {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      profileId
      isDefault
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      profileImagesId
      __typename
    }
  }
`;
export const createForwardEmail = /* GraphQL */ `
  mutation CreateForwardEmail(
    $input: CreateForwardEmailInput!
    $condition: ModelForwardEmailConditionInput
  ) {
    createForwardEmail(input: $input, condition: $condition) {
      id
      received
      remaining
      publicEmail
      expireAt
      privateEmail
      publicReplyEmail
      profile {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      profileId
      sellerProfile {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      sellerProfileId
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      profileForwardEmailsId
      profileSellerForwardEmailsId
      __typename
    }
  }
`;
export const updateForwardEmail = /* GraphQL */ `
  mutation UpdateForwardEmail(
    $input: UpdateForwardEmailInput!
    $condition: ModelForwardEmailConditionInput
  ) {
    updateForwardEmail(input: $input, condition: $condition) {
      id
      received
      remaining
      publicEmail
      expireAt
      privateEmail
      publicReplyEmail
      profile {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      profileId
      sellerProfile {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      sellerProfileId
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      profileForwardEmailsId
      profileSellerForwardEmailsId
      __typename
    }
  }
`;
export const deleteForwardEmail = /* GraphQL */ `
  mutation DeleteForwardEmail(
    $input: DeleteForwardEmailInput!
    $condition: ModelForwardEmailConditionInput
  ) {
    deleteForwardEmail(input: $input, condition: $condition) {
      id
      received
      remaining
      publicEmail
      expireAt
      privateEmail
      publicReplyEmail
      profile {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      profileId
      sellerProfile {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      sellerProfileId
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      profileForwardEmailsId
      profileSellerForwardEmailsId
      __typename
    }
  }
`;
export const createProfile = /* GraphQL */ `
  mutation CreateProfile(
    $input: CreateProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    createProfile(input: $input, condition: $condition) {
      id
      fullName
      fullNameLowerCase
      email
      externalId
      userId
      occupation
      profile
      phoneNumber
      organization {
        id
        externalId
        name
        nameLowerCase
        description
        url
        phoneNumber
        locations {
          nextToken
          startedAt
          __typename
        }
        profiles {
          nextToken
          startedAt
          __typename
        }
        products {
          nextToken
          startedAt
          __typename
        }
        labels
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      organizationId
      auditLogs {
        items {
          id
          date
          author
          resource
          action
          payload
          ipAddress
          meetingRoomId
          profileId
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileAuditLogsId
          meetingRoomAuditlogsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      jobAuditLogs {
        items {
          id
          date
          author
          resource
          action
          payload
          ipAddress
          importJobId
          profileId
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileJobAuditLogsId
          importJobAuditLogsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      exportJobAuditLogs {
        items {
          id
          date
          author
          resource
          action
          payload
          ipAddress
          exportJobId
          profileId
          owner
          identityId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileExportJobAuditLogsId
          exportJobAuditLogsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      teamRelationships {
        items {
          id
          teamId
          memberProfileId
          relationship
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileTeamRelationshipsId
          teamMemberRelationshipsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      meetingAttendees {
        items {
          id
          profileId
          meetingId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileMeetingAttendeesId
          meetingMeetingAttendeesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      locations {
        items {
          id
          title
          address
          city
          state
          country
          profileId
          isPrimary
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileLocationsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      devices {
        items {
          id
          deviceHash
          hasWebcam
          hasMicrophone
          isScreenCapturingSupported
          isWebRTCSupported
          isWebsiteHasMicrophonePermissions
          isWebsiteHasWebcamPermissions
          isWebSocketsSupported
          clockOffset
          lastSeen
          lastLogin
          lastMeetingId
          lastMeetingInviteId
          lastMeetingJoin
          lastMeetingStatus
          meetingData
          userAgent
          userAgentSummary
          micStatus
          cameraStatus
          networkQuality
          profileId
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileDevicesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      images {
        items {
          id
          title
          alt
          width
          height
          profileId
          isDefault
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileImagesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      questionResponses {
        items {
          id
          response
          privateNotes
          questionId
          profileId
          meetingId
          meetingInviteId
          owner
          contactRequestTimeframe
          contactRequestDateTime
          contactPhoneNumber
          contactEmail
          contactName
          providePersonalEmail
          providePersonalPhoneNumber
          textOnly
          followupMeetingRequested
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productQuestionResponsesId
          profileQuestionResponsesId
          meetingQuestionResponsesId
          meetingInviteQuestionResponsesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      importJobs {
        items {
          id
          status
          identityId
          result
          profileId
          lastStartDateTime
          lastEndDateTime
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileImportJobsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      exportJobs {
        items {
          id
          status
          profileId
          meetingInvitationType
          startDateTime
          endDateTime
          owner
          identityId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileExportJobsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      privacyEnabled
      owner
      meetingInvites {
        items {
          id
          meetingId
          providerId
          title
          startDateTime
          endDateTime
          status
          description
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          productId
          isReadinessTest
          meetingRoomId
          providerSessionId
          providerJoinData
          sessionStatus
          actualStartDateTime
          actualEndDateTime
          profileId
          owner
          meetingInvitationType
          teamId
          isInterested
          feedbackSummary
          contactRequestTimeframe
          contactPhoneNumber
          contactEmail
          sellerOrganizationName
          sellerTeamName
          buyerTeamName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productMeetingInvitesId
          profileMeetingInvitesId
          meetingRoomProviderMeetingInvitesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      sortByFullName
      transcriptionLogs {
        items {
          id
          date
          author
          resource
          action
          payload
          ipAddress
          meetingInviteId
          profileId
          authorProfileId
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileTranscriptionLogsId
          meetingInviteTranscriptionLogsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      timeZone
      forwardEmails {
        items {
          id
          received
          remaining
          publicEmail
          expireAt
          privateEmail
          publicReplyEmail
          profileId
          sellerProfileId
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileForwardEmailsId
          profileSellerForwardEmailsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      sellerForwardEmails {
        items {
          id
          received
          remaining
          publicEmail
          expireAt
          privateEmail
          publicReplyEmail
          profileId
          sellerProfileId
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileForwardEmailsId
          profileSellerForwardEmailsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      organizationProfilesId
      __typename
    }
  }
`;
export const updateProfile = /* GraphQL */ `
  mutation UpdateProfile(
    $input: UpdateProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    updateProfile(input: $input, condition: $condition) {
      id
      fullName
      fullNameLowerCase
      email
      externalId
      userId
      occupation
      profile
      phoneNumber
      organization {
        id
        externalId
        name
        nameLowerCase
        description
        url
        phoneNumber
        locations {
          nextToken
          startedAt
          __typename
        }
        profiles {
          nextToken
          startedAt
          __typename
        }
        products {
          nextToken
          startedAt
          __typename
        }
        labels
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      organizationId
      auditLogs {
        items {
          id
          date
          author
          resource
          action
          payload
          ipAddress
          meetingRoomId
          profileId
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileAuditLogsId
          meetingRoomAuditlogsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      jobAuditLogs {
        items {
          id
          date
          author
          resource
          action
          payload
          ipAddress
          importJobId
          profileId
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileJobAuditLogsId
          importJobAuditLogsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      exportJobAuditLogs {
        items {
          id
          date
          author
          resource
          action
          payload
          ipAddress
          exportJobId
          profileId
          owner
          identityId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileExportJobAuditLogsId
          exportJobAuditLogsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      teamRelationships {
        items {
          id
          teamId
          memberProfileId
          relationship
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileTeamRelationshipsId
          teamMemberRelationshipsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      meetingAttendees {
        items {
          id
          profileId
          meetingId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileMeetingAttendeesId
          meetingMeetingAttendeesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      locations {
        items {
          id
          title
          address
          city
          state
          country
          profileId
          isPrimary
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileLocationsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      devices {
        items {
          id
          deviceHash
          hasWebcam
          hasMicrophone
          isScreenCapturingSupported
          isWebRTCSupported
          isWebsiteHasMicrophonePermissions
          isWebsiteHasWebcamPermissions
          isWebSocketsSupported
          clockOffset
          lastSeen
          lastLogin
          lastMeetingId
          lastMeetingInviteId
          lastMeetingJoin
          lastMeetingStatus
          meetingData
          userAgent
          userAgentSummary
          micStatus
          cameraStatus
          networkQuality
          profileId
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileDevicesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      images {
        items {
          id
          title
          alt
          width
          height
          profileId
          isDefault
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileImagesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      questionResponses {
        items {
          id
          response
          privateNotes
          questionId
          profileId
          meetingId
          meetingInviteId
          owner
          contactRequestTimeframe
          contactRequestDateTime
          contactPhoneNumber
          contactEmail
          contactName
          providePersonalEmail
          providePersonalPhoneNumber
          textOnly
          followupMeetingRequested
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productQuestionResponsesId
          profileQuestionResponsesId
          meetingQuestionResponsesId
          meetingInviteQuestionResponsesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      importJobs {
        items {
          id
          status
          identityId
          result
          profileId
          lastStartDateTime
          lastEndDateTime
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileImportJobsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      exportJobs {
        items {
          id
          status
          profileId
          meetingInvitationType
          startDateTime
          endDateTime
          owner
          identityId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileExportJobsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      privacyEnabled
      owner
      meetingInvites {
        items {
          id
          meetingId
          providerId
          title
          startDateTime
          endDateTime
          status
          description
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          productId
          isReadinessTest
          meetingRoomId
          providerSessionId
          providerJoinData
          sessionStatus
          actualStartDateTime
          actualEndDateTime
          profileId
          owner
          meetingInvitationType
          teamId
          isInterested
          feedbackSummary
          contactRequestTimeframe
          contactPhoneNumber
          contactEmail
          sellerOrganizationName
          sellerTeamName
          buyerTeamName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productMeetingInvitesId
          profileMeetingInvitesId
          meetingRoomProviderMeetingInvitesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      sortByFullName
      transcriptionLogs {
        items {
          id
          date
          author
          resource
          action
          payload
          ipAddress
          meetingInviteId
          profileId
          authorProfileId
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileTranscriptionLogsId
          meetingInviteTranscriptionLogsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      timeZone
      forwardEmails {
        items {
          id
          received
          remaining
          publicEmail
          expireAt
          privateEmail
          publicReplyEmail
          profileId
          sellerProfileId
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileForwardEmailsId
          profileSellerForwardEmailsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      sellerForwardEmails {
        items {
          id
          received
          remaining
          publicEmail
          expireAt
          privateEmail
          publicReplyEmail
          profileId
          sellerProfileId
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileForwardEmailsId
          profileSellerForwardEmailsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      organizationProfilesId
      __typename
    }
  }
`;
export const deleteProfile = /* GraphQL */ `
  mutation DeleteProfile(
    $input: DeleteProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    deleteProfile(input: $input, condition: $condition) {
      id
      fullName
      fullNameLowerCase
      email
      externalId
      userId
      occupation
      profile
      phoneNumber
      organization {
        id
        externalId
        name
        nameLowerCase
        description
        url
        phoneNumber
        locations {
          nextToken
          startedAt
          __typename
        }
        profiles {
          nextToken
          startedAt
          __typename
        }
        products {
          nextToken
          startedAt
          __typename
        }
        labels
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      organizationId
      auditLogs {
        items {
          id
          date
          author
          resource
          action
          payload
          ipAddress
          meetingRoomId
          profileId
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileAuditLogsId
          meetingRoomAuditlogsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      jobAuditLogs {
        items {
          id
          date
          author
          resource
          action
          payload
          ipAddress
          importJobId
          profileId
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileJobAuditLogsId
          importJobAuditLogsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      exportJobAuditLogs {
        items {
          id
          date
          author
          resource
          action
          payload
          ipAddress
          exportJobId
          profileId
          owner
          identityId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileExportJobAuditLogsId
          exportJobAuditLogsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      teamRelationships {
        items {
          id
          teamId
          memberProfileId
          relationship
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileTeamRelationshipsId
          teamMemberRelationshipsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      meetingAttendees {
        items {
          id
          profileId
          meetingId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileMeetingAttendeesId
          meetingMeetingAttendeesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      locations {
        items {
          id
          title
          address
          city
          state
          country
          profileId
          isPrimary
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileLocationsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      devices {
        items {
          id
          deviceHash
          hasWebcam
          hasMicrophone
          isScreenCapturingSupported
          isWebRTCSupported
          isWebsiteHasMicrophonePermissions
          isWebsiteHasWebcamPermissions
          isWebSocketsSupported
          clockOffset
          lastSeen
          lastLogin
          lastMeetingId
          lastMeetingInviteId
          lastMeetingJoin
          lastMeetingStatus
          meetingData
          userAgent
          userAgentSummary
          micStatus
          cameraStatus
          networkQuality
          profileId
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileDevicesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      images {
        items {
          id
          title
          alt
          width
          height
          profileId
          isDefault
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileImagesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      questionResponses {
        items {
          id
          response
          privateNotes
          questionId
          profileId
          meetingId
          meetingInviteId
          owner
          contactRequestTimeframe
          contactRequestDateTime
          contactPhoneNumber
          contactEmail
          contactName
          providePersonalEmail
          providePersonalPhoneNumber
          textOnly
          followupMeetingRequested
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productQuestionResponsesId
          profileQuestionResponsesId
          meetingQuestionResponsesId
          meetingInviteQuestionResponsesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      importJobs {
        items {
          id
          status
          identityId
          result
          profileId
          lastStartDateTime
          lastEndDateTime
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileImportJobsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      exportJobs {
        items {
          id
          status
          profileId
          meetingInvitationType
          startDateTime
          endDateTime
          owner
          identityId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileExportJobsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      privacyEnabled
      owner
      meetingInvites {
        items {
          id
          meetingId
          providerId
          title
          startDateTime
          endDateTime
          status
          description
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          productId
          isReadinessTest
          meetingRoomId
          providerSessionId
          providerJoinData
          sessionStatus
          actualStartDateTime
          actualEndDateTime
          profileId
          owner
          meetingInvitationType
          teamId
          isInterested
          feedbackSummary
          contactRequestTimeframe
          contactPhoneNumber
          contactEmail
          sellerOrganizationName
          sellerTeamName
          buyerTeamName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productMeetingInvitesId
          profileMeetingInvitesId
          meetingRoomProviderMeetingInvitesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      sortByFullName
      transcriptionLogs {
        items {
          id
          date
          author
          resource
          action
          payload
          ipAddress
          meetingInviteId
          profileId
          authorProfileId
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileTranscriptionLogsId
          meetingInviteTranscriptionLogsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      timeZone
      forwardEmails {
        items {
          id
          received
          remaining
          publicEmail
          expireAt
          privateEmail
          publicReplyEmail
          profileId
          sellerProfileId
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileForwardEmailsId
          profileSellerForwardEmailsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      sellerForwardEmails {
        items {
          id
          received
          remaining
          publicEmail
          expireAt
          privateEmail
          publicReplyEmail
          profileId
          sellerProfileId
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileForwardEmailsId
          profileSellerForwardEmailsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      organizationProfilesId
      __typename
    }
  }
`;
export const createProfileDevice = /* GraphQL */ `
  mutation CreateProfileDevice(
    $input: CreateProfileDeviceInput!
    $condition: ModelProfileDeviceConditionInput
  ) {
    createProfileDevice(input: $input, condition: $condition) {
      id
      deviceHash
      hasWebcam
      hasMicrophone
      isScreenCapturingSupported
      isWebRTCSupported
      isWebsiteHasMicrophonePermissions
      isWebsiteHasWebcamPermissions
      isWebSocketsSupported
      clockOffset
      lastSeen
      lastLogin
      lastMeetingId
      lastMeetingInviteId
      lastMeetingJoin
      lastMeetingStatus
      meetingData
      userAgent
      userAgentSummary
      micStatus
      cameraStatus
      networkQuality
      profile {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      profileId
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      profileDevicesId
      __typename
    }
  }
`;
export const updateProfileDevice = /* GraphQL */ `
  mutation UpdateProfileDevice(
    $input: UpdateProfileDeviceInput!
    $condition: ModelProfileDeviceConditionInput
  ) {
    updateProfileDevice(input: $input, condition: $condition) {
      id
      deviceHash
      hasWebcam
      hasMicrophone
      isScreenCapturingSupported
      isWebRTCSupported
      isWebsiteHasMicrophonePermissions
      isWebsiteHasWebcamPermissions
      isWebSocketsSupported
      clockOffset
      lastSeen
      lastLogin
      lastMeetingId
      lastMeetingInviteId
      lastMeetingJoin
      lastMeetingStatus
      meetingData
      userAgent
      userAgentSummary
      micStatus
      cameraStatus
      networkQuality
      profile {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      profileId
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      profileDevicesId
      __typename
    }
  }
`;
export const deleteProfileDevice = /* GraphQL */ `
  mutation DeleteProfileDevice(
    $input: DeleteProfileDeviceInput!
    $condition: ModelProfileDeviceConditionInput
  ) {
    deleteProfileDevice(input: $input, condition: $condition) {
      id
      deviceHash
      hasWebcam
      hasMicrophone
      isScreenCapturingSupported
      isWebRTCSupported
      isWebsiteHasMicrophonePermissions
      isWebsiteHasWebcamPermissions
      isWebSocketsSupported
      clockOffset
      lastSeen
      lastLogin
      lastMeetingId
      lastMeetingInviteId
      lastMeetingJoin
      lastMeetingStatus
      meetingData
      userAgent
      userAgentSummary
      micStatus
      cameraStatus
      networkQuality
      profile {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      profileId
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      profileDevicesId
      __typename
    }
  }
`;
export const createTeamImage = /* GraphQL */ `
  mutation CreateTeamImage(
    $input: CreateTeamImageInput!
    $condition: ModelTeamImageConditionInput
  ) {
    createTeamImage(input: $input, condition: $condition) {
      id
      title
      alt
      width
      height
      image {
        bucket
        region
        key
        __typename
      }
      team {
        id
        name
        nameLowerCase
        description
        memberRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingRelationships {
          nextToken
          startedAt
          __typename
        }
        products {
          nextToken
          startedAt
          __typename
        }
        labels
        privacyEnabled
        privacyEnableOtherTeams
        images {
          nextToken
          startedAt
          __typename
        }
        transcriptions {
          nextToken
          startedAt
          __typename
        }
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      teamId
      isDefault
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      teamImagesId
      __typename
    }
  }
`;
export const updateTeamImage = /* GraphQL */ `
  mutation UpdateTeamImage(
    $input: UpdateTeamImageInput!
    $condition: ModelTeamImageConditionInput
  ) {
    updateTeamImage(input: $input, condition: $condition) {
      id
      title
      alt
      width
      height
      image {
        bucket
        region
        key
        __typename
      }
      team {
        id
        name
        nameLowerCase
        description
        memberRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingRelationships {
          nextToken
          startedAt
          __typename
        }
        products {
          nextToken
          startedAt
          __typename
        }
        labels
        privacyEnabled
        privacyEnableOtherTeams
        images {
          nextToken
          startedAt
          __typename
        }
        transcriptions {
          nextToken
          startedAt
          __typename
        }
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      teamId
      isDefault
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      teamImagesId
      __typename
    }
  }
`;
export const deleteTeamImage = /* GraphQL */ `
  mutation DeleteTeamImage(
    $input: DeleteTeamImageInput!
    $condition: ModelTeamImageConditionInput
  ) {
    deleteTeamImage(input: $input, condition: $condition) {
      id
      title
      alt
      width
      height
      image {
        bucket
        region
        key
        __typename
      }
      team {
        id
        name
        nameLowerCase
        description
        memberRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingRelationships {
          nextToken
          startedAt
          __typename
        }
        products {
          nextToken
          startedAt
          __typename
        }
        labels
        privacyEnabled
        privacyEnableOtherTeams
        images {
          nextToken
          startedAt
          __typename
        }
        transcriptions {
          nextToken
          startedAt
          __typename
        }
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      teamId
      isDefault
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      teamImagesId
      __typename
    }
  }
`;
export const createTeam = /* GraphQL */ `
  mutation CreateTeam(
    $input: CreateTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    createTeam(input: $input, condition: $condition) {
      id
      name
      nameLowerCase
      description
      memberRelationships {
        items {
          id
          teamId
          memberProfileId
          relationship
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileTeamRelationshipsId
          teamMemberRelationshipsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      meetingRelationships {
        items {
          id
          teamId
          meetingId
          relationshipType
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          teamMeetingRelationshipsId
          meetingTeamRelationshipsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      products {
        items {
          id
          name
          nameLowerCase
          description
          serviceInformation
          organizationId
          salesTeamId
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProductsId
          teamProductsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      labels
      privacyEnabled
      privacyEnableOtherTeams
      images {
        items {
          id
          title
          alt
          width
          height
          teamId
          isDefault
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          teamImagesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      transcriptions {
        items {
          id
          date
          title
          description
          startDateTime
          endDateTime
          sizeInBytes
          numberOfTranscriptions
          isZipFile
          meetingId
          teamId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          teamTranscriptionsId
          meetingTranscriptionsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      owner
      sortByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateTeam = /* GraphQL */ `
  mutation UpdateTeam(
    $input: UpdateTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    updateTeam(input: $input, condition: $condition) {
      id
      name
      nameLowerCase
      description
      memberRelationships {
        items {
          id
          teamId
          memberProfileId
          relationship
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileTeamRelationshipsId
          teamMemberRelationshipsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      meetingRelationships {
        items {
          id
          teamId
          meetingId
          relationshipType
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          teamMeetingRelationshipsId
          meetingTeamRelationshipsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      products {
        items {
          id
          name
          nameLowerCase
          description
          serviceInformation
          organizationId
          salesTeamId
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProductsId
          teamProductsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      labels
      privacyEnabled
      privacyEnableOtherTeams
      images {
        items {
          id
          title
          alt
          width
          height
          teamId
          isDefault
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          teamImagesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      transcriptions {
        items {
          id
          date
          title
          description
          startDateTime
          endDateTime
          sizeInBytes
          numberOfTranscriptions
          isZipFile
          meetingId
          teamId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          teamTranscriptionsId
          meetingTranscriptionsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      owner
      sortByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteTeam = /* GraphQL */ `
  mutation DeleteTeam(
    $input: DeleteTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    deleteTeam(input: $input, condition: $condition) {
      id
      name
      nameLowerCase
      description
      memberRelationships {
        items {
          id
          teamId
          memberProfileId
          relationship
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileTeamRelationshipsId
          teamMemberRelationshipsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      meetingRelationships {
        items {
          id
          teamId
          meetingId
          relationshipType
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          teamMeetingRelationshipsId
          meetingTeamRelationshipsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      products {
        items {
          id
          name
          nameLowerCase
          description
          serviceInformation
          organizationId
          salesTeamId
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProductsId
          teamProductsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      labels
      privacyEnabled
      privacyEnableOtherTeams
      images {
        items {
          id
          title
          alt
          width
          height
          teamId
          isDefault
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          teamImagesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      transcriptions {
        items {
          id
          date
          title
          description
          startDateTime
          endDateTime
          sizeInBytes
          numberOfTranscriptions
          isZipFile
          meetingId
          teamId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          teamTranscriptionsId
          meetingTranscriptionsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      owner
      sortByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createTeamMemberRelationship = /* GraphQL */ `
  mutation CreateTeamMemberRelationship(
    $input: CreateTeamMemberRelationshipInput!
    $condition: ModelTeamMemberRelationshipConditionInput
  ) {
    createTeamMemberRelationship(input: $input, condition: $condition) {
      id
      team {
        id
        name
        nameLowerCase
        description
        memberRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingRelationships {
          nextToken
          startedAt
          __typename
        }
        products {
          nextToken
          startedAt
          __typename
        }
        labels
        privacyEnabled
        privacyEnableOtherTeams
        images {
          nextToken
          startedAt
          __typename
        }
        transcriptions {
          nextToken
          startedAt
          __typename
        }
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      teamId
      member {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      memberProfileId
      relationship
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      profileTeamRelationshipsId
      teamMemberRelationshipsId
      __typename
    }
  }
`;
export const updateTeamMemberRelationship = /* GraphQL */ `
  mutation UpdateTeamMemberRelationship(
    $input: UpdateTeamMemberRelationshipInput!
    $condition: ModelTeamMemberRelationshipConditionInput
  ) {
    updateTeamMemberRelationship(input: $input, condition: $condition) {
      id
      team {
        id
        name
        nameLowerCase
        description
        memberRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingRelationships {
          nextToken
          startedAt
          __typename
        }
        products {
          nextToken
          startedAt
          __typename
        }
        labels
        privacyEnabled
        privacyEnableOtherTeams
        images {
          nextToken
          startedAt
          __typename
        }
        transcriptions {
          nextToken
          startedAt
          __typename
        }
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      teamId
      member {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      memberProfileId
      relationship
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      profileTeamRelationshipsId
      teamMemberRelationshipsId
      __typename
    }
  }
`;
export const deleteTeamMemberRelationship = /* GraphQL */ `
  mutation DeleteTeamMemberRelationship(
    $input: DeleteTeamMemberRelationshipInput!
    $condition: ModelTeamMemberRelationshipConditionInput
  ) {
    deleteTeamMemberRelationship(input: $input, condition: $condition) {
      id
      team {
        id
        name
        nameLowerCase
        description
        memberRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingRelationships {
          nextToken
          startedAt
          __typename
        }
        products {
          nextToken
          startedAt
          __typename
        }
        labels
        privacyEnabled
        privacyEnableOtherTeams
        images {
          nextToken
          startedAt
          __typename
        }
        transcriptions {
          nextToken
          startedAt
          __typename
        }
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      teamId
      member {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      memberProfileId
      relationship
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      profileTeamRelationshipsId
      teamMemberRelationshipsId
      __typename
    }
  }
`;
export const createMeetingRoomProvider = /* GraphQL */ `
  mutation CreateMeetingRoomProvider(
    $input: CreateMeetingRoomProviderInput!
    $condition: ModelMeetingRoomProviderConditionInput
  ) {
    createMeetingRoomProvider(input: $input, condition: $condition) {
      id
      name
      componentName
      description
      configuration
      meetings {
        items {
          id
          title
          titleLowerCase
          startDateTime
          endDateTime
          status
          description
          preferredRoomProviderId
          actualStartDateTime
          actualEndDateTime
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          productId
          isReadinessTest
          labels
          owner
          sellerOrganizationName
          sellerTeamName
          buyerTeamName
          sellerOrganizationNameLowerCase
          sellerTeamNameLowerCase
          buyerTeamNameLowerCase
          externalId
          metrics
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productMeetingsId
          meetingRoomProviderMeetingsId
          meetingRoomId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      meetingRooms {
        items {
          id
          name
          meetingId
          providerId
          providerMeetingId
          accessCode
          status
          startDateTime
          endDateTime
          actualStartDateTime
          actualEndDateTime
          roomCreatedAt
          roomDestroyedAt
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          isReadinessTest
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          meetingRoomProviderMeetingRoomsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      meetingInvites {
        items {
          id
          meetingId
          providerId
          title
          startDateTime
          endDateTime
          status
          description
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          productId
          isReadinessTest
          meetingRoomId
          providerSessionId
          providerJoinData
          sessionStatus
          actualStartDateTime
          actualEndDateTime
          profileId
          owner
          meetingInvitationType
          teamId
          isInterested
          feedbackSummary
          contactRequestTimeframe
          contactPhoneNumber
          contactEmail
          sellerOrganizationName
          sellerTeamName
          buyerTeamName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productMeetingInvitesId
          profileMeetingInvitesId
          meetingRoomProviderMeetingInvitesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateMeetingRoomProvider = /* GraphQL */ `
  mutation UpdateMeetingRoomProvider(
    $input: UpdateMeetingRoomProviderInput!
    $condition: ModelMeetingRoomProviderConditionInput
  ) {
    updateMeetingRoomProvider(input: $input, condition: $condition) {
      id
      name
      componentName
      description
      configuration
      meetings {
        items {
          id
          title
          titleLowerCase
          startDateTime
          endDateTime
          status
          description
          preferredRoomProviderId
          actualStartDateTime
          actualEndDateTime
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          productId
          isReadinessTest
          labels
          owner
          sellerOrganizationName
          sellerTeamName
          buyerTeamName
          sellerOrganizationNameLowerCase
          sellerTeamNameLowerCase
          buyerTeamNameLowerCase
          externalId
          metrics
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productMeetingsId
          meetingRoomProviderMeetingsId
          meetingRoomId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      meetingRooms {
        items {
          id
          name
          meetingId
          providerId
          providerMeetingId
          accessCode
          status
          startDateTime
          endDateTime
          actualStartDateTime
          actualEndDateTime
          roomCreatedAt
          roomDestroyedAt
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          isReadinessTest
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          meetingRoomProviderMeetingRoomsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      meetingInvites {
        items {
          id
          meetingId
          providerId
          title
          startDateTime
          endDateTime
          status
          description
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          productId
          isReadinessTest
          meetingRoomId
          providerSessionId
          providerJoinData
          sessionStatus
          actualStartDateTime
          actualEndDateTime
          profileId
          owner
          meetingInvitationType
          teamId
          isInterested
          feedbackSummary
          contactRequestTimeframe
          contactPhoneNumber
          contactEmail
          sellerOrganizationName
          sellerTeamName
          buyerTeamName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productMeetingInvitesId
          profileMeetingInvitesId
          meetingRoomProviderMeetingInvitesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteMeetingRoomProvider = /* GraphQL */ `
  mutation DeleteMeetingRoomProvider(
    $input: DeleteMeetingRoomProviderInput!
    $condition: ModelMeetingRoomProviderConditionInput
  ) {
    deleteMeetingRoomProvider(input: $input, condition: $condition) {
      id
      name
      componentName
      description
      configuration
      meetings {
        items {
          id
          title
          titleLowerCase
          startDateTime
          endDateTime
          status
          description
          preferredRoomProviderId
          actualStartDateTime
          actualEndDateTime
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          productId
          isReadinessTest
          labels
          owner
          sellerOrganizationName
          sellerTeamName
          buyerTeamName
          sellerOrganizationNameLowerCase
          sellerTeamNameLowerCase
          buyerTeamNameLowerCase
          externalId
          metrics
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productMeetingsId
          meetingRoomProviderMeetingsId
          meetingRoomId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      meetingRooms {
        items {
          id
          name
          meetingId
          providerId
          providerMeetingId
          accessCode
          status
          startDateTime
          endDateTime
          actualStartDateTime
          actualEndDateTime
          roomCreatedAt
          roomDestroyedAt
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          isReadinessTest
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          meetingRoomProviderMeetingRoomsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      meetingInvites {
        items {
          id
          meetingId
          providerId
          title
          startDateTime
          endDateTime
          status
          description
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          productId
          isReadinessTest
          meetingRoomId
          providerSessionId
          providerJoinData
          sessionStatus
          actualStartDateTime
          actualEndDateTime
          profileId
          owner
          meetingInvitationType
          teamId
          isInterested
          feedbackSummary
          contactRequestTimeframe
          contactPhoneNumber
          contactEmail
          sellerOrganizationName
          sellerTeamName
          buyerTeamName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productMeetingInvitesId
          profileMeetingInvitesId
          meetingRoomProviderMeetingInvitesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createTeamMeetingRelationship = /* GraphQL */ `
  mutation CreateTeamMeetingRelationship(
    $input: CreateTeamMeetingRelationshipInput!
    $condition: ModelTeamMeetingRelationshipConditionInput
  ) {
    createTeamMeetingRelationship(input: $input, condition: $condition) {
      id
      team {
        id
        name
        nameLowerCase
        description
        memberRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingRelationships {
          nextToken
          startedAt
          __typename
        }
        products {
          nextToken
          startedAt
          __typename
        }
        labels
        privacyEnabled
        privacyEnableOtherTeams
        images {
          nextToken
          startedAt
          __typename
        }
        transcriptions {
          nextToken
          startedAt
          __typename
        }
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      teamId
      meeting {
        id
        title
        titleLowerCase
        startDateTime
        endDateTime
        status
        description
        preferredRoomProvider {
          id
          name
          componentName
          description
          configuration
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        preferredRoomProviderId
        actualStartDateTime
        actualEndDateTime
        room {
          id
          name
          meetingId
          providerId
          providerMeetingId
          accessCode
          status
          startDateTime
          endDateTime
          actualStartDateTime
          actualEndDateTime
          roomCreatedAt
          roomDestroyedAt
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          isReadinessTest
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          meetingRoomProviderMeetingRoomsId
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        videoEnabled
        transcriptionEnabled
        product {
          id
          name
          nameLowerCase
          description
          serviceInformation
          organizationId
          salesTeamId
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProductsId
          teamProductsId
          __typename
        }
        productId
        isReadinessTest
        labels
        owner
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        transcriptions {
          nextToken
          startedAt
          __typename
        }
        sellerOrganizationName
        sellerTeamName
        buyerTeamName
        sellerOrganizationNameLowerCase
        sellerTeamNameLowerCase
        buyerTeamNameLowerCase
        externalId
        metrics
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productMeetingsId
        meetingRoomProviderMeetingsId
        meetingRoomId
        __typename
      }
      meetingId
      relationshipType
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      teamMeetingRelationshipsId
      meetingTeamRelationshipsId
      __typename
    }
  }
`;
export const updateTeamMeetingRelationship = /* GraphQL */ `
  mutation UpdateTeamMeetingRelationship(
    $input: UpdateTeamMeetingRelationshipInput!
    $condition: ModelTeamMeetingRelationshipConditionInput
  ) {
    updateTeamMeetingRelationship(input: $input, condition: $condition) {
      id
      team {
        id
        name
        nameLowerCase
        description
        memberRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingRelationships {
          nextToken
          startedAt
          __typename
        }
        products {
          nextToken
          startedAt
          __typename
        }
        labels
        privacyEnabled
        privacyEnableOtherTeams
        images {
          nextToken
          startedAt
          __typename
        }
        transcriptions {
          nextToken
          startedAt
          __typename
        }
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      teamId
      meeting {
        id
        title
        titleLowerCase
        startDateTime
        endDateTime
        status
        description
        preferredRoomProvider {
          id
          name
          componentName
          description
          configuration
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        preferredRoomProviderId
        actualStartDateTime
        actualEndDateTime
        room {
          id
          name
          meetingId
          providerId
          providerMeetingId
          accessCode
          status
          startDateTime
          endDateTime
          actualStartDateTime
          actualEndDateTime
          roomCreatedAt
          roomDestroyedAt
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          isReadinessTest
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          meetingRoomProviderMeetingRoomsId
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        videoEnabled
        transcriptionEnabled
        product {
          id
          name
          nameLowerCase
          description
          serviceInformation
          organizationId
          salesTeamId
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProductsId
          teamProductsId
          __typename
        }
        productId
        isReadinessTest
        labels
        owner
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        transcriptions {
          nextToken
          startedAt
          __typename
        }
        sellerOrganizationName
        sellerTeamName
        buyerTeamName
        sellerOrganizationNameLowerCase
        sellerTeamNameLowerCase
        buyerTeamNameLowerCase
        externalId
        metrics
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productMeetingsId
        meetingRoomProviderMeetingsId
        meetingRoomId
        __typename
      }
      meetingId
      relationshipType
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      teamMeetingRelationshipsId
      meetingTeamRelationshipsId
      __typename
    }
  }
`;
export const deleteTeamMeetingRelationship = /* GraphQL */ `
  mutation DeleteTeamMeetingRelationship(
    $input: DeleteTeamMeetingRelationshipInput!
    $condition: ModelTeamMeetingRelationshipConditionInput
  ) {
    deleteTeamMeetingRelationship(input: $input, condition: $condition) {
      id
      team {
        id
        name
        nameLowerCase
        description
        memberRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingRelationships {
          nextToken
          startedAt
          __typename
        }
        products {
          nextToken
          startedAt
          __typename
        }
        labels
        privacyEnabled
        privacyEnableOtherTeams
        images {
          nextToken
          startedAt
          __typename
        }
        transcriptions {
          nextToken
          startedAt
          __typename
        }
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      teamId
      meeting {
        id
        title
        titleLowerCase
        startDateTime
        endDateTime
        status
        description
        preferredRoomProvider {
          id
          name
          componentName
          description
          configuration
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        preferredRoomProviderId
        actualStartDateTime
        actualEndDateTime
        room {
          id
          name
          meetingId
          providerId
          providerMeetingId
          accessCode
          status
          startDateTime
          endDateTime
          actualStartDateTime
          actualEndDateTime
          roomCreatedAt
          roomDestroyedAt
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          isReadinessTest
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          meetingRoomProviderMeetingRoomsId
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        videoEnabled
        transcriptionEnabled
        product {
          id
          name
          nameLowerCase
          description
          serviceInformation
          organizationId
          salesTeamId
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProductsId
          teamProductsId
          __typename
        }
        productId
        isReadinessTest
        labels
        owner
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        transcriptions {
          nextToken
          startedAt
          __typename
        }
        sellerOrganizationName
        sellerTeamName
        buyerTeamName
        sellerOrganizationNameLowerCase
        sellerTeamNameLowerCase
        buyerTeamNameLowerCase
        externalId
        metrics
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productMeetingsId
        meetingRoomProviderMeetingsId
        meetingRoomId
        __typename
      }
      meetingId
      relationshipType
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      teamMeetingRelationshipsId
      meetingTeamRelationshipsId
      __typename
    }
  }
`;
export const createMeetingAttendee = /* GraphQL */ `
  mutation CreateMeetingAttendee(
    $input: CreateMeetingAttendeeInput!
    $condition: ModelMeetingAttendeeConditionInput
  ) {
    createMeetingAttendee(input: $input, condition: $condition) {
      id
      profile {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      profileId
      meeting {
        id
        title
        titleLowerCase
        startDateTime
        endDateTime
        status
        description
        preferredRoomProvider {
          id
          name
          componentName
          description
          configuration
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        preferredRoomProviderId
        actualStartDateTime
        actualEndDateTime
        room {
          id
          name
          meetingId
          providerId
          providerMeetingId
          accessCode
          status
          startDateTime
          endDateTime
          actualStartDateTime
          actualEndDateTime
          roomCreatedAt
          roomDestroyedAt
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          isReadinessTest
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          meetingRoomProviderMeetingRoomsId
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        videoEnabled
        transcriptionEnabled
        product {
          id
          name
          nameLowerCase
          description
          serviceInformation
          organizationId
          salesTeamId
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProductsId
          teamProductsId
          __typename
        }
        productId
        isReadinessTest
        labels
        owner
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        transcriptions {
          nextToken
          startedAt
          __typename
        }
        sellerOrganizationName
        sellerTeamName
        buyerTeamName
        sellerOrganizationNameLowerCase
        sellerTeamNameLowerCase
        buyerTeamNameLowerCase
        externalId
        metrics
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productMeetingsId
        meetingRoomProviderMeetingsId
        meetingRoomId
        __typename
      }
      meetingId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      profileMeetingAttendeesId
      meetingMeetingAttendeesId
      __typename
    }
  }
`;
export const updateMeetingAttendee = /* GraphQL */ `
  mutation UpdateMeetingAttendee(
    $input: UpdateMeetingAttendeeInput!
    $condition: ModelMeetingAttendeeConditionInput
  ) {
    updateMeetingAttendee(input: $input, condition: $condition) {
      id
      profile {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      profileId
      meeting {
        id
        title
        titleLowerCase
        startDateTime
        endDateTime
        status
        description
        preferredRoomProvider {
          id
          name
          componentName
          description
          configuration
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        preferredRoomProviderId
        actualStartDateTime
        actualEndDateTime
        room {
          id
          name
          meetingId
          providerId
          providerMeetingId
          accessCode
          status
          startDateTime
          endDateTime
          actualStartDateTime
          actualEndDateTime
          roomCreatedAt
          roomDestroyedAt
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          isReadinessTest
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          meetingRoomProviderMeetingRoomsId
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        videoEnabled
        transcriptionEnabled
        product {
          id
          name
          nameLowerCase
          description
          serviceInformation
          organizationId
          salesTeamId
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProductsId
          teamProductsId
          __typename
        }
        productId
        isReadinessTest
        labels
        owner
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        transcriptions {
          nextToken
          startedAt
          __typename
        }
        sellerOrganizationName
        sellerTeamName
        buyerTeamName
        sellerOrganizationNameLowerCase
        sellerTeamNameLowerCase
        buyerTeamNameLowerCase
        externalId
        metrics
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productMeetingsId
        meetingRoomProviderMeetingsId
        meetingRoomId
        __typename
      }
      meetingId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      profileMeetingAttendeesId
      meetingMeetingAttendeesId
      __typename
    }
  }
`;
export const deleteMeetingAttendee = /* GraphQL */ `
  mutation DeleteMeetingAttendee(
    $input: DeleteMeetingAttendeeInput!
    $condition: ModelMeetingAttendeeConditionInput
  ) {
    deleteMeetingAttendee(input: $input, condition: $condition) {
      id
      profile {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      profileId
      meeting {
        id
        title
        titleLowerCase
        startDateTime
        endDateTime
        status
        description
        preferredRoomProvider {
          id
          name
          componentName
          description
          configuration
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        preferredRoomProviderId
        actualStartDateTime
        actualEndDateTime
        room {
          id
          name
          meetingId
          providerId
          providerMeetingId
          accessCode
          status
          startDateTime
          endDateTime
          actualStartDateTime
          actualEndDateTime
          roomCreatedAt
          roomDestroyedAt
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          isReadinessTest
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          meetingRoomProviderMeetingRoomsId
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        videoEnabled
        transcriptionEnabled
        product {
          id
          name
          nameLowerCase
          description
          serviceInformation
          organizationId
          salesTeamId
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProductsId
          teamProductsId
          __typename
        }
        productId
        isReadinessTest
        labels
        owner
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        transcriptions {
          nextToken
          startedAt
          __typename
        }
        sellerOrganizationName
        sellerTeamName
        buyerTeamName
        sellerOrganizationNameLowerCase
        sellerTeamNameLowerCase
        buyerTeamNameLowerCase
        externalId
        metrics
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productMeetingsId
        meetingRoomProviderMeetingsId
        meetingRoomId
        __typename
      }
      meetingId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      profileMeetingAttendeesId
      meetingMeetingAttendeesId
      __typename
    }
  }
`;
export const createMeeting = /* GraphQL */ `
  mutation CreateMeeting(
    $input: CreateMeetingInput!
    $condition: ModelMeetingConditionInput
  ) {
    createMeeting(input: $input, condition: $condition) {
      id
      title
      titleLowerCase
      startDateTime
      endDateTime
      status
      description
      preferredRoomProvider {
        id
        name
        componentName
        description
        configuration
        meetings {
          nextToken
          startedAt
          __typename
        }
        meetingRooms {
          nextToken
          startedAt
          __typename
        }
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      preferredRoomProviderId
      actualStartDateTime
      actualEndDateTime
      room {
        id
        name
        meeting {
          id
          title
          titleLowerCase
          startDateTime
          endDateTime
          status
          description
          preferredRoomProviderId
          actualStartDateTime
          actualEndDateTime
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          productId
          isReadinessTest
          labels
          owner
          sellerOrganizationName
          sellerTeamName
          buyerTeamName
          sellerOrganizationNameLowerCase
          sellerTeamNameLowerCase
          buyerTeamNameLowerCase
          externalId
          metrics
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productMeetingsId
          meetingRoomProviderMeetingsId
          meetingRoomId
          __typename
        }
        meetingId
        provider {
          id
          name
          componentName
          description
          configuration
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        providerId
        providerMeetingId
        accessCode
        status
        startDateTime
        endDateTime
        actualStartDateTime
        actualEndDateTime
        roomCreatedAt
        roomDestroyedAt
        auditlogs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        videoEnabled
        transcriptionEnabled
        isReadinessTest
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        meetingRoomProviderMeetingRoomsId
        __typename
      }
      questionResponses {
        items {
          id
          response
          privateNotes
          questionId
          profileId
          meetingId
          meetingInviteId
          owner
          contactRequestTimeframe
          contactRequestDateTime
          contactPhoneNumber
          contactEmail
          contactName
          providePersonalEmail
          providePersonalPhoneNumber
          textOnly
          followupMeetingRequested
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productQuestionResponsesId
          profileQuestionResponsesId
          meetingQuestionResponsesId
          meetingInviteQuestionResponsesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      privacyEnabled
      videoEnabled
      transcriptionEnabled
      product {
        id
        name
        nameLowerCase
        description
        serviceInformation
        images {
          nextToken
          startedAt
          __typename
        }
        questions {
          nextToken
          startedAt
          __typename
        }
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        salesTeam {
          id
          name
          nameLowerCase
          description
          labels
          privacyEnabled
          privacyEnableOtherTeams
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        salesTeamId
        meetings {
          nextToken
          startedAt
          __typename
        }
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        labels
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProductsId
        teamProductsId
        __typename
      }
      productId
      isReadinessTest
      labels
      owner
      teamRelationships {
        items {
          id
          teamId
          meetingId
          relationshipType
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          teamMeetingRelationshipsId
          meetingTeamRelationshipsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      meetingAttendees {
        items {
          id
          profileId
          meetingId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileMeetingAttendeesId
          meetingMeetingAttendeesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      transcriptions {
        items {
          id
          date
          title
          description
          startDateTime
          endDateTime
          sizeInBytes
          numberOfTranscriptions
          isZipFile
          meetingId
          teamId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          teamTranscriptionsId
          meetingTranscriptionsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      sellerOrganizationName
      sellerTeamName
      buyerTeamName
      sellerOrganizationNameLowerCase
      sellerTeamNameLowerCase
      buyerTeamNameLowerCase
      externalId
      metrics
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      productMeetingsId
      meetingRoomProviderMeetingsId
      meetingRoomId
      __typename
    }
  }
`;
export const updateMeeting = /* GraphQL */ `
  mutation UpdateMeeting(
    $input: UpdateMeetingInput!
    $condition: ModelMeetingConditionInput
  ) {
    updateMeeting(input: $input, condition: $condition) {
      id
      title
      titleLowerCase
      startDateTime
      endDateTime
      status
      description
      preferredRoomProvider {
        id
        name
        componentName
        description
        configuration
        meetings {
          nextToken
          startedAt
          __typename
        }
        meetingRooms {
          nextToken
          startedAt
          __typename
        }
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      preferredRoomProviderId
      actualStartDateTime
      actualEndDateTime
      room {
        id
        name
        meeting {
          id
          title
          titleLowerCase
          startDateTime
          endDateTime
          status
          description
          preferredRoomProviderId
          actualStartDateTime
          actualEndDateTime
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          productId
          isReadinessTest
          labels
          owner
          sellerOrganizationName
          sellerTeamName
          buyerTeamName
          sellerOrganizationNameLowerCase
          sellerTeamNameLowerCase
          buyerTeamNameLowerCase
          externalId
          metrics
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productMeetingsId
          meetingRoomProviderMeetingsId
          meetingRoomId
          __typename
        }
        meetingId
        provider {
          id
          name
          componentName
          description
          configuration
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        providerId
        providerMeetingId
        accessCode
        status
        startDateTime
        endDateTime
        actualStartDateTime
        actualEndDateTime
        roomCreatedAt
        roomDestroyedAt
        auditlogs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        videoEnabled
        transcriptionEnabled
        isReadinessTest
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        meetingRoomProviderMeetingRoomsId
        __typename
      }
      questionResponses {
        items {
          id
          response
          privateNotes
          questionId
          profileId
          meetingId
          meetingInviteId
          owner
          contactRequestTimeframe
          contactRequestDateTime
          contactPhoneNumber
          contactEmail
          contactName
          providePersonalEmail
          providePersonalPhoneNumber
          textOnly
          followupMeetingRequested
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productQuestionResponsesId
          profileQuestionResponsesId
          meetingQuestionResponsesId
          meetingInviteQuestionResponsesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      privacyEnabled
      videoEnabled
      transcriptionEnabled
      product {
        id
        name
        nameLowerCase
        description
        serviceInformation
        images {
          nextToken
          startedAt
          __typename
        }
        questions {
          nextToken
          startedAt
          __typename
        }
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        salesTeam {
          id
          name
          nameLowerCase
          description
          labels
          privacyEnabled
          privacyEnableOtherTeams
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        salesTeamId
        meetings {
          nextToken
          startedAt
          __typename
        }
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        labels
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProductsId
        teamProductsId
        __typename
      }
      productId
      isReadinessTest
      labels
      owner
      teamRelationships {
        items {
          id
          teamId
          meetingId
          relationshipType
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          teamMeetingRelationshipsId
          meetingTeamRelationshipsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      meetingAttendees {
        items {
          id
          profileId
          meetingId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileMeetingAttendeesId
          meetingMeetingAttendeesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      transcriptions {
        items {
          id
          date
          title
          description
          startDateTime
          endDateTime
          sizeInBytes
          numberOfTranscriptions
          isZipFile
          meetingId
          teamId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          teamTranscriptionsId
          meetingTranscriptionsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      sellerOrganizationName
      sellerTeamName
      buyerTeamName
      sellerOrganizationNameLowerCase
      sellerTeamNameLowerCase
      buyerTeamNameLowerCase
      externalId
      metrics
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      productMeetingsId
      meetingRoomProviderMeetingsId
      meetingRoomId
      __typename
    }
  }
`;
export const deleteMeeting = /* GraphQL */ `
  mutation DeleteMeeting(
    $input: DeleteMeetingInput!
    $condition: ModelMeetingConditionInput
  ) {
    deleteMeeting(input: $input, condition: $condition) {
      id
      title
      titleLowerCase
      startDateTime
      endDateTime
      status
      description
      preferredRoomProvider {
        id
        name
        componentName
        description
        configuration
        meetings {
          nextToken
          startedAt
          __typename
        }
        meetingRooms {
          nextToken
          startedAt
          __typename
        }
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      preferredRoomProviderId
      actualStartDateTime
      actualEndDateTime
      room {
        id
        name
        meeting {
          id
          title
          titleLowerCase
          startDateTime
          endDateTime
          status
          description
          preferredRoomProviderId
          actualStartDateTime
          actualEndDateTime
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          productId
          isReadinessTest
          labels
          owner
          sellerOrganizationName
          sellerTeamName
          buyerTeamName
          sellerOrganizationNameLowerCase
          sellerTeamNameLowerCase
          buyerTeamNameLowerCase
          externalId
          metrics
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productMeetingsId
          meetingRoomProviderMeetingsId
          meetingRoomId
          __typename
        }
        meetingId
        provider {
          id
          name
          componentName
          description
          configuration
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        providerId
        providerMeetingId
        accessCode
        status
        startDateTime
        endDateTime
        actualStartDateTime
        actualEndDateTime
        roomCreatedAt
        roomDestroyedAt
        auditlogs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        videoEnabled
        transcriptionEnabled
        isReadinessTest
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        meetingRoomProviderMeetingRoomsId
        __typename
      }
      questionResponses {
        items {
          id
          response
          privateNotes
          questionId
          profileId
          meetingId
          meetingInviteId
          owner
          contactRequestTimeframe
          contactRequestDateTime
          contactPhoneNumber
          contactEmail
          contactName
          providePersonalEmail
          providePersonalPhoneNumber
          textOnly
          followupMeetingRequested
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productQuestionResponsesId
          profileQuestionResponsesId
          meetingQuestionResponsesId
          meetingInviteQuestionResponsesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      privacyEnabled
      videoEnabled
      transcriptionEnabled
      product {
        id
        name
        nameLowerCase
        description
        serviceInformation
        images {
          nextToken
          startedAt
          __typename
        }
        questions {
          nextToken
          startedAt
          __typename
        }
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        salesTeam {
          id
          name
          nameLowerCase
          description
          labels
          privacyEnabled
          privacyEnableOtherTeams
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        salesTeamId
        meetings {
          nextToken
          startedAt
          __typename
        }
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        labels
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProductsId
        teamProductsId
        __typename
      }
      productId
      isReadinessTest
      labels
      owner
      teamRelationships {
        items {
          id
          teamId
          meetingId
          relationshipType
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          teamMeetingRelationshipsId
          meetingTeamRelationshipsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      meetingAttendees {
        items {
          id
          profileId
          meetingId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileMeetingAttendeesId
          meetingMeetingAttendeesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      transcriptions {
        items {
          id
          date
          title
          description
          startDateTime
          endDateTime
          sizeInBytes
          numberOfTranscriptions
          isZipFile
          meetingId
          teamId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          teamTranscriptionsId
          meetingTranscriptionsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      sellerOrganizationName
      sellerTeamName
      buyerTeamName
      sellerOrganizationNameLowerCase
      sellerTeamNameLowerCase
      buyerTeamNameLowerCase
      externalId
      metrics
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      productMeetingsId
      meetingRoomProviderMeetingsId
      meetingRoomId
      __typename
    }
  }
`;
export const createMeetingInvite = /* GraphQL */ `
  mutation CreateMeetingInvite(
    $input: CreateMeetingInviteInput!
    $condition: ModelMeetingInviteConditionInput
  ) {
    createMeetingInvite(input: $input, condition: $condition) {
      id
      meetingId
      provider {
        id
        name
        componentName
        description
        configuration
        meetings {
          nextToken
          startedAt
          __typename
        }
        meetingRooms {
          nextToken
          startedAt
          __typename
        }
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      providerId
      title
      startDateTime
      endDateTime
      status
      description
      questionResponses {
        items {
          id
          response
          privateNotes
          questionId
          profileId
          meetingId
          meetingInviteId
          owner
          contactRequestTimeframe
          contactRequestDateTime
          contactPhoneNumber
          contactEmail
          contactName
          providePersonalEmail
          providePersonalPhoneNumber
          textOnly
          followupMeetingRequested
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productQuestionResponsesId
          profileQuestionResponsesId
          meetingQuestionResponsesId
          meetingInviteQuestionResponsesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      privacyEnabled
      videoEnabled
      transcriptionEnabled
      product {
        id
        name
        nameLowerCase
        description
        serviceInformation
        images {
          nextToken
          startedAt
          __typename
        }
        questions {
          nextToken
          startedAt
          __typename
        }
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        salesTeam {
          id
          name
          nameLowerCase
          description
          labels
          privacyEnabled
          privacyEnableOtherTeams
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        salesTeamId
        meetings {
          nextToken
          startedAt
          __typename
        }
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        labels
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProductsId
        teamProductsId
        __typename
      }
      productId
      isReadinessTest
      meetingRoomId
      providerSessionId
      providerJoinData
      sessionStatus
      actualStartDateTime
      actualEndDateTime
      profile {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      profileId
      owner
      meetingInvitationType
      teamId
      isInterested
      feedbackSummary
      contactRequestTimeframe
      contactPhoneNumber
      contactEmail
      sellerOrganizationName
      sellerTeamName
      buyerTeamName
      transcriptionLogs {
        items {
          id
          date
          author
          resource
          action
          payload
          ipAddress
          meetingInviteId
          profileId
          authorProfileId
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileTranscriptionLogsId
          meetingInviteTranscriptionLogsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      productMeetingInvitesId
      profileMeetingInvitesId
      meetingRoomProviderMeetingInvitesId
      __typename
    }
  }
`;
export const updateMeetingInvite = /* GraphQL */ `
  mutation UpdateMeetingInvite(
    $input: UpdateMeetingInviteInput!
    $condition: ModelMeetingInviteConditionInput
  ) {
    updateMeetingInvite(input: $input, condition: $condition) {
      id
      meetingId
      provider {
        id
        name
        componentName
        description
        configuration
        meetings {
          nextToken
          startedAt
          __typename
        }
        meetingRooms {
          nextToken
          startedAt
          __typename
        }
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      providerId
      title
      startDateTime
      endDateTime
      status
      description
      questionResponses {
        items {
          id
          response
          privateNotes
          questionId
          profileId
          meetingId
          meetingInviteId
          owner
          contactRequestTimeframe
          contactRequestDateTime
          contactPhoneNumber
          contactEmail
          contactName
          providePersonalEmail
          providePersonalPhoneNumber
          textOnly
          followupMeetingRequested
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productQuestionResponsesId
          profileQuestionResponsesId
          meetingQuestionResponsesId
          meetingInviteQuestionResponsesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      privacyEnabled
      videoEnabled
      transcriptionEnabled
      product {
        id
        name
        nameLowerCase
        description
        serviceInformation
        images {
          nextToken
          startedAt
          __typename
        }
        questions {
          nextToken
          startedAt
          __typename
        }
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        salesTeam {
          id
          name
          nameLowerCase
          description
          labels
          privacyEnabled
          privacyEnableOtherTeams
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        salesTeamId
        meetings {
          nextToken
          startedAt
          __typename
        }
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        labels
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProductsId
        teamProductsId
        __typename
      }
      productId
      isReadinessTest
      meetingRoomId
      providerSessionId
      providerJoinData
      sessionStatus
      actualStartDateTime
      actualEndDateTime
      profile {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      profileId
      owner
      meetingInvitationType
      teamId
      isInterested
      feedbackSummary
      contactRequestTimeframe
      contactPhoneNumber
      contactEmail
      sellerOrganizationName
      sellerTeamName
      buyerTeamName
      transcriptionLogs {
        items {
          id
          date
          author
          resource
          action
          payload
          ipAddress
          meetingInviteId
          profileId
          authorProfileId
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileTranscriptionLogsId
          meetingInviteTranscriptionLogsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      productMeetingInvitesId
      profileMeetingInvitesId
      meetingRoomProviderMeetingInvitesId
      __typename
    }
  }
`;
export const deleteMeetingInvite = /* GraphQL */ `
  mutation DeleteMeetingInvite(
    $input: DeleteMeetingInviteInput!
    $condition: ModelMeetingInviteConditionInput
  ) {
    deleteMeetingInvite(input: $input, condition: $condition) {
      id
      meetingId
      provider {
        id
        name
        componentName
        description
        configuration
        meetings {
          nextToken
          startedAt
          __typename
        }
        meetingRooms {
          nextToken
          startedAt
          __typename
        }
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      providerId
      title
      startDateTime
      endDateTime
      status
      description
      questionResponses {
        items {
          id
          response
          privateNotes
          questionId
          profileId
          meetingId
          meetingInviteId
          owner
          contactRequestTimeframe
          contactRequestDateTime
          contactPhoneNumber
          contactEmail
          contactName
          providePersonalEmail
          providePersonalPhoneNumber
          textOnly
          followupMeetingRequested
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productQuestionResponsesId
          profileQuestionResponsesId
          meetingQuestionResponsesId
          meetingInviteQuestionResponsesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      privacyEnabled
      videoEnabled
      transcriptionEnabled
      product {
        id
        name
        nameLowerCase
        description
        serviceInformation
        images {
          nextToken
          startedAt
          __typename
        }
        questions {
          nextToken
          startedAt
          __typename
        }
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        salesTeam {
          id
          name
          nameLowerCase
          description
          labels
          privacyEnabled
          privacyEnableOtherTeams
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        salesTeamId
        meetings {
          nextToken
          startedAt
          __typename
        }
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        labels
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProductsId
        teamProductsId
        __typename
      }
      productId
      isReadinessTest
      meetingRoomId
      providerSessionId
      providerJoinData
      sessionStatus
      actualStartDateTime
      actualEndDateTime
      profile {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      profileId
      owner
      meetingInvitationType
      teamId
      isInterested
      feedbackSummary
      contactRequestTimeframe
      contactPhoneNumber
      contactEmail
      sellerOrganizationName
      sellerTeamName
      buyerTeamName
      transcriptionLogs {
        items {
          id
          date
          author
          resource
          action
          payload
          ipAddress
          meetingInviteId
          profileId
          authorProfileId
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileTranscriptionLogsId
          meetingInviteTranscriptionLogsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      productMeetingInvitesId
      profileMeetingInvitesId
      meetingRoomProviderMeetingInvitesId
      __typename
    }
  }
`;
export const createMeetingRoom = /* GraphQL */ `
  mutation CreateMeetingRoom(
    $input: CreateMeetingRoomInput!
    $condition: ModelMeetingRoomConditionInput
  ) {
    createMeetingRoom(input: $input, condition: $condition) {
      id
      name
      meeting {
        id
        title
        titleLowerCase
        startDateTime
        endDateTime
        status
        description
        preferredRoomProvider {
          id
          name
          componentName
          description
          configuration
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        preferredRoomProviderId
        actualStartDateTime
        actualEndDateTime
        room {
          id
          name
          meetingId
          providerId
          providerMeetingId
          accessCode
          status
          startDateTime
          endDateTime
          actualStartDateTime
          actualEndDateTime
          roomCreatedAt
          roomDestroyedAt
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          isReadinessTest
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          meetingRoomProviderMeetingRoomsId
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        videoEnabled
        transcriptionEnabled
        product {
          id
          name
          nameLowerCase
          description
          serviceInformation
          organizationId
          salesTeamId
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProductsId
          teamProductsId
          __typename
        }
        productId
        isReadinessTest
        labels
        owner
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        transcriptions {
          nextToken
          startedAt
          __typename
        }
        sellerOrganizationName
        sellerTeamName
        buyerTeamName
        sellerOrganizationNameLowerCase
        sellerTeamNameLowerCase
        buyerTeamNameLowerCase
        externalId
        metrics
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productMeetingsId
        meetingRoomProviderMeetingsId
        meetingRoomId
        __typename
      }
      meetingId
      provider {
        id
        name
        componentName
        description
        configuration
        meetings {
          nextToken
          startedAt
          __typename
        }
        meetingRooms {
          nextToken
          startedAt
          __typename
        }
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      providerId
      providerMeetingId
      accessCode
      status
      startDateTime
      endDateTime
      actualStartDateTime
      actualEndDateTime
      roomCreatedAt
      roomDestroyedAt
      auditlogs {
        items {
          id
          date
          author
          resource
          action
          payload
          ipAddress
          meetingRoomId
          profileId
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileAuditLogsId
          meetingRoomAuditlogsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      privacyEnabled
      videoEnabled
      transcriptionEnabled
      isReadinessTest
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      meetingRoomProviderMeetingRoomsId
      __typename
    }
  }
`;
export const updateMeetingRoom = /* GraphQL */ `
  mutation UpdateMeetingRoom(
    $input: UpdateMeetingRoomInput!
    $condition: ModelMeetingRoomConditionInput
  ) {
    updateMeetingRoom(input: $input, condition: $condition) {
      id
      name
      meeting {
        id
        title
        titleLowerCase
        startDateTime
        endDateTime
        status
        description
        preferredRoomProvider {
          id
          name
          componentName
          description
          configuration
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        preferredRoomProviderId
        actualStartDateTime
        actualEndDateTime
        room {
          id
          name
          meetingId
          providerId
          providerMeetingId
          accessCode
          status
          startDateTime
          endDateTime
          actualStartDateTime
          actualEndDateTime
          roomCreatedAt
          roomDestroyedAt
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          isReadinessTest
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          meetingRoomProviderMeetingRoomsId
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        videoEnabled
        transcriptionEnabled
        product {
          id
          name
          nameLowerCase
          description
          serviceInformation
          organizationId
          salesTeamId
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProductsId
          teamProductsId
          __typename
        }
        productId
        isReadinessTest
        labels
        owner
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        transcriptions {
          nextToken
          startedAt
          __typename
        }
        sellerOrganizationName
        sellerTeamName
        buyerTeamName
        sellerOrganizationNameLowerCase
        sellerTeamNameLowerCase
        buyerTeamNameLowerCase
        externalId
        metrics
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productMeetingsId
        meetingRoomProviderMeetingsId
        meetingRoomId
        __typename
      }
      meetingId
      provider {
        id
        name
        componentName
        description
        configuration
        meetings {
          nextToken
          startedAt
          __typename
        }
        meetingRooms {
          nextToken
          startedAt
          __typename
        }
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      providerId
      providerMeetingId
      accessCode
      status
      startDateTime
      endDateTime
      actualStartDateTime
      actualEndDateTime
      roomCreatedAt
      roomDestroyedAt
      auditlogs {
        items {
          id
          date
          author
          resource
          action
          payload
          ipAddress
          meetingRoomId
          profileId
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileAuditLogsId
          meetingRoomAuditlogsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      privacyEnabled
      videoEnabled
      transcriptionEnabled
      isReadinessTest
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      meetingRoomProviderMeetingRoomsId
      __typename
    }
  }
`;
export const deleteMeetingRoom = /* GraphQL */ `
  mutation DeleteMeetingRoom(
    $input: DeleteMeetingRoomInput!
    $condition: ModelMeetingRoomConditionInput
  ) {
    deleteMeetingRoom(input: $input, condition: $condition) {
      id
      name
      meeting {
        id
        title
        titleLowerCase
        startDateTime
        endDateTime
        status
        description
        preferredRoomProvider {
          id
          name
          componentName
          description
          configuration
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        preferredRoomProviderId
        actualStartDateTime
        actualEndDateTime
        room {
          id
          name
          meetingId
          providerId
          providerMeetingId
          accessCode
          status
          startDateTime
          endDateTime
          actualStartDateTime
          actualEndDateTime
          roomCreatedAt
          roomDestroyedAt
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          isReadinessTest
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          meetingRoomProviderMeetingRoomsId
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        videoEnabled
        transcriptionEnabled
        product {
          id
          name
          nameLowerCase
          description
          serviceInformation
          organizationId
          salesTeamId
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProductsId
          teamProductsId
          __typename
        }
        productId
        isReadinessTest
        labels
        owner
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        transcriptions {
          nextToken
          startedAt
          __typename
        }
        sellerOrganizationName
        sellerTeamName
        buyerTeamName
        sellerOrganizationNameLowerCase
        sellerTeamNameLowerCase
        buyerTeamNameLowerCase
        externalId
        metrics
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productMeetingsId
        meetingRoomProviderMeetingsId
        meetingRoomId
        __typename
      }
      meetingId
      provider {
        id
        name
        componentName
        description
        configuration
        meetings {
          nextToken
          startedAt
          __typename
        }
        meetingRooms {
          nextToken
          startedAt
          __typename
        }
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      providerId
      providerMeetingId
      accessCode
      status
      startDateTime
      endDateTime
      actualStartDateTime
      actualEndDateTime
      roomCreatedAt
      roomDestroyedAt
      auditlogs {
        items {
          id
          date
          author
          resource
          action
          payload
          ipAddress
          meetingRoomId
          profileId
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileAuditLogsId
          meetingRoomAuditlogsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      privacyEnabled
      videoEnabled
      transcriptionEnabled
      isReadinessTest
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      meetingRoomProviderMeetingRoomsId
      __typename
    }
  }
`;
export const createMeetingRoomEventAuditLog = /* GraphQL */ `
  mutation CreateMeetingRoomEventAuditLog(
    $input: CreateMeetingRoomEventAuditLogInput!
    $condition: ModelMeetingRoomEventAuditLogConditionInput
  ) {
    createMeetingRoomEventAuditLog(input: $input, condition: $condition) {
      id
      date
      author
      resource
      action
      payload
      ipAddress
      meetingRoom {
        id
        name
        meeting {
          id
          title
          titleLowerCase
          startDateTime
          endDateTime
          status
          description
          preferredRoomProviderId
          actualStartDateTime
          actualEndDateTime
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          productId
          isReadinessTest
          labels
          owner
          sellerOrganizationName
          sellerTeamName
          buyerTeamName
          sellerOrganizationNameLowerCase
          sellerTeamNameLowerCase
          buyerTeamNameLowerCase
          externalId
          metrics
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productMeetingsId
          meetingRoomProviderMeetingsId
          meetingRoomId
          __typename
        }
        meetingId
        provider {
          id
          name
          componentName
          description
          configuration
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        providerId
        providerMeetingId
        accessCode
        status
        startDateTime
        endDateTime
        actualStartDateTime
        actualEndDateTime
        roomCreatedAt
        roomDestroyedAt
        auditlogs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        videoEnabled
        transcriptionEnabled
        isReadinessTest
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        meetingRoomProviderMeetingRoomsId
        __typename
      }
      meetingRoomId
      profile {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      profileId
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      profileAuditLogsId
      meetingRoomAuditlogsId
      __typename
    }
  }
`;
export const updateMeetingRoomEventAuditLog = /* GraphQL */ `
  mutation UpdateMeetingRoomEventAuditLog(
    $input: UpdateMeetingRoomEventAuditLogInput!
    $condition: ModelMeetingRoomEventAuditLogConditionInput
  ) {
    updateMeetingRoomEventAuditLog(input: $input, condition: $condition) {
      id
      date
      author
      resource
      action
      payload
      ipAddress
      meetingRoom {
        id
        name
        meeting {
          id
          title
          titleLowerCase
          startDateTime
          endDateTime
          status
          description
          preferredRoomProviderId
          actualStartDateTime
          actualEndDateTime
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          productId
          isReadinessTest
          labels
          owner
          sellerOrganizationName
          sellerTeamName
          buyerTeamName
          sellerOrganizationNameLowerCase
          sellerTeamNameLowerCase
          buyerTeamNameLowerCase
          externalId
          metrics
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productMeetingsId
          meetingRoomProviderMeetingsId
          meetingRoomId
          __typename
        }
        meetingId
        provider {
          id
          name
          componentName
          description
          configuration
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        providerId
        providerMeetingId
        accessCode
        status
        startDateTime
        endDateTime
        actualStartDateTime
        actualEndDateTime
        roomCreatedAt
        roomDestroyedAt
        auditlogs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        videoEnabled
        transcriptionEnabled
        isReadinessTest
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        meetingRoomProviderMeetingRoomsId
        __typename
      }
      meetingRoomId
      profile {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      profileId
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      profileAuditLogsId
      meetingRoomAuditlogsId
      __typename
    }
  }
`;
export const deleteMeetingRoomEventAuditLog = /* GraphQL */ `
  mutation DeleteMeetingRoomEventAuditLog(
    $input: DeleteMeetingRoomEventAuditLogInput!
    $condition: ModelMeetingRoomEventAuditLogConditionInput
  ) {
    deleteMeetingRoomEventAuditLog(input: $input, condition: $condition) {
      id
      date
      author
      resource
      action
      payload
      ipAddress
      meetingRoom {
        id
        name
        meeting {
          id
          title
          titleLowerCase
          startDateTime
          endDateTime
          status
          description
          preferredRoomProviderId
          actualStartDateTime
          actualEndDateTime
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          productId
          isReadinessTest
          labels
          owner
          sellerOrganizationName
          sellerTeamName
          buyerTeamName
          sellerOrganizationNameLowerCase
          sellerTeamNameLowerCase
          buyerTeamNameLowerCase
          externalId
          metrics
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productMeetingsId
          meetingRoomProviderMeetingsId
          meetingRoomId
          __typename
        }
        meetingId
        provider {
          id
          name
          componentName
          description
          configuration
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        providerId
        providerMeetingId
        accessCode
        status
        startDateTime
        endDateTime
        actualStartDateTime
        actualEndDateTime
        roomCreatedAt
        roomDestroyedAt
        auditlogs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        videoEnabled
        transcriptionEnabled
        isReadinessTest
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        meetingRoomProviderMeetingRoomsId
        __typename
      }
      meetingRoomId
      profile {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      profileId
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      profileAuditLogsId
      meetingRoomAuditlogsId
      __typename
    }
  }
`;
export const createExportJob = /* GraphQL */ `
  mutation CreateExportJob(
    $input: CreateExportJobInput!
    $condition: ModelExportJobConditionInput
  ) {
    createExportJob(input: $input, condition: $condition) {
      id
      status
      profile {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      profileId
      auditLogs {
        items {
          id
          date
          author
          resource
          action
          payload
          ipAddress
          exportJobId
          profileId
          owner
          identityId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileExportJobAuditLogsId
          exportJobAuditLogsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      meetingInvitationType
      startDateTime
      endDateTime
      owner
      identityId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      profileExportJobsId
      __typename
    }
  }
`;
export const updateExportJob = /* GraphQL */ `
  mutation UpdateExportJob(
    $input: UpdateExportJobInput!
    $condition: ModelExportJobConditionInput
  ) {
    updateExportJob(input: $input, condition: $condition) {
      id
      status
      profile {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      profileId
      auditLogs {
        items {
          id
          date
          author
          resource
          action
          payload
          ipAddress
          exportJobId
          profileId
          owner
          identityId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileExportJobAuditLogsId
          exportJobAuditLogsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      meetingInvitationType
      startDateTime
      endDateTime
      owner
      identityId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      profileExportJobsId
      __typename
    }
  }
`;
export const deleteExportJob = /* GraphQL */ `
  mutation DeleteExportJob(
    $input: DeleteExportJobInput!
    $condition: ModelExportJobConditionInput
  ) {
    deleteExportJob(input: $input, condition: $condition) {
      id
      status
      profile {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      profileId
      auditLogs {
        items {
          id
          date
          author
          resource
          action
          payload
          ipAddress
          exportJobId
          profileId
          owner
          identityId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileExportJobAuditLogsId
          exportJobAuditLogsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      meetingInvitationType
      startDateTime
      endDateTime
      owner
      identityId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      profileExportJobsId
      __typename
    }
  }
`;
export const createExportJobAuditLog = /* GraphQL */ `
  mutation CreateExportJobAuditLog(
    $input: CreateExportJobAuditLogInput!
    $condition: ModelExportJobAuditLogConditionInput
  ) {
    createExportJobAuditLog(input: $input, condition: $condition) {
      id
      date
      author
      resource
      action
      payload
      ipAddress
      exportJob {
        id
        status
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        meetingInvitationType
        startDateTime
        endDateTime
        owner
        identityId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        profileExportJobsId
        __typename
      }
      exportJobId
      profile {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      profileId
      owner
      source {
        bucket
        region
        key
        __typename
      }
      identityId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      profileExportJobAuditLogsId
      exportJobAuditLogsId
      __typename
    }
  }
`;
export const updateExportJobAuditLog = /* GraphQL */ `
  mutation UpdateExportJobAuditLog(
    $input: UpdateExportJobAuditLogInput!
    $condition: ModelExportJobAuditLogConditionInput
  ) {
    updateExportJobAuditLog(input: $input, condition: $condition) {
      id
      date
      author
      resource
      action
      payload
      ipAddress
      exportJob {
        id
        status
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        meetingInvitationType
        startDateTime
        endDateTime
        owner
        identityId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        profileExportJobsId
        __typename
      }
      exportJobId
      profile {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      profileId
      owner
      source {
        bucket
        region
        key
        __typename
      }
      identityId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      profileExportJobAuditLogsId
      exportJobAuditLogsId
      __typename
    }
  }
`;
export const deleteExportJobAuditLog = /* GraphQL */ `
  mutation DeleteExportJobAuditLog(
    $input: DeleteExportJobAuditLogInput!
    $condition: ModelExportJobAuditLogConditionInput
  ) {
    deleteExportJobAuditLog(input: $input, condition: $condition) {
      id
      date
      author
      resource
      action
      payload
      ipAddress
      exportJob {
        id
        status
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        meetingInvitationType
        startDateTime
        endDateTime
        owner
        identityId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        profileExportJobsId
        __typename
      }
      exportJobId
      profile {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      profileId
      owner
      source {
        bucket
        region
        key
        __typename
      }
      identityId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      profileExportJobAuditLogsId
      exportJobAuditLogsId
      __typename
    }
  }
`;
export const createImportJob = /* GraphQL */ `
  mutation CreateImportJob(
    $input: CreateImportJobInput!
    $condition: ModelImportJobConditionInput
  ) {
    createImportJob(input: $input, condition: $condition) {
      id
      status
      source {
        bucket
        region
        key
        __typename
      }
      identityId
      result
      profile {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      profileId
      auditLogs {
        items {
          id
          date
          author
          resource
          action
          payload
          ipAddress
          importJobId
          profileId
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileJobAuditLogsId
          importJobAuditLogsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      lastStartDateTime
      lastEndDateTime
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      profileImportJobsId
      __typename
    }
  }
`;
export const updateImportJob = /* GraphQL */ `
  mutation UpdateImportJob(
    $input: UpdateImportJobInput!
    $condition: ModelImportJobConditionInput
  ) {
    updateImportJob(input: $input, condition: $condition) {
      id
      status
      source {
        bucket
        region
        key
        __typename
      }
      identityId
      result
      profile {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      profileId
      auditLogs {
        items {
          id
          date
          author
          resource
          action
          payload
          ipAddress
          importJobId
          profileId
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileJobAuditLogsId
          importJobAuditLogsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      lastStartDateTime
      lastEndDateTime
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      profileImportJobsId
      __typename
    }
  }
`;
export const deleteImportJob = /* GraphQL */ `
  mutation DeleteImportJob(
    $input: DeleteImportJobInput!
    $condition: ModelImportJobConditionInput
  ) {
    deleteImportJob(input: $input, condition: $condition) {
      id
      status
      source {
        bucket
        region
        key
        __typename
      }
      identityId
      result
      profile {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      profileId
      auditLogs {
        items {
          id
          date
          author
          resource
          action
          payload
          ipAddress
          importJobId
          profileId
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileJobAuditLogsId
          importJobAuditLogsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      lastStartDateTime
      lastEndDateTime
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      profileImportJobsId
      __typename
    }
  }
`;
export const createImportJobAuditLog = /* GraphQL */ `
  mutation CreateImportJobAuditLog(
    $input: CreateImportJobAuditLogInput!
    $condition: ModelImportJobAuditLogConditionInput
  ) {
    createImportJobAuditLog(input: $input, condition: $condition) {
      id
      date
      author
      resource
      action
      payload
      ipAddress
      importJob {
        id
        status
        source {
          bucket
          region
          key
          __typename
        }
        identityId
        result
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        lastStartDateTime
        lastEndDateTime
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        profileImportJobsId
        __typename
      }
      importJobId
      profile {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      profileId
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      profileJobAuditLogsId
      importJobAuditLogsId
      __typename
    }
  }
`;
export const updateImportJobAuditLog = /* GraphQL */ `
  mutation UpdateImportJobAuditLog(
    $input: UpdateImportJobAuditLogInput!
    $condition: ModelImportJobAuditLogConditionInput
  ) {
    updateImportJobAuditLog(input: $input, condition: $condition) {
      id
      date
      author
      resource
      action
      payload
      ipAddress
      importJob {
        id
        status
        source {
          bucket
          region
          key
          __typename
        }
        identityId
        result
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        lastStartDateTime
        lastEndDateTime
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        profileImportJobsId
        __typename
      }
      importJobId
      profile {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      profileId
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      profileJobAuditLogsId
      importJobAuditLogsId
      __typename
    }
  }
`;
export const deleteImportJobAuditLog = /* GraphQL */ `
  mutation DeleteImportJobAuditLog(
    $input: DeleteImportJobAuditLogInput!
    $condition: ModelImportJobAuditLogConditionInput
  ) {
    deleteImportJobAuditLog(input: $input, condition: $condition) {
      id
      date
      author
      resource
      action
      payload
      ipAddress
      importJob {
        id
        status
        source {
          bucket
          region
          key
          __typename
        }
        identityId
        result
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        lastStartDateTime
        lastEndDateTime
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        profileImportJobsId
        __typename
      }
      importJobId
      profile {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      profileId
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      profileJobAuditLogsId
      importJobAuditLogsId
      __typename
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      date
      title
      payload
      read
      dateRead
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      date
      title
      payload
      read
      dateRead
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      date
      title
      payload
      read
      dateRead
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createTranscription = /* GraphQL */ `
  mutation CreateTranscription(
    $input: CreateTranscriptionInput!
    $condition: ModelTranscriptionConditionInput
  ) {
    createTranscription(input: $input, condition: $condition) {
      id
      date
      title
      description
      startDateTime
      endDateTime
      result {
        bucket
        region
        key
        __typename
      }
      sizeInBytes
      numberOfTranscriptions
      isZipFile
      meeting {
        id
        title
        titleLowerCase
        startDateTime
        endDateTime
        status
        description
        preferredRoomProvider {
          id
          name
          componentName
          description
          configuration
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        preferredRoomProviderId
        actualStartDateTime
        actualEndDateTime
        room {
          id
          name
          meetingId
          providerId
          providerMeetingId
          accessCode
          status
          startDateTime
          endDateTime
          actualStartDateTime
          actualEndDateTime
          roomCreatedAt
          roomDestroyedAt
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          isReadinessTest
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          meetingRoomProviderMeetingRoomsId
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        videoEnabled
        transcriptionEnabled
        product {
          id
          name
          nameLowerCase
          description
          serviceInformation
          organizationId
          salesTeamId
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProductsId
          teamProductsId
          __typename
        }
        productId
        isReadinessTest
        labels
        owner
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        transcriptions {
          nextToken
          startedAt
          __typename
        }
        sellerOrganizationName
        sellerTeamName
        buyerTeamName
        sellerOrganizationNameLowerCase
        sellerTeamNameLowerCase
        buyerTeamNameLowerCase
        externalId
        metrics
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productMeetingsId
        meetingRoomProviderMeetingsId
        meetingRoomId
        __typename
      }
      meetingId
      team {
        id
        name
        nameLowerCase
        description
        memberRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingRelationships {
          nextToken
          startedAt
          __typename
        }
        products {
          nextToken
          startedAt
          __typename
        }
        labels
        privacyEnabled
        privacyEnableOtherTeams
        images {
          nextToken
          startedAt
          __typename
        }
        transcriptions {
          nextToken
          startedAt
          __typename
        }
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      teamId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      teamTranscriptionsId
      meetingTranscriptionsId
      __typename
    }
  }
`;
export const updateTranscription = /* GraphQL */ `
  mutation UpdateTranscription(
    $input: UpdateTranscriptionInput!
    $condition: ModelTranscriptionConditionInput
  ) {
    updateTranscription(input: $input, condition: $condition) {
      id
      date
      title
      description
      startDateTime
      endDateTime
      result {
        bucket
        region
        key
        __typename
      }
      sizeInBytes
      numberOfTranscriptions
      isZipFile
      meeting {
        id
        title
        titleLowerCase
        startDateTime
        endDateTime
        status
        description
        preferredRoomProvider {
          id
          name
          componentName
          description
          configuration
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        preferredRoomProviderId
        actualStartDateTime
        actualEndDateTime
        room {
          id
          name
          meetingId
          providerId
          providerMeetingId
          accessCode
          status
          startDateTime
          endDateTime
          actualStartDateTime
          actualEndDateTime
          roomCreatedAt
          roomDestroyedAt
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          isReadinessTest
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          meetingRoomProviderMeetingRoomsId
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        videoEnabled
        transcriptionEnabled
        product {
          id
          name
          nameLowerCase
          description
          serviceInformation
          organizationId
          salesTeamId
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProductsId
          teamProductsId
          __typename
        }
        productId
        isReadinessTest
        labels
        owner
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        transcriptions {
          nextToken
          startedAt
          __typename
        }
        sellerOrganizationName
        sellerTeamName
        buyerTeamName
        sellerOrganizationNameLowerCase
        sellerTeamNameLowerCase
        buyerTeamNameLowerCase
        externalId
        metrics
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productMeetingsId
        meetingRoomProviderMeetingsId
        meetingRoomId
        __typename
      }
      meetingId
      team {
        id
        name
        nameLowerCase
        description
        memberRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingRelationships {
          nextToken
          startedAt
          __typename
        }
        products {
          nextToken
          startedAt
          __typename
        }
        labels
        privacyEnabled
        privacyEnableOtherTeams
        images {
          nextToken
          startedAt
          __typename
        }
        transcriptions {
          nextToken
          startedAt
          __typename
        }
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      teamId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      teamTranscriptionsId
      meetingTranscriptionsId
      __typename
    }
  }
`;
export const deleteTranscription = /* GraphQL */ `
  mutation DeleteTranscription(
    $input: DeleteTranscriptionInput!
    $condition: ModelTranscriptionConditionInput
  ) {
    deleteTranscription(input: $input, condition: $condition) {
      id
      date
      title
      description
      startDateTime
      endDateTime
      result {
        bucket
        region
        key
        __typename
      }
      sizeInBytes
      numberOfTranscriptions
      isZipFile
      meeting {
        id
        title
        titleLowerCase
        startDateTime
        endDateTime
        status
        description
        preferredRoomProvider {
          id
          name
          componentName
          description
          configuration
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        preferredRoomProviderId
        actualStartDateTime
        actualEndDateTime
        room {
          id
          name
          meetingId
          providerId
          providerMeetingId
          accessCode
          status
          startDateTime
          endDateTime
          actualStartDateTime
          actualEndDateTime
          roomCreatedAt
          roomDestroyedAt
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          isReadinessTest
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          meetingRoomProviderMeetingRoomsId
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        videoEnabled
        transcriptionEnabled
        product {
          id
          name
          nameLowerCase
          description
          serviceInformation
          organizationId
          salesTeamId
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProductsId
          teamProductsId
          __typename
        }
        productId
        isReadinessTest
        labels
        owner
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        transcriptions {
          nextToken
          startedAt
          __typename
        }
        sellerOrganizationName
        sellerTeamName
        buyerTeamName
        sellerOrganizationNameLowerCase
        sellerTeamNameLowerCase
        buyerTeamNameLowerCase
        externalId
        metrics
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productMeetingsId
        meetingRoomProviderMeetingsId
        meetingRoomId
        __typename
      }
      meetingId
      team {
        id
        name
        nameLowerCase
        description
        memberRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingRelationships {
          nextToken
          startedAt
          __typename
        }
        products {
          nextToken
          startedAt
          __typename
        }
        labels
        privacyEnabled
        privacyEnableOtherTeams
        images {
          nextToken
          startedAt
          __typename
        }
        transcriptions {
          nextToken
          startedAt
          __typename
        }
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      teamId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      teamTranscriptionsId
      meetingTranscriptionsId
      __typename
    }
  }
`;
export const createTranscriptionJob = /* GraphQL */ `
  mutation CreateTranscriptionJob(
    $input: CreateTranscriptionJobInput!
    $condition: ModelTranscriptionJobConditionInput
  ) {
    createTranscriptionJob(input: $input, condition: $condition) {
      id
      date
      status
      result {
        bucket
        region
        key
        __typename
      }
      meetingInviteId
      auditLogs {
        items {
          id
          date
          author
          resource
          action
          payload
          ipAddress
          transcriptionJobId
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          transcriptionJobAuditLogsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      owner
      identityId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateTranscriptionJob = /* GraphQL */ `
  mutation UpdateTranscriptionJob(
    $input: UpdateTranscriptionJobInput!
    $condition: ModelTranscriptionJobConditionInput
  ) {
    updateTranscriptionJob(input: $input, condition: $condition) {
      id
      date
      status
      result {
        bucket
        region
        key
        __typename
      }
      meetingInviteId
      auditLogs {
        items {
          id
          date
          author
          resource
          action
          payload
          ipAddress
          transcriptionJobId
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          transcriptionJobAuditLogsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      owner
      identityId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteTranscriptionJob = /* GraphQL */ `
  mutation DeleteTranscriptionJob(
    $input: DeleteTranscriptionJobInput!
    $condition: ModelTranscriptionJobConditionInput
  ) {
    deleteTranscriptionJob(input: $input, condition: $condition) {
      id
      date
      status
      result {
        bucket
        region
        key
        __typename
      }
      meetingInviteId
      auditLogs {
        items {
          id
          date
          author
          resource
          action
          payload
          ipAddress
          transcriptionJobId
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          transcriptionJobAuditLogsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      owner
      identityId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createTranscriptionJobAuditLog = /* GraphQL */ `
  mutation CreateTranscriptionJobAuditLog(
    $input: CreateTranscriptionJobAuditLogInput!
    $condition: ModelTranscriptionJobAuditLogConditionInput
  ) {
    createTranscriptionJobAuditLog(input: $input, condition: $condition) {
      id
      date
      author
      resource
      action
      payload
      ipAddress
      transcriptionJob {
        id
        date
        status
        result {
          bucket
          region
          key
          __typename
        }
        meetingInviteId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        owner
        identityId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      transcriptionJobId
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      transcriptionJobAuditLogsId
      __typename
    }
  }
`;
export const updateTranscriptionJobAuditLog = /* GraphQL */ `
  mutation UpdateTranscriptionJobAuditLog(
    $input: UpdateTranscriptionJobAuditLogInput!
    $condition: ModelTranscriptionJobAuditLogConditionInput
  ) {
    updateTranscriptionJobAuditLog(input: $input, condition: $condition) {
      id
      date
      author
      resource
      action
      payload
      ipAddress
      transcriptionJob {
        id
        date
        status
        result {
          bucket
          region
          key
          __typename
        }
        meetingInviteId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        owner
        identityId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      transcriptionJobId
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      transcriptionJobAuditLogsId
      __typename
    }
  }
`;
export const deleteTranscriptionJobAuditLog = /* GraphQL */ `
  mutation DeleteTranscriptionJobAuditLog(
    $input: DeleteTranscriptionJobAuditLogInput!
    $condition: ModelTranscriptionJobAuditLogConditionInput
  ) {
    deleteTranscriptionJobAuditLog(input: $input, condition: $condition) {
      id
      date
      author
      resource
      action
      payload
      ipAddress
      transcriptionJob {
        id
        date
        status
        result {
          bucket
          region
          key
          __typename
        }
        meetingInviteId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        owner
        identityId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      transcriptionJobId
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      transcriptionJobAuditLogsId
      __typename
    }
  }
`;
export const createTranscriptionAdminJob = /* GraphQL */ `
  mutation CreateTranscriptionAdminJob(
    $input: CreateTranscriptionAdminJobInput!
    $condition: ModelTranscriptionAdminJobConditionInput
  ) {
    createTranscriptionAdminJob(input: $input, condition: $condition) {
      id
      date
      status
      result {
        bucket
        region
        key
        __typename
      }
      meetingId
      meetingInviteId
      auditLogs {
        items {
          id
          date
          author
          resource
          action
          payload
          ipAddress
          transcriptionAdminJobId
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          transcriptionAdminJobAuditLogsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      owner
      identityId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateTranscriptionAdminJob = /* GraphQL */ `
  mutation UpdateTranscriptionAdminJob(
    $input: UpdateTranscriptionAdminJobInput!
    $condition: ModelTranscriptionAdminJobConditionInput
  ) {
    updateTranscriptionAdminJob(input: $input, condition: $condition) {
      id
      date
      status
      result {
        bucket
        region
        key
        __typename
      }
      meetingId
      meetingInviteId
      auditLogs {
        items {
          id
          date
          author
          resource
          action
          payload
          ipAddress
          transcriptionAdminJobId
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          transcriptionAdminJobAuditLogsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      owner
      identityId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteTranscriptionAdminJob = /* GraphQL */ `
  mutation DeleteTranscriptionAdminJob(
    $input: DeleteTranscriptionAdminJobInput!
    $condition: ModelTranscriptionAdminJobConditionInput
  ) {
    deleteTranscriptionAdminJob(input: $input, condition: $condition) {
      id
      date
      status
      result {
        bucket
        region
        key
        __typename
      }
      meetingId
      meetingInviteId
      auditLogs {
        items {
          id
          date
          author
          resource
          action
          payload
          ipAddress
          transcriptionAdminJobId
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          transcriptionAdminJobAuditLogsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      owner
      identityId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createTranscriptionAdminJobAuditLog = /* GraphQL */ `
  mutation CreateTranscriptionAdminJobAuditLog(
    $input: CreateTranscriptionAdminJobAuditLogInput!
    $condition: ModelTranscriptionAdminJobAuditLogConditionInput
  ) {
    createTranscriptionAdminJobAuditLog(input: $input, condition: $condition) {
      id
      date
      author
      resource
      action
      payload
      ipAddress
      transcriptionAdminJob {
        id
        date
        status
        result {
          bucket
          region
          key
          __typename
        }
        meetingId
        meetingInviteId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        owner
        identityId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      transcriptionAdminJobId
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      transcriptionAdminJobAuditLogsId
      __typename
    }
  }
`;
export const updateTranscriptionAdminJobAuditLog = /* GraphQL */ `
  mutation UpdateTranscriptionAdminJobAuditLog(
    $input: UpdateTranscriptionAdminJobAuditLogInput!
    $condition: ModelTranscriptionAdminJobAuditLogConditionInput
  ) {
    updateTranscriptionAdminJobAuditLog(input: $input, condition: $condition) {
      id
      date
      author
      resource
      action
      payload
      ipAddress
      transcriptionAdminJob {
        id
        date
        status
        result {
          bucket
          region
          key
          __typename
        }
        meetingId
        meetingInviteId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        owner
        identityId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      transcriptionAdminJobId
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      transcriptionAdminJobAuditLogsId
      __typename
    }
  }
`;
export const deleteTranscriptionAdminJobAuditLog = /* GraphQL */ `
  mutation DeleteTranscriptionAdminJobAuditLog(
    $input: DeleteTranscriptionAdminJobAuditLogInput!
    $condition: ModelTranscriptionAdminJobAuditLogConditionInput
  ) {
    deleteTranscriptionAdminJobAuditLog(input: $input, condition: $condition) {
      id
      date
      author
      resource
      action
      payload
      ipAddress
      transcriptionAdminJob {
        id
        date
        status
        result {
          bucket
          region
          key
          __typename
        }
        meetingId
        meetingInviteId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        owner
        identityId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      transcriptionAdminJobId
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      transcriptionAdminJobAuditLogsId
      __typename
    }
  }
`;
export const createTranscriptionEventAuditLog = /* GraphQL */ `
  mutation CreateTranscriptionEventAuditLog(
    $input: CreateTranscriptionEventAuditLogInput!
    $condition: ModelTranscriptionEventAuditLogConditionInput
  ) {
    createTranscriptionEventAuditLog(input: $input, condition: $condition) {
      id
      date
      author
      resource
      action
      payload
      ipAddress
      meetingInvite {
        id
        meetingId
        provider {
          id
          name
          componentName
          description
          configuration
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        providerId
        title
        startDateTime
        endDateTime
        status
        description
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        videoEnabled
        transcriptionEnabled
        product {
          id
          name
          nameLowerCase
          description
          serviceInformation
          organizationId
          salesTeamId
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProductsId
          teamProductsId
          __typename
        }
        productId
        isReadinessTest
        meetingRoomId
        providerSessionId
        providerJoinData
        sessionStatus
        actualStartDateTime
        actualEndDateTime
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        owner
        meetingInvitationType
        teamId
        isInterested
        feedbackSummary
        contactRequestTimeframe
        contactPhoneNumber
        contactEmail
        sellerOrganizationName
        sellerTeamName
        buyerTeamName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productMeetingInvitesId
        profileMeetingInvitesId
        meetingRoomProviderMeetingInvitesId
        __typename
      }
      meetingInviteId
      profile {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      profileId
      authorProfileId
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      profileTranscriptionLogsId
      meetingInviteTranscriptionLogsId
      __typename
    }
  }
`;
export const updateTranscriptionEventAuditLog = /* GraphQL */ `
  mutation UpdateTranscriptionEventAuditLog(
    $input: UpdateTranscriptionEventAuditLogInput!
    $condition: ModelTranscriptionEventAuditLogConditionInput
  ) {
    updateTranscriptionEventAuditLog(input: $input, condition: $condition) {
      id
      date
      author
      resource
      action
      payload
      ipAddress
      meetingInvite {
        id
        meetingId
        provider {
          id
          name
          componentName
          description
          configuration
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        providerId
        title
        startDateTime
        endDateTime
        status
        description
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        videoEnabled
        transcriptionEnabled
        product {
          id
          name
          nameLowerCase
          description
          serviceInformation
          organizationId
          salesTeamId
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProductsId
          teamProductsId
          __typename
        }
        productId
        isReadinessTest
        meetingRoomId
        providerSessionId
        providerJoinData
        sessionStatus
        actualStartDateTime
        actualEndDateTime
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        owner
        meetingInvitationType
        teamId
        isInterested
        feedbackSummary
        contactRequestTimeframe
        contactPhoneNumber
        contactEmail
        sellerOrganizationName
        sellerTeamName
        buyerTeamName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productMeetingInvitesId
        profileMeetingInvitesId
        meetingRoomProviderMeetingInvitesId
        __typename
      }
      meetingInviteId
      profile {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      profileId
      authorProfileId
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      profileTranscriptionLogsId
      meetingInviteTranscriptionLogsId
      __typename
    }
  }
`;
export const deleteTranscriptionEventAuditLog = /* GraphQL */ `
  mutation DeleteTranscriptionEventAuditLog(
    $input: DeleteTranscriptionEventAuditLogInput!
    $condition: ModelTranscriptionEventAuditLogConditionInput
  ) {
    deleteTranscriptionEventAuditLog(input: $input, condition: $condition) {
      id
      date
      author
      resource
      action
      payload
      ipAddress
      meetingInvite {
        id
        meetingId
        provider {
          id
          name
          componentName
          description
          configuration
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        providerId
        title
        startDateTime
        endDateTime
        status
        description
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        videoEnabled
        transcriptionEnabled
        product {
          id
          name
          nameLowerCase
          description
          serviceInformation
          organizationId
          salesTeamId
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProductsId
          teamProductsId
          __typename
        }
        productId
        isReadinessTest
        meetingRoomId
        providerSessionId
        providerJoinData
        sessionStatus
        actualStartDateTime
        actualEndDateTime
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        owner
        meetingInvitationType
        teamId
        isInterested
        feedbackSummary
        contactRequestTimeframe
        contactPhoneNumber
        contactEmail
        sellerOrganizationName
        sellerTeamName
        buyerTeamName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productMeetingInvitesId
        profileMeetingInvitesId
        meetingRoomProviderMeetingInvitesId
        __typename
      }
      meetingInviteId
      profile {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      profileId
      authorProfileId
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      profileTranscriptionLogsId
      meetingInviteTranscriptionLogsId
      __typename
    }
  }
`;
