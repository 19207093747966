import React, { useState } from 'react';
// import { useForm } from 'react-final-form';
// import { Form, FormProps } from 'react-final-form';
import {
    required,
    Button,
    SaveButton,
    useNotify,
    FormWithRedirect,
    ShowProps,
    EditProps,
    useRecordContext,
    ListActionsProps
} from 'react-admin';
import { DateTime } from 'luxon';
import { Logger } from '@aws-amplify/core';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import { DateTimeInput as MaterialDateTimeInput } from './MaterialDatePicker';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { startTranscriptionAdminJob, validateExportJob } from '../lib/helpers';
import { AWS_TIMESTAMP_AS_LUXON_FORMAT } from '../constants';


const logger = new Logger('CreateTranscriptionAdminJobButton');


function CreateTranscriptionAdminJobButton(props: ShowProps | EditProps | ListActionsProps): JSX.Element {

  const record = useRecordContext();
  if (!record) {
    return <></>;
  }
  const [loading, setLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false); // TODO form is validate check
  // const [create, { loading }] = useCreate('posts');
  const notify = useNotify();
  // const form = useForm();

  const handleClick = () => {
      setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async (data: any) => {
    const errors = validateExportJob(data);
    if (Object.keys(errors).length > 0) {
      logger.warn("found validation errors", errors);
      return errors;
    } else {
      setShowDialog(false);
      logger.info("data", data);

      try {
        if (data["startDateTime"].getSeconds() > 0) {
          data["startDateTime"].setSeconds(0);
        }
        if (data["endDateTime"].getSeconds() > 0) {
          data["endDateTime"].setSeconds(0);
        }
      } catch(err) {
        logger.warn("failed to set seconds");
      }
      try {
        if (data["startDateTime"].getMilliseconds() > 0) {
          data["startDateTime"].setMilliseconds(0);
        }
        if (data["endDateTime"].getMilliseconds() > 0) {
          data["endDateTime"].setMilliseconds(0);
        }
      } catch(err) {
        logger.warn("failed to set milliseconds");
      }
  
      const startDateTime = DateTime.fromJSDate(data["startDateTime"]).toUTC().toFormat(AWS_TIMESTAMP_AS_LUXON_FORMAT);
      const endDateTime = DateTime.fromJSDate(data["endDateTime"]).toUTC().toFormat(AWS_TIMESTAMP_AS_LUXON_FORMAT);
      let teamId = "";
      if (record) {
        teamId = `${record.id}`;
      }

      const input = {
        startDateTime,
        endDateTime,
        ...(teamId && { teamId }),
      };
      logger.info("input", input);
      startTranscriptionAdminJob(input).then(
        (startResults) => {
          logger.info("startResults", startResults);
          notify('Job started.', 'info');
        }
      ).catch(
        (err: Error) => {
          notify(`${err}`, 'error');
        }
      ).finally(
        () => {
          setShowDialog(false);
          setLoading(false);
        }
      );
    }
  };
  return (
    <>
      <Button onClick={handleClick} label="Create Transcriptions">
        <IconContentAdd />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Create transcription job"
      >
        <DialogTitle>Create transcription job</DialogTitle>
        <FormWithRedirect
          resource="transcriptions"
          save={handleSubmit}
          render={({
            handleSubmitWithRedirect,
            // pristine,
            saving,
          }) => (
            <>
              <DialogContent>
                <MaterialDateTimeInput
                  source="startDateTime"
                  validate={required("Start date and time is required.")}
                  options={{ format: 'MM/dd/yyyy, hh:mm a', ampm: true, clearable: true }}
                  isRequired
                /> 
                <MaterialDateTimeInput
                  source="endDateTime"
                  validate={required("End date and time is required.")}
                  options={{ format: 'MM/dd/yyyy, hh:mm a', ampm: true, clearable: true }}
                  isRequired
                />
              </DialogContent>
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={handleCloseClick}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  label="Start"
                  handleSubmitWithRedirect={
                    handleSubmitWithRedirect
                  }
                  // pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
}

export default CreateTranscriptionAdminJobButton;
