import React from "react";
import type { ImageFieldProps } from "react-admin";
import { useStorageField } from "../hooks/useStorageField";
// import { Avatar as MaterialAvatar } from "@material-ui/core";
import Avatar from "react-avatar";

type Props = {
  source?: string;
  record?: any;
  storageOptions?: any;
  size?: string;
  round?: boolean;
} & ImageFieldProps;

export const AmplifyImageAvatar: React.FC<Props> = ({
  source,
  record = {},
  storageOptions = {},
  size = "48px",
  round = true,
  ...props
}) => {
  const fieldProps = useStorageField({ source, record, storageOptions });

  if (!fieldProps) {
    return null;
  }
  return <Avatar src={record.image._url} size={size} round={Boolean(round)} />;
};
