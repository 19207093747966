import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  AutocompleteInput,
  Datagrid,
  DateField,
  FilterButton,
  ReferenceField,
  ReferenceInput,
  RefreshButton,
  Show,
  ShowButton,
  SimpleShowLayout,
  TextField,
  useDataProvider,
  useNotify,
  useRefresh,
} from "react-admin";
import { Storage } from "@aws-amplify/storage";
import type { ListProps, ShowProps } from "react-admin";
import { AmplifyFileField } from "./AmplifyFileField";
import { DateTimeInput as MaterialDateTimeInput } from "./MaterialDatePicker";
import {
  RealTimeList,
  RecordListEvent,
  useSubscribe,
} from "@react-admin/ra-realtime";
import { JsonField } from "react-admin-json-view";
import { onCreateExportJobAuditLog } from "../graphql/subscriptions";
import { API, graphqlOperation, Logger } from "aws-amplify";
import { MeetingsStateContext } from "../App";
import { LogPayloadField } from "./CustomFields";
import TopToolbar from "./TopToolbar";
import { useLocation } from "react-router-dom";


const logger = new Logger("ExportJobAudiLog");
const defaultQuery = "listExportJobAuditLogs";

const filters = [
  /* <ReferenceInput
    source="profileId"
    reference="profiles"
    label="User"
    filterToQuery={(searchText) => ({
      listProfiles: { fullName: searchText },
    })}
    perPage={700}
    resettable
    alwaysOn
    >
    <AutocompleteInput optionText="fullName" />
  </ReferenceInput>, */
  <ReferenceInput
    source="exportJobId"
    reference="exportJobs"
    label="Export job"
    filterToQuery={(searchText) => ({
      listExportJobs: { id: searchText },
    })}
    perPage={500}
    resettable
    >
    <AutocompleteInput optionText="id" />
  </ReferenceInput>,
  <MaterialDateTimeInput
    label="Begin Date Range"
    source="date"
    options={{ format: 'MM/dd/yyyy, hh:mm a', ampm: true, clearable: true }}
  />,
  <MaterialDateTimeInput
    label="End Date Range"
    source="endDate"
    options={{ format: 'MM/dd/yyyy, hh:mm a', ampm: true, clearable: true }}
  />,
];

const ListActions = (props: any) => {
  const {
    className,
    resource,
    filters,
    filterValues,
    displayedFilters,
    showFilter,
  } = props;
  return (
    <TopToolbar className={className}>
      <FilterButton
        resource={resource} 
        filters={filters}
        filterValues={filterValues}
        displayedFilters={displayedFilters}
        showFilter={showFilter}
      />
      <RefreshButton />
    </TopToolbar>
  );
};

function useUrlQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

export const ExportJobAuditLogList = (props: ListProps) => {
  const [query, setQuery] = useState(defaultQuery);
  const refresh = useRefresh(); // TODO refresh at interval of implement realtime updates
  const notify = useNotify();
  const urlQuery = useUrlQuery();
  const meetingsState = useContext(MeetingsStateContext);
  const {
    currentProfile,
  } = meetingsState;
  const dataProvider = useDataProvider();

  const handleEventReceived = (event: RecordListEvent) => {
    // const count = get(event, "payload.ids.length", 1);
    logger.info("handleEventReceived event", event);
    // 'List refreshed with %{smart_count} %{name} %{type} by another user'
    // notify("ra-realtime.notification.list.refreshed", "info", event);
    notify("ra-realtime.action.refresh", "info");
    refresh();
  };

  useSubscribe("resource/exportJobAuditLogs", (event) => {
      logger.info("useSubscribe event", event);
      // TODO download file
  });

  useEffect(
    () => {
      if (currentProfile?.userId) {    
        const subscription = API.graphql(
          graphqlOperation(onCreateExportJobAuditLog, { owner: currentProfile?.userId })
        ).subscribe({
          next: ({ value }: any) => {
            logger.info("value.data.onCreateExportJobAuditLog", value.data.onCreateExportJobAuditLog);
            logger.info("value.data.onCreateExportJobAuditLog.id", value.data.onCreateExportJobAuditLog.id);
            const {
              source,
            } = value.data.onCreateExportJobAuditLog;
            if (source) {
              logger.info("source", source);
              const {
                key,
              } = source;
              logger.info("key", key);
              const exportJobId = urlQuery.get("exportJobId");
              logger.info("exportJobId", exportJobId);
              if (key) {
                Storage.get(key, { level: "protected" }).then(
                  (downloadUrl) => {
                    window.open(downloadUrl, "_blank");
                  }
                ).catch(
                  (err) => {
                    logger.error("Storage.get error", err);
                  }
                );
              }
            }
            dataProvider.publish(
              "resource/exportJobAuditLogs",
              {
                type: "created",
                topic: "resource/exportJobAuditLogs",
                payload: { ids: [ value.data.onCreateExportJobAuditLog.id ]},
                date: new Date(),
              },
            );
          },
          error: (error: Error) => {
            logger.warn("onCreateExportJobAuditLog failed", error);
          }
        });
        return () => {
          subscription.unsubscribe();
        }
      }
    },
    [ currentProfile?.userId ]
  );
  
  return (
    <RealTimeList
      {...props}
      filters={filters}
      filterDefaultValues={{ profileId: currentProfile?.id }}
      actions={<ListActions />}
      onEventReceived={handleEventReceived}
    >
      <Datagrid>
        <DateField source="date" showTime={true} sortable={true} />
        <TextField source="exportJob.id" label="Job ID" sortable={false} />
        <TextField source="resource" sortable={false} />
        <TextField source="action" sortable={false} />
        <LogPayloadField source="payload" label="Message" sortable={false} />
        <ShowButton />
      </Datagrid>
    </RealTimeList>
  );
};

export const ExportJobAuditLogShow = (props: ShowProps) => {

  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="id" label="ID" />
        <DateField source="date" showTime={true} />
        <TextField source="author" />
        <TextField source="resource" />
        <TextField source="action" />
        <JsonField
          source="payload"
          addLabel={true}
          jsonString={true}
          reactJsonOptions={{
            // shapeshifter:inverted
            theme: "shapeshifter",
            name: null,
            collapsed: true,
            enableClipboard: false,
            displayDataTypes: false,
          }}
        />
        <TextField source="ipAddress" label="IP address" />
        <ReferenceField
          source="exportJobId"
          reference="exportJobs"
          label="Export job"
          link="show"
        >
          <TextField source="id" />
        </ReferenceField>
        <ReferenceField
          source="profileId"
          reference="profiles"
          label="User"
          link="show"
        >
          <TextField source="fullName" />
        </ReferenceField>
        <AmplifyFileField
          source="source"
          download
          storageOptions={{ level: "protected" }}
        />
        <DateField source="createdAt" showTime={true} />
        <DateField source="updatedAt" showTime={true} />
      </SimpleShowLayout>
    </Show>
  );
}
