import React, { useState } from "react";
import {
  Create,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  ReferenceField,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  required,
  email as emailValidator,
  BooleanField,
  BooleanInput,
} from "react-admin";
import type {
  CreateProps,
  EditProps,
  ListProps,
  ShowProps,
} from "react-admin";
import { CustomList, ListPagination } from "./CustomList";
import { DateTimeInput as MaterialDateTimeInput } from "./MaterialDatePicker";
import NoDeleteToolbar from "./NoDeleteToolbar";
import BooleanCheckboxInput from "./BooleanCheckboxInput";

const defaultQuery = "listQuestionResponses";
const validateRequired = [required()];

export const QuestionResponseList = (props: ListProps) => {
  const [query, setQuery] = useState(defaultQuery);

  return (
    <CustomList
      {...props}
      bulkActionButtons={false}
      pagination={<ListPagination />}
    >
      <Datagrid>
        <TextField source="question.question" label="Question" />
        <TextField source="response" label="Response" />
        <DateField source="updatedAt" showTime={false} sortable={false} />
        <ShowButton />
        <EditButton />
      </Datagrid>
    </CustomList>
  );
};

export const QuestionResponseShow = (props: ShowProps) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" label="ID" fullWidth />
      <ReferenceField
        label="Question"
        source="questionId"
        reference="productQuestions"
        fullWidth
      >
        <TextField source="question" />
      </ReferenceField>
      <TextField source="response" />
      <TextField source="privateNotes" />
      <ReferenceField label="User" source="profileId" reference="profiles">
        <TextField source="fullName" />
      </ReferenceField>
      <ReferenceField label="Meeting" source="meetingId" reference="meetings">
        <TextField source="title" />
      </ReferenceField>
      <ReferenceField label="Meeting Invite" source="meetingInviteId" reference="meetingInvites">
        <TextField source="title" />
      </ReferenceField>
      <TextField source="contactPhoneNumber" />
      <TextField source="contactEmail" />
      <BooleanField
              source="followupMeetingRequested"
              label="Followup meeting requested?"
              defaultValue="No"
            />
      <DateField source="contactRequestDateTime" showTime={true} />
      <DateField source="createdAt" showTime={true} />
      <DateField source="updatedAt" showTime={true} />
    </SimpleShowLayout>
  </Show>
);

const validateEmail = [emailValidator()];

export const QuestionResponseEdit = (props: EditProps) => (
  <Edit
    {...props}
    undoable={false}
    mutationMode="pessimistic"
  >
    <SimpleForm
      toolbar={<NoDeleteToolbar />}
    >
      <TextInput source="id" label="ID" disabled fullWidth />
      <ReferenceInput
        source="questionId"
        reference="productQuestions"
        label="Question"
        perPage={500}
        alwaysOn
        isRequired
        fullWidth
        validate={validateRequired}
      >
        <AutocompleteInput optionText="question" />
      </ReferenceInput>
      <TextInput
        source="response"
        fullWidth
        minRows={4}
      />
      <TextInput
        source="privateNotes"
        fullWidth
        minRows={4}
      />
      <ReferenceInput
        source="profileId"
        reference="profiles"
        label="User"
        perPage={700}
        filterToQuery={(searchText) => ({
          listProfiles: { fullName: searchText },
        })}
        alwaysOn
        isRequired
        validate={validateRequired}
      >
        <AutocompleteInput optionText="fullName" />
      </ReferenceInput>
      <ReferenceInput
        source="meetingId"
        reference="meetings"
        label="Meeting"
        perPage={500}
        alwaysOn
        isRequired
        validate={validateRequired}
      >
        <AutocompleteInput optionText="title" />
      </ReferenceInput>
      <ReferenceInput
        source="meetingInviteId"
        reference="meetingInvites"
        label="Meeting Invite"
        perPage={500}
        alwaysOn
        isRequired
        validate={validateRequired}
      >
        <AutocompleteInput optionText="title" />
      </ReferenceInput>
      <TextInput
        source="contactPhoneNumber"
      />
      <TextInput
        source="contactEmail"
        fullWidth
        validate={validateEmail}
      />
      <BooleanCheckboxInput
          source="followupMeetingRequested"
          label="Followup meeting requested?"
          defaultValue="No"
        />
      <MaterialDateTimeInput
        source="contactRequestDateTime"
        options={{ format: 'MM/dd/yyyy, hh:mm a', ampm: true, clearable: true }}
      />
    </SimpleForm>
  </Edit>
);

export const QuestionResponseCreate = (props: CreateProps) => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput
        source="questionId"
        reference="productQuestions"
        label="Question"
        perPage={500}
        alwaysOn
        isRequired
        fullWidth
        validate={validateRequired}
      >
        <AutocompleteInput optionText="question" />
      </ReferenceInput>
      <TextInput
        source="response"
        isRequired
        fullWidth
        minRows={4}
      />
      <TextInput
        source="privateNotes"
        fullWidth
        minRows={4}
      />
      <ReferenceInput
        source="profileId"
        reference="profiles"
        label="User"
        perPage={700}
        filterToQuery={(searchText) => ({
          listProfiles: { fullName: searchText },
        })}
        alwaysOn
        isRequired
        validate={validateRequired}
      >
        <AutocompleteInput optionText="fullName" />
      </ReferenceInput>
      <ReferenceInput
        source="meetingId"
        reference="meetings"
        label="Meeting"
        perPage={500}
        alwaysOn
        isRequired
        validate={validateRequired}
      >
        <AutocompleteInput optionText="title" />
      </ReferenceInput>
      <ReferenceInput
        source="meetingInviteId"
        reference="meetingInvites"
        label="Meeting Invite"
        perPage={500}
        alwaysOn
        isRequired
        validate={validateRequired}
      >
        <AutocompleteInput optionText="title" />
      </ReferenceInput>
      <TextInput
        source="contactPhoneNumber"
      />
      <TextInput
        source="contactEmail"
        fullWidth
        validate={validateEmail}
      />
      <BooleanInput
          source="followupMeetingRequested"
          label="Followup meeting requested?"
          defaultValue="No"
        />
      <MaterialDateTimeInput
        source="contactRequestDateTime"
        options={{ format: 'MM/dd/yyyy, hh:mm a', ampm: true, clearable: true }}
      />
    </SimpleForm>
  </Create>
);
