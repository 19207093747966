import React, { useState } from "react";
import {
  BooleanField,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  ReferenceField,
  ReferenceManyField,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  required,
} from "react-admin";
import type { EditProps, ListProps, ShowProps } from "react-admin";
import DeleteWithConfirmToolbar from "./DeleteWithConfirmToolbar";
import { CustomList, ListPagination } from "./CustomList";

const defaultQuery = "listMeetingRooms";
const validateRequired = [required()];

export const MeetingRoomList = (props: ListProps) => {
  const [query, setQuery] = useState(defaultQuery);

  return (
    <CustomList
      {...props}
      bulkActionButtons={false}
      pagination={<ListPagination />}
    >
      <Datagrid>
        <TextField source="name" sortable={false} />
        <BooleanField
          source="isReadinessTest"
          label="Test?"
        />
        <DateField source="startDateTime" showTime={true} sortable={true} />
        <DateField source="endDateTime" showTime={true} sortable={false} />
        <ShowButton />
        <EditButton />
      </Datagrid>
    </CustomList>
  );
};

export const MeetingRoomShow = (props: ShowProps) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" label="ID" />
      <TextField source="name" />
      <TextField source="status" />
      <TextField source="accessCode" />
      <TextField source="providerMeetingId" label="Provider meeting room ID" />
      <DateField showTime={true} source="startDateTime" />
      <DateField showTime={true} source="endDateTime" />
      <DateField showTime={true} source="actualStartDateTime" />
      <DateField showTime={true} source="actualEndDateTime" />
      <BooleanField
        source="videoEnabled"
        label="Video enabled?"
        defaultValue="No"
      />
      <BooleanField
        source="privacyEnabled"
        label="Enable privacy mode?"
        defaultValue="No"
      />
      <BooleanField
        source="transcriptionEnabled"
        label="Enable transcription?"
        defaultValue="No"
      />
      <BooleanField
        source="isReadinessTest"
        label="Is readiness test?"
        defaultValue="No"
      />
      <ReferenceField
        source="meetingId"
        reference="meetings"
        label="Meeting"
        link="show"
      >
        <TextField source="title" />
      </ReferenceField>
      <ReferenceField
        source="providerId"
        reference="meetingRoomProviders"
        label="Provider"
      >
        <TextField source="name" />
      </ReferenceField>
   
      <ReferenceManyField
        reference="meetingRoomEventAuditLogs"
        target="meetingRoomId"
        label="Audit logs"
        perPage={50}
        filter={{ listMeetingRoomEventAuditLogsByMeetingRoomId: {} }} // TODO sort
      >
        <Datagrid>
          <DateField showTime={true} source="date" />
          <TextField source="author" label="Author" />
          <TextField source="resource" label="Resource" />
          <TextField source="action" label="Action" />
          <ReferenceField
            source="profileId"
            reference="profiles"
            label="User"
            link
          >
            <TextField source="fullName" />
          </ReferenceField>
          <ShowButton />
          <EditButton />
        </Datagrid>
      </ReferenceManyField>

    
      <DateField source="createdAt" showTime={true} />
      <DateField source="updatedAt" showTime={true} />
    </SimpleShowLayout>
  </Show>
);

export const MeetingRoomEdit = (props: EditProps) => (
  <Edit
    {...props}
    undoable={false}
    mutationMode="pessimistic"
  >
    <SimpleForm
      toolbar={<DeleteWithConfirmToolbar />}
    >
      <TextInput source="id" label="ID" disabled fullWidth />
      <TextInput
        source="accessCode"
        isRequired
        validate={validateRequired}
      />
    </SimpleForm>
  </Edit>
);
