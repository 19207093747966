import React, { useCallback, useContext, useEffect, useState } from "react";
// TODO improve layout
// TODO delete confirmation
// TODO add new team dialog - https://codesandbox.io/s/react-admin-v3-advanced-recipes-quick-createpreview-voyci?file=/src/comments/PostQuickCreateButton.js
// TODO add new user dialog - https://codesandbox.io/s/react-admin-v3-advanced-recipes-quick-createpreview-voyci?file=/src/comments/PostQuickCreateButton.js
import { 
  CreateButton,
  Edit,
  EditButton,
  EditProps,
  required,
  ShowButton,
  useDataProvider,
  useMutation,
  useNotify,
  usePermissions,
  useRedirect,
} from "react-admin";
import {
  API,
  graphqlOperation,
  Logger,
} from "aws-amplify";
import * as Sentry from "@sentry/react";
import {
  ListMeetingInvitesByMeetingIdQuery,
  ListTeamImagesByTeamIdQuery,
  ListTeamMeetingRelationshipsByTeamIdQuery,
  MeetingInvitationType,
  MeetingInvite,
  MeetingStatus,
  ModelTeamMeetingRelationshipConnection,
  Profile,
  TeamImage,
  TeamMeetingRelationshipType,
  TeamRelationship,
} from "../API";
import type {
  CreateTeamMemberRelationshipInput,
  Meeting,
  TeamMeetingRelationship,
  TeamMemberRelationship,
} from "../API";
import {
  makeStyles,
  Grid,
  List,
  ListItem,
  Avatar as MaterialAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Checkbox,
  Button,
  Chip,
  Typography,
  ListSubheader,
} from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import AddIcon from "@material-ui/icons/Add";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import type {
  Theme,
} from "@material-ui/core";
// @ts-ignore
import { CompactForm, RaBox, RaGrid } from "ra-compact-ui";
import { createStyles } from "@material-ui/styles";
import { DateTimeInput as MaterialDateTimeInput } from "../components/MaterialDatePicker";
import { AmplifyImageAvatar } from "../components/AmplifyImageAvatar";
import { MeetingsStateContext } from "../App";
import { meetingColumns } from "../constants";
import { Link, useParams } from "react-router-dom";
import {
  onCreateTeamMeetingRelationship,
  onCreateTeamMemberRelationship,
  onDeleteTeamMeetingRelationship,
  onDeleteTeamMemberRelationship,
} from "../graphql/subscriptions";
// import { useSubscribe } from "@react-admin/ra-realtime";
import NoDeleteToolbar from "../components/NoDeleteToolbar";
import { DateTime } from "luxon";
import {
  listMeetingInvitesByMeetingId,
  listTeamImagesByTeamId,
  listTeamMeetingRelationshipsByTeamId,
} from "../graphql/queries";
import { GraphQLResult } from "@aws-amplify/api";
import { GraphQLErrorResponse } from "../types";
import {
  awsDateTimeStringToDateTime,
  getAllProfilesWithoutMeetingConflicts,
} from "../lib/helpers";
import { ShowScheduledMeetingsButton } from "../components/ShowScheduledMeetingsButton";
import { ArrowForwardIos as ArrowForwardIosIcon,  ArrowBackIos as  ArrowBackIosIcon } from "@material-ui/icons";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import TopToolbar from "../components/TopToolbar";

const ReactSwal = withReactContent(Swal);
const logger = new Logger("MeetingScheduler");


function MeetingSchedulerPage() {

  const [meeting, setMeeting] = useState<Meeting|null>(null);
  const [scheduledMeetingsForBuyer, setScheduledMeetingsForBuyer] = useState<Meeting[]>([]);
  const [scheduledMeetingsForSeller, setScheduledMeetingsForSeller] = useState<Meeting[]>([]);
  const [meetingInvitesForBuyer, setMeetingInvitesForBuyer] = useState<MeetingInvite[]>([]);
  const [meetingInvitesForSeller, setMeetingInvitesForSeller] = useState<MeetingInvite[]>([]);
  const [buyerTeamRelationship, setBuyerTeamRelationship] = useState<TeamMeetingRelationship|null>(null);
  const [sellerTeamRelationship, setSellerTeamRelationship] = useState<TeamMeetingRelationship|null>(null);
  const [buyerTeamImage, setBuyerTeamImage] = useState<TeamImage|null>(null);
  const [sellerTeamImage, setSellerTeamImage] = useState<TeamImage|null>(null);
  const [buyerTeamMemberRelationships, setBuyerTeamMemberRelationships] = useState<TeamMemberRelationship[]>([]);
  const [sellerTeamMemberRelationships, setSellerTeamMemberRelationships] = useState<TeamMemberRelationship[]>([]);
  const [lastTeamMeetingRelationshipChange, setLastTeamMeetingRelationshipChange] = useState<number>(0);
  const [lastTeamMemberRelationshipChange, setLastTeamMemberRelationshipChange] = useState<number>(0);
  const [allProfiles, setAllProfiles] = useState<Profile[]>([]);
  const [checkedProfileIds, setCheckedProfileIds] = useState<string[]>([]);
  const [checkedSellerTeamMemberRelationshipIds, setCheckedSellerTeamMemberRelationshipIds] = useState<string[]>([]);
  const [checkedBuyerTeamMemberRelationshipIds, setCheckedBuyerTeamMemberRelationshipIds] = useState<string[]>([]);

  const notify = useNotify();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const [mutate] = useMutation();
  const params: { meetingId: string } = useParams();
  // const { loaded, permissions } = usePermissions();


  const validateExistingMeeting = (data: any) => {
    let errors: any = {};
  
    let {
      startDateTime,
      endDateTime,
    } = data;

    if (!startDateTime) {
      const errorMessage = "Start date and time is required.";
      errors = { startDateTime: errorMessage };
      return errors;
    }
    if (typeof startDateTime === "string") {
      startDateTime = new Date(Date.parse(startDateTime));
    }
    const now = new Date();

    if (!endDateTime) {
      const errorMessage = "End date and time is required.";
      errors = { endDateTime: errorMessage };
      return errors;
    }
    if (typeof endDateTime === "string") {
      endDateTime = new Date(Date.parse(endDateTime));
    }
    if (startDateTime.getTime() >= endDateTime.getTime()) {
      const errorMessage = "Start date must be before the end date.";
      errors = { startDateTime: errorMessage };
    }

    const durationInMinutes = Math.floor(endDateTime.getTime() - startDateTime.getTime()) / (60 * 1000);

    if (durationInMinutes > 60) {
      const errorMessage = "Meeting duration is over 1 hour.";
      errors = { endDateTime: errorMessage };
    }
  
    return errors;
  };

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        margin: 'auto',
      },
      profileList: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'auto',
        maxHeight: 300,
        verticalAlign: 'top', // TODO is ignored
      },
      button: {
        margin: theme.spacing(0.5, 0),
      },
    }),
  );
  const classes = useStyles();
  const meetingsState = useContext(MeetingsStateContext);
  const {
    currentProfile,
  } = meetingsState;
  
  const handleProfileToggle = (id: string) => () => {
    const currentIndex = checkedProfileIds.indexOf(id);
    const newChecked = [...checkedProfileIds];
    if (currentIndex === -1) {
      newChecked.push(id);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCheckedProfileIds(newChecked);
  };

  const handleSellerTeamMemberRelationshipsToggle = (id: string) => () => {
    const currentIndex = checkedSellerTeamMemberRelationshipIds.indexOf(id);
    const newChecked = [...checkedSellerTeamMemberRelationshipIds];
    if (currentIndex === -1) {
      newChecked.push(id);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCheckedSellerTeamMemberRelationshipIds(newChecked);
  };

  const handleBuyerTeamMemberRelationshipsToggle = (id: string) => () => {
    const currentIndex = checkedBuyerTeamMemberRelationshipIds.indexOf(id);
    const newChecked = [...checkedBuyerTeamMemberRelationshipIds];
    if (currentIndex === -1) {
      newChecked.push(id);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCheckedBuyerTeamMemberRelationshipIds(newChecked);
  };

  const handleAddSellersToTeam = () => {
    logger.info("handleAddSellersToTeam called...");
    if (sellerTeamRelationship) {
      addProfilesToTeam(checkedProfileIds, sellerTeamRelationship.teamId).then(
        () => {
          setTimeout(
            () => {
              setLastTeamMemberRelationshipChange(new Date().getTime());
            },
            2000,
          );
        }
      );
    }
    setCheckedProfileIds([]);
  };

  const handleAddBuyersToTeam = () => {
    logger.info("handleAddBuyersToTeam called...");
    if (buyerTeamRelationship) {
      addProfilesToTeam(checkedProfileIds, buyerTeamRelationship.teamId).then(
        () => {
          setTimeout(
            () => {
              setLastTeamMemberRelationshipChange(new Date().getTime());
            },
            2000,
          );
        }
      );
    }
    setCheckedProfileIds([]);
  };

  const handleRemovedCheckedSellerTeamMemberRelationships = () => {
    logger.info("handleRemovedCheckedSellerTeamMemberRelationships called...");
    deleteTeamMemberRelationships(checkedSellerTeamMemberRelationshipIds).then(
      () => {
        setTimeout(
          () => {
            setLastTeamMemberRelationshipChange(new Date().getTime());
          },
          2000,
        );
      }
    );
    setCheckedSellerTeamMemberRelationshipIds([]);
  };

  const handleRemovedCheckedBuyerTeamMemberRelationships = () => {
    logger.info("handleRemovedCheckedBuyerTeamMemberRelationships called...");
    deleteTeamMemberRelationships(checkedBuyerTeamMemberRelationshipIds).then(
      () => {
        setTimeout(
          () => {
            setLastTeamMemberRelationshipChange(new Date().getTime());
          },
          2000,
        );
      }
    );
    setCheckedBuyerTeamMemberRelationshipIds([]);
  };

  const profileList = (profiles: Profile[]) => {
    return (
      <List
        dense
        component="div"
        role="list"
        className={classes.profileList}
      >
        <ListSubheader>Available Users</ListSubheader>
        {profiles.map((profile: Profile) => {
          const labelId = `available-profile-${profile.id}`;
          return (
            <ListItem key={profile.id} role="listitem" button onClick={handleProfileToggle(profile.id)}>
              <ListItemIcon>
                <Checkbox
                  checked={checkedProfileIds.indexOf(profile.id) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={profile.fullName} secondary={profile.organization?.name || null} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    );
  };

  const sellerTeamMemberRelationshipList = (teamMemberRelationships: TeamMemberRelationship[]) => {
    return (
      <List
        dense
        component="div"
        role="list"
        className={classes.profileList}
      >
        <ListSubheader>Seller Team Members</ListSubheader>
        {teamMemberRelationships.map((teamMemberRelationship: TeamMemberRelationship) => {
          const labelId = `seller-team-member-relationship-${teamMemberRelationship.id}`;
          return (
            <ListItem key={teamMemberRelationship.id} role="listitem" button onClick={handleSellerTeamMemberRelationshipsToggle(teamMemberRelationship.id)}>
              <ListItemIcon>
                <Checkbox
                  checked={checkedSellerTeamMemberRelationshipIds.indexOf(teamMemberRelationship.id) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={teamMemberRelationship.member.fullName} secondary={teamMemberRelationship.member.organization?.name || null} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    );
  };

  const buyerTeamMemberRelationshipList = (teamMemberRelationships: TeamMemberRelationship[]) => {
    return (
      <List
        dense
        component="div"
        role="list"
        className={classes.profileList}
      >
        <ListSubheader>Buyer Team Members</ListSubheader>
        {teamMemberRelationships.map((teamMemberRelationship: TeamMemberRelationship) => {
          const labelId = `buyer-team-member-relationship-${teamMemberRelationship.id}`;
          return (
            <ListItem key={teamMemberRelationship.id} role="listitem" button onClick={handleBuyerTeamMemberRelationshipsToggle(teamMemberRelationship.id)}>
              <ListItemIcon>
                <Checkbox
                  checked={checkedBuyerTeamMemberRelationshipIds.indexOf(teamMemberRelationship.id) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={teamMemberRelationship.member.fullName} secondary={teamMemberRelationship.member.organization?.name || null} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    );
  };

  const getTeamImage = async (teamId: string): Promise<TeamImage | null> => {
    const query = graphqlOperation(
      listTeamImagesByTeamId,
      {
        teamId,
        filter: {
          isDefault: {
            eq: true
          }
        }
      }
    );
    return await (API.graphql(query) as Promise<GraphQLResult<ListTeamImagesByTeamIdQuery>>)
      .then(
        async (result: GraphQLResult<ListTeamImagesByTeamIdQuery>) => {
          if (
            result.data &&
            result.data.listTeamImagesByTeamId &&
            result.data.listTeamImagesByTeamId.items.length > 0
          ) {
            const {
              items
            } = result.data.listTeamImagesByTeamId;
            if (items && items.length > 0) {
              return items[0];
            }
          }
          return null;
        }
      )
      .catch((err: GraphQLErrorResponse) => {
        if (err.errors && err.errors.length > 0) {
          const { message } = err.errors[0];
          const errorMessage = message || "GraphQL API error";
          throw new Error(errorMessage);
        } else {
          throw new Error(err.message);
        }
      }
    );
  };

  const getScheduledMeetingsByTeam = async (
    teamId: string,
    excludedMeetingId: string,
    // hoursAhead: number = 48 * 200, // TODO set to production value or value based on meeting date
    limit: number = 0,
  ): Promise<Meeting[]> => {
    const now = DateTime.utc();
    // const minutesFromNow = now.plus({ hours: hoursAhead });
    return (
      API.graphql(
        graphqlOperation(listTeamMeetingRelationshipsByTeamId, {
          teamId,
          filter: {
            meetingId: {
              ne: excludedMeetingId
            },
          },
          ...(limit && { limit }),
        }) // TODO include only scheduled meetings
      ) as Promise<GraphQLResult>
    )
      .then((queryResult) => {
        if (queryResult.errors || !queryResult.data) {
          logger.error("queryResult", queryResult);
          throw new Error("Data provider error");
        }
        logger.info("listTeamMeetingRelationshipsByTeamId queryResult.data", queryResult.data);
        const queryResultData = (queryResult.data as ListTeamMeetingRelationshipsByTeamIdQuery).listTeamMeetingRelationshipsByTeamId;
        if (!queryResultData) {
          return [];
        }
        const {
          items
        } = queryResultData as ModelTeamMeetingRelationshipConnection;
        if (!items) {
          return [];
        }
        return items.map(
          (teamMeetingRelationship) => teamMeetingRelationship?.meeting
        ).filter(
          (meeting) => meeting?.status === MeetingStatus.SCHEDULED && 
            awsDateTimeStringToDateTime(
              meeting.startDateTime as string,
            ).toUnixInteger() > now.toUnixInteger()
        ).sort( // NOTE: sort by startdate
          (a, b) => awsDateTimeStringToDateTime( // @ts-ignore
            a.startDateTime as string,
          ).toUnixInteger() - awsDateTimeStringToDateTime( // @ts-ignore
            b.startDateTime as string,
          ).toUnixInteger()
        ) as Meeting[];
      })
      .catch((err: GraphQLErrorResponse) => {
        const { errors } = err;
        if (errors && errors.length > 0) {
          const { message } = err.errors[0];
          const errorMessage = message || "Unknown error";
          logger.error(errorMessage);
          throw new Error(errorMessage);
        }
        logger.error(err);
        throw err;
      });
  };

  const getMeetingInvites = async (
    meetingId: string
  ): Promise<ListMeetingInvitesByMeetingIdQuery["listMeetingInvitesByMeetingId"]> => {
    return (
      API.graphql(
        graphqlOperation(listMeetingInvitesByMeetingId, {
          meetingId
        })
      ) as Promise<GraphQLResult>
    ).then((queryResult) => {
      if (queryResult.errors || !queryResult.data) {
        logger.error("queryResult", queryResult);
        throw new Error("Data provider error");
      }
      return (queryResult.data as ListMeetingInvitesByMeetingIdQuery)
        .listMeetingInvitesByMeetingId;
    }).catch((err: GraphQLErrorResponse) => {
      const { errors } = err;
      if (errors && errors.length > 0) {
        const { message } = err.errors[0];
        const errorMessage = message || "Unknown error";
        logger.error(errorMessage);
        throw new Error(errorMessage);
      }
      logger.error(err);
      throw err;
    });
  };


  // useSubscribe("resource/teamMeetingRelationships", (event) => {
  //  logger.info("useSubscribe teamMeetingRelationships event", event);
    // setLastTeamMeetingRelationshipChange(new Date().getTime()); // event.payload.meetingId
  // });

  // useSubscribe("resource/teamMemberRelationships", (event) => {
  //  logger.info("useSubscribe teamMemberRelationships event", event);
    // setLastTeamMemberRelationshipChange(new Date().getTime());
  // });

  // NOTE: get the meeting
  useEffect(() => {
    if (currentProfile && params.meetingId) {
      dataProvider.getOne(
        "meetings",
        {
          id: params.meetingId,
        },
      ).then(
        (m) => {
          logger.info("meeting results.", m);
          if (m && m.data) {
            const currentMeeting = m.data as Meeting;
            logger.info("currentMeeting", currentMeeting);
            setMeeting(currentMeeting);
          }
        }
      );
    }
  }, [currentProfile, params.meetingId]);

  // NOTE: get all the available profiles
  useEffect(() => {
    if (meeting && meeting.status === MeetingStatus.SCHEDULED) {
      logger.info("getAllProfiles called");
      // getAllProfiles(50)
      getAllProfilesWithoutMeetingConflicts(meeting, 50).then(
        (profiles) => {
          logger.info("getAllProfilesWithoutMeetingConflicts results", profiles);
          setAllProfiles(profiles);
        }
      ).catch(
        (err: any) => {
          const { errors } = err;
          if (errors && errors.length > 0) {
            const { message } = err.errors[0];
            const errorMessage = message || "Unknown error";
            Sentry.captureMessage(errorMessage);
          } else {
            Sentry.captureException(err);
          }
        }
      );
    }
  }, [meeting?.id, lastTeamMemberRelationshipChange]);

  // NOTE: get the buyer and seller teams for the meeting
  useEffect(
    () => {
      if (meeting && meeting.status === MeetingStatus.SCHEDULED) {
        let buyer: TeamMeetingRelationship | null  = null;
        let seller: TeamMeetingRelationship | null = null;
        dataProvider.getManyReference(
          "teamMeetingRelationships",
          {
            target: "meetingId",
            id: `${meeting.id}`,
            pagination: { page: 1, perPage: 10 },
            sort: { field: "createdAt", order: "" },
            filter: {},
          }
        ).then(
          (results) => {
            const {
              data,
            } = results;
            if (data && data.length > 0) {
              for (let i = 0; i < data.length; i = i + 1) {
                const relationship = data[i] as TeamMeetingRelationship;
                if (!buyer && relationship.relationshipType === TeamMeetingRelationshipType.INVITEE) {
                  buyer = relationship;
                  setBuyerTeamRelationship(relationship);
                  getScheduledMeetingsByTeam(
                    buyer.teamId,
                    meeting.id,
                  ).then(
                    (meetings) => {
                      setScheduledMeetingsForBuyer(meetings);
                    }
                  ).catch(
                    (err: Error) => {
                      notify(err.message, { type: "warning" });
                      logger.error("buyer getScheduledMeetingsByTeam error", err);
                    }
                  );
                  getTeamImage(
                    buyer.teamId,
                  ).then(
                    (teamImage) => {
                      setBuyerTeamImage(teamImage);
                    }
                  ).catch(
                    (err: Error) => {
                      notify(err.message, { type: "warning" });
                      logger.error("buyer getTeamImage error", err);
                    }
                  );
                } else if (!seller && relationship.relationshipType === TeamMeetingRelationshipType.ORGANIZER) {
                  seller = relationship;
                  setSellerTeamRelationship(relationship);
                  getScheduledMeetingsByTeam(
                    seller.teamId,
                    meeting.id,
                  ).then(
                    (meetings) => {
                      setScheduledMeetingsForSeller(meetings);
                    }
                  ).catch(
                    (err: Error) => {
                      notify(err.message, { type: "warning" });
                      logger.error("seller getScheduledMeetingsByTeam error", err);
                    }
                  );
                  getTeamImage(
                    seller.teamId,
                  ).then(
                    (teamImage) => {
                      setSellerTeamImage(teamImage);
                    }
                  ).catch(
                    (err: Error) => {
                      notify(err.message, { type: "warning" });
                      logger.error("seller getTeamImage error", err);
                    }
                  );
                }
              }
              // TODO getAllAvailableProfiles
            }
          }
        ).catch(
          (err: Error) => {
            notify(err.message, { type: "warning" });
            logger.error("teamMeetingRelationships error", err);
          }
        );
      } // TODO redirect to view if the meeting is not scheduled
    },
    [meeting?.id, lastTeamMeetingRelationshipChange]
  );

  // NOTE: get the buyer team members
  useEffect(
    () => {
      if (buyerTeamRelationship) {
        const currentBuyerTeamMemberRelationships: TeamMemberRelationship[] = [];
        dataProvider.getManyReference(
          "teamMemberRelationships",
          {
            target: "teamId",
            id: `${buyerTeamRelationship.teamId}`,
            pagination: { page: 1, perPage: 25 },
            sort: { field: "createdAt", order: "" },
            filter: {},
          }
        ).then(
          (results) => {
            const {
              data,
            } = results;
            if (data && data.length > 0) {
              for (let i = 0; i < data.length; i = i + 1) {
                const relationship = data[i] as TeamMemberRelationship;
                currentBuyerTeamMemberRelationships.push(relationship);
              }
              setBuyerTeamMemberRelationships(currentBuyerTeamMemberRelationships);
            }
          }
        ).catch(
          (err: Error) => {
            notify(err.message, { type: "warning" });
            logger.error("buyer teamMemberRelationships error", err);
          }
        );
      } else if (buyerTeamMemberRelationships.length > 0) {
        setBuyerTeamMemberRelationships([]);
      }
    },
    [buyerTeamRelationship, lastTeamMemberRelationshipChange] // TODO only update when buyer team changes 
  );

  // NOTE: get the seller team members
  useEffect(
    () => {
      if (sellerTeamRelationship) {
        const currentSellerTeamMemberRelationships: TeamMemberRelationship[] = [];
        dataProvider.getManyReference(
          "teamMemberRelationships",
          {
            target: "teamId",
            id: `${sellerTeamRelationship.teamId}`,
            pagination: { page: 1, perPage: 25 },
            sort: { field: "createdAt", order: "" },
            filter: {},
          }
        ).then(
          (results) => {
            const {
              data,
            } = results;
            if (data && data.length > 0) {
              for (let i = 0; i < data.length; i = i + 1) {
                const relationship = data[i] as TeamMemberRelationship;
                currentSellerTeamMemberRelationships.push(relationship);
              }
              setSellerTeamMemberRelationships(currentSellerTeamMemberRelationships);
            }
          }
        ).catch(
          (err: Error) => {
            notify(err.message, { type: "warning" });
            logger.error("seller teamMemberRelationships error", err);
          }
        );
      } else if (sellerTeamMemberRelationships.length > 0) {
        setSellerTeamMemberRelationships([]);
      }
    },
    [sellerTeamRelationship, lastTeamMemberRelationshipChange] // TODO only update when seller team changes 
  );

  // NOTE: get all the scheduled individuals (MeetingInvite) by meeting and team
  useEffect(
    () => {
      if (meeting) {
        getMeetingInvites(meeting.id).then(
          (meetingInvitesResult) => {
            logger.info("meetingInvitesResult.items", meetingInvitesResult?.items);
            const currentMeetingInvitesForBuyer: MeetingInvite[] = [];
            const currentMeetingInvitesForSeller: MeetingInvite[] = [];
            const meetingInvites = meetingInvitesResult?.items ? meetingInvitesResult.items as MeetingInvite[] : [];
            for (let i = 0; i < meetingInvites.length; i = i + 1) {
              const meetingInvite = meetingInvites[i];
              if (meetingInvite.meetingInvitationType == MeetingInvitationType.INVITEE) {
                currentMeetingInvitesForBuyer.push(meetingInvite);
              } else if (meetingInvite.meetingInvitationType == MeetingInvitationType.ORGANIZER) {
                currentMeetingInvitesForSeller.push(meetingInvite);
              }
            }
            setMeetingInvitesForBuyer(currentMeetingInvitesForBuyer);
            setMeetingInvitesForSeller(currentMeetingInvitesForSeller);
          }
        ).catch(
          (err) => {
            notify(err.message, { type: "warning" });
            logger.error("getMeetingInvites error", err);
          }
        );
      }
    },
    [buyerTeamRelationship, sellerTeamRelationship, buyerTeamMemberRelationships, sellerTeamMemberRelationships] // NOTE: update when the Team or Membership relationship table is updated
  );
  
  // NOTE: realtime subscriptions
  useEffect(
    () => {
      if (currentProfile?.userId) {
        const createTeamMeetingRelationshipSubscription = API.graphql(
          graphqlOperation(onCreateTeamMeetingRelationship, { owner: currentProfile?.userId })
        ).subscribe({
          next: ({ value }: any) => {
            logger.info("value.data.onCreateTeamMeetingRelationship", value.data.onCreateTeamMeetingRelationship);
            logger.info("value.data.onCreateTeamMeetingRelationship.id", value.data.onCreateTeamMeetingRelationship.id);
            /* dataProvider.publish(
              "resource/teamMeetingRelationships",
              {
                type: "created",
                topic: "resource/teamMeetingRelationships",
                payload: { ids: [ value.data.onCreateTeamMeetingRelationship.id ], meetingId: value.data.onCreateTeamMeetingRelationship.meetingId },
                date: new Date(),
              },
            ); */
          },
          error: (error: Error) => {
            logger.warn("onCreateTeamMeetingRelationship failed", error);
          }
        });
        const deleteTeamMeetingRelationshipSubscription = API.graphql(
          graphqlOperation(onDeleteTeamMeetingRelationship, { owner: currentProfile?.userId })
        ).subscribe({
          next: ({ value }: any) => {
            logger.info("value.data.onDeleteTeamMeetingRelationship", value.data.onDeleteTeamMeetingRelationship);
            logger.info("value.data.onDeleteTeamMeetingRelationship.id", value.data.onDeleteTeamMeetingRelationship.id);
            /* dataProvider.publish(
              "resource/teamMeetingRelationships",
              {
                type: "deleted",
                topic: "resource/teamMeetingRelationships",
                payload: { ids: [ value.data.onDeleteTeamMeetingRelationship.id ], meetingId: value.data.onDeleteTeamMeetingRelationship.meetingId },
                date: new Date(),
              },
            ); */
          },
          error: (error: Error) => {
            logger.warn("onDeleteTeamMeetingRelationship failed", error);
          }
        });
        const createTeamMemberRelationshipSubscription = API.graphql(
          graphqlOperation(onCreateTeamMemberRelationship, { owner: currentProfile?.userId })
        ).subscribe({
          next: ({ value }: any) => {
            logger.info("value.data.onCreateTeamMemberRelationship", value.data.onCreateTeamMemberRelationship);
            logger.info("value.data.onCreateTeamMemberRelationship.id", value.data.onCreateTeamMemberRelationship.id);
            /* dataProvider.publish(
              "resource/teamMemberRelationships",
              {
                type: "created",
                topic: "resource/teamMemberRelationships",
                payload: { ids: [ value.data.onCreateTeamMemberRelationship.id ]},
                date: new Date(),
              },
            ); */
          },
          error: (error: Error) => {
            logger.warn("onCreateTeamMemberRelationship failed", error);
          }
        });
        const deleteTeamMemberRelationshipSubscription = API.graphql(
          graphqlOperation(onDeleteTeamMemberRelationship, { owner: currentProfile?.userId })
        ).subscribe({
          next: ({ value }: any) => {
            logger.info("value.data.onDeleteTeamMemberRelationship", value.data.onDeleteTeamMemberRelationship);
            logger.info("value.data.onDeleteTeamMemberRelationship.id", value.data.onDeleteTeamMemberRelationship.id);
            /* dataProvider.publish(
              "resource/teamMemberRelationships",
              {
                type: "deleted",
                topic: "resource/teamMemberRelationships",
                payload: { ids: [ value.data.onDeleteTeamMemberRelationship.id ]},
                date: new Date(),
              },
            ); */
          },
          error: (error: Error) => {
            logger.warn("onDeleteTeamMemberRelationship failed", error);
          }
        });
        return () => {
          createTeamMeetingRelationshipSubscription.unsubscribe();
          deleteTeamMeetingRelationshipSubscription.unsubscribe();
          createTeamMemberRelationshipSubscription.unsubscribe();
          deleteTeamMemberRelationshipSubscription.unsubscribe();
        }
      }
    },
    [ currentProfile?.userId ]
  );

  /* const updateMeeting = (updatedMeeting: Meeting, previousMeeting: Meeting) => {
    // TODO add validation
    dataProvider.update(
      "meetings",
      {
        id: updatedMeeting.id,
        data: {
          id: updatedMeeting.id, // TODO update the date and time
          _version: previousMeeting._version
        },
        previousData: { // TODO add the date and time
          id: previousMeeting.id,
        },
      }
    ).then(
      (meetingUpdateResults) => {
        logger.info("meetingUpdateResults", meetingUpdateResults);
      }
    ).catch(
      (err: any) => {
        logger.error("failed to update meeting", err);
      }
    );
    setMeeting(updatedMeeting);
    logger.info("updatedMeeting", updatedMeeting);
  };

  const addTeamMeetingRelationship = (input: CreateTeamMeetingRelationshipInput) => {

    //  id?: string | null,
    //  teamId: string,
    //  meetingId: string,
    //  relationshipType: TeamMeetingRelationshipType,
    
      dataProvider.create(
        "teamMeetingRelationships",
        {
          data: input,
        }
      ).then(
        (teamMeetingRelationshipCreateResults) => {
          logger.info("teamMeetingRelationshipCreateResults", teamMeetingRelationshipCreateResults);
        }
      ).catch(
        (err: any) => {
          logger.error("failed to delete teamMeetingRelationshipCreateResult", err);
        }
      );
    };


  */

  const deleteTeamMeetingRelationship = (teamMeetingRelationship: TeamMeetingRelationship) => {

    ReactSwal.fire({
      title: "Are you sure?",
      text: "You are about remove a team.",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "No",
      confirmButtonText: "Yes, delete",
      confirmButtonColor: "#056839",
      cancelButtonColor: "#39B54A",
    }).then((result) => {
      if (result.isConfirmed) {
        dataProvider.delete(
          "teamMeetingRelationships",
          {
            id: teamMeetingRelationship.id,
          }
        ).then(
          (teamMeetingRelationshipDeleteResults) => {
            logger.info("teamMeetingRelationshipDeleteResults", teamMeetingRelationshipDeleteResults);
          }
        ).catch(
          (err: any) => {
            logger.error("failed to delete teamMeetingRelationshipDeleteResult", err);
          }
        );
      }
    });

  };

  const deleteTeamMemberRelationship = (id: string) => {
    return dataProvider.delete(
      "teamMemberRelationships",
      {
        id
      }
    );
  };

  const addTeamMemberRelationship = (input: CreateTeamMemberRelationshipInput) => {
    return dataProvider.create(
      "teamMemberRelationships",
      {
        data: input,
      }
    );
  };

  const addProfilesToTeam = async (profileIds: string[], teamId: string) => {
    for (let i = 0; i < profileIds.length; i = i + 1) {
      const memberProfileId = profileIds[i];
      const input = {
        teamId,
        memberProfileId,
        relationship: TeamRelationship.MEMBER,
      }
      await addTeamMemberRelationship(input).then(
        (teamMemberRelationshipCreateResults) => {
          logger.info("teamMemberRelationshipCreateResults", teamMemberRelationshipCreateResults);
        }
      ).catch(
        (err: any) => {
          logger.error("failed to create teamMemberRelationship", err);
          notify(`${err}`, { type: "warning" });
        }
      );
    }
  }

  const deleteTeamMemberRelationships = async (teamMemberRelationshipIds: string[]) => {
    for (let i = 0; i < teamMemberRelationshipIds.length; i = i + 1) {
      const teamMemberRelationshipId = teamMemberRelationshipIds[i];
      await deleteTeamMemberRelationship(teamMemberRelationshipId).then(
        (teamMemberRelationshipDeleteResults) => {
          logger.info("teamMemberRelationshipDeleteResults", teamMemberRelationshipDeleteResults);
        }
      ).catch(
        (err: any) => {
          logger.error("failed to delete teamMeetingRelationship", err);
          notify(`${err}`, { type: "warning" });
        }
      );
    }
  };

  const onSuccess = async (response: any) => {
    const {
      data,
    } = response;
    logger.info("onSuccess.data", data);
    const successMessage = "Saved";
    notify(successMessage, { type: "success" });
    // TODO a delayed refresh
  };

  // See: https://marmelab.com/react-admin/doc/3.19/CreateEdit.html#submission-validation
  const save = useCallback(
    async (data: any) => {
      const errors = validateExistingMeeting(data);
      if (Object.keys(errors).length > 0) {
        logger.warn("found validation errors", errors);
        return errors;
      }
      await mutate(
        {
          type: "update",
          resource: "meetings",
          payload: { data },
        },
        { 
          returnPromise: true,
          onSuccess
        }
      );
    },
    [mutate],
  );

  logger.info("sellerTeamRelationship", sellerTeamRelationship);
  logger.info("buyerTeamRelationship", buyerTeamRelationship);
  logger.info("sellerTeamMemberRelationships", sellerTeamMemberRelationships);
  logger.info("buyerTeamMemberRelationships", buyerTeamMemberRelationships);    
  logger.info("meetingInvitesForSeller", meetingInvitesForSeller);
  logger.info("meetingInvitesForBuyer", meetingInvitesForBuyer);

  /* 

        {!sellerTeamRelationship || !buyerTeamRelationship && 
          <CreateNewTeamButton {...props} />
        }
        {!sellerTeamRelationship && 
          <AddSellerTeamMeetingRelationshipButton {...props} />
        }
        {!buyerTeamRelationship && 
          <AddBuyerTeamMeetingRelationshipButton {...props} />
        }

  */

  const EditActions = (props: EditProps) => {
    const {
      className,
      basePath,
      id
    } = props;
    return (
      <TopToolbar className={className}>
        <ShowButton
          basePath={basePath}
          label="Show"
          record={{ id: id as string }}
        />
        <EditButton
          basePath={basePath}
          label="Edit"
          record={{ id: id as string }}
        />
        <ShowScheduledMeetingsButton {...props} />
      </TopToolbar>
    );
  };

  const editProps: EditProps = { // TODO add actions here?
    id: params.meetingId, // @ts-ignore
    record: meeting,
    resource: "meetings",
    basePath: "/meetings",
    title: meeting?.title,
    undoable: false,
    onSuccess,
  };

  return (
    <>
      
      {meeting && (
      <Edit
        {... editProps}
        actions={<EditActions {...editProps} />}
      >
        <CompactForm
          toolbar={<NoDeleteToolbar />}
          layoutComponents={[RaBox, RaGrid]}
          save={save}
        >
        <Grid 
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
        >
          <Grid item xs={12}>
            <Typography variant="h2">Title: {meeting.title}</Typography>
            {false && (<Typography variant="body2">
              Posts will only be published once an editor approves them
            </Typography>)}
          </Grid>

          <Grid item xs={12} sm={6}>
            <MaterialDateTimeInput
              source="startDateTime"
              validate={required("Start date and time is required.")}
              options={{ format: 'MM/dd/yyyy, hh:mm a', ampm: true, clearable: true }}
              isRequired
            /> 
          </Grid>
          <Grid item xs={12} sm={6}>
            <MaterialDateTimeInput
              source="endDateTime"
              validate={required("End date and time is required.")}
              options={{ format: 'MM/dd/yyyy, hh:mm a', ampm: true, clearable: true }}
              isRequired
            />
          </Grid>

          <Grid item xs={12} sm={6}>
              
            {(sellerTeamRelationship) ? 
              <Chip
                size="medium"
                // clickable
                color="primary"
                avatar={ // TODO use team image
                  sellerTeamImage ? <AmplifyImageAvatar
                  record={sellerTeamImage}
                  source="image"
                  title="title"
                  size="48px"
                  /> : undefined
                }
                label={sellerTeamRelationship?.team.name}
                deleteIcon={<DeleteForeverIcon />}
                onDelete={() => {
                  deleteTeamMeetingRelationship(sellerTeamRelationship);
                }}
              />
              :
              <CreateButton
                component={Link}
                to={{
                  pathname: "/teamMeetingRelationships/create",
                  state: {
                    record: {
                      meetingId: meeting.id,
                      relationshipType: TeamMeetingRelationshipType.ORGANIZER,
                    }
                  },
                  search: `?source=${JSON.stringify(
                    {
                      meetingId: meeting.id,
                      relationshipType: TeamMeetingRelationshipType.ORGANIZER,
                    }
                  )}`,
                }}
                label="Add seller team"
              >
                <AddIcon />
              </CreateButton>
            }

          </Grid>

          <Grid item xs={12} sm={6}>

            {(buyerTeamRelationship) ? 
              <Chip
                size="medium"
                color="secondary"
                avatar={ // TODO use team image
                  buyerTeamImage ? <AmplifyImageAvatar
                  record={buyerTeamImage}
                  source="image"
                  title="title"
                  size="48px"
                  /> : undefined
                }
                label={buyerTeamRelationship?.team.name}
                deleteIcon={<DeleteForeverIcon />}
                onDelete={() => {
                  deleteTeamMeetingRelationship(buyerTeamRelationship);
                }}
              />
              :
              <CreateButton
                component={Link}
                to={{
                  pathname: "/teamMeetingRelationships/create",
                  state: {
                    record: {
                      meetingId: meeting.id,
                      relationshipType: TeamMeetingRelationshipType.INVITEE,
                    }
                  },
                  search: `?source=${JSON.stringify(
                    {
                      meetingId: meeting.id,
                      relationshipType: TeamMeetingRelationshipType.INVITEE,
                    }
                  )}`,
                }}
                label="Add buyer team"
              >
                <AddIcon />
              </CreateButton>
            }

          </Grid>
        </Grid>

        <Grid 
          container
          direction="row"
          spacing={2}
          justifyContent="center"
          alignItems="center"
          // className={classes.root}
        >
          <Grid item xs={12} sm={3}>
            {sellerTeamMemberRelationshipList(sellerTeamMemberRelationships)}
          </Grid>
          <Grid item xs={12} sm={1}>
            <Button
              variant="outlined"
              size="medium"
              className={classes.button}
              onClick={handleRemovedCheckedSellerTeamMemberRelationships}
              disabled={checkedSellerTeamMemberRelationshipIds.length === 0}
              aria-label="remove seller team members"
            >
              <ArrowForwardIosIcon
                fontSize="medium" 
              />
            </Button>
            <Button
              variant="outlined"
              size="medium"
              className={classes.button}
              onClick={handleAddSellersToTeam}
              disabled={checkedProfileIds.length === 0}
              aria-label="add seller team members"
            >
              <ArrowBackIosIcon
                fontSize="medium" 
              /> 
            </Button>
          </Grid>
          <Grid item xs={12} sm={4}>
            {profileList(allProfiles)}
          </Grid>
          <Grid item xs={12} sm={1}>
            <Button
              variant="outlined"
              size="medium"
              className={classes.button}
              onClick={handleAddBuyersToTeam}
              disabled={checkedProfileIds.length === 0}
              aria-label="add buyer team members"
            >
              <ArrowForwardIosIcon
                fontSize="medium" 
              />
            </Button>
            <Button
              variant="outlined"
              size="medium"
              className={classes.button}
              onClick={handleRemovedCheckedBuyerTeamMemberRelationships}
              disabled={checkedBuyerTeamMemberRelationshipIds.length === 0}
              aria-label="remove buyer team members"
            >
              <ArrowBackIosIcon
                fontSize="medium" 
              /> 
            </Button>
          </Grid>
          <Grid item xs={12} sm={3}>
            {buyerTeamMemberRelationshipList(buyerTeamMemberRelationships)}
          </Grid>
        </Grid>

        <Grid 
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
        >
          <Grid item xs={12}>
            <Typography variant="h4">
              Scheduled meetings for seller
            </Typography>
            <DataGrid
              density="compact"
              rows={scheduledMeetingsForSeller}
              columns={meetingColumns}
              pageSize={10}
              rowsPerPageOptions={[5]}
              autoHeight={true}
              disableSelectionOnClick
              onRowClick={(params, event) => { redirect(`/scheduler/${params.id}`); }}
              onError={
                (err) => {
                  logger.error("scheduledMeetingsForSeller grid error", err);
                  notify(`${err}`, { type: "warning" })
                }
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4">
              Scheduled meetings for buyer
            </Typography>
            <DataGrid
              density="compact"
              rows={scheduledMeetingsForBuyer}
              columns={meetingColumns}
              pageSize={10}
              rowsPerPageOptions={[5]}
              autoHeight={true}
              disableSelectionOnClick
              onRowClick={(params, event) => { redirect(`/scheduler/${params.id}`); }}
              onError={
                (err) => {
                  logger.error("scheduledMeetingsForBuyer grid error", err);
                  notify(`${err}`, { type: "warning" })
                }
              }
            />
          </Grid>
        </Grid>
        </CompactForm>

      </Edit>
      )}
      </>
  );
};

export default MeetingSchedulerPage;
