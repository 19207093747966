
import React from "react";
import { Route } from "react-router-dom";
import MeetingErrorPage from "../pages/MeetingError";
import WebRTCDetectorPage from '../pages/WebRTCDetector';
import StopMeetingPage from '../pages/StopMeetingPage';
import MeetingSchedulerPage from "../pages/MeetingScheduler";
import { MeetingBreakPage } from "../pages/MeetingBreak";
import { SellerUpcomingDoctorsPage } from "../pages/SellerUpcomingDoctors";
import { FeedbackPage } from "../pages/Feedback";
import { BuyerUpcomingProductsPage } from "../pages/BuyerUpcomingProducts";

export const customRoutes = [ 
  <Route exact path="/scheduler/:meetingId" component={MeetingSchedulerPage} />,
  <Route exact path="/error/:meetingInviteId" component={MeetingErrorPage} />,
  <Route exact path="/break/:meetingInviteId" component={MeetingBreakPage} />,
  <Route exact path="/feedback" component={FeedbackPage} />,
  <Route exact path="/buyer-upcoming-products" component={BuyerUpcomingProductsPage} />,
  <Route exact path="/seller-upcoming-doctors" component={SellerUpcomingDoctorsPage} />,
  <Route exact path="/webRtcCheck" component={WebRTCDetectorPage} />,
  <Route exact path="/stop/:roomName" component={StopMeetingPage} />,
];
