
import React, { useEffect, useState } from "react";
import { useTimer } from "react-timer-hook";
import type { GenericCountdownProps } from "../types";
import { useRedirect } from "react-admin";
import { CircularCountdown } from "./CircularCountdown";
import { Logger } from "aws-amplify";

const logger = new Logger("GenericCountdown");

const GenericCountdown = (props: GenericCountdownProps) => {
  const {
    expiryDate,
    redirectUrl,
  } = props;
  const redirect = useRedirect();
  const [ expiryTimestamp, setExpiryTimestamp ] = useState<Date>(expiryDate);

  const {
    seconds,
    minutes,
    hours,
    days,
    // isRunning,
    restart,
  } = useTimer(
    {
      expiryTimestamp,
      autoStart: true,
      onExpire: () => {
        redirect(redirectUrl);
      }
    }
  );

  useEffect(() => {
    // NOTE: restart timer if changes
    logger.info("setExpiryTimestamp useeffect called", expiryDate);
    if (
      expiryDate.getTime() !== expiryTimestamp.getTime()
    ) {
      logger.info("expiryDate change detected");
      setExpiryTimestamp(expiryDate);
    }
  }, [expiryDate]);

  useEffect(() => {
    logger.info("restart timer useeffect called", expiryTimestamp);
    restart(expiryTimestamp);
  }, [expiryTimestamp]);

  return (
    <CircularCountdown
      days={days}
      hours={hours}
      minutes={minutes}
      seconds={seconds}
    />
  );
};

export default GenericCountdown
