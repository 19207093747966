import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import * as Sentry from "@sentry/react";
import { CookiesProvider } from 'react-cookie';
// import { ThemeProvider } from "styled-components";
import App from "./App";
import { SENTRY_DSN } from "./constants";
// import reportWebVitals from "./reportWebVitals";

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: SENTRY_DSN,
    // integrations: [new BrowserTracing()],
    tracesSampleRate: 0.2,
    denyUrls: ["localhost", "127.0.0.1"],
    ignoreErrors: [
    "TypeError: Failed to fetch",
    "TypeError: NetworkError when attempting to fetch resource.",
    "TypeError: Cancelled",
    "Request aborted",
  ],
  });
}

ReactDOM.render(
  <CookiesProvider>
    <App />
  </CookiesProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
