import type {
  AuthProvider as AuthProviderInterface,
  DataProvider as DataProviderInterface,
} from "ra-core";
import { AuthProvider, AuthProviderOptions } from "./AuthProvider";
import { DataProvider, DataProviderOptions, Operations } from "./DataProvider";

/**
 * buildAuthProvider.
 *
 * @param {AuthProviderOptions} options auth provider options
 * @returns {AuthProviderInterface} auth provider
 */
export function buildAuthProvider(
  options?: AuthProviderOptions
): AuthProviderInterface {
  const authProvider = new AuthProvider(options);

  return {
    login: authProvider.login,
    logout: authProvider.logout,
    checkAuth: authProvider.checkAuth,
    checkError: authProvider.checkError,
    getPermissions: authProvider.getPermissions,
    getIdentity: authProvider.getIdentity,
  };
}

/**
 * buildDataProvider.
 *
 * @param {Operations} operations operations
 * @param {DataProviderOptions} options options
 * @returns {DataProviderInterface} data provider
 */
export function buildDataProvider(
  operations: Operations,
  options?: DataProviderOptions
): DataProviderInterface {
  const dataProvider = new DataProvider(operations, options);
  return dataProvider;
}
