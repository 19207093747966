/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateProductQuestionInput = {
  id?: string | null,
  question: string,
  productId: string,
  owner?: string | null,
  _version?: number | null,
  productQuestionsId?: string | null,
};

export type ModelProductQuestionConditionInput = {
  question?: ModelStringInput | null,
  productId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelProductQuestionConditionInput | null > | null,
  or?: Array< ModelProductQuestionConditionInput | null > | null,
  not?: ModelProductQuestionConditionInput | null,
  productQuestionsId?: ModelIDInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ProductQuestion = {
  __typename: "ProductQuestion",
  id: string,
  question: string,
  product: Product,
  productId: string,
  responses?: ModelQuestionResponseConnection | null,
  owner?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  productQuestionsId?: string | null,
};

export type QuestionInterface = {
  __typename: "QuestionInterface",
  id: string,
  question: string,
};

export type Product = {
  __typename: "Product",
  id: string,
  name: string,
  nameLowerCase?: string | null,
  description?: string | null,
  serviceInformation?: string | null,
  images?: ModelProductImageConnection | null,
  questions?: ModelProductQuestionConnection | null,
  organization?: Organization | null,
  organizationId?: string | null,
  salesTeam?: Team | null,
  salesTeamId?: string | null,
  meetings?: ModelMeetingConnection | null,
  meetingInvites?: ModelMeetingInviteConnection | null,
  labels?: Array< string | null > | null,
  owner?: string | null,
  sortByName?: number | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  organizationProductsId?: string | null,
  teamProductsId?: string | null,
};

export type ModelProductImageConnection = {
  __typename: "ModelProductImageConnection",
  items:  Array<ProductImage | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ProductImage = {
  __typename: "ProductImage",
  id: string,
  title: string,
  alt?: string | null,
  width?: number | null,
  height?: number | null,
  image: S3Object,
  product: Product,
  productId: string,
  owner?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  productImagesId?: string | null,
};

export type ImageInterface = {
  __typename: "ImageInterface",
  id: string,
  title: string,
  alt?: string | null,
  width?: number | null,
  height?: number | null,
  image: S3Object,
};

export type TeamImage = {
  __typename: "TeamImage",
  id: string,
  title: string,
  alt?: string | null,
  width?: number | null,
  height?: number | null,
  image: S3Object,
  team: Team,
  teamId: string,
  isDefault: boolean,
  owner?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  teamImagesId?: string | null,
};

export type S3Object = {
  __typename: "S3Object",
  bucket: string,
  region: string,
  key: string,
};

export type Team = {
  __typename: "Team",
  id: string,
  name: string,
  nameLowerCase?: string | null,
  description?: string | null,
  memberRelationships?: ModelTeamMemberRelationshipConnection | null,
  meetingRelationships?: ModelTeamMeetingRelationshipConnection | null,
  products?: ModelProductConnection | null,
  labels?: Array< string | null > | null,
  privacyEnabled: boolean,
  privacyEnableOtherTeams?: boolean | null,
  images?: ModelTeamImageConnection | null,
  transcriptions?: ModelTranscriptionConnection | null,
  owner?: string | null,
  sortByName?: number | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelTeamMemberRelationshipConnection = {
  __typename: "ModelTeamMemberRelationshipConnection",
  items:  Array<TeamMemberRelationship | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type TeamMemberRelationship = {
  __typename: "TeamMemberRelationship",
  id: string,
  team: Team,
  teamId: string,
  member: Profile,
  memberProfileId: string,
  relationship: TeamRelationship,
  owner?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  profileTeamRelationshipsId?: string | null,
  teamMemberRelationshipsId?: string | null,
};

export type Profile = {
  __typename: "Profile",
  id: string,
  fullName: string,
  fullNameLowerCase?: string | null,
  email: string,
  externalId?: string | null,
  userId?: string | null,
  occupation?: string | null,
  profile?: string | null,
  phoneNumber?: string | null,
  organization?: Organization | null,
  organizationId?: string | null,
  auditLogs?: ModelMeetingRoomEventAuditLogConnection | null,
  jobAuditLogs?: ModelImportJobAuditLogConnection | null,
  exportJobAuditLogs?: ModelExportJobAuditLogConnection | null,
  teamRelationships?: ModelTeamMemberRelationshipConnection | null,
  meetingAttendees?: ModelMeetingAttendeeConnection | null,
  locations?: ModelProfileLocationConnection | null,
  devices?: ModelProfileDeviceConnection | null,
  images?: ModelProfileImageConnection | null,
  questionResponses?: ModelQuestionResponseConnection | null,
  importJobs?: ModelImportJobConnection | null,
  exportJobs?: ModelExportJobConnection | null,
  privacyEnabled: boolean,
  owner?: string | null,
  meetingInvites?: ModelMeetingInviteConnection | null,
  sortByFullName?: number | null,
  transcriptionLogs?: ModelTranscriptionEventAuditLogConnection | null,
  timeZone?: string | null,
  forwardEmails?: ModelForwardEmailConnection | null,
  sellerForwardEmails?: ModelForwardEmailConnection | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  organizationProfilesId?: string | null,
};

export type Organization = {
  __typename: "Organization",
  id: string,
  externalId?: string | null,
  name: string,
  nameLowerCase?: string | null,
  description?: string | null,
  url?: string | null,
  phoneNumber?: string | null,
  locations?: ModelOrganizationLocationConnection | null,
  profiles?: ModelProfileConnection | null,
  products?: ModelProductConnection | null,
  labels?: Array< string | null > | null,
  owner?: string | null,
  sortByName?: number | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelOrganizationLocationConnection = {
  __typename: "ModelOrganizationLocationConnection",
  items:  Array<OrganizationLocation | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type OrganizationLocation = {
  __typename: "OrganizationLocation",
  id: string,
  title?: string | null,
  address?: string | null,
  city?: string | null,
  state?: string | null,
  country?: string | null,
  organization: Organization,
  organizationId: string,
  owner?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  organizationLocationsId?: string | null,
};

export type LocationInterface = {
  __typename: "LocationInterface",
  id: string,
  address?: string | null,
  city?: string | null,
  state?: string | null,
  country?: string | null,
};

export type ProfileLocation = {
  __typename: "ProfileLocation",
  id: string,
  title?: string | null,
  address?: string | null,
  city?: string | null,
  state?: string | null,
  country?: string | null,
  profile: Profile,
  profileId: string,
  isPrimary: boolean,
  owner?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  profileLocationsId?: string | null,
};

export type ModelProfileConnection = {
  __typename: "ModelProfileConnection",
  items:  Array<Profile | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelProductConnection = {
  __typename: "ModelProductConnection",
  items:  Array<Product | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelMeetingRoomEventAuditLogConnection = {
  __typename: "ModelMeetingRoomEventAuditLogConnection",
  items:  Array<MeetingRoomEventAuditLog | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type MeetingRoomEventAuditLog = {
  __typename: "MeetingRoomEventAuditLog",
  id: string,
  date: string,
  author: string,
  resource: string,
  action: string,
  payload?: string | null,
  ipAddress?: string | null,
  meetingRoom: MeetingRoom,
  meetingRoomId: string,
  profile?: Profile | null,
  profileId?: string | null,
  owner?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  profileAuditLogsId?: string | null,
  meetingRoomAuditlogsId?: string | null,
};

export type AuditLogInterface = {
  __typename: "AuditLogInterface",
  id: string,
  date: string,
  author: string,
  resource: string,
  action: string,
};

export type TranscriptionEventAuditLog = {
  __typename: "TranscriptionEventAuditLog",
  id: string,
  date: string,
  author: string,
  resource: string,
  action: string,
  payload?: string | null,
  ipAddress?: string | null,
  meetingInvite: MeetingInvite,
  meetingInviteId: string,
  profile: Profile,
  profileId: string,
  authorProfileId?: string | null,
  owner?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  profileTranscriptionLogsId?: string | null,
  meetingInviteTranscriptionLogsId?: string | null,
};

export type MeetingInvite = {
  __typename: "MeetingInvite",
  id: string,
  meetingId: string,
  provider?: MeetingRoomProvider | null,
  providerId?: string | null,
  title: string,
  startDateTime: string,
  endDateTime: string,
  status: MeetingStatus,
  description?: string | null,
  questionResponses?: ModelQuestionResponseConnection | null,
  privacyEnabled: boolean,
  videoEnabled: boolean,
  transcriptionEnabled: boolean,
  product?: Product | null,
  productId?: string | null,
  isReadinessTest: boolean,
  meetingRoomId?: string | null,
  providerSessionId?: string | null,
  providerJoinData?: string | null,
  sessionStatus?: MeetingRoomSessionStatus | null,
  actualStartDateTime?: string | null,
  actualEndDateTime?: string | null,
  profile: Profile,
  profileId: string,
  owner?: string | null,
  meetingInvitationType: MeetingInvitationType,
  teamId?: string | null,
  isInterested?: boolean | null,
  feedbackSummary?: string | null,
  contactRequestTimeframe?: string | null,
  contactPhoneNumber?: string | null,
  contactEmail?: string | null,
  sellerOrganizationName?: string | null,
  sellerTeamName?: string | null,
  buyerTeamName?: string | null,
  transcriptionLogs?: ModelTranscriptionEventAuditLogConnection | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  productMeetingInvitesId?: string | null,
  profileMeetingInvitesId?: string | null,
  meetingRoomProviderMeetingInvitesId?: string | null,
};

export type EventInterface = {
  __typename: "EventInterface",
  id: string,
  title: string,
  description?: string | null,
  startDateTime: string,
  endDateTime: string,
};

export type Meeting = {
  __typename: "Meeting",
  id: string,
  title: string,
  titleLowerCase?: string | null,
  startDateTime: string,
  endDateTime: string,
  status: MeetingStatus,
  description?: string | null,
  preferredRoomProvider?: MeetingRoomProvider | null,
  preferredRoomProviderId?: string | null,
  actualStartDateTime?: string | null,
  actualEndDateTime?: string | null,
  room?: MeetingRoom | null,
  questionResponses?: ModelQuestionResponseConnection | null,
  privacyEnabled: boolean,
  videoEnabled: boolean,
  transcriptionEnabled: boolean,
  product?: Product | null,
  productId?: string | null,
  isReadinessTest: boolean,
  labels?: Array< string | null > | null,
  owner?: string | null,
  teamRelationships?: ModelTeamMeetingRelationshipConnection | null,
  meetingAttendees?: ModelMeetingAttendeeConnection | null,
  transcriptions?: ModelTranscriptionConnection | null,
  sellerOrganizationName?: string | null,
  sellerTeamName?: string | null,
  buyerTeamName?: string | null,
  sellerOrganizationNameLowerCase?: string | null,
  sellerTeamNameLowerCase?: string | null,
  buyerTeamNameLowerCase?: string | null,
  externalId?: string | null,
  metrics?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  productMeetingsId?: string | null,
  meetingRoomProviderMeetingsId?: string | null,
  meetingRoomId?: string | null,
};

export enum MeetingStatus {
  SCHEDULED = "SCHEDULED",
  CANCELLED = "CANCELLED",
  ERROR = "ERROR",
  PENDING = "PENDING",
  READY = "READY",
  STARTED = "STARTED",
  ENDED = "ENDED",
  EXPIRED = "EXPIRED",
}


export type MeetingRoomProvider = {
  __typename: "MeetingRoomProvider",
  id: string,
  name: string,
  componentName?: string | null,
  description?: string | null,
  configuration?: string | null,
  meetings?: ModelMeetingConnection | null,
  meetingRooms?: ModelMeetingRoomConnection | null,
  meetingInvites?: ModelMeetingInviteConnection | null,
  owner?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelMeetingConnection = {
  __typename: "ModelMeetingConnection",
  items:  Array<Meeting | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelMeetingRoomConnection = {
  __typename: "ModelMeetingRoomConnection",
  items:  Array<MeetingRoom | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type MeetingRoom = {
  __typename: "MeetingRoom",
  id: string,
  name: string,
  meeting: Meeting,
  meetingId: string,
  provider: MeetingRoomProvider,
  providerId: string,
  providerMeetingId?: string | null,
  accessCode: string,
  status: MeetingRoomStatus,
  startDateTime?: string | null,
  endDateTime?: string | null,
  actualStartDateTime?: string | null,
  actualEndDateTime?: string | null,
  roomCreatedAt?: string | null,
  roomDestroyedAt?: string | null,
  auditlogs?: ModelMeetingRoomEventAuditLogConnection | null,
  privacyEnabled: boolean,
  videoEnabled: boolean,
  transcriptionEnabled: boolean,
  isReadinessTest: boolean,
  owner?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  meetingRoomProviderMeetingRoomsId?: string | null,
};

export enum MeetingRoomStatus {
  CLOSED = "CLOSED",
  OPEN = "OPEN",
}


export type ModelMeetingInviteConnection = {
  __typename: "ModelMeetingInviteConnection",
  items:  Array<MeetingInvite | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelQuestionResponseConnection = {
  __typename: "ModelQuestionResponseConnection",
  items:  Array<QuestionResponse | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type QuestionResponse = {
  __typename: "QuestionResponse",
  id: string,
  response?: string | null,
  privateNotes?: string | null,
  question: ProductQuestion,
  questionId: string,
  profile: Profile,
  profileId: string,
  meeting: Meeting,
  meetingId: string,
  meetingInvite: MeetingInvite,
  meetingInviteId: string,
  owner?: string | null,
  contactRequestTimeframe?: string | null,
  contactRequestDateTime?: string | null,
  contactPhoneNumber?: string | null,
  contactEmail?: string | null,
  contactName?: string | null,
  providePersonalEmail?: boolean | null,
  providePersonalPhoneNumber?: boolean | null,
  textOnly?: boolean | null,
  followupMeetingRequested?: boolean | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  productQuestionResponsesId?: string | null,
  profileQuestionResponsesId?: string | null,
  meetingQuestionResponsesId?: string | null,
  meetingInviteQuestionResponsesId?: string | null,
};

export type QuestionResponseInterface = {
  __typename: "QuestionResponseInterface",
  id: string,
  response?: string | null,
  question: QuestionInterface,
};

export type ModelTeamMeetingRelationshipConnection = {
  __typename: "ModelTeamMeetingRelationshipConnection",
  items:  Array<TeamMeetingRelationship | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type TeamMeetingRelationship = {
  __typename: "TeamMeetingRelationship",
  id: string,
  team: Team,
  teamId: string,
  meeting: Meeting,
  meetingId: string,
  relationshipType: TeamMeetingRelationshipType,
  owner?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  teamMeetingRelationshipsId?: string | null,
  meetingTeamRelationshipsId?: string | null,
};

export enum TeamMeetingRelationshipType {
  ORGANIZER = "ORGANIZER",
  INVITEE = "INVITEE",
}


export type ModelMeetingAttendeeConnection = {
  __typename: "ModelMeetingAttendeeConnection",
  items:  Array<MeetingAttendee | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type MeetingAttendee = {
  __typename: "MeetingAttendee",
  id: string,
  profile: Profile,
  profileId: string,
  meeting: Meeting,
  meetingId: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  profileMeetingAttendeesId?: string | null,
  meetingMeetingAttendeesId?: string | null,
};

export type ModelTranscriptionConnection = {
  __typename: "ModelTranscriptionConnection",
  items:  Array<Transcription | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type Transcription = {
  __typename: "Transcription",
  id: string,
  date: string,
  title: string,
  description?: string | null,
  startDateTime: string,
  endDateTime: string,
  result: S3Object,
  sizeInBytes?: number | null,
  numberOfTranscriptions?: number | null,
  isZipFile: boolean,
  meeting?: Meeting | null,
  meetingId?: string | null,
  team?: Team | null,
  teamId?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  teamTranscriptionsId?: string | null,
  meetingTranscriptionsId?: string | null,
};

export enum MeetingRoomSessionStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}


export enum MeetingInvitationType {
  INVITEE = "INVITEE",
  ORGANIZER = "ORGANIZER",
}


export type ModelTranscriptionEventAuditLogConnection = {
  __typename: "ModelTranscriptionEventAuditLogConnection",
  items:  Array<TranscriptionEventAuditLog | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ImportJobAuditLog = {
  __typename: "ImportJobAuditLog",
  id: string,
  date: string,
  author: string,
  resource: string,
  action: string,
  payload?: string | null,
  ipAddress?: string | null,
  importJob: ImportJob,
  importJobId: string,
  profile?: Profile | null,
  profileId?: string | null,
  owner?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  profileJobAuditLogsId?: string | null,
  importJobAuditLogsId?: string | null,
};

export type ImportJob = {
  __typename: "ImportJob",
  id: string,
  status: JobStatus,
  source: S3Object,
  identityId?: string | null,
  result?: string | null,
  profile?: Profile | null,
  profileId?: string | null,
  auditLogs?: ModelImportJobAuditLogConnection | null,
  lastStartDateTime?: string | null,
  lastEndDateTime?: string | null,
  owner?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  profileImportJobsId?: string | null,
};

export enum JobStatus {
  PENDING = "PENDING",
  PROCESSING = "PROCESSING",
  FAILED = "FAILED",
  CANCELLED = "CANCELLED",
  COMPLETED = "COMPLETED",
}


export type ModelImportJobAuditLogConnection = {
  __typename: "ModelImportJobAuditLogConnection",
  items:  Array<ImportJobAuditLog | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ExportJobAuditLog = {
  __typename: "ExportJobAuditLog",
  id: string,
  date: string,
  author: string,
  resource: string,
  action: string,
  payload?: string | null,
  ipAddress?: string | null,
  exportJob: ExportJob,
  exportJobId: string,
  profile?: Profile | null,
  profileId?: string | null,
  owner: string,
  source?: S3Object | null,
  identityId?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  profileExportJobAuditLogsId?: string | null,
  exportJobAuditLogsId?: string | null,
};

export type ExportJob = {
  __typename: "ExportJob",
  id: string,
  status: JobStatus,
  profile?: Profile | null,
  profileId?: string | null,
  auditLogs?: ModelExportJobAuditLogConnection | null,
  meetingInvitationType?: MeetingInvitationType | null,
  startDateTime: string,
  endDateTime: string,
  owner: string,
  identityId: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  profileExportJobsId?: string | null,
};

export type ModelExportJobAuditLogConnection = {
  __typename: "ModelExportJobAuditLogConnection",
  items:  Array<ExportJobAuditLog | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type TranscriptionJobAuditLog = {
  __typename: "TranscriptionJobAuditLog",
  id: string,
  date: string,
  author: string,
  resource: string,
  action: string,
  payload?: string | null,
  ipAddress?: string | null,
  transcriptionJob: TranscriptionJob,
  transcriptionJobId: string,
  owner?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  transcriptionJobAuditLogsId?: string | null,
};

export type TranscriptionJob = {
  __typename: "TranscriptionJob",
  id: string,
  date: string,
  status: JobStatus,
  result?: S3Object | null,
  meetingInviteId: string,
  auditLogs?: ModelTranscriptionJobAuditLogConnection | null,
  owner: string,
  identityId: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelTranscriptionJobAuditLogConnection = {
  __typename: "ModelTranscriptionJobAuditLogConnection",
  items:  Array<TranscriptionJobAuditLog | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type TranscriptionAdminJobAuditLog = {
  __typename: "TranscriptionAdminJobAuditLog",
  id: string,
  date: string,
  author: string,
  resource: string,
  action: string,
  payload?: string | null,
  ipAddress?: string | null,
  transcriptionAdminJob: TranscriptionAdminJob,
  transcriptionAdminJobId: string,
  owner?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  transcriptionAdminJobAuditLogsId?: string | null,
};

export type TranscriptionAdminJob = {
  __typename: "TranscriptionAdminJob",
  id: string,
  date: string,
  status: JobStatus,
  result?: S3Object | null,
  meetingId: string,
  meetingInviteId?: string | null,
  auditLogs?: ModelTranscriptionAdminJobAuditLogConnection | null,
  owner: string,
  identityId: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelTranscriptionAdminJobAuditLogConnection = {
  __typename: "ModelTranscriptionAdminJobAuditLogConnection",
  items:  Array<TranscriptionAdminJobAuditLog | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelProfileLocationConnection = {
  __typename: "ModelProfileLocationConnection",
  items:  Array<ProfileLocation | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelProfileDeviceConnection = {
  __typename: "ModelProfileDeviceConnection",
  items:  Array<ProfileDevice | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ProfileDevice = {
  __typename: "ProfileDevice",
  id: string,
  deviceHash: string,
  hasWebcam?: boolean | null,
  hasMicrophone?: boolean | null,
  isScreenCapturingSupported?: boolean | null,
  isWebRTCSupported?: boolean | null,
  isWebsiteHasMicrophonePermissions?: boolean | null,
  isWebsiteHasWebcamPermissions?: boolean | null,
  isWebSocketsSupported?: boolean | null,
  clockOffset?: number | null,
  lastSeen?: string | null,
  lastLogin?: string | null,
  lastMeetingId?: string | null,
  lastMeetingInviteId?: string | null,
  lastMeetingJoin?: string | null,
  lastMeetingStatus?: string | null,
  meetingData?: string | null,
  userAgent?: string | null,
  userAgentSummary?: string | null,
  micStatus?: string | null,
  cameraStatus?: string | null,
  networkQuality?: string | null,
  profile: Profile,
  profileId: string,
  owner?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  profileDevicesId?: string | null,
};

export type ModelProfileImageConnection = {
  __typename: "ModelProfileImageConnection",
  items:  Array<ProfileImage | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ProfileImage = {
  __typename: "ProfileImage",
  id: string,
  title: string,
  alt?: string | null,
  width?: number | null,
  height?: number | null,
  image: S3Object,
  profile: Profile,
  profileId: string,
  isDefault: boolean,
  owner?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  profileImagesId?: string | null,
};

export type ModelImportJobConnection = {
  __typename: "ModelImportJobConnection",
  items:  Array<ImportJob | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelExportJobConnection = {
  __typename: "ModelExportJobConnection",
  items:  Array<ExportJob | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelForwardEmailConnection = {
  __typename: "ModelForwardEmailConnection",
  items:  Array<ForwardEmail | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ForwardEmail = {
  __typename: "ForwardEmail",
  id: string,
  received?: number | null,
  remaining?: number | null,
  publicEmail: string,
  expireAt?: string | null,
  privateEmail?: string | null,
  publicReplyEmail?: string | null,
  profile?: Profile | null,
  profileId?: string | null,
  sellerProfile?: Profile | null,
  sellerProfileId?: string | null,
  owner?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  profileForwardEmailsId?: string | null,
  profileSellerForwardEmailsId?: string | null,
};

export enum TeamRelationship {
  LEADER = "LEADER",
  MEMBER = "MEMBER",
  MANAGER = "MANAGER",
  GUEST = "GUEST",
}


export type ModelTeamImageConnection = {
  __typename: "ModelTeamImageConnection",
  items:  Array<TeamImage | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelProductQuestionConnection = {
  __typename: "ModelProductQuestionConnection",
  items:  Array<ProductQuestion | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type UpdateProductQuestionInput = {
  id: string,
  question?: string | null,
  productId?: string | null,
  owner?: string | null,
  _version?: number | null,
  productQuestionsId?: string | null,
};

export type DeleteProductQuestionInput = {
  id: string,
  _version?: number | null,
};

export type CreateQuestionResponseInput = {
  id?: string | null,
  response?: string | null,
  privateNotes?: string | null,
  questionId: string,
  profileId: string,
  meetingId: string,
  meetingInviteId: string,
  owner?: string | null,
  contactRequestTimeframe?: string | null,
  contactRequestDateTime?: string | null,
  contactPhoneNumber?: string | null,
  contactEmail?: string | null,
  contactName?: string | null,
  providePersonalEmail?: boolean | null,
  providePersonalPhoneNumber?: boolean | null,
  textOnly?: boolean | null,
  followupMeetingRequested?: boolean | null,
  _version?: number | null,
  productQuestionResponsesId?: string | null,
  profileQuestionResponsesId?: string | null,
  meetingQuestionResponsesId?: string | null,
  meetingInviteQuestionResponsesId?: string | null,
};

export type ModelQuestionResponseConditionInput = {
  response?: ModelStringInput | null,
  privateNotes?: ModelStringInput | null,
  questionId?: ModelIDInput | null,
  profileId?: ModelIDInput | null,
  meetingId?: ModelIDInput | null,
  meetingInviteId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  contactRequestTimeframe?: ModelStringInput | null,
  contactRequestDateTime?: ModelStringInput | null,
  contactPhoneNumber?: ModelStringInput | null,
  contactEmail?: ModelStringInput | null,
  contactName?: ModelStringInput | null,
  providePersonalEmail?: ModelBooleanInput | null,
  providePersonalPhoneNumber?: ModelBooleanInput | null,
  textOnly?: ModelBooleanInput | null,
  followupMeetingRequested?: ModelBooleanInput | null,
  and?: Array< ModelQuestionResponseConditionInput | null > | null,
  or?: Array< ModelQuestionResponseConditionInput | null > | null,
  not?: ModelQuestionResponseConditionInput | null,
  productQuestionResponsesId?: ModelIDInput | null,
  profileQuestionResponsesId?: ModelIDInput | null,
  meetingQuestionResponsesId?: ModelIDInput | null,
  meetingInviteQuestionResponsesId?: ModelIDInput | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UpdateQuestionResponseInput = {
  id: string,
  response?: string | null,
  privateNotes?: string | null,
  questionId?: string | null,
  profileId?: string | null,
  meetingId?: string | null,
  meetingInviteId?: string | null,
  owner?: string | null,
  contactRequestTimeframe?: string | null,
  contactRequestDateTime?: string | null,
  contactPhoneNumber?: string | null,
  contactEmail?: string | null,
  contactName?: string | null,
  providePersonalEmail?: boolean | null,
  providePersonalPhoneNumber?: boolean | null,
  textOnly?: boolean | null,
  followupMeetingRequested?: boolean | null,
  _version?: number | null,
  productQuestionResponsesId?: string | null,
  profileQuestionResponsesId?: string | null,
  meetingQuestionResponsesId?: string | null,
  meetingInviteQuestionResponsesId?: string | null,
};

export type DeleteQuestionResponseInput = {
  id: string,
  _version?: number | null,
};

export type CreateProductImageInput = {
  id?: string | null,
  title: string,
  alt?: string | null,
  width?: number | null,
  height?: number | null,
  image: S3ObjectInput,
  productId: string,
  owner?: string | null,
  _version?: number | null,
  productImagesId?: string | null,
};

export type S3ObjectInput = {
  bucket: string,
  region: string,
  key: string,
};

export type ModelProductImageConditionInput = {
  title?: ModelStringInput | null,
  alt?: ModelStringInput | null,
  width?: ModelIntInput | null,
  height?: ModelIntInput | null,
  productId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelProductImageConditionInput | null > | null,
  or?: Array< ModelProductImageConditionInput | null > | null,
  not?: ModelProductImageConditionInput | null,
  productImagesId?: ModelIDInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UpdateProductImageInput = {
  id: string,
  title?: string | null,
  alt?: string | null,
  width?: number | null,
  height?: number | null,
  image?: S3ObjectInput | null,
  productId?: string | null,
  owner?: string | null,
  _version?: number | null,
  productImagesId?: string | null,
};

export type DeleteProductImageInput = {
  id: string,
  _version?: number | null,
};

export type CreateProductInput = {
  id?: string | null,
  name: string,
  nameLowerCase?: string | null,
  description?: string | null,
  serviceInformation?: string | null,
  organizationId?: string | null,
  salesTeamId?: string | null,
  labels?: Array< string | null > | null,
  owner?: string | null,
  sortByName?: number | null,
  _version?: number | null,
  organizationProductsId?: string | null,
  teamProductsId?: string | null,
};

export type ModelProductConditionInput = {
  name?: ModelStringInput | null,
  nameLowerCase?: ModelStringInput | null,
  description?: ModelStringInput | null,
  serviceInformation?: ModelStringInput | null,
  organizationId?: ModelIDInput | null,
  salesTeamId?: ModelIDInput | null,
  labels?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  sortByName?: ModelIntInput | null,
  and?: Array< ModelProductConditionInput | null > | null,
  or?: Array< ModelProductConditionInput | null > | null,
  not?: ModelProductConditionInput | null,
  organizationProductsId?: ModelIDInput | null,
  teamProductsId?: ModelIDInput | null,
};

export type UpdateProductInput = {
  id: string,
  name?: string | null,
  nameLowerCase?: string | null,
  description?: string | null,
  serviceInformation?: string | null,
  organizationId?: string | null,
  salesTeamId?: string | null,
  labels?: Array< string | null > | null,
  owner?: string | null,
  sortByName?: number | null,
  _version?: number | null,
  organizationProductsId?: string | null,
  teamProductsId?: string | null,
};

export type DeleteProductInput = {
  id: string,
  _version?: number | null,
};

export type CreateOrganizationLocationInput = {
  id?: string | null,
  title?: string | null,
  address?: string | null,
  city?: string | null,
  state?: string | null,
  country?: string | null,
  organizationId: string,
  owner?: string | null,
  _version?: number | null,
  organizationLocationsId?: string | null,
};

export type ModelOrganizationLocationConditionInput = {
  title?: ModelStringInput | null,
  address?: ModelStringInput | null,
  city?: ModelStringInput | null,
  state?: ModelStringInput | null,
  country?: ModelStringInput | null,
  organizationId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelOrganizationLocationConditionInput | null > | null,
  or?: Array< ModelOrganizationLocationConditionInput | null > | null,
  not?: ModelOrganizationLocationConditionInput | null,
  organizationLocationsId?: ModelIDInput | null,
};

export type UpdateOrganizationLocationInput = {
  id: string,
  title?: string | null,
  address?: string | null,
  city?: string | null,
  state?: string | null,
  country?: string | null,
  organizationId?: string | null,
  owner?: string | null,
  _version?: number | null,
  organizationLocationsId?: string | null,
};

export type DeleteOrganizationLocationInput = {
  id: string,
  _version?: number | null,
};

export type CreateOrganizationInput = {
  id?: string | null,
  externalId?: string | null,
  name: string,
  nameLowerCase?: string | null,
  description?: string | null,
  url?: string | null,
  phoneNumber?: string | null,
  labels?: Array< string | null > | null,
  owner?: string | null,
  sortByName?: number | null,
  _version?: number | null,
};

export type ModelOrganizationConditionInput = {
  externalId?: ModelStringInput | null,
  name?: ModelStringInput | null,
  nameLowerCase?: ModelStringInput | null,
  description?: ModelStringInput | null,
  url?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  labels?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  sortByName?: ModelIntInput | null,
  and?: Array< ModelOrganizationConditionInput | null > | null,
  or?: Array< ModelOrganizationConditionInput | null > | null,
  not?: ModelOrganizationConditionInput | null,
};

export type UpdateOrganizationInput = {
  id: string,
  externalId?: string | null,
  name?: string | null,
  nameLowerCase?: string | null,
  description?: string | null,
  url?: string | null,
  phoneNumber?: string | null,
  labels?: Array< string | null > | null,
  owner?: string | null,
  sortByName?: number | null,
  _version?: number | null,
};

export type DeleteOrganizationInput = {
  id: string,
  _version?: number | null,
};

export type CreateProfileLocationInput = {
  id?: string | null,
  title?: string | null,
  address?: string | null,
  city?: string | null,
  state?: string | null,
  country?: string | null,
  profileId: string,
  isPrimary: boolean,
  owner?: string | null,
  _version?: number | null,
  profileLocationsId?: string | null,
};

export type ModelProfileLocationConditionInput = {
  title?: ModelStringInput | null,
  address?: ModelStringInput | null,
  city?: ModelStringInput | null,
  state?: ModelStringInput | null,
  country?: ModelStringInput | null,
  profileId?: ModelIDInput | null,
  isPrimary?: ModelBooleanInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelProfileLocationConditionInput | null > | null,
  or?: Array< ModelProfileLocationConditionInput | null > | null,
  not?: ModelProfileLocationConditionInput | null,
  profileLocationsId?: ModelIDInput | null,
};

export type UpdateProfileLocationInput = {
  id: string,
  title?: string | null,
  address?: string | null,
  city?: string | null,
  state?: string | null,
  country?: string | null,
  profileId?: string | null,
  isPrimary?: boolean | null,
  owner?: string | null,
  _version?: number | null,
  profileLocationsId?: string | null,
};

export type DeleteProfileLocationInput = {
  id: string,
  _version?: number | null,
};

export type CreateProfileImageInput = {
  id?: string | null,
  title: string,
  alt?: string | null,
  width?: number | null,
  height?: number | null,
  image: S3ObjectInput,
  profileId: string,
  isDefault: boolean,
  owner?: string | null,
  _version?: number | null,
  profileImagesId?: string | null,
};

export type ModelProfileImageConditionInput = {
  title?: ModelStringInput | null,
  alt?: ModelStringInput | null,
  width?: ModelIntInput | null,
  height?: ModelIntInput | null,
  profileId?: ModelIDInput | null,
  isDefault?: ModelBooleanInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelProfileImageConditionInput | null > | null,
  or?: Array< ModelProfileImageConditionInput | null > | null,
  not?: ModelProfileImageConditionInput | null,
  profileImagesId?: ModelIDInput | null,
};

export type UpdateProfileImageInput = {
  id: string,
  title?: string | null,
  alt?: string | null,
  width?: number | null,
  height?: number | null,
  image?: S3ObjectInput | null,
  profileId?: string | null,
  isDefault?: boolean | null,
  owner?: string | null,
  _version?: number | null,
  profileImagesId?: string | null,
};

export type DeleteProfileImageInput = {
  id: string,
  _version?: number | null,
};

export type CreateForwardEmailInput = {
  id?: string | null,
  received?: number | null,
  remaining?: number | null,
  publicEmail: string,
  expireAt?: string | null,
  privateEmail?: string | null,
  publicReplyEmail?: string | null,
  profileId?: string | null,
  sellerProfileId?: string | null,
  owner?: string | null,
  _version?: number | null,
  profileForwardEmailsId?: string | null,
  profileSellerForwardEmailsId?: string | null,
};

export type ModelForwardEmailConditionInput = {
  received?: ModelIntInput | null,
  remaining?: ModelIntInput | null,
  publicEmail?: ModelStringInput | null,
  expireAt?: ModelStringInput | null,
  privateEmail?: ModelStringInput | null,
  publicReplyEmail?: ModelStringInput | null,
  profileId?: ModelIDInput | null,
  sellerProfileId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelForwardEmailConditionInput | null > | null,
  or?: Array< ModelForwardEmailConditionInput | null > | null,
  not?: ModelForwardEmailConditionInput | null,
  profileForwardEmailsId?: ModelIDInput | null,
  profileSellerForwardEmailsId?: ModelIDInput | null,
};

export type UpdateForwardEmailInput = {
  id: string,
  received?: number | null,
  remaining?: number | null,
  publicEmail?: string | null,
  expireAt?: string | null,
  privateEmail?: string | null,
  publicReplyEmail?: string | null,
  profileId?: string | null,
  sellerProfileId?: string | null,
  owner?: string | null,
  _version?: number | null,
  profileForwardEmailsId?: string | null,
  profileSellerForwardEmailsId?: string | null,
};

export type DeleteForwardEmailInput = {
  id: string,
  _version?: number | null,
};

export type CreateProfileInput = {
  id?: string | null,
  fullName: string,
  fullNameLowerCase?: string | null,
  email: string,
  externalId?: string | null,
  userId?: string | null,
  occupation?: string | null,
  profile?: string | null,
  phoneNumber?: string | null,
  organizationId?: string | null,
  privacyEnabled: boolean,
  owner?: string | null,
  sortByFullName?: number | null,
  timeZone?: string | null,
  _version?: number | null,
  organizationProfilesId?: string | null,
};

export type ModelProfileConditionInput = {
  fullName?: ModelStringInput | null,
  fullNameLowerCase?: ModelStringInput | null,
  email?: ModelStringInput | null,
  externalId?: ModelIDInput | null,
  userId?: ModelStringInput | null,
  occupation?: ModelStringInput | null,
  profile?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  organizationId?: ModelIDInput | null,
  privacyEnabled?: ModelBooleanInput | null,
  owner?: ModelStringInput | null,
  sortByFullName?: ModelIntInput | null,
  timeZone?: ModelStringInput | null,
  and?: Array< ModelProfileConditionInput | null > | null,
  or?: Array< ModelProfileConditionInput | null > | null,
  not?: ModelProfileConditionInput | null,
  organizationProfilesId?: ModelIDInput | null,
};

export type UpdateProfileInput = {
  id: string,
  fullName?: string | null,
  fullNameLowerCase?: string | null,
  email?: string | null,
  externalId?: string | null,
  userId?: string | null,
  occupation?: string | null,
  profile?: string | null,
  phoneNumber?: string | null,
  organizationId?: string | null,
  privacyEnabled?: boolean | null,
  owner?: string | null,
  sortByFullName?: number | null,
  timeZone?: string | null,
  _version?: number | null,
  organizationProfilesId?: string | null,
};

export type DeleteProfileInput = {
  id: string,
  _version?: number | null,
};

export type CreateProfileDeviceInput = {
  id?: string | null,
  deviceHash: string,
  hasWebcam?: boolean | null,
  hasMicrophone?: boolean | null,
  isScreenCapturingSupported?: boolean | null,
  isWebRTCSupported?: boolean | null,
  isWebsiteHasMicrophonePermissions?: boolean | null,
  isWebsiteHasWebcamPermissions?: boolean | null,
  isWebSocketsSupported?: boolean | null,
  clockOffset?: number | null,
  lastSeen?: string | null,
  lastLogin?: string | null,
  lastMeetingId?: string | null,
  lastMeetingInviteId?: string | null,
  lastMeetingJoin?: string | null,
  lastMeetingStatus?: string | null,
  meetingData?: string | null,
  userAgent?: string | null,
  userAgentSummary?: string | null,
  micStatus?: string | null,
  cameraStatus?: string | null,
  networkQuality?: string | null,
  profileId: string,
  owner?: string | null,
  _version?: number | null,
  profileDevicesId?: string | null,
};

export type ModelProfileDeviceConditionInput = {
  deviceHash?: ModelStringInput | null,
  hasWebcam?: ModelBooleanInput | null,
  hasMicrophone?: ModelBooleanInput | null,
  isScreenCapturingSupported?: ModelBooleanInput | null,
  isWebRTCSupported?: ModelBooleanInput | null,
  isWebsiteHasMicrophonePermissions?: ModelBooleanInput | null,
  isWebsiteHasWebcamPermissions?: ModelBooleanInput | null,
  isWebSocketsSupported?: ModelBooleanInput | null,
  clockOffset?: ModelIntInput | null,
  lastSeen?: ModelStringInput | null,
  lastLogin?: ModelStringInput | null,
  lastMeetingId?: ModelStringInput | null,
  lastMeetingInviteId?: ModelStringInput | null,
  lastMeetingJoin?: ModelStringInput | null,
  lastMeetingStatus?: ModelStringInput | null,
  meetingData?: ModelStringInput | null,
  userAgent?: ModelStringInput | null,
  userAgentSummary?: ModelStringInput | null,
  micStatus?: ModelStringInput | null,
  cameraStatus?: ModelStringInput | null,
  networkQuality?: ModelStringInput | null,
  profileId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelProfileDeviceConditionInput | null > | null,
  or?: Array< ModelProfileDeviceConditionInput | null > | null,
  not?: ModelProfileDeviceConditionInput | null,
  profileDevicesId?: ModelIDInput | null,
};

export type UpdateProfileDeviceInput = {
  id: string,
  deviceHash?: string | null,
  hasWebcam?: boolean | null,
  hasMicrophone?: boolean | null,
  isScreenCapturingSupported?: boolean | null,
  isWebRTCSupported?: boolean | null,
  isWebsiteHasMicrophonePermissions?: boolean | null,
  isWebsiteHasWebcamPermissions?: boolean | null,
  isWebSocketsSupported?: boolean | null,
  clockOffset?: number | null,
  lastSeen?: string | null,
  lastLogin?: string | null,
  lastMeetingId?: string | null,
  lastMeetingInviteId?: string | null,
  lastMeetingJoin?: string | null,
  lastMeetingStatus?: string | null,
  meetingData?: string | null,
  userAgent?: string | null,
  userAgentSummary?: string | null,
  micStatus?: string | null,
  cameraStatus?: string | null,
  networkQuality?: string | null,
  profileId?: string | null,
  owner?: string | null,
  _version?: number | null,
  profileDevicesId?: string | null,
};

export type DeleteProfileDeviceInput = {
  id: string,
  _version?: number | null,
};

export type CreateTeamImageInput = {
  id?: string | null,
  title: string,
  alt?: string | null,
  width?: number | null,
  height?: number | null,
  image: S3ObjectInput,
  teamId: string,
  isDefault: boolean,
  owner?: string | null,
  _version?: number | null,
  teamImagesId?: string | null,
};

export type ModelTeamImageConditionInput = {
  title?: ModelStringInput | null,
  alt?: ModelStringInput | null,
  width?: ModelIntInput | null,
  height?: ModelIntInput | null,
  teamId?: ModelIDInput | null,
  isDefault?: ModelBooleanInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelTeamImageConditionInput | null > | null,
  or?: Array< ModelTeamImageConditionInput | null > | null,
  not?: ModelTeamImageConditionInput | null,
  teamImagesId?: ModelIDInput | null,
};

export type UpdateTeamImageInput = {
  id: string,
  title?: string | null,
  alt?: string | null,
  width?: number | null,
  height?: number | null,
  image?: S3ObjectInput | null,
  teamId?: string | null,
  isDefault?: boolean | null,
  owner?: string | null,
  _version?: number | null,
  teamImagesId?: string | null,
};

export type DeleteTeamImageInput = {
  id: string,
  _version?: number | null,
};

export type CreateTeamInput = {
  id?: string | null,
  name: string,
  nameLowerCase?: string | null,
  description?: string | null,
  labels?: Array< string | null > | null,
  privacyEnabled: boolean,
  privacyEnableOtherTeams?: boolean | null,
  owner?: string | null,
  sortByName?: number | null,
  _version?: number | null,
};

export type ModelTeamConditionInput = {
  name?: ModelStringInput | null,
  nameLowerCase?: ModelStringInput | null,
  description?: ModelStringInput | null,
  labels?: ModelStringInput | null,
  privacyEnabled?: ModelBooleanInput | null,
  privacyEnableOtherTeams?: ModelBooleanInput | null,
  owner?: ModelStringInput | null,
  sortByName?: ModelIntInput | null,
  and?: Array< ModelTeamConditionInput | null > | null,
  or?: Array< ModelTeamConditionInput | null > | null,
  not?: ModelTeamConditionInput | null,
};

export type UpdateTeamInput = {
  id: string,
  name?: string | null,
  nameLowerCase?: string | null,
  description?: string | null,
  labels?: Array< string | null > | null,
  privacyEnabled?: boolean | null,
  privacyEnableOtherTeams?: boolean | null,
  owner?: string | null,
  sortByName?: number | null,
  _version?: number | null,
};

export type DeleteTeamInput = {
  id: string,
  _version?: number | null,
};

export type CreateTeamMemberRelationshipInput = {
  id?: string | null,
  teamId: string,
  memberProfileId: string,
  relationship: TeamRelationship,
  owner?: string | null,
  _version?: number | null,
  profileTeamRelationshipsId?: string | null,
  teamMemberRelationshipsId?: string | null,
};

export type ModelTeamMemberRelationshipConditionInput = {
  teamId?: ModelIDInput | null,
  memberProfileId?: ModelIDInput | null,
  relationship?: ModelTeamRelationshipInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelTeamMemberRelationshipConditionInput | null > | null,
  or?: Array< ModelTeamMemberRelationshipConditionInput | null > | null,
  not?: ModelTeamMemberRelationshipConditionInput | null,
  profileTeamRelationshipsId?: ModelIDInput | null,
  teamMemberRelationshipsId?: ModelIDInput | null,
};

export type ModelTeamRelationshipInput = {
  eq?: TeamRelationship | null,
  ne?: TeamRelationship | null,
};

export type UpdateTeamMemberRelationshipInput = {
  id: string,
  teamId?: string | null,
  memberProfileId?: string | null,
  relationship?: TeamRelationship | null,
  owner?: string | null,
  _version?: number | null,
  profileTeamRelationshipsId?: string | null,
  teamMemberRelationshipsId?: string | null,
};

export type DeleteTeamMemberRelationshipInput = {
  id: string,
  _version?: number | null,
};

export type CreateMeetingRoomProviderInput = {
  id?: string | null,
  name: string,
  componentName?: string | null,
  description?: string | null,
  configuration?: string | null,
  owner?: string | null,
  _version?: number | null,
};

export type ModelMeetingRoomProviderConditionInput = {
  name?: ModelStringInput | null,
  componentName?: ModelStringInput | null,
  description?: ModelStringInput | null,
  configuration?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelMeetingRoomProviderConditionInput | null > | null,
  or?: Array< ModelMeetingRoomProviderConditionInput | null > | null,
  not?: ModelMeetingRoomProviderConditionInput | null,
};

export type UpdateMeetingRoomProviderInput = {
  id: string,
  name?: string | null,
  componentName?: string | null,
  description?: string | null,
  configuration?: string | null,
  owner?: string | null,
  _version?: number | null,
};

export type DeleteMeetingRoomProviderInput = {
  id: string,
  _version?: number | null,
};

export type CreateTeamMeetingRelationshipInput = {
  id?: string | null,
  teamId: string,
  meetingId: string,
  relationshipType: TeamMeetingRelationshipType,
  owner?: string | null,
  _version?: number | null,
  teamMeetingRelationshipsId?: string | null,
  meetingTeamRelationshipsId?: string | null,
};

export type ModelTeamMeetingRelationshipConditionInput = {
  teamId?: ModelIDInput | null,
  meetingId?: ModelIDInput | null,
  relationshipType?: ModelTeamMeetingRelationshipTypeInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelTeamMeetingRelationshipConditionInput | null > | null,
  or?: Array< ModelTeamMeetingRelationshipConditionInput | null > | null,
  not?: ModelTeamMeetingRelationshipConditionInput | null,
  teamMeetingRelationshipsId?: ModelIDInput | null,
  meetingTeamRelationshipsId?: ModelIDInput | null,
};

export type ModelTeamMeetingRelationshipTypeInput = {
  eq?: TeamMeetingRelationshipType | null,
  ne?: TeamMeetingRelationshipType | null,
};

export type UpdateTeamMeetingRelationshipInput = {
  id: string,
  teamId?: string | null,
  meetingId?: string | null,
  relationshipType?: TeamMeetingRelationshipType | null,
  owner?: string | null,
  _version?: number | null,
  teamMeetingRelationshipsId?: string | null,
  meetingTeamRelationshipsId?: string | null,
};

export type DeleteTeamMeetingRelationshipInput = {
  id: string,
  _version?: number | null,
};

export type CreateMeetingAttendeeInput = {
  id?: string | null,
  profileId: string,
  meetingId: string,
  _version?: number | null,
  profileMeetingAttendeesId?: string | null,
  meetingMeetingAttendeesId?: string | null,
};

export type ModelMeetingAttendeeConditionInput = {
  profileId?: ModelIDInput | null,
  meetingId?: ModelIDInput | null,
  and?: Array< ModelMeetingAttendeeConditionInput | null > | null,
  or?: Array< ModelMeetingAttendeeConditionInput | null > | null,
  not?: ModelMeetingAttendeeConditionInput | null,
  profileMeetingAttendeesId?: ModelIDInput | null,
  meetingMeetingAttendeesId?: ModelIDInput | null,
};

export type UpdateMeetingAttendeeInput = {
  id: string,
  profileId?: string | null,
  meetingId?: string | null,
  _version?: number | null,
  profileMeetingAttendeesId?: string | null,
  meetingMeetingAttendeesId?: string | null,
};

export type DeleteMeetingAttendeeInput = {
  id: string,
  _version?: number | null,
};

export type CreateMeetingInput = {
  id?: string | null,
  title: string,
  titleLowerCase?: string | null,
  startDateTime: string,
  endDateTime: string,
  status: MeetingStatus,
  description?: string | null,
  preferredRoomProviderId?: string | null,
  actualStartDateTime?: string | null,
  actualEndDateTime?: string | null,
  privacyEnabled: boolean,
  videoEnabled: boolean,
  transcriptionEnabled: boolean,
  productId?: string | null,
  isReadinessTest: boolean,
  labels?: Array< string | null > | null,
  owner?: string | null,
  sellerOrganizationName?: string | null,
  sellerTeamName?: string | null,
  buyerTeamName?: string | null,
  sellerOrganizationNameLowerCase?: string | null,
  sellerTeamNameLowerCase?: string | null,
  buyerTeamNameLowerCase?: string | null,
  externalId?: string | null,
  metrics?: string | null,
  _version?: number | null,
  productMeetingsId?: string | null,
  meetingRoomProviderMeetingsId?: string | null,
  meetingRoomId?: string | null,
};

export type ModelMeetingConditionInput = {
  title?: ModelStringInput | null,
  titleLowerCase?: ModelStringInput | null,
  startDateTime?: ModelStringInput | null,
  endDateTime?: ModelStringInput | null,
  status?: ModelMeetingStatusInput | null,
  description?: ModelStringInput | null,
  preferredRoomProviderId?: ModelIDInput | null,
  actualStartDateTime?: ModelStringInput | null,
  actualEndDateTime?: ModelStringInput | null,
  privacyEnabled?: ModelBooleanInput | null,
  videoEnabled?: ModelBooleanInput | null,
  transcriptionEnabled?: ModelBooleanInput | null,
  productId?: ModelIDInput | null,
  isReadinessTest?: ModelBooleanInput | null,
  labels?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  sellerOrganizationName?: ModelStringInput | null,
  sellerTeamName?: ModelStringInput | null,
  buyerTeamName?: ModelStringInput | null,
  sellerOrganizationNameLowerCase?: ModelStringInput | null,
  sellerTeamNameLowerCase?: ModelStringInput | null,
  buyerTeamNameLowerCase?: ModelStringInput | null,
  externalId?: ModelStringInput | null,
  metrics?: ModelStringInput | null,
  and?: Array< ModelMeetingConditionInput | null > | null,
  or?: Array< ModelMeetingConditionInput | null > | null,
  not?: ModelMeetingConditionInput | null,
  productMeetingsId?: ModelIDInput | null,
  meetingRoomProviderMeetingsId?: ModelIDInput | null,
  meetingRoomId?: ModelIDInput | null,
};

export type ModelMeetingStatusInput = {
  eq?: MeetingStatus | null,
  ne?: MeetingStatus | null,
};

export type UpdateMeetingInput = {
  id: string,
  title?: string | null,
  titleLowerCase?: string | null,
  startDateTime?: string | null,
  endDateTime?: string | null,
  status?: MeetingStatus | null,
  description?: string | null,
  preferredRoomProviderId?: string | null,
  actualStartDateTime?: string | null,
  actualEndDateTime?: string | null,
  privacyEnabled?: boolean | null,
  videoEnabled?: boolean | null,
  transcriptionEnabled?: boolean | null,
  productId?: string | null,
  isReadinessTest?: boolean | null,
  labels?: Array< string | null > | null,
  owner?: string | null,
  sellerOrganizationName?: string | null,
  sellerTeamName?: string | null,
  buyerTeamName?: string | null,
  sellerOrganizationNameLowerCase?: string | null,
  sellerTeamNameLowerCase?: string | null,
  buyerTeamNameLowerCase?: string | null,
  externalId?: string | null,
  metrics?: string | null,
  _version?: number | null,
  productMeetingsId?: string | null,
  meetingRoomProviderMeetingsId?: string | null,
  meetingRoomId?: string | null,
};

export type DeleteMeetingInput = {
  id: string,
  _version?: number | null,
};

export type CreateMeetingInviteInput = {
  id?: string | null,
  meetingId: string,
  providerId?: string | null,
  title: string,
  startDateTime: string,
  endDateTime: string,
  status: MeetingStatus,
  description?: string | null,
  privacyEnabled: boolean,
  videoEnabled: boolean,
  transcriptionEnabled: boolean,
  productId?: string | null,
  isReadinessTest: boolean,
  meetingRoomId?: string | null,
  providerSessionId?: string | null,
  providerJoinData?: string | null,
  sessionStatus?: MeetingRoomSessionStatus | null,
  actualStartDateTime?: string | null,
  actualEndDateTime?: string | null,
  profileId: string,
  owner?: string | null,
  meetingInvitationType: MeetingInvitationType,
  teamId?: string | null,
  isInterested?: boolean | null,
  feedbackSummary?: string | null,
  contactRequestTimeframe?: string | null,
  contactPhoneNumber?: string | null,
  contactEmail?: string | null,
  sellerOrganizationName?: string | null,
  sellerTeamName?: string | null,
  buyerTeamName?: string | null,
  _version?: number | null,
  productMeetingInvitesId?: string | null,
  profileMeetingInvitesId?: string | null,
  meetingRoomProviderMeetingInvitesId?: string | null,
};

export type ModelMeetingInviteConditionInput = {
  meetingId?: ModelIDInput | null,
  providerId?: ModelIDInput | null,
  title?: ModelStringInput | null,
  startDateTime?: ModelStringInput | null,
  endDateTime?: ModelStringInput | null,
  status?: ModelMeetingStatusInput | null,
  description?: ModelStringInput | null,
  privacyEnabled?: ModelBooleanInput | null,
  videoEnabled?: ModelBooleanInput | null,
  transcriptionEnabled?: ModelBooleanInput | null,
  productId?: ModelIDInput | null,
  isReadinessTest?: ModelBooleanInput | null,
  meetingRoomId?: ModelIDInput | null,
  providerSessionId?: ModelStringInput | null,
  providerJoinData?: ModelStringInput | null,
  sessionStatus?: ModelMeetingRoomSessionStatusInput | null,
  actualStartDateTime?: ModelStringInput | null,
  actualEndDateTime?: ModelStringInput | null,
  profileId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  meetingInvitationType?: ModelMeetingInvitationTypeInput | null,
  teamId?: ModelIDInput | null,
  isInterested?: ModelBooleanInput | null,
  feedbackSummary?: ModelStringInput | null,
  contactRequestTimeframe?: ModelStringInput | null,
  contactPhoneNumber?: ModelStringInput | null,
  contactEmail?: ModelStringInput | null,
  sellerOrganizationName?: ModelStringInput | null,
  sellerTeamName?: ModelStringInput | null,
  buyerTeamName?: ModelStringInput | null,
  and?: Array< ModelMeetingInviteConditionInput | null > | null,
  or?: Array< ModelMeetingInviteConditionInput | null > | null,
  not?: ModelMeetingInviteConditionInput | null,
  productMeetingInvitesId?: ModelIDInput | null,
  profileMeetingInvitesId?: ModelIDInput | null,
  meetingRoomProviderMeetingInvitesId?: ModelIDInput | null,
};

export type ModelMeetingRoomSessionStatusInput = {
  eq?: MeetingRoomSessionStatus | null,
  ne?: MeetingRoomSessionStatus | null,
};

export type ModelMeetingInvitationTypeInput = {
  eq?: MeetingInvitationType | null,
  ne?: MeetingInvitationType | null,
};

export type UpdateMeetingInviteInput = {
  id: string,
  meetingId?: string | null,
  providerId?: string | null,
  title?: string | null,
  startDateTime?: string | null,
  endDateTime?: string | null,
  status?: MeetingStatus | null,
  description?: string | null,
  privacyEnabled?: boolean | null,
  videoEnabled?: boolean | null,
  transcriptionEnabled?: boolean | null,
  productId?: string | null,
  isReadinessTest?: boolean | null,
  meetingRoomId?: string | null,
  providerSessionId?: string | null,
  providerJoinData?: string | null,
  sessionStatus?: MeetingRoomSessionStatus | null,
  actualStartDateTime?: string | null,
  actualEndDateTime?: string | null,
  profileId?: string | null,
  owner?: string | null,
  meetingInvitationType?: MeetingInvitationType | null,
  teamId?: string | null,
  isInterested?: boolean | null,
  feedbackSummary?: string | null,
  contactRequestTimeframe?: string | null,
  contactPhoneNumber?: string | null,
  contactEmail?: string | null,
  sellerOrganizationName?: string | null,
  sellerTeamName?: string | null,
  buyerTeamName?: string | null,
  _version?: number | null,
  productMeetingInvitesId?: string | null,
  profileMeetingInvitesId?: string | null,
  meetingRoomProviderMeetingInvitesId?: string | null,
};

export type DeleteMeetingInviteInput = {
  id: string,
  _version?: number | null,
};

export type CreateMeetingRoomInput = {
  id?: string | null,
  name: string,
  meetingId: string,
  providerId: string,
  providerMeetingId?: string | null,
  accessCode: string,
  status: MeetingRoomStatus,
  startDateTime?: string | null,
  endDateTime?: string | null,
  actualStartDateTime?: string | null,
  actualEndDateTime?: string | null,
  roomCreatedAt?: string | null,
  roomDestroyedAt?: string | null,
  privacyEnabled: boolean,
  videoEnabled: boolean,
  transcriptionEnabled: boolean,
  isReadinessTest: boolean,
  owner?: string | null,
  _version?: number | null,
  meetingRoomProviderMeetingRoomsId?: string | null,
};

export type ModelMeetingRoomConditionInput = {
  name?: ModelStringInput | null,
  meetingId?: ModelIDInput | null,
  providerId?: ModelIDInput | null,
  providerMeetingId?: ModelStringInput | null,
  accessCode?: ModelStringInput | null,
  status?: ModelMeetingRoomStatusInput | null,
  startDateTime?: ModelStringInput | null,
  endDateTime?: ModelStringInput | null,
  actualStartDateTime?: ModelStringInput | null,
  actualEndDateTime?: ModelStringInput | null,
  roomCreatedAt?: ModelStringInput | null,
  roomDestroyedAt?: ModelStringInput | null,
  privacyEnabled?: ModelBooleanInput | null,
  videoEnabled?: ModelBooleanInput | null,
  transcriptionEnabled?: ModelBooleanInput | null,
  isReadinessTest?: ModelBooleanInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelMeetingRoomConditionInput | null > | null,
  or?: Array< ModelMeetingRoomConditionInput | null > | null,
  not?: ModelMeetingRoomConditionInput | null,
  meetingRoomProviderMeetingRoomsId?: ModelIDInput | null,
};

export type ModelMeetingRoomStatusInput = {
  eq?: MeetingRoomStatus | null,
  ne?: MeetingRoomStatus | null,
};

export type UpdateMeetingRoomInput = {
  id: string,
  name?: string | null,
  meetingId?: string | null,
  providerId?: string | null,
  providerMeetingId?: string | null,
  accessCode?: string | null,
  status?: MeetingRoomStatus | null,
  startDateTime?: string | null,
  endDateTime?: string | null,
  actualStartDateTime?: string | null,
  actualEndDateTime?: string | null,
  roomCreatedAt?: string | null,
  roomDestroyedAt?: string | null,
  privacyEnabled?: boolean | null,
  videoEnabled?: boolean | null,
  transcriptionEnabled?: boolean | null,
  isReadinessTest?: boolean | null,
  owner?: string | null,
  _version?: number | null,
  meetingRoomProviderMeetingRoomsId?: string | null,
};

export type DeleteMeetingRoomInput = {
  id: string,
  _version?: number | null,
};

export type CreateMeetingRoomEventAuditLogInput = {
  id?: string | null,
  date: string,
  author: string,
  resource: string,
  action: string,
  payload?: string | null,
  ipAddress?: string | null,
  meetingRoomId: string,
  profileId?: string | null,
  owner?: string | null,
  _version?: number | null,
  profileAuditLogsId?: string | null,
  meetingRoomAuditlogsId?: string | null,
};

export type ModelMeetingRoomEventAuditLogConditionInput = {
  date?: ModelStringInput | null,
  author?: ModelStringInput | null,
  resource?: ModelStringInput | null,
  action?: ModelStringInput | null,
  payload?: ModelStringInput | null,
  ipAddress?: ModelStringInput | null,
  meetingRoomId?: ModelIDInput | null,
  profileId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelMeetingRoomEventAuditLogConditionInput | null > | null,
  or?: Array< ModelMeetingRoomEventAuditLogConditionInput | null > | null,
  not?: ModelMeetingRoomEventAuditLogConditionInput | null,
  profileAuditLogsId?: ModelIDInput | null,
  meetingRoomAuditlogsId?: ModelIDInput | null,
};

export type UpdateMeetingRoomEventAuditLogInput = {
  id: string,
  date?: string | null,
  author?: string | null,
  resource?: string | null,
  action?: string | null,
  payload?: string | null,
  ipAddress?: string | null,
  meetingRoomId?: string | null,
  profileId?: string | null,
  owner?: string | null,
  _version?: number | null,
  profileAuditLogsId?: string | null,
  meetingRoomAuditlogsId?: string | null,
};

export type DeleteMeetingRoomEventAuditLogInput = {
  id: string,
  _version?: number | null,
};

export type CreateExportJobInput = {
  id?: string | null,
  status: JobStatus,
  profileId?: string | null,
  meetingInvitationType?: MeetingInvitationType | null,
  startDateTime: string,
  endDateTime: string,
  owner: string,
  identityId: string,
  _version?: number | null,
  profileExportJobsId?: string | null,
};

export type ModelExportJobConditionInput = {
  status?: ModelJobStatusInput | null,
  profileId?: ModelIDInput | null,
  meetingInvitationType?: ModelMeetingInvitationTypeInput | null,
  startDateTime?: ModelStringInput | null,
  endDateTime?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  identityId?: ModelStringInput | null,
  and?: Array< ModelExportJobConditionInput | null > | null,
  or?: Array< ModelExportJobConditionInput | null > | null,
  not?: ModelExportJobConditionInput | null,
  profileExportJobsId?: ModelIDInput | null,
};

export type ModelJobStatusInput = {
  eq?: JobStatus | null,
  ne?: JobStatus | null,
};

export type UpdateExportJobInput = {
  id: string,
  status?: JobStatus | null,
  profileId?: string | null,
  meetingInvitationType?: MeetingInvitationType | null,
  startDateTime?: string | null,
  endDateTime?: string | null,
  owner?: string | null,
  identityId?: string | null,
  _version?: number | null,
  profileExportJobsId?: string | null,
};

export type DeleteExportJobInput = {
  id: string,
  _version?: number | null,
};

export type CreateExportJobAuditLogInput = {
  id?: string | null,
  date: string,
  author: string,
  resource: string,
  action: string,
  payload?: string | null,
  ipAddress?: string | null,
  exportJobId: string,
  profileId?: string | null,
  owner: string,
  source?: S3ObjectInput | null,
  identityId?: string | null,
  _version?: number | null,
  profileExportJobAuditLogsId?: string | null,
  exportJobAuditLogsId?: string | null,
};

export type ModelExportJobAuditLogConditionInput = {
  date?: ModelStringInput | null,
  author?: ModelStringInput | null,
  resource?: ModelStringInput | null,
  action?: ModelStringInput | null,
  payload?: ModelStringInput | null,
  ipAddress?: ModelStringInput | null,
  exportJobId?: ModelIDInput | null,
  profileId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  identityId?: ModelStringInput | null,
  and?: Array< ModelExportJobAuditLogConditionInput | null > | null,
  or?: Array< ModelExportJobAuditLogConditionInput | null > | null,
  not?: ModelExportJobAuditLogConditionInput | null,
  profileExportJobAuditLogsId?: ModelIDInput | null,
  exportJobAuditLogsId?: ModelIDInput | null,
};

export type UpdateExportJobAuditLogInput = {
  id: string,
  date?: string | null,
  author?: string | null,
  resource?: string | null,
  action?: string | null,
  payload?: string | null,
  ipAddress?: string | null,
  exportJobId?: string | null,
  profileId?: string | null,
  owner?: string | null,
  source?: S3ObjectInput | null,
  identityId?: string | null,
  _version?: number | null,
  profileExportJobAuditLogsId?: string | null,
  exportJobAuditLogsId?: string | null,
};

export type DeleteExportJobAuditLogInput = {
  id: string,
  _version?: number | null,
};

export type CreateImportJobInput = {
  id?: string | null,
  status: JobStatus,
  source: S3ObjectInput,
  identityId?: string | null,
  result?: string | null,
  profileId?: string | null,
  lastStartDateTime?: string | null,
  lastEndDateTime?: string | null,
  owner?: string | null,
  _version?: number | null,
  profileImportJobsId?: string | null,
};

export type ModelImportJobConditionInput = {
  status?: ModelJobStatusInput | null,
  identityId?: ModelStringInput | null,
  result?: ModelStringInput | null,
  profileId?: ModelIDInput | null,
  lastStartDateTime?: ModelStringInput | null,
  lastEndDateTime?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelImportJobConditionInput | null > | null,
  or?: Array< ModelImportJobConditionInput | null > | null,
  not?: ModelImportJobConditionInput | null,
  profileImportJobsId?: ModelIDInput | null,
};

export type UpdateImportJobInput = {
  id: string,
  status?: JobStatus | null,
  source?: S3ObjectInput | null,
  identityId?: string | null,
  result?: string | null,
  profileId?: string | null,
  lastStartDateTime?: string | null,
  lastEndDateTime?: string | null,
  owner?: string | null,
  _version?: number | null,
  profileImportJobsId?: string | null,
};

export type DeleteImportJobInput = {
  id: string,
  _version?: number | null,
};

export type CreateImportJobAuditLogInput = {
  id?: string | null,
  date: string,
  author: string,
  resource: string,
  action: string,
  payload?: string | null,
  ipAddress?: string | null,
  importJobId: string,
  profileId?: string | null,
  owner?: string | null,
  _version?: number | null,
  profileJobAuditLogsId?: string | null,
  importJobAuditLogsId?: string | null,
};

export type ModelImportJobAuditLogConditionInput = {
  date?: ModelStringInput | null,
  author?: ModelStringInput | null,
  resource?: ModelStringInput | null,
  action?: ModelStringInput | null,
  payload?: ModelStringInput | null,
  ipAddress?: ModelStringInput | null,
  importJobId?: ModelIDInput | null,
  profileId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelImportJobAuditLogConditionInput | null > | null,
  or?: Array< ModelImportJobAuditLogConditionInput | null > | null,
  not?: ModelImportJobAuditLogConditionInput | null,
  profileJobAuditLogsId?: ModelIDInput | null,
  importJobAuditLogsId?: ModelIDInput | null,
};

export type UpdateImportJobAuditLogInput = {
  id: string,
  date?: string | null,
  author?: string | null,
  resource?: string | null,
  action?: string | null,
  payload?: string | null,
  ipAddress?: string | null,
  importJobId?: string | null,
  profileId?: string | null,
  owner?: string | null,
  _version?: number | null,
  profileJobAuditLogsId?: string | null,
  importJobAuditLogsId?: string | null,
};

export type DeleteImportJobAuditLogInput = {
  id: string,
  _version?: number | null,
};

export type CreateNotificationInput = {
  id?: string | null,
  date: string,
  title: string,
  payload: string,
  read: number,
  dateRead?: string | null,
  owner: string,
  _version?: number | null,
};

export type ModelNotificationConditionInput = {
  date?: ModelStringInput | null,
  title?: ModelStringInput | null,
  payload?: ModelStringInput | null,
  read?: ModelIntInput | null,
  dateRead?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelNotificationConditionInput | null > | null,
  or?: Array< ModelNotificationConditionInput | null > | null,
  not?: ModelNotificationConditionInput | null,
};

export type Notification = {
  __typename: "Notification",
  id: string,
  date: string,
  title: string,
  payload: string,
  read: number,
  dateRead?: string | null,
  owner: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateNotificationInput = {
  id: string,
  date?: string | null,
  title?: string | null,
  payload?: string | null,
  read?: number | null,
  dateRead?: string | null,
  owner?: string | null,
  _version?: number | null,
};

export type DeleteNotificationInput = {
  id: string,
  _version?: number | null,
};

export type CreateTranscriptionInput = {
  id?: string | null,
  date: string,
  title: string,
  description?: string | null,
  startDateTime: string,
  endDateTime: string,
  result: S3ObjectInput,
  sizeInBytes?: number | null,
  numberOfTranscriptions?: number | null,
  isZipFile: boolean,
  meetingId?: string | null,
  teamId?: string | null,
  _version?: number | null,
  teamTranscriptionsId?: string | null,
  meetingTranscriptionsId?: string | null,
};

export type ModelTranscriptionConditionInput = {
  date?: ModelStringInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  startDateTime?: ModelStringInput | null,
  endDateTime?: ModelStringInput | null,
  sizeInBytes?: ModelIntInput | null,
  numberOfTranscriptions?: ModelIntInput | null,
  isZipFile?: ModelBooleanInput | null,
  meetingId?: ModelIDInput | null,
  teamId?: ModelIDInput | null,
  and?: Array< ModelTranscriptionConditionInput | null > | null,
  or?: Array< ModelTranscriptionConditionInput | null > | null,
  not?: ModelTranscriptionConditionInput | null,
  teamTranscriptionsId?: ModelIDInput | null,
  meetingTranscriptionsId?: ModelIDInput | null,
};

export type UpdateTranscriptionInput = {
  id: string,
  date?: string | null,
  title?: string | null,
  description?: string | null,
  startDateTime?: string | null,
  endDateTime?: string | null,
  result?: S3ObjectInput | null,
  sizeInBytes?: number | null,
  numberOfTranscriptions?: number | null,
  isZipFile?: boolean | null,
  meetingId?: string | null,
  teamId?: string | null,
  _version?: number | null,
  teamTranscriptionsId?: string | null,
  meetingTranscriptionsId?: string | null,
};

export type DeleteTranscriptionInput = {
  id: string,
  _version?: number | null,
};

export type CreateTranscriptionJobInput = {
  id?: string | null,
  date: string,
  status: JobStatus,
  result?: S3ObjectInput | null,
  meetingInviteId: string,
  owner: string,
  identityId: string,
  _version?: number | null,
};

export type ModelTranscriptionJobConditionInput = {
  date?: ModelStringInput | null,
  status?: ModelJobStatusInput | null,
  meetingInviteId?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  identityId?: ModelStringInput | null,
  and?: Array< ModelTranscriptionJobConditionInput | null > | null,
  or?: Array< ModelTranscriptionJobConditionInput | null > | null,
  not?: ModelTranscriptionJobConditionInput | null,
};

export type UpdateTranscriptionJobInput = {
  id: string,
  date?: string | null,
  status?: JobStatus | null,
  result?: S3ObjectInput | null,
  meetingInviteId?: string | null,
  owner?: string | null,
  identityId?: string | null,
  _version?: number | null,
};

export type DeleteTranscriptionJobInput = {
  id: string,
  _version?: number | null,
};

export type CreateTranscriptionJobAuditLogInput = {
  id?: string | null,
  date: string,
  author: string,
  resource: string,
  action: string,
  payload?: string | null,
  ipAddress?: string | null,
  transcriptionJobId: string,
  owner?: string | null,
  _version?: number | null,
  transcriptionJobAuditLogsId?: string | null,
};

export type ModelTranscriptionJobAuditLogConditionInput = {
  date?: ModelStringInput | null,
  author?: ModelStringInput | null,
  resource?: ModelStringInput | null,
  action?: ModelStringInput | null,
  payload?: ModelStringInput | null,
  ipAddress?: ModelStringInput | null,
  transcriptionJobId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelTranscriptionJobAuditLogConditionInput | null > | null,
  or?: Array< ModelTranscriptionJobAuditLogConditionInput | null > | null,
  not?: ModelTranscriptionJobAuditLogConditionInput | null,
  transcriptionJobAuditLogsId?: ModelIDInput | null,
};

export type UpdateTranscriptionJobAuditLogInput = {
  id: string,
  date?: string | null,
  author?: string | null,
  resource?: string | null,
  action?: string | null,
  payload?: string | null,
  ipAddress?: string | null,
  transcriptionJobId?: string | null,
  owner?: string | null,
  _version?: number | null,
  transcriptionJobAuditLogsId?: string | null,
};

export type DeleteTranscriptionJobAuditLogInput = {
  id: string,
  _version?: number | null,
};

export type CreateTranscriptionAdminJobInput = {
  id?: string | null,
  date: string,
  status: JobStatus,
  result?: S3ObjectInput | null,
  meetingId: string,
  meetingInviteId?: string | null,
  owner: string,
  identityId: string,
  _version?: number | null,
};

export type ModelTranscriptionAdminJobConditionInput = {
  date?: ModelStringInput | null,
  status?: ModelJobStatusInput | null,
  meetingId?: ModelStringInput | null,
  meetingInviteId?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  identityId?: ModelStringInput | null,
  and?: Array< ModelTranscriptionAdminJobConditionInput | null > | null,
  or?: Array< ModelTranscriptionAdminJobConditionInput | null > | null,
  not?: ModelTranscriptionAdminJobConditionInput | null,
};

export type UpdateTranscriptionAdminJobInput = {
  id: string,
  date?: string | null,
  status?: JobStatus | null,
  result?: S3ObjectInput | null,
  meetingId?: string | null,
  meetingInviteId?: string | null,
  owner?: string | null,
  identityId?: string | null,
  _version?: number | null,
};

export type DeleteTranscriptionAdminJobInput = {
  id: string,
  _version?: number | null,
};

export type CreateTranscriptionAdminJobAuditLogInput = {
  id?: string | null,
  date: string,
  author: string,
  resource: string,
  action: string,
  payload?: string | null,
  ipAddress?: string | null,
  transcriptionAdminJobId: string,
  owner?: string | null,
  _version?: number | null,
  transcriptionAdminJobAuditLogsId?: string | null,
};

export type ModelTranscriptionAdminJobAuditLogConditionInput = {
  date?: ModelStringInput | null,
  author?: ModelStringInput | null,
  resource?: ModelStringInput | null,
  action?: ModelStringInput | null,
  payload?: ModelStringInput | null,
  ipAddress?: ModelStringInput | null,
  transcriptionAdminJobId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelTranscriptionAdminJobAuditLogConditionInput | null > | null,
  or?: Array< ModelTranscriptionAdminJobAuditLogConditionInput | null > | null,
  not?: ModelTranscriptionAdminJobAuditLogConditionInput | null,
  transcriptionAdminJobAuditLogsId?: ModelIDInput | null,
};

export type UpdateTranscriptionAdminJobAuditLogInput = {
  id: string,
  date?: string | null,
  author?: string | null,
  resource?: string | null,
  action?: string | null,
  payload?: string | null,
  ipAddress?: string | null,
  transcriptionAdminJobId?: string | null,
  owner?: string | null,
  _version?: number | null,
  transcriptionAdminJobAuditLogsId?: string | null,
};

export type DeleteTranscriptionAdminJobAuditLogInput = {
  id: string,
  _version?: number | null,
};

export type CreateTranscriptionEventAuditLogInput = {
  id?: string | null,
  date: string,
  author: string,
  resource: string,
  action: string,
  payload?: string | null,
  ipAddress?: string | null,
  meetingInviteId: string,
  profileId: string,
  authorProfileId?: string | null,
  owner?: string | null,
  _version?: number | null,
  profileTranscriptionLogsId?: string | null,
  meetingInviteTranscriptionLogsId?: string | null,
};

export type ModelTranscriptionEventAuditLogConditionInput = {
  date?: ModelStringInput | null,
  author?: ModelStringInput | null,
  resource?: ModelStringInput | null,
  action?: ModelStringInput | null,
  payload?: ModelStringInput | null,
  ipAddress?: ModelStringInput | null,
  meetingInviteId?: ModelIDInput | null,
  profileId?: ModelIDInput | null,
  authorProfileId?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelTranscriptionEventAuditLogConditionInput | null > | null,
  or?: Array< ModelTranscriptionEventAuditLogConditionInput | null > | null,
  not?: ModelTranscriptionEventAuditLogConditionInput | null,
  profileTranscriptionLogsId?: ModelIDInput | null,
  meetingInviteTranscriptionLogsId?: ModelIDInput | null,
};

export type UpdateTranscriptionEventAuditLogInput = {
  id: string,
  date?: string | null,
  author?: string | null,
  resource?: string | null,
  action?: string | null,
  payload?: string | null,
  ipAddress?: string | null,
  meetingInviteId?: string | null,
  profileId?: string | null,
  authorProfileId?: string | null,
  owner?: string | null,
  _version?: number | null,
  profileTranscriptionLogsId?: string | null,
  meetingInviteTranscriptionLogsId?: string | null,
};

export type DeleteTranscriptionEventAuditLogInput = {
  id: string,
  _version?: number | null,
};

export type ModelProductQuestionFilterInput = {
  id?: ModelIDInput | null,
  question?: ModelStringInput | null,
  productId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelProductQuestionFilterInput | null > | null,
  or?: Array< ModelProductQuestionFilterInput | null > | null,
  not?: ModelProductQuestionFilterInput | null,
  productQuestionsId?: ModelIDInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelQuestionResponseFilterInput = {
  id?: ModelIDInput | null,
  response?: ModelStringInput | null,
  privateNotes?: ModelStringInput | null,
  questionId?: ModelIDInput | null,
  profileId?: ModelIDInput | null,
  meetingId?: ModelIDInput | null,
  meetingInviteId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  contactRequestTimeframe?: ModelStringInput | null,
  contactRequestDateTime?: ModelStringInput | null,
  contactPhoneNumber?: ModelStringInput | null,
  contactEmail?: ModelStringInput | null,
  contactName?: ModelStringInput | null,
  providePersonalEmail?: ModelBooleanInput | null,
  providePersonalPhoneNumber?: ModelBooleanInput | null,
  textOnly?: ModelBooleanInput | null,
  followupMeetingRequested?: ModelBooleanInput | null,
  and?: Array< ModelQuestionResponseFilterInput | null > | null,
  or?: Array< ModelQuestionResponseFilterInput | null > | null,
  not?: ModelQuestionResponseFilterInput | null,
  productQuestionResponsesId?: ModelIDInput | null,
  profileQuestionResponsesId?: ModelIDInput | null,
  meetingQuestionResponsesId?: ModelIDInput | null,
  meetingInviteQuestionResponsesId?: ModelIDInput | null,
};

export type ModelIDKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelProductImageFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  alt?: ModelStringInput | null,
  width?: ModelIntInput | null,
  height?: ModelIntInput | null,
  productId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelProductImageFilterInput | null > | null,
  or?: Array< ModelProductImageFilterInput | null > | null,
  not?: ModelProductImageFilterInput | null,
  productImagesId?: ModelIDInput | null,
};

export type ModelProductFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  nameLowerCase?: ModelStringInput | null,
  description?: ModelStringInput | null,
  serviceInformation?: ModelStringInput | null,
  organizationId?: ModelIDInput | null,
  salesTeamId?: ModelIDInput | null,
  labels?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  sortByName?: ModelIntInput | null,
  and?: Array< ModelProductFilterInput | null > | null,
  or?: Array< ModelProductFilterInput | null > | null,
  not?: ModelProductFilterInput | null,
  organizationProductsId?: ModelIDInput | null,
  teamProductsId?: ModelIDInput | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelOrganizationLocationFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  address?: ModelStringInput | null,
  city?: ModelStringInput | null,
  state?: ModelStringInput | null,
  country?: ModelStringInput | null,
  organizationId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelOrganizationLocationFilterInput | null > | null,
  or?: Array< ModelOrganizationLocationFilterInput | null > | null,
  not?: ModelOrganizationLocationFilterInput | null,
  organizationLocationsId?: ModelIDInput | null,
};

export type ModelOrganizationFilterInput = {
  id?: ModelIDInput | null,
  externalId?: ModelStringInput | null,
  name?: ModelStringInput | null,
  nameLowerCase?: ModelStringInput | null,
  description?: ModelStringInput | null,
  url?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  labels?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  sortByName?: ModelIntInput | null,
  and?: Array< ModelOrganizationFilterInput | null > | null,
  or?: Array< ModelOrganizationFilterInput | null > | null,
  not?: ModelOrganizationFilterInput | null,
};

export type ModelOrganizationConnection = {
  __typename: "ModelOrganizationConnection",
  items:  Array<Organization | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelProfileLocationFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  address?: ModelStringInput | null,
  city?: ModelStringInput | null,
  state?: ModelStringInput | null,
  country?: ModelStringInput | null,
  profileId?: ModelIDInput | null,
  isPrimary?: ModelBooleanInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelProfileLocationFilterInput | null > | null,
  or?: Array< ModelProfileLocationFilterInput | null > | null,
  not?: ModelProfileLocationFilterInput | null,
  profileLocationsId?: ModelIDInput | null,
};

export type ModelProfileImageFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  alt?: ModelStringInput | null,
  width?: ModelIntInput | null,
  height?: ModelIntInput | null,
  profileId?: ModelIDInput | null,
  isDefault?: ModelBooleanInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelProfileImageFilterInput | null > | null,
  or?: Array< ModelProfileImageFilterInput | null > | null,
  not?: ModelProfileImageFilterInput | null,
  profileImagesId?: ModelIDInput | null,
};

export type ModelForwardEmailFilterInput = {
  id?: ModelIDInput | null,
  received?: ModelIntInput | null,
  remaining?: ModelIntInput | null,
  publicEmail?: ModelStringInput | null,
  expireAt?: ModelStringInput | null,
  privateEmail?: ModelStringInput | null,
  publicReplyEmail?: ModelStringInput | null,
  profileId?: ModelIDInput | null,
  sellerProfileId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelForwardEmailFilterInput | null > | null,
  or?: Array< ModelForwardEmailFilterInput | null > | null,
  not?: ModelForwardEmailFilterInput | null,
  profileForwardEmailsId?: ModelIDInput | null,
  profileSellerForwardEmailsId?: ModelIDInput | null,
};

export type ModelIntKeyConditionInput = {
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelProfileFilterInput = {
  id?: ModelIDInput | null,
  fullName?: ModelStringInput | null,
  fullNameLowerCase?: ModelStringInput | null,
  email?: ModelStringInput | null,
  externalId?: ModelIDInput | null,
  userId?: ModelStringInput | null,
  occupation?: ModelStringInput | null,
  profile?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  organizationId?: ModelIDInput | null,
  privacyEnabled?: ModelBooleanInput | null,
  owner?: ModelStringInput | null,
  sortByFullName?: ModelIntInput | null,
  timeZone?: ModelStringInput | null,
  and?: Array< ModelProfileFilterInput | null > | null,
  or?: Array< ModelProfileFilterInput | null > | null,
  not?: ModelProfileFilterInput | null,
  organizationProfilesId?: ModelIDInput | null,
};

export type ModelProfileDeviceFilterInput = {
  id?: ModelIDInput | null,
  deviceHash?: ModelStringInput | null,
  hasWebcam?: ModelBooleanInput | null,
  hasMicrophone?: ModelBooleanInput | null,
  isScreenCapturingSupported?: ModelBooleanInput | null,
  isWebRTCSupported?: ModelBooleanInput | null,
  isWebsiteHasMicrophonePermissions?: ModelBooleanInput | null,
  isWebsiteHasWebcamPermissions?: ModelBooleanInput | null,
  isWebSocketsSupported?: ModelBooleanInput | null,
  clockOffset?: ModelIntInput | null,
  lastSeen?: ModelStringInput | null,
  lastLogin?: ModelStringInput | null,
  lastMeetingId?: ModelStringInput | null,
  lastMeetingInviteId?: ModelStringInput | null,
  lastMeetingJoin?: ModelStringInput | null,
  lastMeetingStatus?: ModelStringInput | null,
  meetingData?: ModelStringInput | null,
  userAgent?: ModelStringInput | null,
  userAgentSummary?: ModelStringInput | null,
  micStatus?: ModelStringInput | null,
  cameraStatus?: ModelStringInput | null,
  networkQuality?: ModelStringInput | null,
  profileId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelProfileDeviceFilterInput | null > | null,
  or?: Array< ModelProfileDeviceFilterInput | null > | null,
  not?: ModelProfileDeviceFilterInput | null,
  profileDevicesId?: ModelIDInput | null,
};

export type ModelTeamImageFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  alt?: ModelStringInput | null,
  width?: ModelIntInput | null,
  height?: ModelIntInput | null,
  teamId?: ModelIDInput | null,
  isDefault?: ModelBooleanInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelTeamImageFilterInput | null > | null,
  or?: Array< ModelTeamImageFilterInput | null > | null,
  not?: ModelTeamImageFilterInput | null,
  teamImagesId?: ModelIDInput | null,
};

export type ModelTeamFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  nameLowerCase?: ModelStringInput | null,
  description?: ModelStringInput | null,
  labels?: ModelStringInput | null,
  privacyEnabled?: ModelBooleanInput | null,
  privacyEnableOtherTeams?: ModelBooleanInput | null,
  owner?: ModelStringInput | null,
  sortByName?: ModelIntInput | null,
  and?: Array< ModelTeamFilterInput | null > | null,
  or?: Array< ModelTeamFilterInput | null > | null,
  not?: ModelTeamFilterInput | null,
};

export type ModelTeamConnection = {
  __typename: "ModelTeamConnection",
  items:  Array<Team | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelTeamMemberRelationshipFilterInput = {
  id?: ModelIDInput | null,
  teamId?: ModelIDInput | null,
  memberProfileId?: ModelIDInput | null,
  relationship?: ModelTeamRelationshipInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelTeamMemberRelationshipFilterInput | null > | null,
  or?: Array< ModelTeamMemberRelationshipFilterInput | null > | null,
  not?: ModelTeamMemberRelationshipFilterInput | null,
  profileTeamRelationshipsId?: ModelIDInput | null,
  teamMemberRelationshipsId?: ModelIDInput | null,
};

export type ModelMeetingRoomProviderFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  componentName?: ModelStringInput | null,
  description?: ModelStringInput | null,
  configuration?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelMeetingRoomProviderFilterInput | null > | null,
  or?: Array< ModelMeetingRoomProviderFilterInput | null > | null,
  not?: ModelMeetingRoomProviderFilterInput | null,
};

export type ModelMeetingRoomProviderConnection = {
  __typename: "ModelMeetingRoomProviderConnection",
  items:  Array<MeetingRoomProvider | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelTeamMeetingRelationshipFilterInput = {
  id?: ModelIDInput | null,
  teamId?: ModelIDInput | null,
  meetingId?: ModelIDInput | null,
  relationshipType?: ModelTeamMeetingRelationshipTypeInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelTeamMeetingRelationshipFilterInput | null > | null,
  or?: Array< ModelTeamMeetingRelationshipFilterInput | null > | null,
  not?: ModelTeamMeetingRelationshipFilterInput | null,
  teamMeetingRelationshipsId?: ModelIDInput | null,
  meetingTeamRelationshipsId?: ModelIDInput | null,
};

export type ModelMeetingAttendeeFilterInput = {
  id?: ModelIDInput | null,
  profileId?: ModelIDInput | null,
  meetingId?: ModelIDInput | null,
  and?: Array< ModelMeetingAttendeeFilterInput | null > | null,
  or?: Array< ModelMeetingAttendeeFilterInput | null > | null,
  not?: ModelMeetingAttendeeFilterInput | null,
  profileMeetingAttendeesId?: ModelIDInput | null,
  meetingMeetingAttendeesId?: ModelIDInput | null,
};

export type ModelMeetingFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  titleLowerCase?: ModelStringInput | null,
  startDateTime?: ModelStringInput | null,
  endDateTime?: ModelStringInput | null,
  status?: ModelMeetingStatusInput | null,
  description?: ModelStringInput | null,
  preferredRoomProviderId?: ModelIDInput | null,
  actualStartDateTime?: ModelStringInput | null,
  actualEndDateTime?: ModelStringInput | null,
  privacyEnabled?: ModelBooleanInput | null,
  videoEnabled?: ModelBooleanInput | null,
  transcriptionEnabled?: ModelBooleanInput | null,
  productId?: ModelIDInput | null,
  isReadinessTest?: ModelBooleanInput | null,
  labels?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  sellerOrganizationName?: ModelStringInput | null,
  sellerTeamName?: ModelStringInput | null,
  buyerTeamName?: ModelStringInput | null,
  sellerOrganizationNameLowerCase?: ModelStringInput | null,
  sellerTeamNameLowerCase?: ModelStringInput | null,
  buyerTeamNameLowerCase?: ModelStringInput | null,
  externalId?: ModelStringInput | null,
  metrics?: ModelStringInput | null,
  and?: Array< ModelMeetingFilterInput | null > | null,
  or?: Array< ModelMeetingFilterInput | null > | null,
  not?: ModelMeetingFilterInput | null,
  productMeetingsId?: ModelIDInput | null,
  meetingRoomProviderMeetingsId?: ModelIDInput | null,
  meetingRoomId?: ModelIDInput | null,
};

export type ModelMeetingInviteFilterInput = {
  id?: ModelIDInput | null,
  meetingId?: ModelIDInput | null,
  providerId?: ModelIDInput | null,
  title?: ModelStringInput | null,
  startDateTime?: ModelStringInput | null,
  endDateTime?: ModelStringInput | null,
  status?: ModelMeetingStatusInput | null,
  description?: ModelStringInput | null,
  privacyEnabled?: ModelBooleanInput | null,
  videoEnabled?: ModelBooleanInput | null,
  transcriptionEnabled?: ModelBooleanInput | null,
  productId?: ModelIDInput | null,
  isReadinessTest?: ModelBooleanInput | null,
  meetingRoomId?: ModelIDInput | null,
  providerSessionId?: ModelStringInput | null,
  providerJoinData?: ModelStringInput | null,
  sessionStatus?: ModelMeetingRoomSessionStatusInput | null,
  actualStartDateTime?: ModelStringInput | null,
  actualEndDateTime?: ModelStringInput | null,
  profileId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  meetingInvitationType?: ModelMeetingInvitationTypeInput | null,
  teamId?: ModelIDInput | null,
  isInterested?: ModelBooleanInput | null,
  feedbackSummary?: ModelStringInput | null,
  contactRequestTimeframe?: ModelStringInput | null,
  contactPhoneNumber?: ModelStringInput | null,
  contactEmail?: ModelStringInput | null,
  sellerOrganizationName?: ModelStringInput | null,
  sellerTeamName?: ModelStringInput | null,
  buyerTeamName?: ModelStringInput | null,
  and?: Array< ModelMeetingInviteFilterInput | null > | null,
  or?: Array< ModelMeetingInviteFilterInput | null > | null,
  not?: ModelMeetingInviteFilterInput | null,
  productMeetingInvitesId?: ModelIDInput | null,
  profileMeetingInvitesId?: ModelIDInput | null,
  meetingRoomProviderMeetingInvitesId?: ModelIDInput | null,
};

export type ModelMeetingInviteMeetingInvitesByProfileIdAndDatesCompositeKeyConditionInput = {
  eq?: ModelMeetingInviteMeetingInvitesByProfileIdAndDatesCompositeKeyInput | null,
  le?: ModelMeetingInviteMeetingInvitesByProfileIdAndDatesCompositeKeyInput | null,
  lt?: ModelMeetingInviteMeetingInvitesByProfileIdAndDatesCompositeKeyInput | null,
  ge?: ModelMeetingInviteMeetingInvitesByProfileIdAndDatesCompositeKeyInput | null,
  gt?: ModelMeetingInviteMeetingInvitesByProfileIdAndDatesCompositeKeyInput | null,
  between?: Array< ModelMeetingInviteMeetingInvitesByProfileIdAndDatesCompositeKeyInput | null > | null,
  beginsWith?: ModelMeetingInviteMeetingInvitesByProfileIdAndDatesCompositeKeyInput | null,
};

export type ModelMeetingInviteMeetingInvitesByProfileIdAndDatesCompositeKeyInput = {
  startDateTime?: string | null,
  endDateTime?: string | null,
};

export type ModelMeetingRoomFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  meetingId?: ModelIDInput | null,
  providerId?: ModelIDInput | null,
  providerMeetingId?: ModelStringInput | null,
  accessCode?: ModelStringInput | null,
  status?: ModelMeetingRoomStatusInput | null,
  startDateTime?: ModelStringInput | null,
  endDateTime?: ModelStringInput | null,
  actualStartDateTime?: ModelStringInput | null,
  actualEndDateTime?: ModelStringInput | null,
  roomCreatedAt?: ModelStringInput | null,
  roomDestroyedAt?: ModelStringInput | null,
  privacyEnabled?: ModelBooleanInput | null,
  videoEnabled?: ModelBooleanInput | null,
  transcriptionEnabled?: ModelBooleanInput | null,
  isReadinessTest?: ModelBooleanInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelMeetingRoomFilterInput | null > | null,
  or?: Array< ModelMeetingRoomFilterInput | null > | null,
  not?: ModelMeetingRoomFilterInput | null,
  meetingRoomProviderMeetingRoomsId?: ModelIDInput | null,
};

export type ModelMeetingRoomEventAuditLogFilterInput = {
  id?: ModelIDInput | null,
  date?: ModelStringInput | null,
  author?: ModelStringInput | null,
  resource?: ModelStringInput | null,
  action?: ModelStringInput | null,
  payload?: ModelStringInput | null,
  ipAddress?: ModelStringInput | null,
  meetingRoomId?: ModelIDInput | null,
  profileId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelMeetingRoomEventAuditLogFilterInput | null > | null,
  or?: Array< ModelMeetingRoomEventAuditLogFilterInput | null > | null,
  not?: ModelMeetingRoomEventAuditLogFilterInput | null,
  profileAuditLogsId?: ModelIDInput | null,
  meetingRoomAuditlogsId?: ModelIDInput | null,
};

export type ModelExportJobFilterInput = {
  id?: ModelIDInput | null,
  status?: ModelJobStatusInput | null,
  profileId?: ModelIDInput | null,
  meetingInvitationType?: ModelMeetingInvitationTypeInput | null,
  startDateTime?: ModelStringInput | null,
  endDateTime?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  identityId?: ModelStringInput | null,
  and?: Array< ModelExportJobFilterInput | null > | null,
  or?: Array< ModelExportJobFilterInput | null > | null,
  not?: ModelExportJobFilterInput | null,
  profileExportJobsId?: ModelIDInput | null,
};

export type ModelExportJobAuditLogFilterInput = {
  id?: ModelIDInput | null,
  date?: ModelStringInput | null,
  author?: ModelStringInput | null,
  resource?: ModelStringInput | null,
  action?: ModelStringInput | null,
  payload?: ModelStringInput | null,
  ipAddress?: ModelStringInput | null,
  exportJobId?: ModelIDInput | null,
  profileId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  identityId?: ModelStringInput | null,
  and?: Array< ModelExportJobAuditLogFilterInput | null > | null,
  or?: Array< ModelExportJobAuditLogFilterInput | null > | null,
  not?: ModelExportJobAuditLogFilterInput | null,
  profileExportJobAuditLogsId?: ModelIDInput | null,
  exportJobAuditLogsId?: ModelIDInput | null,
};

export type ModelImportJobFilterInput = {
  id?: ModelIDInput | null,
  status?: ModelJobStatusInput | null,
  identityId?: ModelStringInput | null,
  result?: ModelStringInput | null,
  profileId?: ModelIDInput | null,
  lastStartDateTime?: ModelStringInput | null,
  lastEndDateTime?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelImportJobFilterInput | null > | null,
  or?: Array< ModelImportJobFilterInput | null > | null,
  not?: ModelImportJobFilterInput | null,
  profileImportJobsId?: ModelIDInput | null,
};

export type ModelImportJobAuditLogFilterInput = {
  id?: ModelIDInput | null,
  date?: ModelStringInput | null,
  author?: ModelStringInput | null,
  resource?: ModelStringInput | null,
  action?: ModelStringInput | null,
  payload?: ModelStringInput | null,
  ipAddress?: ModelStringInput | null,
  importJobId?: ModelIDInput | null,
  profileId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelImportJobAuditLogFilterInput | null > | null,
  or?: Array< ModelImportJobAuditLogFilterInput | null > | null,
  not?: ModelImportJobAuditLogFilterInput | null,
  profileJobAuditLogsId?: ModelIDInput | null,
  importJobAuditLogsId?: ModelIDInput | null,
};

export type ModelNotificationFilterInput = {
  id?: ModelIDInput | null,
  date?: ModelStringInput | null,
  title?: ModelStringInput | null,
  payload?: ModelStringInput | null,
  read?: ModelIntInput | null,
  dateRead?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelNotificationFilterInput | null > | null,
  or?: Array< ModelNotificationFilterInput | null > | null,
  not?: ModelNotificationFilterInput | null,
};

export type ModelNotificationConnection = {
  __typename: "ModelNotificationConnection",
  items:  Array<Notification | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelTranscriptionFilterInput = {
  id?: ModelIDInput | null,
  date?: ModelStringInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  startDateTime?: ModelStringInput | null,
  endDateTime?: ModelStringInput | null,
  sizeInBytes?: ModelIntInput | null,
  numberOfTranscriptions?: ModelIntInput | null,
  isZipFile?: ModelBooleanInput | null,
  meetingId?: ModelIDInput | null,
  teamId?: ModelIDInput | null,
  and?: Array< ModelTranscriptionFilterInput | null > | null,
  or?: Array< ModelTranscriptionFilterInput | null > | null,
  not?: ModelTranscriptionFilterInput | null,
  teamTranscriptionsId?: ModelIDInput | null,
  meetingTranscriptionsId?: ModelIDInput | null,
};

export type ModelTranscriptionJobFilterInput = {
  id?: ModelIDInput | null,
  date?: ModelStringInput | null,
  status?: ModelJobStatusInput | null,
  meetingInviteId?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  identityId?: ModelStringInput | null,
  and?: Array< ModelTranscriptionJobFilterInput | null > | null,
  or?: Array< ModelTranscriptionJobFilterInput | null > | null,
  not?: ModelTranscriptionJobFilterInput | null,
};

export type ModelTranscriptionJobConnection = {
  __typename: "ModelTranscriptionJobConnection",
  items:  Array<TranscriptionJob | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelTranscriptionJobAuditLogFilterInput = {
  id?: ModelIDInput | null,
  date?: ModelStringInput | null,
  author?: ModelStringInput | null,
  resource?: ModelStringInput | null,
  action?: ModelStringInput | null,
  payload?: ModelStringInput | null,
  ipAddress?: ModelStringInput | null,
  transcriptionJobId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelTranscriptionJobAuditLogFilterInput | null > | null,
  or?: Array< ModelTranscriptionJobAuditLogFilterInput | null > | null,
  not?: ModelTranscriptionJobAuditLogFilterInput | null,
  transcriptionJobAuditLogsId?: ModelIDInput | null,
};

export type ModelTranscriptionAdminJobFilterInput = {
  id?: ModelIDInput | null,
  date?: ModelStringInput | null,
  status?: ModelJobStatusInput | null,
  meetingId?: ModelStringInput | null,
  meetingInviteId?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  identityId?: ModelStringInput | null,
  and?: Array< ModelTranscriptionAdminJobFilterInput | null > | null,
  or?: Array< ModelTranscriptionAdminJobFilterInput | null > | null,
  not?: ModelTranscriptionAdminJobFilterInput | null,
};

export type ModelTranscriptionAdminJobConnection = {
  __typename: "ModelTranscriptionAdminJobConnection",
  items:  Array<TranscriptionAdminJob | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelTranscriptionAdminJobAuditLogFilterInput = {
  id?: ModelIDInput | null,
  date?: ModelStringInput | null,
  author?: ModelStringInput | null,
  resource?: ModelStringInput | null,
  action?: ModelStringInput | null,
  payload?: ModelStringInput | null,
  ipAddress?: ModelStringInput | null,
  transcriptionAdminJobId?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelTranscriptionAdminJobAuditLogFilterInput | null > | null,
  or?: Array< ModelTranscriptionAdminJobAuditLogFilterInput | null > | null,
  not?: ModelTranscriptionAdminJobAuditLogFilterInput | null,
  transcriptionAdminJobAuditLogsId?: ModelIDInput | null,
};

export type ModelTranscriptionEventAuditLogFilterInput = {
  id?: ModelIDInput | null,
  date?: ModelStringInput | null,
  author?: ModelStringInput | null,
  resource?: ModelStringInput | null,
  action?: ModelStringInput | null,
  payload?: ModelStringInput | null,
  ipAddress?: ModelStringInput | null,
  meetingInviteId?: ModelIDInput | null,
  profileId?: ModelIDInput | null,
  authorProfileId?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelTranscriptionEventAuditLogFilterInput | null > | null,
  or?: Array< ModelTranscriptionEventAuditLogFilterInput | null > | null,
  not?: ModelTranscriptionEventAuditLogFilterInput | null,
  profileTranscriptionLogsId?: ModelIDInput | null,
  meetingInviteTranscriptionLogsId?: ModelIDInput | null,
};

export type CreateProductQuestionMutationVariables = {
  input: CreateProductQuestionInput,
  condition?: ModelProductQuestionConditionInput | null,
};

export type CreateProductQuestionMutation = {
  createProductQuestion?:  {
    __typename: "ProductQuestion",
    id: string,
    question: string,
    product:  {
      __typename: "Product",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      serviceInformation?: string | null,
      images?:  {
        __typename: "ModelProductImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questions?:  {
        __typename: "ModelProductQuestionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      salesTeam?:  {
        __typename: "Team",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        labels?: Array< string | null > | null,
        privacyEnabled: boolean,
        privacyEnableOtherTeams?: boolean | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      salesTeamId?: string | null,
      meetings?:  {
        __typename: "ModelMeetingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProductsId?: string | null,
      teamProductsId?: string | null,
    },
    productId: string,
    responses?:  {
      __typename: "ModelQuestionResponseConnection",
      items:  Array< {
        __typename: "QuestionResponse",
        id: string,
        response?: string | null,
        privateNotes?: string | null,
        questionId: string,
        profileId: string,
        meetingId: string,
        meetingInviteId: string,
        owner?: string | null,
        contactRequestTimeframe?: string | null,
        contactRequestDateTime?: string | null,
        contactPhoneNumber?: string | null,
        contactEmail?: string | null,
        contactName?: string | null,
        providePersonalEmail?: boolean | null,
        providePersonalPhoneNumber?: boolean | null,
        textOnly?: boolean | null,
        followupMeetingRequested?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productQuestionResponsesId?: string | null,
        profileQuestionResponsesId?: string | null,
        meetingQuestionResponsesId?: string | null,
        meetingInviteQuestionResponsesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    productQuestionsId?: string | null,
  } | null,
};

export type UpdateProductQuestionMutationVariables = {
  input: UpdateProductQuestionInput,
  condition?: ModelProductQuestionConditionInput | null,
};

export type UpdateProductQuestionMutation = {
  updateProductQuestion?:  {
    __typename: "ProductQuestion",
    id: string,
    question: string,
    product:  {
      __typename: "Product",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      serviceInformation?: string | null,
      images?:  {
        __typename: "ModelProductImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questions?:  {
        __typename: "ModelProductQuestionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      salesTeam?:  {
        __typename: "Team",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        labels?: Array< string | null > | null,
        privacyEnabled: boolean,
        privacyEnableOtherTeams?: boolean | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      salesTeamId?: string | null,
      meetings?:  {
        __typename: "ModelMeetingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProductsId?: string | null,
      teamProductsId?: string | null,
    },
    productId: string,
    responses?:  {
      __typename: "ModelQuestionResponseConnection",
      items:  Array< {
        __typename: "QuestionResponse",
        id: string,
        response?: string | null,
        privateNotes?: string | null,
        questionId: string,
        profileId: string,
        meetingId: string,
        meetingInviteId: string,
        owner?: string | null,
        contactRequestTimeframe?: string | null,
        contactRequestDateTime?: string | null,
        contactPhoneNumber?: string | null,
        contactEmail?: string | null,
        contactName?: string | null,
        providePersonalEmail?: boolean | null,
        providePersonalPhoneNumber?: boolean | null,
        textOnly?: boolean | null,
        followupMeetingRequested?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productQuestionResponsesId?: string | null,
        profileQuestionResponsesId?: string | null,
        meetingQuestionResponsesId?: string | null,
        meetingInviteQuestionResponsesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    productQuestionsId?: string | null,
  } | null,
};

export type DeleteProductQuestionMutationVariables = {
  input: DeleteProductQuestionInput,
  condition?: ModelProductQuestionConditionInput | null,
};

export type DeleteProductQuestionMutation = {
  deleteProductQuestion?:  {
    __typename: "ProductQuestion",
    id: string,
    question: string,
    product:  {
      __typename: "Product",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      serviceInformation?: string | null,
      images?:  {
        __typename: "ModelProductImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questions?:  {
        __typename: "ModelProductQuestionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      salesTeam?:  {
        __typename: "Team",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        labels?: Array< string | null > | null,
        privacyEnabled: boolean,
        privacyEnableOtherTeams?: boolean | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      salesTeamId?: string | null,
      meetings?:  {
        __typename: "ModelMeetingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProductsId?: string | null,
      teamProductsId?: string | null,
    },
    productId: string,
    responses?:  {
      __typename: "ModelQuestionResponseConnection",
      items:  Array< {
        __typename: "QuestionResponse",
        id: string,
        response?: string | null,
        privateNotes?: string | null,
        questionId: string,
        profileId: string,
        meetingId: string,
        meetingInviteId: string,
        owner?: string | null,
        contactRequestTimeframe?: string | null,
        contactRequestDateTime?: string | null,
        contactPhoneNumber?: string | null,
        contactEmail?: string | null,
        contactName?: string | null,
        providePersonalEmail?: boolean | null,
        providePersonalPhoneNumber?: boolean | null,
        textOnly?: boolean | null,
        followupMeetingRequested?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productQuestionResponsesId?: string | null,
        profileQuestionResponsesId?: string | null,
        meetingQuestionResponsesId?: string | null,
        meetingInviteQuestionResponsesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    productQuestionsId?: string | null,
  } | null,
};

export type CreateQuestionResponseMutationVariables = {
  input: CreateQuestionResponseInput,
  condition?: ModelQuestionResponseConditionInput | null,
};

export type CreateQuestionResponseMutation = {
  createQuestionResponse?:  {
    __typename: "QuestionResponse",
    id: string,
    response?: string | null,
    privateNotes?: string | null,
    question:  {
      __typename: "ProductQuestion",
      id: string,
      question: string,
      product:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      },
      productId: string,
      responses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productQuestionsId?: string | null,
    },
    questionId: string,
    profile:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    },
    profileId: string,
    meeting:  {
      __typename: "Meeting",
      id: string,
      title: string,
      titleLowerCase?: string | null,
      startDateTime: string,
      endDateTime: string,
      status: MeetingStatus,
      description?: string | null,
      preferredRoomProvider?:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      preferredRoomProviderId?: string | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      room?:  {
        __typename: "MeetingRoom",
        id: string,
        name: string,
        meetingId: string,
        providerId: string,
        providerMeetingId?: string | null,
        accessCode: string,
        status: MeetingRoomStatus,
        startDateTime?: string | null,
        endDateTime?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        roomCreatedAt?: string | null,
        roomDestroyedAt?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        isReadinessTest: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        meetingRoomProviderMeetingRoomsId?: string | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null,
      productId?: string | null,
      isReadinessTest: boolean,
      labels?: Array< string | null > | null,
      owner?: string | null,
      teamRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerOrganizationName?: string | null,
      sellerTeamName?: string | null,
      buyerTeamName?: string | null,
      sellerOrganizationNameLowerCase?: string | null,
      sellerTeamNameLowerCase?: string | null,
      buyerTeamNameLowerCase?: string | null,
      externalId?: string | null,
      metrics?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productMeetingsId?: string | null,
      meetingRoomProviderMeetingsId?: string | null,
      meetingRoomId?: string | null,
    },
    meetingId: string,
    meetingInvite:  {
      __typename: "MeetingInvite",
      id: string,
      meetingId: string,
      provider?:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      providerId?: string | null,
      title: string,
      startDateTime: string,
      endDateTime: string,
      status: MeetingStatus,
      description?: string | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null,
      productId?: string | null,
      isReadinessTest: boolean,
      meetingRoomId?: string | null,
      providerSessionId?: string | null,
      providerJoinData?: string | null,
      sessionStatus?: MeetingRoomSessionStatus | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      profile:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      },
      profileId: string,
      owner?: string | null,
      meetingInvitationType: MeetingInvitationType,
      teamId?: string | null,
      isInterested?: boolean | null,
      feedbackSummary?: string | null,
      contactRequestTimeframe?: string | null,
      contactPhoneNumber?: string | null,
      contactEmail?: string | null,
      sellerOrganizationName?: string | null,
      sellerTeamName?: string | null,
      buyerTeamName?: string | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productMeetingInvitesId?: string | null,
      profileMeetingInvitesId?: string | null,
      meetingRoomProviderMeetingInvitesId?: string | null,
    },
    meetingInviteId: string,
    owner?: string | null,
    contactRequestTimeframe?: string | null,
    contactRequestDateTime?: string | null,
    contactPhoneNumber?: string | null,
    contactEmail?: string | null,
    contactName?: string | null,
    providePersonalEmail?: boolean | null,
    providePersonalPhoneNumber?: boolean | null,
    textOnly?: boolean | null,
    followupMeetingRequested?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    productQuestionResponsesId?: string | null,
    profileQuestionResponsesId?: string | null,
    meetingQuestionResponsesId?: string | null,
    meetingInviteQuestionResponsesId?: string | null,
  } | null,
};

export type UpdateQuestionResponseMutationVariables = {
  input: UpdateQuestionResponseInput,
  condition?: ModelQuestionResponseConditionInput | null,
};

export type UpdateQuestionResponseMutation = {
  updateQuestionResponse?:  {
    __typename: "QuestionResponse",
    id: string,
    response?: string | null,
    privateNotes?: string | null,
    question:  {
      __typename: "ProductQuestion",
      id: string,
      question: string,
      product:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      },
      productId: string,
      responses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productQuestionsId?: string | null,
    },
    questionId: string,
    profile:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    },
    profileId: string,
    meeting:  {
      __typename: "Meeting",
      id: string,
      title: string,
      titleLowerCase?: string | null,
      startDateTime: string,
      endDateTime: string,
      status: MeetingStatus,
      description?: string | null,
      preferredRoomProvider?:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      preferredRoomProviderId?: string | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      room?:  {
        __typename: "MeetingRoom",
        id: string,
        name: string,
        meetingId: string,
        providerId: string,
        providerMeetingId?: string | null,
        accessCode: string,
        status: MeetingRoomStatus,
        startDateTime?: string | null,
        endDateTime?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        roomCreatedAt?: string | null,
        roomDestroyedAt?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        isReadinessTest: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        meetingRoomProviderMeetingRoomsId?: string | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null,
      productId?: string | null,
      isReadinessTest: boolean,
      labels?: Array< string | null > | null,
      owner?: string | null,
      teamRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerOrganizationName?: string | null,
      sellerTeamName?: string | null,
      buyerTeamName?: string | null,
      sellerOrganizationNameLowerCase?: string | null,
      sellerTeamNameLowerCase?: string | null,
      buyerTeamNameLowerCase?: string | null,
      externalId?: string | null,
      metrics?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productMeetingsId?: string | null,
      meetingRoomProviderMeetingsId?: string | null,
      meetingRoomId?: string | null,
    },
    meetingId: string,
    meetingInvite:  {
      __typename: "MeetingInvite",
      id: string,
      meetingId: string,
      provider?:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      providerId?: string | null,
      title: string,
      startDateTime: string,
      endDateTime: string,
      status: MeetingStatus,
      description?: string | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null,
      productId?: string | null,
      isReadinessTest: boolean,
      meetingRoomId?: string | null,
      providerSessionId?: string | null,
      providerJoinData?: string | null,
      sessionStatus?: MeetingRoomSessionStatus | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      profile:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      },
      profileId: string,
      owner?: string | null,
      meetingInvitationType: MeetingInvitationType,
      teamId?: string | null,
      isInterested?: boolean | null,
      feedbackSummary?: string | null,
      contactRequestTimeframe?: string | null,
      contactPhoneNumber?: string | null,
      contactEmail?: string | null,
      sellerOrganizationName?: string | null,
      sellerTeamName?: string | null,
      buyerTeamName?: string | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productMeetingInvitesId?: string | null,
      profileMeetingInvitesId?: string | null,
      meetingRoomProviderMeetingInvitesId?: string | null,
    },
    meetingInviteId: string,
    owner?: string | null,
    contactRequestTimeframe?: string | null,
    contactRequestDateTime?: string | null,
    contactPhoneNumber?: string | null,
    contactEmail?: string | null,
    contactName?: string | null,
    providePersonalEmail?: boolean | null,
    providePersonalPhoneNumber?: boolean | null,
    textOnly?: boolean | null,
    followupMeetingRequested?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    productQuestionResponsesId?: string | null,
    profileQuestionResponsesId?: string | null,
    meetingQuestionResponsesId?: string | null,
    meetingInviteQuestionResponsesId?: string | null,
  } | null,
};

export type DeleteQuestionResponseMutationVariables = {
  input: DeleteQuestionResponseInput,
  condition?: ModelQuestionResponseConditionInput | null,
};

export type DeleteQuestionResponseMutation = {
  deleteQuestionResponse?:  {
    __typename: "QuestionResponse",
    id: string,
    response?: string | null,
    privateNotes?: string | null,
    question:  {
      __typename: "ProductQuestion",
      id: string,
      question: string,
      product:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      },
      productId: string,
      responses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productQuestionsId?: string | null,
    },
    questionId: string,
    profile:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    },
    profileId: string,
    meeting:  {
      __typename: "Meeting",
      id: string,
      title: string,
      titleLowerCase?: string | null,
      startDateTime: string,
      endDateTime: string,
      status: MeetingStatus,
      description?: string | null,
      preferredRoomProvider?:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      preferredRoomProviderId?: string | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      room?:  {
        __typename: "MeetingRoom",
        id: string,
        name: string,
        meetingId: string,
        providerId: string,
        providerMeetingId?: string | null,
        accessCode: string,
        status: MeetingRoomStatus,
        startDateTime?: string | null,
        endDateTime?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        roomCreatedAt?: string | null,
        roomDestroyedAt?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        isReadinessTest: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        meetingRoomProviderMeetingRoomsId?: string | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null,
      productId?: string | null,
      isReadinessTest: boolean,
      labels?: Array< string | null > | null,
      owner?: string | null,
      teamRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerOrganizationName?: string | null,
      sellerTeamName?: string | null,
      buyerTeamName?: string | null,
      sellerOrganizationNameLowerCase?: string | null,
      sellerTeamNameLowerCase?: string | null,
      buyerTeamNameLowerCase?: string | null,
      externalId?: string | null,
      metrics?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productMeetingsId?: string | null,
      meetingRoomProviderMeetingsId?: string | null,
      meetingRoomId?: string | null,
    },
    meetingId: string,
    meetingInvite:  {
      __typename: "MeetingInvite",
      id: string,
      meetingId: string,
      provider?:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      providerId?: string | null,
      title: string,
      startDateTime: string,
      endDateTime: string,
      status: MeetingStatus,
      description?: string | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null,
      productId?: string | null,
      isReadinessTest: boolean,
      meetingRoomId?: string | null,
      providerSessionId?: string | null,
      providerJoinData?: string | null,
      sessionStatus?: MeetingRoomSessionStatus | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      profile:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      },
      profileId: string,
      owner?: string | null,
      meetingInvitationType: MeetingInvitationType,
      teamId?: string | null,
      isInterested?: boolean | null,
      feedbackSummary?: string | null,
      contactRequestTimeframe?: string | null,
      contactPhoneNumber?: string | null,
      contactEmail?: string | null,
      sellerOrganizationName?: string | null,
      sellerTeamName?: string | null,
      buyerTeamName?: string | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productMeetingInvitesId?: string | null,
      profileMeetingInvitesId?: string | null,
      meetingRoomProviderMeetingInvitesId?: string | null,
    },
    meetingInviteId: string,
    owner?: string | null,
    contactRequestTimeframe?: string | null,
    contactRequestDateTime?: string | null,
    contactPhoneNumber?: string | null,
    contactEmail?: string | null,
    contactName?: string | null,
    providePersonalEmail?: boolean | null,
    providePersonalPhoneNumber?: boolean | null,
    textOnly?: boolean | null,
    followupMeetingRequested?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    productQuestionResponsesId?: string | null,
    profileQuestionResponsesId?: string | null,
    meetingQuestionResponsesId?: string | null,
    meetingInviteQuestionResponsesId?: string | null,
  } | null,
};

export type CreateProductImageMutationVariables = {
  input: CreateProductImageInput,
  condition?: ModelProductImageConditionInput | null,
};

export type CreateProductImageMutation = {
  createProductImage?:  {
    __typename: "ProductImage",
    id: string,
    title: string,
    alt?: string | null,
    width?: number | null,
    height?: number | null,
    image:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    },
    product:  {
      __typename: "Product",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      serviceInformation?: string | null,
      images?:  {
        __typename: "ModelProductImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questions?:  {
        __typename: "ModelProductQuestionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      salesTeam?:  {
        __typename: "Team",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        labels?: Array< string | null > | null,
        privacyEnabled: boolean,
        privacyEnableOtherTeams?: boolean | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      salesTeamId?: string | null,
      meetings?:  {
        __typename: "ModelMeetingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProductsId?: string | null,
      teamProductsId?: string | null,
    },
    productId: string,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    productImagesId?: string | null,
  } | null,
};

export type UpdateProductImageMutationVariables = {
  input: UpdateProductImageInput,
  condition?: ModelProductImageConditionInput | null,
};

export type UpdateProductImageMutation = {
  updateProductImage?:  {
    __typename: "ProductImage",
    id: string,
    title: string,
    alt?: string | null,
    width?: number | null,
    height?: number | null,
    image:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    },
    product:  {
      __typename: "Product",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      serviceInformation?: string | null,
      images?:  {
        __typename: "ModelProductImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questions?:  {
        __typename: "ModelProductQuestionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      salesTeam?:  {
        __typename: "Team",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        labels?: Array< string | null > | null,
        privacyEnabled: boolean,
        privacyEnableOtherTeams?: boolean | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      salesTeamId?: string | null,
      meetings?:  {
        __typename: "ModelMeetingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProductsId?: string | null,
      teamProductsId?: string | null,
    },
    productId: string,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    productImagesId?: string | null,
  } | null,
};

export type DeleteProductImageMutationVariables = {
  input: DeleteProductImageInput,
  condition?: ModelProductImageConditionInput | null,
};

export type DeleteProductImageMutation = {
  deleteProductImage?:  {
    __typename: "ProductImage",
    id: string,
    title: string,
    alt?: string | null,
    width?: number | null,
    height?: number | null,
    image:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    },
    product:  {
      __typename: "Product",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      serviceInformation?: string | null,
      images?:  {
        __typename: "ModelProductImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questions?:  {
        __typename: "ModelProductQuestionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      salesTeam?:  {
        __typename: "Team",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        labels?: Array< string | null > | null,
        privacyEnabled: boolean,
        privacyEnableOtherTeams?: boolean | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      salesTeamId?: string | null,
      meetings?:  {
        __typename: "ModelMeetingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProductsId?: string | null,
      teamProductsId?: string | null,
    },
    productId: string,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    productImagesId?: string | null,
  } | null,
};

export type CreateProductMutationVariables = {
  input: CreateProductInput,
  condition?: ModelProductConditionInput | null,
};

export type CreateProductMutation = {
  createProduct?:  {
    __typename: "Product",
    id: string,
    name: string,
    nameLowerCase?: string | null,
    description?: string | null,
    serviceInformation?: string | null,
    images?:  {
      __typename: "ModelProductImageConnection",
      items:  Array< {
        __typename: "ProductImage",
        id: string,
        title: string,
        alt?: string | null,
        width?: number | null,
        height?: number | null,
        productId: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productImagesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    questions?:  {
      __typename: "ModelProductQuestionConnection",
      items:  Array< {
        __typename: "ProductQuestion",
        id: string,
        question: string,
        productId: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productQuestionsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      externalId?: string | null,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      url?: string | null,
      phoneNumber?: string | null,
      locations?:  {
        __typename: "ModelOrganizationLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      profiles?:  {
        __typename: "ModelProfileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    organizationId?: string | null,
    salesTeam?:  {
      __typename: "Team",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      memberRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      privacyEnabled: boolean,
      privacyEnableOtherTeams?: boolean | null,
      images?:  {
        __typename: "ModelTeamImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    salesTeamId?: string | null,
    meetings?:  {
      __typename: "ModelMeetingConnection",
      items:  Array< {
        __typename: "Meeting",
        id: string,
        title: string,
        titleLowerCase?: string | null,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        preferredRoomProviderId?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        sellerOrganizationNameLowerCase?: string | null,
        sellerTeamNameLowerCase?: string | null,
        buyerTeamNameLowerCase?: string | null,
        externalId?: string | null,
        metrics?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingsId?: string | null,
        meetingRoomProviderMeetingsId?: string | null,
        meetingRoomId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    meetingInvites?:  {
      __typename: "ModelMeetingInviteConnection",
      items:  Array< {
        __typename: "MeetingInvite",
        id: string,
        meetingId: string,
        providerId?: string | null,
        title: string,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        meetingRoomId?: string | null,
        providerSessionId?: string | null,
        providerJoinData?: string | null,
        sessionStatus?: MeetingRoomSessionStatus | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        profileId: string,
        owner?: string | null,
        meetingInvitationType: MeetingInvitationType,
        teamId?: string | null,
        isInterested?: boolean | null,
        feedbackSummary?: string | null,
        contactRequestTimeframe?: string | null,
        contactPhoneNumber?: string | null,
        contactEmail?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingInvitesId?: string | null,
        profileMeetingInvitesId?: string | null,
        meetingRoomProviderMeetingInvitesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    labels?: Array< string | null > | null,
    owner?: string | null,
    sortByName?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    organizationProductsId?: string | null,
    teamProductsId?: string | null,
  } | null,
};

export type UpdateProductMutationVariables = {
  input: UpdateProductInput,
  condition?: ModelProductConditionInput | null,
};

export type UpdateProductMutation = {
  updateProduct?:  {
    __typename: "Product",
    id: string,
    name: string,
    nameLowerCase?: string | null,
    description?: string | null,
    serviceInformation?: string | null,
    images?:  {
      __typename: "ModelProductImageConnection",
      items:  Array< {
        __typename: "ProductImage",
        id: string,
        title: string,
        alt?: string | null,
        width?: number | null,
        height?: number | null,
        productId: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productImagesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    questions?:  {
      __typename: "ModelProductQuestionConnection",
      items:  Array< {
        __typename: "ProductQuestion",
        id: string,
        question: string,
        productId: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productQuestionsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      externalId?: string | null,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      url?: string | null,
      phoneNumber?: string | null,
      locations?:  {
        __typename: "ModelOrganizationLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      profiles?:  {
        __typename: "ModelProfileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    organizationId?: string | null,
    salesTeam?:  {
      __typename: "Team",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      memberRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      privacyEnabled: boolean,
      privacyEnableOtherTeams?: boolean | null,
      images?:  {
        __typename: "ModelTeamImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    salesTeamId?: string | null,
    meetings?:  {
      __typename: "ModelMeetingConnection",
      items:  Array< {
        __typename: "Meeting",
        id: string,
        title: string,
        titleLowerCase?: string | null,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        preferredRoomProviderId?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        sellerOrganizationNameLowerCase?: string | null,
        sellerTeamNameLowerCase?: string | null,
        buyerTeamNameLowerCase?: string | null,
        externalId?: string | null,
        metrics?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingsId?: string | null,
        meetingRoomProviderMeetingsId?: string | null,
        meetingRoomId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    meetingInvites?:  {
      __typename: "ModelMeetingInviteConnection",
      items:  Array< {
        __typename: "MeetingInvite",
        id: string,
        meetingId: string,
        providerId?: string | null,
        title: string,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        meetingRoomId?: string | null,
        providerSessionId?: string | null,
        providerJoinData?: string | null,
        sessionStatus?: MeetingRoomSessionStatus | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        profileId: string,
        owner?: string | null,
        meetingInvitationType: MeetingInvitationType,
        teamId?: string | null,
        isInterested?: boolean | null,
        feedbackSummary?: string | null,
        contactRequestTimeframe?: string | null,
        contactPhoneNumber?: string | null,
        contactEmail?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingInvitesId?: string | null,
        profileMeetingInvitesId?: string | null,
        meetingRoomProviderMeetingInvitesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    labels?: Array< string | null > | null,
    owner?: string | null,
    sortByName?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    organizationProductsId?: string | null,
    teamProductsId?: string | null,
  } | null,
};

export type DeleteProductMutationVariables = {
  input: DeleteProductInput,
  condition?: ModelProductConditionInput | null,
};

export type DeleteProductMutation = {
  deleteProduct?:  {
    __typename: "Product",
    id: string,
    name: string,
    nameLowerCase?: string | null,
    description?: string | null,
    serviceInformation?: string | null,
    images?:  {
      __typename: "ModelProductImageConnection",
      items:  Array< {
        __typename: "ProductImage",
        id: string,
        title: string,
        alt?: string | null,
        width?: number | null,
        height?: number | null,
        productId: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productImagesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    questions?:  {
      __typename: "ModelProductQuestionConnection",
      items:  Array< {
        __typename: "ProductQuestion",
        id: string,
        question: string,
        productId: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productQuestionsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      externalId?: string | null,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      url?: string | null,
      phoneNumber?: string | null,
      locations?:  {
        __typename: "ModelOrganizationLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      profiles?:  {
        __typename: "ModelProfileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    organizationId?: string | null,
    salesTeam?:  {
      __typename: "Team",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      memberRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      privacyEnabled: boolean,
      privacyEnableOtherTeams?: boolean | null,
      images?:  {
        __typename: "ModelTeamImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    salesTeamId?: string | null,
    meetings?:  {
      __typename: "ModelMeetingConnection",
      items:  Array< {
        __typename: "Meeting",
        id: string,
        title: string,
        titleLowerCase?: string | null,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        preferredRoomProviderId?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        sellerOrganizationNameLowerCase?: string | null,
        sellerTeamNameLowerCase?: string | null,
        buyerTeamNameLowerCase?: string | null,
        externalId?: string | null,
        metrics?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingsId?: string | null,
        meetingRoomProviderMeetingsId?: string | null,
        meetingRoomId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    meetingInvites?:  {
      __typename: "ModelMeetingInviteConnection",
      items:  Array< {
        __typename: "MeetingInvite",
        id: string,
        meetingId: string,
        providerId?: string | null,
        title: string,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        meetingRoomId?: string | null,
        providerSessionId?: string | null,
        providerJoinData?: string | null,
        sessionStatus?: MeetingRoomSessionStatus | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        profileId: string,
        owner?: string | null,
        meetingInvitationType: MeetingInvitationType,
        teamId?: string | null,
        isInterested?: boolean | null,
        feedbackSummary?: string | null,
        contactRequestTimeframe?: string | null,
        contactPhoneNumber?: string | null,
        contactEmail?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingInvitesId?: string | null,
        profileMeetingInvitesId?: string | null,
        meetingRoomProviderMeetingInvitesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    labels?: Array< string | null > | null,
    owner?: string | null,
    sortByName?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    organizationProductsId?: string | null,
    teamProductsId?: string | null,
  } | null,
};

export type CreateOrganizationLocationMutationVariables = {
  input: CreateOrganizationLocationInput,
  condition?: ModelOrganizationLocationConditionInput | null,
};

export type CreateOrganizationLocationMutation = {
  createOrganizationLocation?:  {
    __typename: "OrganizationLocation",
    id: string,
    title?: string | null,
    address?: string | null,
    city?: string | null,
    state?: string | null,
    country?: string | null,
    organization:  {
      __typename: "Organization",
      id: string,
      externalId?: string | null,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      url?: string | null,
      phoneNumber?: string | null,
      locations?:  {
        __typename: "ModelOrganizationLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      profiles?:  {
        __typename: "ModelProfileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    organizationId: string,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    organizationLocationsId?: string | null,
  } | null,
};

export type UpdateOrganizationLocationMutationVariables = {
  input: UpdateOrganizationLocationInput,
  condition?: ModelOrganizationLocationConditionInput | null,
};

export type UpdateOrganizationLocationMutation = {
  updateOrganizationLocation?:  {
    __typename: "OrganizationLocation",
    id: string,
    title?: string | null,
    address?: string | null,
    city?: string | null,
    state?: string | null,
    country?: string | null,
    organization:  {
      __typename: "Organization",
      id: string,
      externalId?: string | null,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      url?: string | null,
      phoneNumber?: string | null,
      locations?:  {
        __typename: "ModelOrganizationLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      profiles?:  {
        __typename: "ModelProfileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    organizationId: string,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    organizationLocationsId?: string | null,
  } | null,
};

export type DeleteOrganizationLocationMutationVariables = {
  input: DeleteOrganizationLocationInput,
  condition?: ModelOrganizationLocationConditionInput | null,
};

export type DeleteOrganizationLocationMutation = {
  deleteOrganizationLocation?:  {
    __typename: "OrganizationLocation",
    id: string,
    title?: string | null,
    address?: string | null,
    city?: string | null,
    state?: string | null,
    country?: string | null,
    organization:  {
      __typename: "Organization",
      id: string,
      externalId?: string | null,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      url?: string | null,
      phoneNumber?: string | null,
      locations?:  {
        __typename: "ModelOrganizationLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      profiles?:  {
        __typename: "ModelProfileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    organizationId: string,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    organizationLocationsId?: string | null,
  } | null,
};

export type CreateOrganizationMutationVariables = {
  input: CreateOrganizationInput,
  condition?: ModelOrganizationConditionInput | null,
};

export type CreateOrganizationMutation = {
  createOrganization?:  {
    __typename: "Organization",
    id: string,
    externalId?: string | null,
    name: string,
    nameLowerCase?: string | null,
    description?: string | null,
    url?: string | null,
    phoneNumber?: string | null,
    locations?:  {
      __typename: "ModelOrganizationLocationConnection",
      items:  Array< {
        __typename: "OrganizationLocation",
        id: string,
        title?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        country?: string | null,
        organizationId: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationLocationsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    profiles?:  {
      __typename: "ModelProfileConnection",
      items:  Array< {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    products?:  {
      __typename: "ModelProductConnection",
      items:  Array< {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    labels?: Array< string | null > | null,
    owner?: string | null,
    sortByName?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateOrganizationMutationVariables = {
  input: UpdateOrganizationInput,
  condition?: ModelOrganizationConditionInput | null,
};

export type UpdateOrganizationMutation = {
  updateOrganization?:  {
    __typename: "Organization",
    id: string,
    externalId?: string | null,
    name: string,
    nameLowerCase?: string | null,
    description?: string | null,
    url?: string | null,
    phoneNumber?: string | null,
    locations?:  {
      __typename: "ModelOrganizationLocationConnection",
      items:  Array< {
        __typename: "OrganizationLocation",
        id: string,
        title?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        country?: string | null,
        organizationId: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationLocationsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    profiles?:  {
      __typename: "ModelProfileConnection",
      items:  Array< {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    products?:  {
      __typename: "ModelProductConnection",
      items:  Array< {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    labels?: Array< string | null > | null,
    owner?: string | null,
    sortByName?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteOrganizationMutationVariables = {
  input: DeleteOrganizationInput,
  condition?: ModelOrganizationConditionInput | null,
};

export type DeleteOrganizationMutation = {
  deleteOrganization?:  {
    __typename: "Organization",
    id: string,
    externalId?: string | null,
    name: string,
    nameLowerCase?: string | null,
    description?: string | null,
    url?: string | null,
    phoneNumber?: string | null,
    locations?:  {
      __typename: "ModelOrganizationLocationConnection",
      items:  Array< {
        __typename: "OrganizationLocation",
        id: string,
        title?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        country?: string | null,
        organizationId: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationLocationsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    profiles?:  {
      __typename: "ModelProfileConnection",
      items:  Array< {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    products?:  {
      __typename: "ModelProductConnection",
      items:  Array< {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    labels?: Array< string | null > | null,
    owner?: string | null,
    sortByName?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateProfileLocationMutationVariables = {
  input: CreateProfileLocationInput,
  condition?: ModelProfileLocationConditionInput | null,
};

export type CreateProfileLocationMutation = {
  createProfileLocation?:  {
    __typename: "ProfileLocation",
    id: string,
    title?: string | null,
    address?: string | null,
    city?: string | null,
    state?: string | null,
    country?: string | null,
    profile:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    },
    profileId: string,
    isPrimary: boolean,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileLocationsId?: string | null,
  } | null,
};

export type UpdateProfileLocationMutationVariables = {
  input: UpdateProfileLocationInput,
  condition?: ModelProfileLocationConditionInput | null,
};

export type UpdateProfileLocationMutation = {
  updateProfileLocation?:  {
    __typename: "ProfileLocation",
    id: string,
    title?: string | null,
    address?: string | null,
    city?: string | null,
    state?: string | null,
    country?: string | null,
    profile:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    },
    profileId: string,
    isPrimary: boolean,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileLocationsId?: string | null,
  } | null,
};

export type DeleteProfileLocationMutationVariables = {
  input: DeleteProfileLocationInput,
  condition?: ModelProfileLocationConditionInput | null,
};

export type DeleteProfileLocationMutation = {
  deleteProfileLocation?:  {
    __typename: "ProfileLocation",
    id: string,
    title?: string | null,
    address?: string | null,
    city?: string | null,
    state?: string | null,
    country?: string | null,
    profile:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    },
    profileId: string,
    isPrimary: boolean,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileLocationsId?: string | null,
  } | null,
};

export type CreateProfileImageMutationVariables = {
  input: CreateProfileImageInput,
  condition?: ModelProfileImageConditionInput | null,
};

export type CreateProfileImageMutation = {
  createProfileImage?:  {
    __typename: "ProfileImage",
    id: string,
    title: string,
    alt?: string | null,
    width?: number | null,
    height?: number | null,
    image:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    },
    profile:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    },
    profileId: string,
    isDefault: boolean,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileImagesId?: string | null,
  } | null,
};

export type UpdateProfileImageMutationVariables = {
  input: UpdateProfileImageInput,
  condition?: ModelProfileImageConditionInput | null,
};

export type UpdateProfileImageMutation = {
  updateProfileImage?:  {
    __typename: "ProfileImage",
    id: string,
    title: string,
    alt?: string | null,
    width?: number | null,
    height?: number | null,
    image:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    },
    profile:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    },
    profileId: string,
    isDefault: boolean,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileImagesId?: string | null,
  } | null,
};

export type DeleteProfileImageMutationVariables = {
  input: DeleteProfileImageInput,
  condition?: ModelProfileImageConditionInput | null,
};

export type DeleteProfileImageMutation = {
  deleteProfileImage?:  {
    __typename: "ProfileImage",
    id: string,
    title: string,
    alt?: string | null,
    width?: number | null,
    height?: number | null,
    image:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    },
    profile:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    },
    profileId: string,
    isDefault: boolean,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileImagesId?: string | null,
  } | null,
};

export type CreateForwardEmailMutationVariables = {
  input: CreateForwardEmailInput,
  condition?: ModelForwardEmailConditionInput | null,
};

export type CreateForwardEmailMutation = {
  createForwardEmail?:  {
    __typename: "ForwardEmail",
    id: string,
    received?: number | null,
    remaining?: number | null,
    publicEmail: string,
    expireAt?: string | null,
    privateEmail?: string | null,
    publicReplyEmail?: string | null,
    profile?:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    } | null,
    profileId?: string | null,
    sellerProfile?:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    } | null,
    sellerProfileId?: string | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileForwardEmailsId?: string | null,
    profileSellerForwardEmailsId?: string | null,
  } | null,
};

export type UpdateForwardEmailMutationVariables = {
  input: UpdateForwardEmailInput,
  condition?: ModelForwardEmailConditionInput | null,
};

export type UpdateForwardEmailMutation = {
  updateForwardEmail?:  {
    __typename: "ForwardEmail",
    id: string,
    received?: number | null,
    remaining?: number | null,
    publicEmail: string,
    expireAt?: string | null,
    privateEmail?: string | null,
    publicReplyEmail?: string | null,
    profile?:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    } | null,
    profileId?: string | null,
    sellerProfile?:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    } | null,
    sellerProfileId?: string | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileForwardEmailsId?: string | null,
    profileSellerForwardEmailsId?: string | null,
  } | null,
};

export type DeleteForwardEmailMutationVariables = {
  input: DeleteForwardEmailInput,
  condition?: ModelForwardEmailConditionInput | null,
};

export type DeleteForwardEmailMutation = {
  deleteForwardEmail?:  {
    __typename: "ForwardEmail",
    id: string,
    received?: number | null,
    remaining?: number | null,
    publicEmail: string,
    expireAt?: string | null,
    privateEmail?: string | null,
    publicReplyEmail?: string | null,
    profile?:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    } | null,
    profileId?: string | null,
    sellerProfile?:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    } | null,
    sellerProfileId?: string | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileForwardEmailsId?: string | null,
    profileSellerForwardEmailsId?: string | null,
  } | null,
};

export type CreateProfileMutationVariables = {
  input: CreateProfileInput,
  condition?: ModelProfileConditionInput | null,
};

export type CreateProfileMutation = {
  createProfile?:  {
    __typename: "Profile",
    id: string,
    fullName: string,
    fullNameLowerCase?: string | null,
    email: string,
    externalId?: string | null,
    userId?: string | null,
    occupation?: string | null,
    profile?: string | null,
    phoneNumber?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      externalId?: string | null,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      url?: string | null,
      phoneNumber?: string | null,
      locations?:  {
        __typename: "ModelOrganizationLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      profiles?:  {
        __typename: "ModelProfileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    organizationId?: string | null,
    auditLogs?:  {
      __typename: "ModelMeetingRoomEventAuditLogConnection",
      items:  Array< {
        __typename: "MeetingRoomEventAuditLog",
        id: string,
        date: string,
        author: string,
        resource: string,
        action: string,
        payload?: string | null,
        ipAddress?: string | null,
        meetingRoomId: string,
        profileId?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileAuditLogsId?: string | null,
        meetingRoomAuditlogsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    jobAuditLogs?:  {
      __typename: "ModelImportJobAuditLogConnection",
      items:  Array< {
        __typename: "ImportJobAuditLog",
        id: string,
        date: string,
        author: string,
        resource: string,
        action: string,
        payload?: string | null,
        ipAddress?: string | null,
        importJobId: string,
        profileId?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileJobAuditLogsId?: string | null,
        importJobAuditLogsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    exportJobAuditLogs?:  {
      __typename: "ModelExportJobAuditLogConnection",
      items:  Array< {
        __typename: "ExportJobAuditLog",
        id: string,
        date: string,
        author: string,
        resource: string,
        action: string,
        payload?: string | null,
        ipAddress?: string | null,
        exportJobId: string,
        profileId?: string | null,
        owner: string,
        identityId?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileExportJobAuditLogsId?: string | null,
        exportJobAuditLogsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    teamRelationships?:  {
      __typename: "ModelTeamMemberRelationshipConnection",
      items:  Array< {
        __typename: "TeamMemberRelationship",
        id: string,
        teamId: string,
        memberProfileId: string,
        relationship: TeamRelationship,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileTeamRelationshipsId?: string | null,
        teamMemberRelationshipsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    meetingAttendees?:  {
      __typename: "ModelMeetingAttendeeConnection",
      items:  Array< {
        __typename: "MeetingAttendee",
        id: string,
        profileId: string,
        meetingId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileMeetingAttendeesId?: string | null,
        meetingMeetingAttendeesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    locations?:  {
      __typename: "ModelProfileLocationConnection",
      items:  Array< {
        __typename: "ProfileLocation",
        id: string,
        title?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        country?: string | null,
        profileId: string,
        isPrimary: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileLocationsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    devices?:  {
      __typename: "ModelProfileDeviceConnection",
      items:  Array< {
        __typename: "ProfileDevice",
        id: string,
        deviceHash: string,
        hasWebcam?: boolean | null,
        hasMicrophone?: boolean | null,
        isScreenCapturingSupported?: boolean | null,
        isWebRTCSupported?: boolean | null,
        isWebsiteHasMicrophonePermissions?: boolean | null,
        isWebsiteHasWebcamPermissions?: boolean | null,
        isWebSocketsSupported?: boolean | null,
        clockOffset?: number | null,
        lastSeen?: string | null,
        lastLogin?: string | null,
        lastMeetingId?: string | null,
        lastMeetingInviteId?: string | null,
        lastMeetingJoin?: string | null,
        lastMeetingStatus?: string | null,
        meetingData?: string | null,
        userAgent?: string | null,
        userAgentSummary?: string | null,
        micStatus?: string | null,
        cameraStatus?: string | null,
        networkQuality?: string | null,
        profileId: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileDevicesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    images?:  {
      __typename: "ModelProfileImageConnection",
      items:  Array< {
        __typename: "ProfileImage",
        id: string,
        title: string,
        alt?: string | null,
        width?: number | null,
        height?: number | null,
        profileId: string,
        isDefault: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileImagesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    questionResponses?:  {
      __typename: "ModelQuestionResponseConnection",
      items:  Array< {
        __typename: "QuestionResponse",
        id: string,
        response?: string | null,
        privateNotes?: string | null,
        questionId: string,
        profileId: string,
        meetingId: string,
        meetingInviteId: string,
        owner?: string | null,
        contactRequestTimeframe?: string | null,
        contactRequestDateTime?: string | null,
        contactPhoneNumber?: string | null,
        contactEmail?: string | null,
        contactName?: string | null,
        providePersonalEmail?: boolean | null,
        providePersonalPhoneNumber?: boolean | null,
        textOnly?: boolean | null,
        followupMeetingRequested?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productQuestionResponsesId?: string | null,
        profileQuestionResponsesId?: string | null,
        meetingQuestionResponsesId?: string | null,
        meetingInviteQuestionResponsesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    importJobs?:  {
      __typename: "ModelImportJobConnection",
      items:  Array< {
        __typename: "ImportJob",
        id: string,
        status: JobStatus,
        identityId?: string | null,
        result?: string | null,
        profileId?: string | null,
        lastStartDateTime?: string | null,
        lastEndDateTime?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileImportJobsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    exportJobs?:  {
      __typename: "ModelExportJobConnection",
      items:  Array< {
        __typename: "ExportJob",
        id: string,
        status: JobStatus,
        profileId?: string | null,
        meetingInvitationType?: MeetingInvitationType | null,
        startDateTime: string,
        endDateTime: string,
        owner: string,
        identityId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileExportJobsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    privacyEnabled: boolean,
    owner?: string | null,
    meetingInvites?:  {
      __typename: "ModelMeetingInviteConnection",
      items:  Array< {
        __typename: "MeetingInvite",
        id: string,
        meetingId: string,
        providerId?: string | null,
        title: string,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        meetingRoomId?: string | null,
        providerSessionId?: string | null,
        providerJoinData?: string | null,
        sessionStatus?: MeetingRoomSessionStatus | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        profileId: string,
        owner?: string | null,
        meetingInvitationType: MeetingInvitationType,
        teamId?: string | null,
        isInterested?: boolean | null,
        feedbackSummary?: string | null,
        contactRequestTimeframe?: string | null,
        contactPhoneNumber?: string | null,
        contactEmail?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingInvitesId?: string | null,
        profileMeetingInvitesId?: string | null,
        meetingRoomProviderMeetingInvitesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    sortByFullName?: number | null,
    transcriptionLogs?:  {
      __typename: "ModelTranscriptionEventAuditLogConnection",
      items:  Array< {
        __typename: "TranscriptionEventAuditLog",
        id: string,
        date: string,
        author: string,
        resource: string,
        action: string,
        payload?: string | null,
        ipAddress?: string | null,
        meetingInviteId: string,
        profileId: string,
        authorProfileId?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileTranscriptionLogsId?: string | null,
        meetingInviteTranscriptionLogsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    timeZone?: string | null,
    forwardEmails?:  {
      __typename: "ModelForwardEmailConnection",
      items:  Array< {
        __typename: "ForwardEmail",
        id: string,
        received?: number | null,
        remaining?: number | null,
        publicEmail: string,
        expireAt?: string | null,
        privateEmail?: string | null,
        publicReplyEmail?: string | null,
        profileId?: string | null,
        sellerProfileId?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileForwardEmailsId?: string | null,
        profileSellerForwardEmailsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    sellerForwardEmails?:  {
      __typename: "ModelForwardEmailConnection",
      items:  Array< {
        __typename: "ForwardEmail",
        id: string,
        received?: number | null,
        remaining?: number | null,
        publicEmail: string,
        expireAt?: string | null,
        privateEmail?: string | null,
        publicReplyEmail?: string | null,
        profileId?: string | null,
        sellerProfileId?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileForwardEmailsId?: string | null,
        profileSellerForwardEmailsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    organizationProfilesId?: string | null,
  } | null,
};

export type UpdateProfileMutationVariables = {
  input: UpdateProfileInput,
  condition?: ModelProfileConditionInput | null,
};

export type UpdateProfileMutation = {
  updateProfile?:  {
    __typename: "Profile",
    id: string,
    fullName: string,
    fullNameLowerCase?: string | null,
    email: string,
    externalId?: string | null,
    userId?: string | null,
    occupation?: string | null,
    profile?: string | null,
    phoneNumber?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      externalId?: string | null,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      url?: string | null,
      phoneNumber?: string | null,
      locations?:  {
        __typename: "ModelOrganizationLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      profiles?:  {
        __typename: "ModelProfileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    organizationId?: string | null,
    auditLogs?:  {
      __typename: "ModelMeetingRoomEventAuditLogConnection",
      items:  Array< {
        __typename: "MeetingRoomEventAuditLog",
        id: string,
        date: string,
        author: string,
        resource: string,
        action: string,
        payload?: string | null,
        ipAddress?: string | null,
        meetingRoomId: string,
        profileId?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileAuditLogsId?: string | null,
        meetingRoomAuditlogsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    jobAuditLogs?:  {
      __typename: "ModelImportJobAuditLogConnection",
      items:  Array< {
        __typename: "ImportJobAuditLog",
        id: string,
        date: string,
        author: string,
        resource: string,
        action: string,
        payload?: string | null,
        ipAddress?: string | null,
        importJobId: string,
        profileId?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileJobAuditLogsId?: string | null,
        importJobAuditLogsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    exportJobAuditLogs?:  {
      __typename: "ModelExportJobAuditLogConnection",
      items:  Array< {
        __typename: "ExportJobAuditLog",
        id: string,
        date: string,
        author: string,
        resource: string,
        action: string,
        payload?: string | null,
        ipAddress?: string | null,
        exportJobId: string,
        profileId?: string | null,
        owner: string,
        identityId?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileExportJobAuditLogsId?: string | null,
        exportJobAuditLogsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    teamRelationships?:  {
      __typename: "ModelTeamMemberRelationshipConnection",
      items:  Array< {
        __typename: "TeamMemberRelationship",
        id: string,
        teamId: string,
        memberProfileId: string,
        relationship: TeamRelationship,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileTeamRelationshipsId?: string | null,
        teamMemberRelationshipsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    meetingAttendees?:  {
      __typename: "ModelMeetingAttendeeConnection",
      items:  Array< {
        __typename: "MeetingAttendee",
        id: string,
        profileId: string,
        meetingId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileMeetingAttendeesId?: string | null,
        meetingMeetingAttendeesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    locations?:  {
      __typename: "ModelProfileLocationConnection",
      items:  Array< {
        __typename: "ProfileLocation",
        id: string,
        title?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        country?: string | null,
        profileId: string,
        isPrimary: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileLocationsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    devices?:  {
      __typename: "ModelProfileDeviceConnection",
      items:  Array< {
        __typename: "ProfileDevice",
        id: string,
        deviceHash: string,
        hasWebcam?: boolean | null,
        hasMicrophone?: boolean | null,
        isScreenCapturingSupported?: boolean | null,
        isWebRTCSupported?: boolean | null,
        isWebsiteHasMicrophonePermissions?: boolean | null,
        isWebsiteHasWebcamPermissions?: boolean | null,
        isWebSocketsSupported?: boolean | null,
        clockOffset?: number | null,
        lastSeen?: string | null,
        lastLogin?: string | null,
        lastMeetingId?: string | null,
        lastMeetingInviteId?: string | null,
        lastMeetingJoin?: string | null,
        lastMeetingStatus?: string | null,
        meetingData?: string | null,
        userAgent?: string | null,
        userAgentSummary?: string | null,
        micStatus?: string | null,
        cameraStatus?: string | null,
        networkQuality?: string | null,
        profileId: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileDevicesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    images?:  {
      __typename: "ModelProfileImageConnection",
      items:  Array< {
        __typename: "ProfileImage",
        id: string,
        title: string,
        alt?: string | null,
        width?: number | null,
        height?: number | null,
        profileId: string,
        isDefault: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileImagesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    questionResponses?:  {
      __typename: "ModelQuestionResponseConnection",
      items:  Array< {
        __typename: "QuestionResponse",
        id: string,
        response?: string | null,
        privateNotes?: string | null,
        questionId: string,
        profileId: string,
        meetingId: string,
        meetingInviteId: string,
        owner?: string | null,
        contactRequestTimeframe?: string | null,
        contactRequestDateTime?: string | null,
        contactPhoneNumber?: string | null,
        contactEmail?: string | null,
        contactName?: string | null,
        providePersonalEmail?: boolean | null,
        providePersonalPhoneNumber?: boolean | null,
        textOnly?: boolean | null,
        followupMeetingRequested?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productQuestionResponsesId?: string | null,
        profileQuestionResponsesId?: string | null,
        meetingQuestionResponsesId?: string | null,
        meetingInviteQuestionResponsesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    importJobs?:  {
      __typename: "ModelImportJobConnection",
      items:  Array< {
        __typename: "ImportJob",
        id: string,
        status: JobStatus,
        identityId?: string | null,
        result?: string | null,
        profileId?: string | null,
        lastStartDateTime?: string | null,
        lastEndDateTime?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileImportJobsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    exportJobs?:  {
      __typename: "ModelExportJobConnection",
      items:  Array< {
        __typename: "ExportJob",
        id: string,
        status: JobStatus,
        profileId?: string | null,
        meetingInvitationType?: MeetingInvitationType | null,
        startDateTime: string,
        endDateTime: string,
        owner: string,
        identityId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileExportJobsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    privacyEnabled: boolean,
    owner?: string | null,
    meetingInvites?:  {
      __typename: "ModelMeetingInviteConnection",
      items:  Array< {
        __typename: "MeetingInvite",
        id: string,
        meetingId: string,
        providerId?: string | null,
        title: string,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        meetingRoomId?: string | null,
        providerSessionId?: string | null,
        providerJoinData?: string | null,
        sessionStatus?: MeetingRoomSessionStatus | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        profileId: string,
        owner?: string | null,
        meetingInvitationType: MeetingInvitationType,
        teamId?: string | null,
        isInterested?: boolean | null,
        feedbackSummary?: string | null,
        contactRequestTimeframe?: string | null,
        contactPhoneNumber?: string | null,
        contactEmail?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingInvitesId?: string | null,
        profileMeetingInvitesId?: string | null,
        meetingRoomProviderMeetingInvitesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    sortByFullName?: number | null,
    transcriptionLogs?:  {
      __typename: "ModelTranscriptionEventAuditLogConnection",
      items:  Array< {
        __typename: "TranscriptionEventAuditLog",
        id: string,
        date: string,
        author: string,
        resource: string,
        action: string,
        payload?: string | null,
        ipAddress?: string | null,
        meetingInviteId: string,
        profileId: string,
        authorProfileId?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileTranscriptionLogsId?: string | null,
        meetingInviteTranscriptionLogsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    timeZone?: string | null,
    forwardEmails?:  {
      __typename: "ModelForwardEmailConnection",
      items:  Array< {
        __typename: "ForwardEmail",
        id: string,
        received?: number | null,
        remaining?: number | null,
        publicEmail: string,
        expireAt?: string | null,
        privateEmail?: string | null,
        publicReplyEmail?: string | null,
        profileId?: string | null,
        sellerProfileId?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileForwardEmailsId?: string | null,
        profileSellerForwardEmailsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    sellerForwardEmails?:  {
      __typename: "ModelForwardEmailConnection",
      items:  Array< {
        __typename: "ForwardEmail",
        id: string,
        received?: number | null,
        remaining?: number | null,
        publicEmail: string,
        expireAt?: string | null,
        privateEmail?: string | null,
        publicReplyEmail?: string | null,
        profileId?: string | null,
        sellerProfileId?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileForwardEmailsId?: string | null,
        profileSellerForwardEmailsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    organizationProfilesId?: string | null,
  } | null,
};

export type DeleteProfileMutationVariables = {
  input: DeleteProfileInput,
  condition?: ModelProfileConditionInput | null,
};

export type DeleteProfileMutation = {
  deleteProfile?:  {
    __typename: "Profile",
    id: string,
    fullName: string,
    fullNameLowerCase?: string | null,
    email: string,
    externalId?: string | null,
    userId?: string | null,
    occupation?: string | null,
    profile?: string | null,
    phoneNumber?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      externalId?: string | null,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      url?: string | null,
      phoneNumber?: string | null,
      locations?:  {
        __typename: "ModelOrganizationLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      profiles?:  {
        __typename: "ModelProfileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    organizationId?: string | null,
    auditLogs?:  {
      __typename: "ModelMeetingRoomEventAuditLogConnection",
      items:  Array< {
        __typename: "MeetingRoomEventAuditLog",
        id: string,
        date: string,
        author: string,
        resource: string,
        action: string,
        payload?: string | null,
        ipAddress?: string | null,
        meetingRoomId: string,
        profileId?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileAuditLogsId?: string | null,
        meetingRoomAuditlogsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    jobAuditLogs?:  {
      __typename: "ModelImportJobAuditLogConnection",
      items:  Array< {
        __typename: "ImportJobAuditLog",
        id: string,
        date: string,
        author: string,
        resource: string,
        action: string,
        payload?: string | null,
        ipAddress?: string | null,
        importJobId: string,
        profileId?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileJobAuditLogsId?: string | null,
        importJobAuditLogsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    exportJobAuditLogs?:  {
      __typename: "ModelExportJobAuditLogConnection",
      items:  Array< {
        __typename: "ExportJobAuditLog",
        id: string,
        date: string,
        author: string,
        resource: string,
        action: string,
        payload?: string | null,
        ipAddress?: string | null,
        exportJobId: string,
        profileId?: string | null,
        owner: string,
        identityId?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileExportJobAuditLogsId?: string | null,
        exportJobAuditLogsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    teamRelationships?:  {
      __typename: "ModelTeamMemberRelationshipConnection",
      items:  Array< {
        __typename: "TeamMemberRelationship",
        id: string,
        teamId: string,
        memberProfileId: string,
        relationship: TeamRelationship,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileTeamRelationshipsId?: string | null,
        teamMemberRelationshipsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    meetingAttendees?:  {
      __typename: "ModelMeetingAttendeeConnection",
      items:  Array< {
        __typename: "MeetingAttendee",
        id: string,
        profileId: string,
        meetingId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileMeetingAttendeesId?: string | null,
        meetingMeetingAttendeesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    locations?:  {
      __typename: "ModelProfileLocationConnection",
      items:  Array< {
        __typename: "ProfileLocation",
        id: string,
        title?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        country?: string | null,
        profileId: string,
        isPrimary: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileLocationsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    devices?:  {
      __typename: "ModelProfileDeviceConnection",
      items:  Array< {
        __typename: "ProfileDevice",
        id: string,
        deviceHash: string,
        hasWebcam?: boolean | null,
        hasMicrophone?: boolean | null,
        isScreenCapturingSupported?: boolean | null,
        isWebRTCSupported?: boolean | null,
        isWebsiteHasMicrophonePermissions?: boolean | null,
        isWebsiteHasWebcamPermissions?: boolean | null,
        isWebSocketsSupported?: boolean | null,
        clockOffset?: number | null,
        lastSeen?: string | null,
        lastLogin?: string | null,
        lastMeetingId?: string | null,
        lastMeetingInviteId?: string | null,
        lastMeetingJoin?: string | null,
        lastMeetingStatus?: string | null,
        meetingData?: string | null,
        userAgent?: string | null,
        userAgentSummary?: string | null,
        micStatus?: string | null,
        cameraStatus?: string | null,
        networkQuality?: string | null,
        profileId: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileDevicesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    images?:  {
      __typename: "ModelProfileImageConnection",
      items:  Array< {
        __typename: "ProfileImage",
        id: string,
        title: string,
        alt?: string | null,
        width?: number | null,
        height?: number | null,
        profileId: string,
        isDefault: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileImagesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    questionResponses?:  {
      __typename: "ModelQuestionResponseConnection",
      items:  Array< {
        __typename: "QuestionResponse",
        id: string,
        response?: string | null,
        privateNotes?: string | null,
        questionId: string,
        profileId: string,
        meetingId: string,
        meetingInviteId: string,
        owner?: string | null,
        contactRequestTimeframe?: string | null,
        contactRequestDateTime?: string | null,
        contactPhoneNumber?: string | null,
        contactEmail?: string | null,
        contactName?: string | null,
        providePersonalEmail?: boolean | null,
        providePersonalPhoneNumber?: boolean | null,
        textOnly?: boolean | null,
        followupMeetingRequested?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productQuestionResponsesId?: string | null,
        profileQuestionResponsesId?: string | null,
        meetingQuestionResponsesId?: string | null,
        meetingInviteQuestionResponsesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    importJobs?:  {
      __typename: "ModelImportJobConnection",
      items:  Array< {
        __typename: "ImportJob",
        id: string,
        status: JobStatus,
        identityId?: string | null,
        result?: string | null,
        profileId?: string | null,
        lastStartDateTime?: string | null,
        lastEndDateTime?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileImportJobsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    exportJobs?:  {
      __typename: "ModelExportJobConnection",
      items:  Array< {
        __typename: "ExportJob",
        id: string,
        status: JobStatus,
        profileId?: string | null,
        meetingInvitationType?: MeetingInvitationType | null,
        startDateTime: string,
        endDateTime: string,
        owner: string,
        identityId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileExportJobsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    privacyEnabled: boolean,
    owner?: string | null,
    meetingInvites?:  {
      __typename: "ModelMeetingInviteConnection",
      items:  Array< {
        __typename: "MeetingInvite",
        id: string,
        meetingId: string,
        providerId?: string | null,
        title: string,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        meetingRoomId?: string | null,
        providerSessionId?: string | null,
        providerJoinData?: string | null,
        sessionStatus?: MeetingRoomSessionStatus | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        profileId: string,
        owner?: string | null,
        meetingInvitationType: MeetingInvitationType,
        teamId?: string | null,
        isInterested?: boolean | null,
        feedbackSummary?: string | null,
        contactRequestTimeframe?: string | null,
        contactPhoneNumber?: string | null,
        contactEmail?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingInvitesId?: string | null,
        profileMeetingInvitesId?: string | null,
        meetingRoomProviderMeetingInvitesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    sortByFullName?: number | null,
    transcriptionLogs?:  {
      __typename: "ModelTranscriptionEventAuditLogConnection",
      items:  Array< {
        __typename: "TranscriptionEventAuditLog",
        id: string,
        date: string,
        author: string,
        resource: string,
        action: string,
        payload?: string | null,
        ipAddress?: string | null,
        meetingInviteId: string,
        profileId: string,
        authorProfileId?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileTranscriptionLogsId?: string | null,
        meetingInviteTranscriptionLogsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    timeZone?: string | null,
    forwardEmails?:  {
      __typename: "ModelForwardEmailConnection",
      items:  Array< {
        __typename: "ForwardEmail",
        id: string,
        received?: number | null,
        remaining?: number | null,
        publicEmail: string,
        expireAt?: string | null,
        privateEmail?: string | null,
        publicReplyEmail?: string | null,
        profileId?: string | null,
        sellerProfileId?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileForwardEmailsId?: string | null,
        profileSellerForwardEmailsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    sellerForwardEmails?:  {
      __typename: "ModelForwardEmailConnection",
      items:  Array< {
        __typename: "ForwardEmail",
        id: string,
        received?: number | null,
        remaining?: number | null,
        publicEmail: string,
        expireAt?: string | null,
        privateEmail?: string | null,
        publicReplyEmail?: string | null,
        profileId?: string | null,
        sellerProfileId?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileForwardEmailsId?: string | null,
        profileSellerForwardEmailsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    organizationProfilesId?: string | null,
  } | null,
};

export type CreateProfileDeviceMutationVariables = {
  input: CreateProfileDeviceInput,
  condition?: ModelProfileDeviceConditionInput | null,
};

export type CreateProfileDeviceMutation = {
  createProfileDevice?:  {
    __typename: "ProfileDevice",
    id: string,
    deviceHash: string,
    hasWebcam?: boolean | null,
    hasMicrophone?: boolean | null,
    isScreenCapturingSupported?: boolean | null,
    isWebRTCSupported?: boolean | null,
    isWebsiteHasMicrophonePermissions?: boolean | null,
    isWebsiteHasWebcamPermissions?: boolean | null,
    isWebSocketsSupported?: boolean | null,
    clockOffset?: number | null,
    lastSeen?: string | null,
    lastLogin?: string | null,
    lastMeetingId?: string | null,
    lastMeetingInviteId?: string | null,
    lastMeetingJoin?: string | null,
    lastMeetingStatus?: string | null,
    meetingData?: string | null,
    userAgent?: string | null,
    userAgentSummary?: string | null,
    micStatus?: string | null,
    cameraStatus?: string | null,
    networkQuality?: string | null,
    profile:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    },
    profileId: string,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileDevicesId?: string | null,
  } | null,
};

export type UpdateProfileDeviceMutationVariables = {
  input: UpdateProfileDeviceInput,
  condition?: ModelProfileDeviceConditionInput | null,
};

export type UpdateProfileDeviceMutation = {
  updateProfileDevice?:  {
    __typename: "ProfileDevice",
    id: string,
    deviceHash: string,
    hasWebcam?: boolean | null,
    hasMicrophone?: boolean | null,
    isScreenCapturingSupported?: boolean | null,
    isWebRTCSupported?: boolean | null,
    isWebsiteHasMicrophonePermissions?: boolean | null,
    isWebsiteHasWebcamPermissions?: boolean | null,
    isWebSocketsSupported?: boolean | null,
    clockOffset?: number | null,
    lastSeen?: string | null,
    lastLogin?: string | null,
    lastMeetingId?: string | null,
    lastMeetingInviteId?: string | null,
    lastMeetingJoin?: string | null,
    lastMeetingStatus?: string | null,
    meetingData?: string | null,
    userAgent?: string | null,
    userAgentSummary?: string | null,
    micStatus?: string | null,
    cameraStatus?: string | null,
    networkQuality?: string | null,
    profile:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    },
    profileId: string,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileDevicesId?: string | null,
  } | null,
};

export type DeleteProfileDeviceMutationVariables = {
  input: DeleteProfileDeviceInput,
  condition?: ModelProfileDeviceConditionInput | null,
};

export type DeleteProfileDeviceMutation = {
  deleteProfileDevice?:  {
    __typename: "ProfileDevice",
    id: string,
    deviceHash: string,
    hasWebcam?: boolean | null,
    hasMicrophone?: boolean | null,
    isScreenCapturingSupported?: boolean | null,
    isWebRTCSupported?: boolean | null,
    isWebsiteHasMicrophonePermissions?: boolean | null,
    isWebsiteHasWebcamPermissions?: boolean | null,
    isWebSocketsSupported?: boolean | null,
    clockOffset?: number | null,
    lastSeen?: string | null,
    lastLogin?: string | null,
    lastMeetingId?: string | null,
    lastMeetingInviteId?: string | null,
    lastMeetingJoin?: string | null,
    lastMeetingStatus?: string | null,
    meetingData?: string | null,
    userAgent?: string | null,
    userAgentSummary?: string | null,
    micStatus?: string | null,
    cameraStatus?: string | null,
    networkQuality?: string | null,
    profile:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    },
    profileId: string,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileDevicesId?: string | null,
  } | null,
};

export type CreateTeamImageMutationVariables = {
  input: CreateTeamImageInput,
  condition?: ModelTeamImageConditionInput | null,
};

export type CreateTeamImageMutation = {
  createTeamImage?:  {
    __typename: "TeamImage",
    id: string,
    title: string,
    alt?: string | null,
    width?: number | null,
    height?: number | null,
    image:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    },
    team:  {
      __typename: "Team",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      memberRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      privacyEnabled: boolean,
      privacyEnableOtherTeams?: boolean | null,
      images?:  {
        __typename: "ModelTeamImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    teamId: string,
    isDefault: boolean,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    teamImagesId?: string | null,
  } | null,
};

export type UpdateTeamImageMutationVariables = {
  input: UpdateTeamImageInput,
  condition?: ModelTeamImageConditionInput | null,
};

export type UpdateTeamImageMutation = {
  updateTeamImage?:  {
    __typename: "TeamImage",
    id: string,
    title: string,
    alt?: string | null,
    width?: number | null,
    height?: number | null,
    image:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    },
    team:  {
      __typename: "Team",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      memberRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      privacyEnabled: boolean,
      privacyEnableOtherTeams?: boolean | null,
      images?:  {
        __typename: "ModelTeamImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    teamId: string,
    isDefault: boolean,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    teamImagesId?: string | null,
  } | null,
};

export type DeleteTeamImageMutationVariables = {
  input: DeleteTeamImageInput,
  condition?: ModelTeamImageConditionInput | null,
};

export type DeleteTeamImageMutation = {
  deleteTeamImage?:  {
    __typename: "TeamImage",
    id: string,
    title: string,
    alt?: string | null,
    width?: number | null,
    height?: number | null,
    image:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    },
    team:  {
      __typename: "Team",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      memberRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      privacyEnabled: boolean,
      privacyEnableOtherTeams?: boolean | null,
      images?:  {
        __typename: "ModelTeamImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    teamId: string,
    isDefault: boolean,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    teamImagesId?: string | null,
  } | null,
};

export type CreateTeamMutationVariables = {
  input: CreateTeamInput,
  condition?: ModelTeamConditionInput | null,
};

export type CreateTeamMutation = {
  createTeam?:  {
    __typename: "Team",
    id: string,
    name: string,
    nameLowerCase?: string | null,
    description?: string | null,
    memberRelationships?:  {
      __typename: "ModelTeamMemberRelationshipConnection",
      items:  Array< {
        __typename: "TeamMemberRelationship",
        id: string,
        teamId: string,
        memberProfileId: string,
        relationship: TeamRelationship,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileTeamRelationshipsId?: string | null,
        teamMemberRelationshipsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    meetingRelationships?:  {
      __typename: "ModelTeamMeetingRelationshipConnection",
      items:  Array< {
        __typename: "TeamMeetingRelationship",
        id: string,
        teamId: string,
        meetingId: string,
        relationshipType: TeamMeetingRelationshipType,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        teamMeetingRelationshipsId?: string | null,
        meetingTeamRelationshipsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    products?:  {
      __typename: "ModelProductConnection",
      items:  Array< {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    labels?: Array< string | null > | null,
    privacyEnabled: boolean,
    privacyEnableOtherTeams?: boolean | null,
    images?:  {
      __typename: "ModelTeamImageConnection",
      items:  Array< {
        __typename: "TeamImage",
        id: string,
        title: string,
        alt?: string | null,
        width?: number | null,
        height?: number | null,
        teamId: string,
        isDefault: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        teamImagesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    transcriptions?:  {
      __typename: "ModelTranscriptionConnection",
      items:  Array< {
        __typename: "Transcription",
        id: string,
        date: string,
        title: string,
        description?: string | null,
        startDateTime: string,
        endDateTime: string,
        sizeInBytes?: number | null,
        numberOfTranscriptions?: number | null,
        isZipFile: boolean,
        meetingId?: string | null,
        teamId?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        teamTranscriptionsId?: string | null,
        meetingTranscriptionsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    owner?: string | null,
    sortByName?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateTeamMutationVariables = {
  input: UpdateTeamInput,
  condition?: ModelTeamConditionInput | null,
};

export type UpdateTeamMutation = {
  updateTeam?:  {
    __typename: "Team",
    id: string,
    name: string,
    nameLowerCase?: string | null,
    description?: string | null,
    memberRelationships?:  {
      __typename: "ModelTeamMemberRelationshipConnection",
      items:  Array< {
        __typename: "TeamMemberRelationship",
        id: string,
        teamId: string,
        memberProfileId: string,
        relationship: TeamRelationship,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileTeamRelationshipsId?: string | null,
        teamMemberRelationshipsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    meetingRelationships?:  {
      __typename: "ModelTeamMeetingRelationshipConnection",
      items:  Array< {
        __typename: "TeamMeetingRelationship",
        id: string,
        teamId: string,
        meetingId: string,
        relationshipType: TeamMeetingRelationshipType,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        teamMeetingRelationshipsId?: string | null,
        meetingTeamRelationshipsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    products?:  {
      __typename: "ModelProductConnection",
      items:  Array< {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    labels?: Array< string | null > | null,
    privacyEnabled: boolean,
    privacyEnableOtherTeams?: boolean | null,
    images?:  {
      __typename: "ModelTeamImageConnection",
      items:  Array< {
        __typename: "TeamImage",
        id: string,
        title: string,
        alt?: string | null,
        width?: number | null,
        height?: number | null,
        teamId: string,
        isDefault: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        teamImagesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    transcriptions?:  {
      __typename: "ModelTranscriptionConnection",
      items:  Array< {
        __typename: "Transcription",
        id: string,
        date: string,
        title: string,
        description?: string | null,
        startDateTime: string,
        endDateTime: string,
        sizeInBytes?: number | null,
        numberOfTranscriptions?: number | null,
        isZipFile: boolean,
        meetingId?: string | null,
        teamId?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        teamTranscriptionsId?: string | null,
        meetingTranscriptionsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    owner?: string | null,
    sortByName?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteTeamMutationVariables = {
  input: DeleteTeamInput,
  condition?: ModelTeamConditionInput | null,
};

export type DeleteTeamMutation = {
  deleteTeam?:  {
    __typename: "Team",
    id: string,
    name: string,
    nameLowerCase?: string | null,
    description?: string | null,
    memberRelationships?:  {
      __typename: "ModelTeamMemberRelationshipConnection",
      items:  Array< {
        __typename: "TeamMemberRelationship",
        id: string,
        teamId: string,
        memberProfileId: string,
        relationship: TeamRelationship,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileTeamRelationshipsId?: string | null,
        teamMemberRelationshipsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    meetingRelationships?:  {
      __typename: "ModelTeamMeetingRelationshipConnection",
      items:  Array< {
        __typename: "TeamMeetingRelationship",
        id: string,
        teamId: string,
        meetingId: string,
        relationshipType: TeamMeetingRelationshipType,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        teamMeetingRelationshipsId?: string | null,
        meetingTeamRelationshipsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    products?:  {
      __typename: "ModelProductConnection",
      items:  Array< {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    labels?: Array< string | null > | null,
    privacyEnabled: boolean,
    privacyEnableOtherTeams?: boolean | null,
    images?:  {
      __typename: "ModelTeamImageConnection",
      items:  Array< {
        __typename: "TeamImage",
        id: string,
        title: string,
        alt?: string | null,
        width?: number | null,
        height?: number | null,
        teamId: string,
        isDefault: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        teamImagesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    transcriptions?:  {
      __typename: "ModelTranscriptionConnection",
      items:  Array< {
        __typename: "Transcription",
        id: string,
        date: string,
        title: string,
        description?: string | null,
        startDateTime: string,
        endDateTime: string,
        sizeInBytes?: number | null,
        numberOfTranscriptions?: number | null,
        isZipFile: boolean,
        meetingId?: string | null,
        teamId?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        teamTranscriptionsId?: string | null,
        meetingTranscriptionsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    owner?: string | null,
    sortByName?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateTeamMemberRelationshipMutationVariables = {
  input: CreateTeamMemberRelationshipInput,
  condition?: ModelTeamMemberRelationshipConditionInput | null,
};

export type CreateTeamMemberRelationshipMutation = {
  createTeamMemberRelationship?:  {
    __typename: "TeamMemberRelationship",
    id: string,
    team:  {
      __typename: "Team",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      memberRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      privacyEnabled: boolean,
      privacyEnableOtherTeams?: boolean | null,
      images?:  {
        __typename: "ModelTeamImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    teamId: string,
    member:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    },
    memberProfileId: string,
    relationship: TeamRelationship,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileTeamRelationshipsId?: string | null,
    teamMemberRelationshipsId?: string | null,
  } | null,
};

export type UpdateTeamMemberRelationshipMutationVariables = {
  input: UpdateTeamMemberRelationshipInput,
  condition?: ModelTeamMemberRelationshipConditionInput | null,
};

export type UpdateTeamMemberRelationshipMutation = {
  updateTeamMemberRelationship?:  {
    __typename: "TeamMemberRelationship",
    id: string,
    team:  {
      __typename: "Team",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      memberRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      privacyEnabled: boolean,
      privacyEnableOtherTeams?: boolean | null,
      images?:  {
        __typename: "ModelTeamImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    teamId: string,
    member:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    },
    memberProfileId: string,
    relationship: TeamRelationship,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileTeamRelationshipsId?: string | null,
    teamMemberRelationshipsId?: string | null,
  } | null,
};

export type DeleteTeamMemberRelationshipMutationVariables = {
  input: DeleteTeamMemberRelationshipInput,
  condition?: ModelTeamMemberRelationshipConditionInput | null,
};

export type DeleteTeamMemberRelationshipMutation = {
  deleteTeamMemberRelationship?:  {
    __typename: "TeamMemberRelationship",
    id: string,
    team:  {
      __typename: "Team",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      memberRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      privacyEnabled: boolean,
      privacyEnableOtherTeams?: boolean | null,
      images?:  {
        __typename: "ModelTeamImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    teamId: string,
    member:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    },
    memberProfileId: string,
    relationship: TeamRelationship,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileTeamRelationshipsId?: string | null,
    teamMemberRelationshipsId?: string | null,
  } | null,
};

export type CreateMeetingRoomProviderMutationVariables = {
  input: CreateMeetingRoomProviderInput,
  condition?: ModelMeetingRoomProviderConditionInput | null,
};

export type CreateMeetingRoomProviderMutation = {
  createMeetingRoomProvider?:  {
    __typename: "MeetingRoomProvider",
    id: string,
    name: string,
    componentName?: string | null,
    description?: string | null,
    configuration?: string | null,
    meetings?:  {
      __typename: "ModelMeetingConnection",
      items:  Array< {
        __typename: "Meeting",
        id: string,
        title: string,
        titleLowerCase?: string | null,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        preferredRoomProviderId?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        sellerOrganizationNameLowerCase?: string | null,
        sellerTeamNameLowerCase?: string | null,
        buyerTeamNameLowerCase?: string | null,
        externalId?: string | null,
        metrics?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingsId?: string | null,
        meetingRoomProviderMeetingsId?: string | null,
        meetingRoomId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    meetingRooms?:  {
      __typename: "ModelMeetingRoomConnection",
      items:  Array< {
        __typename: "MeetingRoom",
        id: string,
        name: string,
        meetingId: string,
        providerId: string,
        providerMeetingId?: string | null,
        accessCode: string,
        status: MeetingRoomStatus,
        startDateTime?: string | null,
        endDateTime?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        roomCreatedAt?: string | null,
        roomDestroyedAt?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        isReadinessTest: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        meetingRoomProviderMeetingRoomsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    meetingInvites?:  {
      __typename: "ModelMeetingInviteConnection",
      items:  Array< {
        __typename: "MeetingInvite",
        id: string,
        meetingId: string,
        providerId?: string | null,
        title: string,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        meetingRoomId?: string | null,
        providerSessionId?: string | null,
        providerJoinData?: string | null,
        sessionStatus?: MeetingRoomSessionStatus | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        profileId: string,
        owner?: string | null,
        meetingInvitationType: MeetingInvitationType,
        teamId?: string | null,
        isInterested?: boolean | null,
        feedbackSummary?: string | null,
        contactRequestTimeframe?: string | null,
        contactPhoneNumber?: string | null,
        contactEmail?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingInvitesId?: string | null,
        profileMeetingInvitesId?: string | null,
        meetingRoomProviderMeetingInvitesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateMeetingRoomProviderMutationVariables = {
  input: UpdateMeetingRoomProviderInput,
  condition?: ModelMeetingRoomProviderConditionInput | null,
};

export type UpdateMeetingRoomProviderMutation = {
  updateMeetingRoomProvider?:  {
    __typename: "MeetingRoomProvider",
    id: string,
    name: string,
    componentName?: string | null,
    description?: string | null,
    configuration?: string | null,
    meetings?:  {
      __typename: "ModelMeetingConnection",
      items:  Array< {
        __typename: "Meeting",
        id: string,
        title: string,
        titleLowerCase?: string | null,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        preferredRoomProviderId?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        sellerOrganizationNameLowerCase?: string | null,
        sellerTeamNameLowerCase?: string | null,
        buyerTeamNameLowerCase?: string | null,
        externalId?: string | null,
        metrics?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingsId?: string | null,
        meetingRoomProviderMeetingsId?: string | null,
        meetingRoomId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    meetingRooms?:  {
      __typename: "ModelMeetingRoomConnection",
      items:  Array< {
        __typename: "MeetingRoom",
        id: string,
        name: string,
        meetingId: string,
        providerId: string,
        providerMeetingId?: string | null,
        accessCode: string,
        status: MeetingRoomStatus,
        startDateTime?: string | null,
        endDateTime?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        roomCreatedAt?: string | null,
        roomDestroyedAt?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        isReadinessTest: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        meetingRoomProviderMeetingRoomsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    meetingInvites?:  {
      __typename: "ModelMeetingInviteConnection",
      items:  Array< {
        __typename: "MeetingInvite",
        id: string,
        meetingId: string,
        providerId?: string | null,
        title: string,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        meetingRoomId?: string | null,
        providerSessionId?: string | null,
        providerJoinData?: string | null,
        sessionStatus?: MeetingRoomSessionStatus | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        profileId: string,
        owner?: string | null,
        meetingInvitationType: MeetingInvitationType,
        teamId?: string | null,
        isInterested?: boolean | null,
        feedbackSummary?: string | null,
        contactRequestTimeframe?: string | null,
        contactPhoneNumber?: string | null,
        contactEmail?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingInvitesId?: string | null,
        profileMeetingInvitesId?: string | null,
        meetingRoomProviderMeetingInvitesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteMeetingRoomProviderMutationVariables = {
  input: DeleteMeetingRoomProviderInput,
  condition?: ModelMeetingRoomProviderConditionInput | null,
};

export type DeleteMeetingRoomProviderMutation = {
  deleteMeetingRoomProvider?:  {
    __typename: "MeetingRoomProvider",
    id: string,
    name: string,
    componentName?: string | null,
    description?: string | null,
    configuration?: string | null,
    meetings?:  {
      __typename: "ModelMeetingConnection",
      items:  Array< {
        __typename: "Meeting",
        id: string,
        title: string,
        titleLowerCase?: string | null,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        preferredRoomProviderId?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        sellerOrganizationNameLowerCase?: string | null,
        sellerTeamNameLowerCase?: string | null,
        buyerTeamNameLowerCase?: string | null,
        externalId?: string | null,
        metrics?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingsId?: string | null,
        meetingRoomProviderMeetingsId?: string | null,
        meetingRoomId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    meetingRooms?:  {
      __typename: "ModelMeetingRoomConnection",
      items:  Array< {
        __typename: "MeetingRoom",
        id: string,
        name: string,
        meetingId: string,
        providerId: string,
        providerMeetingId?: string | null,
        accessCode: string,
        status: MeetingRoomStatus,
        startDateTime?: string | null,
        endDateTime?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        roomCreatedAt?: string | null,
        roomDestroyedAt?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        isReadinessTest: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        meetingRoomProviderMeetingRoomsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    meetingInvites?:  {
      __typename: "ModelMeetingInviteConnection",
      items:  Array< {
        __typename: "MeetingInvite",
        id: string,
        meetingId: string,
        providerId?: string | null,
        title: string,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        meetingRoomId?: string | null,
        providerSessionId?: string | null,
        providerJoinData?: string | null,
        sessionStatus?: MeetingRoomSessionStatus | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        profileId: string,
        owner?: string | null,
        meetingInvitationType: MeetingInvitationType,
        teamId?: string | null,
        isInterested?: boolean | null,
        feedbackSummary?: string | null,
        contactRequestTimeframe?: string | null,
        contactPhoneNumber?: string | null,
        contactEmail?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingInvitesId?: string | null,
        profileMeetingInvitesId?: string | null,
        meetingRoomProviderMeetingInvitesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateTeamMeetingRelationshipMutationVariables = {
  input: CreateTeamMeetingRelationshipInput,
  condition?: ModelTeamMeetingRelationshipConditionInput | null,
};

export type CreateTeamMeetingRelationshipMutation = {
  createTeamMeetingRelationship?:  {
    __typename: "TeamMeetingRelationship",
    id: string,
    team:  {
      __typename: "Team",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      memberRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      privacyEnabled: boolean,
      privacyEnableOtherTeams?: boolean | null,
      images?:  {
        __typename: "ModelTeamImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    teamId: string,
    meeting:  {
      __typename: "Meeting",
      id: string,
      title: string,
      titleLowerCase?: string | null,
      startDateTime: string,
      endDateTime: string,
      status: MeetingStatus,
      description?: string | null,
      preferredRoomProvider?:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      preferredRoomProviderId?: string | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      room?:  {
        __typename: "MeetingRoom",
        id: string,
        name: string,
        meetingId: string,
        providerId: string,
        providerMeetingId?: string | null,
        accessCode: string,
        status: MeetingRoomStatus,
        startDateTime?: string | null,
        endDateTime?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        roomCreatedAt?: string | null,
        roomDestroyedAt?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        isReadinessTest: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        meetingRoomProviderMeetingRoomsId?: string | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null,
      productId?: string | null,
      isReadinessTest: boolean,
      labels?: Array< string | null > | null,
      owner?: string | null,
      teamRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerOrganizationName?: string | null,
      sellerTeamName?: string | null,
      buyerTeamName?: string | null,
      sellerOrganizationNameLowerCase?: string | null,
      sellerTeamNameLowerCase?: string | null,
      buyerTeamNameLowerCase?: string | null,
      externalId?: string | null,
      metrics?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productMeetingsId?: string | null,
      meetingRoomProviderMeetingsId?: string | null,
      meetingRoomId?: string | null,
    },
    meetingId: string,
    relationshipType: TeamMeetingRelationshipType,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    teamMeetingRelationshipsId?: string | null,
    meetingTeamRelationshipsId?: string | null,
  } | null,
};

export type UpdateTeamMeetingRelationshipMutationVariables = {
  input: UpdateTeamMeetingRelationshipInput,
  condition?: ModelTeamMeetingRelationshipConditionInput | null,
};

export type UpdateTeamMeetingRelationshipMutation = {
  updateTeamMeetingRelationship?:  {
    __typename: "TeamMeetingRelationship",
    id: string,
    team:  {
      __typename: "Team",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      memberRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      privacyEnabled: boolean,
      privacyEnableOtherTeams?: boolean | null,
      images?:  {
        __typename: "ModelTeamImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    teamId: string,
    meeting:  {
      __typename: "Meeting",
      id: string,
      title: string,
      titleLowerCase?: string | null,
      startDateTime: string,
      endDateTime: string,
      status: MeetingStatus,
      description?: string | null,
      preferredRoomProvider?:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      preferredRoomProviderId?: string | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      room?:  {
        __typename: "MeetingRoom",
        id: string,
        name: string,
        meetingId: string,
        providerId: string,
        providerMeetingId?: string | null,
        accessCode: string,
        status: MeetingRoomStatus,
        startDateTime?: string | null,
        endDateTime?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        roomCreatedAt?: string | null,
        roomDestroyedAt?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        isReadinessTest: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        meetingRoomProviderMeetingRoomsId?: string | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null,
      productId?: string | null,
      isReadinessTest: boolean,
      labels?: Array< string | null > | null,
      owner?: string | null,
      teamRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerOrganizationName?: string | null,
      sellerTeamName?: string | null,
      buyerTeamName?: string | null,
      sellerOrganizationNameLowerCase?: string | null,
      sellerTeamNameLowerCase?: string | null,
      buyerTeamNameLowerCase?: string | null,
      externalId?: string | null,
      metrics?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productMeetingsId?: string | null,
      meetingRoomProviderMeetingsId?: string | null,
      meetingRoomId?: string | null,
    },
    meetingId: string,
    relationshipType: TeamMeetingRelationshipType,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    teamMeetingRelationshipsId?: string | null,
    meetingTeamRelationshipsId?: string | null,
  } | null,
};

export type DeleteTeamMeetingRelationshipMutationVariables = {
  input: DeleteTeamMeetingRelationshipInput,
  condition?: ModelTeamMeetingRelationshipConditionInput | null,
};

export type DeleteTeamMeetingRelationshipMutation = {
  deleteTeamMeetingRelationship?:  {
    __typename: "TeamMeetingRelationship",
    id: string,
    team:  {
      __typename: "Team",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      memberRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      privacyEnabled: boolean,
      privacyEnableOtherTeams?: boolean | null,
      images?:  {
        __typename: "ModelTeamImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    teamId: string,
    meeting:  {
      __typename: "Meeting",
      id: string,
      title: string,
      titleLowerCase?: string | null,
      startDateTime: string,
      endDateTime: string,
      status: MeetingStatus,
      description?: string | null,
      preferredRoomProvider?:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      preferredRoomProviderId?: string | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      room?:  {
        __typename: "MeetingRoom",
        id: string,
        name: string,
        meetingId: string,
        providerId: string,
        providerMeetingId?: string | null,
        accessCode: string,
        status: MeetingRoomStatus,
        startDateTime?: string | null,
        endDateTime?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        roomCreatedAt?: string | null,
        roomDestroyedAt?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        isReadinessTest: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        meetingRoomProviderMeetingRoomsId?: string | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null,
      productId?: string | null,
      isReadinessTest: boolean,
      labels?: Array< string | null > | null,
      owner?: string | null,
      teamRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerOrganizationName?: string | null,
      sellerTeamName?: string | null,
      buyerTeamName?: string | null,
      sellerOrganizationNameLowerCase?: string | null,
      sellerTeamNameLowerCase?: string | null,
      buyerTeamNameLowerCase?: string | null,
      externalId?: string | null,
      metrics?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productMeetingsId?: string | null,
      meetingRoomProviderMeetingsId?: string | null,
      meetingRoomId?: string | null,
    },
    meetingId: string,
    relationshipType: TeamMeetingRelationshipType,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    teamMeetingRelationshipsId?: string | null,
    meetingTeamRelationshipsId?: string | null,
  } | null,
};

export type CreateMeetingAttendeeMutationVariables = {
  input: CreateMeetingAttendeeInput,
  condition?: ModelMeetingAttendeeConditionInput | null,
};

export type CreateMeetingAttendeeMutation = {
  createMeetingAttendee?:  {
    __typename: "MeetingAttendee",
    id: string,
    profile:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    },
    profileId: string,
    meeting:  {
      __typename: "Meeting",
      id: string,
      title: string,
      titleLowerCase?: string | null,
      startDateTime: string,
      endDateTime: string,
      status: MeetingStatus,
      description?: string | null,
      preferredRoomProvider?:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      preferredRoomProviderId?: string | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      room?:  {
        __typename: "MeetingRoom",
        id: string,
        name: string,
        meetingId: string,
        providerId: string,
        providerMeetingId?: string | null,
        accessCode: string,
        status: MeetingRoomStatus,
        startDateTime?: string | null,
        endDateTime?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        roomCreatedAt?: string | null,
        roomDestroyedAt?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        isReadinessTest: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        meetingRoomProviderMeetingRoomsId?: string | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null,
      productId?: string | null,
      isReadinessTest: boolean,
      labels?: Array< string | null > | null,
      owner?: string | null,
      teamRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerOrganizationName?: string | null,
      sellerTeamName?: string | null,
      buyerTeamName?: string | null,
      sellerOrganizationNameLowerCase?: string | null,
      sellerTeamNameLowerCase?: string | null,
      buyerTeamNameLowerCase?: string | null,
      externalId?: string | null,
      metrics?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productMeetingsId?: string | null,
      meetingRoomProviderMeetingsId?: string | null,
      meetingRoomId?: string | null,
    },
    meetingId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileMeetingAttendeesId?: string | null,
    meetingMeetingAttendeesId?: string | null,
  } | null,
};

export type UpdateMeetingAttendeeMutationVariables = {
  input: UpdateMeetingAttendeeInput,
  condition?: ModelMeetingAttendeeConditionInput | null,
};

export type UpdateMeetingAttendeeMutation = {
  updateMeetingAttendee?:  {
    __typename: "MeetingAttendee",
    id: string,
    profile:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    },
    profileId: string,
    meeting:  {
      __typename: "Meeting",
      id: string,
      title: string,
      titleLowerCase?: string | null,
      startDateTime: string,
      endDateTime: string,
      status: MeetingStatus,
      description?: string | null,
      preferredRoomProvider?:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      preferredRoomProviderId?: string | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      room?:  {
        __typename: "MeetingRoom",
        id: string,
        name: string,
        meetingId: string,
        providerId: string,
        providerMeetingId?: string | null,
        accessCode: string,
        status: MeetingRoomStatus,
        startDateTime?: string | null,
        endDateTime?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        roomCreatedAt?: string | null,
        roomDestroyedAt?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        isReadinessTest: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        meetingRoomProviderMeetingRoomsId?: string | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null,
      productId?: string | null,
      isReadinessTest: boolean,
      labels?: Array< string | null > | null,
      owner?: string | null,
      teamRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerOrganizationName?: string | null,
      sellerTeamName?: string | null,
      buyerTeamName?: string | null,
      sellerOrganizationNameLowerCase?: string | null,
      sellerTeamNameLowerCase?: string | null,
      buyerTeamNameLowerCase?: string | null,
      externalId?: string | null,
      metrics?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productMeetingsId?: string | null,
      meetingRoomProviderMeetingsId?: string | null,
      meetingRoomId?: string | null,
    },
    meetingId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileMeetingAttendeesId?: string | null,
    meetingMeetingAttendeesId?: string | null,
  } | null,
};

export type DeleteMeetingAttendeeMutationVariables = {
  input: DeleteMeetingAttendeeInput,
  condition?: ModelMeetingAttendeeConditionInput | null,
};

export type DeleteMeetingAttendeeMutation = {
  deleteMeetingAttendee?:  {
    __typename: "MeetingAttendee",
    id: string,
    profile:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    },
    profileId: string,
    meeting:  {
      __typename: "Meeting",
      id: string,
      title: string,
      titleLowerCase?: string | null,
      startDateTime: string,
      endDateTime: string,
      status: MeetingStatus,
      description?: string | null,
      preferredRoomProvider?:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      preferredRoomProviderId?: string | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      room?:  {
        __typename: "MeetingRoom",
        id: string,
        name: string,
        meetingId: string,
        providerId: string,
        providerMeetingId?: string | null,
        accessCode: string,
        status: MeetingRoomStatus,
        startDateTime?: string | null,
        endDateTime?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        roomCreatedAt?: string | null,
        roomDestroyedAt?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        isReadinessTest: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        meetingRoomProviderMeetingRoomsId?: string | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null,
      productId?: string | null,
      isReadinessTest: boolean,
      labels?: Array< string | null > | null,
      owner?: string | null,
      teamRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerOrganizationName?: string | null,
      sellerTeamName?: string | null,
      buyerTeamName?: string | null,
      sellerOrganizationNameLowerCase?: string | null,
      sellerTeamNameLowerCase?: string | null,
      buyerTeamNameLowerCase?: string | null,
      externalId?: string | null,
      metrics?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productMeetingsId?: string | null,
      meetingRoomProviderMeetingsId?: string | null,
      meetingRoomId?: string | null,
    },
    meetingId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileMeetingAttendeesId?: string | null,
    meetingMeetingAttendeesId?: string | null,
  } | null,
};

export type CreateMeetingMutationVariables = {
  input: CreateMeetingInput,
  condition?: ModelMeetingConditionInput | null,
};

export type CreateMeetingMutation = {
  createMeeting?:  {
    __typename: "Meeting",
    id: string,
    title: string,
    titleLowerCase?: string | null,
    startDateTime: string,
    endDateTime: string,
    status: MeetingStatus,
    description?: string | null,
    preferredRoomProvider?:  {
      __typename: "MeetingRoomProvider",
      id: string,
      name: string,
      componentName?: string | null,
      description?: string | null,
      configuration?: string | null,
      meetings?:  {
        __typename: "ModelMeetingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingRooms?:  {
        __typename: "ModelMeetingRoomConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    preferredRoomProviderId?: string | null,
    actualStartDateTime?: string | null,
    actualEndDateTime?: string | null,
    room?:  {
      __typename: "MeetingRoom",
      id: string,
      name: string,
      meeting:  {
        __typename: "Meeting",
        id: string,
        title: string,
        titleLowerCase?: string | null,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        preferredRoomProviderId?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        sellerOrganizationNameLowerCase?: string | null,
        sellerTeamNameLowerCase?: string | null,
        buyerTeamNameLowerCase?: string | null,
        externalId?: string | null,
        metrics?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingsId?: string | null,
        meetingRoomProviderMeetingsId?: string | null,
        meetingRoomId?: string | null,
      },
      meetingId: string,
      provider:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      providerId: string,
      providerMeetingId?: string | null,
      accessCode: string,
      status: MeetingRoomStatus,
      startDateTime?: string | null,
      endDateTime?: string | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      roomCreatedAt?: string | null,
      roomDestroyedAt?: string | null,
      auditlogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      isReadinessTest: boolean,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      meetingRoomProviderMeetingRoomsId?: string | null,
    } | null,
    questionResponses?:  {
      __typename: "ModelQuestionResponseConnection",
      items:  Array< {
        __typename: "QuestionResponse",
        id: string,
        response?: string | null,
        privateNotes?: string | null,
        questionId: string,
        profileId: string,
        meetingId: string,
        meetingInviteId: string,
        owner?: string | null,
        contactRequestTimeframe?: string | null,
        contactRequestDateTime?: string | null,
        contactPhoneNumber?: string | null,
        contactEmail?: string | null,
        contactName?: string | null,
        providePersonalEmail?: boolean | null,
        providePersonalPhoneNumber?: boolean | null,
        textOnly?: boolean | null,
        followupMeetingRequested?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productQuestionResponsesId?: string | null,
        profileQuestionResponsesId?: string | null,
        meetingQuestionResponsesId?: string | null,
        meetingInviteQuestionResponsesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    privacyEnabled: boolean,
    videoEnabled: boolean,
    transcriptionEnabled: boolean,
    product?:  {
      __typename: "Product",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      serviceInformation?: string | null,
      images?:  {
        __typename: "ModelProductImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questions?:  {
        __typename: "ModelProductQuestionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      salesTeam?:  {
        __typename: "Team",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        labels?: Array< string | null > | null,
        privacyEnabled: boolean,
        privacyEnableOtherTeams?: boolean | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      salesTeamId?: string | null,
      meetings?:  {
        __typename: "ModelMeetingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProductsId?: string | null,
      teamProductsId?: string | null,
    } | null,
    productId?: string | null,
    isReadinessTest: boolean,
    labels?: Array< string | null > | null,
    owner?: string | null,
    teamRelationships?:  {
      __typename: "ModelTeamMeetingRelationshipConnection",
      items:  Array< {
        __typename: "TeamMeetingRelationship",
        id: string,
        teamId: string,
        meetingId: string,
        relationshipType: TeamMeetingRelationshipType,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        teamMeetingRelationshipsId?: string | null,
        meetingTeamRelationshipsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    meetingAttendees?:  {
      __typename: "ModelMeetingAttendeeConnection",
      items:  Array< {
        __typename: "MeetingAttendee",
        id: string,
        profileId: string,
        meetingId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileMeetingAttendeesId?: string | null,
        meetingMeetingAttendeesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    transcriptions?:  {
      __typename: "ModelTranscriptionConnection",
      items:  Array< {
        __typename: "Transcription",
        id: string,
        date: string,
        title: string,
        description?: string | null,
        startDateTime: string,
        endDateTime: string,
        sizeInBytes?: number | null,
        numberOfTranscriptions?: number | null,
        isZipFile: boolean,
        meetingId?: string | null,
        teamId?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        teamTranscriptionsId?: string | null,
        meetingTranscriptionsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    sellerOrganizationName?: string | null,
    sellerTeamName?: string | null,
    buyerTeamName?: string | null,
    sellerOrganizationNameLowerCase?: string | null,
    sellerTeamNameLowerCase?: string | null,
    buyerTeamNameLowerCase?: string | null,
    externalId?: string | null,
    metrics?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    productMeetingsId?: string | null,
    meetingRoomProviderMeetingsId?: string | null,
    meetingRoomId?: string | null,
  } | null,
};

export type UpdateMeetingMutationVariables = {
  input: UpdateMeetingInput,
  condition?: ModelMeetingConditionInput | null,
};

export type UpdateMeetingMutation = {
  updateMeeting?:  {
    __typename: "Meeting",
    id: string,
    title: string,
    titleLowerCase?: string | null,
    startDateTime: string,
    endDateTime: string,
    status: MeetingStatus,
    description?: string | null,
    preferredRoomProvider?:  {
      __typename: "MeetingRoomProvider",
      id: string,
      name: string,
      componentName?: string | null,
      description?: string | null,
      configuration?: string | null,
      meetings?:  {
        __typename: "ModelMeetingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingRooms?:  {
        __typename: "ModelMeetingRoomConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    preferredRoomProviderId?: string | null,
    actualStartDateTime?: string | null,
    actualEndDateTime?: string | null,
    room?:  {
      __typename: "MeetingRoom",
      id: string,
      name: string,
      meeting:  {
        __typename: "Meeting",
        id: string,
        title: string,
        titleLowerCase?: string | null,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        preferredRoomProviderId?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        sellerOrganizationNameLowerCase?: string | null,
        sellerTeamNameLowerCase?: string | null,
        buyerTeamNameLowerCase?: string | null,
        externalId?: string | null,
        metrics?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingsId?: string | null,
        meetingRoomProviderMeetingsId?: string | null,
        meetingRoomId?: string | null,
      },
      meetingId: string,
      provider:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      providerId: string,
      providerMeetingId?: string | null,
      accessCode: string,
      status: MeetingRoomStatus,
      startDateTime?: string | null,
      endDateTime?: string | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      roomCreatedAt?: string | null,
      roomDestroyedAt?: string | null,
      auditlogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      isReadinessTest: boolean,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      meetingRoomProviderMeetingRoomsId?: string | null,
    } | null,
    questionResponses?:  {
      __typename: "ModelQuestionResponseConnection",
      items:  Array< {
        __typename: "QuestionResponse",
        id: string,
        response?: string | null,
        privateNotes?: string | null,
        questionId: string,
        profileId: string,
        meetingId: string,
        meetingInviteId: string,
        owner?: string | null,
        contactRequestTimeframe?: string | null,
        contactRequestDateTime?: string | null,
        contactPhoneNumber?: string | null,
        contactEmail?: string | null,
        contactName?: string | null,
        providePersonalEmail?: boolean | null,
        providePersonalPhoneNumber?: boolean | null,
        textOnly?: boolean | null,
        followupMeetingRequested?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productQuestionResponsesId?: string | null,
        profileQuestionResponsesId?: string | null,
        meetingQuestionResponsesId?: string | null,
        meetingInviteQuestionResponsesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    privacyEnabled: boolean,
    videoEnabled: boolean,
    transcriptionEnabled: boolean,
    product?:  {
      __typename: "Product",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      serviceInformation?: string | null,
      images?:  {
        __typename: "ModelProductImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questions?:  {
        __typename: "ModelProductQuestionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      salesTeam?:  {
        __typename: "Team",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        labels?: Array< string | null > | null,
        privacyEnabled: boolean,
        privacyEnableOtherTeams?: boolean | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      salesTeamId?: string | null,
      meetings?:  {
        __typename: "ModelMeetingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProductsId?: string | null,
      teamProductsId?: string | null,
    } | null,
    productId?: string | null,
    isReadinessTest: boolean,
    labels?: Array< string | null > | null,
    owner?: string | null,
    teamRelationships?:  {
      __typename: "ModelTeamMeetingRelationshipConnection",
      items:  Array< {
        __typename: "TeamMeetingRelationship",
        id: string,
        teamId: string,
        meetingId: string,
        relationshipType: TeamMeetingRelationshipType,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        teamMeetingRelationshipsId?: string | null,
        meetingTeamRelationshipsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    meetingAttendees?:  {
      __typename: "ModelMeetingAttendeeConnection",
      items:  Array< {
        __typename: "MeetingAttendee",
        id: string,
        profileId: string,
        meetingId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileMeetingAttendeesId?: string | null,
        meetingMeetingAttendeesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    transcriptions?:  {
      __typename: "ModelTranscriptionConnection",
      items:  Array< {
        __typename: "Transcription",
        id: string,
        date: string,
        title: string,
        description?: string | null,
        startDateTime: string,
        endDateTime: string,
        sizeInBytes?: number | null,
        numberOfTranscriptions?: number | null,
        isZipFile: boolean,
        meetingId?: string | null,
        teamId?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        teamTranscriptionsId?: string | null,
        meetingTranscriptionsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    sellerOrganizationName?: string | null,
    sellerTeamName?: string | null,
    buyerTeamName?: string | null,
    sellerOrganizationNameLowerCase?: string | null,
    sellerTeamNameLowerCase?: string | null,
    buyerTeamNameLowerCase?: string | null,
    externalId?: string | null,
    metrics?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    productMeetingsId?: string | null,
    meetingRoomProviderMeetingsId?: string | null,
    meetingRoomId?: string | null,
  } | null,
};

export type DeleteMeetingMutationVariables = {
  input: DeleteMeetingInput,
  condition?: ModelMeetingConditionInput | null,
};

export type DeleteMeetingMutation = {
  deleteMeeting?:  {
    __typename: "Meeting",
    id: string,
    title: string,
    titleLowerCase?: string | null,
    startDateTime: string,
    endDateTime: string,
    status: MeetingStatus,
    description?: string | null,
    preferredRoomProvider?:  {
      __typename: "MeetingRoomProvider",
      id: string,
      name: string,
      componentName?: string | null,
      description?: string | null,
      configuration?: string | null,
      meetings?:  {
        __typename: "ModelMeetingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingRooms?:  {
        __typename: "ModelMeetingRoomConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    preferredRoomProviderId?: string | null,
    actualStartDateTime?: string | null,
    actualEndDateTime?: string | null,
    room?:  {
      __typename: "MeetingRoom",
      id: string,
      name: string,
      meeting:  {
        __typename: "Meeting",
        id: string,
        title: string,
        titleLowerCase?: string | null,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        preferredRoomProviderId?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        sellerOrganizationNameLowerCase?: string | null,
        sellerTeamNameLowerCase?: string | null,
        buyerTeamNameLowerCase?: string | null,
        externalId?: string | null,
        metrics?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingsId?: string | null,
        meetingRoomProviderMeetingsId?: string | null,
        meetingRoomId?: string | null,
      },
      meetingId: string,
      provider:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      providerId: string,
      providerMeetingId?: string | null,
      accessCode: string,
      status: MeetingRoomStatus,
      startDateTime?: string | null,
      endDateTime?: string | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      roomCreatedAt?: string | null,
      roomDestroyedAt?: string | null,
      auditlogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      isReadinessTest: boolean,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      meetingRoomProviderMeetingRoomsId?: string | null,
    } | null,
    questionResponses?:  {
      __typename: "ModelQuestionResponseConnection",
      items:  Array< {
        __typename: "QuestionResponse",
        id: string,
        response?: string | null,
        privateNotes?: string | null,
        questionId: string,
        profileId: string,
        meetingId: string,
        meetingInviteId: string,
        owner?: string | null,
        contactRequestTimeframe?: string | null,
        contactRequestDateTime?: string | null,
        contactPhoneNumber?: string | null,
        contactEmail?: string | null,
        contactName?: string | null,
        providePersonalEmail?: boolean | null,
        providePersonalPhoneNumber?: boolean | null,
        textOnly?: boolean | null,
        followupMeetingRequested?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productQuestionResponsesId?: string | null,
        profileQuestionResponsesId?: string | null,
        meetingQuestionResponsesId?: string | null,
        meetingInviteQuestionResponsesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    privacyEnabled: boolean,
    videoEnabled: boolean,
    transcriptionEnabled: boolean,
    product?:  {
      __typename: "Product",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      serviceInformation?: string | null,
      images?:  {
        __typename: "ModelProductImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questions?:  {
        __typename: "ModelProductQuestionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      salesTeam?:  {
        __typename: "Team",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        labels?: Array< string | null > | null,
        privacyEnabled: boolean,
        privacyEnableOtherTeams?: boolean | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      salesTeamId?: string | null,
      meetings?:  {
        __typename: "ModelMeetingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProductsId?: string | null,
      teamProductsId?: string | null,
    } | null,
    productId?: string | null,
    isReadinessTest: boolean,
    labels?: Array< string | null > | null,
    owner?: string | null,
    teamRelationships?:  {
      __typename: "ModelTeamMeetingRelationshipConnection",
      items:  Array< {
        __typename: "TeamMeetingRelationship",
        id: string,
        teamId: string,
        meetingId: string,
        relationshipType: TeamMeetingRelationshipType,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        teamMeetingRelationshipsId?: string | null,
        meetingTeamRelationshipsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    meetingAttendees?:  {
      __typename: "ModelMeetingAttendeeConnection",
      items:  Array< {
        __typename: "MeetingAttendee",
        id: string,
        profileId: string,
        meetingId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileMeetingAttendeesId?: string | null,
        meetingMeetingAttendeesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    transcriptions?:  {
      __typename: "ModelTranscriptionConnection",
      items:  Array< {
        __typename: "Transcription",
        id: string,
        date: string,
        title: string,
        description?: string | null,
        startDateTime: string,
        endDateTime: string,
        sizeInBytes?: number | null,
        numberOfTranscriptions?: number | null,
        isZipFile: boolean,
        meetingId?: string | null,
        teamId?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        teamTranscriptionsId?: string | null,
        meetingTranscriptionsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    sellerOrganizationName?: string | null,
    sellerTeamName?: string | null,
    buyerTeamName?: string | null,
    sellerOrganizationNameLowerCase?: string | null,
    sellerTeamNameLowerCase?: string | null,
    buyerTeamNameLowerCase?: string | null,
    externalId?: string | null,
    metrics?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    productMeetingsId?: string | null,
    meetingRoomProviderMeetingsId?: string | null,
    meetingRoomId?: string | null,
  } | null,
};

export type CreateMeetingInviteMutationVariables = {
  input: CreateMeetingInviteInput,
  condition?: ModelMeetingInviteConditionInput | null,
};

export type CreateMeetingInviteMutation = {
  createMeetingInvite?:  {
    __typename: "MeetingInvite",
    id: string,
    meetingId: string,
    provider?:  {
      __typename: "MeetingRoomProvider",
      id: string,
      name: string,
      componentName?: string | null,
      description?: string | null,
      configuration?: string | null,
      meetings?:  {
        __typename: "ModelMeetingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingRooms?:  {
        __typename: "ModelMeetingRoomConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    providerId?: string | null,
    title: string,
    startDateTime: string,
    endDateTime: string,
    status: MeetingStatus,
    description?: string | null,
    questionResponses?:  {
      __typename: "ModelQuestionResponseConnection",
      items:  Array< {
        __typename: "QuestionResponse",
        id: string,
        response?: string | null,
        privateNotes?: string | null,
        questionId: string,
        profileId: string,
        meetingId: string,
        meetingInviteId: string,
        owner?: string | null,
        contactRequestTimeframe?: string | null,
        contactRequestDateTime?: string | null,
        contactPhoneNumber?: string | null,
        contactEmail?: string | null,
        contactName?: string | null,
        providePersonalEmail?: boolean | null,
        providePersonalPhoneNumber?: boolean | null,
        textOnly?: boolean | null,
        followupMeetingRequested?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productQuestionResponsesId?: string | null,
        profileQuestionResponsesId?: string | null,
        meetingQuestionResponsesId?: string | null,
        meetingInviteQuestionResponsesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    privacyEnabled: boolean,
    videoEnabled: boolean,
    transcriptionEnabled: boolean,
    product?:  {
      __typename: "Product",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      serviceInformation?: string | null,
      images?:  {
        __typename: "ModelProductImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questions?:  {
        __typename: "ModelProductQuestionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      salesTeam?:  {
        __typename: "Team",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        labels?: Array< string | null > | null,
        privacyEnabled: boolean,
        privacyEnableOtherTeams?: boolean | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      salesTeamId?: string | null,
      meetings?:  {
        __typename: "ModelMeetingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProductsId?: string | null,
      teamProductsId?: string | null,
    } | null,
    productId?: string | null,
    isReadinessTest: boolean,
    meetingRoomId?: string | null,
    providerSessionId?: string | null,
    providerJoinData?: string | null,
    sessionStatus?: MeetingRoomSessionStatus | null,
    actualStartDateTime?: string | null,
    actualEndDateTime?: string | null,
    profile:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    },
    profileId: string,
    owner?: string | null,
    meetingInvitationType: MeetingInvitationType,
    teamId?: string | null,
    isInterested?: boolean | null,
    feedbackSummary?: string | null,
    contactRequestTimeframe?: string | null,
    contactPhoneNumber?: string | null,
    contactEmail?: string | null,
    sellerOrganizationName?: string | null,
    sellerTeamName?: string | null,
    buyerTeamName?: string | null,
    transcriptionLogs?:  {
      __typename: "ModelTranscriptionEventAuditLogConnection",
      items:  Array< {
        __typename: "TranscriptionEventAuditLog",
        id: string,
        date: string,
        author: string,
        resource: string,
        action: string,
        payload?: string | null,
        ipAddress?: string | null,
        meetingInviteId: string,
        profileId: string,
        authorProfileId?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileTranscriptionLogsId?: string | null,
        meetingInviteTranscriptionLogsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    productMeetingInvitesId?: string | null,
    profileMeetingInvitesId?: string | null,
    meetingRoomProviderMeetingInvitesId?: string | null,
  } | null,
};

export type UpdateMeetingInviteMutationVariables = {
  input: UpdateMeetingInviteInput,
  condition?: ModelMeetingInviteConditionInput | null,
};

export type UpdateMeetingInviteMutation = {
  updateMeetingInvite?:  {
    __typename: "MeetingInvite",
    id: string,
    meetingId: string,
    provider?:  {
      __typename: "MeetingRoomProvider",
      id: string,
      name: string,
      componentName?: string | null,
      description?: string | null,
      configuration?: string | null,
      meetings?:  {
        __typename: "ModelMeetingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingRooms?:  {
        __typename: "ModelMeetingRoomConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    providerId?: string | null,
    title: string,
    startDateTime: string,
    endDateTime: string,
    status: MeetingStatus,
    description?: string | null,
    questionResponses?:  {
      __typename: "ModelQuestionResponseConnection",
      items:  Array< {
        __typename: "QuestionResponse",
        id: string,
        response?: string | null,
        privateNotes?: string | null,
        questionId: string,
        profileId: string,
        meetingId: string,
        meetingInviteId: string,
        owner?: string | null,
        contactRequestTimeframe?: string | null,
        contactRequestDateTime?: string | null,
        contactPhoneNumber?: string | null,
        contactEmail?: string | null,
        contactName?: string | null,
        providePersonalEmail?: boolean | null,
        providePersonalPhoneNumber?: boolean | null,
        textOnly?: boolean | null,
        followupMeetingRequested?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productQuestionResponsesId?: string | null,
        profileQuestionResponsesId?: string | null,
        meetingQuestionResponsesId?: string | null,
        meetingInviteQuestionResponsesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    privacyEnabled: boolean,
    videoEnabled: boolean,
    transcriptionEnabled: boolean,
    product?:  {
      __typename: "Product",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      serviceInformation?: string | null,
      images?:  {
        __typename: "ModelProductImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questions?:  {
        __typename: "ModelProductQuestionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      salesTeam?:  {
        __typename: "Team",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        labels?: Array< string | null > | null,
        privacyEnabled: boolean,
        privacyEnableOtherTeams?: boolean | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      salesTeamId?: string | null,
      meetings?:  {
        __typename: "ModelMeetingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProductsId?: string | null,
      teamProductsId?: string | null,
    } | null,
    productId?: string | null,
    isReadinessTest: boolean,
    meetingRoomId?: string | null,
    providerSessionId?: string | null,
    providerJoinData?: string | null,
    sessionStatus?: MeetingRoomSessionStatus | null,
    actualStartDateTime?: string | null,
    actualEndDateTime?: string | null,
    profile:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    },
    profileId: string,
    owner?: string | null,
    meetingInvitationType: MeetingInvitationType,
    teamId?: string | null,
    isInterested?: boolean | null,
    feedbackSummary?: string | null,
    contactRequestTimeframe?: string | null,
    contactPhoneNumber?: string | null,
    contactEmail?: string | null,
    sellerOrganizationName?: string | null,
    sellerTeamName?: string | null,
    buyerTeamName?: string | null,
    transcriptionLogs?:  {
      __typename: "ModelTranscriptionEventAuditLogConnection",
      items:  Array< {
        __typename: "TranscriptionEventAuditLog",
        id: string,
        date: string,
        author: string,
        resource: string,
        action: string,
        payload?: string | null,
        ipAddress?: string | null,
        meetingInviteId: string,
        profileId: string,
        authorProfileId?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileTranscriptionLogsId?: string | null,
        meetingInviteTranscriptionLogsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    productMeetingInvitesId?: string | null,
    profileMeetingInvitesId?: string | null,
    meetingRoomProviderMeetingInvitesId?: string | null,
  } | null,
};

export type DeleteMeetingInviteMutationVariables = {
  input: DeleteMeetingInviteInput,
  condition?: ModelMeetingInviteConditionInput | null,
};

export type DeleteMeetingInviteMutation = {
  deleteMeetingInvite?:  {
    __typename: "MeetingInvite",
    id: string,
    meetingId: string,
    provider?:  {
      __typename: "MeetingRoomProvider",
      id: string,
      name: string,
      componentName?: string | null,
      description?: string | null,
      configuration?: string | null,
      meetings?:  {
        __typename: "ModelMeetingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingRooms?:  {
        __typename: "ModelMeetingRoomConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    providerId?: string | null,
    title: string,
    startDateTime: string,
    endDateTime: string,
    status: MeetingStatus,
    description?: string | null,
    questionResponses?:  {
      __typename: "ModelQuestionResponseConnection",
      items:  Array< {
        __typename: "QuestionResponse",
        id: string,
        response?: string | null,
        privateNotes?: string | null,
        questionId: string,
        profileId: string,
        meetingId: string,
        meetingInviteId: string,
        owner?: string | null,
        contactRequestTimeframe?: string | null,
        contactRequestDateTime?: string | null,
        contactPhoneNumber?: string | null,
        contactEmail?: string | null,
        contactName?: string | null,
        providePersonalEmail?: boolean | null,
        providePersonalPhoneNumber?: boolean | null,
        textOnly?: boolean | null,
        followupMeetingRequested?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productQuestionResponsesId?: string | null,
        profileQuestionResponsesId?: string | null,
        meetingQuestionResponsesId?: string | null,
        meetingInviteQuestionResponsesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    privacyEnabled: boolean,
    videoEnabled: boolean,
    transcriptionEnabled: boolean,
    product?:  {
      __typename: "Product",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      serviceInformation?: string | null,
      images?:  {
        __typename: "ModelProductImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questions?:  {
        __typename: "ModelProductQuestionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      salesTeam?:  {
        __typename: "Team",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        labels?: Array< string | null > | null,
        privacyEnabled: boolean,
        privacyEnableOtherTeams?: boolean | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      salesTeamId?: string | null,
      meetings?:  {
        __typename: "ModelMeetingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProductsId?: string | null,
      teamProductsId?: string | null,
    } | null,
    productId?: string | null,
    isReadinessTest: boolean,
    meetingRoomId?: string | null,
    providerSessionId?: string | null,
    providerJoinData?: string | null,
    sessionStatus?: MeetingRoomSessionStatus | null,
    actualStartDateTime?: string | null,
    actualEndDateTime?: string | null,
    profile:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    },
    profileId: string,
    owner?: string | null,
    meetingInvitationType: MeetingInvitationType,
    teamId?: string | null,
    isInterested?: boolean | null,
    feedbackSummary?: string | null,
    contactRequestTimeframe?: string | null,
    contactPhoneNumber?: string | null,
    contactEmail?: string | null,
    sellerOrganizationName?: string | null,
    sellerTeamName?: string | null,
    buyerTeamName?: string | null,
    transcriptionLogs?:  {
      __typename: "ModelTranscriptionEventAuditLogConnection",
      items:  Array< {
        __typename: "TranscriptionEventAuditLog",
        id: string,
        date: string,
        author: string,
        resource: string,
        action: string,
        payload?: string | null,
        ipAddress?: string | null,
        meetingInviteId: string,
        profileId: string,
        authorProfileId?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileTranscriptionLogsId?: string | null,
        meetingInviteTranscriptionLogsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    productMeetingInvitesId?: string | null,
    profileMeetingInvitesId?: string | null,
    meetingRoomProviderMeetingInvitesId?: string | null,
  } | null,
};

export type CreateMeetingRoomMutationVariables = {
  input: CreateMeetingRoomInput,
  condition?: ModelMeetingRoomConditionInput | null,
};

export type CreateMeetingRoomMutation = {
  createMeetingRoom?:  {
    __typename: "MeetingRoom",
    id: string,
    name: string,
    meeting:  {
      __typename: "Meeting",
      id: string,
      title: string,
      titleLowerCase?: string | null,
      startDateTime: string,
      endDateTime: string,
      status: MeetingStatus,
      description?: string | null,
      preferredRoomProvider?:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      preferredRoomProviderId?: string | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      room?:  {
        __typename: "MeetingRoom",
        id: string,
        name: string,
        meetingId: string,
        providerId: string,
        providerMeetingId?: string | null,
        accessCode: string,
        status: MeetingRoomStatus,
        startDateTime?: string | null,
        endDateTime?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        roomCreatedAt?: string | null,
        roomDestroyedAt?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        isReadinessTest: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        meetingRoomProviderMeetingRoomsId?: string | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null,
      productId?: string | null,
      isReadinessTest: boolean,
      labels?: Array< string | null > | null,
      owner?: string | null,
      teamRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerOrganizationName?: string | null,
      sellerTeamName?: string | null,
      buyerTeamName?: string | null,
      sellerOrganizationNameLowerCase?: string | null,
      sellerTeamNameLowerCase?: string | null,
      buyerTeamNameLowerCase?: string | null,
      externalId?: string | null,
      metrics?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productMeetingsId?: string | null,
      meetingRoomProviderMeetingsId?: string | null,
      meetingRoomId?: string | null,
    },
    meetingId: string,
    provider:  {
      __typename: "MeetingRoomProvider",
      id: string,
      name: string,
      componentName?: string | null,
      description?: string | null,
      configuration?: string | null,
      meetings?:  {
        __typename: "ModelMeetingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingRooms?:  {
        __typename: "ModelMeetingRoomConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    providerId: string,
    providerMeetingId?: string | null,
    accessCode: string,
    status: MeetingRoomStatus,
    startDateTime?: string | null,
    endDateTime?: string | null,
    actualStartDateTime?: string | null,
    actualEndDateTime?: string | null,
    roomCreatedAt?: string | null,
    roomDestroyedAt?: string | null,
    auditlogs?:  {
      __typename: "ModelMeetingRoomEventAuditLogConnection",
      items:  Array< {
        __typename: "MeetingRoomEventAuditLog",
        id: string,
        date: string,
        author: string,
        resource: string,
        action: string,
        payload?: string | null,
        ipAddress?: string | null,
        meetingRoomId: string,
        profileId?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileAuditLogsId?: string | null,
        meetingRoomAuditlogsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    privacyEnabled: boolean,
    videoEnabled: boolean,
    transcriptionEnabled: boolean,
    isReadinessTest: boolean,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    meetingRoomProviderMeetingRoomsId?: string | null,
  } | null,
};

export type UpdateMeetingRoomMutationVariables = {
  input: UpdateMeetingRoomInput,
  condition?: ModelMeetingRoomConditionInput | null,
};

export type UpdateMeetingRoomMutation = {
  updateMeetingRoom?:  {
    __typename: "MeetingRoom",
    id: string,
    name: string,
    meeting:  {
      __typename: "Meeting",
      id: string,
      title: string,
      titleLowerCase?: string | null,
      startDateTime: string,
      endDateTime: string,
      status: MeetingStatus,
      description?: string | null,
      preferredRoomProvider?:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      preferredRoomProviderId?: string | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      room?:  {
        __typename: "MeetingRoom",
        id: string,
        name: string,
        meetingId: string,
        providerId: string,
        providerMeetingId?: string | null,
        accessCode: string,
        status: MeetingRoomStatus,
        startDateTime?: string | null,
        endDateTime?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        roomCreatedAt?: string | null,
        roomDestroyedAt?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        isReadinessTest: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        meetingRoomProviderMeetingRoomsId?: string | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null,
      productId?: string | null,
      isReadinessTest: boolean,
      labels?: Array< string | null > | null,
      owner?: string | null,
      teamRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerOrganizationName?: string | null,
      sellerTeamName?: string | null,
      buyerTeamName?: string | null,
      sellerOrganizationNameLowerCase?: string | null,
      sellerTeamNameLowerCase?: string | null,
      buyerTeamNameLowerCase?: string | null,
      externalId?: string | null,
      metrics?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productMeetingsId?: string | null,
      meetingRoomProviderMeetingsId?: string | null,
      meetingRoomId?: string | null,
    },
    meetingId: string,
    provider:  {
      __typename: "MeetingRoomProvider",
      id: string,
      name: string,
      componentName?: string | null,
      description?: string | null,
      configuration?: string | null,
      meetings?:  {
        __typename: "ModelMeetingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingRooms?:  {
        __typename: "ModelMeetingRoomConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    providerId: string,
    providerMeetingId?: string | null,
    accessCode: string,
    status: MeetingRoomStatus,
    startDateTime?: string | null,
    endDateTime?: string | null,
    actualStartDateTime?: string | null,
    actualEndDateTime?: string | null,
    roomCreatedAt?: string | null,
    roomDestroyedAt?: string | null,
    auditlogs?:  {
      __typename: "ModelMeetingRoomEventAuditLogConnection",
      items:  Array< {
        __typename: "MeetingRoomEventAuditLog",
        id: string,
        date: string,
        author: string,
        resource: string,
        action: string,
        payload?: string | null,
        ipAddress?: string | null,
        meetingRoomId: string,
        profileId?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileAuditLogsId?: string | null,
        meetingRoomAuditlogsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    privacyEnabled: boolean,
    videoEnabled: boolean,
    transcriptionEnabled: boolean,
    isReadinessTest: boolean,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    meetingRoomProviderMeetingRoomsId?: string | null,
  } | null,
};

export type DeleteMeetingRoomMutationVariables = {
  input: DeleteMeetingRoomInput,
  condition?: ModelMeetingRoomConditionInput | null,
};

export type DeleteMeetingRoomMutation = {
  deleteMeetingRoom?:  {
    __typename: "MeetingRoom",
    id: string,
    name: string,
    meeting:  {
      __typename: "Meeting",
      id: string,
      title: string,
      titleLowerCase?: string | null,
      startDateTime: string,
      endDateTime: string,
      status: MeetingStatus,
      description?: string | null,
      preferredRoomProvider?:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      preferredRoomProviderId?: string | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      room?:  {
        __typename: "MeetingRoom",
        id: string,
        name: string,
        meetingId: string,
        providerId: string,
        providerMeetingId?: string | null,
        accessCode: string,
        status: MeetingRoomStatus,
        startDateTime?: string | null,
        endDateTime?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        roomCreatedAt?: string | null,
        roomDestroyedAt?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        isReadinessTest: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        meetingRoomProviderMeetingRoomsId?: string | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null,
      productId?: string | null,
      isReadinessTest: boolean,
      labels?: Array< string | null > | null,
      owner?: string | null,
      teamRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerOrganizationName?: string | null,
      sellerTeamName?: string | null,
      buyerTeamName?: string | null,
      sellerOrganizationNameLowerCase?: string | null,
      sellerTeamNameLowerCase?: string | null,
      buyerTeamNameLowerCase?: string | null,
      externalId?: string | null,
      metrics?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productMeetingsId?: string | null,
      meetingRoomProviderMeetingsId?: string | null,
      meetingRoomId?: string | null,
    },
    meetingId: string,
    provider:  {
      __typename: "MeetingRoomProvider",
      id: string,
      name: string,
      componentName?: string | null,
      description?: string | null,
      configuration?: string | null,
      meetings?:  {
        __typename: "ModelMeetingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingRooms?:  {
        __typename: "ModelMeetingRoomConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    providerId: string,
    providerMeetingId?: string | null,
    accessCode: string,
    status: MeetingRoomStatus,
    startDateTime?: string | null,
    endDateTime?: string | null,
    actualStartDateTime?: string | null,
    actualEndDateTime?: string | null,
    roomCreatedAt?: string | null,
    roomDestroyedAt?: string | null,
    auditlogs?:  {
      __typename: "ModelMeetingRoomEventAuditLogConnection",
      items:  Array< {
        __typename: "MeetingRoomEventAuditLog",
        id: string,
        date: string,
        author: string,
        resource: string,
        action: string,
        payload?: string | null,
        ipAddress?: string | null,
        meetingRoomId: string,
        profileId?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileAuditLogsId?: string | null,
        meetingRoomAuditlogsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    privacyEnabled: boolean,
    videoEnabled: boolean,
    transcriptionEnabled: boolean,
    isReadinessTest: boolean,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    meetingRoomProviderMeetingRoomsId?: string | null,
  } | null,
};

export type CreateMeetingRoomEventAuditLogMutationVariables = {
  input: CreateMeetingRoomEventAuditLogInput,
  condition?: ModelMeetingRoomEventAuditLogConditionInput | null,
};

export type CreateMeetingRoomEventAuditLogMutation = {
  createMeetingRoomEventAuditLog?:  {
    __typename: "MeetingRoomEventAuditLog",
    id: string,
    date: string,
    author: string,
    resource: string,
    action: string,
    payload?: string | null,
    ipAddress?: string | null,
    meetingRoom:  {
      __typename: "MeetingRoom",
      id: string,
      name: string,
      meeting:  {
        __typename: "Meeting",
        id: string,
        title: string,
        titleLowerCase?: string | null,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        preferredRoomProviderId?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        sellerOrganizationNameLowerCase?: string | null,
        sellerTeamNameLowerCase?: string | null,
        buyerTeamNameLowerCase?: string | null,
        externalId?: string | null,
        metrics?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingsId?: string | null,
        meetingRoomProviderMeetingsId?: string | null,
        meetingRoomId?: string | null,
      },
      meetingId: string,
      provider:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      providerId: string,
      providerMeetingId?: string | null,
      accessCode: string,
      status: MeetingRoomStatus,
      startDateTime?: string | null,
      endDateTime?: string | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      roomCreatedAt?: string | null,
      roomDestroyedAt?: string | null,
      auditlogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      isReadinessTest: boolean,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      meetingRoomProviderMeetingRoomsId?: string | null,
    },
    meetingRoomId: string,
    profile?:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    } | null,
    profileId?: string | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileAuditLogsId?: string | null,
    meetingRoomAuditlogsId?: string | null,
  } | null,
};

export type UpdateMeetingRoomEventAuditLogMutationVariables = {
  input: UpdateMeetingRoomEventAuditLogInput,
  condition?: ModelMeetingRoomEventAuditLogConditionInput | null,
};

export type UpdateMeetingRoomEventAuditLogMutation = {
  updateMeetingRoomEventAuditLog?:  {
    __typename: "MeetingRoomEventAuditLog",
    id: string,
    date: string,
    author: string,
    resource: string,
    action: string,
    payload?: string | null,
    ipAddress?: string | null,
    meetingRoom:  {
      __typename: "MeetingRoom",
      id: string,
      name: string,
      meeting:  {
        __typename: "Meeting",
        id: string,
        title: string,
        titleLowerCase?: string | null,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        preferredRoomProviderId?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        sellerOrganizationNameLowerCase?: string | null,
        sellerTeamNameLowerCase?: string | null,
        buyerTeamNameLowerCase?: string | null,
        externalId?: string | null,
        metrics?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingsId?: string | null,
        meetingRoomProviderMeetingsId?: string | null,
        meetingRoomId?: string | null,
      },
      meetingId: string,
      provider:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      providerId: string,
      providerMeetingId?: string | null,
      accessCode: string,
      status: MeetingRoomStatus,
      startDateTime?: string | null,
      endDateTime?: string | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      roomCreatedAt?: string | null,
      roomDestroyedAt?: string | null,
      auditlogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      isReadinessTest: boolean,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      meetingRoomProviderMeetingRoomsId?: string | null,
    },
    meetingRoomId: string,
    profile?:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    } | null,
    profileId?: string | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileAuditLogsId?: string | null,
    meetingRoomAuditlogsId?: string | null,
  } | null,
};

export type DeleteMeetingRoomEventAuditLogMutationVariables = {
  input: DeleteMeetingRoomEventAuditLogInput,
  condition?: ModelMeetingRoomEventAuditLogConditionInput | null,
};

export type DeleteMeetingRoomEventAuditLogMutation = {
  deleteMeetingRoomEventAuditLog?:  {
    __typename: "MeetingRoomEventAuditLog",
    id: string,
    date: string,
    author: string,
    resource: string,
    action: string,
    payload?: string | null,
    ipAddress?: string | null,
    meetingRoom:  {
      __typename: "MeetingRoom",
      id: string,
      name: string,
      meeting:  {
        __typename: "Meeting",
        id: string,
        title: string,
        titleLowerCase?: string | null,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        preferredRoomProviderId?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        sellerOrganizationNameLowerCase?: string | null,
        sellerTeamNameLowerCase?: string | null,
        buyerTeamNameLowerCase?: string | null,
        externalId?: string | null,
        metrics?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingsId?: string | null,
        meetingRoomProviderMeetingsId?: string | null,
        meetingRoomId?: string | null,
      },
      meetingId: string,
      provider:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      providerId: string,
      providerMeetingId?: string | null,
      accessCode: string,
      status: MeetingRoomStatus,
      startDateTime?: string | null,
      endDateTime?: string | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      roomCreatedAt?: string | null,
      roomDestroyedAt?: string | null,
      auditlogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      isReadinessTest: boolean,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      meetingRoomProviderMeetingRoomsId?: string | null,
    },
    meetingRoomId: string,
    profile?:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    } | null,
    profileId?: string | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileAuditLogsId?: string | null,
    meetingRoomAuditlogsId?: string | null,
  } | null,
};

export type CreateExportJobMutationVariables = {
  input: CreateExportJobInput,
  condition?: ModelExportJobConditionInput | null,
};

export type CreateExportJobMutation = {
  createExportJob?:  {
    __typename: "ExportJob",
    id: string,
    status: JobStatus,
    profile?:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    } | null,
    profileId?: string | null,
    auditLogs?:  {
      __typename: "ModelExportJobAuditLogConnection",
      items:  Array< {
        __typename: "ExportJobAuditLog",
        id: string,
        date: string,
        author: string,
        resource: string,
        action: string,
        payload?: string | null,
        ipAddress?: string | null,
        exportJobId: string,
        profileId?: string | null,
        owner: string,
        identityId?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileExportJobAuditLogsId?: string | null,
        exportJobAuditLogsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    meetingInvitationType?: MeetingInvitationType | null,
    startDateTime: string,
    endDateTime: string,
    owner: string,
    identityId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileExportJobsId?: string | null,
  } | null,
};

export type UpdateExportJobMutationVariables = {
  input: UpdateExportJobInput,
  condition?: ModelExportJobConditionInput | null,
};

export type UpdateExportJobMutation = {
  updateExportJob?:  {
    __typename: "ExportJob",
    id: string,
    status: JobStatus,
    profile?:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    } | null,
    profileId?: string | null,
    auditLogs?:  {
      __typename: "ModelExportJobAuditLogConnection",
      items:  Array< {
        __typename: "ExportJobAuditLog",
        id: string,
        date: string,
        author: string,
        resource: string,
        action: string,
        payload?: string | null,
        ipAddress?: string | null,
        exportJobId: string,
        profileId?: string | null,
        owner: string,
        identityId?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileExportJobAuditLogsId?: string | null,
        exportJobAuditLogsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    meetingInvitationType?: MeetingInvitationType | null,
    startDateTime: string,
    endDateTime: string,
    owner: string,
    identityId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileExportJobsId?: string | null,
  } | null,
};

export type DeleteExportJobMutationVariables = {
  input: DeleteExportJobInput,
  condition?: ModelExportJobConditionInput | null,
};

export type DeleteExportJobMutation = {
  deleteExportJob?:  {
    __typename: "ExportJob",
    id: string,
    status: JobStatus,
    profile?:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    } | null,
    profileId?: string | null,
    auditLogs?:  {
      __typename: "ModelExportJobAuditLogConnection",
      items:  Array< {
        __typename: "ExportJobAuditLog",
        id: string,
        date: string,
        author: string,
        resource: string,
        action: string,
        payload?: string | null,
        ipAddress?: string | null,
        exportJobId: string,
        profileId?: string | null,
        owner: string,
        identityId?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileExportJobAuditLogsId?: string | null,
        exportJobAuditLogsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    meetingInvitationType?: MeetingInvitationType | null,
    startDateTime: string,
    endDateTime: string,
    owner: string,
    identityId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileExportJobsId?: string | null,
  } | null,
};

export type CreateExportJobAuditLogMutationVariables = {
  input: CreateExportJobAuditLogInput,
  condition?: ModelExportJobAuditLogConditionInput | null,
};

export type CreateExportJobAuditLogMutation = {
  createExportJobAuditLog?:  {
    __typename: "ExportJobAuditLog",
    id: string,
    date: string,
    author: string,
    resource: string,
    action: string,
    payload?: string | null,
    ipAddress?: string | null,
    exportJob:  {
      __typename: "ExportJob",
      id: string,
      status: JobStatus,
      profile?:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      } | null,
      profileId?: string | null,
      auditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingInvitationType?: MeetingInvitationType | null,
      startDateTime: string,
      endDateTime: string,
      owner: string,
      identityId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      profileExportJobsId?: string | null,
    },
    exportJobId: string,
    profile?:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    } | null,
    profileId?: string | null,
    owner: string,
    source?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    identityId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileExportJobAuditLogsId?: string | null,
    exportJobAuditLogsId?: string | null,
  } | null,
};

export type UpdateExportJobAuditLogMutationVariables = {
  input: UpdateExportJobAuditLogInput,
  condition?: ModelExportJobAuditLogConditionInput | null,
};

export type UpdateExportJobAuditLogMutation = {
  updateExportJobAuditLog?:  {
    __typename: "ExportJobAuditLog",
    id: string,
    date: string,
    author: string,
    resource: string,
    action: string,
    payload?: string | null,
    ipAddress?: string | null,
    exportJob:  {
      __typename: "ExportJob",
      id: string,
      status: JobStatus,
      profile?:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      } | null,
      profileId?: string | null,
      auditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingInvitationType?: MeetingInvitationType | null,
      startDateTime: string,
      endDateTime: string,
      owner: string,
      identityId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      profileExportJobsId?: string | null,
    },
    exportJobId: string,
    profile?:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    } | null,
    profileId?: string | null,
    owner: string,
    source?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    identityId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileExportJobAuditLogsId?: string | null,
    exportJobAuditLogsId?: string | null,
  } | null,
};

export type DeleteExportJobAuditLogMutationVariables = {
  input: DeleteExportJobAuditLogInput,
  condition?: ModelExportJobAuditLogConditionInput | null,
};

export type DeleteExportJobAuditLogMutation = {
  deleteExportJobAuditLog?:  {
    __typename: "ExportJobAuditLog",
    id: string,
    date: string,
    author: string,
    resource: string,
    action: string,
    payload?: string | null,
    ipAddress?: string | null,
    exportJob:  {
      __typename: "ExportJob",
      id: string,
      status: JobStatus,
      profile?:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      } | null,
      profileId?: string | null,
      auditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingInvitationType?: MeetingInvitationType | null,
      startDateTime: string,
      endDateTime: string,
      owner: string,
      identityId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      profileExportJobsId?: string | null,
    },
    exportJobId: string,
    profile?:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    } | null,
    profileId?: string | null,
    owner: string,
    source?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    identityId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileExportJobAuditLogsId?: string | null,
    exportJobAuditLogsId?: string | null,
  } | null,
};

export type CreateImportJobMutationVariables = {
  input: CreateImportJobInput,
  condition?: ModelImportJobConditionInput | null,
};

export type CreateImportJobMutation = {
  createImportJob?:  {
    __typename: "ImportJob",
    id: string,
    status: JobStatus,
    source:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    },
    identityId?: string | null,
    result?: string | null,
    profile?:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    } | null,
    profileId?: string | null,
    auditLogs?:  {
      __typename: "ModelImportJobAuditLogConnection",
      items:  Array< {
        __typename: "ImportJobAuditLog",
        id: string,
        date: string,
        author: string,
        resource: string,
        action: string,
        payload?: string | null,
        ipAddress?: string | null,
        importJobId: string,
        profileId?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileJobAuditLogsId?: string | null,
        importJobAuditLogsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    lastStartDateTime?: string | null,
    lastEndDateTime?: string | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileImportJobsId?: string | null,
  } | null,
};

export type UpdateImportJobMutationVariables = {
  input: UpdateImportJobInput,
  condition?: ModelImportJobConditionInput | null,
};

export type UpdateImportJobMutation = {
  updateImportJob?:  {
    __typename: "ImportJob",
    id: string,
    status: JobStatus,
    source:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    },
    identityId?: string | null,
    result?: string | null,
    profile?:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    } | null,
    profileId?: string | null,
    auditLogs?:  {
      __typename: "ModelImportJobAuditLogConnection",
      items:  Array< {
        __typename: "ImportJobAuditLog",
        id: string,
        date: string,
        author: string,
        resource: string,
        action: string,
        payload?: string | null,
        ipAddress?: string | null,
        importJobId: string,
        profileId?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileJobAuditLogsId?: string | null,
        importJobAuditLogsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    lastStartDateTime?: string | null,
    lastEndDateTime?: string | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileImportJobsId?: string | null,
  } | null,
};

export type DeleteImportJobMutationVariables = {
  input: DeleteImportJobInput,
  condition?: ModelImportJobConditionInput | null,
};

export type DeleteImportJobMutation = {
  deleteImportJob?:  {
    __typename: "ImportJob",
    id: string,
    status: JobStatus,
    source:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    },
    identityId?: string | null,
    result?: string | null,
    profile?:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    } | null,
    profileId?: string | null,
    auditLogs?:  {
      __typename: "ModelImportJobAuditLogConnection",
      items:  Array< {
        __typename: "ImportJobAuditLog",
        id: string,
        date: string,
        author: string,
        resource: string,
        action: string,
        payload?: string | null,
        ipAddress?: string | null,
        importJobId: string,
        profileId?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileJobAuditLogsId?: string | null,
        importJobAuditLogsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    lastStartDateTime?: string | null,
    lastEndDateTime?: string | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileImportJobsId?: string | null,
  } | null,
};

export type CreateImportJobAuditLogMutationVariables = {
  input: CreateImportJobAuditLogInput,
  condition?: ModelImportJobAuditLogConditionInput | null,
};

export type CreateImportJobAuditLogMutation = {
  createImportJobAuditLog?:  {
    __typename: "ImportJobAuditLog",
    id: string,
    date: string,
    author: string,
    resource: string,
    action: string,
    payload?: string | null,
    ipAddress?: string | null,
    importJob:  {
      __typename: "ImportJob",
      id: string,
      status: JobStatus,
      source:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      identityId?: string | null,
      result?: string | null,
      profile?:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      } | null,
      profileId?: string | null,
      auditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      lastStartDateTime?: string | null,
      lastEndDateTime?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      profileImportJobsId?: string | null,
    },
    importJobId: string,
    profile?:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    } | null,
    profileId?: string | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileJobAuditLogsId?: string | null,
    importJobAuditLogsId?: string | null,
  } | null,
};

export type UpdateImportJobAuditLogMutationVariables = {
  input: UpdateImportJobAuditLogInput,
  condition?: ModelImportJobAuditLogConditionInput | null,
};

export type UpdateImportJobAuditLogMutation = {
  updateImportJobAuditLog?:  {
    __typename: "ImportJobAuditLog",
    id: string,
    date: string,
    author: string,
    resource: string,
    action: string,
    payload?: string | null,
    ipAddress?: string | null,
    importJob:  {
      __typename: "ImportJob",
      id: string,
      status: JobStatus,
      source:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      identityId?: string | null,
      result?: string | null,
      profile?:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      } | null,
      profileId?: string | null,
      auditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      lastStartDateTime?: string | null,
      lastEndDateTime?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      profileImportJobsId?: string | null,
    },
    importJobId: string,
    profile?:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    } | null,
    profileId?: string | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileJobAuditLogsId?: string | null,
    importJobAuditLogsId?: string | null,
  } | null,
};

export type DeleteImportJobAuditLogMutationVariables = {
  input: DeleteImportJobAuditLogInput,
  condition?: ModelImportJobAuditLogConditionInput | null,
};

export type DeleteImportJobAuditLogMutation = {
  deleteImportJobAuditLog?:  {
    __typename: "ImportJobAuditLog",
    id: string,
    date: string,
    author: string,
    resource: string,
    action: string,
    payload?: string | null,
    ipAddress?: string | null,
    importJob:  {
      __typename: "ImportJob",
      id: string,
      status: JobStatus,
      source:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      identityId?: string | null,
      result?: string | null,
      profile?:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      } | null,
      profileId?: string | null,
      auditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      lastStartDateTime?: string | null,
      lastEndDateTime?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      profileImportJobsId?: string | null,
    },
    importJobId: string,
    profile?:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    } | null,
    profileId?: string | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileJobAuditLogsId?: string | null,
    importJobAuditLogsId?: string | null,
  } | null,
};

export type CreateNotificationMutationVariables = {
  input: CreateNotificationInput,
  condition?: ModelNotificationConditionInput | null,
};

export type CreateNotificationMutation = {
  createNotification?:  {
    __typename: "Notification",
    id: string,
    date: string,
    title: string,
    payload: string,
    read: number,
    dateRead?: string | null,
    owner: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateNotificationMutationVariables = {
  input: UpdateNotificationInput,
  condition?: ModelNotificationConditionInput | null,
};

export type UpdateNotificationMutation = {
  updateNotification?:  {
    __typename: "Notification",
    id: string,
    date: string,
    title: string,
    payload: string,
    read: number,
    dateRead?: string | null,
    owner: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteNotificationMutationVariables = {
  input: DeleteNotificationInput,
  condition?: ModelNotificationConditionInput | null,
};

export type DeleteNotificationMutation = {
  deleteNotification?:  {
    __typename: "Notification",
    id: string,
    date: string,
    title: string,
    payload: string,
    read: number,
    dateRead?: string | null,
    owner: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateTranscriptionMutationVariables = {
  input: CreateTranscriptionInput,
  condition?: ModelTranscriptionConditionInput | null,
};

export type CreateTranscriptionMutation = {
  createTranscription?:  {
    __typename: "Transcription",
    id: string,
    date: string,
    title: string,
    description?: string | null,
    startDateTime: string,
    endDateTime: string,
    result:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    },
    sizeInBytes?: number | null,
    numberOfTranscriptions?: number | null,
    isZipFile: boolean,
    meeting?:  {
      __typename: "Meeting",
      id: string,
      title: string,
      titleLowerCase?: string | null,
      startDateTime: string,
      endDateTime: string,
      status: MeetingStatus,
      description?: string | null,
      preferredRoomProvider?:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      preferredRoomProviderId?: string | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      room?:  {
        __typename: "MeetingRoom",
        id: string,
        name: string,
        meetingId: string,
        providerId: string,
        providerMeetingId?: string | null,
        accessCode: string,
        status: MeetingRoomStatus,
        startDateTime?: string | null,
        endDateTime?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        roomCreatedAt?: string | null,
        roomDestroyedAt?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        isReadinessTest: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        meetingRoomProviderMeetingRoomsId?: string | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null,
      productId?: string | null,
      isReadinessTest: boolean,
      labels?: Array< string | null > | null,
      owner?: string | null,
      teamRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerOrganizationName?: string | null,
      sellerTeamName?: string | null,
      buyerTeamName?: string | null,
      sellerOrganizationNameLowerCase?: string | null,
      sellerTeamNameLowerCase?: string | null,
      buyerTeamNameLowerCase?: string | null,
      externalId?: string | null,
      metrics?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productMeetingsId?: string | null,
      meetingRoomProviderMeetingsId?: string | null,
      meetingRoomId?: string | null,
    } | null,
    meetingId?: string | null,
    team?:  {
      __typename: "Team",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      memberRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      privacyEnabled: boolean,
      privacyEnableOtherTeams?: boolean | null,
      images?:  {
        __typename: "ModelTeamImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    teamId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    teamTranscriptionsId?: string | null,
    meetingTranscriptionsId?: string | null,
  } | null,
};

export type UpdateTranscriptionMutationVariables = {
  input: UpdateTranscriptionInput,
  condition?: ModelTranscriptionConditionInput | null,
};

export type UpdateTranscriptionMutation = {
  updateTranscription?:  {
    __typename: "Transcription",
    id: string,
    date: string,
    title: string,
    description?: string | null,
    startDateTime: string,
    endDateTime: string,
    result:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    },
    sizeInBytes?: number | null,
    numberOfTranscriptions?: number | null,
    isZipFile: boolean,
    meeting?:  {
      __typename: "Meeting",
      id: string,
      title: string,
      titleLowerCase?: string | null,
      startDateTime: string,
      endDateTime: string,
      status: MeetingStatus,
      description?: string | null,
      preferredRoomProvider?:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      preferredRoomProviderId?: string | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      room?:  {
        __typename: "MeetingRoom",
        id: string,
        name: string,
        meetingId: string,
        providerId: string,
        providerMeetingId?: string | null,
        accessCode: string,
        status: MeetingRoomStatus,
        startDateTime?: string | null,
        endDateTime?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        roomCreatedAt?: string | null,
        roomDestroyedAt?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        isReadinessTest: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        meetingRoomProviderMeetingRoomsId?: string | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null,
      productId?: string | null,
      isReadinessTest: boolean,
      labels?: Array< string | null > | null,
      owner?: string | null,
      teamRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerOrganizationName?: string | null,
      sellerTeamName?: string | null,
      buyerTeamName?: string | null,
      sellerOrganizationNameLowerCase?: string | null,
      sellerTeamNameLowerCase?: string | null,
      buyerTeamNameLowerCase?: string | null,
      externalId?: string | null,
      metrics?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productMeetingsId?: string | null,
      meetingRoomProviderMeetingsId?: string | null,
      meetingRoomId?: string | null,
    } | null,
    meetingId?: string | null,
    team?:  {
      __typename: "Team",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      memberRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      privacyEnabled: boolean,
      privacyEnableOtherTeams?: boolean | null,
      images?:  {
        __typename: "ModelTeamImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    teamId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    teamTranscriptionsId?: string | null,
    meetingTranscriptionsId?: string | null,
  } | null,
};

export type DeleteTranscriptionMutationVariables = {
  input: DeleteTranscriptionInput,
  condition?: ModelTranscriptionConditionInput | null,
};

export type DeleteTranscriptionMutation = {
  deleteTranscription?:  {
    __typename: "Transcription",
    id: string,
    date: string,
    title: string,
    description?: string | null,
    startDateTime: string,
    endDateTime: string,
    result:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    },
    sizeInBytes?: number | null,
    numberOfTranscriptions?: number | null,
    isZipFile: boolean,
    meeting?:  {
      __typename: "Meeting",
      id: string,
      title: string,
      titleLowerCase?: string | null,
      startDateTime: string,
      endDateTime: string,
      status: MeetingStatus,
      description?: string | null,
      preferredRoomProvider?:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      preferredRoomProviderId?: string | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      room?:  {
        __typename: "MeetingRoom",
        id: string,
        name: string,
        meetingId: string,
        providerId: string,
        providerMeetingId?: string | null,
        accessCode: string,
        status: MeetingRoomStatus,
        startDateTime?: string | null,
        endDateTime?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        roomCreatedAt?: string | null,
        roomDestroyedAt?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        isReadinessTest: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        meetingRoomProviderMeetingRoomsId?: string | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null,
      productId?: string | null,
      isReadinessTest: boolean,
      labels?: Array< string | null > | null,
      owner?: string | null,
      teamRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerOrganizationName?: string | null,
      sellerTeamName?: string | null,
      buyerTeamName?: string | null,
      sellerOrganizationNameLowerCase?: string | null,
      sellerTeamNameLowerCase?: string | null,
      buyerTeamNameLowerCase?: string | null,
      externalId?: string | null,
      metrics?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productMeetingsId?: string | null,
      meetingRoomProviderMeetingsId?: string | null,
      meetingRoomId?: string | null,
    } | null,
    meetingId?: string | null,
    team?:  {
      __typename: "Team",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      memberRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      privacyEnabled: boolean,
      privacyEnableOtherTeams?: boolean | null,
      images?:  {
        __typename: "ModelTeamImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    teamId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    teamTranscriptionsId?: string | null,
    meetingTranscriptionsId?: string | null,
  } | null,
};

export type CreateTranscriptionJobMutationVariables = {
  input: CreateTranscriptionJobInput,
  condition?: ModelTranscriptionJobConditionInput | null,
};

export type CreateTranscriptionJobMutation = {
  createTranscriptionJob?:  {
    __typename: "TranscriptionJob",
    id: string,
    date: string,
    status: JobStatus,
    result?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    meetingInviteId: string,
    auditLogs?:  {
      __typename: "ModelTranscriptionJobAuditLogConnection",
      items:  Array< {
        __typename: "TranscriptionJobAuditLog",
        id: string,
        date: string,
        author: string,
        resource: string,
        action: string,
        payload?: string | null,
        ipAddress?: string | null,
        transcriptionJobId: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        transcriptionJobAuditLogsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    owner: string,
    identityId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateTranscriptionJobMutationVariables = {
  input: UpdateTranscriptionJobInput,
  condition?: ModelTranscriptionJobConditionInput | null,
};

export type UpdateTranscriptionJobMutation = {
  updateTranscriptionJob?:  {
    __typename: "TranscriptionJob",
    id: string,
    date: string,
    status: JobStatus,
    result?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    meetingInviteId: string,
    auditLogs?:  {
      __typename: "ModelTranscriptionJobAuditLogConnection",
      items:  Array< {
        __typename: "TranscriptionJobAuditLog",
        id: string,
        date: string,
        author: string,
        resource: string,
        action: string,
        payload?: string | null,
        ipAddress?: string | null,
        transcriptionJobId: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        transcriptionJobAuditLogsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    owner: string,
    identityId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteTranscriptionJobMutationVariables = {
  input: DeleteTranscriptionJobInput,
  condition?: ModelTranscriptionJobConditionInput | null,
};

export type DeleteTranscriptionJobMutation = {
  deleteTranscriptionJob?:  {
    __typename: "TranscriptionJob",
    id: string,
    date: string,
    status: JobStatus,
    result?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    meetingInviteId: string,
    auditLogs?:  {
      __typename: "ModelTranscriptionJobAuditLogConnection",
      items:  Array< {
        __typename: "TranscriptionJobAuditLog",
        id: string,
        date: string,
        author: string,
        resource: string,
        action: string,
        payload?: string | null,
        ipAddress?: string | null,
        transcriptionJobId: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        transcriptionJobAuditLogsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    owner: string,
    identityId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateTranscriptionJobAuditLogMutationVariables = {
  input: CreateTranscriptionJobAuditLogInput,
  condition?: ModelTranscriptionJobAuditLogConditionInput | null,
};

export type CreateTranscriptionJobAuditLogMutation = {
  createTranscriptionJobAuditLog?:  {
    __typename: "TranscriptionJobAuditLog",
    id: string,
    date: string,
    author: string,
    resource: string,
    action: string,
    payload?: string | null,
    ipAddress?: string | null,
    transcriptionJob:  {
      __typename: "TranscriptionJob",
      id: string,
      date: string,
      status: JobStatus,
      result?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      meetingInviteId: string,
      auditLogs?:  {
        __typename: "ModelTranscriptionJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner: string,
      identityId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    transcriptionJobId: string,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    transcriptionJobAuditLogsId?: string | null,
  } | null,
};

export type UpdateTranscriptionJobAuditLogMutationVariables = {
  input: UpdateTranscriptionJobAuditLogInput,
  condition?: ModelTranscriptionJobAuditLogConditionInput | null,
};

export type UpdateTranscriptionJobAuditLogMutation = {
  updateTranscriptionJobAuditLog?:  {
    __typename: "TranscriptionJobAuditLog",
    id: string,
    date: string,
    author: string,
    resource: string,
    action: string,
    payload?: string | null,
    ipAddress?: string | null,
    transcriptionJob:  {
      __typename: "TranscriptionJob",
      id: string,
      date: string,
      status: JobStatus,
      result?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      meetingInviteId: string,
      auditLogs?:  {
        __typename: "ModelTranscriptionJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner: string,
      identityId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    transcriptionJobId: string,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    transcriptionJobAuditLogsId?: string | null,
  } | null,
};

export type DeleteTranscriptionJobAuditLogMutationVariables = {
  input: DeleteTranscriptionJobAuditLogInput,
  condition?: ModelTranscriptionJobAuditLogConditionInput | null,
};

export type DeleteTranscriptionJobAuditLogMutation = {
  deleteTranscriptionJobAuditLog?:  {
    __typename: "TranscriptionJobAuditLog",
    id: string,
    date: string,
    author: string,
    resource: string,
    action: string,
    payload?: string | null,
    ipAddress?: string | null,
    transcriptionJob:  {
      __typename: "TranscriptionJob",
      id: string,
      date: string,
      status: JobStatus,
      result?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      meetingInviteId: string,
      auditLogs?:  {
        __typename: "ModelTranscriptionJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner: string,
      identityId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    transcriptionJobId: string,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    transcriptionJobAuditLogsId?: string | null,
  } | null,
};

export type CreateTranscriptionAdminJobMutationVariables = {
  input: CreateTranscriptionAdminJobInput,
  condition?: ModelTranscriptionAdminJobConditionInput | null,
};

export type CreateTranscriptionAdminJobMutation = {
  createTranscriptionAdminJob?:  {
    __typename: "TranscriptionAdminJob",
    id: string,
    date: string,
    status: JobStatus,
    result?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    meetingId: string,
    meetingInviteId?: string | null,
    auditLogs?:  {
      __typename: "ModelTranscriptionAdminJobAuditLogConnection",
      items:  Array< {
        __typename: "TranscriptionAdminJobAuditLog",
        id: string,
        date: string,
        author: string,
        resource: string,
        action: string,
        payload?: string | null,
        ipAddress?: string | null,
        transcriptionAdminJobId: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        transcriptionAdminJobAuditLogsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    owner: string,
    identityId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateTranscriptionAdminJobMutationVariables = {
  input: UpdateTranscriptionAdminJobInput,
  condition?: ModelTranscriptionAdminJobConditionInput | null,
};

export type UpdateTranscriptionAdminJobMutation = {
  updateTranscriptionAdminJob?:  {
    __typename: "TranscriptionAdminJob",
    id: string,
    date: string,
    status: JobStatus,
    result?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    meetingId: string,
    meetingInviteId?: string | null,
    auditLogs?:  {
      __typename: "ModelTranscriptionAdminJobAuditLogConnection",
      items:  Array< {
        __typename: "TranscriptionAdminJobAuditLog",
        id: string,
        date: string,
        author: string,
        resource: string,
        action: string,
        payload?: string | null,
        ipAddress?: string | null,
        transcriptionAdminJobId: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        transcriptionAdminJobAuditLogsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    owner: string,
    identityId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteTranscriptionAdminJobMutationVariables = {
  input: DeleteTranscriptionAdminJobInput,
  condition?: ModelTranscriptionAdminJobConditionInput | null,
};

export type DeleteTranscriptionAdminJobMutation = {
  deleteTranscriptionAdminJob?:  {
    __typename: "TranscriptionAdminJob",
    id: string,
    date: string,
    status: JobStatus,
    result?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    meetingId: string,
    meetingInviteId?: string | null,
    auditLogs?:  {
      __typename: "ModelTranscriptionAdminJobAuditLogConnection",
      items:  Array< {
        __typename: "TranscriptionAdminJobAuditLog",
        id: string,
        date: string,
        author: string,
        resource: string,
        action: string,
        payload?: string | null,
        ipAddress?: string | null,
        transcriptionAdminJobId: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        transcriptionAdminJobAuditLogsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    owner: string,
    identityId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateTranscriptionAdminJobAuditLogMutationVariables = {
  input: CreateTranscriptionAdminJobAuditLogInput,
  condition?: ModelTranscriptionAdminJobAuditLogConditionInput | null,
};

export type CreateTranscriptionAdminJobAuditLogMutation = {
  createTranscriptionAdminJobAuditLog?:  {
    __typename: "TranscriptionAdminJobAuditLog",
    id: string,
    date: string,
    author: string,
    resource: string,
    action: string,
    payload?: string | null,
    ipAddress?: string | null,
    transcriptionAdminJob:  {
      __typename: "TranscriptionAdminJob",
      id: string,
      date: string,
      status: JobStatus,
      result?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      meetingId: string,
      meetingInviteId?: string | null,
      auditLogs?:  {
        __typename: "ModelTranscriptionAdminJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner: string,
      identityId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    transcriptionAdminJobId: string,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    transcriptionAdminJobAuditLogsId?: string | null,
  } | null,
};

export type UpdateTranscriptionAdminJobAuditLogMutationVariables = {
  input: UpdateTranscriptionAdminJobAuditLogInput,
  condition?: ModelTranscriptionAdminJobAuditLogConditionInput | null,
};

export type UpdateTranscriptionAdminJobAuditLogMutation = {
  updateTranscriptionAdminJobAuditLog?:  {
    __typename: "TranscriptionAdminJobAuditLog",
    id: string,
    date: string,
    author: string,
    resource: string,
    action: string,
    payload?: string | null,
    ipAddress?: string | null,
    transcriptionAdminJob:  {
      __typename: "TranscriptionAdminJob",
      id: string,
      date: string,
      status: JobStatus,
      result?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      meetingId: string,
      meetingInviteId?: string | null,
      auditLogs?:  {
        __typename: "ModelTranscriptionAdminJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner: string,
      identityId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    transcriptionAdminJobId: string,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    transcriptionAdminJobAuditLogsId?: string | null,
  } | null,
};

export type DeleteTranscriptionAdminJobAuditLogMutationVariables = {
  input: DeleteTranscriptionAdminJobAuditLogInput,
  condition?: ModelTranscriptionAdminJobAuditLogConditionInput | null,
};

export type DeleteTranscriptionAdminJobAuditLogMutation = {
  deleteTranscriptionAdminJobAuditLog?:  {
    __typename: "TranscriptionAdminJobAuditLog",
    id: string,
    date: string,
    author: string,
    resource: string,
    action: string,
    payload?: string | null,
    ipAddress?: string | null,
    transcriptionAdminJob:  {
      __typename: "TranscriptionAdminJob",
      id: string,
      date: string,
      status: JobStatus,
      result?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      meetingId: string,
      meetingInviteId?: string | null,
      auditLogs?:  {
        __typename: "ModelTranscriptionAdminJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner: string,
      identityId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    transcriptionAdminJobId: string,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    transcriptionAdminJobAuditLogsId?: string | null,
  } | null,
};

export type CreateTranscriptionEventAuditLogMutationVariables = {
  input: CreateTranscriptionEventAuditLogInput,
  condition?: ModelTranscriptionEventAuditLogConditionInput | null,
};

export type CreateTranscriptionEventAuditLogMutation = {
  createTranscriptionEventAuditLog?:  {
    __typename: "TranscriptionEventAuditLog",
    id: string,
    date: string,
    author: string,
    resource: string,
    action: string,
    payload?: string | null,
    ipAddress?: string | null,
    meetingInvite:  {
      __typename: "MeetingInvite",
      id: string,
      meetingId: string,
      provider?:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      providerId?: string | null,
      title: string,
      startDateTime: string,
      endDateTime: string,
      status: MeetingStatus,
      description?: string | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null,
      productId?: string | null,
      isReadinessTest: boolean,
      meetingRoomId?: string | null,
      providerSessionId?: string | null,
      providerJoinData?: string | null,
      sessionStatus?: MeetingRoomSessionStatus | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      profile:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      },
      profileId: string,
      owner?: string | null,
      meetingInvitationType: MeetingInvitationType,
      teamId?: string | null,
      isInterested?: boolean | null,
      feedbackSummary?: string | null,
      contactRequestTimeframe?: string | null,
      contactPhoneNumber?: string | null,
      contactEmail?: string | null,
      sellerOrganizationName?: string | null,
      sellerTeamName?: string | null,
      buyerTeamName?: string | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productMeetingInvitesId?: string | null,
      profileMeetingInvitesId?: string | null,
      meetingRoomProviderMeetingInvitesId?: string | null,
    },
    meetingInviteId: string,
    profile:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    },
    profileId: string,
    authorProfileId?: string | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileTranscriptionLogsId?: string | null,
    meetingInviteTranscriptionLogsId?: string | null,
  } | null,
};

export type UpdateTranscriptionEventAuditLogMutationVariables = {
  input: UpdateTranscriptionEventAuditLogInput,
  condition?: ModelTranscriptionEventAuditLogConditionInput | null,
};

export type UpdateTranscriptionEventAuditLogMutation = {
  updateTranscriptionEventAuditLog?:  {
    __typename: "TranscriptionEventAuditLog",
    id: string,
    date: string,
    author: string,
    resource: string,
    action: string,
    payload?: string | null,
    ipAddress?: string | null,
    meetingInvite:  {
      __typename: "MeetingInvite",
      id: string,
      meetingId: string,
      provider?:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      providerId?: string | null,
      title: string,
      startDateTime: string,
      endDateTime: string,
      status: MeetingStatus,
      description?: string | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null,
      productId?: string | null,
      isReadinessTest: boolean,
      meetingRoomId?: string | null,
      providerSessionId?: string | null,
      providerJoinData?: string | null,
      sessionStatus?: MeetingRoomSessionStatus | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      profile:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      },
      profileId: string,
      owner?: string | null,
      meetingInvitationType: MeetingInvitationType,
      teamId?: string | null,
      isInterested?: boolean | null,
      feedbackSummary?: string | null,
      contactRequestTimeframe?: string | null,
      contactPhoneNumber?: string | null,
      contactEmail?: string | null,
      sellerOrganizationName?: string | null,
      sellerTeamName?: string | null,
      buyerTeamName?: string | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productMeetingInvitesId?: string | null,
      profileMeetingInvitesId?: string | null,
      meetingRoomProviderMeetingInvitesId?: string | null,
    },
    meetingInviteId: string,
    profile:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    },
    profileId: string,
    authorProfileId?: string | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileTranscriptionLogsId?: string | null,
    meetingInviteTranscriptionLogsId?: string | null,
  } | null,
};

export type DeleteTranscriptionEventAuditLogMutationVariables = {
  input: DeleteTranscriptionEventAuditLogInput,
  condition?: ModelTranscriptionEventAuditLogConditionInput | null,
};

export type DeleteTranscriptionEventAuditLogMutation = {
  deleteTranscriptionEventAuditLog?:  {
    __typename: "TranscriptionEventAuditLog",
    id: string,
    date: string,
    author: string,
    resource: string,
    action: string,
    payload?: string | null,
    ipAddress?: string | null,
    meetingInvite:  {
      __typename: "MeetingInvite",
      id: string,
      meetingId: string,
      provider?:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      providerId?: string | null,
      title: string,
      startDateTime: string,
      endDateTime: string,
      status: MeetingStatus,
      description?: string | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null,
      productId?: string | null,
      isReadinessTest: boolean,
      meetingRoomId?: string | null,
      providerSessionId?: string | null,
      providerJoinData?: string | null,
      sessionStatus?: MeetingRoomSessionStatus | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      profile:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      },
      profileId: string,
      owner?: string | null,
      meetingInvitationType: MeetingInvitationType,
      teamId?: string | null,
      isInterested?: boolean | null,
      feedbackSummary?: string | null,
      contactRequestTimeframe?: string | null,
      contactPhoneNumber?: string | null,
      contactEmail?: string | null,
      sellerOrganizationName?: string | null,
      sellerTeamName?: string | null,
      buyerTeamName?: string | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productMeetingInvitesId?: string | null,
      profileMeetingInvitesId?: string | null,
      meetingRoomProviderMeetingInvitesId?: string | null,
    },
    meetingInviteId: string,
    profile:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    },
    profileId: string,
    authorProfileId?: string | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileTranscriptionLogsId?: string | null,
    meetingInviteTranscriptionLogsId?: string | null,
  } | null,
};

export type GetProductQuestionQueryVariables = {
  id: string,
};

export type GetProductQuestionQuery = {
  getProductQuestion?:  {
    __typename: "ProductQuestion",
    id: string,
    question: string,
    product:  {
      __typename: "Product",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      serviceInformation?: string | null,
      images?:  {
        __typename: "ModelProductImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questions?:  {
        __typename: "ModelProductQuestionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      salesTeam?:  {
        __typename: "Team",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        labels?: Array< string | null > | null,
        privacyEnabled: boolean,
        privacyEnableOtherTeams?: boolean | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      salesTeamId?: string | null,
      meetings?:  {
        __typename: "ModelMeetingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProductsId?: string | null,
      teamProductsId?: string | null,
    },
    productId: string,
    responses?:  {
      __typename: "ModelQuestionResponseConnection",
      items:  Array< {
        __typename: "QuestionResponse",
        id: string,
        response?: string | null,
        privateNotes?: string | null,
        questionId: string,
        profileId: string,
        meetingId: string,
        meetingInviteId: string,
        owner?: string | null,
        contactRequestTimeframe?: string | null,
        contactRequestDateTime?: string | null,
        contactPhoneNumber?: string | null,
        contactEmail?: string | null,
        contactName?: string | null,
        providePersonalEmail?: boolean | null,
        providePersonalPhoneNumber?: boolean | null,
        textOnly?: boolean | null,
        followupMeetingRequested?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productQuestionResponsesId?: string | null,
        profileQuestionResponsesId?: string | null,
        meetingQuestionResponsesId?: string | null,
        meetingInviteQuestionResponsesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    productQuestionsId?: string | null,
  } | null,
};

export type ListProductQuestionsQueryVariables = {
  filter?: ModelProductQuestionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProductQuestionsQuery = {
  listProductQuestions?:  {
    __typename: "ModelProductQuestionConnection",
    items:  Array< {
      __typename: "ProductQuestion",
      id: string,
      question: string,
      product:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      },
      productId: string,
      responses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productQuestionsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncProductQuestionsQueryVariables = {
  filter?: ModelProductQuestionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncProductQuestionsQuery = {
  syncProductQuestions?:  {
    __typename: "ModelProductQuestionConnection",
    items:  Array< {
      __typename: "ProductQuestion",
      id: string,
      question: string,
      product:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      },
      productId: string,
      responses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productQuestionsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListProductQuestionsByProductIdQueryVariables = {
  productId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductQuestionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProductQuestionsByProductIdQuery = {
  listProductQuestionsByProductId?:  {
    __typename: "ModelProductQuestionConnection",
    items:  Array< {
      __typename: "ProductQuestion",
      id: string,
      question: string,
      product:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      },
      productId: string,
      responses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productQuestionsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetQuestionResponseQueryVariables = {
  id: string,
};

export type GetQuestionResponseQuery = {
  getQuestionResponse?:  {
    __typename: "QuestionResponse",
    id: string,
    response?: string | null,
    privateNotes?: string | null,
    question:  {
      __typename: "ProductQuestion",
      id: string,
      question: string,
      product:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      },
      productId: string,
      responses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productQuestionsId?: string | null,
    },
    questionId: string,
    profile:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    },
    profileId: string,
    meeting:  {
      __typename: "Meeting",
      id: string,
      title: string,
      titleLowerCase?: string | null,
      startDateTime: string,
      endDateTime: string,
      status: MeetingStatus,
      description?: string | null,
      preferredRoomProvider?:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      preferredRoomProviderId?: string | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      room?:  {
        __typename: "MeetingRoom",
        id: string,
        name: string,
        meetingId: string,
        providerId: string,
        providerMeetingId?: string | null,
        accessCode: string,
        status: MeetingRoomStatus,
        startDateTime?: string | null,
        endDateTime?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        roomCreatedAt?: string | null,
        roomDestroyedAt?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        isReadinessTest: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        meetingRoomProviderMeetingRoomsId?: string | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null,
      productId?: string | null,
      isReadinessTest: boolean,
      labels?: Array< string | null > | null,
      owner?: string | null,
      teamRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerOrganizationName?: string | null,
      sellerTeamName?: string | null,
      buyerTeamName?: string | null,
      sellerOrganizationNameLowerCase?: string | null,
      sellerTeamNameLowerCase?: string | null,
      buyerTeamNameLowerCase?: string | null,
      externalId?: string | null,
      metrics?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productMeetingsId?: string | null,
      meetingRoomProviderMeetingsId?: string | null,
      meetingRoomId?: string | null,
    },
    meetingId: string,
    meetingInvite:  {
      __typename: "MeetingInvite",
      id: string,
      meetingId: string,
      provider?:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      providerId?: string | null,
      title: string,
      startDateTime: string,
      endDateTime: string,
      status: MeetingStatus,
      description?: string | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null,
      productId?: string | null,
      isReadinessTest: boolean,
      meetingRoomId?: string | null,
      providerSessionId?: string | null,
      providerJoinData?: string | null,
      sessionStatus?: MeetingRoomSessionStatus | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      profile:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      },
      profileId: string,
      owner?: string | null,
      meetingInvitationType: MeetingInvitationType,
      teamId?: string | null,
      isInterested?: boolean | null,
      feedbackSummary?: string | null,
      contactRequestTimeframe?: string | null,
      contactPhoneNumber?: string | null,
      contactEmail?: string | null,
      sellerOrganizationName?: string | null,
      sellerTeamName?: string | null,
      buyerTeamName?: string | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productMeetingInvitesId?: string | null,
      profileMeetingInvitesId?: string | null,
      meetingRoomProviderMeetingInvitesId?: string | null,
    },
    meetingInviteId: string,
    owner?: string | null,
    contactRequestTimeframe?: string | null,
    contactRequestDateTime?: string | null,
    contactPhoneNumber?: string | null,
    contactEmail?: string | null,
    contactName?: string | null,
    providePersonalEmail?: boolean | null,
    providePersonalPhoneNumber?: boolean | null,
    textOnly?: boolean | null,
    followupMeetingRequested?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    productQuestionResponsesId?: string | null,
    profileQuestionResponsesId?: string | null,
    meetingQuestionResponsesId?: string | null,
    meetingInviteQuestionResponsesId?: string | null,
  } | null,
};

export type ListQuestionResponsesQueryVariables = {
  filter?: ModelQuestionResponseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListQuestionResponsesQuery = {
  listQuestionResponses?:  {
    __typename: "ModelQuestionResponseConnection",
    items:  Array< {
      __typename: "QuestionResponse",
      id: string,
      response?: string | null,
      privateNotes?: string | null,
      question:  {
        __typename: "ProductQuestion",
        id: string,
        question: string,
        productId: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productQuestionsId?: string | null,
      },
      questionId: string,
      profile:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      },
      profileId: string,
      meeting:  {
        __typename: "Meeting",
        id: string,
        title: string,
        titleLowerCase?: string | null,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        preferredRoomProviderId?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        sellerOrganizationNameLowerCase?: string | null,
        sellerTeamNameLowerCase?: string | null,
        buyerTeamNameLowerCase?: string | null,
        externalId?: string | null,
        metrics?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingsId?: string | null,
        meetingRoomProviderMeetingsId?: string | null,
        meetingRoomId?: string | null,
      },
      meetingId: string,
      meetingInvite:  {
        __typename: "MeetingInvite",
        id: string,
        meetingId: string,
        providerId?: string | null,
        title: string,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        meetingRoomId?: string | null,
        providerSessionId?: string | null,
        providerJoinData?: string | null,
        sessionStatus?: MeetingRoomSessionStatus | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        profileId: string,
        owner?: string | null,
        meetingInvitationType: MeetingInvitationType,
        teamId?: string | null,
        isInterested?: boolean | null,
        feedbackSummary?: string | null,
        contactRequestTimeframe?: string | null,
        contactPhoneNumber?: string | null,
        contactEmail?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingInvitesId?: string | null,
        profileMeetingInvitesId?: string | null,
        meetingRoomProviderMeetingInvitesId?: string | null,
      },
      meetingInviteId: string,
      owner?: string | null,
      contactRequestTimeframe?: string | null,
      contactRequestDateTime?: string | null,
      contactPhoneNumber?: string | null,
      contactEmail?: string | null,
      contactName?: string | null,
      providePersonalEmail?: boolean | null,
      providePersonalPhoneNumber?: boolean | null,
      textOnly?: boolean | null,
      followupMeetingRequested?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productQuestionResponsesId?: string | null,
      profileQuestionResponsesId?: string | null,
      meetingQuestionResponsesId?: string | null,
      meetingInviteQuestionResponsesId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncQuestionResponsesQueryVariables = {
  filter?: ModelQuestionResponseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncQuestionResponsesQuery = {
  syncQuestionResponses?:  {
    __typename: "ModelQuestionResponseConnection",
    items:  Array< {
      __typename: "QuestionResponse",
      id: string,
      response?: string | null,
      privateNotes?: string | null,
      question:  {
        __typename: "ProductQuestion",
        id: string,
        question: string,
        productId: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productQuestionsId?: string | null,
      },
      questionId: string,
      profile:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      },
      profileId: string,
      meeting:  {
        __typename: "Meeting",
        id: string,
        title: string,
        titleLowerCase?: string | null,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        preferredRoomProviderId?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        sellerOrganizationNameLowerCase?: string | null,
        sellerTeamNameLowerCase?: string | null,
        buyerTeamNameLowerCase?: string | null,
        externalId?: string | null,
        metrics?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingsId?: string | null,
        meetingRoomProviderMeetingsId?: string | null,
        meetingRoomId?: string | null,
      },
      meetingId: string,
      meetingInvite:  {
        __typename: "MeetingInvite",
        id: string,
        meetingId: string,
        providerId?: string | null,
        title: string,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        meetingRoomId?: string | null,
        providerSessionId?: string | null,
        providerJoinData?: string | null,
        sessionStatus?: MeetingRoomSessionStatus | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        profileId: string,
        owner?: string | null,
        meetingInvitationType: MeetingInvitationType,
        teamId?: string | null,
        isInterested?: boolean | null,
        feedbackSummary?: string | null,
        contactRequestTimeframe?: string | null,
        contactPhoneNumber?: string | null,
        contactEmail?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingInvitesId?: string | null,
        profileMeetingInvitesId?: string | null,
        meetingRoomProviderMeetingInvitesId?: string | null,
      },
      meetingInviteId: string,
      owner?: string | null,
      contactRequestTimeframe?: string | null,
      contactRequestDateTime?: string | null,
      contactPhoneNumber?: string | null,
      contactEmail?: string | null,
      contactName?: string | null,
      providePersonalEmail?: boolean | null,
      providePersonalPhoneNumber?: boolean | null,
      textOnly?: boolean | null,
      followupMeetingRequested?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productQuestionResponsesId?: string | null,
      profileQuestionResponsesId?: string | null,
      meetingQuestionResponsesId?: string | null,
      meetingInviteQuestionResponsesId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListQuestionResponsesByQuestionIdQueryVariables = {
  questionId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelQuestionResponseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListQuestionResponsesByQuestionIdQuery = {
  listQuestionResponsesByQuestionId?:  {
    __typename: "ModelQuestionResponseConnection",
    items:  Array< {
      __typename: "QuestionResponse",
      id: string,
      response?: string | null,
      privateNotes?: string | null,
      question:  {
        __typename: "ProductQuestion",
        id: string,
        question: string,
        productId: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productQuestionsId?: string | null,
      },
      questionId: string,
      profile:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      },
      profileId: string,
      meeting:  {
        __typename: "Meeting",
        id: string,
        title: string,
        titleLowerCase?: string | null,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        preferredRoomProviderId?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        sellerOrganizationNameLowerCase?: string | null,
        sellerTeamNameLowerCase?: string | null,
        buyerTeamNameLowerCase?: string | null,
        externalId?: string | null,
        metrics?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingsId?: string | null,
        meetingRoomProviderMeetingsId?: string | null,
        meetingRoomId?: string | null,
      },
      meetingId: string,
      meetingInvite:  {
        __typename: "MeetingInvite",
        id: string,
        meetingId: string,
        providerId?: string | null,
        title: string,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        meetingRoomId?: string | null,
        providerSessionId?: string | null,
        providerJoinData?: string | null,
        sessionStatus?: MeetingRoomSessionStatus | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        profileId: string,
        owner?: string | null,
        meetingInvitationType: MeetingInvitationType,
        teamId?: string | null,
        isInterested?: boolean | null,
        feedbackSummary?: string | null,
        contactRequestTimeframe?: string | null,
        contactPhoneNumber?: string | null,
        contactEmail?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingInvitesId?: string | null,
        profileMeetingInvitesId?: string | null,
        meetingRoomProviderMeetingInvitesId?: string | null,
      },
      meetingInviteId: string,
      owner?: string | null,
      contactRequestTimeframe?: string | null,
      contactRequestDateTime?: string | null,
      contactPhoneNumber?: string | null,
      contactEmail?: string | null,
      contactName?: string | null,
      providePersonalEmail?: boolean | null,
      providePersonalPhoneNumber?: boolean | null,
      textOnly?: boolean | null,
      followupMeetingRequested?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productQuestionResponsesId?: string | null,
      profileQuestionResponsesId?: string | null,
      meetingQuestionResponsesId?: string | null,
      meetingInviteQuestionResponsesId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListQuestionResponsesByProfileIdQueryVariables = {
  profileId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelQuestionResponseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListQuestionResponsesByProfileIdQuery = {
  listQuestionResponsesByProfileId?:  {
    __typename: "ModelQuestionResponseConnection",
    items:  Array< {
      __typename: "QuestionResponse",
      id: string,
      response?: string | null,
      privateNotes?: string | null,
      question:  {
        __typename: "ProductQuestion",
        id: string,
        question: string,
        productId: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productQuestionsId?: string | null,
      },
      questionId: string,
      profile:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      },
      profileId: string,
      meeting:  {
        __typename: "Meeting",
        id: string,
        title: string,
        titleLowerCase?: string | null,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        preferredRoomProviderId?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        sellerOrganizationNameLowerCase?: string | null,
        sellerTeamNameLowerCase?: string | null,
        buyerTeamNameLowerCase?: string | null,
        externalId?: string | null,
        metrics?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingsId?: string | null,
        meetingRoomProviderMeetingsId?: string | null,
        meetingRoomId?: string | null,
      },
      meetingId: string,
      meetingInvite:  {
        __typename: "MeetingInvite",
        id: string,
        meetingId: string,
        providerId?: string | null,
        title: string,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        meetingRoomId?: string | null,
        providerSessionId?: string | null,
        providerJoinData?: string | null,
        sessionStatus?: MeetingRoomSessionStatus | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        profileId: string,
        owner?: string | null,
        meetingInvitationType: MeetingInvitationType,
        teamId?: string | null,
        isInterested?: boolean | null,
        feedbackSummary?: string | null,
        contactRequestTimeframe?: string | null,
        contactPhoneNumber?: string | null,
        contactEmail?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingInvitesId?: string | null,
        profileMeetingInvitesId?: string | null,
        meetingRoomProviderMeetingInvitesId?: string | null,
      },
      meetingInviteId: string,
      owner?: string | null,
      contactRequestTimeframe?: string | null,
      contactRequestDateTime?: string | null,
      contactPhoneNumber?: string | null,
      contactEmail?: string | null,
      contactName?: string | null,
      providePersonalEmail?: boolean | null,
      providePersonalPhoneNumber?: boolean | null,
      textOnly?: boolean | null,
      followupMeetingRequested?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productQuestionResponsesId?: string | null,
      profileQuestionResponsesId?: string | null,
      meetingQuestionResponsesId?: string | null,
      meetingInviteQuestionResponsesId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListQuestionResponsesByMeetingIdQueryVariables = {
  meetingId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelQuestionResponseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListQuestionResponsesByMeetingIdQuery = {
  listQuestionResponsesByMeetingId?:  {
    __typename: "ModelQuestionResponseConnection",
    items:  Array< {
      __typename: "QuestionResponse",
      id: string,
      response?: string | null,
      privateNotes?: string | null,
      question:  {
        __typename: "ProductQuestion",
        id: string,
        question: string,
        productId: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productQuestionsId?: string | null,
      },
      questionId: string,
      profile:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      },
      profileId: string,
      meeting:  {
        __typename: "Meeting",
        id: string,
        title: string,
        titleLowerCase?: string | null,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        preferredRoomProviderId?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        sellerOrganizationNameLowerCase?: string | null,
        sellerTeamNameLowerCase?: string | null,
        buyerTeamNameLowerCase?: string | null,
        externalId?: string | null,
        metrics?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingsId?: string | null,
        meetingRoomProviderMeetingsId?: string | null,
        meetingRoomId?: string | null,
      },
      meetingId: string,
      meetingInvite:  {
        __typename: "MeetingInvite",
        id: string,
        meetingId: string,
        providerId?: string | null,
        title: string,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        meetingRoomId?: string | null,
        providerSessionId?: string | null,
        providerJoinData?: string | null,
        sessionStatus?: MeetingRoomSessionStatus | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        profileId: string,
        owner?: string | null,
        meetingInvitationType: MeetingInvitationType,
        teamId?: string | null,
        isInterested?: boolean | null,
        feedbackSummary?: string | null,
        contactRequestTimeframe?: string | null,
        contactPhoneNumber?: string | null,
        contactEmail?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingInvitesId?: string | null,
        profileMeetingInvitesId?: string | null,
        meetingRoomProviderMeetingInvitesId?: string | null,
      },
      meetingInviteId: string,
      owner?: string | null,
      contactRequestTimeframe?: string | null,
      contactRequestDateTime?: string | null,
      contactPhoneNumber?: string | null,
      contactEmail?: string | null,
      contactName?: string | null,
      providePersonalEmail?: boolean | null,
      providePersonalPhoneNumber?: boolean | null,
      textOnly?: boolean | null,
      followupMeetingRequested?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productQuestionResponsesId?: string | null,
      profileQuestionResponsesId?: string | null,
      meetingQuestionResponsesId?: string | null,
      meetingInviteQuestionResponsesId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListQuestionResponsesByMeetingInviteIdQueryVariables = {
  meetingInviteId: string,
  questionId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelQuestionResponseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListQuestionResponsesByMeetingInviteIdQuery = {
  listQuestionResponsesByMeetingInviteId?:  {
    __typename: "ModelQuestionResponseConnection",
    items:  Array< {
      __typename: "QuestionResponse",
      id: string,
      response?: string | null,
      privateNotes?: string | null,
      question:  {
        __typename: "ProductQuestion",
        id: string,
        question: string,
        productId: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productQuestionsId?: string | null,
      },
      questionId: string,
      profile:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      },
      profileId: string,
      meeting:  {
        __typename: "Meeting",
        id: string,
        title: string,
        titleLowerCase?: string | null,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        preferredRoomProviderId?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        sellerOrganizationNameLowerCase?: string | null,
        sellerTeamNameLowerCase?: string | null,
        buyerTeamNameLowerCase?: string | null,
        externalId?: string | null,
        metrics?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingsId?: string | null,
        meetingRoomProviderMeetingsId?: string | null,
        meetingRoomId?: string | null,
      },
      meetingId: string,
      meetingInvite:  {
        __typename: "MeetingInvite",
        id: string,
        meetingId: string,
        providerId?: string | null,
        title: string,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        meetingRoomId?: string | null,
        providerSessionId?: string | null,
        providerJoinData?: string | null,
        sessionStatus?: MeetingRoomSessionStatus | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        profileId: string,
        owner?: string | null,
        meetingInvitationType: MeetingInvitationType,
        teamId?: string | null,
        isInterested?: boolean | null,
        feedbackSummary?: string | null,
        contactRequestTimeframe?: string | null,
        contactPhoneNumber?: string | null,
        contactEmail?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingInvitesId?: string | null,
        profileMeetingInvitesId?: string | null,
        meetingRoomProviderMeetingInvitesId?: string | null,
      },
      meetingInviteId: string,
      owner?: string | null,
      contactRequestTimeframe?: string | null,
      contactRequestDateTime?: string | null,
      contactPhoneNumber?: string | null,
      contactEmail?: string | null,
      contactName?: string | null,
      providePersonalEmail?: boolean | null,
      providePersonalPhoneNumber?: boolean | null,
      textOnly?: boolean | null,
      followupMeetingRequested?: boolean | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productQuestionResponsesId?: string | null,
      profileQuestionResponsesId?: string | null,
      meetingQuestionResponsesId?: string | null,
      meetingInviteQuestionResponsesId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetProductImageQueryVariables = {
  id: string,
};

export type GetProductImageQuery = {
  getProductImage?:  {
    __typename: "ProductImage",
    id: string,
    title: string,
    alt?: string | null,
    width?: number | null,
    height?: number | null,
    image:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    },
    product:  {
      __typename: "Product",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      serviceInformation?: string | null,
      images?:  {
        __typename: "ModelProductImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questions?:  {
        __typename: "ModelProductQuestionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      salesTeam?:  {
        __typename: "Team",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        labels?: Array< string | null > | null,
        privacyEnabled: boolean,
        privacyEnableOtherTeams?: boolean | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      salesTeamId?: string | null,
      meetings?:  {
        __typename: "ModelMeetingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProductsId?: string | null,
      teamProductsId?: string | null,
    },
    productId: string,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    productImagesId?: string | null,
  } | null,
};

export type ListProductImagesQueryVariables = {
  filter?: ModelProductImageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProductImagesQuery = {
  listProductImages?:  {
    __typename: "ModelProductImageConnection",
    items:  Array< {
      __typename: "ProductImage",
      id: string,
      title: string,
      alt?: string | null,
      width?: number | null,
      height?: number | null,
      image:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      product:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      },
      productId: string,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productImagesId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncProductImagesQueryVariables = {
  filter?: ModelProductImageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncProductImagesQuery = {
  syncProductImages?:  {
    __typename: "ModelProductImageConnection",
    items:  Array< {
      __typename: "ProductImage",
      id: string,
      title: string,
      alt?: string | null,
      width?: number | null,
      height?: number | null,
      image:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      product:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      },
      productId: string,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productImagesId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListProductImagesByProductIdQueryVariables = {
  productId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductImageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProductImagesByProductIdQuery = {
  listProductImagesByProductId?:  {
    __typename: "ModelProductImageConnection",
    items:  Array< {
      __typename: "ProductImage",
      id: string,
      title: string,
      alt?: string | null,
      width?: number | null,
      height?: number | null,
      image:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      product:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      },
      productId: string,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productImagesId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetProductQueryVariables = {
  id: string,
};

export type GetProductQuery = {
  getProduct?:  {
    __typename: "Product",
    id: string,
    name: string,
    nameLowerCase?: string | null,
    description?: string | null,
    serviceInformation?: string | null,
    images?:  {
      __typename: "ModelProductImageConnection",
      items:  Array< {
        __typename: "ProductImage",
        id: string,
        title: string,
        alt?: string | null,
        width?: number | null,
        height?: number | null,
        productId: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productImagesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    questions?:  {
      __typename: "ModelProductQuestionConnection",
      items:  Array< {
        __typename: "ProductQuestion",
        id: string,
        question: string,
        productId: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productQuestionsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      externalId?: string | null,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      url?: string | null,
      phoneNumber?: string | null,
      locations?:  {
        __typename: "ModelOrganizationLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      profiles?:  {
        __typename: "ModelProfileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    organizationId?: string | null,
    salesTeam?:  {
      __typename: "Team",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      memberRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      privacyEnabled: boolean,
      privacyEnableOtherTeams?: boolean | null,
      images?:  {
        __typename: "ModelTeamImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    salesTeamId?: string | null,
    meetings?:  {
      __typename: "ModelMeetingConnection",
      items:  Array< {
        __typename: "Meeting",
        id: string,
        title: string,
        titleLowerCase?: string | null,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        preferredRoomProviderId?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        sellerOrganizationNameLowerCase?: string | null,
        sellerTeamNameLowerCase?: string | null,
        buyerTeamNameLowerCase?: string | null,
        externalId?: string | null,
        metrics?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingsId?: string | null,
        meetingRoomProviderMeetingsId?: string | null,
        meetingRoomId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    meetingInvites?:  {
      __typename: "ModelMeetingInviteConnection",
      items:  Array< {
        __typename: "MeetingInvite",
        id: string,
        meetingId: string,
        providerId?: string | null,
        title: string,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        meetingRoomId?: string | null,
        providerSessionId?: string | null,
        providerJoinData?: string | null,
        sessionStatus?: MeetingRoomSessionStatus | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        profileId: string,
        owner?: string | null,
        meetingInvitationType: MeetingInvitationType,
        teamId?: string | null,
        isInterested?: boolean | null,
        feedbackSummary?: string | null,
        contactRequestTimeframe?: string | null,
        contactPhoneNumber?: string | null,
        contactEmail?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingInvitesId?: string | null,
        profileMeetingInvitesId?: string | null,
        meetingRoomProviderMeetingInvitesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    labels?: Array< string | null > | null,
    owner?: string | null,
    sortByName?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    organizationProductsId?: string | null,
    teamProductsId?: string | null,
  } | null,
};

export type ListProductsQueryVariables = {
  filter?: ModelProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProductsQuery = {
  listProducts?:  {
    __typename: "ModelProductConnection",
    items:  Array< {
      __typename: "Product",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      serviceInformation?: string | null,
      images?:  {
        __typename: "ModelProductImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questions?:  {
        __typename: "ModelProductQuestionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      salesTeam?:  {
        __typename: "Team",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        labels?: Array< string | null > | null,
        privacyEnabled: boolean,
        privacyEnableOtherTeams?: boolean | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      salesTeamId?: string | null,
      meetings?:  {
        __typename: "ModelMeetingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProductsId?: string | null,
      teamProductsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncProductsQueryVariables = {
  filter?: ModelProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncProductsQuery = {
  syncProducts?:  {
    __typename: "ModelProductConnection",
    items:  Array< {
      __typename: "Product",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      serviceInformation?: string | null,
      images?:  {
        __typename: "ModelProductImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questions?:  {
        __typename: "ModelProductQuestionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      salesTeam?:  {
        __typename: "Team",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        labels?: Array< string | null > | null,
        privacyEnabled: boolean,
        privacyEnableOtherTeams?: boolean | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      salesTeamId?: string | null,
      meetings?:  {
        __typename: "ModelMeetingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProductsId?: string | null,
      teamProductsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListProductsByNameQueryVariables = {
  name: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProductsByNameQuery = {
  listProductsByName?:  {
    __typename: "ModelProductConnection",
    items:  Array< {
      __typename: "Product",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      serviceInformation?: string | null,
      images?:  {
        __typename: "ModelProductImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questions?:  {
        __typename: "ModelProductQuestionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      salesTeam?:  {
        __typename: "Team",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        labels?: Array< string | null > | null,
        privacyEnabled: boolean,
        privacyEnableOtherTeams?: boolean | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      salesTeamId?: string | null,
      meetings?:  {
        __typename: "ModelMeetingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProductsId?: string | null,
      teamProductsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListProductsByNameLowerCaseQueryVariables = {
  nameLowerCase: string,
  name?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProductsByNameLowerCaseQuery = {
  listProductsByNameLowerCase?:  {
    __typename: "ModelProductConnection",
    items:  Array< {
      __typename: "Product",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      serviceInformation?: string | null,
      images?:  {
        __typename: "ModelProductImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questions?:  {
        __typename: "ModelProductQuestionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      salesTeam?:  {
        __typename: "Team",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        labels?: Array< string | null > | null,
        privacyEnabled: boolean,
        privacyEnableOtherTeams?: boolean | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      salesTeamId?: string | null,
      meetings?:  {
        __typename: "ModelMeetingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProductsId?: string | null,
      teamProductsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListProductsByOrganizationIdQueryVariables = {
  organizationId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProductsByOrganizationIdQuery = {
  listProductsByOrganizationId?:  {
    __typename: "ModelProductConnection",
    items:  Array< {
      __typename: "Product",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      serviceInformation?: string | null,
      images?:  {
        __typename: "ModelProductImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questions?:  {
        __typename: "ModelProductQuestionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      salesTeam?:  {
        __typename: "Team",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        labels?: Array< string | null > | null,
        privacyEnabled: boolean,
        privacyEnableOtherTeams?: boolean | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      salesTeamId?: string | null,
      meetings?:  {
        __typename: "ModelMeetingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProductsId?: string | null,
      teamProductsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListProductsBySalesTeamIdQueryVariables = {
  salesTeamId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProductsBySalesTeamIdQuery = {
  listProductsBySalesTeamId?:  {
    __typename: "ModelProductConnection",
    items:  Array< {
      __typename: "Product",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      serviceInformation?: string | null,
      images?:  {
        __typename: "ModelProductImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questions?:  {
        __typename: "ModelProductQuestionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      salesTeam?:  {
        __typename: "Team",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        labels?: Array< string | null > | null,
        privacyEnabled: boolean,
        privacyEnableOtherTeams?: boolean | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      salesTeamId?: string | null,
      meetings?:  {
        __typename: "ModelMeetingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProductsId?: string | null,
      teamProductsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListProductsBySortByNameQueryVariables = {
  sortByName: number,
  nameLowerCase?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProductsBySortByNameQuery = {
  listProductsBySortByName?:  {
    __typename: "ModelProductConnection",
    items:  Array< {
      __typename: "Product",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      serviceInformation?: string | null,
      images?:  {
        __typename: "ModelProductImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questions?:  {
        __typename: "ModelProductQuestionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      salesTeam?:  {
        __typename: "Team",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        labels?: Array< string | null > | null,
        privacyEnabled: boolean,
        privacyEnableOtherTeams?: boolean | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      salesTeamId?: string | null,
      meetings?:  {
        __typename: "ModelMeetingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProductsId?: string | null,
      teamProductsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetOrganizationLocationQueryVariables = {
  id: string,
};

export type GetOrganizationLocationQuery = {
  getOrganizationLocation?:  {
    __typename: "OrganizationLocation",
    id: string,
    title?: string | null,
    address?: string | null,
    city?: string | null,
    state?: string | null,
    country?: string | null,
    organization:  {
      __typename: "Organization",
      id: string,
      externalId?: string | null,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      url?: string | null,
      phoneNumber?: string | null,
      locations?:  {
        __typename: "ModelOrganizationLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      profiles?:  {
        __typename: "ModelProfileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    organizationId: string,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    organizationLocationsId?: string | null,
  } | null,
};

export type ListOrganizationLocationsQueryVariables = {
  filter?: ModelOrganizationLocationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrganizationLocationsQuery = {
  listOrganizationLocations?:  {
    __typename: "ModelOrganizationLocationConnection",
    items:  Array< {
      __typename: "OrganizationLocation",
      id: string,
      title?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      organization:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      organizationId: string,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationLocationsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncOrganizationLocationsQueryVariables = {
  filter?: ModelOrganizationLocationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncOrganizationLocationsQuery = {
  syncOrganizationLocations?:  {
    __typename: "ModelOrganizationLocationConnection",
    items:  Array< {
      __typename: "OrganizationLocation",
      id: string,
      title?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      organization:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      organizationId: string,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationLocationsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListOrganizationLocationsByOrganizationIdQueryVariables = {
  organizationId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrganizationLocationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrganizationLocationsByOrganizationIdQuery = {
  listOrganizationLocationsByOrganizationId?:  {
    __typename: "ModelOrganizationLocationConnection",
    items:  Array< {
      __typename: "OrganizationLocation",
      id: string,
      title?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      organization:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      organizationId: string,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationLocationsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetOrganizationQueryVariables = {
  id: string,
};

export type GetOrganizationQuery = {
  getOrganization?:  {
    __typename: "Organization",
    id: string,
    externalId?: string | null,
    name: string,
    nameLowerCase?: string | null,
    description?: string | null,
    url?: string | null,
    phoneNumber?: string | null,
    locations?:  {
      __typename: "ModelOrganizationLocationConnection",
      items:  Array< {
        __typename: "OrganizationLocation",
        id: string,
        title?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        country?: string | null,
        organizationId: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationLocationsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    profiles?:  {
      __typename: "ModelProfileConnection",
      items:  Array< {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    products?:  {
      __typename: "ModelProductConnection",
      items:  Array< {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    labels?: Array< string | null > | null,
    owner?: string | null,
    sortByName?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListOrganizationsQueryVariables = {
  filter?: ModelOrganizationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrganizationsQuery = {
  listOrganizations?:  {
    __typename: "ModelOrganizationConnection",
    items:  Array< {
      __typename: "Organization",
      id: string,
      externalId?: string | null,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      url?: string | null,
      phoneNumber?: string | null,
      locations?:  {
        __typename: "ModelOrganizationLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      profiles?:  {
        __typename: "ModelProfileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncOrganizationsQueryVariables = {
  filter?: ModelOrganizationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncOrganizationsQuery = {
  syncOrganizations?:  {
    __typename: "ModelOrganizationConnection",
    items:  Array< {
      __typename: "Organization",
      id: string,
      externalId?: string | null,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      url?: string | null,
      phoneNumber?: string | null,
      locations?:  {
        __typename: "ModelOrganizationLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      profiles?:  {
        __typename: "ModelProfileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListOrganizationsByExternalIdQueryVariables = {
  externalId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrganizationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrganizationsByExternalIdQuery = {
  listOrganizationsByExternalId?:  {
    __typename: "ModelOrganizationConnection",
    items:  Array< {
      __typename: "Organization",
      id: string,
      externalId?: string | null,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      url?: string | null,
      phoneNumber?: string | null,
      locations?:  {
        __typename: "ModelOrganizationLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      profiles?:  {
        __typename: "ModelProfileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListOrganizationsByNameQueryVariables = {
  name: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrganizationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrganizationsByNameQuery = {
  listOrganizationsByName?:  {
    __typename: "ModelOrganizationConnection",
    items:  Array< {
      __typename: "Organization",
      id: string,
      externalId?: string | null,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      url?: string | null,
      phoneNumber?: string | null,
      locations?:  {
        __typename: "ModelOrganizationLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      profiles?:  {
        __typename: "ModelProfileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListOrganizationsByNameLowerCaseQueryVariables = {
  nameLowerCase: string,
  name?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrganizationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrganizationsByNameLowerCaseQuery = {
  listOrganizationsByNameLowerCase?:  {
    __typename: "ModelOrganizationConnection",
    items:  Array< {
      __typename: "Organization",
      id: string,
      externalId?: string | null,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      url?: string | null,
      phoneNumber?: string | null,
      locations?:  {
        __typename: "ModelOrganizationLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      profiles?:  {
        __typename: "ModelProfileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListOrganizationsBySortByNameQueryVariables = {
  sortByName: number,
  nameLowerCase?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrganizationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrganizationsBySortByNameQuery = {
  listOrganizationsBySortByName?:  {
    __typename: "ModelOrganizationConnection",
    items:  Array< {
      __typename: "Organization",
      id: string,
      externalId?: string | null,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      url?: string | null,
      phoneNumber?: string | null,
      locations?:  {
        __typename: "ModelOrganizationLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      profiles?:  {
        __typename: "ModelProfileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetProfileLocationQueryVariables = {
  id: string,
};

export type GetProfileLocationQuery = {
  getProfileLocation?:  {
    __typename: "ProfileLocation",
    id: string,
    title?: string | null,
    address?: string | null,
    city?: string | null,
    state?: string | null,
    country?: string | null,
    profile:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    },
    profileId: string,
    isPrimary: boolean,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileLocationsId?: string | null,
  } | null,
};

export type ListProfileLocationsQueryVariables = {
  filter?: ModelProfileLocationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProfileLocationsQuery = {
  listProfileLocations?:  {
    __typename: "ModelProfileLocationConnection",
    items:  Array< {
      __typename: "ProfileLocation",
      id: string,
      title?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      profile:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      },
      profileId: string,
      isPrimary: boolean,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      profileLocationsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncProfileLocationsQueryVariables = {
  filter?: ModelProfileLocationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncProfileLocationsQuery = {
  syncProfileLocations?:  {
    __typename: "ModelProfileLocationConnection",
    items:  Array< {
      __typename: "ProfileLocation",
      id: string,
      title?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      profile:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      },
      profileId: string,
      isPrimary: boolean,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      profileLocationsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListProfileLocationsByProfileIdQueryVariables = {
  profileId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProfileLocationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProfileLocationsByProfileIdQuery = {
  listProfileLocationsByProfileId?:  {
    __typename: "ModelProfileLocationConnection",
    items:  Array< {
      __typename: "ProfileLocation",
      id: string,
      title?: string | null,
      address?: string | null,
      city?: string | null,
      state?: string | null,
      country?: string | null,
      profile:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      },
      profileId: string,
      isPrimary: boolean,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      profileLocationsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetProfileImageQueryVariables = {
  id: string,
};

export type GetProfileImageQuery = {
  getProfileImage?:  {
    __typename: "ProfileImage",
    id: string,
    title: string,
    alt?: string | null,
    width?: number | null,
    height?: number | null,
    image:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    },
    profile:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    },
    profileId: string,
    isDefault: boolean,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileImagesId?: string | null,
  } | null,
};

export type ListProfileImagesQueryVariables = {
  filter?: ModelProfileImageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProfileImagesQuery = {
  listProfileImages?:  {
    __typename: "ModelProfileImageConnection",
    items:  Array< {
      __typename: "ProfileImage",
      id: string,
      title: string,
      alt?: string | null,
      width?: number | null,
      height?: number | null,
      image:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      profile:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      },
      profileId: string,
      isDefault: boolean,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      profileImagesId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncProfileImagesQueryVariables = {
  filter?: ModelProfileImageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncProfileImagesQuery = {
  syncProfileImages?:  {
    __typename: "ModelProfileImageConnection",
    items:  Array< {
      __typename: "ProfileImage",
      id: string,
      title: string,
      alt?: string | null,
      width?: number | null,
      height?: number | null,
      image:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      profile:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      },
      profileId: string,
      isDefault: boolean,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      profileImagesId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListProfileImagesByProfileIdQueryVariables = {
  profileId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProfileImageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProfileImagesByProfileIdQuery = {
  listProfileImagesByProfileId?:  {
    __typename: "ModelProfileImageConnection",
    items:  Array< {
      __typename: "ProfileImage",
      id: string,
      title: string,
      alt?: string | null,
      width?: number | null,
      height?: number | null,
      image:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      profile:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      },
      profileId: string,
      isDefault: boolean,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      profileImagesId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetForwardEmailQueryVariables = {
  id: string,
};

export type GetForwardEmailQuery = {
  getForwardEmail?:  {
    __typename: "ForwardEmail",
    id: string,
    received?: number | null,
    remaining?: number | null,
    publicEmail: string,
    expireAt?: string | null,
    privateEmail?: string | null,
    publicReplyEmail?: string | null,
    profile?:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    } | null,
    profileId?: string | null,
    sellerProfile?:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    } | null,
    sellerProfileId?: string | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileForwardEmailsId?: string | null,
    profileSellerForwardEmailsId?: string | null,
  } | null,
};

export type ListForwardEmailsQueryVariables = {
  filter?: ModelForwardEmailFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListForwardEmailsQuery = {
  listForwardEmails?:  {
    __typename: "ModelForwardEmailConnection",
    items:  Array< {
      __typename: "ForwardEmail",
      id: string,
      received?: number | null,
      remaining?: number | null,
      publicEmail: string,
      expireAt?: string | null,
      privateEmail?: string | null,
      publicReplyEmail?: string | null,
      profile?:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      } | null,
      profileId?: string | null,
      sellerProfile?:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      } | null,
      sellerProfileId?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      profileForwardEmailsId?: string | null,
      profileSellerForwardEmailsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncForwardEmailsQueryVariables = {
  filter?: ModelForwardEmailFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncForwardEmailsQuery = {
  syncForwardEmails?:  {
    __typename: "ModelForwardEmailConnection",
    items:  Array< {
      __typename: "ForwardEmail",
      id: string,
      received?: number | null,
      remaining?: number | null,
      publicEmail: string,
      expireAt?: string | null,
      privateEmail?: string | null,
      publicReplyEmail?: string | null,
      profile?:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      } | null,
      profileId?: string | null,
      sellerProfile?:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      } | null,
      sellerProfileId?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      profileForwardEmailsId?: string | null,
      profileSellerForwardEmailsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListForwardEmailsByPublicEmailQueryVariables = {
  publicEmail: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelForwardEmailFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListForwardEmailsByPublicEmailQuery = {
  listForwardEmailsByPublicEmail?:  {
    __typename: "ModelForwardEmailConnection",
    items:  Array< {
      __typename: "ForwardEmail",
      id: string,
      received?: number | null,
      remaining?: number | null,
      publicEmail: string,
      expireAt?: string | null,
      privateEmail?: string | null,
      publicReplyEmail?: string | null,
      profile?:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      } | null,
      profileId?: string | null,
      sellerProfile?:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      } | null,
      sellerProfileId?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      profileForwardEmailsId?: string | null,
      profileSellerForwardEmailsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListForwardEmailsByPrivateEmailQueryVariables = {
  privateEmail: string,
  publicReplyEmail?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelForwardEmailFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListForwardEmailsByPrivateEmailQuery = {
  listForwardEmailsByPrivateEmail?:  {
    __typename: "ModelForwardEmailConnection",
    items:  Array< {
      __typename: "ForwardEmail",
      id: string,
      received?: number | null,
      remaining?: number | null,
      publicEmail: string,
      expireAt?: string | null,
      privateEmail?: string | null,
      publicReplyEmail?: string | null,
      profile?:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      } | null,
      profileId?: string | null,
      sellerProfile?:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      } | null,
      sellerProfileId?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      profileForwardEmailsId?: string | null,
      profileSellerForwardEmailsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListForwardEmailsByProfileIdQueryVariables = {
  profileId: string,
  received?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelForwardEmailFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListForwardEmailsByProfileIdQuery = {
  listForwardEmailsByProfileId?:  {
    __typename: "ModelForwardEmailConnection",
    items:  Array< {
      __typename: "ForwardEmail",
      id: string,
      received?: number | null,
      remaining?: number | null,
      publicEmail: string,
      expireAt?: string | null,
      privateEmail?: string | null,
      publicReplyEmail?: string | null,
      profile?:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      } | null,
      profileId?: string | null,
      sellerProfile?:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      } | null,
      sellerProfileId?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      profileForwardEmailsId?: string | null,
      profileSellerForwardEmailsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListForwardEmailsBySellerProfileIdQueryVariables = {
  sellerProfileId: string,
  profileId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelForwardEmailFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListForwardEmailsBySellerProfileIdQuery = {
  listForwardEmailsBySellerProfileId?:  {
    __typename: "ModelForwardEmailConnection",
    items:  Array< {
      __typename: "ForwardEmail",
      id: string,
      received?: number | null,
      remaining?: number | null,
      publicEmail: string,
      expireAt?: string | null,
      privateEmail?: string | null,
      publicReplyEmail?: string | null,
      profile?:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      } | null,
      profileId?: string | null,
      sellerProfile?:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      } | null,
      sellerProfileId?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      profileForwardEmailsId?: string | null,
      profileSellerForwardEmailsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetProfileQueryVariables = {
  id: string,
};

export type GetProfileQuery = {
  getProfile?:  {
    __typename: "Profile",
    id: string,
    fullName: string,
    fullNameLowerCase?: string | null,
    email: string,
    externalId?: string | null,
    userId?: string | null,
    occupation?: string | null,
    profile?: string | null,
    phoneNumber?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      externalId?: string | null,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      url?: string | null,
      phoneNumber?: string | null,
      locations?:  {
        __typename: "ModelOrganizationLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      profiles?:  {
        __typename: "ModelProfileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    organizationId?: string | null,
    auditLogs?:  {
      __typename: "ModelMeetingRoomEventAuditLogConnection",
      items:  Array< {
        __typename: "MeetingRoomEventAuditLog",
        id: string,
        date: string,
        author: string,
        resource: string,
        action: string,
        payload?: string | null,
        ipAddress?: string | null,
        meetingRoomId: string,
        profileId?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileAuditLogsId?: string | null,
        meetingRoomAuditlogsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    jobAuditLogs?:  {
      __typename: "ModelImportJobAuditLogConnection",
      items:  Array< {
        __typename: "ImportJobAuditLog",
        id: string,
        date: string,
        author: string,
        resource: string,
        action: string,
        payload?: string | null,
        ipAddress?: string | null,
        importJobId: string,
        profileId?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileJobAuditLogsId?: string | null,
        importJobAuditLogsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    exportJobAuditLogs?:  {
      __typename: "ModelExportJobAuditLogConnection",
      items:  Array< {
        __typename: "ExportJobAuditLog",
        id: string,
        date: string,
        author: string,
        resource: string,
        action: string,
        payload?: string | null,
        ipAddress?: string | null,
        exportJobId: string,
        profileId?: string | null,
        owner: string,
        identityId?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileExportJobAuditLogsId?: string | null,
        exportJobAuditLogsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    teamRelationships?:  {
      __typename: "ModelTeamMemberRelationshipConnection",
      items:  Array< {
        __typename: "TeamMemberRelationship",
        id: string,
        teamId: string,
        memberProfileId: string,
        relationship: TeamRelationship,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileTeamRelationshipsId?: string | null,
        teamMemberRelationshipsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    meetingAttendees?:  {
      __typename: "ModelMeetingAttendeeConnection",
      items:  Array< {
        __typename: "MeetingAttendee",
        id: string,
        profileId: string,
        meetingId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileMeetingAttendeesId?: string | null,
        meetingMeetingAttendeesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    locations?:  {
      __typename: "ModelProfileLocationConnection",
      items:  Array< {
        __typename: "ProfileLocation",
        id: string,
        title?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        country?: string | null,
        profileId: string,
        isPrimary: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileLocationsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    devices?:  {
      __typename: "ModelProfileDeviceConnection",
      items:  Array< {
        __typename: "ProfileDevice",
        id: string,
        deviceHash: string,
        hasWebcam?: boolean | null,
        hasMicrophone?: boolean | null,
        isScreenCapturingSupported?: boolean | null,
        isWebRTCSupported?: boolean | null,
        isWebsiteHasMicrophonePermissions?: boolean | null,
        isWebsiteHasWebcamPermissions?: boolean | null,
        isWebSocketsSupported?: boolean | null,
        clockOffset?: number | null,
        lastSeen?: string | null,
        lastLogin?: string | null,
        lastMeetingId?: string | null,
        lastMeetingInviteId?: string | null,
        lastMeetingJoin?: string | null,
        lastMeetingStatus?: string | null,
        meetingData?: string | null,
        userAgent?: string | null,
        userAgentSummary?: string | null,
        micStatus?: string | null,
        cameraStatus?: string | null,
        networkQuality?: string | null,
        profileId: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileDevicesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    images?:  {
      __typename: "ModelProfileImageConnection",
      items:  Array< {
        __typename: "ProfileImage",
        id: string,
        title: string,
        alt?: string | null,
        width?: number | null,
        height?: number | null,
        profileId: string,
        isDefault: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileImagesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    questionResponses?:  {
      __typename: "ModelQuestionResponseConnection",
      items:  Array< {
        __typename: "QuestionResponse",
        id: string,
        response?: string | null,
        privateNotes?: string | null,
        questionId: string,
        profileId: string,
        meetingId: string,
        meetingInviteId: string,
        owner?: string | null,
        contactRequestTimeframe?: string | null,
        contactRequestDateTime?: string | null,
        contactPhoneNumber?: string | null,
        contactEmail?: string | null,
        contactName?: string | null,
        providePersonalEmail?: boolean | null,
        providePersonalPhoneNumber?: boolean | null,
        textOnly?: boolean | null,
        followupMeetingRequested?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productQuestionResponsesId?: string | null,
        profileQuestionResponsesId?: string | null,
        meetingQuestionResponsesId?: string | null,
        meetingInviteQuestionResponsesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    importJobs?:  {
      __typename: "ModelImportJobConnection",
      items:  Array< {
        __typename: "ImportJob",
        id: string,
        status: JobStatus,
        identityId?: string | null,
        result?: string | null,
        profileId?: string | null,
        lastStartDateTime?: string | null,
        lastEndDateTime?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileImportJobsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    exportJobs?:  {
      __typename: "ModelExportJobConnection",
      items:  Array< {
        __typename: "ExportJob",
        id: string,
        status: JobStatus,
        profileId?: string | null,
        meetingInvitationType?: MeetingInvitationType | null,
        startDateTime: string,
        endDateTime: string,
        owner: string,
        identityId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileExportJobsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    privacyEnabled: boolean,
    owner?: string | null,
    meetingInvites?:  {
      __typename: "ModelMeetingInviteConnection",
      items:  Array< {
        __typename: "MeetingInvite",
        id: string,
        meetingId: string,
        providerId?: string | null,
        title: string,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        meetingRoomId?: string | null,
        providerSessionId?: string | null,
        providerJoinData?: string | null,
        sessionStatus?: MeetingRoomSessionStatus | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        profileId: string,
        owner?: string | null,
        meetingInvitationType: MeetingInvitationType,
        teamId?: string | null,
        isInterested?: boolean | null,
        feedbackSummary?: string | null,
        contactRequestTimeframe?: string | null,
        contactPhoneNumber?: string | null,
        contactEmail?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingInvitesId?: string | null,
        profileMeetingInvitesId?: string | null,
        meetingRoomProviderMeetingInvitesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    sortByFullName?: number | null,
    transcriptionLogs?:  {
      __typename: "ModelTranscriptionEventAuditLogConnection",
      items:  Array< {
        __typename: "TranscriptionEventAuditLog",
        id: string,
        date: string,
        author: string,
        resource: string,
        action: string,
        payload?: string | null,
        ipAddress?: string | null,
        meetingInviteId: string,
        profileId: string,
        authorProfileId?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileTranscriptionLogsId?: string | null,
        meetingInviteTranscriptionLogsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    timeZone?: string | null,
    forwardEmails?:  {
      __typename: "ModelForwardEmailConnection",
      items:  Array< {
        __typename: "ForwardEmail",
        id: string,
        received?: number | null,
        remaining?: number | null,
        publicEmail: string,
        expireAt?: string | null,
        privateEmail?: string | null,
        publicReplyEmail?: string | null,
        profileId?: string | null,
        sellerProfileId?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileForwardEmailsId?: string | null,
        profileSellerForwardEmailsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    sellerForwardEmails?:  {
      __typename: "ModelForwardEmailConnection",
      items:  Array< {
        __typename: "ForwardEmail",
        id: string,
        received?: number | null,
        remaining?: number | null,
        publicEmail: string,
        expireAt?: string | null,
        privateEmail?: string | null,
        publicReplyEmail?: string | null,
        profileId?: string | null,
        sellerProfileId?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileForwardEmailsId?: string | null,
        profileSellerForwardEmailsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    organizationProfilesId?: string | null,
  } | null,
};

export type ListProfilesQueryVariables = {
  filter?: ModelProfileFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProfilesQuery = {
  listProfiles?:  {
    __typename: "ModelProfileConnection",
    items:  Array< {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncProfilesQueryVariables = {
  filter?: ModelProfileFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncProfilesQuery = {
  syncProfiles?:  {
    __typename: "ModelProfileConnection",
    items:  Array< {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListProfilesByFullNameQueryVariables = {
  fullName: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProfileFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProfilesByFullNameQuery = {
  listProfilesByFullName?:  {
    __typename: "ModelProfileConnection",
    items:  Array< {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListProfilesByFullNameLowerCaseQueryVariables = {
  fullNameLowerCase: string,
  fullName?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProfileFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProfilesByFullNameLowerCaseQuery = {
  listProfilesByFullNameLowerCase?:  {
    __typename: "ModelProfileConnection",
    items:  Array< {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListProfilesByEmailQueryVariables = {
  email: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProfileFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProfilesByEmailQuery = {
  listProfilesByEmail?:  {
    __typename: "ModelProfileConnection",
    items:  Array< {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListProfilesByExternalIdQueryVariables = {
  externalId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProfileFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProfilesByExternalIdQuery = {
  listProfilesByExternalId?:  {
    __typename: "ModelProfileConnection",
    items:  Array< {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListProfilesByUserIdQueryVariables = {
  userId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProfileFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProfilesByUserIdQuery = {
  listProfilesByUserId?:  {
    __typename: "ModelProfileConnection",
    items:  Array< {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListProfilesByOrganizationIdQueryVariables = {
  organizationId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProfileFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProfilesByOrganizationIdQuery = {
  listProfilesByOrganizationId?:  {
    __typename: "ModelProfileConnection",
    items:  Array< {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListProfilesBySortByFullNameQueryVariables = {
  sortByFullName: number,
  fullNameLowerCase?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProfileFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProfilesBySortByFullNameQuery = {
  listProfilesBySortByFullName?:  {
    __typename: "ModelProfileConnection",
    items:  Array< {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetProfileDeviceQueryVariables = {
  id: string,
};

export type GetProfileDeviceQuery = {
  getProfileDevice?:  {
    __typename: "ProfileDevice",
    id: string,
    deviceHash: string,
    hasWebcam?: boolean | null,
    hasMicrophone?: boolean | null,
    isScreenCapturingSupported?: boolean | null,
    isWebRTCSupported?: boolean | null,
    isWebsiteHasMicrophonePermissions?: boolean | null,
    isWebsiteHasWebcamPermissions?: boolean | null,
    isWebSocketsSupported?: boolean | null,
    clockOffset?: number | null,
    lastSeen?: string | null,
    lastLogin?: string | null,
    lastMeetingId?: string | null,
    lastMeetingInviteId?: string | null,
    lastMeetingJoin?: string | null,
    lastMeetingStatus?: string | null,
    meetingData?: string | null,
    userAgent?: string | null,
    userAgentSummary?: string | null,
    micStatus?: string | null,
    cameraStatus?: string | null,
    networkQuality?: string | null,
    profile:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    },
    profileId: string,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileDevicesId?: string | null,
  } | null,
};

export type ListProfileDevicesQueryVariables = {
  filter?: ModelProfileDeviceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProfileDevicesQuery = {
  listProfileDevices?:  {
    __typename: "ModelProfileDeviceConnection",
    items:  Array< {
      __typename: "ProfileDevice",
      id: string,
      deviceHash: string,
      hasWebcam?: boolean | null,
      hasMicrophone?: boolean | null,
      isScreenCapturingSupported?: boolean | null,
      isWebRTCSupported?: boolean | null,
      isWebsiteHasMicrophonePermissions?: boolean | null,
      isWebsiteHasWebcamPermissions?: boolean | null,
      isWebSocketsSupported?: boolean | null,
      clockOffset?: number | null,
      lastSeen?: string | null,
      lastLogin?: string | null,
      lastMeetingId?: string | null,
      lastMeetingInviteId?: string | null,
      lastMeetingJoin?: string | null,
      lastMeetingStatus?: string | null,
      meetingData?: string | null,
      userAgent?: string | null,
      userAgentSummary?: string | null,
      micStatus?: string | null,
      cameraStatus?: string | null,
      networkQuality?: string | null,
      profile:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      },
      profileId: string,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      profileDevicesId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncProfileDevicesQueryVariables = {
  filter?: ModelProfileDeviceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncProfileDevicesQuery = {
  syncProfileDevices?:  {
    __typename: "ModelProfileDeviceConnection",
    items:  Array< {
      __typename: "ProfileDevice",
      id: string,
      deviceHash: string,
      hasWebcam?: boolean | null,
      hasMicrophone?: boolean | null,
      isScreenCapturingSupported?: boolean | null,
      isWebRTCSupported?: boolean | null,
      isWebsiteHasMicrophonePermissions?: boolean | null,
      isWebsiteHasWebcamPermissions?: boolean | null,
      isWebSocketsSupported?: boolean | null,
      clockOffset?: number | null,
      lastSeen?: string | null,
      lastLogin?: string | null,
      lastMeetingId?: string | null,
      lastMeetingInviteId?: string | null,
      lastMeetingJoin?: string | null,
      lastMeetingStatus?: string | null,
      meetingData?: string | null,
      userAgent?: string | null,
      userAgentSummary?: string | null,
      micStatus?: string | null,
      cameraStatus?: string | null,
      networkQuality?: string | null,
      profile:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      },
      profileId: string,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      profileDevicesId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListProfileDevicesByClockOffsetQueryVariables = {
  clockOffset: number,
  lastSeen?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProfileDeviceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProfileDevicesByClockOffsetQuery = {
  listProfileDevicesByClockOffset?:  {
    __typename: "ModelProfileDeviceConnection",
    items:  Array< {
      __typename: "ProfileDevice",
      id: string,
      deviceHash: string,
      hasWebcam?: boolean | null,
      hasMicrophone?: boolean | null,
      isScreenCapturingSupported?: boolean | null,
      isWebRTCSupported?: boolean | null,
      isWebsiteHasMicrophonePermissions?: boolean | null,
      isWebsiteHasWebcamPermissions?: boolean | null,
      isWebSocketsSupported?: boolean | null,
      clockOffset?: number | null,
      lastSeen?: string | null,
      lastLogin?: string | null,
      lastMeetingId?: string | null,
      lastMeetingInviteId?: string | null,
      lastMeetingJoin?: string | null,
      lastMeetingStatus?: string | null,
      meetingData?: string | null,
      userAgent?: string | null,
      userAgentSummary?: string | null,
      micStatus?: string | null,
      cameraStatus?: string | null,
      networkQuality?: string | null,
      profile:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      },
      profileId: string,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      profileDevicesId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListProfileDevicesByLastSeenQueryVariables = {
  lastSeen: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProfileDeviceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProfileDevicesByLastSeenQuery = {
  listProfileDevicesByLastSeen?:  {
    __typename: "ModelProfileDeviceConnection",
    items:  Array< {
      __typename: "ProfileDevice",
      id: string,
      deviceHash: string,
      hasWebcam?: boolean | null,
      hasMicrophone?: boolean | null,
      isScreenCapturingSupported?: boolean | null,
      isWebRTCSupported?: boolean | null,
      isWebsiteHasMicrophonePermissions?: boolean | null,
      isWebsiteHasWebcamPermissions?: boolean | null,
      isWebSocketsSupported?: boolean | null,
      clockOffset?: number | null,
      lastSeen?: string | null,
      lastLogin?: string | null,
      lastMeetingId?: string | null,
      lastMeetingInviteId?: string | null,
      lastMeetingJoin?: string | null,
      lastMeetingStatus?: string | null,
      meetingData?: string | null,
      userAgent?: string | null,
      userAgentSummary?: string | null,
      micStatus?: string | null,
      cameraStatus?: string | null,
      networkQuality?: string | null,
      profile:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      },
      profileId: string,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      profileDevicesId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListProfileDevicesByMeetingStatusQueryVariables = {
  lastMeetingStatus: string,
  lastMeetingJoin?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProfileDeviceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProfileDevicesByMeetingStatusQuery = {
  listProfileDevicesByMeetingStatus?:  {
    __typename: "ModelProfileDeviceConnection",
    items:  Array< {
      __typename: "ProfileDevice",
      id: string,
      deviceHash: string,
      hasWebcam?: boolean | null,
      hasMicrophone?: boolean | null,
      isScreenCapturingSupported?: boolean | null,
      isWebRTCSupported?: boolean | null,
      isWebsiteHasMicrophonePermissions?: boolean | null,
      isWebsiteHasWebcamPermissions?: boolean | null,
      isWebSocketsSupported?: boolean | null,
      clockOffset?: number | null,
      lastSeen?: string | null,
      lastLogin?: string | null,
      lastMeetingId?: string | null,
      lastMeetingInviteId?: string | null,
      lastMeetingJoin?: string | null,
      lastMeetingStatus?: string | null,
      meetingData?: string | null,
      userAgent?: string | null,
      userAgentSummary?: string | null,
      micStatus?: string | null,
      cameraStatus?: string | null,
      networkQuality?: string | null,
      profile:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      },
      profileId: string,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      profileDevicesId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListProfileDevicesByMicStatusQueryVariables = {
  micStatus: string,
  lastMeetingJoin?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProfileDeviceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProfileDevicesByMicStatusQuery = {
  listProfileDevicesByMicStatus?:  {
    __typename: "ModelProfileDeviceConnection",
    items:  Array< {
      __typename: "ProfileDevice",
      id: string,
      deviceHash: string,
      hasWebcam?: boolean | null,
      hasMicrophone?: boolean | null,
      isScreenCapturingSupported?: boolean | null,
      isWebRTCSupported?: boolean | null,
      isWebsiteHasMicrophonePermissions?: boolean | null,
      isWebsiteHasWebcamPermissions?: boolean | null,
      isWebSocketsSupported?: boolean | null,
      clockOffset?: number | null,
      lastSeen?: string | null,
      lastLogin?: string | null,
      lastMeetingId?: string | null,
      lastMeetingInviteId?: string | null,
      lastMeetingJoin?: string | null,
      lastMeetingStatus?: string | null,
      meetingData?: string | null,
      userAgent?: string | null,
      userAgentSummary?: string | null,
      micStatus?: string | null,
      cameraStatus?: string | null,
      networkQuality?: string | null,
      profile:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      },
      profileId: string,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      profileDevicesId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListProfileDevicesByCameraStatusQueryVariables = {
  cameraStatus: string,
  lastMeetingJoin?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProfileDeviceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProfileDevicesByCameraStatusQuery = {
  listProfileDevicesByCameraStatus?:  {
    __typename: "ModelProfileDeviceConnection",
    items:  Array< {
      __typename: "ProfileDevice",
      id: string,
      deviceHash: string,
      hasWebcam?: boolean | null,
      hasMicrophone?: boolean | null,
      isScreenCapturingSupported?: boolean | null,
      isWebRTCSupported?: boolean | null,
      isWebsiteHasMicrophonePermissions?: boolean | null,
      isWebsiteHasWebcamPermissions?: boolean | null,
      isWebSocketsSupported?: boolean | null,
      clockOffset?: number | null,
      lastSeen?: string | null,
      lastLogin?: string | null,
      lastMeetingId?: string | null,
      lastMeetingInviteId?: string | null,
      lastMeetingJoin?: string | null,
      lastMeetingStatus?: string | null,
      meetingData?: string | null,
      userAgent?: string | null,
      userAgentSummary?: string | null,
      micStatus?: string | null,
      cameraStatus?: string | null,
      networkQuality?: string | null,
      profile:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      },
      profileId: string,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      profileDevicesId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListProfileDevicesByNetworkQualityQueryVariables = {
  networkQuality: string,
  lastMeetingJoin?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProfileDeviceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProfileDevicesByNetworkQualityQuery = {
  listProfileDevicesByNetworkQuality?:  {
    __typename: "ModelProfileDeviceConnection",
    items:  Array< {
      __typename: "ProfileDevice",
      id: string,
      deviceHash: string,
      hasWebcam?: boolean | null,
      hasMicrophone?: boolean | null,
      isScreenCapturingSupported?: boolean | null,
      isWebRTCSupported?: boolean | null,
      isWebsiteHasMicrophonePermissions?: boolean | null,
      isWebsiteHasWebcamPermissions?: boolean | null,
      isWebSocketsSupported?: boolean | null,
      clockOffset?: number | null,
      lastSeen?: string | null,
      lastLogin?: string | null,
      lastMeetingId?: string | null,
      lastMeetingInviteId?: string | null,
      lastMeetingJoin?: string | null,
      lastMeetingStatus?: string | null,
      meetingData?: string | null,
      userAgent?: string | null,
      userAgentSummary?: string | null,
      micStatus?: string | null,
      cameraStatus?: string | null,
      networkQuality?: string | null,
      profile:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      },
      profileId: string,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      profileDevicesId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListProfileDevicesByProfileIdQueryVariables = {
  profileId: string,
  deviceHash?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProfileDeviceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProfileDevicesByProfileIdQuery = {
  listProfileDevicesByProfileId?:  {
    __typename: "ModelProfileDeviceConnection",
    items:  Array< {
      __typename: "ProfileDevice",
      id: string,
      deviceHash: string,
      hasWebcam?: boolean | null,
      hasMicrophone?: boolean | null,
      isScreenCapturingSupported?: boolean | null,
      isWebRTCSupported?: boolean | null,
      isWebsiteHasMicrophonePermissions?: boolean | null,
      isWebsiteHasWebcamPermissions?: boolean | null,
      isWebSocketsSupported?: boolean | null,
      clockOffset?: number | null,
      lastSeen?: string | null,
      lastLogin?: string | null,
      lastMeetingId?: string | null,
      lastMeetingInviteId?: string | null,
      lastMeetingJoin?: string | null,
      lastMeetingStatus?: string | null,
      meetingData?: string | null,
      userAgent?: string | null,
      userAgentSummary?: string | null,
      micStatus?: string | null,
      cameraStatus?: string | null,
      networkQuality?: string | null,
      profile:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      },
      profileId: string,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      profileDevicesId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetTeamImageQueryVariables = {
  id: string,
};

export type GetTeamImageQuery = {
  getTeamImage?:  {
    __typename: "TeamImage",
    id: string,
    title: string,
    alt?: string | null,
    width?: number | null,
    height?: number | null,
    image:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    },
    team:  {
      __typename: "Team",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      memberRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      privacyEnabled: boolean,
      privacyEnableOtherTeams?: boolean | null,
      images?:  {
        __typename: "ModelTeamImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    teamId: string,
    isDefault: boolean,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    teamImagesId?: string | null,
  } | null,
};

export type ListTeamImagesQueryVariables = {
  filter?: ModelTeamImageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTeamImagesQuery = {
  listTeamImages?:  {
    __typename: "ModelTeamImageConnection",
    items:  Array< {
      __typename: "TeamImage",
      id: string,
      title: string,
      alt?: string | null,
      width?: number | null,
      height?: number | null,
      image:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      team:  {
        __typename: "Team",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        labels?: Array< string | null > | null,
        privacyEnabled: boolean,
        privacyEnableOtherTeams?: boolean | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      teamId: string,
      isDefault: boolean,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      teamImagesId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncTeamImagesQueryVariables = {
  filter?: ModelTeamImageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncTeamImagesQuery = {
  syncTeamImages?:  {
    __typename: "ModelTeamImageConnection",
    items:  Array< {
      __typename: "TeamImage",
      id: string,
      title: string,
      alt?: string | null,
      width?: number | null,
      height?: number | null,
      image:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      team:  {
        __typename: "Team",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        labels?: Array< string | null > | null,
        privacyEnabled: boolean,
        privacyEnableOtherTeams?: boolean | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      teamId: string,
      isDefault: boolean,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      teamImagesId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListTeamImagesByTeamIdQueryVariables = {
  teamId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTeamImageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTeamImagesByTeamIdQuery = {
  listTeamImagesByTeamId?:  {
    __typename: "ModelTeamImageConnection",
    items:  Array< {
      __typename: "TeamImage",
      id: string,
      title: string,
      alt?: string | null,
      width?: number | null,
      height?: number | null,
      image:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      team:  {
        __typename: "Team",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        labels?: Array< string | null > | null,
        privacyEnabled: boolean,
        privacyEnableOtherTeams?: boolean | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      teamId: string,
      isDefault: boolean,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      teamImagesId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetTeamQueryVariables = {
  id: string,
};

export type GetTeamQuery = {
  getTeam?:  {
    __typename: "Team",
    id: string,
    name: string,
    nameLowerCase?: string | null,
    description?: string | null,
    memberRelationships?:  {
      __typename: "ModelTeamMemberRelationshipConnection",
      items:  Array< {
        __typename: "TeamMemberRelationship",
        id: string,
        teamId: string,
        memberProfileId: string,
        relationship: TeamRelationship,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileTeamRelationshipsId?: string | null,
        teamMemberRelationshipsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    meetingRelationships?:  {
      __typename: "ModelTeamMeetingRelationshipConnection",
      items:  Array< {
        __typename: "TeamMeetingRelationship",
        id: string,
        teamId: string,
        meetingId: string,
        relationshipType: TeamMeetingRelationshipType,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        teamMeetingRelationshipsId?: string | null,
        meetingTeamRelationshipsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    products?:  {
      __typename: "ModelProductConnection",
      items:  Array< {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    labels?: Array< string | null > | null,
    privacyEnabled: boolean,
    privacyEnableOtherTeams?: boolean | null,
    images?:  {
      __typename: "ModelTeamImageConnection",
      items:  Array< {
        __typename: "TeamImage",
        id: string,
        title: string,
        alt?: string | null,
        width?: number | null,
        height?: number | null,
        teamId: string,
        isDefault: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        teamImagesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    transcriptions?:  {
      __typename: "ModelTranscriptionConnection",
      items:  Array< {
        __typename: "Transcription",
        id: string,
        date: string,
        title: string,
        description?: string | null,
        startDateTime: string,
        endDateTime: string,
        sizeInBytes?: number | null,
        numberOfTranscriptions?: number | null,
        isZipFile: boolean,
        meetingId?: string | null,
        teamId?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        teamTranscriptionsId?: string | null,
        meetingTranscriptionsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    owner?: string | null,
    sortByName?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListTeamsQueryVariables = {
  filter?: ModelTeamFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTeamsQuery = {
  listTeams?:  {
    __typename: "ModelTeamConnection",
    items:  Array< {
      __typename: "Team",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      memberRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      privacyEnabled: boolean,
      privacyEnableOtherTeams?: boolean | null,
      images?:  {
        __typename: "ModelTeamImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncTeamsQueryVariables = {
  filter?: ModelTeamFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncTeamsQuery = {
  syncTeams?:  {
    __typename: "ModelTeamConnection",
    items:  Array< {
      __typename: "Team",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      memberRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      privacyEnabled: boolean,
      privacyEnableOtherTeams?: boolean | null,
      images?:  {
        __typename: "ModelTeamImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListTeamsByNameQueryVariables = {
  name: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTeamFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTeamsByNameQuery = {
  listTeamsByName?:  {
    __typename: "ModelTeamConnection",
    items:  Array< {
      __typename: "Team",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      memberRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      privacyEnabled: boolean,
      privacyEnableOtherTeams?: boolean | null,
      images?:  {
        __typename: "ModelTeamImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListTeamsByNameLowerCaseQueryVariables = {
  nameLowerCase: string,
  name?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTeamFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTeamsByNameLowerCaseQuery = {
  listTeamsByNameLowerCase?:  {
    __typename: "ModelTeamConnection",
    items:  Array< {
      __typename: "Team",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      memberRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      privacyEnabled: boolean,
      privacyEnableOtherTeams?: boolean | null,
      images?:  {
        __typename: "ModelTeamImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListTeamsBySortByNameQueryVariables = {
  sortByName: number,
  nameLowerCase?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTeamFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTeamsBySortByNameQuery = {
  listTeamsBySortByName?:  {
    __typename: "ModelTeamConnection",
    items:  Array< {
      __typename: "Team",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      memberRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      privacyEnabled: boolean,
      privacyEnableOtherTeams?: boolean | null,
      images?:  {
        __typename: "ModelTeamImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetTeamMemberRelationshipQueryVariables = {
  id: string,
};

export type GetTeamMemberRelationshipQuery = {
  getTeamMemberRelationship?:  {
    __typename: "TeamMemberRelationship",
    id: string,
    team:  {
      __typename: "Team",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      memberRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      privacyEnabled: boolean,
      privacyEnableOtherTeams?: boolean | null,
      images?:  {
        __typename: "ModelTeamImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    teamId: string,
    member:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    },
    memberProfileId: string,
    relationship: TeamRelationship,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileTeamRelationshipsId?: string | null,
    teamMemberRelationshipsId?: string | null,
  } | null,
};

export type ListTeamMemberRelationshipsQueryVariables = {
  filter?: ModelTeamMemberRelationshipFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTeamMemberRelationshipsQuery = {
  listTeamMemberRelationships?:  {
    __typename: "ModelTeamMemberRelationshipConnection",
    items:  Array< {
      __typename: "TeamMemberRelationship",
      id: string,
      team:  {
        __typename: "Team",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        labels?: Array< string | null > | null,
        privacyEnabled: boolean,
        privacyEnableOtherTeams?: boolean | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      teamId: string,
      member:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      },
      memberProfileId: string,
      relationship: TeamRelationship,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      profileTeamRelationshipsId?: string | null,
      teamMemberRelationshipsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncTeamMemberRelationshipsQueryVariables = {
  filter?: ModelTeamMemberRelationshipFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncTeamMemberRelationshipsQuery = {
  syncTeamMemberRelationships?:  {
    __typename: "ModelTeamMemberRelationshipConnection",
    items:  Array< {
      __typename: "TeamMemberRelationship",
      id: string,
      team:  {
        __typename: "Team",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        labels?: Array< string | null > | null,
        privacyEnabled: boolean,
        privacyEnableOtherTeams?: boolean | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      teamId: string,
      member:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      },
      memberProfileId: string,
      relationship: TeamRelationship,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      profileTeamRelationshipsId?: string | null,
      teamMemberRelationshipsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListTeamMemberRelationshipsByTeamIdQueryVariables = {
  teamId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTeamMemberRelationshipFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTeamMemberRelationshipsByTeamIdQuery = {
  listTeamMemberRelationshipsByTeamId?:  {
    __typename: "ModelTeamMemberRelationshipConnection",
    items:  Array< {
      __typename: "TeamMemberRelationship",
      id: string,
      team:  {
        __typename: "Team",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        labels?: Array< string | null > | null,
        privacyEnabled: boolean,
        privacyEnableOtherTeams?: boolean | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      teamId: string,
      member:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      },
      memberProfileId: string,
      relationship: TeamRelationship,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      profileTeamRelationshipsId?: string | null,
      teamMemberRelationshipsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListTeamMemberRelationshipsByMemberProfileIdQueryVariables = {
  memberProfileId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTeamMemberRelationshipFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTeamMemberRelationshipsByMemberProfileIdQuery = {
  listTeamMemberRelationshipsByMemberProfileId?:  {
    __typename: "ModelTeamMemberRelationshipConnection",
    items:  Array< {
      __typename: "TeamMemberRelationship",
      id: string,
      team:  {
        __typename: "Team",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        labels?: Array< string | null > | null,
        privacyEnabled: boolean,
        privacyEnableOtherTeams?: boolean | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      teamId: string,
      member:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      },
      memberProfileId: string,
      relationship: TeamRelationship,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      profileTeamRelationshipsId?: string | null,
      teamMemberRelationshipsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetMeetingRoomProviderQueryVariables = {
  id: string,
};

export type GetMeetingRoomProviderQuery = {
  getMeetingRoomProvider?:  {
    __typename: "MeetingRoomProvider",
    id: string,
    name: string,
    componentName?: string | null,
    description?: string | null,
    configuration?: string | null,
    meetings?:  {
      __typename: "ModelMeetingConnection",
      items:  Array< {
        __typename: "Meeting",
        id: string,
        title: string,
        titleLowerCase?: string | null,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        preferredRoomProviderId?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        sellerOrganizationNameLowerCase?: string | null,
        sellerTeamNameLowerCase?: string | null,
        buyerTeamNameLowerCase?: string | null,
        externalId?: string | null,
        metrics?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingsId?: string | null,
        meetingRoomProviderMeetingsId?: string | null,
        meetingRoomId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    meetingRooms?:  {
      __typename: "ModelMeetingRoomConnection",
      items:  Array< {
        __typename: "MeetingRoom",
        id: string,
        name: string,
        meetingId: string,
        providerId: string,
        providerMeetingId?: string | null,
        accessCode: string,
        status: MeetingRoomStatus,
        startDateTime?: string | null,
        endDateTime?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        roomCreatedAt?: string | null,
        roomDestroyedAt?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        isReadinessTest: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        meetingRoomProviderMeetingRoomsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    meetingInvites?:  {
      __typename: "ModelMeetingInviteConnection",
      items:  Array< {
        __typename: "MeetingInvite",
        id: string,
        meetingId: string,
        providerId?: string | null,
        title: string,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        meetingRoomId?: string | null,
        providerSessionId?: string | null,
        providerJoinData?: string | null,
        sessionStatus?: MeetingRoomSessionStatus | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        profileId: string,
        owner?: string | null,
        meetingInvitationType: MeetingInvitationType,
        teamId?: string | null,
        isInterested?: boolean | null,
        feedbackSummary?: string | null,
        contactRequestTimeframe?: string | null,
        contactPhoneNumber?: string | null,
        contactEmail?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingInvitesId?: string | null,
        profileMeetingInvitesId?: string | null,
        meetingRoomProviderMeetingInvitesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListMeetingRoomProvidersQueryVariables = {
  filter?: ModelMeetingRoomProviderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMeetingRoomProvidersQuery = {
  listMeetingRoomProviders?:  {
    __typename: "ModelMeetingRoomProviderConnection",
    items:  Array< {
      __typename: "MeetingRoomProvider",
      id: string,
      name: string,
      componentName?: string | null,
      description?: string | null,
      configuration?: string | null,
      meetings?:  {
        __typename: "ModelMeetingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingRooms?:  {
        __typename: "ModelMeetingRoomConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncMeetingRoomProvidersQueryVariables = {
  filter?: ModelMeetingRoomProviderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncMeetingRoomProvidersQuery = {
  syncMeetingRoomProviders?:  {
    __typename: "ModelMeetingRoomProviderConnection",
    items:  Array< {
      __typename: "MeetingRoomProvider",
      id: string,
      name: string,
      componentName?: string | null,
      description?: string | null,
      configuration?: string | null,
      meetings?:  {
        __typename: "ModelMeetingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingRooms?:  {
        __typename: "ModelMeetingRoomConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListMeetingRoomProvidersByComponentNameQueryVariables = {
  componentName: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMeetingRoomProviderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMeetingRoomProvidersByComponentNameQuery = {
  listMeetingRoomProvidersByComponentName?:  {
    __typename: "ModelMeetingRoomProviderConnection",
    items:  Array< {
      __typename: "MeetingRoomProvider",
      id: string,
      name: string,
      componentName?: string | null,
      description?: string | null,
      configuration?: string | null,
      meetings?:  {
        __typename: "ModelMeetingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingRooms?:  {
        __typename: "ModelMeetingRoomConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetTeamMeetingRelationshipQueryVariables = {
  id: string,
};

export type GetTeamMeetingRelationshipQuery = {
  getTeamMeetingRelationship?:  {
    __typename: "TeamMeetingRelationship",
    id: string,
    team:  {
      __typename: "Team",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      memberRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      privacyEnabled: boolean,
      privacyEnableOtherTeams?: boolean | null,
      images?:  {
        __typename: "ModelTeamImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    teamId: string,
    meeting:  {
      __typename: "Meeting",
      id: string,
      title: string,
      titleLowerCase?: string | null,
      startDateTime: string,
      endDateTime: string,
      status: MeetingStatus,
      description?: string | null,
      preferredRoomProvider?:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      preferredRoomProviderId?: string | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      room?:  {
        __typename: "MeetingRoom",
        id: string,
        name: string,
        meetingId: string,
        providerId: string,
        providerMeetingId?: string | null,
        accessCode: string,
        status: MeetingRoomStatus,
        startDateTime?: string | null,
        endDateTime?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        roomCreatedAt?: string | null,
        roomDestroyedAt?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        isReadinessTest: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        meetingRoomProviderMeetingRoomsId?: string | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null,
      productId?: string | null,
      isReadinessTest: boolean,
      labels?: Array< string | null > | null,
      owner?: string | null,
      teamRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerOrganizationName?: string | null,
      sellerTeamName?: string | null,
      buyerTeamName?: string | null,
      sellerOrganizationNameLowerCase?: string | null,
      sellerTeamNameLowerCase?: string | null,
      buyerTeamNameLowerCase?: string | null,
      externalId?: string | null,
      metrics?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productMeetingsId?: string | null,
      meetingRoomProviderMeetingsId?: string | null,
      meetingRoomId?: string | null,
    },
    meetingId: string,
    relationshipType: TeamMeetingRelationshipType,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    teamMeetingRelationshipsId?: string | null,
    meetingTeamRelationshipsId?: string | null,
  } | null,
};

export type ListTeamMeetingRelationshipsQueryVariables = {
  filter?: ModelTeamMeetingRelationshipFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTeamMeetingRelationshipsQuery = {
  listTeamMeetingRelationships?:  {
    __typename: "ModelTeamMeetingRelationshipConnection",
    items:  Array< {
      __typename: "TeamMeetingRelationship",
      id: string,
      team:  {
        __typename: "Team",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        labels?: Array< string | null > | null,
        privacyEnabled: boolean,
        privacyEnableOtherTeams?: boolean | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      teamId: string,
      meeting:  {
        __typename: "Meeting",
        id: string,
        title: string,
        titleLowerCase?: string | null,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        preferredRoomProviderId?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        sellerOrganizationNameLowerCase?: string | null,
        sellerTeamNameLowerCase?: string | null,
        buyerTeamNameLowerCase?: string | null,
        externalId?: string | null,
        metrics?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingsId?: string | null,
        meetingRoomProviderMeetingsId?: string | null,
        meetingRoomId?: string | null,
      },
      meetingId: string,
      relationshipType: TeamMeetingRelationshipType,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      teamMeetingRelationshipsId?: string | null,
      meetingTeamRelationshipsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncTeamMeetingRelationshipsQueryVariables = {
  filter?: ModelTeamMeetingRelationshipFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncTeamMeetingRelationshipsQuery = {
  syncTeamMeetingRelationships?:  {
    __typename: "ModelTeamMeetingRelationshipConnection",
    items:  Array< {
      __typename: "TeamMeetingRelationship",
      id: string,
      team:  {
        __typename: "Team",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        labels?: Array< string | null > | null,
        privacyEnabled: boolean,
        privacyEnableOtherTeams?: boolean | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      teamId: string,
      meeting:  {
        __typename: "Meeting",
        id: string,
        title: string,
        titleLowerCase?: string | null,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        preferredRoomProviderId?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        sellerOrganizationNameLowerCase?: string | null,
        sellerTeamNameLowerCase?: string | null,
        buyerTeamNameLowerCase?: string | null,
        externalId?: string | null,
        metrics?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingsId?: string | null,
        meetingRoomProviderMeetingsId?: string | null,
        meetingRoomId?: string | null,
      },
      meetingId: string,
      relationshipType: TeamMeetingRelationshipType,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      teamMeetingRelationshipsId?: string | null,
      meetingTeamRelationshipsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListTeamMeetingRelationshipsByTeamIdQueryVariables = {
  teamId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTeamMeetingRelationshipFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTeamMeetingRelationshipsByTeamIdQuery = {
  listTeamMeetingRelationshipsByTeamId?:  {
    __typename: "ModelTeamMeetingRelationshipConnection",
    items:  Array< {
      __typename: "TeamMeetingRelationship",
      id: string,
      team:  {
        __typename: "Team",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        labels?: Array< string | null > | null,
        privacyEnabled: boolean,
        privacyEnableOtherTeams?: boolean | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      teamId: string,
      meeting:  {
        __typename: "Meeting",
        id: string,
        title: string,
        titleLowerCase?: string | null,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        preferredRoomProviderId?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        sellerOrganizationNameLowerCase?: string | null,
        sellerTeamNameLowerCase?: string | null,
        buyerTeamNameLowerCase?: string | null,
        externalId?: string | null,
        metrics?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingsId?: string | null,
        meetingRoomProviderMeetingsId?: string | null,
        meetingRoomId?: string | null,
      },
      meetingId: string,
      relationshipType: TeamMeetingRelationshipType,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      teamMeetingRelationshipsId?: string | null,
      meetingTeamRelationshipsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListTeamMeetingRelationshipsByMeetingIdQueryVariables = {
  meetingId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTeamMeetingRelationshipFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTeamMeetingRelationshipsByMeetingIdQuery = {
  listTeamMeetingRelationshipsByMeetingId?:  {
    __typename: "ModelTeamMeetingRelationshipConnection",
    items:  Array< {
      __typename: "TeamMeetingRelationship",
      id: string,
      team:  {
        __typename: "Team",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        labels?: Array< string | null > | null,
        privacyEnabled: boolean,
        privacyEnableOtherTeams?: boolean | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      teamId: string,
      meeting:  {
        __typename: "Meeting",
        id: string,
        title: string,
        titleLowerCase?: string | null,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        preferredRoomProviderId?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        sellerOrganizationNameLowerCase?: string | null,
        sellerTeamNameLowerCase?: string | null,
        buyerTeamNameLowerCase?: string | null,
        externalId?: string | null,
        metrics?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingsId?: string | null,
        meetingRoomProviderMeetingsId?: string | null,
        meetingRoomId?: string | null,
      },
      meetingId: string,
      relationshipType: TeamMeetingRelationshipType,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      teamMeetingRelationshipsId?: string | null,
      meetingTeamRelationshipsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListTeamMeetingRelationshipsByMeetingIdAndRelationshipTypeQueryVariables = {
  meetingId: string,
  relationshipType?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTeamMeetingRelationshipFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTeamMeetingRelationshipsByMeetingIdAndRelationshipTypeQuery = {
  listTeamMeetingRelationshipsByMeetingIdAndRelationshipType?:  {
    __typename: "ModelTeamMeetingRelationshipConnection",
    items:  Array< {
      __typename: "TeamMeetingRelationship",
      id: string,
      team:  {
        __typename: "Team",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        labels?: Array< string | null > | null,
        privacyEnabled: boolean,
        privacyEnableOtherTeams?: boolean | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      teamId: string,
      meeting:  {
        __typename: "Meeting",
        id: string,
        title: string,
        titleLowerCase?: string | null,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        preferredRoomProviderId?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        sellerOrganizationNameLowerCase?: string | null,
        sellerTeamNameLowerCase?: string | null,
        buyerTeamNameLowerCase?: string | null,
        externalId?: string | null,
        metrics?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingsId?: string | null,
        meetingRoomProviderMeetingsId?: string | null,
        meetingRoomId?: string | null,
      },
      meetingId: string,
      relationshipType: TeamMeetingRelationshipType,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      teamMeetingRelationshipsId?: string | null,
      meetingTeamRelationshipsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListTeamMeetingRelationshipsByRelationshipTypeQueryVariables = {
  relationshipType: TeamMeetingRelationshipType,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTeamMeetingRelationshipFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTeamMeetingRelationshipsByRelationshipTypeQuery = {
  listTeamMeetingRelationshipsByRelationshipType?:  {
    __typename: "ModelTeamMeetingRelationshipConnection",
    items:  Array< {
      __typename: "TeamMeetingRelationship",
      id: string,
      team:  {
        __typename: "Team",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        labels?: Array< string | null > | null,
        privacyEnabled: boolean,
        privacyEnableOtherTeams?: boolean | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      teamId: string,
      meeting:  {
        __typename: "Meeting",
        id: string,
        title: string,
        titleLowerCase?: string | null,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        preferredRoomProviderId?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        sellerOrganizationNameLowerCase?: string | null,
        sellerTeamNameLowerCase?: string | null,
        buyerTeamNameLowerCase?: string | null,
        externalId?: string | null,
        metrics?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingsId?: string | null,
        meetingRoomProviderMeetingsId?: string | null,
        meetingRoomId?: string | null,
      },
      meetingId: string,
      relationshipType: TeamMeetingRelationshipType,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      teamMeetingRelationshipsId?: string | null,
      meetingTeamRelationshipsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetMeetingAttendeeQueryVariables = {
  id: string,
};

export type GetMeetingAttendeeQuery = {
  getMeetingAttendee?:  {
    __typename: "MeetingAttendee",
    id: string,
    profile:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    },
    profileId: string,
    meeting:  {
      __typename: "Meeting",
      id: string,
      title: string,
      titleLowerCase?: string | null,
      startDateTime: string,
      endDateTime: string,
      status: MeetingStatus,
      description?: string | null,
      preferredRoomProvider?:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      preferredRoomProviderId?: string | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      room?:  {
        __typename: "MeetingRoom",
        id: string,
        name: string,
        meetingId: string,
        providerId: string,
        providerMeetingId?: string | null,
        accessCode: string,
        status: MeetingRoomStatus,
        startDateTime?: string | null,
        endDateTime?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        roomCreatedAt?: string | null,
        roomDestroyedAt?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        isReadinessTest: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        meetingRoomProviderMeetingRoomsId?: string | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null,
      productId?: string | null,
      isReadinessTest: boolean,
      labels?: Array< string | null > | null,
      owner?: string | null,
      teamRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerOrganizationName?: string | null,
      sellerTeamName?: string | null,
      buyerTeamName?: string | null,
      sellerOrganizationNameLowerCase?: string | null,
      sellerTeamNameLowerCase?: string | null,
      buyerTeamNameLowerCase?: string | null,
      externalId?: string | null,
      metrics?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productMeetingsId?: string | null,
      meetingRoomProviderMeetingsId?: string | null,
      meetingRoomId?: string | null,
    },
    meetingId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileMeetingAttendeesId?: string | null,
    meetingMeetingAttendeesId?: string | null,
  } | null,
};

export type ListMeetingAttendeesQueryVariables = {
  filter?: ModelMeetingAttendeeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMeetingAttendeesQuery = {
  listMeetingAttendees?:  {
    __typename: "ModelMeetingAttendeeConnection",
    items:  Array< {
      __typename: "MeetingAttendee",
      id: string,
      profile:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      },
      profileId: string,
      meeting:  {
        __typename: "Meeting",
        id: string,
        title: string,
        titleLowerCase?: string | null,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        preferredRoomProviderId?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        sellerOrganizationNameLowerCase?: string | null,
        sellerTeamNameLowerCase?: string | null,
        buyerTeamNameLowerCase?: string | null,
        externalId?: string | null,
        metrics?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingsId?: string | null,
        meetingRoomProviderMeetingsId?: string | null,
        meetingRoomId?: string | null,
      },
      meetingId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      profileMeetingAttendeesId?: string | null,
      meetingMeetingAttendeesId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncMeetingAttendeesQueryVariables = {
  filter?: ModelMeetingAttendeeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncMeetingAttendeesQuery = {
  syncMeetingAttendees?:  {
    __typename: "ModelMeetingAttendeeConnection",
    items:  Array< {
      __typename: "MeetingAttendee",
      id: string,
      profile:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      },
      profileId: string,
      meeting:  {
        __typename: "Meeting",
        id: string,
        title: string,
        titleLowerCase?: string | null,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        preferredRoomProviderId?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        sellerOrganizationNameLowerCase?: string | null,
        sellerTeamNameLowerCase?: string | null,
        buyerTeamNameLowerCase?: string | null,
        externalId?: string | null,
        metrics?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingsId?: string | null,
        meetingRoomProviderMeetingsId?: string | null,
        meetingRoomId?: string | null,
      },
      meetingId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      profileMeetingAttendeesId?: string | null,
      meetingMeetingAttendeesId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListMeetingAttendeesByProfileIdQueryVariables = {
  profileId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMeetingAttendeeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMeetingAttendeesByProfileIdQuery = {
  listMeetingAttendeesByProfileId?:  {
    __typename: "ModelMeetingAttendeeConnection",
    items:  Array< {
      __typename: "MeetingAttendee",
      id: string,
      profile:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      },
      profileId: string,
      meeting:  {
        __typename: "Meeting",
        id: string,
        title: string,
        titleLowerCase?: string | null,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        preferredRoomProviderId?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        sellerOrganizationNameLowerCase?: string | null,
        sellerTeamNameLowerCase?: string | null,
        buyerTeamNameLowerCase?: string | null,
        externalId?: string | null,
        metrics?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingsId?: string | null,
        meetingRoomProviderMeetingsId?: string | null,
        meetingRoomId?: string | null,
      },
      meetingId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      profileMeetingAttendeesId?: string | null,
      meetingMeetingAttendeesId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListMeetingAttendeesByMeetingIdQueryVariables = {
  meetingId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMeetingAttendeeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMeetingAttendeesByMeetingIdQuery = {
  listMeetingAttendeesByMeetingId?:  {
    __typename: "ModelMeetingAttendeeConnection",
    items:  Array< {
      __typename: "MeetingAttendee",
      id: string,
      profile:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      },
      profileId: string,
      meeting:  {
        __typename: "Meeting",
        id: string,
        title: string,
        titleLowerCase?: string | null,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        preferredRoomProviderId?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        sellerOrganizationNameLowerCase?: string | null,
        sellerTeamNameLowerCase?: string | null,
        buyerTeamNameLowerCase?: string | null,
        externalId?: string | null,
        metrics?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingsId?: string | null,
        meetingRoomProviderMeetingsId?: string | null,
        meetingRoomId?: string | null,
      },
      meetingId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      profileMeetingAttendeesId?: string | null,
      meetingMeetingAttendeesId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetMeetingQueryVariables = {
  id: string,
};

export type GetMeetingQuery = {
  getMeeting?:  {
    __typename: "Meeting",
    id: string,
    title: string,
    titleLowerCase?: string | null,
    startDateTime: string,
    endDateTime: string,
    status: MeetingStatus,
    description?: string | null,
    preferredRoomProvider?:  {
      __typename: "MeetingRoomProvider",
      id: string,
      name: string,
      componentName?: string | null,
      description?: string | null,
      configuration?: string | null,
      meetings?:  {
        __typename: "ModelMeetingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingRooms?:  {
        __typename: "ModelMeetingRoomConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    preferredRoomProviderId?: string | null,
    actualStartDateTime?: string | null,
    actualEndDateTime?: string | null,
    room?:  {
      __typename: "MeetingRoom",
      id: string,
      name: string,
      meeting:  {
        __typename: "Meeting",
        id: string,
        title: string,
        titleLowerCase?: string | null,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        preferredRoomProviderId?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        sellerOrganizationNameLowerCase?: string | null,
        sellerTeamNameLowerCase?: string | null,
        buyerTeamNameLowerCase?: string | null,
        externalId?: string | null,
        metrics?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingsId?: string | null,
        meetingRoomProviderMeetingsId?: string | null,
        meetingRoomId?: string | null,
      },
      meetingId: string,
      provider:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      providerId: string,
      providerMeetingId?: string | null,
      accessCode: string,
      status: MeetingRoomStatus,
      startDateTime?: string | null,
      endDateTime?: string | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      roomCreatedAt?: string | null,
      roomDestroyedAt?: string | null,
      auditlogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      isReadinessTest: boolean,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      meetingRoomProviderMeetingRoomsId?: string | null,
    } | null,
    questionResponses?:  {
      __typename: "ModelQuestionResponseConnection",
      items:  Array< {
        __typename: "QuestionResponse",
        id: string,
        response?: string | null,
        privateNotes?: string | null,
        questionId: string,
        profileId: string,
        meetingId: string,
        meetingInviteId: string,
        owner?: string | null,
        contactRequestTimeframe?: string | null,
        contactRequestDateTime?: string | null,
        contactPhoneNumber?: string | null,
        contactEmail?: string | null,
        contactName?: string | null,
        providePersonalEmail?: boolean | null,
        providePersonalPhoneNumber?: boolean | null,
        textOnly?: boolean | null,
        followupMeetingRequested?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productQuestionResponsesId?: string | null,
        profileQuestionResponsesId?: string | null,
        meetingQuestionResponsesId?: string | null,
        meetingInviteQuestionResponsesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    privacyEnabled: boolean,
    videoEnabled: boolean,
    transcriptionEnabled: boolean,
    product?:  {
      __typename: "Product",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      serviceInformation?: string | null,
      images?:  {
        __typename: "ModelProductImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questions?:  {
        __typename: "ModelProductQuestionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      salesTeam?:  {
        __typename: "Team",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        labels?: Array< string | null > | null,
        privacyEnabled: boolean,
        privacyEnableOtherTeams?: boolean | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      salesTeamId?: string | null,
      meetings?:  {
        __typename: "ModelMeetingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProductsId?: string | null,
      teamProductsId?: string | null,
    } | null,
    productId?: string | null,
    isReadinessTest: boolean,
    labels?: Array< string | null > | null,
    owner?: string | null,
    teamRelationships?:  {
      __typename: "ModelTeamMeetingRelationshipConnection",
      items:  Array< {
        __typename: "TeamMeetingRelationship",
        id: string,
        teamId: string,
        meetingId: string,
        relationshipType: TeamMeetingRelationshipType,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        teamMeetingRelationshipsId?: string | null,
        meetingTeamRelationshipsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    meetingAttendees?:  {
      __typename: "ModelMeetingAttendeeConnection",
      items:  Array< {
        __typename: "MeetingAttendee",
        id: string,
        profileId: string,
        meetingId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileMeetingAttendeesId?: string | null,
        meetingMeetingAttendeesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    transcriptions?:  {
      __typename: "ModelTranscriptionConnection",
      items:  Array< {
        __typename: "Transcription",
        id: string,
        date: string,
        title: string,
        description?: string | null,
        startDateTime: string,
        endDateTime: string,
        sizeInBytes?: number | null,
        numberOfTranscriptions?: number | null,
        isZipFile: boolean,
        meetingId?: string | null,
        teamId?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        teamTranscriptionsId?: string | null,
        meetingTranscriptionsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    sellerOrganizationName?: string | null,
    sellerTeamName?: string | null,
    buyerTeamName?: string | null,
    sellerOrganizationNameLowerCase?: string | null,
    sellerTeamNameLowerCase?: string | null,
    buyerTeamNameLowerCase?: string | null,
    externalId?: string | null,
    metrics?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    productMeetingsId?: string | null,
    meetingRoomProviderMeetingsId?: string | null,
    meetingRoomId?: string | null,
  } | null,
};

export type ListMeetingsQueryVariables = {
  filter?: ModelMeetingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMeetingsQuery = {
  listMeetings?:  {
    __typename: "ModelMeetingConnection",
    items:  Array< {
      __typename: "Meeting",
      id: string,
      title: string,
      titleLowerCase?: string | null,
      startDateTime: string,
      endDateTime: string,
      status: MeetingStatus,
      description?: string | null,
      preferredRoomProvider?:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      preferredRoomProviderId?: string | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      room?:  {
        __typename: "MeetingRoom",
        id: string,
        name: string,
        meetingId: string,
        providerId: string,
        providerMeetingId?: string | null,
        accessCode: string,
        status: MeetingRoomStatus,
        startDateTime?: string | null,
        endDateTime?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        roomCreatedAt?: string | null,
        roomDestroyedAt?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        isReadinessTest: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        meetingRoomProviderMeetingRoomsId?: string | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null,
      productId?: string | null,
      isReadinessTest: boolean,
      labels?: Array< string | null > | null,
      owner?: string | null,
      teamRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerOrganizationName?: string | null,
      sellerTeamName?: string | null,
      buyerTeamName?: string | null,
      sellerOrganizationNameLowerCase?: string | null,
      sellerTeamNameLowerCase?: string | null,
      buyerTeamNameLowerCase?: string | null,
      externalId?: string | null,
      metrics?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productMeetingsId?: string | null,
      meetingRoomProviderMeetingsId?: string | null,
      meetingRoomId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncMeetingsQueryVariables = {
  filter?: ModelMeetingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncMeetingsQuery = {
  syncMeetings?:  {
    __typename: "ModelMeetingConnection",
    items:  Array< {
      __typename: "Meeting",
      id: string,
      title: string,
      titleLowerCase?: string | null,
      startDateTime: string,
      endDateTime: string,
      status: MeetingStatus,
      description?: string | null,
      preferredRoomProvider?:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      preferredRoomProviderId?: string | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      room?:  {
        __typename: "MeetingRoom",
        id: string,
        name: string,
        meetingId: string,
        providerId: string,
        providerMeetingId?: string | null,
        accessCode: string,
        status: MeetingRoomStatus,
        startDateTime?: string | null,
        endDateTime?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        roomCreatedAt?: string | null,
        roomDestroyedAt?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        isReadinessTest: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        meetingRoomProviderMeetingRoomsId?: string | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null,
      productId?: string | null,
      isReadinessTest: boolean,
      labels?: Array< string | null > | null,
      owner?: string | null,
      teamRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerOrganizationName?: string | null,
      sellerTeamName?: string | null,
      buyerTeamName?: string | null,
      sellerOrganizationNameLowerCase?: string | null,
      sellerTeamNameLowerCase?: string | null,
      buyerTeamNameLowerCase?: string | null,
      externalId?: string | null,
      metrics?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productMeetingsId?: string | null,
      meetingRoomProviderMeetingsId?: string | null,
      meetingRoomId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListMeetingsByTitleQueryVariables = {
  title: string,
  startDateTime?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMeetingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMeetingsByTitleQuery = {
  listMeetingsByTitle?:  {
    __typename: "ModelMeetingConnection",
    items:  Array< {
      __typename: "Meeting",
      id: string,
      title: string,
      titleLowerCase?: string | null,
      startDateTime: string,
      endDateTime: string,
      status: MeetingStatus,
      description?: string | null,
      preferredRoomProvider?:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      preferredRoomProviderId?: string | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      room?:  {
        __typename: "MeetingRoom",
        id: string,
        name: string,
        meetingId: string,
        providerId: string,
        providerMeetingId?: string | null,
        accessCode: string,
        status: MeetingRoomStatus,
        startDateTime?: string | null,
        endDateTime?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        roomCreatedAt?: string | null,
        roomDestroyedAt?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        isReadinessTest: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        meetingRoomProviderMeetingRoomsId?: string | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null,
      productId?: string | null,
      isReadinessTest: boolean,
      labels?: Array< string | null > | null,
      owner?: string | null,
      teamRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerOrganizationName?: string | null,
      sellerTeamName?: string | null,
      buyerTeamName?: string | null,
      sellerOrganizationNameLowerCase?: string | null,
      sellerTeamNameLowerCase?: string | null,
      buyerTeamNameLowerCase?: string | null,
      externalId?: string | null,
      metrics?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productMeetingsId?: string | null,
      meetingRoomProviderMeetingsId?: string | null,
      meetingRoomId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListMeetingsByTitleLowerCaseQueryVariables = {
  titleLowerCase: string,
  title?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMeetingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMeetingsByTitleLowerCaseQuery = {
  listMeetingsByTitleLowerCase?:  {
    __typename: "ModelMeetingConnection",
    items:  Array< {
      __typename: "Meeting",
      id: string,
      title: string,
      titleLowerCase?: string | null,
      startDateTime: string,
      endDateTime: string,
      status: MeetingStatus,
      description?: string | null,
      preferredRoomProvider?:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      preferredRoomProviderId?: string | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      room?:  {
        __typename: "MeetingRoom",
        id: string,
        name: string,
        meetingId: string,
        providerId: string,
        providerMeetingId?: string | null,
        accessCode: string,
        status: MeetingRoomStatus,
        startDateTime?: string | null,
        endDateTime?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        roomCreatedAt?: string | null,
        roomDestroyedAt?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        isReadinessTest: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        meetingRoomProviderMeetingRoomsId?: string | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null,
      productId?: string | null,
      isReadinessTest: boolean,
      labels?: Array< string | null > | null,
      owner?: string | null,
      teamRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerOrganizationName?: string | null,
      sellerTeamName?: string | null,
      buyerTeamName?: string | null,
      sellerOrganizationNameLowerCase?: string | null,
      sellerTeamNameLowerCase?: string | null,
      buyerTeamNameLowerCase?: string | null,
      externalId?: string | null,
      metrics?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productMeetingsId?: string | null,
      meetingRoomProviderMeetingsId?: string | null,
      meetingRoomId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListMeetingsByStatusQueryVariables = {
  status: MeetingStatus,
  startDateTime?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMeetingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMeetingsByStatusQuery = {
  listMeetingsByStatus?:  {
    __typename: "ModelMeetingConnection",
    items:  Array< {
      __typename: "Meeting",
      id: string,
      title: string,
      titleLowerCase?: string | null,
      startDateTime: string,
      endDateTime: string,
      status: MeetingStatus,
      description?: string | null,
      preferredRoomProvider?:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      preferredRoomProviderId?: string | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      room?:  {
        __typename: "MeetingRoom",
        id: string,
        name: string,
        meetingId: string,
        providerId: string,
        providerMeetingId?: string | null,
        accessCode: string,
        status: MeetingRoomStatus,
        startDateTime?: string | null,
        endDateTime?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        roomCreatedAt?: string | null,
        roomDestroyedAt?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        isReadinessTest: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        meetingRoomProviderMeetingRoomsId?: string | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null,
      productId?: string | null,
      isReadinessTest: boolean,
      labels?: Array< string | null > | null,
      owner?: string | null,
      teamRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerOrganizationName?: string | null,
      sellerTeamName?: string | null,
      buyerTeamName?: string | null,
      sellerOrganizationNameLowerCase?: string | null,
      sellerTeamNameLowerCase?: string | null,
      buyerTeamNameLowerCase?: string | null,
      externalId?: string | null,
      metrics?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productMeetingsId?: string | null,
      meetingRoomProviderMeetingsId?: string | null,
      meetingRoomId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListMeetingsByPreferredRoomProviderIdQueryVariables = {
  preferredRoomProviderId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMeetingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMeetingsByPreferredRoomProviderIdQuery = {
  listMeetingsByPreferredRoomProviderId?:  {
    __typename: "ModelMeetingConnection",
    items:  Array< {
      __typename: "Meeting",
      id: string,
      title: string,
      titleLowerCase?: string | null,
      startDateTime: string,
      endDateTime: string,
      status: MeetingStatus,
      description?: string | null,
      preferredRoomProvider?:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      preferredRoomProviderId?: string | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      room?:  {
        __typename: "MeetingRoom",
        id: string,
        name: string,
        meetingId: string,
        providerId: string,
        providerMeetingId?: string | null,
        accessCode: string,
        status: MeetingRoomStatus,
        startDateTime?: string | null,
        endDateTime?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        roomCreatedAt?: string | null,
        roomDestroyedAt?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        isReadinessTest: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        meetingRoomProviderMeetingRoomsId?: string | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null,
      productId?: string | null,
      isReadinessTest: boolean,
      labels?: Array< string | null > | null,
      owner?: string | null,
      teamRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerOrganizationName?: string | null,
      sellerTeamName?: string | null,
      buyerTeamName?: string | null,
      sellerOrganizationNameLowerCase?: string | null,
      sellerTeamNameLowerCase?: string | null,
      buyerTeamNameLowerCase?: string | null,
      externalId?: string | null,
      metrics?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productMeetingsId?: string | null,
      meetingRoomProviderMeetingsId?: string | null,
      meetingRoomId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListMeetingsByProductIdQueryVariables = {
  productId: string,
  startDateTime?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMeetingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMeetingsByProductIdQuery = {
  listMeetingsByProductId?:  {
    __typename: "ModelMeetingConnection",
    items:  Array< {
      __typename: "Meeting",
      id: string,
      title: string,
      titleLowerCase?: string | null,
      startDateTime: string,
      endDateTime: string,
      status: MeetingStatus,
      description?: string | null,
      preferredRoomProvider?:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      preferredRoomProviderId?: string | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      room?:  {
        __typename: "MeetingRoom",
        id: string,
        name: string,
        meetingId: string,
        providerId: string,
        providerMeetingId?: string | null,
        accessCode: string,
        status: MeetingRoomStatus,
        startDateTime?: string | null,
        endDateTime?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        roomCreatedAt?: string | null,
        roomDestroyedAt?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        isReadinessTest: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        meetingRoomProviderMeetingRoomsId?: string | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null,
      productId?: string | null,
      isReadinessTest: boolean,
      labels?: Array< string | null > | null,
      owner?: string | null,
      teamRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerOrganizationName?: string | null,
      sellerTeamName?: string | null,
      buyerTeamName?: string | null,
      sellerOrganizationNameLowerCase?: string | null,
      sellerTeamNameLowerCase?: string | null,
      buyerTeamNameLowerCase?: string | null,
      externalId?: string | null,
      metrics?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productMeetingsId?: string | null,
      meetingRoomProviderMeetingsId?: string | null,
      meetingRoomId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListMeetingsByExternalIdQueryVariables = {
  externalId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMeetingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMeetingsByExternalIdQuery = {
  listMeetingsByExternalId?:  {
    __typename: "ModelMeetingConnection",
    items:  Array< {
      __typename: "Meeting",
      id: string,
      title: string,
      titleLowerCase?: string | null,
      startDateTime: string,
      endDateTime: string,
      status: MeetingStatus,
      description?: string | null,
      preferredRoomProvider?:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      preferredRoomProviderId?: string | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      room?:  {
        __typename: "MeetingRoom",
        id: string,
        name: string,
        meetingId: string,
        providerId: string,
        providerMeetingId?: string | null,
        accessCode: string,
        status: MeetingRoomStatus,
        startDateTime?: string | null,
        endDateTime?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        roomCreatedAt?: string | null,
        roomDestroyedAt?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        isReadinessTest: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        meetingRoomProviderMeetingRoomsId?: string | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null,
      productId?: string | null,
      isReadinessTest: boolean,
      labels?: Array< string | null > | null,
      owner?: string | null,
      teamRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerOrganizationName?: string | null,
      sellerTeamName?: string | null,
      buyerTeamName?: string | null,
      sellerOrganizationNameLowerCase?: string | null,
      sellerTeamNameLowerCase?: string | null,
      buyerTeamNameLowerCase?: string | null,
      externalId?: string | null,
      metrics?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productMeetingsId?: string | null,
      meetingRoomProviderMeetingsId?: string | null,
      meetingRoomId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetMeetingInviteQueryVariables = {
  id: string,
};

export type GetMeetingInviteQuery = {
  getMeetingInvite?:  {
    __typename: "MeetingInvite",
    id: string,
    meetingId: string,
    provider?:  {
      __typename: "MeetingRoomProvider",
      id: string,
      name: string,
      componentName?: string | null,
      description?: string | null,
      configuration?: string | null,
      meetings?:  {
        __typename: "ModelMeetingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingRooms?:  {
        __typename: "ModelMeetingRoomConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    providerId?: string | null,
    title: string,
    startDateTime: string,
    endDateTime: string,
    status: MeetingStatus,
    description?: string | null,
    questionResponses?:  {
      __typename: "ModelQuestionResponseConnection",
      items:  Array< {
        __typename: "QuestionResponse",
        id: string,
        response?: string | null,
        privateNotes?: string | null,
        questionId: string,
        profileId: string,
        meetingId: string,
        meetingInviteId: string,
        owner?: string | null,
        contactRequestTimeframe?: string | null,
        contactRequestDateTime?: string | null,
        contactPhoneNumber?: string | null,
        contactEmail?: string | null,
        contactName?: string | null,
        providePersonalEmail?: boolean | null,
        providePersonalPhoneNumber?: boolean | null,
        textOnly?: boolean | null,
        followupMeetingRequested?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productQuestionResponsesId?: string | null,
        profileQuestionResponsesId?: string | null,
        meetingQuestionResponsesId?: string | null,
        meetingInviteQuestionResponsesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    privacyEnabled: boolean,
    videoEnabled: boolean,
    transcriptionEnabled: boolean,
    product?:  {
      __typename: "Product",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      serviceInformation?: string | null,
      images?:  {
        __typename: "ModelProductImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questions?:  {
        __typename: "ModelProductQuestionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      salesTeam?:  {
        __typename: "Team",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        labels?: Array< string | null > | null,
        privacyEnabled: boolean,
        privacyEnableOtherTeams?: boolean | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      salesTeamId?: string | null,
      meetings?:  {
        __typename: "ModelMeetingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProductsId?: string | null,
      teamProductsId?: string | null,
    } | null,
    productId?: string | null,
    isReadinessTest: boolean,
    meetingRoomId?: string | null,
    providerSessionId?: string | null,
    providerJoinData?: string | null,
    sessionStatus?: MeetingRoomSessionStatus | null,
    actualStartDateTime?: string | null,
    actualEndDateTime?: string | null,
    profile:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    },
    profileId: string,
    owner?: string | null,
    meetingInvitationType: MeetingInvitationType,
    teamId?: string | null,
    isInterested?: boolean | null,
    feedbackSummary?: string | null,
    contactRequestTimeframe?: string | null,
    contactPhoneNumber?: string | null,
    contactEmail?: string | null,
    sellerOrganizationName?: string | null,
    sellerTeamName?: string | null,
    buyerTeamName?: string | null,
    transcriptionLogs?:  {
      __typename: "ModelTranscriptionEventAuditLogConnection",
      items:  Array< {
        __typename: "TranscriptionEventAuditLog",
        id: string,
        date: string,
        author: string,
        resource: string,
        action: string,
        payload?: string | null,
        ipAddress?: string | null,
        meetingInviteId: string,
        profileId: string,
        authorProfileId?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileTranscriptionLogsId?: string | null,
        meetingInviteTranscriptionLogsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    productMeetingInvitesId?: string | null,
    profileMeetingInvitesId?: string | null,
    meetingRoomProviderMeetingInvitesId?: string | null,
  } | null,
};

export type ListMeetingInvitesQueryVariables = {
  filter?: ModelMeetingInviteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMeetingInvitesQuery = {
  listMeetingInvites?:  {
    __typename: "ModelMeetingInviteConnection",
    items:  Array< {
      __typename: "MeetingInvite",
      id: string,
      meetingId: string,
      provider?:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      providerId?: string | null,
      title: string,
      startDateTime: string,
      endDateTime: string,
      status: MeetingStatus,
      description?: string | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null,
      productId?: string | null,
      isReadinessTest: boolean,
      meetingRoomId?: string | null,
      providerSessionId?: string | null,
      providerJoinData?: string | null,
      sessionStatus?: MeetingRoomSessionStatus | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      profile:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      },
      profileId: string,
      owner?: string | null,
      meetingInvitationType: MeetingInvitationType,
      teamId?: string | null,
      isInterested?: boolean | null,
      feedbackSummary?: string | null,
      contactRequestTimeframe?: string | null,
      contactPhoneNumber?: string | null,
      contactEmail?: string | null,
      sellerOrganizationName?: string | null,
      sellerTeamName?: string | null,
      buyerTeamName?: string | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productMeetingInvitesId?: string | null,
      profileMeetingInvitesId?: string | null,
      meetingRoomProviderMeetingInvitesId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncMeetingInvitesQueryVariables = {
  filter?: ModelMeetingInviteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncMeetingInvitesQuery = {
  syncMeetingInvites?:  {
    __typename: "ModelMeetingInviteConnection",
    items:  Array< {
      __typename: "MeetingInvite",
      id: string,
      meetingId: string,
      provider?:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      providerId?: string | null,
      title: string,
      startDateTime: string,
      endDateTime: string,
      status: MeetingStatus,
      description?: string | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null,
      productId?: string | null,
      isReadinessTest: boolean,
      meetingRoomId?: string | null,
      providerSessionId?: string | null,
      providerJoinData?: string | null,
      sessionStatus?: MeetingRoomSessionStatus | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      profile:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      },
      profileId: string,
      owner?: string | null,
      meetingInvitationType: MeetingInvitationType,
      teamId?: string | null,
      isInterested?: boolean | null,
      feedbackSummary?: string | null,
      contactRequestTimeframe?: string | null,
      contactPhoneNumber?: string | null,
      contactEmail?: string | null,
      sellerOrganizationName?: string | null,
      sellerTeamName?: string | null,
      buyerTeamName?: string | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productMeetingInvitesId?: string | null,
      profileMeetingInvitesId?: string | null,
      meetingRoomProviderMeetingInvitesId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListMeetingInvitesByMeetingIdQueryVariables = {
  meetingId: string,
  profileId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMeetingInviteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMeetingInvitesByMeetingIdQuery = {
  listMeetingInvitesByMeetingId?:  {
    __typename: "ModelMeetingInviteConnection",
    items:  Array< {
      __typename: "MeetingInvite",
      id: string,
      meetingId: string,
      provider?:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      providerId?: string | null,
      title: string,
      startDateTime: string,
      endDateTime: string,
      status: MeetingStatus,
      description?: string | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null,
      productId?: string | null,
      isReadinessTest: boolean,
      meetingRoomId?: string | null,
      providerSessionId?: string | null,
      providerJoinData?: string | null,
      sessionStatus?: MeetingRoomSessionStatus | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      profile:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      },
      profileId: string,
      owner?: string | null,
      meetingInvitationType: MeetingInvitationType,
      teamId?: string | null,
      isInterested?: boolean | null,
      feedbackSummary?: string | null,
      contactRequestTimeframe?: string | null,
      contactPhoneNumber?: string | null,
      contactEmail?: string | null,
      sellerOrganizationName?: string | null,
      sellerTeamName?: string | null,
      buyerTeamName?: string | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productMeetingInvitesId?: string | null,
      profileMeetingInvitesId?: string | null,
      meetingRoomProviderMeetingInvitesId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListMeetingInvitesByMeetingIdAndMeetingInvitationTypeQueryVariables = {
  meetingId: string,
  meetingInvitationType?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMeetingInviteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMeetingInvitesByMeetingIdAndMeetingInvitationTypeQuery = {
  listMeetingInvitesByMeetingIdAndMeetingInvitationType?:  {
    __typename: "ModelMeetingInviteConnection",
    items:  Array< {
      __typename: "MeetingInvite",
      id: string,
      meetingId: string,
      provider?:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      providerId?: string | null,
      title: string,
      startDateTime: string,
      endDateTime: string,
      status: MeetingStatus,
      description?: string | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null,
      productId?: string | null,
      isReadinessTest: boolean,
      meetingRoomId?: string | null,
      providerSessionId?: string | null,
      providerJoinData?: string | null,
      sessionStatus?: MeetingRoomSessionStatus | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      profile:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      },
      profileId: string,
      owner?: string | null,
      meetingInvitationType: MeetingInvitationType,
      teamId?: string | null,
      isInterested?: boolean | null,
      feedbackSummary?: string | null,
      contactRequestTimeframe?: string | null,
      contactPhoneNumber?: string | null,
      contactEmail?: string | null,
      sellerOrganizationName?: string | null,
      sellerTeamName?: string | null,
      buyerTeamName?: string | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productMeetingInvitesId?: string | null,
      profileMeetingInvitesId?: string | null,
      meetingRoomProviderMeetingInvitesId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListMeetingInvitesByProviderIdQueryVariables = {
  providerId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMeetingInviteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMeetingInvitesByProviderIdQuery = {
  listMeetingInvitesByProviderId?:  {
    __typename: "ModelMeetingInviteConnection",
    items:  Array< {
      __typename: "MeetingInvite",
      id: string,
      meetingId: string,
      provider?:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      providerId?: string | null,
      title: string,
      startDateTime: string,
      endDateTime: string,
      status: MeetingStatus,
      description?: string | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null,
      productId?: string | null,
      isReadinessTest: boolean,
      meetingRoomId?: string | null,
      providerSessionId?: string | null,
      providerJoinData?: string | null,
      sessionStatus?: MeetingRoomSessionStatus | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      profile:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      },
      profileId: string,
      owner?: string | null,
      meetingInvitationType: MeetingInvitationType,
      teamId?: string | null,
      isInterested?: boolean | null,
      feedbackSummary?: string | null,
      contactRequestTimeframe?: string | null,
      contactPhoneNumber?: string | null,
      contactEmail?: string | null,
      sellerOrganizationName?: string | null,
      sellerTeamName?: string | null,
      buyerTeamName?: string | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productMeetingInvitesId?: string | null,
      profileMeetingInvitesId?: string | null,
      meetingRoomProviderMeetingInvitesId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListMeetingInvitesByTitleQueryVariables = {
  title: string,
  startDateTime?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMeetingInviteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMeetingInvitesByTitleQuery = {
  listMeetingInvitesByTitle?:  {
    __typename: "ModelMeetingInviteConnection",
    items:  Array< {
      __typename: "MeetingInvite",
      id: string,
      meetingId: string,
      provider?:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      providerId?: string | null,
      title: string,
      startDateTime: string,
      endDateTime: string,
      status: MeetingStatus,
      description?: string | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null,
      productId?: string | null,
      isReadinessTest: boolean,
      meetingRoomId?: string | null,
      providerSessionId?: string | null,
      providerJoinData?: string | null,
      sessionStatus?: MeetingRoomSessionStatus | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      profile:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      },
      profileId: string,
      owner?: string | null,
      meetingInvitationType: MeetingInvitationType,
      teamId?: string | null,
      isInterested?: boolean | null,
      feedbackSummary?: string | null,
      contactRequestTimeframe?: string | null,
      contactPhoneNumber?: string | null,
      contactEmail?: string | null,
      sellerOrganizationName?: string | null,
      sellerTeamName?: string | null,
      buyerTeamName?: string | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productMeetingInvitesId?: string | null,
      profileMeetingInvitesId?: string | null,
      meetingRoomProviderMeetingInvitesId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListMeetingInvitesByStatusQueryVariables = {
  status: MeetingStatus,
  startDateTime?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMeetingInviteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMeetingInvitesByStatusQuery = {
  listMeetingInvitesByStatus?:  {
    __typename: "ModelMeetingInviteConnection",
    items:  Array< {
      __typename: "MeetingInvite",
      id: string,
      meetingId: string,
      provider?:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      providerId?: string | null,
      title: string,
      startDateTime: string,
      endDateTime: string,
      status: MeetingStatus,
      description?: string | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null,
      productId?: string | null,
      isReadinessTest: boolean,
      meetingRoomId?: string | null,
      providerSessionId?: string | null,
      providerJoinData?: string | null,
      sessionStatus?: MeetingRoomSessionStatus | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      profile:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      },
      profileId: string,
      owner?: string | null,
      meetingInvitationType: MeetingInvitationType,
      teamId?: string | null,
      isInterested?: boolean | null,
      feedbackSummary?: string | null,
      contactRequestTimeframe?: string | null,
      contactPhoneNumber?: string | null,
      contactEmail?: string | null,
      sellerOrganizationName?: string | null,
      sellerTeamName?: string | null,
      buyerTeamName?: string | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productMeetingInvitesId?: string | null,
      profileMeetingInvitesId?: string | null,
      meetingRoomProviderMeetingInvitesId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListMeetingInvitesByProductIdQueryVariables = {
  productId: string,
  startDateTime?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMeetingInviteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMeetingInvitesByProductIdQuery = {
  listMeetingInvitesByProductId?:  {
    __typename: "ModelMeetingInviteConnection",
    items:  Array< {
      __typename: "MeetingInvite",
      id: string,
      meetingId: string,
      provider?:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      providerId?: string | null,
      title: string,
      startDateTime: string,
      endDateTime: string,
      status: MeetingStatus,
      description?: string | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null,
      productId?: string | null,
      isReadinessTest: boolean,
      meetingRoomId?: string | null,
      providerSessionId?: string | null,
      providerJoinData?: string | null,
      sessionStatus?: MeetingRoomSessionStatus | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      profile:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      },
      profileId: string,
      owner?: string | null,
      meetingInvitationType: MeetingInvitationType,
      teamId?: string | null,
      isInterested?: boolean | null,
      feedbackSummary?: string | null,
      contactRequestTimeframe?: string | null,
      contactPhoneNumber?: string | null,
      contactEmail?: string | null,
      sellerOrganizationName?: string | null,
      sellerTeamName?: string | null,
      buyerTeamName?: string | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productMeetingInvitesId?: string | null,
      profileMeetingInvitesId?: string | null,
      meetingRoomProviderMeetingInvitesId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListMeetingInvitesByMeetingRoomIdQueryVariables = {
  meetingRoomId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMeetingInviteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMeetingInvitesByMeetingRoomIdQuery = {
  listMeetingInvitesByMeetingRoomId?:  {
    __typename: "ModelMeetingInviteConnection",
    items:  Array< {
      __typename: "MeetingInvite",
      id: string,
      meetingId: string,
      provider?:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      providerId?: string | null,
      title: string,
      startDateTime: string,
      endDateTime: string,
      status: MeetingStatus,
      description?: string | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null,
      productId?: string | null,
      isReadinessTest: boolean,
      meetingRoomId?: string | null,
      providerSessionId?: string | null,
      providerJoinData?: string | null,
      sessionStatus?: MeetingRoomSessionStatus | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      profile:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      },
      profileId: string,
      owner?: string | null,
      meetingInvitationType: MeetingInvitationType,
      teamId?: string | null,
      isInterested?: boolean | null,
      feedbackSummary?: string | null,
      contactRequestTimeframe?: string | null,
      contactPhoneNumber?: string | null,
      contactEmail?: string | null,
      sellerOrganizationName?: string | null,
      sellerTeamName?: string | null,
      buyerTeamName?: string | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productMeetingInvitesId?: string | null,
      profileMeetingInvitesId?: string | null,
      meetingRoomProviderMeetingInvitesId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListMeetingInvitesBySessionStatusQueryVariables = {
  sessionStatus: MeetingRoomSessionStatus,
  startDateTime?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMeetingInviteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMeetingInvitesBySessionStatusQuery = {
  listMeetingInvitesBySessionStatus?:  {
    __typename: "ModelMeetingInviteConnection",
    items:  Array< {
      __typename: "MeetingInvite",
      id: string,
      meetingId: string,
      provider?:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      providerId?: string | null,
      title: string,
      startDateTime: string,
      endDateTime: string,
      status: MeetingStatus,
      description?: string | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null,
      productId?: string | null,
      isReadinessTest: boolean,
      meetingRoomId?: string | null,
      providerSessionId?: string | null,
      providerJoinData?: string | null,
      sessionStatus?: MeetingRoomSessionStatus | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      profile:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      },
      profileId: string,
      owner?: string | null,
      meetingInvitationType: MeetingInvitationType,
      teamId?: string | null,
      isInterested?: boolean | null,
      feedbackSummary?: string | null,
      contactRequestTimeframe?: string | null,
      contactPhoneNumber?: string | null,
      contactEmail?: string | null,
      sellerOrganizationName?: string | null,
      sellerTeamName?: string | null,
      buyerTeamName?: string | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productMeetingInvitesId?: string | null,
      profileMeetingInvitesId?: string | null,
      meetingRoomProviderMeetingInvitesId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListMeetingInvitesByProfileIdQueryVariables = {
  profileId: string,
  startDateTime?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMeetingInviteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMeetingInvitesByProfileIdQuery = {
  listMeetingInvitesByProfileId?:  {
    __typename: "ModelMeetingInviteConnection",
    items:  Array< {
      __typename: "MeetingInvite",
      id: string,
      meetingId: string,
      provider?:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      providerId?: string | null,
      title: string,
      startDateTime: string,
      endDateTime: string,
      status: MeetingStatus,
      description?: string | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null,
      productId?: string | null,
      isReadinessTest: boolean,
      meetingRoomId?: string | null,
      providerSessionId?: string | null,
      providerJoinData?: string | null,
      sessionStatus?: MeetingRoomSessionStatus | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      profile:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      },
      profileId: string,
      owner?: string | null,
      meetingInvitationType: MeetingInvitationType,
      teamId?: string | null,
      isInterested?: boolean | null,
      feedbackSummary?: string | null,
      contactRequestTimeframe?: string | null,
      contactPhoneNumber?: string | null,
      contactEmail?: string | null,
      sellerOrganizationName?: string | null,
      sellerTeamName?: string | null,
      buyerTeamName?: string | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productMeetingInvitesId?: string | null,
      profileMeetingInvitesId?: string | null,
      meetingRoomProviderMeetingInvitesId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListMeetingInvitesByProfileIdAndDatesQueryVariables = {
  profileId: string,
  startDateTimeEndDateTime?: ModelMeetingInviteMeetingInvitesByProfileIdAndDatesCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMeetingInviteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMeetingInvitesByProfileIdAndDatesQuery = {
  listMeetingInvitesByProfileIdAndDates?:  {
    __typename: "ModelMeetingInviteConnection",
    items:  Array< {
      __typename: "MeetingInvite",
      id: string,
      meetingId: string,
      provider?:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      providerId?: string | null,
      title: string,
      startDateTime: string,
      endDateTime: string,
      status: MeetingStatus,
      description?: string | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null,
      productId?: string | null,
      isReadinessTest: boolean,
      meetingRoomId?: string | null,
      providerSessionId?: string | null,
      providerJoinData?: string | null,
      sessionStatus?: MeetingRoomSessionStatus | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      profile:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      },
      profileId: string,
      owner?: string | null,
      meetingInvitationType: MeetingInvitationType,
      teamId?: string | null,
      isInterested?: boolean | null,
      feedbackSummary?: string | null,
      contactRequestTimeframe?: string | null,
      contactPhoneNumber?: string | null,
      contactEmail?: string | null,
      sellerOrganizationName?: string | null,
      sellerTeamName?: string | null,
      buyerTeamName?: string | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productMeetingInvitesId?: string | null,
      profileMeetingInvitesId?: string | null,
      meetingRoomProviderMeetingInvitesId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListMeetingInvitesByTeamIdQueryVariables = {
  teamId: string,
  startDateTime?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMeetingInviteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMeetingInvitesByTeamIdQuery = {
  listMeetingInvitesByTeamId?:  {
    __typename: "ModelMeetingInviteConnection",
    items:  Array< {
      __typename: "MeetingInvite",
      id: string,
      meetingId: string,
      provider?:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      providerId?: string | null,
      title: string,
      startDateTime: string,
      endDateTime: string,
      status: MeetingStatus,
      description?: string | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null,
      productId?: string | null,
      isReadinessTest: boolean,
      meetingRoomId?: string | null,
      providerSessionId?: string | null,
      providerJoinData?: string | null,
      sessionStatus?: MeetingRoomSessionStatus | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      profile:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      },
      profileId: string,
      owner?: string | null,
      meetingInvitationType: MeetingInvitationType,
      teamId?: string | null,
      isInterested?: boolean | null,
      feedbackSummary?: string | null,
      contactRequestTimeframe?: string | null,
      contactPhoneNumber?: string | null,
      contactEmail?: string | null,
      sellerOrganizationName?: string | null,
      sellerTeamName?: string | null,
      buyerTeamName?: string | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productMeetingInvitesId?: string | null,
      profileMeetingInvitesId?: string | null,
      meetingRoomProviderMeetingInvitesId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListMeetingInvitesByTeamIdAndStatusQueryVariables = {
  teamId: string,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMeetingInviteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMeetingInvitesByTeamIdAndStatusQuery = {
  listMeetingInvitesByTeamIdAndStatus?:  {
    __typename: "ModelMeetingInviteConnection",
    items:  Array< {
      __typename: "MeetingInvite",
      id: string,
      meetingId: string,
      provider?:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      providerId?: string | null,
      title: string,
      startDateTime: string,
      endDateTime: string,
      status: MeetingStatus,
      description?: string | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null,
      productId?: string | null,
      isReadinessTest: boolean,
      meetingRoomId?: string | null,
      providerSessionId?: string | null,
      providerJoinData?: string | null,
      sessionStatus?: MeetingRoomSessionStatus | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      profile:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      },
      profileId: string,
      owner?: string | null,
      meetingInvitationType: MeetingInvitationType,
      teamId?: string | null,
      isInterested?: boolean | null,
      feedbackSummary?: string | null,
      contactRequestTimeframe?: string | null,
      contactPhoneNumber?: string | null,
      contactEmail?: string | null,
      sellerOrganizationName?: string | null,
      sellerTeamName?: string | null,
      buyerTeamName?: string | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productMeetingInvitesId?: string | null,
      profileMeetingInvitesId?: string | null,
      meetingRoomProviderMeetingInvitesId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetMeetingRoomQueryVariables = {
  id: string,
};

export type GetMeetingRoomQuery = {
  getMeetingRoom?:  {
    __typename: "MeetingRoom",
    id: string,
    name: string,
    meeting:  {
      __typename: "Meeting",
      id: string,
      title: string,
      titleLowerCase?: string | null,
      startDateTime: string,
      endDateTime: string,
      status: MeetingStatus,
      description?: string | null,
      preferredRoomProvider?:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      preferredRoomProviderId?: string | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      room?:  {
        __typename: "MeetingRoom",
        id: string,
        name: string,
        meetingId: string,
        providerId: string,
        providerMeetingId?: string | null,
        accessCode: string,
        status: MeetingRoomStatus,
        startDateTime?: string | null,
        endDateTime?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        roomCreatedAt?: string | null,
        roomDestroyedAt?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        isReadinessTest: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        meetingRoomProviderMeetingRoomsId?: string | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null,
      productId?: string | null,
      isReadinessTest: boolean,
      labels?: Array< string | null > | null,
      owner?: string | null,
      teamRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerOrganizationName?: string | null,
      sellerTeamName?: string | null,
      buyerTeamName?: string | null,
      sellerOrganizationNameLowerCase?: string | null,
      sellerTeamNameLowerCase?: string | null,
      buyerTeamNameLowerCase?: string | null,
      externalId?: string | null,
      metrics?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productMeetingsId?: string | null,
      meetingRoomProviderMeetingsId?: string | null,
      meetingRoomId?: string | null,
    },
    meetingId: string,
    provider:  {
      __typename: "MeetingRoomProvider",
      id: string,
      name: string,
      componentName?: string | null,
      description?: string | null,
      configuration?: string | null,
      meetings?:  {
        __typename: "ModelMeetingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingRooms?:  {
        __typename: "ModelMeetingRoomConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    providerId: string,
    providerMeetingId?: string | null,
    accessCode: string,
    status: MeetingRoomStatus,
    startDateTime?: string | null,
    endDateTime?: string | null,
    actualStartDateTime?: string | null,
    actualEndDateTime?: string | null,
    roomCreatedAt?: string | null,
    roomDestroyedAt?: string | null,
    auditlogs?:  {
      __typename: "ModelMeetingRoomEventAuditLogConnection",
      items:  Array< {
        __typename: "MeetingRoomEventAuditLog",
        id: string,
        date: string,
        author: string,
        resource: string,
        action: string,
        payload?: string | null,
        ipAddress?: string | null,
        meetingRoomId: string,
        profileId?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileAuditLogsId?: string | null,
        meetingRoomAuditlogsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    privacyEnabled: boolean,
    videoEnabled: boolean,
    transcriptionEnabled: boolean,
    isReadinessTest: boolean,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    meetingRoomProviderMeetingRoomsId?: string | null,
  } | null,
};

export type ListMeetingRoomsQueryVariables = {
  filter?: ModelMeetingRoomFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMeetingRoomsQuery = {
  listMeetingRooms?:  {
    __typename: "ModelMeetingRoomConnection",
    items:  Array< {
      __typename: "MeetingRoom",
      id: string,
      name: string,
      meeting:  {
        __typename: "Meeting",
        id: string,
        title: string,
        titleLowerCase?: string | null,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        preferredRoomProviderId?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        sellerOrganizationNameLowerCase?: string | null,
        sellerTeamNameLowerCase?: string | null,
        buyerTeamNameLowerCase?: string | null,
        externalId?: string | null,
        metrics?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingsId?: string | null,
        meetingRoomProviderMeetingsId?: string | null,
        meetingRoomId?: string | null,
      },
      meetingId: string,
      provider:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      providerId: string,
      providerMeetingId?: string | null,
      accessCode: string,
      status: MeetingRoomStatus,
      startDateTime?: string | null,
      endDateTime?: string | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      roomCreatedAt?: string | null,
      roomDestroyedAt?: string | null,
      auditlogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      isReadinessTest: boolean,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      meetingRoomProviderMeetingRoomsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncMeetingRoomsQueryVariables = {
  filter?: ModelMeetingRoomFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncMeetingRoomsQuery = {
  syncMeetingRooms?:  {
    __typename: "ModelMeetingRoomConnection",
    items:  Array< {
      __typename: "MeetingRoom",
      id: string,
      name: string,
      meeting:  {
        __typename: "Meeting",
        id: string,
        title: string,
        titleLowerCase?: string | null,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        preferredRoomProviderId?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        sellerOrganizationNameLowerCase?: string | null,
        sellerTeamNameLowerCase?: string | null,
        buyerTeamNameLowerCase?: string | null,
        externalId?: string | null,
        metrics?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingsId?: string | null,
        meetingRoomProviderMeetingsId?: string | null,
        meetingRoomId?: string | null,
      },
      meetingId: string,
      provider:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      providerId: string,
      providerMeetingId?: string | null,
      accessCode: string,
      status: MeetingRoomStatus,
      startDateTime?: string | null,
      endDateTime?: string | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      roomCreatedAt?: string | null,
      roomDestroyedAt?: string | null,
      auditlogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      isReadinessTest: boolean,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      meetingRoomProviderMeetingRoomsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListMeetingRoomsByMeetingIdQueryVariables = {
  meetingId: string,
  providerId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMeetingRoomFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMeetingRoomsByMeetingIdQuery = {
  listMeetingRoomsByMeetingId?:  {
    __typename: "ModelMeetingRoomConnection",
    items:  Array< {
      __typename: "MeetingRoom",
      id: string,
      name: string,
      meeting:  {
        __typename: "Meeting",
        id: string,
        title: string,
        titleLowerCase?: string | null,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        preferredRoomProviderId?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        sellerOrganizationNameLowerCase?: string | null,
        sellerTeamNameLowerCase?: string | null,
        buyerTeamNameLowerCase?: string | null,
        externalId?: string | null,
        metrics?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingsId?: string | null,
        meetingRoomProviderMeetingsId?: string | null,
        meetingRoomId?: string | null,
      },
      meetingId: string,
      provider:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      providerId: string,
      providerMeetingId?: string | null,
      accessCode: string,
      status: MeetingRoomStatus,
      startDateTime?: string | null,
      endDateTime?: string | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      roomCreatedAt?: string | null,
      roomDestroyedAt?: string | null,
      auditlogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      isReadinessTest: boolean,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      meetingRoomProviderMeetingRoomsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListMeetingRoomsByProviderIdQueryVariables = {
  providerId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMeetingRoomFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMeetingRoomsByProviderIdQuery = {
  listMeetingRoomsByProviderId?:  {
    __typename: "ModelMeetingRoomConnection",
    items:  Array< {
      __typename: "MeetingRoom",
      id: string,
      name: string,
      meeting:  {
        __typename: "Meeting",
        id: string,
        title: string,
        titleLowerCase?: string | null,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        preferredRoomProviderId?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        sellerOrganizationNameLowerCase?: string | null,
        sellerTeamNameLowerCase?: string | null,
        buyerTeamNameLowerCase?: string | null,
        externalId?: string | null,
        metrics?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingsId?: string | null,
        meetingRoomProviderMeetingsId?: string | null,
        meetingRoomId?: string | null,
      },
      meetingId: string,
      provider:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      providerId: string,
      providerMeetingId?: string | null,
      accessCode: string,
      status: MeetingRoomStatus,
      startDateTime?: string | null,
      endDateTime?: string | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      roomCreatedAt?: string | null,
      roomDestroyedAt?: string | null,
      auditlogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      isReadinessTest: boolean,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      meetingRoomProviderMeetingRoomsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListMeetingRoomsByAccessCodeQueryVariables = {
  accessCode: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMeetingRoomFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMeetingRoomsByAccessCodeQuery = {
  listMeetingRoomsByAccessCode?:  {
    __typename: "ModelMeetingRoomConnection",
    items:  Array< {
      __typename: "MeetingRoom",
      id: string,
      name: string,
      meeting:  {
        __typename: "Meeting",
        id: string,
        title: string,
        titleLowerCase?: string | null,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        preferredRoomProviderId?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        sellerOrganizationNameLowerCase?: string | null,
        sellerTeamNameLowerCase?: string | null,
        buyerTeamNameLowerCase?: string | null,
        externalId?: string | null,
        metrics?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingsId?: string | null,
        meetingRoomProviderMeetingsId?: string | null,
        meetingRoomId?: string | null,
      },
      meetingId: string,
      provider:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      providerId: string,
      providerMeetingId?: string | null,
      accessCode: string,
      status: MeetingRoomStatus,
      startDateTime?: string | null,
      endDateTime?: string | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      roomCreatedAt?: string | null,
      roomDestroyedAt?: string | null,
      auditlogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      isReadinessTest: boolean,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      meetingRoomProviderMeetingRoomsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetMeetingRoomEventAuditLogQueryVariables = {
  id: string,
};

export type GetMeetingRoomEventAuditLogQuery = {
  getMeetingRoomEventAuditLog?:  {
    __typename: "MeetingRoomEventAuditLog",
    id: string,
    date: string,
    author: string,
    resource: string,
    action: string,
    payload?: string | null,
    ipAddress?: string | null,
    meetingRoom:  {
      __typename: "MeetingRoom",
      id: string,
      name: string,
      meeting:  {
        __typename: "Meeting",
        id: string,
        title: string,
        titleLowerCase?: string | null,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        preferredRoomProviderId?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        sellerOrganizationNameLowerCase?: string | null,
        sellerTeamNameLowerCase?: string | null,
        buyerTeamNameLowerCase?: string | null,
        externalId?: string | null,
        metrics?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingsId?: string | null,
        meetingRoomProviderMeetingsId?: string | null,
        meetingRoomId?: string | null,
      },
      meetingId: string,
      provider:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      providerId: string,
      providerMeetingId?: string | null,
      accessCode: string,
      status: MeetingRoomStatus,
      startDateTime?: string | null,
      endDateTime?: string | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      roomCreatedAt?: string | null,
      roomDestroyedAt?: string | null,
      auditlogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      isReadinessTest: boolean,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      meetingRoomProviderMeetingRoomsId?: string | null,
    },
    meetingRoomId: string,
    profile?:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    } | null,
    profileId?: string | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileAuditLogsId?: string | null,
    meetingRoomAuditlogsId?: string | null,
  } | null,
};

export type ListMeetingRoomEventAuditLogsQueryVariables = {
  filter?: ModelMeetingRoomEventAuditLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMeetingRoomEventAuditLogsQuery = {
  listMeetingRoomEventAuditLogs?:  {
    __typename: "ModelMeetingRoomEventAuditLogConnection",
    items:  Array< {
      __typename: "MeetingRoomEventAuditLog",
      id: string,
      date: string,
      author: string,
      resource: string,
      action: string,
      payload?: string | null,
      ipAddress?: string | null,
      meetingRoom:  {
        __typename: "MeetingRoom",
        id: string,
        name: string,
        meetingId: string,
        providerId: string,
        providerMeetingId?: string | null,
        accessCode: string,
        status: MeetingRoomStatus,
        startDateTime?: string | null,
        endDateTime?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        roomCreatedAt?: string | null,
        roomDestroyedAt?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        isReadinessTest: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        meetingRoomProviderMeetingRoomsId?: string | null,
      },
      meetingRoomId: string,
      profile?:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      } | null,
      profileId?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      profileAuditLogsId?: string | null,
      meetingRoomAuditlogsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncMeetingRoomEventAuditLogsQueryVariables = {
  filter?: ModelMeetingRoomEventAuditLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncMeetingRoomEventAuditLogsQuery = {
  syncMeetingRoomEventAuditLogs?:  {
    __typename: "ModelMeetingRoomEventAuditLogConnection",
    items:  Array< {
      __typename: "MeetingRoomEventAuditLog",
      id: string,
      date: string,
      author: string,
      resource: string,
      action: string,
      payload?: string | null,
      ipAddress?: string | null,
      meetingRoom:  {
        __typename: "MeetingRoom",
        id: string,
        name: string,
        meetingId: string,
        providerId: string,
        providerMeetingId?: string | null,
        accessCode: string,
        status: MeetingRoomStatus,
        startDateTime?: string | null,
        endDateTime?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        roomCreatedAt?: string | null,
        roomDestroyedAt?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        isReadinessTest: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        meetingRoomProviderMeetingRoomsId?: string | null,
      },
      meetingRoomId: string,
      profile?:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      } | null,
      profileId?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      profileAuditLogsId?: string | null,
      meetingRoomAuditlogsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListMeetingRoomEventAuditLogsByMeetingRoomIdQueryVariables = {
  meetingRoomId: string,
  date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMeetingRoomEventAuditLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMeetingRoomEventAuditLogsByMeetingRoomIdQuery = {
  listMeetingRoomEventAuditLogsByMeetingRoomId?:  {
    __typename: "ModelMeetingRoomEventAuditLogConnection",
    items:  Array< {
      __typename: "MeetingRoomEventAuditLog",
      id: string,
      date: string,
      author: string,
      resource: string,
      action: string,
      payload?: string | null,
      ipAddress?: string | null,
      meetingRoom:  {
        __typename: "MeetingRoom",
        id: string,
        name: string,
        meetingId: string,
        providerId: string,
        providerMeetingId?: string | null,
        accessCode: string,
        status: MeetingRoomStatus,
        startDateTime?: string | null,
        endDateTime?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        roomCreatedAt?: string | null,
        roomDestroyedAt?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        isReadinessTest: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        meetingRoomProviderMeetingRoomsId?: string | null,
      },
      meetingRoomId: string,
      profile?:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      } | null,
      profileId?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      profileAuditLogsId?: string | null,
      meetingRoomAuditlogsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListMeetingRoomEventAuditLogsByProfileIdQueryVariables = {
  profileId: string,
  date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMeetingRoomEventAuditLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMeetingRoomEventAuditLogsByProfileIdQuery = {
  listMeetingRoomEventAuditLogsByProfileId?:  {
    __typename: "ModelMeetingRoomEventAuditLogConnection",
    items:  Array< {
      __typename: "MeetingRoomEventAuditLog",
      id: string,
      date: string,
      author: string,
      resource: string,
      action: string,
      payload?: string | null,
      ipAddress?: string | null,
      meetingRoom:  {
        __typename: "MeetingRoom",
        id: string,
        name: string,
        meetingId: string,
        providerId: string,
        providerMeetingId?: string | null,
        accessCode: string,
        status: MeetingRoomStatus,
        startDateTime?: string | null,
        endDateTime?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        roomCreatedAt?: string | null,
        roomDestroyedAt?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        isReadinessTest: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        meetingRoomProviderMeetingRoomsId?: string | null,
      },
      meetingRoomId: string,
      profile?:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      } | null,
      profileId?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      profileAuditLogsId?: string | null,
      meetingRoomAuditlogsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetExportJobQueryVariables = {
  id: string,
};

export type GetExportJobQuery = {
  getExportJob?:  {
    __typename: "ExportJob",
    id: string,
    status: JobStatus,
    profile?:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    } | null,
    profileId?: string | null,
    auditLogs?:  {
      __typename: "ModelExportJobAuditLogConnection",
      items:  Array< {
        __typename: "ExportJobAuditLog",
        id: string,
        date: string,
        author: string,
        resource: string,
        action: string,
        payload?: string | null,
        ipAddress?: string | null,
        exportJobId: string,
        profileId?: string | null,
        owner: string,
        identityId?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileExportJobAuditLogsId?: string | null,
        exportJobAuditLogsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    meetingInvitationType?: MeetingInvitationType | null,
    startDateTime: string,
    endDateTime: string,
    owner: string,
    identityId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileExportJobsId?: string | null,
  } | null,
};

export type ListExportJobsQueryVariables = {
  filter?: ModelExportJobFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListExportJobsQuery = {
  listExportJobs?:  {
    __typename: "ModelExportJobConnection",
    items:  Array< {
      __typename: "ExportJob",
      id: string,
      status: JobStatus,
      profile?:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      } | null,
      profileId?: string | null,
      auditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingInvitationType?: MeetingInvitationType | null,
      startDateTime: string,
      endDateTime: string,
      owner: string,
      identityId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      profileExportJobsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncExportJobsQueryVariables = {
  filter?: ModelExportJobFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncExportJobsQuery = {
  syncExportJobs?:  {
    __typename: "ModelExportJobConnection",
    items:  Array< {
      __typename: "ExportJob",
      id: string,
      status: JobStatus,
      profile?:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      } | null,
      profileId?: string | null,
      auditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingInvitationType?: MeetingInvitationType | null,
      startDateTime: string,
      endDateTime: string,
      owner: string,
      identityId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      profileExportJobsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListExportJobsByStatusQueryVariables = {
  status: JobStatus,
  startDateTime?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelExportJobFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListExportJobsByStatusQuery = {
  listExportJobsByStatus?:  {
    __typename: "ModelExportJobConnection",
    items:  Array< {
      __typename: "ExportJob",
      id: string,
      status: JobStatus,
      profile?:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      } | null,
      profileId?: string | null,
      auditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingInvitationType?: MeetingInvitationType | null,
      startDateTime: string,
      endDateTime: string,
      owner: string,
      identityId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      profileExportJobsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListExportJobsByProfileIdQueryVariables = {
  profileId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelExportJobFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListExportJobsByProfileIdQuery = {
  listExportJobsByProfileId?:  {
    __typename: "ModelExportJobConnection",
    items:  Array< {
      __typename: "ExportJob",
      id: string,
      status: JobStatus,
      profile?:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      } | null,
      profileId?: string | null,
      auditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingInvitationType?: MeetingInvitationType | null,
      startDateTime: string,
      endDateTime: string,
      owner: string,
      identityId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      profileExportJobsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetExportJobAuditLogQueryVariables = {
  id: string,
};

export type GetExportJobAuditLogQuery = {
  getExportJobAuditLog?:  {
    __typename: "ExportJobAuditLog",
    id: string,
    date: string,
    author: string,
    resource: string,
    action: string,
    payload?: string | null,
    ipAddress?: string | null,
    exportJob:  {
      __typename: "ExportJob",
      id: string,
      status: JobStatus,
      profile?:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      } | null,
      profileId?: string | null,
      auditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingInvitationType?: MeetingInvitationType | null,
      startDateTime: string,
      endDateTime: string,
      owner: string,
      identityId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      profileExportJobsId?: string | null,
    },
    exportJobId: string,
    profile?:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    } | null,
    profileId?: string | null,
    owner: string,
    source?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    identityId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileExportJobAuditLogsId?: string | null,
    exportJobAuditLogsId?: string | null,
  } | null,
};

export type ListExportJobAuditLogsQueryVariables = {
  filter?: ModelExportJobAuditLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListExportJobAuditLogsQuery = {
  listExportJobAuditLogs?:  {
    __typename: "ModelExportJobAuditLogConnection",
    items:  Array< {
      __typename: "ExportJobAuditLog",
      id: string,
      date: string,
      author: string,
      resource: string,
      action: string,
      payload?: string | null,
      ipAddress?: string | null,
      exportJob:  {
        __typename: "ExportJob",
        id: string,
        status: JobStatus,
        profileId?: string | null,
        meetingInvitationType?: MeetingInvitationType | null,
        startDateTime: string,
        endDateTime: string,
        owner: string,
        identityId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileExportJobsId?: string | null,
      },
      exportJobId: string,
      profile?:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      } | null,
      profileId?: string | null,
      owner: string,
      source?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      identityId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      profileExportJobAuditLogsId?: string | null,
      exportJobAuditLogsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncExportJobAuditLogsQueryVariables = {
  filter?: ModelExportJobAuditLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncExportJobAuditLogsQuery = {
  syncExportJobAuditLogs?:  {
    __typename: "ModelExportJobAuditLogConnection",
    items:  Array< {
      __typename: "ExportJobAuditLog",
      id: string,
      date: string,
      author: string,
      resource: string,
      action: string,
      payload?: string | null,
      ipAddress?: string | null,
      exportJob:  {
        __typename: "ExportJob",
        id: string,
        status: JobStatus,
        profileId?: string | null,
        meetingInvitationType?: MeetingInvitationType | null,
        startDateTime: string,
        endDateTime: string,
        owner: string,
        identityId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileExportJobsId?: string | null,
      },
      exportJobId: string,
      profile?:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      } | null,
      profileId?: string | null,
      owner: string,
      source?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      identityId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      profileExportJobAuditLogsId?: string | null,
      exportJobAuditLogsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListExportJobAuditLogsByExportJobIdQueryVariables = {
  exportJobId: string,
  date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelExportJobAuditLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListExportJobAuditLogsByExportJobIdQuery = {
  listExportJobAuditLogsByExportJobId?:  {
    __typename: "ModelExportJobAuditLogConnection",
    items:  Array< {
      __typename: "ExportJobAuditLog",
      id: string,
      date: string,
      author: string,
      resource: string,
      action: string,
      payload?: string | null,
      ipAddress?: string | null,
      exportJob:  {
        __typename: "ExportJob",
        id: string,
        status: JobStatus,
        profileId?: string | null,
        meetingInvitationType?: MeetingInvitationType | null,
        startDateTime: string,
        endDateTime: string,
        owner: string,
        identityId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileExportJobsId?: string | null,
      },
      exportJobId: string,
      profile?:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      } | null,
      profileId?: string | null,
      owner: string,
      source?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      identityId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      profileExportJobAuditLogsId?: string | null,
      exportJobAuditLogsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListExportJobAuditLogsByProfileIdQueryVariables = {
  profileId: string,
  date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelExportJobAuditLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListExportJobAuditLogsByProfileIdQuery = {
  listExportJobAuditLogsByProfileId?:  {
    __typename: "ModelExportJobAuditLogConnection",
    items:  Array< {
      __typename: "ExportJobAuditLog",
      id: string,
      date: string,
      author: string,
      resource: string,
      action: string,
      payload?: string | null,
      ipAddress?: string | null,
      exportJob:  {
        __typename: "ExportJob",
        id: string,
        status: JobStatus,
        profileId?: string | null,
        meetingInvitationType?: MeetingInvitationType | null,
        startDateTime: string,
        endDateTime: string,
        owner: string,
        identityId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileExportJobsId?: string | null,
      },
      exportJobId: string,
      profile?:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      } | null,
      profileId?: string | null,
      owner: string,
      source?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      identityId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      profileExportJobAuditLogsId?: string | null,
      exportJobAuditLogsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetImportJobQueryVariables = {
  id: string,
};

export type GetImportJobQuery = {
  getImportJob?:  {
    __typename: "ImportJob",
    id: string,
    status: JobStatus,
    source:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    },
    identityId?: string | null,
    result?: string | null,
    profile?:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    } | null,
    profileId?: string | null,
    auditLogs?:  {
      __typename: "ModelImportJobAuditLogConnection",
      items:  Array< {
        __typename: "ImportJobAuditLog",
        id: string,
        date: string,
        author: string,
        resource: string,
        action: string,
        payload?: string | null,
        ipAddress?: string | null,
        importJobId: string,
        profileId?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileJobAuditLogsId?: string | null,
        importJobAuditLogsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    lastStartDateTime?: string | null,
    lastEndDateTime?: string | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileImportJobsId?: string | null,
  } | null,
};

export type ListImportJobsQueryVariables = {
  filter?: ModelImportJobFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListImportJobsQuery = {
  listImportJobs?:  {
    __typename: "ModelImportJobConnection",
    items:  Array< {
      __typename: "ImportJob",
      id: string,
      status: JobStatus,
      source:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      identityId?: string | null,
      result?: string | null,
      profile?:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      } | null,
      profileId?: string | null,
      auditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      lastStartDateTime?: string | null,
      lastEndDateTime?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      profileImportJobsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncImportJobsQueryVariables = {
  filter?: ModelImportJobFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncImportJobsQuery = {
  syncImportJobs?:  {
    __typename: "ModelImportJobConnection",
    items:  Array< {
      __typename: "ImportJob",
      id: string,
      status: JobStatus,
      source:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      identityId?: string | null,
      result?: string | null,
      profile?:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      } | null,
      profileId?: string | null,
      auditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      lastStartDateTime?: string | null,
      lastEndDateTime?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      profileImportJobsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListImportJobsByStatusQueryVariables = {
  status: JobStatus,
  lastStartDateTime?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelImportJobFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListImportJobsByStatusQuery = {
  listImportJobsByStatus?:  {
    __typename: "ModelImportJobConnection",
    items:  Array< {
      __typename: "ImportJob",
      id: string,
      status: JobStatus,
      source:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      identityId?: string | null,
      result?: string | null,
      profile?:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      } | null,
      profileId?: string | null,
      auditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      lastStartDateTime?: string | null,
      lastEndDateTime?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      profileImportJobsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListImportJobsByProfileIdQueryVariables = {
  profileId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelImportJobFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListImportJobsByProfileIdQuery = {
  listImportJobsByProfileId?:  {
    __typename: "ModelImportJobConnection",
    items:  Array< {
      __typename: "ImportJob",
      id: string,
      status: JobStatus,
      source:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      identityId?: string | null,
      result?: string | null,
      profile?:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      } | null,
      profileId?: string | null,
      auditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      lastStartDateTime?: string | null,
      lastEndDateTime?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      profileImportJobsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetImportJobAuditLogQueryVariables = {
  id: string,
};

export type GetImportJobAuditLogQuery = {
  getImportJobAuditLog?:  {
    __typename: "ImportJobAuditLog",
    id: string,
    date: string,
    author: string,
    resource: string,
    action: string,
    payload?: string | null,
    ipAddress?: string | null,
    importJob:  {
      __typename: "ImportJob",
      id: string,
      status: JobStatus,
      source:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      identityId?: string | null,
      result?: string | null,
      profile?:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      } | null,
      profileId?: string | null,
      auditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      lastStartDateTime?: string | null,
      lastEndDateTime?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      profileImportJobsId?: string | null,
    },
    importJobId: string,
    profile?:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    } | null,
    profileId?: string | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileJobAuditLogsId?: string | null,
    importJobAuditLogsId?: string | null,
  } | null,
};

export type ListImportJobAuditLogsQueryVariables = {
  filter?: ModelImportJobAuditLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListImportJobAuditLogsQuery = {
  listImportJobAuditLogs?:  {
    __typename: "ModelImportJobAuditLogConnection",
    items:  Array< {
      __typename: "ImportJobAuditLog",
      id: string,
      date: string,
      author: string,
      resource: string,
      action: string,
      payload?: string | null,
      ipAddress?: string | null,
      importJob:  {
        __typename: "ImportJob",
        id: string,
        status: JobStatus,
        identityId?: string | null,
        result?: string | null,
        profileId?: string | null,
        lastStartDateTime?: string | null,
        lastEndDateTime?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileImportJobsId?: string | null,
      },
      importJobId: string,
      profile?:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      } | null,
      profileId?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      profileJobAuditLogsId?: string | null,
      importJobAuditLogsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncImportJobAuditLogsQueryVariables = {
  filter?: ModelImportJobAuditLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncImportJobAuditLogsQuery = {
  syncImportJobAuditLogs?:  {
    __typename: "ModelImportJobAuditLogConnection",
    items:  Array< {
      __typename: "ImportJobAuditLog",
      id: string,
      date: string,
      author: string,
      resource: string,
      action: string,
      payload?: string | null,
      ipAddress?: string | null,
      importJob:  {
        __typename: "ImportJob",
        id: string,
        status: JobStatus,
        identityId?: string | null,
        result?: string | null,
        profileId?: string | null,
        lastStartDateTime?: string | null,
        lastEndDateTime?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileImportJobsId?: string | null,
      },
      importJobId: string,
      profile?:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      } | null,
      profileId?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      profileJobAuditLogsId?: string | null,
      importJobAuditLogsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListImportJobAuditLogsByImportJobIdQueryVariables = {
  importJobId: string,
  date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelImportJobAuditLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListImportJobAuditLogsByImportJobIdQuery = {
  listImportJobAuditLogsByImportJobId?:  {
    __typename: "ModelImportJobAuditLogConnection",
    items:  Array< {
      __typename: "ImportJobAuditLog",
      id: string,
      date: string,
      author: string,
      resource: string,
      action: string,
      payload?: string | null,
      ipAddress?: string | null,
      importJob:  {
        __typename: "ImportJob",
        id: string,
        status: JobStatus,
        identityId?: string | null,
        result?: string | null,
        profileId?: string | null,
        lastStartDateTime?: string | null,
        lastEndDateTime?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileImportJobsId?: string | null,
      },
      importJobId: string,
      profile?:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      } | null,
      profileId?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      profileJobAuditLogsId?: string | null,
      importJobAuditLogsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListImportJobAuditLogsByProfileIdQueryVariables = {
  profileId: string,
  date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelImportJobAuditLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListImportJobAuditLogsByProfileIdQuery = {
  listImportJobAuditLogsByProfileId?:  {
    __typename: "ModelImportJobAuditLogConnection",
    items:  Array< {
      __typename: "ImportJobAuditLog",
      id: string,
      date: string,
      author: string,
      resource: string,
      action: string,
      payload?: string | null,
      ipAddress?: string | null,
      importJob:  {
        __typename: "ImportJob",
        id: string,
        status: JobStatus,
        identityId?: string | null,
        result?: string | null,
        profileId?: string | null,
        lastStartDateTime?: string | null,
        lastEndDateTime?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileImportJobsId?: string | null,
      },
      importJobId: string,
      profile?:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      } | null,
      profileId?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      profileJobAuditLogsId?: string | null,
      importJobAuditLogsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetNotificationQueryVariables = {
  id: string,
};

export type GetNotificationQuery = {
  getNotification?:  {
    __typename: "Notification",
    id: string,
    date: string,
    title: string,
    payload: string,
    read: number,
    dateRead?: string | null,
    owner: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListNotificationsQueryVariables = {
  filter?: ModelNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNotificationsQuery = {
  listNotifications?:  {
    __typename: "ModelNotificationConnection",
    items:  Array< {
      __typename: "Notification",
      id: string,
      date: string,
      title: string,
      payload: string,
      read: number,
      dateRead?: string | null,
      owner: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncNotificationsQueryVariables = {
  filter?: ModelNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncNotificationsQuery = {
  syncNotifications?:  {
    __typename: "ModelNotificationConnection",
    items:  Array< {
      __typename: "Notification",
      id: string,
      date: string,
      title: string,
      payload: string,
      read: number,
      dateRead?: string | null,
      owner: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListNotificationsByReadQueryVariables = {
  read: number,
  date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNotificationsByReadQuery = {
  listNotificationsByRead?:  {
    __typename: "ModelNotificationConnection",
    items:  Array< {
      __typename: "Notification",
      id: string,
      date: string,
      title: string,
      payload: string,
      read: number,
      dateRead?: string | null,
      owner: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetTranscriptionQueryVariables = {
  id: string,
};

export type GetTranscriptionQuery = {
  getTranscription?:  {
    __typename: "Transcription",
    id: string,
    date: string,
    title: string,
    description?: string | null,
    startDateTime: string,
    endDateTime: string,
    result:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    },
    sizeInBytes?: number | null,
    numberOfTranscriptions?: number | null,
    isZipFile: boolean,
    meeting?:  {
      __typename: "Meeting",
      id: string,
      title: string,
      titleLowerCase?: string | null,
      startDateTime: string,
      endDateTime: string,
      status: MeetingStatus,
      description?: string | null,
      preferredRoomProvider?:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      preferredRoomProviderId?: string | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      room?:  {
        __typename: "MeetingRoom",
        id: string,
        name: string,
        meetingId: string,
        providerId: string,
        providerMeetingId?: string | null,
        accessCode: string,
        status: MeetingRoomStatus,
        startDateTime?: string | null,
        endDateTime?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        roomCreatedAt?: string | null,
        roomDestroyedAt?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        isReadinessTest: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        meetingRoomProviderMeetingRoomsId?: string | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null,
      productId?: string | null,
      isReadinessTest: boolean,
      labels?: Array< string | null > | null,
      owner?: string | null,
      teamRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerOrganizationName?: string | null,
      sellerTeamName?: string | null,
      buyerTeamName?: string | null,
      sellerOrganizationNameLowerCase?: string | null,
      sellerTeamNameLowerCase?: string | null,
      buyerTeamNameLowerCase?: string | null,
      externalId?: string | null,
      metrics?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productMeetingsId?: string | null,
      meetingRoomProviderMeetingsId?: string | null,
      meetingRoomId?: string | null,
    } | null,
    meetingId?: string | null,
    team?:  {
      __typename: "Team",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      memberRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      privacyEnabled: boolean,
      privacyEnableOtherTeams?: boolean | null,
      images?:  {
        __typename: "ModelTeamImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    teamId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    teamTranscriptionsId?: string | null,
    meetingTranscriptionsId?: string | null,
  } | null,
};

export type ListTranscriptionsQueryVariables = {
  filter?: ModelTranscriptionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTranscriptionsQuery = {
  listTranscriptions?:  {
    __typename: "ModelTranscriptionConnection",
    items:  Array< {
      __typename: "Transcription",
      id: string,
      date: string,
      title: string,
      description?: string | null,
      startDateTime: string,
      endDateTime: string,
      result:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      sizeInBytes?: number | null,
      numberOfTranscriptions?: number | null,
      isZipFile: boolean,
      meeting?:  {
        __typename: "Meeting",
        id: string,
        title: string,
        titleLowerCase?: string | null,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        preferredRoomProviderId?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        sellerOrganizationNameLowerCase?: string | null,
        sellerTeamNameLowerCase?: string | null,
        buyerTeamNameLowerCase?: string | null,
        externalId?: string | null,
        metrics?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingsId?: string | null,
        meetingRoomProviderMeetingsId?: string | null,
        meetingRoomId?: string | null,
      } | null,
      meetingId?: string | null,
      team?:  {
        __typename: "Team",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        labels?: Array< string | null > | null,
        privacyEnabled: boolean,
        privacyEnableOtherTeams?: boolean | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      teamId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      teamTranscriptionsId?: string | null,
      meetingTranscriptionsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncTranscriptionsQueryVariables = {
  filter?: ModelTranscriptionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncTranscriptionsQuery = {
  syncTranscriptions?:  {
    __typename: "ModelTranscriptionConnection",
    items:  Array< {
      __typename: "Transcription",
      id: string,
      date: string,
      title: string,
      description?: string | null,
      startDateTime: string,
      endDateTime: string,
      result:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      sizeInBytes?: number | null,
      numberOfTranscriptions?: number | null,
      isZipFile: boolean,
      meeting?:  {
        __typename: "Meeting",
        id: string,
        title: string,
        titleLowerCase?: string | null,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        preferredRoomProviderId?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        sellerOrganizationNameLowerCase?: string | null,
        sellerTeamNameLowerCase?: string | null,
        buyerTeamNameLowerCase?: string | null,
        externalId?: string | null,
        metrics?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingsId?: string | null,
        meetingRoomProviderMeetingsId?: string | null,
        meetingRoomId?: string | null,
      } | null,
      meetingId?: string | null,
      team?:  {
        __typename: "Team",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        labels?: Array< string | null > | null,
        privacyEnabled: boolean,
        privacyEnableOtherTeams?: boolean | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      teamId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      teamTranscriptionsId?: string | null,
      meetingTranscriptionsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListTranscriptionsByMeetingIdQueryVariables = {
  meetingId: string,
  startDateTime?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTranscriptionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTranscriptionsByMeetingIdQuery = {
  listTranscriptionsByMeetingId?:  {
    __typename: "ModelTranscriptionConnection",
    items:  Array< {
      __typename: "Transcription",
      id: string,
      date: string,
      title: string,
      description?: string | null,
      startDateTime: string,
      endDateTime: string,
      result:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      sizeInBytes?: number | null,
      numberOfTranscriptions?: number | null,
      isZipFile: boolean,
      meeting?:  {
        __typename: "Meeting",
        id: string,
        title: string,
        titleLowerCase?: string | null,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        preferredRoomProviderId?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        sellerOrganizationNameLowerCase?: string | null,
        sellerTeamNameLowerCase?: string | null,
        buyerTeamNameLowerCase?: string | null,
        externalId?: string | null,
        metrics?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingsId?: string | null,
        meetingRoomProviderMeetingsId?: string | null,
        meetingRoomId?: string | null,
      } | null,
      meetingId?: string | null,
      team?:  {
        __typename: "Team",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        labels?: Array< string | null > | null,
        privacyEnabled: boolean,
        privacyEnableOtherTeams?: boolean | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      teamId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      teamTranscriptionsId?: string | null,
      meetingTranscriptionsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListTranscriptionsByTeamIdQueryVariables = {
  teamId: string,
  startDateTime?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTranscriptionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTranscriptionsByTeamIdQuery = {
  listTranscriptionsByTeamId?:  {
    __typename: "ModelTranscriptionConnection",
    items:  Array< {
      __typename: "Transcription",
      id: string,
      date: string,
      title: string,
      description?: string | null,
      startDateTime: string,
      endDateTime: string,
      result:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      sizeInBytes?: number | null,
      numberOfTranscriptions?: number | null,
      isZipFile: boolean,
      meeting?:  {
        __typename: "Meeting",
        id: string,
        title: string,
        titleLowerCase?: string | null,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        preferredRoomProviderId?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        sellerOrganizationNameLowerCase?: string | null,
        sellerTeamNameLowerCase?: string | null,
        buyerTeamNameLowerCase?: string | null,
        externalId?: string | null,
        metrics?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingsId?: string | null,
        meetingRoomProviderMeetingsId?: string | null,
        meetingRoomId?: string | null,
      } | null,
      meetingId?: string | null,
      team?:  {
        __typename: "Team",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        labels?: Array< string | null > | null,
        privacyEnabled: boolean,
        privacyEnableOtherTeams?: boolean | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      teamId?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      teamTranscriptionsId?: string | null,
      meetingTranscriptionsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetTranscriptionJobQueryVariables = {
  id: string,
};

export type GetTranscriptionJobQuery = {
  getTranscriptionJob?:  {
    __typename: "TranscriptionJob",
    id: string,
    date: string,
    status: JobStatus,
    result?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    meetingInviteId: string,
    auditLogs?:  {
      __typename: "ModelTranscriptionJobAuditLogConnection",
      items:  Array< {
        __typename: "TranscriptionJobAuditLog",
        id: string,
        date: string,
        author: string,
        resource: string,
        action: string,
        payload?: string | null,
        ipAddress?: string | null,
        transcriptionJobId: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        transcriptionJobAuditLogsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    owner: string,
    identityId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListTranscriptionJobsQueryVariables = {
  filter?: ModelTranscriptionJobFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTranscriptionJobsQuery = {
  listTranscriptionJobs?:  {
    __typename: "ModelTranscriptionJobConnection",
    items:  Array< {
      __typename: "TranscriptionJob",
      id: string,
      date: string,
      status: JobStatus,
      result?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      meetingInviteId: string,
      auditLogs?:  {
        __typename: "ModelTranscriptionJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner: string,
      identityId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncTranscriptionJobsQueryVariables = {
  filter?: ModelTranscriptionJobFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncTranscriptionJobsQuery = {
  syncTranscriptionJobs?:  {
    __typename: "ModelTranscriptionJobConnection",
    items:  Array< {
      __typename: "TranscriptionJob",
      id: string,
      date: string,
      status: JobStatus,
      result?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      meetingInviteId: string,
      auditLogs?:  {
        __typename: "ModelTranscriptionJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner: string,
      identityId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListTranscriptionJobsByStatusQueryVariables = {
  status: JobStatus,
  date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTranscriptionJobFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTranscriptionJobsByStatusQuery = {
  listTranscriptionJobsByStatus?:  {
    __typename: "ModelTranscriptionJobConnection",
    items:  Array< {
      __typename: "TranscriptionJob",
      id: string,
      date: string,
      status: JobStatus,
      result?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      meetingInviteId: string,
      auditLogs?:  {
        __typename: "ModelTranscriptionJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner: string,
      identityId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListTranscriptionJobsByMeetingInviteIdQueryVariables = {
  meetingInviteId: string,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTranscriptionJobFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTranscriptionJobsByMeetingInviteIdQuery = {
  listTranscriptionJobsByMeetingInviteId?:  {
    __typename: "ModelTranscriptionJobConnection",
    items:  Array< {
      __typename: "TranscriptionJob",
      id: string,
      date: string,
      status: JobStatus,
      result?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      meetingInviteId: string,
      auditLogs?:  {
        __typename: "ModelTranscriptionJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner: string,
      identityId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetTranscriptionJobAuditLogQueryVariables = {
  id: string,
};

export type GetTranscriptionJobAuditLogQuery = {
  getTranscriptionJobAuditLog?:  {
    __typename: "TranscriptionJobAuditLog",
    id: string,
    date: string,
    author: string,
    resource: string,
    action: string,
    payload?: string | null,
    ipAddress?: string | null,
    transcriptionJob:  {
      __typename: "TranscriptionJob",
      id: string,
      date: string,
      status: JobStatus,
      result?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      meetingInviteId: string,
      auditLogs?:  {
        __typename: "ModelTranscriptionJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner: string,
      identityId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    transcriptionJobId: string,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    transcriptionJobAuditLogsId?: string | null,
  } | null,
};

export type ListTranscriptionJobAuditLogsQueryVariables = {
  filter?: ModelTranscriptionJobAuditLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTranscriptionJobAuditLogsQuery = {
  listTranscriptionJobAuditLogs?:  {
    __typename: "ModelTranscriptionJobAuditLogConnection",
    items:  Array< {
      __typename: "TranscriptionJobAuditLog",
      id: string,
      date: string,
      author: string,
      resource: string,
      action: string,
      payload?: string | null,
      ipAddress?: string | null,
      transcriptionJob:  {
        __typename: "TranscriptionJob",
        id: string,
        date: string,
        status: JobStatus,
        meetingInviteId: string,
        owner: string,
        identityId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      transcriptionJobId: string,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      transcriptionJobAuditLogsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncTranscriptionJobAuditLogsQueryVariables = {
  filter?: ModelTranscriptionJobAuditLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncTranscriptionJobAuditLogsQuery = {
  syncTranscriptionJobAuditLogs?:  {
    __typename: "ModelTranscriptionJobAuditLogConnection",
    items:  Array< {
      __typename: "TranscriptionJobAuditLog",
      id: string,
      date: string,
      author: string,
      resource: string,
      action: string,
      payload?: string | null,
      ipAddress?: string | null,
      transcriptionJob:  {
        __typename: "TranscriptionJob",
        id: string,
        date: string,
        status: JobStatus,
        meetingInviteId: string,
        owner: string,
        identityId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      transcriptionJobId: string,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      transcriptionJobAuditLogsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListTranscriptionJobAuditLogsByTranscriptionJobIdQueryVariables = {
  transcriptionJobId: string,
  date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTranscriptionJobAuditLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTranscriptionJobAuditLogsByTranscriptionJobIdQuery = {
  listTranscriptionJobAuditLogsByTranscriptionJobId?:  {
    __typename: "ModelTranscriptionJobAuditLogConnection",
    items:  Array< {
      __typename: "TranscriptionJobAuditLog",
      id: string,
      date: string,
      author: string,
      resource: string,
      action: string,
      payload?: string | null,
      ipAddress?: string | null,
      transcriptionJob:  {
        __typename: "TranscriptionJob",
        id: string,
        date: string,
        status: JobStatus,
        meetingInviteId: string,
        owner: string,
        identityId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      transcriptionJobId: string,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      transcriptionJobAuditLogsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetTranscriptionAdminJobQueryVariables = {
  id: string,
};

export type GetTranscriptionAdminJobQuery = {
  getTranscriptionAdminJob?:  {
    __typename: "TranscriptionAdminJob",
    id: string,
    date: string,
    status: JobStatus,
    result?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    meetingId: string,
    meetingInviteId?: string | null,
    auditLogs?:  {
      __typename: "ModelTranscriptionAdminJobAuditLogConnection",
      items:  Array< {
        __typename: "TranscriptionAdminJobAuditLog",
        id: string,
        date: string,
        author: string,
        resource: string,
        action: string,
        payload?: string | null,
        ipAddress?: string | null,
        transcriptionAdminJobId: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        transcriptionAdminJobAuditLogsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    owner: string,
    identityId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListTranscriptionAdminJobsQueryVariables = {
  filter?: ModelTranscriptionAdminJobFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTranscriptionAdminJobsQuery = {
  listTranscriptionAdminJobs?:  {
    __typename: "ModelTranscriptionAdminJobConnection",
    items:  Array< {
      __typename: "TranscriptionAdminJob",
      id: string,
      date: string,
      status: JobStatus,
      result?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      meetingId: string,
      meetingInviteId?: string | null,
      auditLogs?:  {
        __typename: "ModelTranscriptionAdminJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner: string,
      identityId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncTranscriptionAdminJobsQueryVariables = {
  filter?: ModelTranscriptionAdminJobFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncTranscriptionAdminJobsQuery = {
  syncTranscriptionAdminJobs?:  {
    __typename: "ModelTranscriptionAdminJobConnection",
    items:  Array< {
      __typename: "TranscriptionAdminJob",
      id: string,
      date: string,
      status: JobStatus,
      result?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      meetingId: string,
      meetingInviteId?: string | null,
      auditLogs?:  {
        __typename: "ModelTranscriptionAdminJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner: string,
      identityId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListTranscriptionAdminJobsByStatusQueryVariables = {
  status: JobStatus,
  date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTranscriptionAdminJobFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTranscriptionAdminJobsByStatusQuery = {
  listTranscriptionAdminJobsByStatus?:  {
    __typename: "ModelTranscriptionAdminJobConnection",
    items:  Array< {
      __typename: "TranscriptionAdminJob",
      id: string,
      date: string,
      status: JobStatus,
      result?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      meetingId: string,
      meetingInviteId?: string | null,
      auditLogs?:  {
        __typename: "ModelTranscriptionAdminJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner: string,
      identityId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListTranscriptionAdminJobsByMeetingIdQueryVariables = {
  meetingId: string,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTranscriptionAdminJobFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTranscriptionAdminJobsByMeetingIdQuery = {
  listTranscriptionAdminJobsByMeetingId?:  {
    __typename: "ModelTranscriptionAdminJobConnection",
    items:  Array< {
      __typename: "TranscriptionAdminJob",
      id: string,
      date: string,
      status: JobStatus,
      result?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      meetingId: string,
      meetingInviteId?: string | null,
      auditLogs?:  {
        __typename: "ModelTranscriptionAdminJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner: string,
      identityId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetTranscriptionAdminJobAuditLogQueryVariables = {
  id: string,
};

export type GetTranscriptionAdminJobAuditLogQuery = {
  getTranscriptionAdminJobAuditLog?:  {
    __typename: "TranscriptionAdminJobAuditLog",
    id: string,
    date: string,
    author: string,
    resource: string,
    action: string,
    payload?: string | null,
    ipAddress?: string | null,
    transcriptionAdminJob:  {
      __typename: "TranscriptionAdminJob",
      id: string,
      date: string,
      status: JobStatus,
      result?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      meetingId: string,
      meetingInviteId?: string | null,
      auditLogs?:  {
        __typename: "ModelTranscriptionAdminJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner: string,
      identityId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    transcriptionAdminJobId: string,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    transcriptionAdminJobAuditLogsId?: string | null,
  } | null,
};

export type ListTranscriptionAdminJobAuditLogsQueryVariables = {
  filter?: ModelTranscriptionAdminJobAuditLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTranscriptionAdminJobAuditLogsQuery = {
  listTranscriptionAdminJobAuditLogs?:  {
    __typename: "ModelTranscriptionAdminJobAuditLogConnection",
    items:  Array< {
      __typename: "TranscriptionAdminJobAuditLog",
      id: string,
      date: string,
      author: string,
      resource: string,
      action: string,
      payload?: string | null,
      ipAddress?: string | null,
      transcriptionAdminJob:  {
        __typename: "TranscriptionAdminJob",
        id: string,
        date: string,
        status: JobStatus,
        meetingId: string,
        meetingInviteId?: string | null,
        owner: string,
        identityId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      transcriptionAdminJobId: string,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      transcriptionAdminJobAuditLogsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncTranscriptionAdminJobAuditLogsQueryVariables = {
  filter?: ModelTranscriptionAdminJobAuditLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncTranscriptionAdminJobAuditLogsQuery = {
  syncTranscriptionAdminJobAuditLogs?:  {
    __typename: "ModelTranscriptionAdminJobAuditLogConnection",
    items:  Array< {
      __typename: "TranscriptionAdminJobAuditLog",
      id: string,
      date: string,
      author: string,
      resource: string,
      action: string,
      payload?: string | null,
      ipAddress?: string | null,
      transcriptionAdminJob:  {
        __typename: "TranscriptionAdminJob",
        id: string,
        date: string,
        status: JobStatus,
        meetingId: string,
        meetingInviteId?: string | null,
        owner: string,
        identityId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      transcriptionAdminJobId: string,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      transcriptionAdminJobAuditLogsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListTranscriptionAdminJobAuditLogsByTranscriptionAdminJobIdQueryVariables = {
  transcriptionAdminJobId: string,
  date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTranscriptionAdminJobAuditLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTranscriptionAdminJobAuditLogsByTranscriptionAdminJobIdQuery = {
  listTranscriptionAdminJobAuditLogsByTranscriptionAdminJobId?:  {
    __typename: "ModelTranscriptionAdminJobAuditLogConnection",
    items:  Array< {
      __typename: "TranscriptionAdminJobAuditLog",
      id: string,
      date: string,
      author: string,
      resource: string,
      action: string,
      payload?: string | null,
      ipAddress?: string | null,
      transcriptionAdminJob:  {
        __typename: "TranscriptionAdminJob",
        id: string,
        date: string,
        status: JobStatus,
        meetingId: string,
        meetingInviteId?: string | null,
        owner: string,
        identityId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      transcriptionAdminJobId: string,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      transcriptionAdminJobAuditLogsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetTranscriptionEventAuditLogQueryVariables = {
  id: string,
};

export type GetTranscriptionEventAuditLogQuery = {
  getTranscriptionEventAuditLog?:  {
    __typename: "TranscriptionEventAuditLog",
    id: string,
    date: string,
    author: string,
    resource: string,
    action: string,
    payload?: string | null,
    ipAddress?: string | null,
    meetingInvite:  {
      __typename: "MeetingInvite",
      id: string,
      meetingId: string,
      provider?:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      providerId?: string | null,
      title: string,
      startDateTime: string,
      endDateTime: string,
      status: MeetingStatus,
      description?: string | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null,
      productId?: string | null,
      isReadinessTest: boolean,
      meetingRoomId?: string | null,
      providerSessionId?: string | null,
      providerJoinData?: string | null,
      sessionStatus?: MeetingRoomSessionStatus | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      profile:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      },
      profileId: string,
      owner?: string | null,
      meetingInvitationType: MeetingInvitationType,
      teamId?: string | null,
      isInterested?: boolean | null,
      feedbackSummary?: string | null,
      contactRequestTimeframe?: string | null,
      contactPhoneNumber?: string | null,
      contactEmail?: string | null,
      sellerOrganizationName?: string | null,
      sellerTeamName?: string | null,
      buyerTeamName?: string | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productMeetingInvitesId?: string | null,
      profileMeetingInvitesId?: string | null,
      meetingRoomProviderMeetingInvitesId?: string | null,
    },
    meetingInviteId: string,
    profile:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    },
    profileId: string,
    authorProfileId?: string | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileTranscriptionLogsId?: string | null,
    meetingInviteTranscriptionLogsId?: string | null,
  } | null,
};

export type ListTranscriptionEventAuditLogsQueryVariables = {
  filter?: ModelTranscriptionEventAuditLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTranscriptionEventAuditLogsQuery = {
  listTranscriptionEventAuditLogs?:  {
    __typename: "ModelTranscriptionEventAuditLogConnection",
    items:  Array< {
      __typename: "TranscriptionEventAuditLog",
      id: string,
      date: string,
      author: string,
      resource: string,
      action: string,
      payload?: string | null,
      ipAddress?: string | null,
      meetingInvite:  {
        __typename: "MeetingInvite",
        id: string,
        meetingId: string,
        providerId?: string | null,
        title: string,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        meetingRoomId?: string | null,
        providerSessionId?: string | null,
        providerJoinData?: string | null,
        sessionStatus?: MeetingRoomSessionStatus | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        profileId: string,
        owner?: string | null,
        meetingInvitationType: MeetingInvitationType,
        teamId?: string | null,
        isInterested?: boolean | null,
        feedbackSummary?: string | null,
        contactRequestTimeframe?: string | null,
        contactPhoneNumber?: string | null,
        contactEmail?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingInvitesId?: string | null,
        profileMeetingInvitesId?: string | null,
        meetingRoomProviderMeetingInvitesId?: string | null,
      },
      meetingInviteId: string,
      profile:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      },
      profileId: string,
      authorProfileId?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      profileTranscriptionLogsId?: string | null,
      meetingInviteTranscriptionLogsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncTranscriptionEventAuditLogsQueryVariables = {
  filter?: ModelTranscriptionEventAuditLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncTranscriptionEventAuditLogsQuery = {
  syncTranscriptionEventAuditLogs?:  {
    __typename: "ModelTranscriptionEventAuditLogConnection",
    items:  Array< {
      __typename: "TranscriptionEventAuditLog",
      id: string,
      date: string,
      author: string,
      resource: string,
      action: string,
      payload?: string | null,
      ipAddress?: string | null,
      meetingInvite:  {
        __typename: "MeetingInvite",
        id: string,
        meetingId: string,
        providerId?: string | null,
        title: string,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        meetingRoomId?: string | null,
        providerSessionId?: string | null,
        providerJoinData?: string | null,
        sessionStatus?: MeetingRoomSessionStatus | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        profileId: string,
        owner?: string | null,
        meetingInvitationType: MeetingInvitationType,
        teamId?: string | null,
        isInterested?: boolean | null,
        feedbackSummary?: string | null,
        contactRequestTimeframe?: string | null,
        contactPhoneNumber?: string | null,
        contactEmail?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingInvitesId?: string | null,
        profileMeetingInvitesId?: string | null,
        meetingRoomProviderMeetingInvitesId?: string | null,
      },
      meetingInviteId: string,
      profile:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      },
      profileId: string,
      authorProfileId?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      profileTranscriptionLogsId?: string | null,
      meetingInviteTranscriptionLogsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListTranscriptionEventAuditLogsByMeetingInviteIdQueryVariables = {
  meetingInviteId: string,
  date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTranscriptionEventAuditLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTranscriptionEventAuditLogsByMeetingInviteIdQuery = {
  listTranscriptionEventAuditLogsByMeetingInviteId?:  {
    __typename: "ModelTranscriptionEventAuditLogConnection",
    items:  Array< {
      __typename: "TranscriptionEventAuditLog",
      id: string,
      date: string,
      author: string,
      resource: string,
      action: string,
      payload?: string | null,
      ipAddress?: string | null,
      meetingInvite:  {
        __typename: "MeetingInvite",
        id: string,
        meetingId: string,
        providerId?: string | null,
        title: string,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        meetingRoomId?: string | null,
        providerSessionId?: string | null,
        providerJoinData?: string | null,
        sessionStatus?: MeetingRoomSessionStatus | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        profileId: string,
        owner?: string | null,
        meetingInvitationType: MeetingInvitationType,
        teamId?: string | null,
        isInterested?: boolean | null,
        feedbackSummary?: string | null,
        contactRequestTimeframe?: string | null,
        contactPhoneNumber?: string | null,
        contactEmail?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingInvitesId?: string | null,
        profileMeetingInvitesId?: string | null,
        meetingRoomProviderMeetingInvitesId?: string | null,
      },
      meetingInviteId: string,
      profile:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      },
      profileId: string,
      authorProfileId?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      profileTranscriptionLogsId?: string | null,
      meetingInviteTranscriptionLogsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListTranscriptionEventAuditLogsByProfileIdQueryVariables = {
  profileId: string,
  date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTranscriptionEventAuditLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTranscriptionEventAuditLogsByProfileIdQuery = {
  listTranscriptionEventAuditLogsByProfileId?:  {
    __typename: "ModelTranscriptionEventAuditLogConnection",
    items:  Array< {
      __typename: "TranscriptionEventAuditLog",
      id: string,
      date: string,
      author: string,
      resource: string,
      action: string,
      payload?: string | null,
      ipAddress?: string | null,
      meetingInvite:  {
        __typename: "MeetingInvite",
        id: string,
        meetingId: string,
        providerId?: string | null,
        title: string,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        meetingRoomId?: string | null,
        providerSessionId?: string | null,
        providerJoinData?: string | null,
        sessionStatus?: MeetingRoomSessionStatus | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        profileId: string,
        owner?: string | null,
        meetingInvitationType: MeetingInvitationType,
        teamId?: string | null,
        isInterested?: boolean | null,
        feedbackSummary?: string | null,
        contactRequestTimeframe?: string | null,
        contactPhoneNumber?: string | null,
        contactEmail?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingInvitesId?: string | null,
        profileMeetingInvitesId?: string | null,
        meetingRoomProviderMeetingInvitesId?: string | null,
      },
      meetingInviteId: string,
      profile:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      },
      profileId: string,
      authorProfileId?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      profileTranscriptionLogsId?: string | null,
      meetingInviteTranscriptionLogsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type OnCreateProductQuestionSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateProductQuestionSubscription = {
  onCreateProductQuestion?:  {
    __typename: "ProductQuestion",
    id: string,
    question: string,
    product:  {
      __typename: "Product",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      serviceInformation?: string | null,
      images?:  {
        __typename: "ModelProductImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questions?:  {
        __typename: "ModelProductQuestionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      salesTeam?:  {
        __typename: "Team",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        labels?: Array< string | null > | null,
        privacyEnabled: boolean,
        privacyEnableOtherTeams?: boolean | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      salesTeamId?: string | null,
      meetings?:  {
        __typename: "ModelMeetingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProductsId?: string | null,
      teamProductsId?: string | null,
    },
    productId: string,
    responses?:  {
      __typename: "ModelQuestionResponseConnection",
      items:  Array< {
        __typename: "QuestionResponse",
        id: string,
        response?: string | null,
        privateNotes?: string | null,
        questionId: string,
        profileId: string,
        meetingId: string,
        meetingInviteId: string,
        owner?: string | null,
        contactRequestTimeframe?: string | null,
        contactRequestDateTime?: string | null,
        contactPhoneNumber?: string | null,
        contactEmail?: string | null,
        contactName?: string | null,
        providePersonalEmail?: boolean | null,
        providePersonalPhoneNumber?: boolean | null,
        textOnly?: boolean | null,
        followupMeetingRequested?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productQuestionResponsesId?: string | null,
        profileQuestionResponsesId?: string | null,
        meetingQuestionResponsesId?: string | null,
        meetingInviteQuestionResponsesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    productQuestionsId?: string | null,
  } | null,
};

export type OnUpdateProductQuestionSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateProductQuestionSubscription = {
  onUpdateProductQuestion?:  {
    __typename: "ProductQuestion",
    id: string,
    question: string,
    product:  {
      __typename: "Product",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      serviceInformation?: string | null,
      images?:  {
        __typename: "ModelProductImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questions?:  {
        __typename: "ModelProductQuestionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      salesTeam?:  {
        __typename: "Team",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        labels?: Array< string | null > | null,
        privacyEnabled: boolean,
        privacyEnableOtherTeams?: boolean | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      salesTeamId?: string | null,
      meetings?:  {
        __typename: "ModelMeetingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProductsId?: string | null,
      teamProductsId?: string | null,
    },
    productId: string,
    responses?:  {
      __typename: "ModelQuestionResponseConnection",
      items:  Array< {
        __typename: "QuestionResponse",
        id: string,
        response?: string | null,
        privateNotes?: string | null,
        questionId: string,
        profileId: string,
        meetingId: string,
        meetingInviteId: string,
        owner?: string | null,
        contactRequestTimeframe?: string | null,
        contactRequestDateTime?: string | null,
        contactPhoneNumber?: string | null,
        contactEmail?: string | null,
        contactName?: string | null,
        providePersonalEmail?: boolean | null,
        providePersonalPhoneNumber?: boolean | null,
        textOnly?: boolean | null,
        followupMeetingRequested?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productQuestionResponsesId?: string | null,
        profileQuestionResponsesId?: string | null,
        meetingQuestionResponsesId?: string | null,
        meetingInviteQuestionResponsesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    productQuestionsId?: string | null,
  } | null,
};

export type OnDeleteProductQuestionSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteProductQuestionSubscription = {
  onDeleteProductQuestion?:  {
    __typename: "ProductQuestion",
    id: string,
    question: string,
    product:  {
      __typename: "Product",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      serviceInformation?: string | null,
      images?:  {
        __typename: "ModelProductImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questions?:  {
        __typename: "ModelProductQuestionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      salesTeam?:  {
        __typename: "Team",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        labels?: Array< string | null > | null,
        privacyEnabled: boolean,
        privacyEnableOtherTeams?: boolean | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      salesTeamId?: string | null,
      meetings?:  {
        __typename: "ModelMeetingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProductsId?: string | null,
      teamProductsId?: string | null,
    },
    productId: string,
    responses?:  {
      __typename: "ModelQuestionResponseConnection",
      items:  Array< {
        __typename: "QuestionResponse",
        id: string,
        response?: string | null,
        privateNotes?: string | null,
        questionId: string,
        profileId: string,
        meetingId: string,
        meetingInviteId: string,
        owner?: string | null,
        contactRequestTimeframe?: string | null,
        contactRequestDateTime?: string | null,
        contactPhoneNumber?: string | null,
        contactEmail?: string | null,
        contactName?: string | null,
        providePersonalEmail?: boolean | null,
        providePersonalPhoneNumber?: boolean | null,
        textOnly?: boolean | null,
        followupMeetingRequested?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productQuestionResponsesId?: string | null,
        profileQuestionResponsesId?: string | null,
        meetingQuestionResponsesId?: string | null,
        meetingInviteQuestionResponsesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    productQuestionsId?: string | null,
  } | null,
};

export type OnCreateQuestionResponseSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateQuestionResponseSubscription = {
  onCreateQuestionResponse?:  {
    __typename: "QuestionResponse",
    id: string,
    response?: string | null,
    privateNotes?: string | null,
    question:  {
      __typename: "ProductQuestion",
      id: string,
      question: string,
      product:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      },
      productId: string,
      responses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productQuestionsId?: string | null,
    },
    questionId: string,
    profile:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    },
    profileId: string,
    meeting:  {
      __typename: "Meeting",
      id: string,
      title: string,
      titleLowerCase?: string | null,
      startDateTime: string,
      endDateTime: string,
      status: MeetingStatus,
      description?: string | null,
      preferredRoomProvider?:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      preferredRoomProviderId?: string | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      room?:  {
        __typename: "MeetingRoom",
        id: string,
        name: string,
        meetingId: string,
        providerId: string,
        providerMeetingId?: string | null,
        accessCode: string,
        status: MeetingRoomStatus,
        startDateTime?: string | null,
        endDateTime?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        roomCreatedAt?: string | null,
        roomDestroyedAt?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        isReadinessTest: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        meetingRoomProviderMeetingRoomsId?: string | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null,
      productId?: string | null,
      isReadinessTest: boolean,
      labels?: Array< string | null > | null,
      owner?: string | null,
      teamRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerOrganizationName?: string | null,
      sellerTeamName?: string | null,
      buyerTeamName?: string | null,
      sellerOrganizationNameLowerCase?: string | null,
      sellerTeamNameLowerCase?: string | null,
      buyerTeamNameLowerCase?: string | null,
      externalId?: string | null,
      metrics?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productMeetingsId?: string | null,
      meetingRoomProviderMeetingsId?: string | null,
      meetingRoomId?: string | null,
    },
    meetingId: string,
    meetingInvite:  {
      __typename: "MeetingInvite",
      id: string,
      meetingId: string,
      provider?:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      providerId?: string | null,
      title: string,
      startDateTime: string,
      endDateTime: string,
      status: MeetingStatus,
      description?: string | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null,
      productId?: string | null,
      isReadinessTest: boolean,
      meetingRoomId?: string | null,
      providerSessionId?: string | null,
      providerJoinData?: string | null,
      sessionStatus?: MeetingRoomSessionStatus | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      profile:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      },
      profileId: string,
      owner?: string | null,
      meetingInvitationType: MeetingInvitationType,
      teamId?: string | null,
      isInterested?: boolean | null,
      feedbackSummary?: string | null,
      contactRequestTimeframe?: string | null,
      contactPhoneNumber?: string | null,
      contactEmail?: string | null,
      sellerOrganizationName?: string | null,
      sellerTeamName?: string | null,
      buyerTeamName?: string | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productMeetingInvitesId?: string | null,
      profileMeetingInvitesId?: string | null,
      meetingRoomProviderMeetingInvitesId?: string | null,
    },
    meetingInviteId: string,
    owner?: string | null,
    contactRequestTimeframe?: string | null,
    contactRequestDateTime?: string | null,
    contactPhoneNumber?: string | null,
    contactEmail?: string | null,
    contactName?: string | null,
    providePersonalEmail?: boolean | null,
    providePersonalPhoneNumber?: boolean | null,
    textOnly?: boolean | null,
    followupMeetingRequested?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    productQuestionResponsesId?: string | null,
    profileQuestionResponsesId?: string | null,
    meetingQuestionResponsesId?: string | null,
    meetingInviteQuestionResponsesId?: string | null,
  } | null,
};

export type OnUpdateQuestionResponseSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateQuestionResponseSubscription = {
  onUpdateQuestionResponse?:  {
    __typename: "QuestionResponse",
    id: string,
    response?: string | null,
    privateNotes?: string | null,
    question:  {
      __typename: "ProductQuestion",
      id: string,
      question: string,
      product:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      },
      productId: string,
      responses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productQuestionsId?: string | null,
    },
    questionId: string,
    profile:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    },
    profileId: string,
    meeting:  {
      __typename: "Meeting",
      id: string,
      title: string,
      titleLowerCase?: string | null,
      startDateTime: string,
      endDateTime: string,
      status: MeetingStatus,
      description?: string | null,
      preferredRoomProvider?:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      preferredRoomProviderId?: string | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      room?:  {
        __typename: "MeetingRoom",
        id: string,
        name: string,
        meetingId: string,
        providerId: string,
        providerMeetingId?: string | null,
        accessCode: string,
        status: MeetingRoomStatus,
        startDateTime?: string | null,
        endDateTime?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        roomCreatedAt?: string | null,
        roomDestroyedAt?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        isReadinessTest: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        meetingRoomProviderMeetingRoomsId?: string | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null,
      productId?: string | null,
      isReadinessTest: boolean,
      labels?: Array< string | null > | null,
      owner?: string | null,
      teamRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerOrganizationName?: string | null,
      sellerTeamName?: string | null,
      buyerTeamName?: string | null,
      sellerOrganizationNameLowerCase?: string | null,
      sellerTeamNameLowerCase?: string | null,
      buyerTeamNameLowerCase?: string | null,
      externalId?: string | null,
      metrics?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productMeetingsId?: string | null,
      meetingRoomProviderMeetingsId?: string | null,
      meetingRoomId?: string | null,
    },
    meetingId: string,
    meetingInvite:  {
      __typename: "MeetingInvite",
      id: string,
      meetingId: string,
      provider?:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      providerId?: string | null,
      title: string,
      startDateTime: string,
      endDateTime: string,
      status: MeetingStatus,
      description?: string | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null,
      productId?: string | null,
      isReadinessTest: boolean,
      meetingRoomId?: string | null,
      providerSessionId?: string | null,
      providerJoinData?: string | null,
      sessionStatus?: MeetingRoomSessionStatus | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      profile:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      },
      profileId: string,
      owner?: string | null,
      meetingInvitationType: MeetingInvitationType,
      teamId?: string | null,
      isInterested?: boolean | null,
      feedbackSummary?: string | null,
      contactRequestTimeframe?: string | null,
      contactPhoneNumber?: string | null,
      contactEmail?: string | null,
      sellerOrganizationName?: string | null,
      sellerTeamName?: string | null,
      buyerTeamName?: string | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productMeetingInvitesId?: string | null,
      profileMeetingInvitesId?: string | null,
      meetingRoomProviderMeetingInvitesId?: string | null,
    },
    meetingInviteId: string,
    owner?: string | null,
    contactRequestTimeframe?: string | null,
    contactRequestDateTime?: string | null,
    contactPhoneNumber?: string | null,
    contactEmail?: string | null,
    contactName?: string | null,
    providePersonalEmail?: boolean | null,
    providePersonalPhoneNumber?: boolean | null,
    textOnly?: boolean | null,
    followupMeetingRequested?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    productQuestionResponsesId?: string | null,
    profileQuestionResponsesId?: string | null,
    meetingQuestionResponsesId?: string | null,
    meetingInviteQuestionResponsesId?: string | null,
  } | null,
};

export type OnDeleteQuestionResponseSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteQuestionResponseSubscription = {
  onDeleteQuestionResponse?:  {
    __typename: "QuestionResponse",
    id: string,
    response?: string | null,
    privateNotes?: string | null,
    question:  {
      __typename: "ProductQuestion",
      id: string,
      question: string,
      product:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      },
      productId: string,
      responses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productQuestionsId?: string | null,
    },
    questionId: string,
    profile:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    },
    profileId: string,
    meeting:  {
      __typename: "Meeting",
      id: string,
      title: string,
      titleLowerCase?: string | null,
      startDateTime: string,
      endDateTime: string,
      status: MeetingStatus,
      description?: string | null,
      preferredRoomProvider?:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      preferredRoomProviderId?: string | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      room?:  {
        __typename: "MeetingRoom",
        id: string,
        name: string,
        meetingId: string,
        providerId: string,
        providerMeetingId?: string | null,
        accessCode: string,
        status: MeetingRoomStatus,
        startDateTime?: string | null,
        endDateTime?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        roomCreatedAt?: string | null,
        roomDestroyedAt?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        isReadinessTest: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        meetingRoomProviderMeetingRoomsId?: string | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null,
      productId?: string | null,
      isReadinessTest: boolean,
      labels?: Array< string | null > | null,
      owner?: string | null,
      teamRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerOrganizationName?: string | null,
      sellerTeamName?: string | null,
      buyerTeamName?: string | null,
      sellerOrganizationNameLowerCase?: string | null,
      sellerTeamNameLowerCase?: string | null,
      buyerTeamNameLowerCase?: string | null,
      externalId?: string | null,
      metrics?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productMeetingsId?: string | null,
      meetingRoomProviderMeetingsId?: string | null,
      meetingRoomId?: string | null,
    },
    meetingId: string,
    meetingInvite:  {
      __typename: "MeetingInvite",
      id: string,
      meetingId: string,
      provider?:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      providerId?: string | null,
      title: string,
      startDateTime: string,
      endDateTime: string,
      status: MeetingStatus,
      description?: string | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null,
      productId?: string | null,
      isReadinessTest: boolean,
      meetingRoomId?: string | null,
      providerSessionId?: string | null,
      providerJoinData?: string | null,
      sessionStatus?: MeetingRoomSessionStatus | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      profile:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      },
      profileId: string,
      owner?: string | null,
      meetingInvitationType: MeetingInvitationType,
      teamId?: string | null,
      isInterested?: boolean | null,
      feedbackSummary?: string | null,
      contactRequestTimeframe?: string | null,
      contactPhoneNumber?: string | null,
      contactEmail?: string | null,
      sellerOrganizationName?: string | null,
      sellerTeamName?: string | null,
      buyerTeamName?: string | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productMeetingInvitesId?: string | null,
      profileMeetingInvitesId?: string | null,
      meetingRoomProviderMeetingInvitesId?: string | null,
    },
    meetingInviteId: string,
    owner?: string | null,
    contactRequestTimeframe?: string | null,
    contactRequestDateTime?: string | null,
    contactPhoneNumber?: string | null,
    contactEmail?: string | null,
    contactName?: string | null,
    providePersonalEmail?: boolean | null,
    providePersonalPhoneNumber?: boolean | null,
    textOnly?: boolean | null,
    followupMeetingRequested?: boolean | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    productQuestionResponsesId?: string | null,
    profileQuestionResponsesId?: string | null,
    meetingQuestionResponsesId?: string | null,
    meetingInviteQuestionResponsesId?: string | null,
  } | null,
};

export type OnCreateProductImageSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateProductImageSubscription = {
  onCreateProductImage?:  {
    __typename: "ProductImage",
    id: string,
    title: string,
    alt?: string | null,
    width?: number | null,
    height?: number | null,
    image:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    },
    product:  {
      __typename: "Product",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      serviceInformation?: string | null,
      images?:  {
        __typename: "ModelProductImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questions?:  {
        __typename: "ModelProductQuestionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      salesTeam?:  {
        __typename: "Team",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        labels?: Array< string | null > | null,
        privacyEnabled: boolean,
        privacyEnableOtherTeams?: boolean | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      salesTeamId?: string | null,
      meetings?:  {
        __typename: "ModelMeetingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProductsId?: string | null,
      teamProductsId?: string | null,
    },
    productId: string,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    productImagesId?: string | null,
  } | null,
};

export type OnUpdateProductImageSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateProductImageSubscription = {
  onUpdateProductImage?:  {
    __typename: "ProductImage",
    id: string,
    title: string,
    alt?: string | null,
    width?: number | null,
    height?: number | null,
    image:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    },
    product:  {
      __typename: "Product",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      serviceInformation?: string | null,
      images?:  {
        __typename: "ModelProductImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questions?:  {
        __typename: "ModelProductQuestionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      salesTeam?:  {
        __typename: "Team",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        labels?: Array< string | null > | null,
        privacyEnabled: boolean,
        privacyEnableOtherTeams?: boolean | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      salesTeamId?: string | null,
      meetings?:  {
        __typename: "ModelMeetingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProductsId?: string | null,
      teamProductsId?: string | null,
    },
    productId: string,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    productImagesId?: string | null,
  } | null,
};

export type OnDeleteProductImageSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteProductImageSubscription = {
  onDeleteProductImage?:  {
    __typename: "ProductImage",
    id: string,
    title: string,
    alt?: string | null,
    width?: number | null,
    height?: number | null,
    image:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    },
    product:  {
      __typename: "Product",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      serviceInformation?: string | null,
      images?:  {
        __typename: "ModelProductImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questions?:  {
        __typename: "ModelProductQuestionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      salesTeam?:  {
        __typename: "Team",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        labels?: Array< string | null > | null,
        privacyEnabled: boolean,
        privacyEnableOtherTeams?: boolean | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      salesTeamId?: string | null,
      meetings?:  {
        __typename: "ModelMeetingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProductsId?: string | null,
      teamProductsId?: string | null,
    },
    productId: string,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    productImagesId?: string | null,
  } | null,
};

export type OnCreateProductSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateProductSubscription = {
  onCreateProduct?:  {
    __typename: "Product",
    id: string,
    name: string,
    nameLowerCase?: string | null,
    description?: string | null,
    serviceInformation?: string | null,
    images?:  {
      __typename: "ModelProductImageConnection",
      items:  Array< {
        __typename: "ProductImage",
        id: string,
        title: string,
        alt?: string | null,
        width?: number | null,
        height?: number | null,
        productId: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productImagesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    questions?:  {
      __typename: "ModelProductQuestionConnection",
      items:  Array< {
        __typename: "ProductQuestion",
        id: string,
        question: string,
        productId: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productQuestionsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      externalId?: string | null,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      url?: string | null,
      phoneNumber?: string | null,
      locations?:  {
        __typename: "ModelOrganizationLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      profiles?:  {
        __typename: "ModelProfileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    organizationId?: string | null,
    salesTeam?:  {
      __typename: "Team",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      memberRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      privacyEnabled: boolean,
      privacyEnableOtherTeams?: boolean | null,
      images?:  {
        __typename: "ModelTeamImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    salesTeamId?: string | null,
    meetings?:  {
      __typename: "ModelMeetingConnection",
      items:  Array< {
        __typename: "Meeting",
        id: string,
        title: string,
        titleLowerCase?: string | null,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        preferredRoomProviderId?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        sellerOrganizationNameLowerCase?: string | null,
        sellerTeamNameLowerCase?: string | null,
        buyerTeamNameLowerCase?: string | null,
        externalId?: string | null,
        metrics?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingsId?: string | null,
        meetingRoomProviderMeetingsId?: string | null,
        meetingRoomId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    meetingInvites?:  {
      __typename: "ModelMeetingInviteConnection",
      items:  Array< {
        __typename: "MeetingInvite",
        id: string,
        meetingId: string,
        providerId?: string | null,
        title: string,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        meetingRoomId?: string | null,
        providerSessionId?: string | null,
        providerJoinData?: string | null,
        sessionStatus?: MeetingRoomSessionStatus | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        profileId: string,
        owner?: string | null,
        meetingInvitationType: MeetingInvitationType,
        teamId?: string | null,
        isInterested?: boolean | null,
        feedbackSummary?: string | null,
        contactRequestTimeframe?: string | null,
        contactPhoneNumber?: string | null,
        contactEmail?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingInvitesId?: string | null,
        profileMeetingInvitesId?: string | null,
        meetingRoomProviderMeetingInvitesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    labels?: Array< string | null > | null,
    owner?: string | null,
    sortByName?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    organizationProductsId?: string | null,
    teamProductsId?: string | null,
  } | null,
};

export type OnUpdateProductSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateProductSubscription = {
  onUpdateProduct?:  {
    __typename: "Product",
    id: string,
    name: string,
    nameLowerCase?: string | null,
    description?: string | null,
    serviceInformation?: string | null,
    images?:  {
      __typename: "ModelProductImageConnection",
      items:  Array< {
        __typename: "ProductImage",
        id: string,
        title: string,
        alt?: string | null,
        width?: number | null,
        height?: number | null,
        productId: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productImagesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    questions?:  {
      __typename: "ModelProductQuestionConnection",
      items:  Array< {
        __typename: "ProductQuestion",
        id: string,
        question: string,
        productId: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productQuestionsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      externalId?: string | null,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      url?: string | null,
      phoneNumber?: string | null,
      locations?:  {
        __typename: "ModelOrganizationLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      profiles?:  {
        __typename: "ModelProfileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    organizationId?: string | null,
    salesTeam?:  {
      __typename: "Team",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      memberRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      privacyEnabled: boolean,
      privacyEnableOtherTeams?: boolean | null,
      images?:  {
        __typename: "ModelTeamImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    salesTeamId?: string | null,
    meetings?:  {
      __typename: "ModelMeetingConnection",
      items:  Array< {
        __typename: "Meeting",
        id: string,
        title: string,
        titleLowerCase?: string | null,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        preferredRoomProviderId?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        sellerOrganizationNameLowerCase?: string | null,
        sellerTeamNameLowerCase?: string | null,
        buyerTeamNameLowerCase?: string | null,
        externalId?: string | null,
        metrics?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingsId?: string | null,
        meetingRoomProviderMeetingsId?: string | null,
        meetingRoomId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    meetingInvites?:  {
      __typename: "ModelMeetingInviteConnection",
      items:  Array< {
        __typename: "MeetingInvite",
        id: string,
        meetingId: string,
        providerId?: string | null,
        title: string,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        meetingRoomId?: string | null,
        providerSessionId?: string | null,
        providerJoinData?: string | null,
        sessionStatus?: MeetingRoomSessionStatus | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        profileId: string,
        owner?: string | null,
        meetingInvitationType: MeetingInvitationType,
        teamId?: string | null,
        isInterested?: boolean | null,
        feedbackSummary?: string | null,
        contactRequestTimeframe?: string | null,
        contactPhoneNumber?: string | null,
        contactEmail?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingInvitesId?: string | null,
        profileMeetingInvitesId?: string | null,
        meetingRoomProviderMeetingInvitesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    labels?: Array< string | null > | null,
    owner?: string | null,
    sortByName?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    organizationProductsId?: string | null,
    teamProductsId?: string | null,
  } | null,
};

export type OnDeleteProductSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteProductSubscription = {
  onDeleteProduct?:  {
    __typename: "Product",
    id: string,
    name: string,
    nameLowerCase?: string | null,
    description?: string | null,
    serviceInformation?: string | null,
    images?:  {
      __typename: "ModelProductImageConnection",
      items:  Array< {
        __typename: "ProductImage",
        id: string,
        title: string,
        alt?: string | null,
        width?: number | null,
        height?: number | null,
        productId: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productImagesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    questions?:  {
      __typename: "ModelProductQuestionConnection",
      items:  Array< {
        __typename: "ProductQuestion",
        id: string,
        question: string,
        productId: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productQuestionsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      externalId?: string | null,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      url?: string | null,
      phoneNumber?: string | null,
      locations?:  {
        __typename: "ModelOrganizationLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      profiles?:  {
        __typename: "ModelProfileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    organizationId?: string | null,
    salesTeam?:  {
      __typename: "Team",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      memberRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      privacyEnabled: boolean,
      privacyEnableOtherTeams?: boolean | null,
      images?:  {
        __typename: "ModelTeamImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    salesTeamId?: string | null,
    meetings?:  {
      __typename: "ModelMeetingConnection",
      items:  Array< {
        __typename: "Meeting",
        id: string,
        title: string,
        titleLowerCase?: string | null,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        preferredRoomProviderId?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        sellerOrganizationNameLowerCase?: string | null,
        sellerTeamNameLowerCase?: string | null,
        buyerTeamNameLowerCase?: string | null,
        externalId?: string | null,
        metrics?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingsId?: string | null,
        meetingRoomProviderMeetingsId?: string | null,
        meetingRoomId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    meetingInvites?:  {
      __typename: "ModelMeetingInviteConnection",
      items:  Array< {
        __typename: "MeetingInvite",
        id: string,
        meetingId: string,
        providerId?: string | null,
        title: string,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        meetingRoomId?: string | null,
        providerSessionId?: string | null,
        providerJoinData?: string | null,
        sessionStatus?: MeetingRoomSessionStatus | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        profileId: string,
        owner?: string | null,
        meetingInvitationType: MeetingInvitationType,
        teamId?: string | null,
        isInterested?: boolean | null,
        feedbackSummary?: string | null,
        contactRequestTimeframe?: string | null,
        contactPhoneNumber?: string | null,
        contactEmail?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingInvitesId?: string | null,
        profileMeetingInvitesId?: string | null,
        meetingRoomProviderMeetingInvitesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    labels?: Array< string | null > | null,
    owner?: string | null,
    sortByName?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    organizationProductsId?: string | null,
    teamProductsId?: string | null,
  } | null,
};

export type OnCreateOrganizationLocationSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateOrganizationLocationSubscription = {
  onCreateOrganizationLocation?:  {
    __typename: "OrganizationLocation",
    id: string,
    title?: string | null,
    address?: string | null,
    city?: string | null,
    state?: string | null,
    country?: string | null,
    organization:  {
      __typename: "Organization",
      id: string,
      externalId?: string | null,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      url?: string | null,
      phoneNumber?: string | null,
      locations?:  {
        __typename: "ModelOrganizationLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      profiles?:  {
        __typename: "ModelProfileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    organizationId: string,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    organizationLocationsId?: string | null,
  } | null,
};

export type OnUpdateOrganizationLocationSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateOrganizationLocationSubscription = {
  onUpdateOrganizationLocation?:  {
    __typename: "OrganizationLocation",
    id: string,
    title?: string | null,
    address?: string | null,
    city?: string | null,
    state?: string | null,
    country?: string | null,
    organization:  {
      __typename: "Organization",
      id: string,
      externalId?: string | null,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      url?: string | null,
      phoneNumber?: string | null,
      locations?:  {
        __typename: "ModelOrganizationLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      profiles?:  {
        __typename: "ModelProfileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    organizationId: string,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    organizationLocationsId?: string | null,
  } | null,
};

export type OnDeleteOrganizationLocationSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteOrganizationLocationSubscription = {
  onDeleteOrganizationLocation?:  {
    __typename: "OrganizationLocation",
    id: string,
    title?: string | null,
    address?: string | null,
    city?: string | null,
    state?: string | null,
    country?: string | null,
    organization:  {
      __typename: "Organization",
      id: string,
      externalId?: string | null,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      url?: string | null,
      phoneNumber?: string | null,
      locations?:  {
        __typename: "ModelOrganizationLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      profiles?:  {
        __typename: "ModelProfileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    organizationId: string,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    organizationLocationsId?: string | null,
  } | null,
};

export type OnCreateOrganizationSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateOrganizationSubscription = {
  onCreateOrganization?:  {
    __typename: "Organization",
    id: string,
    externalId?: string | null,
    name: string,
    nameLowerCase?: string | null,
    description?: string | null,
    url?: string | null,
    phoneNumber?: string | null,
    locations?:  {
      __typename: "ModelOrganizationLocationConnection",
      items:  Array< {
        __typename: "OrganizationLocation",
        id: string,
        title?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        country?: string | null,
        organizationId: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationLocationsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    profiles?:  {
      __typename: "ModelProfileConnection",
      items:  Array< {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    products?:  {
      __typename: "ModelProductConnection",
      items:  Array< {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    labels?: Array< string | null > | null,
    owner?: string | null,
    sortByName?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateOrganizationSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateOrganizationSubscription = {
  onUpdateOrganization?:  {
    __typename: "Organization",
    id: string,
    externalId?: string | null,
    name: string,
    nameLowerCase?: string | null,
    description?: string | null,
    url?: string | null,
    phoneNumber?: string | null,
    locations?:  {
      __typename: "ModelOrganizationLocationConnection",
      items:  Array< {
        __typename: "OrganizationLocation",
        id: string,
        title?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        country?: string | null,
        organizationId: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationLocationsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    profiles?:  {
      __typename: "ModelProfileConnection",
      items:  Array< {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    products?:  {
      __typename: "ModelProductConnection",
      items:  Array< {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    labels?: Array< string | null > | null,
    owner?: string | null,
    sortByName?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteOrganizationSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteOrganizationSubscription = {
  onDeleteOrganization?:  {
    __typename: "Organization",
    id: string,
    externalId?: string | null,
    name: string,
    nameLowerCase?: string | null,
    description?: string | null,
    url?: string | null,
    phoneNumber?: string | null,
    locations?:  {
      __typename: "ModelOrganizationLocationConnection",
      items:  Array< {
        __typename: "OrganizationLocation",
        id: string,
        title?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        country?: string | null,
        organizationId: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationLocationsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    profiles?:  {
      __typename: "ModelProfileConnection",
      items:  Array< {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    products?:  {
      __typename: "ModelProductConnection",
      items:  Array< {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    labels?: Array< string | null > | null,
    owner?: string | null,
    sortByName?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateProfileLocationSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateProfileLocationSubscription = {
  onCreateProfileLocation?:  {
    __typename: "ProfileLocation",
    id: string,
    title?: string | null,
    address?: string | null,
    city?: string | null,
    state?: string | null,
    country?: string | null,
    profile:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    },
    profileId: string,
    isPrimary: boolean,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileLocationsId?: string | null,
  } | null,
};

export type OnUpdateProfileLocationSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateProfileLocationSubscription = {
  onUpdateProfileLocation?:  {
    __typename: "ProfileLocation",
    id: string,
    title?: string | null,
    address?: string | null,
    city?: string | null,
    state?: string | null,
    country?: string | null,
    profile:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    },
    profileId: string,
    isPrimary: boolean,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileLocationsId?: string | null,
  } | null,
};

export type OnDeleteProfileLocationSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteProfileLocationSubscription = {
  onDeleteProfileLocation?:  {
    __typename: "ProfileLocation",
    id: string,
    title?: string | null,
    address?: string | null,
    city?: string | null,
    state?: string | null,
    country?: string | null,
    profile:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    },
    profileId: string,
    isPrimary: boolean,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileLocationsId?: string | null,
  } | null,
};

export type OnCreateProfileImageSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateProfileImageSubscription = {
  onCreateProfileImage?:  {
    __typename: "ProfileImage",
    id: string,
    title: string,
    alt?: string | null,
    width?: number | null,
    height?: number | null,
    image:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    },
    profile:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    },
    profileId: string,
    isDefault: boolean,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileImagesId?: string | null,
  } | null,
};

export type OnUpdateProfileImageSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateProfileImageSubscription = {
  onUpdateProfileImage?:  {
    __typename: "ProfileImage",
    id: string,
    title: string,
    alt?: string | null,
    width?: number | null,
    height?: number | null,
    image:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    },
    profile:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    },
    profileId: string,
    isDefault: boolean,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileImagesId?: string | null,
  } | null,
};

export type OnDeleteProfileImageSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteProfileImageSubscription = {
  onDeleteProfileImage?:  {
    __typename: "ProfileImage",
    id: string,
    title: string,
    alt?: string | null,
    width?: number | null,
    height?: number | null,
    image:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    },
    profile:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    },
    profileId: string,
    isDefault: boolean,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileImagesId?: string | null,
  } | null,
};

export type OnCreateForwardEmailSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateForwardEmailSubscription = {
  onCreateForwardEmail?:  {
    __typename: "ForwardEmail",
    id: string,
    received?: number | null,
    remaining?: number | null,
    publicEmail: string,
    expireAt?: string | null,
    privateEmail?: string | null,
    publicReplyEmail?: string | null,
    profile?:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    } | null,
    profileId?: string | null,
    sellerProfile?:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    } | null,
    sellerProfileId?: string | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileForwardEmailsId?: string | null,
    profileSellerForwardEmailsId?: string | null,
  } | null,
};

export type OnUpdateForwardEmailSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateForwardEmailSubscription = {
  onUpdateForwardEmail?:  {
    __typename: "ForwardEmail",
    id: string,
    received?: number | null,
    remaining?: number | null,
    publicEmail: string,
    expireAt?: string | null,
    privateEmail?: string | null,
    publicReplyEmail?: string | null,
    profile?:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    } | null,
    profileId?: string | null,
    sellerProfile?:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    } | null,
    sellerProfileId?: string | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileForwardEmailsId?: string | null,
    profileSellerForwardEmailsId?: string | null,
  } | null,
};

export type OnDeleteForwardEmailSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteForwardEmailSubscription = {
  onDeleteForwardEmail?:  {
    __typename: "ForwardEmail",
    id: string,
    received?: number | null,
    remaining?: number | null,
    publicEmail: string,
    expireAt?: string | null,
    privateEmail?: string | null,
    publicReplyEmail?: string | null,
    profile?:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    } | null,
    profileId?: string | null,
    sellerProfile?:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    } | null,
    sellerProfileId?: string | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileForwardEmailsId?: string | null,
    profileSellerForwardEmailsId?: string | null,
  } | null,
};

export type OnCreateProfileSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateProfileSubscription = {
  onCreateProfile?:  {
    __typename: "Profile",
    id: string,
    fullName: string,
    fullNameLowerCase?: string | null,
    email: string,
    externalId?: string | null,
    userId?: string | null,
    occupation?: string | null,
    profile?: string | null,
    phoneNumber?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      externalId?: string | null,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      url?: string | null,
      phoneNumber?: string | null,
      locations?:  {
        __typename: "ModelOrganizationLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      profiles?:  {
        __typename: "ModelProfileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    organizationId?: string | null,
    auditLogs?:  {
      __typename: "ModelMeetingRoomEventAuditLogConnection",
      items:  Array< {
        __typename: "MeetingRoomEventAuditLog",
        id: string,
        date: string,
        author: string,
        resource: string,
        action: string,
        payload?: string | null,
        ipAddress?: string | null,
        meetingRoomId: string,
        profileId?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileAuditLogsId?: string | null,
        meetingRoomAuditlogsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    jobAuditLogs?:  {
      __typename: "ModelImportJobAuditLogConnection",
      items:  Array< {
        __typename: "ImportJobAuditLog",
        id: string,
        date: string,
        author: string,
        resource: string,
        action: string,
        payload?: string | null,
        ipAddress?: string | null,
        importJobId: string,
        profileId?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileJobAuditLogsId?: string | null,
        importJobAuditLogsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    exportJobAuditLogs?:  {
      __typename: "ModelExportJobAuditLogConnection",
      items:  Array< {
        __typename: "ExportJobAuditLog",
        id: string,
        date: string,
        author: string,
        resource: string,
        action: string,
        payload?: string | null,
        ipAddress?: string | null,
        exportJobId: string,
        profileId?: string | null,
        owner: string,
        identityId?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileExportJobAuditLogsId?: string | null,
        exportJobAuditLogsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    teamRelationships?:  {
      __typename: "ModelTeamMemberRelationshipConnection",
      items:  Array< {
        __typename: "TeamMemberRelationship",
        id: string,
        teamId: string,
        memberProfileId: string,
        relationship: TeamRelationship,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileTeamRelationshipsId?: string | null,
        teamMemberRelationshipsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    meetingAttendees?:  {
      __typename: "ModelMeetingAttendeeConnection",
      items:  Array< {
        __typename: "MeetingAttendee",
        id: string,
        profileId: string,
        meetingId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileMeetingAttendeesId?: string | null,
        meetingMeetingAttendeesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    locations?:  {
      __typename: "ModelProfileLocationConnection",
      items:  Array< {
        __typename: "ProfileLocation",
        id: string,
        title?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        country?: string | null,
        profileId: string,
        isPrimary: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileLocationsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    devices?:  {
      __typename: "ModelProfileDeviceConnection",
      items:  Array< {
        __typename: "ProfileDevice",
        id: string,
        deviceHash: string,
        hasWebcam?: boolean | null,
        hasMicrophone?: boolean | null,
        isScreenCapturingSupported?: boolean | null,
        isWebRTCSupported?: boolean | null,
        isWebsiteHasMicrophonePermissions?: boolean | null,
        isWebsiteHasWebcamPermissions?: boolean | null,
        isWebSocketsSupported?: boolean | null,
        clockOffset?: number | null,
        lastSeen?: string | null,
        lastLogin?: string | null,
        lastMeetingId?: string | null,
        lastMeetingInviteId?: string | null,
        lastMeetingJoin?: string | null,
        lastMeetingStatus?: string | null,
        meetingData?: string | null,
        userAgent?: string | null,
        userAgentSummary?: string | null,
        micStatus?: string | null,
        cameraStatus?: string | null,
        networkQuality?: string | null,
        profileId: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileDevicesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    images?:  {
      __typename: "ModelProfileImageConnection",
      items:  Array< {
        __typename: "ProfileImage",
        id: string,
        title: string,
        alt?: string | null,
        width?: number | null,
        height?: number | null,
        profileId: string,
        isDefault: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileImagesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    questionResponses?:  {
      __typename: "ModelQuestionResponseConnection",
      items:  Array< {
        __typename: "QuestionResponse",
        id: string,
        response?: string | null,
        privateNotes?: string | null,
        questionId: string,
        profileId: string,
        meetingId: string,
        meetingInviteId: string,
        owner?: string | null,
        contactRequestTimeframe?: string | null,
        contactRequestDateTime?: string | null,
        contactPhoneNumber?: string | null,
        contactEmail?: string | null,
        contactName?: string | null,
        providePersonalEmail?: boolean | null,
        providePersonalPhoneNumber?: boolean | null,
        textOnly?: boolean | null,
        followupMeetingRequested?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productQuestionResponsesId?: string | null,
        profileQuestionResponsesId?: string | null,
        meetingQuestionResponsesId?: string | null,
        meetingInviteQuestionResponsesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    importJobs?:  {
      __typename: "ModelImportJobConnection",
      items:  Array< {
        __typename: "ImportJob",
        id: string,
        status: JobStatus,
        identityId?: string | null,
        result?: string | null,
        profileId?: string | null,
        lastStartDateTime?: string | null,
        lastEndDateTime?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileImportJobsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    exportJobs?:  {
      __typename: "ModelExportJobConnection",
      items:  Array< {
        __typename: "ExportJob",
        id: string,
        status: JobStatus,
        profileId?: string | null,
        meetingInvitationType?: MeetingInvitationType | null,
        startDateTime: string,
        endDateTime: string,
        owner: string,
        identityId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileExportJobsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    privacyEnabled: boolean,
    owner?: string | null,
    meetingInvites?:  {
      __typename: "ModelMeetingInviteConnection",
      items:  Array< {
        __typename: "MeetingInvite",
        id: string,
        meetingId: string,
        providerId?: string | null,
        title: string,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        meetingRoomId?: string | null,
        providerSessionId?: string | null,
        providerJoinData?: string | null,
        sessionStatus?: MeetingRoomSessionStatus | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        profileId: string,
        owner?: string | null,
        meetingInvitationType: MeetingInvitationType,
        teamId?: string | null,
        isInterested?: boolean | null,
        feedbackSummary?: string | null,
        contactRequestTimeframe?: string | null,
        contactPhoneNumber?: string | null,
        contactEmail?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingInvitesId?: string | null,
        profileMeetingInvitesId?: string | null,
        meetingRoomProviderMeetingInvitesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    sortByFullName?: number | null,
    transcriptionLogs?:  {
      __typename: "ModelTranscriptionEventAuditLogConnection",
      items:  Array< {
        __typename: "TranscriptionEventAuditLog",
        id: string,
        date: string,
        author: string,
        resource: string,
        action: string,
        payload?: string | null,
        ipAddress?: string | null,
        meetingInviteId: string,
        profileId: string,
        authorProfileId?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileTranscriptionLogsId?: string | null,
        meetingInviteTranscriptionLogsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    timeZone?: string | null,
    forwardEmails?:  {
      __typename: "ModelForwardEmailConnection",
      items:  Array< {
        __typename: "ForwardEmail",
        id: string,
        received?: number | null,
        remaining?: number | null,
        publicEmail: string,
        expireAt?: string | null,
        privateEmail?: string | null,
        publicReplyEmail?: string | null,
        profileId?: string | null,
        sellerProfileId?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileForwardEmailsId?: string | null,
        profileSellerForwardEmailsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    sellerForwardEmails?:  {
      __typename: "ModelForwardEmailConnection",
      items:  Array< {
        __typename: "ForwardEmail",
        id: string,
        received?: number | null,
        remaining?: number | null,
        publicEmail: string,
        expireAt?: string | null,
        privateEmail?: string | null,
        publicReplyEmail?: string | null,
        profileId?: string | null,
        sellerProfileId?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileForwardEmailsId?: string | null,
        profileSellerForwardEmailsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    organizationProfilesId?: string | null,
  } | null,
};

export type OnUpdateProfileSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateProfileSubscription = {
  onUpdateProfile?:  {
    __typename: "Profile",
    id: string,
    fullName: string,
    fullNameLowerCase?: string | null,
    email: string,
    externalId?: string | null,
    userId?: string | null,
    occupation?: string | null,
    profile?: string | null,
    phoneNumber?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      externalId?: string | null,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      url?: string | null,
      phoneNumber?: string | null,
      locations?:  {
        __typename: "ModelOrganizationLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      profiles?:  {
        __typename: "ModelProfileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    organizationId?: string | null,
    auditLogs?:  {
      __typename: "ModelMeetingRoomEventAuditLogConnection",
      items:  Array< {
        __typename: "MeetingRoomEventAuditLog",
        id: string,
        date: string,
        author: string,
        resource: string,
        action: string,
        payload?: string | null,
        ipAddress?: string | null,
        meetingRoomId: string,
        profileId?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileAuditLogsId?: string | null,
        meetingRoomAuditlogsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    jobAuditLogs?:  {
      __typename: "ModelImportJobAuditLogConnection",
      items:  Array< {
        __typename: "ImportJobAuditLog",
        id: string,
        date: string,
        author: string,
        resource: string,
        action: string,
        payload?: string | null,
        ipAddress?: string | null,
        importJobId: string,
        profileId?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileJobAuditLogsId?: string | null,
        importJobAuditLogsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    exportJobAuditLogs?:  {
      __typename: "ModelExportJobAuditLogConnection",
      items:  Array< {
        __typename: "ExportJobAuditLog",
        id: string,
        date: string,
        author: string,
        resource: string,
        action: string,
        payload?: string | null,
        ipAddress?: string | null,
        exportJobId: string,
        profileId?: string | null,
        owner: string,
        identityId?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileExportJobAuditLogsId?: string | null,
        exportJobAuditLogsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    teamRelationships?:  {
      __typename: "ModelTeamMemberRelationshipConnection",
      items:  Array< {
        __typename: "TeamMemberRelationship",
        id: string,
        teamId: string,
        memberProfileId: string,
        relationship: TeamRelationship,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileTeamRelationshipsId?: string | null,
        teamMemberRelationshipsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    meetingAttendees?:  {
      __typename: "ModelMeetingAttendeeConnection",
      items:  Array< {
        __typename: "MeetingAttendee",
        id: string,
        profileId: string,
        meetingId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileMeetingAttendeesId?: string | null,
        meetingMeetingAttendeesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    locations?:  {
      __typename: "ModelProfileLocationConnection",
      items:  Array< {
        __typename: "ProfileLocation",
        id: string,
        title?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        country?: string | null,
        profileId: string,
        isPrimary: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileLocationsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    devices?:  {
      __typename: "ModelProfileDeviceConnection",
      items:  Array< {
        __typename: "ProfileDevice",
        id: string,
        deviceHash: string,
        hasWebcam?: boolean | null,
        hasMicrophone?: boolean | null,
        isScreenCapturingSupported?: boolean | null,
        isWebRTCSupported?: boolean | null,
        isWebsiteHasMicrophonePermissions?: boolean | null,
        isWebsiteHasWebcamPermissions?: boolean | null,
        isWebSocketsSupported?: boolean | null,
        clockOffset?: number | null,
        lastSeen?: string | null,
        lastLogin?: string | null,
        lastMeetingId?: string | null,
        lastMeetingInviteId?: string | null,
        lastMeetingJoin?: string | null,
        lastMeetingStatus?: string | null,
        meetingData?: string | null,
        userAgent?: string | null,
        userAgentSummary?: string | null,
        micStatus?: string | null,
        cameraStatus?: string | null,
        networkQuality?: string | null,
        profileId: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileDevicesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    images?:  {
      __typename: "ModelProfileImageConnection",
      items:  Array< {
        __typename: "ProfileImage",
        id: string,
        title: string,
        alt?: string | null,
        width?: number | null,
        height?: number | null,
        profileId: string,
        isDefault: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileImagesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    questionResponses?:  {
      __typename: "ModelQuestionResponseConnection",
      items:  Array< {
        __typename: "QuestionResponse",
        id: string,
        response?: string | null,
        privateNotes?: string | null,
        questionId: string,
        profileId: string,
        meetingId: string,
        meetingInviteId: string,
        owner?: string | null,
        contactRequestTimeframe?: string | null,
        contactRequestDateTime?: string | null,
        contactPhoneNumber?: string | null,
        contactEmail?: string | null,
        contactName?: string | null,
        providePersonalEmail?: boolean | null,
        providePersonalPhoneNumber?: boolean | null,
        textOnly?: boolean | null,
        followupMeetingRequested?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productQuestionResponsesId?: string | null,
        profileQuestionResponsesId?: string | null,
        meetingQuestionResponsesId?: string | null,
        meetingInviteQuestionResponsesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    importJobs?:  {
      __typename: "ModelImportJobConnection",
      items:  Array< {
        __typename: "ImportJob",
        id: string,
        status: JobStatus,
        identityId?: string | null,
        result?: string | null,
        profileId?: string | null,
        lastStartDateTime?: string | null,
        lastEndDateTime?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileImportJobsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    exportJobs?:  {
      __typename: "ModelExportJobConnection",
      items:  Array< {
        __typename: "ExportJob",
        id: string,
        status: JobStatus,
        profileId?: string | null,
        meetingInvitationType?: MeetingInvitationType | null,
        startDateTime: string,
        endDateTime: string,
        owner: string,
        identityId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileExportJobsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    privacyEnabled: boolean,
    owner?: string | null,
    meetingInvites?:  {
      __typename: "ModelMeetingInviteConnection",
      items:  Array< {
        __typename: "MeetingInvite",
        id: string,
        meetingId: string,
        providerId?: string | null,
        title: string,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        meetingRoomId?: string | null,
        providerSessionId?: string | null,
        providerJoinData?: string | null,
        sessionStatus?: MeetingRoomSessionStatus | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        profileId: string,
        owner?: string | null,
        meetingInvitationType: MeetingInvitationType,
        teamId?: string | null,
        isInterested?: boolean | null,
        feedbackSummary?: string | null,
        contactRequestTimeframe?: string | null,
        contactPhoneNumber?: string | null,
        contactEmail?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingInvitesId?: string | null,
        profileMeetingInvitesId?: string | null,
        meetingRoomProviderMeetingInvitesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    sortByFullName?: number | null,
    transcriptionLogs?:  {
      __typename: "ModelTranscriptionEventAuditLogConnection",
      items:  Array< {
        __typename: "TranscriptionEventAuditLog",
        id: string,
        date: string,
        author: string,
        resource: string,
        action: string,
        payload?: string | null,
        ipAddress?: string | null,
        meetingInviteId: string,
        profileId: string,
        authorProfileId?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileTranscriptionLogsId?: string | null,
        meetingInviteTranscriptionLogsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    timeZone?: string | null,
    forwardEmails?:  {
      __typename: "ModelForwardEmailConnection",
      items:  Array< {
        __typename: "ForwardEmail",
        id: string,
        received?: number | null,
        remaining?: number | null,
        publicEmail: string,
        expireAt?: string | null,
        privateEmail?: string | null,
        publicReplyEmail?: string | null,
        profileId?: string | null,
        sellerProfileId?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileForwardEmailsId?: string | null,
        profileSellerForwardEmailsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    sellerForwardEmails?:  {
      __typename: "ModelForwardEmailConnection",
      items:  Array< {
        __typename: "ForwardEmail",
        id: string,
        received?: number | null,
        remaining?: number | null,
        publicEmail: string,
        expireAt?: string | null,
        privateEmail?: string | null,
        publicReplyEmail?: string | null,
        profileId?: string | null,
        sellerProfileId?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileForwardEmailsId?: string | null,
        profileSellerForwardEmailsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    organizationProfilesId?: string | null,
  } | null,
};

export type OnDeleteProfileSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteProfileSubscription = {
  onDeleteProfile?:  {
    __typename: "Profile",
    id: string,
    fullName: string,
    fullNameLowerCase?: string | null,
    email: string,
    externalId?: string | null,
    userId?: string | null,
    occupation?: string | null,
    profile?: string | null,
    phoneNumber?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      externalId?: string | null,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      url?: string | null,
      phoneNumber?: string | null,
      locations?:  {
        __typename: "ModelOrganizationLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      profiles?:  {
        __typename: "ModelProfileConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    organizationId?: string | null,
    auditLogs?:  {
      __typename: "ModelMeetingRoomEventAuditLogConnection",
      items:  Array< {
        __typename: "MeetingRoomEventAuditLog",
        id: string,
        date: string,
        author: string,
        resource: string,
        action: string,
        payload?: string | null,
        ipAddress?: string | null,
        meetingRoomId: string,
        profileId?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileAuditLogsId?: string | null,
        meetingRoomAuditlogsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    jobAuditLogs?:  {
      __typename: "ModelImportJobAuditLogConnection",
      items:  Array< {
        __typename: "ImportJobAuditLog",
        id: string,
        date: string,
        author: string,
        resource: string,
        action: string,
        payload?: string | null,
        ipAddress?: string | null,
        importJobId: string,
        profileId?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileJobAuditLogsId?: string | null,
        importJobAuditLogsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    exportJobAuditLogs?:  {
      __typename: "ModelExportJobAuditLogConnection",
      items:  Array< {
        __typename: "ExportJobAuditLog",
        id: string,
        date: string,
        author: string,
        resource: string,
        action: string,
        payload?: string | null,
        ipAddress?: string | null,
        exportJobId: string,
        profileId?: string | null,
        owner: string,
        identityId?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileExportJobAuditLogsId?: string | null,
        exportJobAuditLogsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    teamRelationships?:  {
      __typename: "ModelTeamMemberRelationshipConnection",
      items:  Array< {
        __typename: "TeamMemberRelationship",
        id: string,
        teamId: string,
        memberProfileId: string,
        relationship: TeamRelationship,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileTeamRelationshipsId?: string | null,
        teamMemberRelationshipsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    meetingAttendees?:  {
      __typename: "ModelMeetingAttendeeConnection",
      items:  Array< {
        __typename: "MeetingAttendee",
        id: string,
        profileId: string,
        meetingId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileMeetingAttendeesId?: string | null,
        meetingMeetingAttendeesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    locations?:  {
      __typename: "ModelProfileLocationConnection",
      items:  Array< {
        __typename: "ProfileLocation",
        id: string,
        title?: string | null,
        address?: string | null,
        city?: string | null,
        state?: string | null,
        country?: string | null,
        profileId: string,
        isPrimary: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileLocationsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    devices?:  {
      __typename: "ModelProfileDeviceConnection",
      items:  Array< {
        __typename: "ProfileDevice",
        id: string,
        deviceHash: string,
        hasWebcam?: boolean | null,
        hasMicrophone?: boolean | null,
        isScreenCapturingSupported?: boolean | null,
        isWebRTCSupported?: boolean | null,
        isWebsiteHasMicrophonePermissions?: boolean | null,
        isWebsiteHasWebcamPermissions?: boolean | null,
        isWebSocketsSupported?: boolean | null,
        clockOffset?: number | null,
        lastSeen?: string | null,
        lastLogin?: string | null,
        lastMeetingId?: string | null,
        lastMeetingInviteId?: string | null,
        lastMeetingJoin?: string | null,
        lastMeetingStatus?: string | null,
        meetingData?: string | null,
        userAgent?: string | null,
        userAgentSummary?: string | null,
        micStatus?: string | null,
        cameraStatus?: string | null,
        networkQuality?: string | null,
        profileId: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileDevicesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    images?:  {
      __typename: "ModelProfileImageConnection",
      items:  Array< {
        __typename: "ProfileImage",
        id: string,
        title: string,
        alt?: string | null,
        width?: number | null,
        height?: number | null,
        profileId: string,
        isDefault: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileImagesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    questionResponses?:  {
      __typename: "ModelQuestionResponseConnection",
      items:  Array< {
        __typename: "QuestionResponse",
        id: string,
        response?: string | null,
        privateNotes?: string | null,
        questionId: string,
        profileId: string,
        meetingId: string,
        meetingInviteId: string,
        owner?: string | null,
        contactRequestTimeframe?: string | null,
        contactRequestDateTime?: string | null,
        contactPhoneNumber?: string | null,
        contactEmail?: string | null,
        contactName?: string | null,
        providePersonalEmail?: boolean | null,
        providePersonalPhoneNumber?: boolean | null,
        textOnly?: boolean | null,
        followupMeetingRequested?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productQuestionResponsesId?: string | null,
        profileQuestionResponsesId?: string | null,
        meetingQuestionResponsesId?: string | null,
        meetingInviteQuestionResponsesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    importJobs?:  {
      __typename: "ModelImportJobConnection",
      items:  Array< {
        __typename: "ImportJob",
        id: string,
        status: JobStatus,
        identityId?: string | null,
        result?: string | null,
        profileId?: string | null,
        lastStartDateTime?: string | null,
        lastEndDateTime?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileImportJobsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    exportJobs?:  {
      __typename: "ModelExportJobConnection",
      items:  Array< {
        __typename: "ExportJob",
        id: string,
        status: JobStatus,
        profileId?: string | null,
        meetingInvitationType?: MeetingInvitationType | null,
        startDateTime: string,
        endDateTime: string,
        owner: string,
        identityId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileExportJobsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    privacyEnabled: boolean,
    owner?: string | null,
    meetingInvites?:  {
      __typename: "ModelMeetingInviteConnection",
      items:  Array< {
        __typename: "MeetingInvite",
        id: string,
        meetingId: string,
        providerId?: string | null,
        title: string,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        meetingRoomId?: string | null,
        providerSessionId?: string | null,
        providerJoinData?: string | null,
        sessionStatus?: MeetingRoomSessionStatus | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        profileId: string,
        owner?: string | null,
        meetingInvitationType: MeetingInvitationType,
        teamId?: string | null,
        isInterested?: boolean | null,
        feedbackSummary?: string | null,
        contactRequestTimeframe?: string | null,
        contactPhoneNumber?: string | null,
        contactEmail?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingInvitesId?: string | null,
        profileMeetingInvitesId?: string | null,
        meetingRoomProviderMeetingInvitesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    sortByFullName?: number | null,
    transcriptionLogs?:  {
      __typename: "ModelTranscriptionEventAuditLogConnection",
      items:  Array< {
        __typename: "TranscriptionEventAuditLog",
        id: string,
        date: string,
        author: string,
        resource: string,
        action: string,
        payload?: string | null,
        ipAddress?: string | null,
        meetingInviteId: string,
        profileId: string,
        authorProfileId?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileTranscriptionLogsId?: string | null,
        meetingInviteTranscriptionLogsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    timeZone?: string | null,
    forwardEmails?:  {
      __typename: "ModelForwardEmailConnection",
      items:  Array< {
        __typename: "ForwardEmail",
        id: string,
        received?: number | null,
        remaining?: number | null,
        publicEmail: string,
        expireAt?: string | null,
        privateEmail?: string | null,
        publicReplyEmail?: string | null,
        profileId?: string | null,
        sellerProfileId?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileForwardEmailsId?: string | null,
        profileSellerForwardEmailsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    sellerForwardEmails?:  {
      __typename: "ModelForwardEmailConnection",
      items:  Array< {
        __typename: "ForwardEmail",
        id: string,
        received?: number | null,
        remaining?: number | null,
        publicEmail: string,
        expireAt?: string | null,
        privateEmail?: string | null,
        publicReplyEmail?: string | null,
        profileId?: string | null,
        sellerProfileId?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileForwardEmailsId?: string | null,
        profileSellerForwardEmailsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    organizationProfilesId?: string | null,
  } | null,
};

export type OnCreateProfileDeviceSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateProfileDeviceSubscription = {
  onCreateProfileDevice?:  {
    __typename: "ProfileDevice",
    id: string,
    deviceHash: string,
    hasWebcam?: boolean | null,
    hasMicrophone?: boolean | null,
    isScreenCapturingSupported?: boolean | null,
    isWebRTCSupported?: boolean | null,
    isWebsiteHasMicrophonePermissions?: boolean | null,
    isWebsiteHasWebcamPermissions?: boolean | null,
    isWebSocketsSupported?: boolean | null,
    clockOffset?: number | null,
    lastSeen?: string | null,
    lastLogin?: string | null,
    lastMeetingId?: string | null,
    lastMeetingInviteId?: string | null,
    lastMeetingJoin?: string | null,
    lastMeetingStatus?: string | null,
    meetingData?: string | null,
    userAgent?: string | null,
    userAgentSummary?: string | null,
    micStatus?: string | null,
    cameraStatus?: string | null,
    networkQuality?: string | null,
    profile:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    },
    profileId: string,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileDevicesId?: string | null,
  } | null,
};

export type OnUpdateProfileDeviceSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateProfileDeviceSubscription = {
  onUpdateProfileDevice?:  {
    __typename: "ProfileDevice",
    id: string,
    deviceHash: string,
    hasWebcam?: boolean | null,
    hasMicrophone?: boolean | null,
    isScreenCapturingSupported?: boolean | null,
    isWebRTCSupported?: boolean | null,
    isWebsiteHasMicrophonePermissions?: boolean | null,
    isWebsiteHasWebcamPermissions?: boolean | null,
    isWebSocketsSupported?: boolean | null,
    clockOffset?: number | null,
    lastSeen?: string | null,
    lastLogin?: string | null,
    lastMeetingId?: string | null,
    lastMeetingInviteId?: string | null,
    lastMeetingJoin?: string | null,
    lastMeetingStatus?: string | null,
    meetingData?: string | null,
    userAgent?: string | null,
    userAgentSummary?: string | null,
    micStatus?: string | null,
    cameraStatus?: string | null,
    networkQuality?: string | null,
    profile:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    },
    profileId: string,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileDevicesId?: string | null,
  } | null,
};

export type OnDeleteProfileDeviceSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteProfileDeviceSubscription = {
  onDeleteProfileDevice?:  {
    __typename: "ProfileDevice",
    id: string,
    deviceHash: string,
    hasWebcam?: boolean | null,
    hasMicrophone?: boolean | null,
    isScreenCapturingSupported?: boolean | null,
    isWebRTCSupported?: boolean | null,
    isWebsiteHasMicrophonePermissions?: boolean | null,
    isWebsiteHasWebcamPermissions?: boolean | null,
    isWebSocketsSupported?: boolean | null,
    clockOffset?: number | null,
    lastSeen?: string | null,
    lastLogin?: string | null,
    lastMeetingId?: string | null,
    lastMeetingInviteId?: string | null,
    lastMeetingJoin?: string | null,
    lastMeetingStatus?: string | null,
    meetingData?: string | null,
    userAgent?: string | null,
    userAgentSummary?: string | null,
    micStatus?: string | null,
    cameraStatus?: string | null,
    networkQuality?: string | null,
    profile:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    },
    profileId: string,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileDevicesId?: string | null,
  } | null,
};

export type OnCreateTeamImageSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateTeamImageSubscription = {
  onCreateTeamImage?:  {
    __typename: "TeamImage",
    id: string,
    title: string,
    alt?: string | null,
    width?: number | null,
    height?: number | null,
    image:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    },
    team:  {
      __typename: "Team",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      memberRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      privacyEnabled: boolean,
      privacyEnableOtherTeams?: boolean | null,
      images?:  {
        __typename: "ModelTeamImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    teamId: string,
    isDefault: boolean,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    teamImagesId?: string | null,
  } | null,
};

export type OnUpdateTeamImageSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateTeamImageSubscription = {
  onUpdateTeamImage?:  {
    __typename: "TeamImage",
    id: string,
    title: string,
    alt?: string | null,
    width?: number | null,
    height?: number | null,
    image:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    },
    team:  {
      __typename: "Team",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      memberRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      privacyEnabled: boolean,
      privacyEnableOtherTeams?: boolean | null,
      images?:  {
        __typename: "ModelTeamImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    teamId: string,
    isDefault: boolean,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    teamImagesId?: string | null,
  } | null,
};

export type OnDeleteTeamImageSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteTeamImageSubscription = {
  onDeleteTeamImage?:  {
    __typename: "TeamImage",
    id: string,
    title: string,
    alt?: string | null,
    width?: number | null,
    height?: number | null,
    image:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    },
    team:  {
      __typename: "Team",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      memberRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      privacyEnabled: boolean,
      privacyEnableOtherTeams?: boolean | null,
      images?:  {
        __typename: "ModelTeamImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    teamId: string,
    isDefault: boolean,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    teamImagesId?: string | null,
  } | null,
};

export type OnCreateTeamSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateTeamSubscription = {
  onCreateTeam?:  {
    __typename: "Team",
    id: string,
    name: string,
    nameLowerCase?: string | null,
    description?: string | null,
    memberRelationships?:  {
      __typename: "ModelTeamMemberRelationshipConnection",
      items:  Array< {
        __typename: "TeamMemberRelationship",
        id: string,
        teamId: string,
        memberProfileId: string,
        relationship: TeamRelationship,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileTeamRelationshipsId?: string | null,
        teamMemberRelationshipsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    meetingRelationships?:  {
      __typename: "ModelTeamMeetingRelationshipConnection",
      items:  Array< {
        __typename: "TeamMeetingRelationship",
        id: string,
        teamId: string,
        meetingId: string,
        relationshipType: TeamMeetingRelationshipType,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        teamMeetingRelationshipsId?: string | null,
        meetingTeamRelationshipsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    products?:  {
      __typename: "ModelProductConnection",
      items:  Array< {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    labels?: Array< string | null > | null,
    privacyEnabled: boolean,
    privacyEnableOtherTeams?: boolean | null,
    images?:  {
      __typename: "ModelTeamImageConnection",
      items:  Array< {
        __typename: "TeamImage",
        id: string,
        title: string,
        alt?: string | null,
        width?: number | null,
        height?: number | null,
        teamId: string,
        isDefault: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        teamImagesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    transcriptions?:  {
      __typename: "ModelTranscriptionConnection",
      items:  Array< {
        __typename: "Transcription",
        id: string,
        date: string,
        title: string,
        description?: string | null,
        startDateTime: string,
        endDateTime: string,
        sizeInBytes?: number | null,
        numberOfTranscriptions?: number | null,
        isZipFile: boolean,
        meetingId?: string | null,
        teamId?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        teamTranscriptionsId?: string | null,
        meetingTranscriptionsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    owner?: string | null,
    sortByName?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateTeamSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateTeamSubscription = {
  onUpdateTeam?:  {
    __typename: "Team",
    id: string,
    name: string,
    nameLowerCase?: string | null,
    description?: string | null,
    memberRelationships?:  {
      __typename: "ModelTeamMemberRelationshipConnection",
      items:  Array< {
        __typename: "TeamMemberRelationship",
        id: string,
        teamId: string,
        memberProfileId: string,
        relationship: TeamRelationship,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileTeamRelationshipsId?: string | null,
        teamMemberRelationshipsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    meetingRelationships?:  {
      __typename: "ModelTeamMeetingRelationshipConnection",
      items:  Array< {
        __typename: "TeamMeetingRelationship",
        id: string,
        teamId: string,
        meetingId: string,
        relationshipType: TeamMeetingRelationshipType,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        teamMeetingRelationshipsId?: string | null,
        meetingTeamRelationshipsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    products?:  {
      __typename: "ModelProductConnection",
      items:  Array< {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    labels?: Array< string | null > | null,
    privacyEnabled: boolean,
    privacyEnableOtherTeams?: boolean | null,
    images?:  {
      __typename: "ModelTeamImageConnection",
      items:  Array< {
        __typename: "TeamImage",
        id: string,
        title: string,
        alt?: string | null,
        width?: number | null,
        height?: number | null,
        teamId: string,
        isDefault: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        teamImagesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    transcriptions?:  {
      __typename: "ModelTranscriptionConnection",
      items:  Array< {
        __typename: "Transcription",
        id: string,
        date: string,
        title: string,
        description?: string | null,
        startDateTime: string,
        endDateTime: string,
        sizeInBytes?: number | null,
        numberOfTranscriptions?: number | null,
        isZipFile: boolean,
        meetingId?: string | null,
        teamId?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        teamTranscriptionsId?: string | null,
        meetingTranscriptionsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    owner?: string | null,
    sortByName?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteTeamSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteTeamSubscription = {
  onDeleteTeam?:  {
    __typename: "Team",
    id: string,
    name: string,
    nameLowerCase?: string | null,
    description?: string | null,
    memberRelationships?:  {
      __typename: "ModelTeamMemberRelationshipConnection",
      items:  Array< {
        __typename: "TeamMemberRelationship",
        id: string,
        teamId: string,
        memberProfileId: string,
        relationship: TeamRelationship,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileTeamRelationshipsId?: string | null,
        teamMemberRelationshipsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    meetingRelationships?:  {
      __typename: "ModelTeamMeetingRelationshipConnection",
      items:  Array< {
        __typename: "TeamMeetingRelationship",
        id: string,
        teamId: string,
        meetingId: string,
        relationshipType: TeamMeetingRelationshipType,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        teamMeetingRelationshipsId?: string | null,
        meetingTeamRelationshipsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    products?:  {
      __typename: "ModelProductConnection",
      items:  Array< {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    labels?: Array< string | null > | null,
    privacyEnabled: boolean,
    privacyEnableOtherTeams?: boolean | null,
    images?:  {
      __typename: "ModelTeamImageConnection",
      items:  Array< {
        __typename: "TeamImage",
        id: string,
        title: string,
        alt?: string | null,
        width?: number | null,
        height?: number | null,
        teamId: string,
        isDefault: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        teamImagesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    transcriptions?:  {
      __typename: "ModelTranscriptionConnection",
      items:  Array< {
        __typename: "Transcription",
        id: string,
        date: string,
        title: string,
        description?: string | null,
        startDateTime: string,
        endDateTime: string,
        sizeInBytes?: number | null,
        numberOfTranscriptions?: number | null,
        isZipFile: boolean,
        meetingId?: string | null,
        teamId?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        teamTranscriptionsId?: string | null,
        meetingTranscriptionsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    owner?: string | null,
    sortByName?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateTeamMemberRelationshipSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateTeamMemberRelationshipSubscription = {
  onCreateTeamMemberRelationship?:  {
    __typename: "TeamMemberRelationship",
    id: string,
    team:  {
      __typename: "Team",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      memberRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      privacyEnabled: boolean,
      privacyEnableOtherTeams?: boolean | null,
      images?:  {
        __typename: "ModelTeamImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    teamId: string,
    member:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    },
    memberProfileId: string,
    relationship: TeamRelationship,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileTeamRelationshipsId?: string | null,
    teamMemberRelationshipsId?: string | null,
  } | null,
};

export type OnUpdateTeamMemberRelationshipSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateTeamMemberRelationshipSubscription = {
  onUpdateTeamMemberRelationship?:  {
    __typename: "TeamMemberRelationship",
    id: string,
    team:  {
      __typename: "Team",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      memberRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      privacyEnabled: boolean,
      privacyEnableOtherTeams?: boolean | null,
      images?:  {
        __typename: "ModelTeamImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    teamId: string,
    member:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    },
    memberProfileId: string,
    relationship: TeamRelationship,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileTeamRelationshipsId?: string | null,
    teamMemberRelationshipsId?: string | null,
  } | null,
};

export type OnDeleteTeamMemberRelationshipSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteTeamMemberRelationshipSubscription = {
  onDeleteTeamMemberRelationship?:  {
    __typename: "TeamMemberRelationship",
    id: string,
    team:  {
      __typename: "Team",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      memberRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      privacyEnabled: boolean,
      privacyEnableOtherTeams?: boolean | null,
      images?:  {
        __typename: "ModelTeamImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    teamId: string,
    member:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    },
    memberProfileId: string,
    relationship: TeamRelationship,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileTeamRelationshipsId?: string | null,
    teamMemberRelationshipsId?: string | null,
  } | null,
};

export type OnCreateMeetingRoomProviderSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateMeetingRoomProviderSubscription = {
  onCreateMeetingRoomProvider?:  {
    __typename: "MeetingRoomProvider",
    id: string,
    name: string,
    componentName?: string | null,
    description?: string | null,
    configuration?: string | null,
    meetings?:  {
      __typename: "ModelMeetingConnection",
      items:  Array< {
        __typename: "Meeting",
        id: string,
        title: string,
        titleLowerCase?: string | null,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        preferredRoomProviderId?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        sellerOrganizationNameLowerCase?: string | null,
        sellerTeamNameLowerCase?: string | null,
        buyerTeamNameLowerCase?: string | null,
        externalId?: string | null,
        metrics?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingsId?: string | null,
        meetingRoomProviderMeetingsId?: string | null,
        meetingRoomId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    meetingRooms?:  {
      __typename: "ModelMeetingRoomConnection",
      items:  Array< {
        __typename: "MeetingRoom",
        id: string,
        name: string,
        meetingId: string,
        providerId: string,
        providerMeetingId?: string | null,
        accessCode: string,
        status: MeetingRoomStatus,
        startDateTime?: string | null,
        endDateTime?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        roomCreatedAt?: string | null,
        roomDestroyedAt?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        isReadinessTest: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        meetingRoomProviderMeetingRoomsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    meetingInvites?:  {
      __typename: "ModelMeetingInviteConnection",
      items:  Array< {
        __typename: "MeetingInvite",
        id: string,
        meetingId: string,
        providerId?: string | null,
        title: string,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        meetingRoomId?: string | null,
        providerSessionId?: string | null,
        providerJoinData?: string | null,
        sessionStatus?: MeetingRoomSessionStatus | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        profileId: string,
        owner?: string | null,
        meetingInvitationType: MeetingInvitationType,
        teamId?: string | null,
        isInterested?: boolean | null,
        feedbackSummary?: string | null,
        contactRequestTimeframe?: string | null,
        contactPhoneNumber?: string | null,
        contactEmail?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingInvitesId?: string | null,
        profileMeetingInvitesId?: string | null,
        meetingRoomProviderMeetingInvitesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateMeetingRoomProviderSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateMeetingRoomProviderSubscription = {
  onUpdateMeetingRoomProvider?:  {
    __typename: "MeetingRoomProvider",
    id: string,
    name: string,
    componentName?: string | null,
    description?: string | null,
    configuration?: string | null,
    meetings?:  {
      __typename: "ModelMeetingConnection",
      items:  Array< {
        __typename: "Meeting",
        id: string,
        title: string,
        titleLowerCase?: string | null,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        preferredRoomProviderId?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        sellerOrganizationNameLowerCase?: string | null,
        sellerTeamNameLowerCase?: string | null,
        buyerTeamNameLowerCase?: string | null,
        externalId?: string | null,
        metrics?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingsId?: string | null,
        meetingRoomProviderMeetingsId?: string | null,
        meetingRoomId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    meetingRooms?:  {
      __typename: "ModelMeetingRoomConnection",
      items:  Array< {
        __typename: "MeetingRoom",
        id: string,
        name: string,
        meetingId: string,
        providerId: string,
        providerMeetingId?: string | null,
        accessCode: string,
        status: MeetingRoomStatus,
        startDateTime?: string | null,
        endDateTime?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        roomCreatedAt?: string | null,
        roomDestroyedAt?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        isReadinessTest: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        meetingRoomProviderMeetingRoomsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    meetingInvites?:  {
      __typename: "ModelMeetingInviteConnection",
      items:  Array< {
        __typename: "MeetingInvite",
        id: string,
        meetingId: string,
        providerId?: string | null,
        title: string,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        meetingRoomId?: string | null,
        providerSessionId?: string | null,
        providerJoinData?: string | null,
        sessionStatus?: MeetingRoomSessionStatus | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        profileId: string,
        owner?: string | null,
        meetingInvitationType: MeetingInvitationType,
        teamId?: string | null,
        isInterested?: boolean | null,
        feedbackSummary?: string | null,
        contactRequestTimeframe?: string | null,
        contactPhoneNumber?: string | null,
        contactEmail?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingInvitesId?: string | null,
        profileMeetingInvitesId?: string | null,
        meetingRoomProviderMeetingInvitesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteMeetingRoomProviderSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteMeetingRoomProviderSubscription = {
  onDeleteMeetingRoomProvider?:  {
    __typename: "MeetingRoomProvider",
    id: string,
    name: string,
    componentName?: string | null,
    description?: string | null,
    configuration?: string | null,
    meetings?:  {
      __typename: "ModelMeetingConnection",
      items:  Array< {
        __typename: "Meeting",
        id: string,
        title: string,
        titleLowerCase?: string | null,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        preferredRoomProviderId?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        sellerOrganizationNameLowerCase?: string | null,
        sellerTeamNameLowerCase?: string | null,
        buyerTeamNameLowerCase?: string | null,
        externalId?: string | null,
        metrics?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingsId?: string | null,
        meetingRoomProviderMeetingsId?: string | null,
        meetingRoomId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    meetingRooms?:  {
      __typename: "ModelMeetingRoomConnection",
      items:  Array< {
        __typename: "MeetingRoom",
        id: string,
        name: string,
        meetingId: string,
        providerId: string,
        providerMeetingId?: string | null,
        accessCode: string,
        status: MeetingRoomStatus,
        startDateTime?: string | null,
        endDateTime?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        roomCreatedAt?: string | null,
        roomDestroyedAt?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        isReadinessTest: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        meetingRoomProviderMeetingRoomsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    meetingInvites?:  {
      __typename: "ModelMeetingInviteConnection",
      items:  Array< {
        __typename: "MeetingInvite",
        id: string,
        meetingId: string,
        providerId?: string | null,
        title: string,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        meetingRoomId?: string | null,
        providerSessionId?: string | null,
        providerJoinData?: string | null,
        sessionStatus?: MeetingRoomSessionStatus | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        profileId: string,
        owner?: string | null,
        meetingInvitationType: MeetingInvitationType,
        teamId?: string | null,
        isInterested?: boolean | null,
        feedbackSummary?: string | null,
        contactRequestTimeframe?: string | null,
        contactPhoneNumber?: string | null,
        contactEmail?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingInvitesId?: string | null,
        profileMeetingInvitesId?: string | null,
        meetingRoomProviderMeetingInvitesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateTeamMeetingRelationshipSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateTeamMeetingRelationshipSubscription = {
  onCreateTeamMeetingRelationship?:  {
    __typename: "TeamMeetingRelationship",
    id: string,
    team:  {
      __typename: "Team",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      memberRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      privacyEnabled: boolean,
      privacyEnableOtherTeams?: boolean | null,
      images?:  {
        __typename: "ModelTeamImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    teamId: string,
    meeting:  {
      __typename: "Meeting",
      id: string,
      title: string,
      titleLowerCase?: string | null,
      startDateTime: string,
      endDateTime: string,
      status: MeetingStatus,
      description?: string | null,
      preferredRoomProvider?:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      preferredRoomProviderId?: string | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      room?:  {
        __typename: "MeetingRoom",
        id: string,
        name: string,
        meetingId: string,
        providerId: string,
        providerMeetingId?: string | null,
        accessCode: string,
        status: MeetingRoomStatus,
        startDateTime?: string | null,
        endDateTime?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        roomCreatedAt?: string | null,
        roomDestroyedAt?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        isReadinessTest: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        meetingRoomProviderMeetingRoomsId?: string | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null,
      productId?: string | null,
      isReadinessTest: boolean,
      labels?: Array< string | null > | null,
      owner?: string | null,
      teamRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerOrganizationName?: string | null,
      sellerTeamName?: string | null,
      buyerTeamName?: string | null,
      sellerOrganizationNameLowerCase?: string | null,
      sellerTeamNameLowerCase?: string | null,
      buyerTeamNameLowerCase?: string | null,
      externalId?: string | null,
      metrics?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productMeetingsId?: string | null,
      meetingRoomProviderMeetingsId?: string | null,
      meetingRoomId?: string | null,
    },
    meetingId: string,
    relationshipType: TeamMeetingRelationshipType,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    teamMeetingRelationshipsId?: string | null,
    meetingTeamRelationshipsId?: string | null,
  } | null,
};

export type OnUpdateTeamMeetingRelationshipSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateTeamMeetingRelationshipSubscription = {
  onUpdateTeamMeetingRelationship?:  {
    __typename: "TeamMeetingRelationship",
    id: string,
    team:  {
      __typename: "Team",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      memberRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      privacyEnabled: boolean,
      privacyEnableOtherTeams?: boolean | null,
      images?:  {
        __typename: "ModelTeamImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    teamId: string,
    meeting:  {
      __typename: "Meeting",
      id: string,
      title: string,
      titleLowerCase?: string | null,
      startDateTime: string,
      endDateTime: string,
      status: MeetingStatus,
      description?: string | null,
      preferredRoomProvider?:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      preferredRoomProviderId?: string | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      room?:  {
        __typename: "MeetingRoom",
        id: string,
        name: string,
        meetingId: string,
        providerId: string,
        providerMeetingId?: string | null,
        accessCode: string,
        status: MeetingRoomStatus,
        startDateTime?: string | null,
        endDateTime?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        roomCreatedAt?: string | null,
        roomDestroyedAt?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        isReadinessTest: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        meetingRoomProviderMeetingRoomsId?: string | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null,
      productId?: string | null,
      isReadinessTest: boolean,
      labels?: Array< string | null > | null,
      owner?: string | null,
      teamRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerOrganizationName?: string | null,
      sellerTeamName?: string | null,
      buyerTeamName?: string | null,
      sellerOrganizationNameLowerCase?: string | null,
      sellerTeamNameLowerCase?: string | null,
      buyerTeamNameLowerCase?: string | null,
      externalId?: string | null,
      metrics?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productMeetingsId?: string | null,
      meetingRoomProviderMeetingsId?: string | null,
      meetingRoomId?: string | null,
    },
    meetingId: string,
    relationshipType: TeamMeetingRelationshipType,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    teamMeetingRelationshipsId?: string | null,
    meetingTeamRelationshipsId?: string | null,
  } | null,
};

export type OnDeleteTeamMeetingRelationshipSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteTeamMeetingRelationshipSubscription = {
  onDeleteTeamMeetingRelationship?:  {
    __typename: "TeamMeetingRelationship",
    id: string,
    team:  {
      __typename: "Team",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      memberRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      privacyEnabled: boolean,
      privacyEnableOtherTeams?: boolean | null,
      images?:  {
        __typename: "ModelTeamImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    teamId: string,
    meeting:  {
      __typename: "Meeting",
      id: string,
      title: string,
      titleLowerCase?: string | null,
      startDateTime: string,
      endDateTime: string,
      status: MeetingStatus,
      description?: string | null,
      preferredRoomProvider?:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      preferredRoomProviderId?: string | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      room?:  {
        __typename: "MeetingRoom",
        id: string,
        name: string,
        meetingId: string,
        providerId: string,
        providerMeetingId?: string | null,
        accessCode: string,
        status: MeetingRoomStatus,
        startDateTime?: string | null,
        endDateTime?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        roomCreatedAt?: string | null,
        roomDestroyedAt?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        isReadinessTest: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        meetingRoomProviderMeetingRoomsId?: string | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null,
      productId?: string | null,
      isReadinessTest: boolean,
      labels?: Array< string | null > | null,
      owner?: string | null,
      teamRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerOrganizationName?: string | null,
      sellerTeamName?: string | null,
      buyerTeamName?: string | null,
      sellerOrganizationNameLowerCase?: string | null,
      sellerTeamNameLowerCase?: string | null,
      buyerTeamNameLowerCase?: string | null,
      externalId?: string | null,
      metrics?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productMeetingsId?: string | null,
      meetingRoomProviderMeetingsId?: string | null,
      meetingRoomId?: string | null,
    },
    meetingId: string,
    relationshipType: TeamMeetingRelationshipType,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    teamMeetingRelationshipsId?: string | null,
    meetingTeamRelationshipsId?: string | null,
  } | null,
};

export type OnCreateMeetingAttendeeSubscription = {
  onCreateMeetingAttendee?:  {
    __typename: "MeetingAttendee",
    id: string,
    profile:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    },
    profileId: string,
    meeting:  {
      __typename: "Meeting",
      id: string,
      title: string,
      titleLowerCase?: string | null,
      startDateTime: string,
      endDateTime: string,
      status: MeetingStatus,
      description?: string | null,
      preferredRoomProvider?:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      preferredRoomProviderId?: string | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      room?:  {
        __typename: "MeetingRoom",
        id: string,
        name: string,
        meetingId: string,
        providerId: string,
        providerMeetingId?: string | null,
        accessCode: string,
        status: MeetingRoomStatus,
        startDateTime?: string | null,
        endDateTime?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        roomCreatedAt?: string | null,
        roomDestroyedAt?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        isReadinessTest: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        meetingRoomProviderMeetingRoomsId?: string | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null,
      productId?: string | null,
      isReadinessTest: boolean,
      labels?: Array< string | null > | null,
      owner?: string | null,
      teamRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerOrganizationName?: string | null,
      sellerTeamName?: string | null,
      buyerTeamName?: string | null,
      sellerOrganizationNameLowerCase?: string | null,
      sellerTeamNameLowerCase?: string | null,
      buyerTeamNameLowerCase?: string | null,
      externalId?: string | null,
      metrics?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productMeetingsId?: string | null,
      meetingRoomProviderMeetingsId?: string | null,
      meetingRoomId?: string | null,
    },
    meetingId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileMeetingAttendeesId?: string | null,
    meetingMeetingAttendeesId?: string | null,
  } | null,
};

export type OnUpdateMeetingAttendeeSubscription = {
  onUpdateMeetingAttendee?:  {
    __typename: "MeetingAttendee",
    id: string,
    profile:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    },
    profileId: string,
    meeting:  {
      __typename: "Meeting",
      id: string,
      title: string,
      titleLowerCase?: string | null,
      startDateTime: string,
      endDateTime: string,
      status: MeetingStatus,
      description?: string | null,
      preferredRoomProvider?:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      preferredRoomProviderId?: string | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      room?:  {
        __typename: "MeetingRoom",
        id: string,
        name: string,
        meetingId: string,
        providerId: string,
        providerMeetingId?: string | null,
        accessCode: string,
        status: MeetingRoomStatus,
        startDateTime?: string | null,
        endDateTime?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        roomCreatedAt?: string | null,
        roomDestroyedAt?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        isReadinessTest: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        meetingRoomProviderMeetingRoomsId?: string | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null,
      productId?: string | null,
      isReadinessTest: boolean,
      labels?: Array< string | null > | null,
      owner?: string | null,
      teamRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerOrganizationName?: string | null,
      sellerTeamName?: string | null,
      buyerTeamName?: string | null,
      sellerOrganizationNameLowerCase?: string | null,
      sellerTeamNameLowerCase?: string | null,
      buyerTeamNameLowerCase?: string | null,
      externalId?: string | null,
      metrics?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productMeetingsId?: string | null,
      meetingRoomProviderMeetingsId?: string | null,
      meetingRoomId?: string | null,
    },
    meetingId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileMeetingAttendeesId?: string | null,
    meetingMeetingAttendeesId?: string | null,
  } | null,
};

export type OnDeleteMeetingAttendeeSubscription = {
  onDeleteMeetingAttendee?:  {
    __typename: "MeetingAttendee",
    id: string,
    profile:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    },
    profileId: string,
    meeting:  {
      __typename: "Meeting",
      id: string,
      title: string,
      titleLowerCase?: string | null,
      startDateTime: string,
      endDateTime: string,
      status: MeetingStatus,
      description?: string | null,
      preferredRoomProvider?:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      preferredRoomProviderId?: string | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      room?:  {
        __typename: "MeetingRoom",
        id: string,
        name: string,
        meetingId: string,
        providerId: string,
        providerMeetingId?: string | null,
        accessCode: string,
        status: MeetingRoomStatus,
        startDateTime?: string | null,
        endDateTime?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        roomCreatedAt?: string | null,
        roomDestroyedAt?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        isReadinessTest: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        meetingRoomProviderMeetingRoomsId?: string | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null,
      productId?: string | null,
      isReadinessTest: boolean,
      labels?: Array< string | null > | null,
      owner?: string | null,
      teamRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerOrganizationName?: string | null,
      sellerTeamName?: string | null,
      buyerTeamName?: string | null,
      sellerOrganizationNameLowerCase?: string | null,
      sellerTeamNameLowerCase?: string | null,
      buyerTeamNameLowerCase?: string | null,
      externalId?: string | null,
      metrics?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productMeetingsId?: string | null,
      meetingRoomProviderMeetingsId?: string | null,
      meetingRoomId?: string | null,
    },
    meetingId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileMeetingAttendeesId?: string | null,
    meetingMeetingAttendeesId?: string | null,
  } | null,
};

export type OnCreateMeetingSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateMeetingSubscription = {
  onCreateMeeting?:  {
    __typename: "Meeting",
    id: string,
    title: string,
    titleLowerCase?: string | null,
    startDateTime: string,
    endDateTime: string,
    status: MeetingStatus,
    description?: string | null,
    preferredRoomProvider?:  {
      __typename: "MeetingRoomProvider",
      id: string,
      name: string,
      componentName?: string | null,
      description?: string | null,
      configuration?: string | null,
      meetings?:  {
        __typename: "ModelMeetingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingRooms?:  {
        __typename: "ModelMeetingRoomConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    preferredRoomProviderId?: string | null,
    actualStartDateTime?: string | null,
    actualEndDateTime?: string | null,
    room?:  {
      __typename: "MeetingRoom",
      id: string,
      name: string,
      meeting:  {
        __typename: "Meeting",
        id: string,
        title: string,
        titleLowerCase?: string | null,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        preferredRoomProviderId?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        sellerOrganizationNameLowerCase?: string | null,
        sellerTeamNameLowerCase?: string | null,
        buyerTeamNameLowerCase?: string | null,
        externalId?: string | null,
        metrics?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingsId?: string | null,
        meetingRoomProviderMeetingsId?: string | null,
        meetingRoomId?: string | null,
      },
      meetingId: string,
      provider:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      providerId: string,
      providerMeetingId?: string | null,
      accessCode: string,
      status: MeetingRoomStatus,
      startDateTime?: string | null,
      endDateTime?: string | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      roomCreatedAt?: string | null,
      roomDestroyedAt?: string | null,
      auditlogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      isReadinessTest: boolean,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      meetingRoomProviderMeetingRoomsId?: string | null,
    } | null,
    questionResponses?:  {
      __typename: "ModelQuestionResponseConnection",
      items:  Array< {
        __typename: "QuestionResponse",
        id: string,
        response?: string | null,
        privateNotes?: string | null,
        questionId: string,
        profileId: string,
        meetingId: string,
        meetingInviteId: string,
        owner?: string | null,
        contactRequestTimeframe?: string | null,
        contactRequestDateTime?: string | null,
        contactPhoneNumber?: string | null,
        contactEmail?: string | null,
        contactName?: string | null,
        providePersonalEmail?: boolean | null,
        providePersonalPhoneNumber?: boolean | null,
        textOnly?: boolean | null,
        followupMeetingRequested?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productQuestionResponsesId?: string | null,
        profileQuestionResponsesId?: string | null,
        meetingQuestionResponsesId?: string | null,
        meetingInviteQuestionResponsesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    privacyEnabled: boolean,
    videoEnabled: boolean,
    transcriptionEnabled: boolean,
    product?:  {
      __typename: "Product",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      serviceInformation?: string | null,
      images?:  {
        __typename: "ModelProductImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questions?:  {
        __typename: "ModelProductQuestionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      salesTeam?:  {
        __typename: "Team",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        labels?: Array< string | null > | null,
        privacyEnabled: boolean,
        privacyEnableOtherTeams?: boolean | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      salesTeamId?: string | null,
      meetings?:  {
        __typename: "ModelMeetingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProductsId?: string | null,
      teamProductsId?: string | null,
    } | null,
    productId?: string | null,
    isReadinessTest: boolean,
    labels?: Array< string | null > | null,
    owner?: string | null,
    teamRelationships?:  {
      __typename: "ModelTeamMeetingRelationshipConnection",
      items:  Array< {
        __typename: "TeamMeetingRelationship",
        id: string,
        teamId: string,
        meetingId: string,
        relationshipType: TeamMeetingRelationshipType,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        teamMeetingRelationshipsId?: string | null,
        meetingTeamRelationshipsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    meetingAttendees?:  {
      __typename: "ModelMeetingAttendeeConnection",
      items:  Array< {
        __typename: "MeetingAttendee",
        id: string,
        profileId: string,
        meetingId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileMeetingAttendeesId?: string | null,
        meetingMeetingAttendeesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    transcriptions?:  {
      __typename: "ModelTranscriptionConnection",
      items:  Array< {
        __typename: "Transcription",
        id: string,
        date: string,
        title: string,
        description?: string | null,
        startDateTime: string,
        endDateTime: string,
        sizeInBytes?: number | null,
        numberOfTranscriptions?: number | null,
        isZipFile: boolean,
        meetingId?: string | null,
        teamId?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        teamTranscriptionsId?: string | null,
        meetingTranscriptionsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    sellerOrganizationName?: string | null,
    sellerTeamName?: string | null,
    buyerTeamName?: string | null,
    sellerOrganizationNameLowerCase?: string | null,
    sellerTeamNameLowerCase?: string | null,
    buyerTeamNameLowerCase?: string | null,
    externalId?: string | null,
    metrics?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    productMeetingsId?: string | null,
    meetingRoomProviderMeetingsId?: string | null,
    meetingRoomId?: string | null,
  } | null,
};

export type OnUpdateMeetingSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateMeetingSubscription = {
  onUpdateMeeting?:  {
    __typename: "Meeting",
    id: string,
    title: string,
    titleLowerCase?: string | null,
    startDateTime: string,
    endDateTime: string,
    status: MeetingStatus,
    description?: string | null,
    preferredRoomProvider?:  {
      __typename: "MeetingRoomProvider",
      id: string,
      name: string,
      componentName?: string | null,
      description?: string | null,
      configuration?: string | null,
      meetings?:  {
        __typename: "ModelMeetingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingRooms?:  {
        __typename: "ModelMeetingRoomConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    preferredRoomProviderId?: string | null,
    actualStartDateTime?: string | null,
    actualEndDateTime?: string | null,
    room?:  {
      __typename: "MeetingRoom",
      id: string,
      name: string,
      meeting:  {
        __typename: "Meeting",
        id: string,
        title: string,
        titleLowerCase?: string | null,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        preferredRoomProviderId?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        sellerOrganizationNameLowerCase?: string | null,
        sellerTeamNameLowerCase?: string | null,
        buyerTeamNameLowerCase?: string | null,
        externalId?: string | null,
        metrics?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingsId?: string | null,
        meetingRoomProviderMeetingsId?: string | null,
        meetingRoomId?: string | null,
      },
      meetingId: string,
      provider:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      providerId: string,
      providerMeetingId?: string | null,
      accessCode: string,
      status: MeetingRoomStatus,
      startDateTime?: string | null,
      endDateTime?: string | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      roomCreatedAt?: string | null,
      roomDestroyedAt?: string | null,
      auditlogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      isReadinessTest: boolean,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      meetingRoomProviderMeetingRoomsId?: string | null,
    } | null,
    questionResponses?:  {
      __typename: "ModelQuestionResponseConnection",
      items:  Array< {
        __typename: "QuestionResponse",
        id: string,
        response?: string | null,
        privateNotes?: string | null,
        questionId: string,
        profileId: string,
        meetingId: string,
        meetingInviteId: string,
        owner?: string | null,
        contactRequestTimeframe?: string | null,
        contactRequestDateTime?: string | null,
        contactPhoneNumber?: string | null,
        contactEmail?: string | null,
        contactName?: string | null,
        providePersonalEmail?: boolean | null,
        providePersonalPhoneNumber?: boolean | null,
        textOnly?: boolean | null,
        followupMeetingRequested?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productQuestionResponsesId?: string | null,
        profileQuestionResponsesId?: string | null,
        meetingQuestionResponsesId?: string | null,
        meetingInviteQuestionResponsesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    privacyEnabled: boolean,
    videoEnabled: boolean,
    transcriptionEnabled: boolean,
    product?:  {
      __typename: "Product",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      serviceInformation?: string | null,
      images?:  {
        __typename: "ModelProductImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questions?:  {
        __typename: "ModelProductQuestionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      salesTeam?:  {
        __typename: "Team",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        labels?: Array< string | null > | null,
        privacyEnabled: boolean,
        privacyEnableOtherTeams?: boolean | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      salesTeamId?: string | null,
      meetings?:  {
        __typename: "ModelMeetingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProductsId?: string | null,
      teamProductsId?: string | null,
    } | null,
    productId?: string | null,
    isReadinessTest: boolean,
    labels?: Array< string | null > | null,
    owner?: string | null,
    teamRelationships?:  {
      __typename: "ModelTeamMeetingRelationshipConnection",
      items:  Array< {
        __typename: "TeamMeetingRelationship",
        id: string,
        teamId: string,
        meetingId: string,
        relationshipType: TeamMeetingRelationshipType,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        teamMeetingRelationshipsId?: string | null,
        meetingTeamRelationshipsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    meetingAttendees?:  {
      __typename: "ModelMeetingAttendeeConnection",
      items:  Array< {
        __typename: "MeetingAttendee",
        id: string,
        profileId: string,
        meetingId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileMeetingAttendeesId?: string | null,
        meetingMeetingAttendeesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    transcriptions?:  {
      __typename: "ModelTranscriptionConnection",
      items:  Array< {
        __typename: "Transcription",
        id: string,
        date: string,
        title: string,
        description?: string | null,
        startDateTime: string,
        endDateTime: string,
        sizeInBytes?: number | null,
        numberOfTranscriptions?: number | null,
        isZipFile: boolean,
        meetingId?: string | null,
        teamId?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        teamTranscriptionsId?: string | null,
        meetingTranscriptionsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    sellerOrganizationName?: string | null,
    sellerTeamName?: string | null,
    buyerTeamName?: string | null,
    sellerOrganizationNameLowerCase?: string | null,
    sellerTeamNameLowerCase?: string | null,
    buyerTeamNameLowerCase?: string | null,
    externalId?: string | null,
    metrics?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    productMeetingsId?: string | null,
    meetingRoomProviderMeetingsId?: string | null,
    meetingRoomId?: string | null,
  } | null,
};

export type OnDeleteMeetingSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteMeetingSubscription = {
  onDeleteMeeting?:  {
    __typename: "Meeting",
    id: string,
    title: string,
    titleLowerCase?: string | null,
    startDateTime: string,
    endDateTime: string,
    status: MeetingStatus,
    description?: string | null,
    preferredRoomProvider?:  {
      __typename: "MeetingRoomProvider",
      id: string,
      name: string,
      componentName?: string | null,
      description?: string | null,
      configuration?: string | null,
      meetings?:  {
        __typename: "ModelMeetingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingRooms?:  {
        __typename: "ModelMeetingRoomConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    preferredRoomProviderId?: string | null,
    actualStartDateTime?: string | null,
    actualEndDateTime?: string | null,
    room?:  {
      __typename: "MeetingRoom",
      id: string,
      name: string,
      meeting:  {
        __typename: "Meeting",
        id: string,
        title: string,
        titleLowerCase?: string | null,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        preferredRoomProviderId?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        sellerOrganizationNameLowerCase?: string | null,
        sellerTeamNameLowerCase?: string | null,
        buyerTeamNameLowerCase?: string | null,
        externalId?: string | null,
        metrics?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingsId?: string | null,
        meetingRoomProviderMeetingsId?: string | null,
        meetingRoomId?: string | null,
      },
      meetingId: string,
      provider:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      providerId: string,
      providerMeetingId?: string | null,
      accessCode: string,
      status: MeetingRoomStatus,
      startDateTime?: string | null,
      endDateTime?: string | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      roomCreatedAt?: string | null,
      roomDestroyedAt?: string | null,
      auditlogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      isReadinessTest: boolean,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      meetingRoomProviderMeetingRoomsId?: string | null,
    } | null,
    questionResponses?:  {
      __typename: "ModelQuestionResponseConnection",
      items:  Array< {
        __typename: "QuestionResponse",
        id: string,
        response?: string | null,
        privateNotes?: string | null,
        questionId: string,
        profileId: string,
        meetingId: string,
        meetingInviteId: string,
        owner?: string | null,
        contactRequestTimeframe?: string | null,
        contactRequestDateTime?: string | null,
        contactPhoneNumber?: string | null,
        contactEmail?: string | null,
        contactName?: string | null,
        providePersonalEmail?: boolean | null,
        providePersonalPhoneNumber?: boolean | null,
        textOnly?: boolean | null,
        followupMeetingRequested?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productQuestionResponsesId?: string | null,
        profileQuestionResponsesId?: string | null,
        meetingQuestionResponsesId?: string | null,
        meetingInviteQuestionResponsesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    privacyEnabled: boolean,
    videoEnabled: boolean,
    transcriptionEnabled: boolean,
    product?:  {
      __typename: "Product",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      serviceInformation?: string | null,
      images?:  {
        __typename: "ModelProductImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questions?:  {
        __typename: "ModelProductQuestionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      salesTeam?:  {
        __typename: "Team",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        labels?: Array< string | null > | null,
        privacyEnabled: boolean,
        privacyEnableOtherTeams?: boolean | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      salesTeamId?: string | null,
      meetings?:  {
        __typename: "ModelMeetingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProductsId?: string | null,
      teamProductsId?: string | null,
    } | null,
    productId?: string | null,
    isReadinessTest: boolean,
    labels?: Array< string | null > | null,
    owner?: string | null,
    teamRelationships?:  {
      __typename: "ModelTeamMeetingRelationshipConnection",
      items:  Array< {
        __typename: "TeamMeetingRelationship",
        id: string,
        teamId: string,
        meetingId: string,
        relationshipType: TeamMeetingRelationshipType,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        teamMeetingRelationshipsId?: string | null,
        meetingTeamRelationshipsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    meetingAttendees?:  {
      __typename: "ModelMeetingAttendeeConnection",
      items:  Array< {
        __typename: "MeetingAttendee",
        id: string,
        profileId: string,
        meetingId: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileMeetingAttendeesId?: string | null,
        meetingMeetingAttendeesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    transcriptions?:  {
      __typename: "ModelTranscriptionConnection",
      items:  Array< {
        __typename: "Transcription",
        id: string,
        date: string,
        title: string,
        description?: string | null,
        startDateTime: string,
        endDateTime: string,
        sizeInBytes?: number | null,
        numberOfTranscriptions?: number | null,
        isZipFile: boolean,
        meetingId?: string | null,
        teamId?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        teamTranscriptionsId?: string | null,
        meetingTranscriptionsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    sellerOrganizationName?: string | null,
    sellerTeamName?: string | null,
    buyerTeamName?: string | null,
    sellerOrganizationNameLowerCase?: string | null,
    sellerTeamNameLowerCase?: string | null,
    buyerTeamNameLowerCase?: string | null,
    externalId?: string | null,
    metrics?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    productMeetingsId?: string | null,
    meetingRoomProviderMeetingsId?: string | null,
    meetingRoomId?: string | null,
  } | null,
};

export type OnCreateMeetingInviteSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateMeetingInviteSubscription = {
  onCreateMeetingInvite?:  {
    __typename: "MeetingInvite",
    id: string,
    meetingId: string,
    provider?:  {
      __typename: "MeetingRoomProvider",
      id: string,
      name: string,
      componentName?: string | null,
      description?: string | null,
      configuration?: string | null,
      meetings?:  {
        __typename: "ModelMeetingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingRooms?:  {
        __typename: "ModelMeetingRoomConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    providerId?: string | null,
    title: string,
    startDateTime: string,
    endDateTime: string,
    status: MeetingStatus,
    description?: string | null,
    questionResponses?:  {
      __typename: "ModelQuestionResponseConnection",
      items:  Array< {
        __typename: "QuestionResponse",
        id: string,
        response?: string | null,
        privateNotes?: string | null,
        questionId: string,
        profileId: string,
        meetingId: string,
        meetingInviteId: string,
        owner?: string | null,
        contactRequestTimeframe?: string | null,
        contactRequestDateTime?: string | null,
        contactPhoneNumber?: string | null,
        contactEmail?: string | null,
        contactName?: string | null,
        providePersonalEmail?: boolean | null,
        providePersonalPhoneNumber?: boolean | null,
        textOnly?: boolean | null,
        followupMeetingRequested?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productQuestionResponsesId?: string | null,
        profileQuestionResponsesId?: string | null,
        meetingQuestionResponsesId?: string | null,
        meetingInviteQuestionResponsesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    privacyEnabled: boolean,
    videoEnabled: boolean,
    transcriptionEnabled: boolean,
    product?:  {
      __typename: "Product",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      serviceInformation?: string | null,
      images?:  {
        __typename: "ModelProductImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questions?:  {
        __typename: "ModelProductQuestionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      salesTeam?:  {
        __typename: "Team",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        labels?: Array< string | null > | null,
        privacyEnabled: boolean,
        privacyEnableOtherTeams?: boolean | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      salesTeamId?: string | null,
      meetings?:  {
        __typename: "ModelMeetingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProductsId?: string | null,
      teamProductsId?: string | null,
    } | null,
    productId?: string | null,
    isReadinessTest: boolean,
    meetingRoomId?: string | null,
    providerSessionId?: string | null,
    providerJoinData?: string | null,
    sessionStatus?: MeetingRoomSessionStatus | null,
    actualStartDateTime?: string | null,
    actualEndDateTime?: string | null,
    profile:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    },
    profileId: string,
    owner?: string | null,
    meetingInvitationType: MeetingInvitationType,
    teamId?: string | null,
    isInterested?: boolean | null,
    feedbackSummary?: string | null,
    contactRequestTimeframe?: string | null,
    contactPhoneNumber?: string | null,
    contactEmail?: string | null,
    sellerOrganizationName?: string | null,
    sellerTeamName?: string | null,
    buyerTeamName?: string | null,
    transcriptionLogs?:  {
      __typename: "ModelTranscriptionEventAuditLogConnection",
      items:  Array< {
        __typename: "TranscriptionEventAuditLog",
        id: string,
        date: string,
        author: string,
        resource: string,
        action: string,
        payload?: string | null,
        ipAddress?: string | null,
        meetingInviteId: string,
        profileId: string,
        authorProfileId?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileTranscriptionLogsId?: string | null,
        meetingInviteTranscriptionLogsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    productMeetingInvitesId?: string | null,
    profileMeetingInvitesId?: string | null,
    meetingRoomProviderMeetingInvitesId?: string | null,
  } | null,
};

export type OnUpdateMeetingInviteSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateMeetingInviteSubscription = {
  onUpdateMeetingInvite?:  {
    __typename: "MeetingInvite",
    id: string,
    meetingId: string,
    provider?:  {
      __typename: "MeetingRoomProvider",
      id: string,
      name: string,
      componentName?: string | null,
      description?: string | null,
      configuration?: string | null,
      meetings?:  {
        __typename: "ModelMeetingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingRooms?:  {
        __typename: "ModelMeetingRoomConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    providerId?: string | null,
    title: string,
    startDateTime: string,
    endDateTime: string,
    status: MeetingStatus,
    description?: string | null,
    questionResponses?:  {
      __typename: "ModelQuestionResponseConnection",
      items:  Array< {
        __typename: "QuestionResponse",
        id: string,
        response?: string | null,
        privateNotes?: string | null,
        questionId: string,
        profileId: string,
        meetingId: string,
        meetingInviteId: string,
        owner?: string | null,
        contactRequestTimeframe?: string | null,
        contactRequestDateTime?: string | null,
        contactPhoneNumber?: string | null,
        contactEmail?: string | null,
        contactName?: string | null,
        providePersonalEmail?: boolean | null,
        providePersonalPhoneNumber?: boolean | null,
        textOnly?: boolean | null,
        followupMeetingRequested?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productQuestionResponsesId?: string | null,
        profileQuestionResponsesId?: string | null,
        meetingQuestionResponsesId?: string | null,
        meetingInviteQuestionResponsesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    privacyEnabled: boolean,
    videoEnabled: boolean,
    transcriptionEnabled: boolean,
    product?:  {
      __typename: "Product",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      serviceInformation?: string | null,
      images?:  {
        __typename: "ModelProductImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questions?:  {
        __typename: "ModelProductQuestionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      salesTeam?:  {
        __typename: "Team",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        labels?: Array< string | null > | null,
        privacyEnabled: boolean,
        privacyEnableOtherTeams?: boolean | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      salesTeamId?: string | null,
      meetings?:  {
        __typename: "ModelMeetingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProductsId?: string | null,
      teamProductsId?: string | null,
    } | null,
    productId?: string | null,
    isReadinessTest: boolean,
    meetingRoomId?: string | null,
    providerSessionId?: string | null,
    providerJoinData?: string | null,
    sessionStatus?: MeetingRoomSessionStatus | null,
    actualStartDateTime?: string | null,
    actualEndDateTime?: string | null,
    profile:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    },
    profileId: string,
    owner?: string | null,
    meetingInvitationType: MeetingInvitationType,
    teamId?: string | null,
    isInterested?: boolean | null,
    feedbackSummary?: string | null,
    contactRequestTimeframe?: string | null,
    contactPhoneNumber?: string | null,
    contactEmail?: string | null,
    sellerOrganizationName?: string | null,
    sellerTeamName?: string | null,
    buyerTeamName?: string | null,
    transcriptionLogs?:  {
      __typename: "ModelTranscriptionEventAuditLogConnection",
      items:  Array< {
        __typename: "TranscriptionEventAuditLog",
        id: string,
        date: string,
        author: string,
        resource: string,
        action: string,
        payload?: string | null,
        ipAddress?: string | null,
        meetingInviteId: string,
        profileId: string,
        authorProfileId?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileTranscriptionLogsId?: string | null,
        meetingInviteTranscriptionLogsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    productMeetingInvitesId?: string | null,
    profileMeetingInvitesId?: string | null,
    meetingRoomProviderMeetingInvitesId?: string | null,
  } | null,
};

export type OnDeleteMeetingInviteSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteMeetingInviteSubscription = {
  onDeleteMeetingInvite?:  {
    __typename: "MeetingInvite",
    id: string,
    meetingId: string,
    provider?:  {
      __typename: "MeetingRoomProvider",
      id: string,
      name: string,
      componentName?: string | null,
      description?: string | null,
      configuration?: string | null,
      meetings?:  {
        __typename: "ModelMeetingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingRooms?:  {
        __typename: "ModelMeetingRoomConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    providerId?: string | null,
    title: string,
    startDateTime: string,
    endDateTime: string,
    status: MeetingStatus,
    description?: string | null,
    questionResponses?:  {
      __typename: "ModelQuestionResponseConnection",
      items:  Array< {
        __typename: "QuestionResponse",
        id: string,
        response?: string | null,
        privateNotes?: string | null,
        questionId: string,
        profileId: string,
        meetingId: string,
        meetingInviteId: string,
        owner?: string | null,
        contactRequestTimeframe?: string | null,
        contactRequestDateTime?: string | null,
        contactPhoneNumber?: string | null,
        contactEmail?: string | null,
        contactName?: string | null,
        providePersonalEmail?: boolean | null,
        providePersonalPhoneNumber?: boolean | null,
        textOnly?: boolean | null,
        followupMeetingRequested?: boolean | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productQuestionResponsesId?: string | null,
        profileQuestionResponsesId?: string | null,
        meetingQuestionResponsesId?: string | null,
        meetingInviteQuestionResponsesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    privacyEnabled: boolean,
    videoEnabled: boolean,
    transcriptionEnabled: boolean,
    product?:  {
      __typename: "Product",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      serviceInformation?: string | null,
      images?:  {
        __typename: "ModelProductImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questions?:  {
        __typename: "ModelProductQuestionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      salesTeam?:  {
        __typename: "Team",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        labels?: Array< string | null > | null,
        privacyEnabled: boolean,
        privacyEnableOtherTeams?: boolean | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      salesTeamId?: string | null,
      meetings?:  {
        __typename: "ModelMeetingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProductsId?: string | null,
      teamProductsId?: string | null,
    } | null,
    productId?: string | null,
    isReadinessTest: boolean,
    meetingRoomId?: string | null,
    providerSessionId?: string | null,
    providerJoinData?: string | null,
    sessionStatus?: MeetingRoomSessionStatus | null,
    actualStartDateTime?: string | null,
    actualEndDateTime?: string | null,
    profile:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    },
    profileId: string,
    owner?: string | null,
    meetingInvitationType: MeetingInvitationType,
    teamId?: string | null,
    isInterested?: boolean | null,
    feedbackSummary?: string | null,
    contactRequestTimeframe?: string | null,
    contactPhoneNumber?: string | null,
    contactEmail?: string | null,
    sellerOrganizationName?: string | null,
    sellerTeamName?: string | null,
    buyerTeamName?: string | null,
    transcriptionLogs?:  {
      __typename: "ModelTranscriptionEventAuditLogConnection",
      items:  Array< {
        __typename: "TranscriptionEventAuditLog",
        id: string,
        date: string,
        author: string,
        resource: string,
        action: string,
        payload?: string | null,
        ipAddress?: string | null,
        meetingInviteId: string,
        profileId: string,
        authorProfileId?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileTranscriptionLogsId?: string | null,
        meetingInviteTranscriptionLogsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    productMeetingInvitesId?: string | null,
    profileMeetingInvitesId?: string | null,
    meetingRoomProviderMeetingInvitesId?: string | null,
  } | null,
};

export type OnCreateMeetingRoomSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateMeetingRoomSubscription = {
  onCreateMeetingRoom?:  {
    __typename: "MeetingRoom",
    id: string,
    name: string,
    meeting:  {
      __typename: "Meeting",
      id: string,
      title: string,
      titleLowerCase?: string | null,
      startDateTime: string,
      endDateTime: string,
      status: MeetingStatus,
      description?: string | null,
      preferredRoomProvider?:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      preferredRoomProviderId?: string | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      room?:  {
        __typename: "MeetingRoom",
        id: string,
        name: string,
        meetingId: string,
        providerId: string,
        providerMeetingId?: string | null,
        accessCode: string,
        status: MeetingRoomStatus,
        startDateTime?: string | null,
        endDateTime?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        roomCreatedAt?: string | null,
        roomDestroyedAt?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        isReadinessTest: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        meetingRoomProviderMeetingRoomsId?: string | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null,
      productId?: string | null,
      isReadinessTest: boolean,
      labels?: Array< string | null > | null,
      owner?: string | null,
      teamRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerOrganizationName?: string | null,
      sellerTeamName?: string | null,
      buyerTeamName?: string | null,
      sellerOrganizationNameLowerCase?: string | null,
      sellerTeamNameLowerCase?: string | null,
      buyerTeamNameLowerCase?: string | null,
      externalId?: string | null,
      metrics?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productMeetingsId?: string | null,
      meetingRoomProviderMeetingsId?: string | null,
      meetingRoomId?: string | null,
    },
    meetingId: string,
    provider:  {
      __typename: "MeetingRoomProvider",
      id: string,
      name: string,
      componentName?: string | null,
      description?: string | null,
      configuration?: string | null,
      meetings?:  {
        __typename: "ModelMeetingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingRooms?:  {
        __typename: "ModelMeetingRoomConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    providerId: string,
    providerMeetingId?: string | null,
    accessCode: string,
    status: MeetingRoomStatus,
    startDateTime?: string | null,
    endDateTime?: string | null,
    actualStartDateTime?: string | null,
    actualEndDateTime?: string | null,
    roomCreatedAt?: string | null,
    roomDestroyedAt?: string | null,
    auditlogs?:  {
      __typename: "ModelMeetingRoomEventAuditLogConnection",
      items:  Array< {
        __typename: "MeetingRoomEventAuditLog",
        id: string,
        date: string,
        author: string,
        resource: string,
        action: string,
        payload?: string | null,
        ipAddress?: string | null,
        meetingRoomId: string,
        profileId?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileAuditLogsId?: string | null,
        meetingRoomAuditlogsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    privacyEnabled: boolean,
    videoEnabled: boolean,
    transcriptionEnabled: boolean,
    isReadinessTest: boolean,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    meetingRoomProviderMeetingRoomsId?: string | null,
  } | null,
};

export type OnUpdateMeetingRoomSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateMeetingRoomSubscription = {
  onUpdateMeetingRoom?:  {
    __typename: "MeetingRoom",
    id: string,
    name: string,
    meeting:  {
      __typename: "Meeting",
      id: string,
      title: string,
      titleLowerCase?: string | null,
      startDateTime: string,
      endDateTime: string,
      status: MeetingStatus,
      description?: string | null,
      preferredRoomProvider?:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      preferredRoomProviderId?: string | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      room?:  {
        __typename: "MeetingRoom",
        id: string,
        name: string,
        meetingId: string,
        providerId: string,
        providerMeetingId?: string | null,
        accessCode: string,
        status: MeetingRoomStatus,
        startDateTime?: string | null,
        endDateTime?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        roomCreatedAt?: string | null,
        roomDestroyedAt?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        isReadinessTest: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        meetingRoomProviderMeetingRoomsId?: string | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null,
      productId?: string | null,
      isReadinessTest: boolean,
      labels?: Array< string | null > | null,
      owner?: string | null,
      teamRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerOrganizationName?: string | null,
      sellerTeamName?: string | null,
      buyerTeamName?: string | null,
      sellerOrganizationNameLowerCase?: string | null,
      sellerTeamNameLowerCase?: string | null,
      buyerTeamNameLowerCase?: string | null,
      externalId?: string | null,
      metrics?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productMeetingsId?: string | null,
      meetingRoomProviderMeetingsId?: string | null,
      meetingRoomId?: string | null,
    },
    meetingId: string,
    provider:  {
      __typename: "MeetingRoomProvider",
      id: string,
      name: string,
      componentName?: string | null,
      description?: string | null,
      configuration?: string | null,
      meetings?:  {
        __typename: "ModelMeetingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingRooms?:  {
        __typename: "ModelMeetingRoomConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    providerId: string,
    providerMeetingId?: string | null,
    accessCode: string,
    status: MeetingRoomStatus,
    startDateTime?: string | null,
    endDateTime?: string | null,
    actualStartDateTime?: string | null,
    actualEndDateTime?: string | null,
    roomCreatedAt?: string | null,
    roomDestroyedAt?: string | null,
    auditlogs?:  {
      __typename: "ModelMeetingRoomEventAuditLogConnection",
      items:  Array< {
        __typename: "MeetingRoomEventAuditLog",
        id: string,
        date: string,
        author: string,
        resource: string,
        action: string,
        payload?: string | null,
        ipAddress?: string | null,
        meetingRoomId: string,
        profileId?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileAuditLogsId?: string | null,
        meetingRoomAuditlogsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    privacyEnabled: boolean,
    videoEnabled: boolean,
    transcriptionEnabled: boolean,
    isReadinessTest: boolean,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    meetingRoomProviderMeetingRoomsId?: string | null,
  } | null,
};

export type OnDeleteMeetingRoomSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteMeetingRoomSubscription = {
  onDeleteMeetingRoom?:  {
    __typename: "MeetingRoom",
    id: string,
    name: string,
    meeting:  {
      __typename: "Meeting",
      id: string,
      title: string,
      titleLowerCase?: string | null,
      startDateTime: string,
      endDateTime: string,
      status: MeetingStatus,
      description?: string | null,
      preferredRoomProvider?:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      preferredRoomProviderId?: string | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      room?:  {
        __typename: "MeetingRoom",
        id: string,
        name: string,
        meetingId: string,
        providerId: string,
        providerMeetingId?: string | null,
        accessCode: string,
        status: MeetingRoomStatus,
        startDateTime?: string | null,
        endDateTime?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        roomCreatedAt?: string | null,
        roomDestroyedAt?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        isReadinessTest: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        meetingRoomProviderMeetingRoomsId?: string | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null,
      productId?: string | null,
      isReadinessTest: boolean,
      labels?: Array< string | null > | null,
      owner?: string | null,
      teamRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerOrganizationName?: string | null,
      sellerTeamName?: string | null,
      buyerTeamName?: string | null,
      sellerOrganizationNameLowerCase?: string | null,
      sellerTeamNameLowerCase?: string | null,
      buyerTeamNameLowerCase?: string | null,
      externalId?: string | null,
      metrics?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productMeetingsId?: string | null,
      meetingRoomProviderMeetingsId?: string | null,
      meetingRoomId?: string | null,
    },
    meetingId: string,
    provider:  {
      __typename: "MeetingRoomProvider",
      id: string,
      name: string,
      componentName?: string | null,
      description?: string | null,
      configuration?: string | null,
      meetings?:  {
        __typename: "ModelMeetingConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingRooms?:  {
        __typename: "ModelMeetingRoomConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    providerId: string,
    providerMeetingId?: string | null,
    accessCode: string,
    status: MeetingRoomStatus,
    startDateTime?: string | null,
    endDateTime?: string | null,
    actualStartDateTime?: string | null,
    actualEndDateTime?: string | null,
    roomCreatedAt?: string | null,
    roomDestroyedAt?: string | null,
    auditlogs?:  {
      __typename: "ModelMeetingRoomEventAuditLogConnection",
      items:  Array< {
        __typename: "MeetingRoomEventAuditLog",
        id: string,
        date: string,
        author: string,
        resource: string,
        action: string,
        payload?: string | null,
        ipAddress?: string | null,
        meetingRoomId: string,
        profileId?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileAuditLogsId?: string | null,
        meetingRoomAuditlogsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    privacyEnabled: boolean,
    videoEnabled: boolean,
    transcriptionEnabled: boolean,
    isReadinessTest: boolean,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    meetingRoomProviderMeetingRoomsId?: string | null,
  } | null,
};

export type OnCreateMeetingRoomEventAuditLogSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateMeetingRoomEventAuditLogSubscription = {
  onCreateMeetingRoomEventAuditLog?:  {
    __typename: "MeetingRoomEventAuditLog",
    id: string,
    date: string,
    author: string,
    resource: string,
    action: string,
    payload?: string | null,
    ipAddress?: string | null,
    meetingRoom:  {
      __typename: "MeetingRoom",
      id: string,
      name: string,
      meeting:  {
        __typename: "Meeting",
        id: string,
        title: string,
        titleLowerCase?: string | null,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        preferredRoomProviderId?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        sellerOrganizationNameLowerCase?: string | null,
        sellerTeamNameLowerCase?: string | null,
        buyerTeamNameLowerCase?: string | null,
        externalId?: string | null,
        metrics?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingsId?: string | null,
        meetingRoomProviderMeetingsId?: string | null,
        meetingRoomId?: string | null,
      },
      meetingId: string,
      provider:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      providerId: string,
      providerMeetingId?: string | null,
      accessCode: string,
      status: MeetingRoomStatus,
      startDateTime?: string | null,
      endDateTime?: string | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      roomCreatedAt?: string | null,
      roomDestroyedAt?: string | null,
      auditlogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      isReadinessTest: boolean,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      meetingRoomProviderMeetingRoomsId?: string | null,
    },
    meetingRoomId: string,
    profile?:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    } | null,
    profileId?: string | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileAuditLogsId?: string | null,
    meetingRoomAuditlogsId?: string | null,
  } | null,
};

export type OnUpdateMeetingRoomEventAuditLogSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateMeetingRoomEventAuditLogSubscription = {
  onUpdateMeetingRoomEventAuditLog?:  {
    __typename: "MeetingRoomEventAuditLog",
    id: string,
    date: string,
    author: string,
    resource: string,
    action: string,
    payload?: string | null,
    ipAddress?: string | null,
    meetingRoom:  {
      __typename: "MeetingRoom",
      id: string,
      name: string,
      meeting:  {
        __typename: "Meeting",
        id: string,
        title: string,
        titleLowerCase?: string | null,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        preferredRoomProviderId?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        sellerOrganizationNameLowerCase?: string | null,
        sellerTeamNameLowerCase?: string | null,
        buyerTeamNameLowerCase?: string | null,
        externalId?: string | null,
        metrics?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingsId?: string | null,
        meetingRoomProviderMeetingsId?: string | null,
        meetingRoomId?: string | null,
      },
      meetingId: string,
      provider:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      providerId: string,
      providerMeetingId?: string | null,
      accessCode: string,
      status: MeetingRoomStatus,
      startDateTime?: string | null,
      endDateTime?: string | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      roomCreatedAt?: string | null,
      roomDestroyedAt?: string | null,
      auditlogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      isReadinessTest: boolean,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      meetingRoomProviderMeetingRoomsId?: string | null,
    },
    meetingRoomId: string,
    profile?:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    } | null,
    profileId?: string | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileAuditLogsId?: string | null,
    meetingRoomAuditlogsId?: string | null,
  } | null,
};

export type OnDeleteMeetingRoomEventAuditLogSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteMeetingRoomEventAuditLogSubscription = {
  onDeleteMeetingRoomEventAuditLog?:  {
    __typename: "MeetingRoomEventAuditLog",
    id: string,
    date: string,
    author: string,
    resource: string,
    action: string,
    payload?: string | null,
    ipAddress?: string | null,
    meetingRoom:  {
      __typename: "MeetingRoom",
      id: string,
      name: string,
      meeting:  {
        __typename: "Meeting",
        id: string,
        title: string,
        titleLowerCase?: string | null,
        startDateTime: string,
        endDateTime: string,
        status: MeetingStatus,
        description?: string | null,
        preferredRoomProviderId?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        productId?: string | null,
        isReadinessTest: boolean,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sellerOrganizationName?: string | null,
        sellerTeamName?: string | null,
        buyerTeamName?: string | null,
        sellerOrganizationNameLowerCase?: string | null,
        sellerTeamNameLowerCase?: string | null,
        buyerTeamNameLowerCase?: string | null,
        externalId?: string | null,
        metrics?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        productMeetingsId?: string | null,
        meetingRoomProviderMeetingsId?: string | null,
        meetingRoomId?: string | null,
      },
      meetingId: string,
      provider:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      providerId: string,
      providerMeetingId?: string | null,
      accessCode: string,
      status: MeetingRoomStatus,
      startDateTime?: string | null,
      endDateTime?: string | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      roomCreatedAt?: string | null,
      roomDestroyedAt?: string | null,
      auditlogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      isReadinessTest: boolean,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      meetingRoomProviderMeetingRoomsId?: string | null,
    },
    meetingRoomId: string,
    profile?:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    } | null,
    profileId?: string | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileAuditLogsId?: string | null,
    meetingRoomAuditlogsId?: string | null,
  } | null,
};

export type OnCreateExportJobSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateExportJobSubscription = {
  onCreateExportJob?:  {
    __typename: "ExportJob",
    id: string,
    status: JobStatus,
    profile?:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    } | null,
    profileId?: string | null,
    auditLogs?:  {
      __typename: "ModelExportJobAuditLogConnection",
      items:  Array< {
        __typename: "ExportJobAuditLog",
        id: string,
        date: string,
        author: string,
        resource: string,
        action: string,
        payload?: string | null,
        ipAddress?: string | null,
        exportJobId: string,
        profileId?: string | null,
        owner: string,
        identityId?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileExportJobAuditLogsId?: string | null,
        exportJobAuditLogsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    meetingInvitationType?: MeetingInvitationType | null,
    startDateTime: string,
    endDateTime: string,
    owner: string,
    identityId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileExportJobsId?: string | null,
  } | null,
};

export type OnUpdateExportJobSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateExportJobSubscription = {
  onUpdateExportJob?:  {
    __typename: "ExportJob",
    id: string,
    status: JobStatus,
    profile?:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    } | null,
    profileId?: string | null,
    auditLogs?:  {
      __typename: "ModelExportJobAuditLogConnection",
      items:  Array< {
        __typename: "ExportJobAuditLog",
        id: string,
        date: string,
        author: string,
        resource: string,
        action: string,
        payload?: string | null,
        ipAddress?: string | null,
        exportJobId: string,
        profileId?: string | null,
        owner: string,
        identityId?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileExportJobAuditLogsId?: string | null,
        exportJobAuditLogsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    meetingInvitationType?: MeetingInvitationType | null,
    startDateTime: string,
    endDateTime: string,
    owner: string,
    identityId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileExportJobsId?: string | null,
  } | null,
};

export type OnDeleteExportJobSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteExportJobSubscription = {
  onDeleteExportJob?:  {
    __typename: "ExportJob",
    id: string,
    status: JobStatus,
    profile?:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    } | null,
    profileId?: string | null,
    auditLogs?:  {
      __typename: "ModelExportJobAuditLogConnection",
      items:  Array< {
        __typename: "ExportJobAuditLog",
        id: string,
        date: string,
        author: string,
        resource: string,
        action: string,
        payload?: string | null,
        ipAddress?: string | null,
        exportJobId: string,
        profileId?: string | null,
        owner: string,
        identityId?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileExportJobAuditLogsId?: string | null,
        exportJobAuditLogsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    meetingInvitationType?: MeetingInvitationType | null,
    startDateTime: string,
    endDateTime: string,
    owner: string,
    identityId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileExportJobsId?: string | null,
  } | null,
};

export type OnCreateExportJobAuditLogSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateExportJobAuditLogSubscription = {
  onCreateExportJobAuditLog?:  {
    __typename: "ExportJobAuditLog",
    id: string,
    date: string,
    author: string,
    resource: string,
    action: string,
    payload?: string | null,
    ipAddress?: string | null,
    exportJob:  {
      __typename: "ExportJob",
      id: string,
      status: JobStatus,
      profile?:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      } | null,
      profileId?: string | null,
      auditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingInvitationType?: MeetingInvitationType | null,
      startDateTime: string,
      endDateTime: string,
      owner: string,
      identityId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      profileExportJobsId?: string | null,
    },
    exportJobId: string,
    profile?:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    } | null,
    profileId?: string | null,
    owner: string,
    source?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    identityId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileExportJobAuditLogsId?: string | null,
    exportJobAuditLogsId?: string | null,
  } | null,
};

export type OnUpdateExportJobAuditLogSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateExportJobAuditLogSubscription = {
  onUpdateExportJobAuditLog?:  {
    __typename: "ExportJobAuditLog",
    id: string,
    date: string,
    author: string,
    resource: string,
    action: string,
    payload?: string | null,
    ipAddress?: string | null,
    exportJob:  {
      __typename: "ExportJob",
      id: string,
      status: JobStatus,
      profile?:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      } | null,
      profileId?: string | null,
      auditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingInvitationType?: MeetingInvitationType | null,
      startDateTime: string,
      endDateTime: string,
      owner: string,
      identityId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      profileExportJobsId?: string | null,
    },
    exportJobId: string,
    profile?:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    } | null,
    profileId?: string | null,
    owner: string,
    source?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    identityId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileExportJobAuditLogsId?: string | null,
    exportJobAuditLogsId?: string | null,
  } | null,
};

export type OnDeleteExportJobAuditLogSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteExportJobAuditLogSubscription = {
  onDeleteExportJobAuditLog?:  {
    __typename: "ExportJobAuditLog",
    id: string,
    date: string,
    author: string,
    resource: string,
    action: string,
    payload?: string | null,
    ipAddress?: string | null,
    exportJob:  {
      __typename: "ExportJob",
      id: string,
      status: JobStatus,
      profile?:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      } | null,
      profileId?: string | null,
      auditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingInvitationType?: MeetingInvitationType | null,
      startDateTime: string,
      endDateTime: string,
      owner: string,
      identityId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      profileExportJobsId?: string | null,
    },
    exportJobId: string,
    profile?:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    } | null,
    profileId?: string | null,
    owner: string,
    source?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    identityId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileExportJobAuditLogsId?: string | null,
    exportJobAuditLogsId?: string | null,
  } | null,
};

export type OnCreateImportJobSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateImportJobSubscription = {
  onCreateImportJob?:  {
    __typename: "ImportJob",
    id: string,
    status: JobStatus,
    source:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    },
    identityId?: string | null,
    result?: string | null,
    profile?:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    } | null,
    profileId?: string | null,
    auditLogs?:  {
      __typename: "ModelImportJobAuditLogConnection",
      items:  Array< {
        __typename: "ImportJobAuditLog",
        id: string,
        date: string,
        author: string,
        resource: string,
        action: string,
        payload?: string | null,
        ipAddress?: string | null,
        importJobId: string,
        profileId?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileJobAuditLogsId?: string | null,
        importJobAuditLogsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    lastStartDateTime?: string | null,
    lastEndDateTime?: string | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileImportJobsId?: string | null,
  } | null,
};

export type OnUpdateImportJobSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateImportJobSubscription = {
  onUpdateImportJob?:  {
    __typename: "ImportJob",
    id: string,
    status: JobStatus,
    source:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    },
    identityId?: string | null,
    result?: string | null,
    profile?:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    } | null,
    profileId?: string | null,
    auditLogs?:  {
      __typename: "ModelImportJobAuditLogConnection",
      items:  Array< {
        __typename: "ImportJobAuditLog",
        id: string,
        date: string,
        author: string,
        resource: string,
        action: string,
        payload?: string | null,
        ipAddress?: string | null,
        importJobId: string,
        profileId?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileJobAuditLogsId?: string | null,
        importJobAuditLogsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    lastStartDateTime?: string | null,
    lastEndDateTime?: string | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileImportJobsId?: string | null,
  } | null,
};

export type OnDeleteImportJobSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteImportJobSubscription = {
  onDeleteImportJob?:  {
    __typename: "ImportJob",
    id: string,
    status: JobStatus,
    source:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    },
    identityId?: string | null,
    result?: string | null,
    profile?:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    } | null,
    profileId?: string | null,
    auditLogs?:  {
      __typename: "ModelImportJobAuditLogConnection",
      items:  Array< {
        __typename: "ImportJobAuditLog",
        id: string,
        date: string,
        author: string,
        resource: string,
        action: string,
        payload?: string | null,
        ipAddress?: string | null,
        importJobId: string,
        profileId?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        profileJobAuditLogsId?: string | null,
        importJobAuditLogsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    lastStartDateTime?: string | null,
    lastEndDateTime?: string | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileImportJobsId?: string | null,
  } | null,
};

export type OnCreateImportJobAuditLogSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateImportJobAuditLogSubscription = {
  onCreateImportJobAuditLog?:  {
    __typename: "ImportJobAuditLog",
    id: string,
    date: string,
    author: string,
    resource: string,
    action: string,
    payload?: string | null,
    ipAddress?: string | null,
    importJob:  {
      __typename: "ImportJob",
      id: string,
      status: JobStatus,
      source:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      identityId?: string | null,
      result?: string | null,
      profile?:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      } | null,
      profileId?: string | null,
      auditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      lastStartDateTime?: string | null,
      lastEndDateTime?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      profileImportJobsId?: string | null,
    },
    importJobId: string,
    profile?:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    } | null,
    profileId?: string | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileJobAuditLogsId?: string | null,
    importJobAuditLogsId?: string | null,
  } | null,
};

export type OnUpdateImportJobAuditLogSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateImportJobAuditLogSubscription = {
  onUpdateImportJobAuditLog?:  {
    __typename: "ImportJobAuditLog",
    id: string,
    date: string,
    author: string,
    resource: string,
    action: string,
    payload?: string | null,
    ipAddress?: string | null,
    importJob:  {
      __typename: "ImportJob",
      id: string,
      status: JobStatus,
      source:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      identityId?: string | null,
      result?: string | null,
      profile?:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      } | null,
      profileId?: string | null,
      auditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      lastStartDateTime?: string | null,
      lastEndDateTime?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      profileImportJobsId?: string | null,
    },
    importJobId: string,
    profile?:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    } | null,
    profileId?: string | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileJobAuditLogsId?: string | null,
    importJobAuditLogsId?: string | null,
  } | null,
};

export type OnDeleteImportJobAuditLogSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteImportJobAuditLogSubscription = {
  onDeleteImportJobAuditLog?:  {
    __typename: "ImportJobAuditLog",
    id: string,
    date: string,
    author: string,
    resource: string,
    action: string,
    payload?: string | null,
    ipAddress?: string | null,
    importJob:  {
      __typename: "ImportJob",
      id: string,
      status: JobStatus,
      source:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      },
      identityId?: string | null,
      result?: string | null,
      profile?:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      } | null,
      profileId?: string | null,
      auditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      lastStartDateTime?: string | null,
      lastEndDateTime?: string | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      profileImportJobsId?: string | null,
    },
    importJobId: string,
    profile?:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    } | null,
    profileId?: string | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileJobAuditLogsId?: string | null,
    importJobAuditLogsId?: string | null,
  } | null,
};

export type OnCreateNotificationSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateNotificationSubscription = {
  onCreateNotification?:  {
    __typename: "Notification",
    id: string,
    date: string,
    title: string,
    payload: string,
    read: number,
    dateRead?: string | null,
    owner: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateNotificationSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateNotificationSubscription = {
  onUpdateNotification?:  {
    __typename: "Notification",
    id: string,
    date: string,
    title: string,
    payload: string,
    read: number,
    dateRead?: string | null,
    owner: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteNotificationSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteNotificationSubscription = {
  onDeleteNotification?:  {
    __typename: "Notification",
    id: string,
    date: string,
    title: string,
    payload: string,
    read: number,
    dateRead?: string | null,
    owner: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateTranscriptionSubscription = {
  onCreateTranscription?:  {
    __typename: "Transcription",
    id: string,
    date: string,
    title: string,
    description?: string | null,
    startDateTime: string,
    endDateTime: string,
    result:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    },
    sizeInBytes?: number | null,
    numberOfTranscriptions?: number | null,
    isZipFile: boolean,
    meeting?:  {
      __typename: "Meeting",
      id: string,
      title: string,
      titleLowerCase?: string | null,
      startDateTime: string,
      endDateTime: string,
      status: MeetingStatus,
      description?: string | null,
      preferredRoomProvider?:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      preferredRoomProviderId?: string | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      room?:  {
        __typename: "MeetingRoom",
        id: string,
        name: string,
        meetingId: string,
        providerId: string,
        providerMeetingId?: string | null,
        accessCode: string,
        status: MeetingRoomStatus,
        startDateTime?: string | null,
        endDateTime?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        roomCreatedAt?: string | null,
        roomDestroyedAt?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        isReadinessTest: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        meetingRoomProviderMeetingRoomsId?: string | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null,
      productId?: string | null,
      isReadinessTest: boolean,
      labels?: Array< string | null > | null,
      owner?: string | null,
      teamRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerOrganizationName?: string | null,
      sellerTeamName?: string | null,
      buyerTeamName?: string | null,
      sellerOrganizationNameLowerCase?: string | null,
      sellerTeamNameLowerCase?: string | null,
      buyerTeamNameLowerCase?: string | null,
      externalId?: string | null,
      metrics?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productMeetingsId?: string | null,
      meetingRoomProviderMeetingsId?: string | null,
      meetingRoomId?: string | null,
    } | null,
    meetingId?: string | null,
    team?:  {
      __typename: "Team",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      memberRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      privacyEnabled: boolean,
      privacyEnableOtherTeams?: boolean | null,
      images?:  {
        __typename: "ModelTeamImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    teamId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    teamTranscriptionsId?: string | null,
    meetingTranscriptionsId?: string | null,
  } | null,
};

export type OnUpdateTranscriptionSubscription = {
  onUpdateTranscription?:  {
    __typename: "Transcription",
    id: string,
    date: string,
    title: string,
    description?: string | null,
    startDateTime: string,
    endDateTime: string,
    result:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    },
    sizeInBytes?: number | null,
    numberOfTranscriptions?: number | null,
    isZipFile: boolean,
    meeting?:  {
      __typename: "Meeting",
      id: string,
      title: string,
      titleLowerCase?: string | null,
      startDateTime: string,
      endDateTime: string,
      status: MeetingStatus,
      description?: string | null,
      preferredRoomProvider?:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      preferredRoomProviderId?: string | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      room?:  {
        __typename: "MeetingRoom",
        id: string,
        name: string,
        meetingId: string,
        providerId: string,
        providerMeetingId?: string | null,
        accessCode: string,
        status: MeetingRoomStatus,
        startDateTime?: string | null,
        endDateTime?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        roomCreatedAt?: string | null,
        roomDestroyedAt?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        isReadinessTest: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        meetingRoomProviderMeetingRoomsId?: string | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null,
      productId?: string | null,
      isReadinessTest: boolean,
      labels?: Array< string | null > | null,
      owner?: string | null,
      teamRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerOrganizationName?: string | null,
      sellerTeamName?: string | null,
      buyerTeamName?: string | null,
      sellerOrganizationNameLowerCase?: string | null,
      sellerTeamNameLowerCase?: string | null,
      buyerTeamNameLowerCase?: string | null,
      externalId?: string | null,
      metrics?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productMeetingsId?: string | null,
      meetingRoomProviderMeetingsId?: string | null,
      meetingRoomId?: string | null,
    } | null,
    meetingId?: string | null,
    team?:  {
      __typename: "Team",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      memberRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      privacyEnabled: boolean,
      privacyEnableOtherTeams?: boolean | null,
      images?:  {
        __typename: "ModelTeamImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    teamId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    teamTranscriptionsId?: string | null,
    meetingTranscriptionsId?: string | null,
  } | null,
};

export type OnDeleteTranscriptionSubscription = {
  onDeleteTranscription?:  {
    __typename: "Transcription",
    id: string,
    date: string,
    title: string,
    description?: string | null,
    startDateTime: string,
    endDateTime: string,
    result:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    },
    sizeInBytes?: number | null,
    numberOfTranscriptions?: number | null,
    isZipFile: boolean,
    meeting?:  {
      __typename: "Meeting",
      id: string,
      title: string,
      titleLowerCase?: string | null,
      startDateTime: string,
      endDateTime: string,
      status: MeetingStatus,
      description?: string | null,
      preferredRoomProvider?:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      preferredRoomProviderId?: string | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      room?:  {
        __typename: "MeetingRoom",
        id: string,
        name: string,
        meetingId: string,
        providerId: string,
        providerMeetingId?: string | null,
        accessCode: string,
        status: MeetingRoomStatus,
        startDateTime?: string | null,
        endDateTime?: string | null,
        actualStartDateTime?: string | null,
        actualEndDateTime?: string | null,
        roomCreatedAt?: string | null,
        roomDestroyedAt?: string | null,
        privacyEnabled: boolean,
        videoEnabled: boolean,
        transcriptionEnabled: boolean,
        isReadinessTest: boolean,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        meetingRoomProviderMeetingRoomsId?: string | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null,
      productId?: string | null,
      isReadinessTest: boolean,
      labels?: Array< string | null > | null,
      owner?: string | null,
      teamRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerOrganizationName?: string | null,
      sellerTeamName?: string | null,
      buyerTeamName?: string | null,
      sellerOrganizationNameLowerCase?: string | null,
      sellerTeamNameLowerCase?: string | null,
      buyerTeamNameLowerCase?: string | null,
      externalId?: string | null,
      metrics?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productMeetingsId?: string | null,
      meetingRoomProviderMeetingsId?: string | null,
      meetingRoomId?: string | null,
    } | null,
    meetingId?: string | null,
    team?:  {
      __typename: "Team",
      id: string,
      name: string,
      nameLowerCase?: string | null,
      description?: string | null,
      memberRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingRelationships?:  {
        __typename: "ModelTeamMeetingRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      products?:  {
        __typename: "ModelProductConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      labels?: Array< string | null > | null,
      privacyEnabled: boolean,
      privacyEnableOtherTeams?: boolean | null,
      images?:  {
        __typename: "ModelTeamImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      transcriptions?:  {
        __typename: "ModelTranscriptionConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner?: string | null,
      sortByName?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    teamId?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    teamTranscriptionsId?: string | null,
    meetingTranscriptionsId?: string | null,
  } | null,
};

export type OnCreateTranscriptionJobSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateTranscriptionJobSubscription = {
  onCreateTranscriptionJob?:  {
    __typename: "TranscriptionJob",
    id: string,
    date: string,
    status: JobStatus,
    result?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    meetingInviteId: string,
    auditLogs?:  {
      __typename: "ModelTranscriptionJobAuditLogConnection",
      items:  Array< {
        __typename: "TranscriptionJobAuditLog",
        id: string,
        date: string,
        author: string,
        resource: string,
        action: string,
        payload?: string | null,
        ipAddress?: string | null,
        transcriptionJobId: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        transcriptionJobAuditLogsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    owner: string,
    identityId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateTranscriptionJobSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateTranscriptionJobSubscription = {
  onUpdateTranscriptionJob?:  {
    __typename: "TranscriptionJob",
    id: string,
    date: string,
    status: JobStatus,
    result?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    meetingInviteId: string,
    auditLogs?:  {
      __typename: "ModelTranscriptionJobAuditLogConnection",
      items:  Array< {
        __typename: "TranscriptionJobAuditLog",
        id: string,
        date: string,
        author: string,
        resource: string,
        action: string,
        payload?: string | null,
        ipAddress?: string | null,
        transcriptionJobId: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        transcriptionJobAuditLogsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    owner: string,
    identityId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteTranscriptionJobSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteTranscriptionJobSubscription = {
  onDeleteTranscriptionJob?:  {
    __typename: "TranscriptionJob",
    id: string,
    date: string,
    status: JobStatus,
    result?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    meetingInviteId: string,
    auditLogs?:  {
      __typename: "ModelTranscriptionJobAuditLogConnection",
      items:  Array< {
        __typename: "TranscriptionJobAuditLog",
        id: string,
        date: string,
        author: string,
        resource: string,
        action: string,
        payload?: string | null,
        ipAddress?: string | null,
        transcriptionJobId: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        transcriptionJobAuditLogsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    owner: string,
    identityId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateTranscriptionJobAuditLogSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateTranscriptionJobAuditLogSubscription = {
  onCreateTranscriptionJobAuditLog?:  {
    __typename: "TranscriptionJobAuditLog",
    id: string,
    date: string,
    author: string,
    resource: string,
    action: string,
    payload?: string | null,
    ipAddress?: string | null,
    transcriptionJob:  {
      __typename: "TranscriptionJob",
      id: string,
      date: string,
      status: JobStatus,
      result?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      meetingInviteId: string,
      auditLogs?:  {
        __typename: "ModelTranscriptionJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner: string,
      identityId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    transcriptionJobId: string,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    transcriptionJobAuditLogsId?: string | null,
  } | null,
};

export type OnUpdateTranscriptionJobAuditLogSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateTranscriptionJobAuditLogSubscription = {
  onUpdateTranscriptionJobAuditLog?:  {
    __typename: "TranscriptionJobAuditLog",
    id: string,
    date: string,
    author: string,
    resource: string,
    action: string,
    payload?: string | null,
    ipAddress?: string | null,
    transcriptionJob:  {
      __typename: "TranscriptionJob",
      id: string,
      date: string,
      status: JobStatus,
      result?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      meetingInviteId: string,
      auditLogs?:  {
        __typename: "ModelTranscriptionJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner: string,
      identityId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    transcriptionJobId: string,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    transcriptionJobAuditLogsId?: string | null,
  } | null,
};

export type OnDeleteTranscriptionJobAuditLogSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteTranscriptionJobAuditLogSubscription = {
  onDeleteTranscriptionJobAuditLog?:  {
    __typename: "TranscriptionJobAuditLog",
    id: string,
    date: string,
    author: string,
    resource: string,
    action: string,
    payload?: string | null,
    ipAddress?: string | null,
    transcriptionJob:  {
      __typename: "TranscriptionJob",
      id: string,
      date: string,
      status: JobStatus,
      result?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      meetingInviteId: string,
      auditLogs?:  {
        __typename: "ModelTranscriptionJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner: string,
      identityId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    transcriptionJobId: string,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    transcriptionJobAuditLogsId?: string | null,
  } | null,
};

export type OnCreateTranscriptionAdminJobSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateTranscriptionAdminJobSubscription = {
  onCreateTranscriptionAdminJob?:  {
    __typename: "TranscriptionAdminJob",
    id: string,
    date: string,
    status: JobStatus,
    result?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    meetingId: string,
    meetingInviteId?: string | null,
    auditLogs?:  {
      __typename: "ModelTranscriptionAdminJobAuditLogConnection",
      items:  Array< {
        __typename: "TranscriptionAdminJobAuditLog",
        id: string,
        date: string,
        author: string,
        resource: string,
        action: string,
        payload?: string | null,
        ipAddress?: string | null,
        transcriptionAdminJobId: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        transcriptionAdminJobAuditLogsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    owner: string,
    identityId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateTranscriptionAdminJobSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateTranscriptionAdminJobSubscription = {
  onUpdateTranscriptionAdminJob?:  {
    __typename: "TranscriptionAdminJob",
    id: string,
    date: string,
    status: JobStatus,
    result?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    meetingId: string,
    meetingInviteId?: string | null,
    auditLogs?:  {
      __typename: "ModelTranscriptionAdminJobAuditLogConnection",
      items:  Array< {
        __typename: "TranscriptionAdminJobAuditLog",
        id: string,
        date: string,
        author: string,
        resource: string,
        action: string,
        payload?: string | null,
        ipAddress?: string | null,
        transcriptionAdminJobId: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        transcriptionAdminJobAuditLogsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    owner: string,
    identityId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteTranscriptionAdminJobSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteTranscriptionAdminJobSubscription = {
  onDeleteTranscriptionAdminJob?:  {
    __typename: "TranscriptionAdminJob",
    id: string,
    date: string,
    status: JobStatus,
    result?:  {
      __typename: "S3Object",
      bucket: string,
      region: string,
      key: string,
    } | null,
    meetingId: string,
    meetingInviteId?: string | null,
    auditLogs?:  {
      __typename: "ModelTranscriptionAdminJobAuditLogConnection",
      items:  Array< {
        __typename: "TranscriptionAdminJobAuditLog",
        id: string,
        date: string,
        author: string,
        resource: string,
        action: string,
        payload?: string | null,
        ipAddress?: string | null,
        transcriptionAdminJobId: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        transcriptionAdminJobAuditLogsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    owner: string,
    identityId: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateTranscriptionAdminJobAuditLogSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateTranscriptionAdminJobAuditLogSubscription = {
  onCreateTranscriptionAdminJobAuditLog?:  {
    __typename: "TranscriptionAdminJobAuditLog",
    id: string,
    date: string,
    author: string,
    resource: string,
    action: string,
    payload?: string | null,
    ipAddress?: string | null,
    transcriptionAdminJob:  {
      __typename: "TranscriptionAdminJob",
      id: string,
      date: string,
      status: JobStatus,
      result?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      meetingId: string,
      meetingInviteId?: string | null,
      auditLogs?:  {
        __typename: "ModelTranscriptionAdminJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner: string,
      identityId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    transcriptionAdminJobId: string,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    transcriptionAdminJobAuditLogsId?: string | null,
  } | null,
};

export type OnUpdateTranscriptionAdminJobAuditLogSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateTranscriptionAdminJobAuditLogSubscription = {
  onUpdateTranscriptionAdminJobAuditLog?:  {
    __typename: "TranscriptionAdminJobAuditLog",
    id: string,
    date: string,
    author: string,
    resource: string,
    action: string,
    payload?: string | null,
    ipAddress?: string | null,
    transcriptionAdminJob:  {
      __typename: "TranscriptionAdminJob",
      id: string,
      date: string,
      status: JobStatus,
      result?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      meetingId: string,
      meetingInviteId?: string | null,
      auditLogs?:  {
        __typename: "ModelTranscriptionAdminJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner: string,
      identityId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    transcriptionAdminJobId: string,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    transcriptionAdminJobAuditLogsId?: string | null,
  } | null,
};

export type OnDeleteTranscriptionAdminJobAuditLogSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteTranscriptionAdminJobAuditLogSubscription = {
  onDeleteTranscriptionAdminJobAuditLog?:  {
    __typename: "TranscriptionAdminJobAuditLog",
    id: string,
    date: string,
    author: string,
    resource: string,
    action: string,
    payload?: string | null,
    ipAddress?: string | null,
    transcriptionAdminJob:  {
      __typename: "TranscriptionAdminJob",
      id: string,
      date: string,
      status: JobStatus,
      result?:  {
        __typename: "S3Object",
        bucket: string,
        region: string,
        key: string,
      } | null,
      meetingId: string,
      meetingInviteId?: string | null,
      auditLogs?:  {
        __typename: "ModelTranscriptionAdminJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      owner: string,
      identityId: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    transcriptionAdminJobId: string,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    transcriptionAdminJobAuditLogsId?: string | null,
  } | null,
};

export type OnCreateTranscriptionEventAuditLogSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateTranscriptionEventAuditLogSubscription = {
  onCreateTranscriptionEventAuditLog?:  {
    __typename: "TranscriptionEventAuditLog",
    id: string,
    date: string,
    author: string,
    resource: string,
    action: string,
    payload?: string | null,
    ipAddress?: string | null,
    meetingInvite:  {
      __typename: "MeetingInvite",
      id: string,
      meetingId: string,
      provider?:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      providerId?: string | null,
      title: string,
      startDateTime: string,
      endDateTime: string,
      status: MeetingStatus,
      description?: string | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null,
      productId?: string | null,
      isReadinessTest: boolean,
      meetingRoomId?: string | null,
      providerSessionId?: string | null,
      providerJoinData?: string | null,
      sessionStatus?: MeetingRoomSessionStatus | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      profile:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      },
      profileId: string,
      owner?: string | null,
      meetingInvitationType: MeetingInvitationType,
      teamId?: string | null,
      isInterested?: boolean | null,
      feedbackSummary?: string | null,
      contactRequestTimeframe?: string | null,
      contactPhoneNumber?: string | null,
      contactEmail?: string | null,
      sellerOrganizationName?: string | null,
      sellerTeamName?: string | null,
      buyerTeamName?: string | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productMeetingInvitesId?: string | null,
      profileMeetingInvitesId?: string | null,
      meetingRoomProviderMeetingInvitesId?: string | null,
    },
    meetingInviteId: string,
    profile:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    },
    profileId: string,
    authorProfileId?: string | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileTranscriptionLogsId?: string | null,
    meetingInviteTranscriptionLogsId?: string | null,
  } | null,
};

export type OnUpdateTranscriptionEventAuditLogSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateTranscriptionEventAuditLogSubscription = {
  onUpdateTranscriptionEventAuditLog?:  {
    __typename: "TranscriptionEventAuditLog",
    id: string,
    date: string,
    author: string,
    resource: string,
    action: string,
    payload?: string | null,
    ipAddress?: string | null,
    meetingInvite:  {
      __typename: "MeetingInvite",
      id: string,
      meetingId: string,
      provider?:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      providerId?: string | null,
      title: string,
      startDateTime: string,
      endDateTime: string,
      status: MeetingStatus,
      description?: string | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null,
      productId?: string | null,
      isReadinessTest: boolean,
      meetingRoomId?: string | null,
      providerSessionId?: string | null,
      providerJoinData?: string | null,
      sessionStatus?: MeetingRoomSessionStatus | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      profile:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      },
      profileId: string,
      owner?: string | null,
      meetingInvitationType: MeetingInvitationType,
      teamId?: string | null,
      isInterested?: boolean | null,
      feedbackSummary?: string | null,
      contactRequestTimeframe?: string | null,
      contactPhoneNumber?: string | null,
      contactEmail?: string | null,
      sellerOrganizationName?: string | null,
      sellerTeamName?: string | null,
      buyerTeamName?: string | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productMeetingInvitesId?: string | null,
      profileMeetingInvitesId?: string | null,
      meetingRoomProviderMeetingInvitesId?: string | null,
    },
    meetingInviteId: string,
    profile:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    },
    profileId: string,
    authorProfileId?: string | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileTranscriptionLogsId?: string | null,
    meetingInviteTranscriptionLogsId?: string | null,
  } | null,
};

export type OnDeleteTranscriptionEventAuditLogSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteTranscriptionEventAuditLogSubscription = {
  onDeleteTranscriptionEventAuditLog?:  {
    __typename: "TranscriptionEventAuditLog",
    id: string,
    date: string,
    author: string,
    resource: string,
    action: string,
    payload?: string | null,
    ipAddress?: string | null,
    meetingInvite:  {
      __typename: "MeetingInvite",
      id: string,
      meetingId: string,
      provider?:  {
        __typename: "MeetingRoomProvider",
        id: string,
        name: string,
        componentName?: string | null,
        description?: string | null,
        configuration?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      providerId?: string | null,
      title: string,
      startDateTime: string,
      endDateTime: string,
      status: MeetingStatus,
      description?: string | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      videoEnabled: boolean,
      transcriptionEnabled: boolean,
      product?:  {
        __typename: "Product",
        id: string,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        serviceInformation?: string | null,
        organizationId?: string | null,
        salesTeamId?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProductsId?: string | null,
        teamProductsId?: string | null,
      } | null,
      productId?: string | null,
      isReadinessTest: boolean,
      meetingRoomId?: string | null,
      providerSessionId?: string | null,
      providerJoinData?: string | null,
      sessionStatus?: MeetingRoomSessionStatus | null,
      actualStartDateTime?: string | null,
      actualEndDateTime?: string | null,
      profile:  {
        __typename: "Profile",
        id: string,
        fullName: string,
        fullNameLowerCase?: string | null,
        email: string,
        externalId?: string | null,
        userId?: string | null,
        occupation?: string | null,
        profile?: string | null,
        phoneNumber?: string | null,
        organizationId?: string | null,
        privacyEnabled: boolean,
        owner?: string | null,
        sortByFullName?: number | null,
        timeZone?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        organizationProfilesId?: string | null,
      },
      profileId: string,
      owner?: string | null,
      meetingInvitationType: MeetingInvitationType,
      teamId?: string | null,
      isInterested?: boolean | null,
      feedbackSummary?: string | null,
      contactRequestTimeframe?: string | null,
      contactPhoneNumber?: string | null,
      contactEmail?: string | null,
      sellerOrganizationName?: string | null,
      sellerTeamName?: string | null,
      buyerTeamName?: string | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      productMeetingInvitesId?: string | null,
      profileMeetingInvitesId?: string | null,
      meetingRoomProviderMeetingInvitesId?: string | null,
    },
    meetingInviteId: string,
    profile:  {
      __typename: "Profile",
      id: string,
      fullName: string,
      fullNameLowerCase?: string | null,
      email: string,
      externalId?: string | null,
      userId?: string | null,
      occupation?: string | null,
      profile?: string | null,
      phoneNumber?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        externalId?: string | null,
        name: string,
        nameLowerCase?: string | null,
        description?: string | null,
        url?: string | null,
        phoneNumber?: string | null,
        labels?: Array< string | null > | null,
        owner?: string | null,
        sortByName?: number | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      organizationId?: string | null,
      auditLogs?:  {
        __typename: "ModelMeetingRoomEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      jobAuditLogs?:  {
        __typename: "ModelImportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobAuditLogs?:  {
        __typename: "ModelExportJobAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      teamRelationships?:  {
        __typename: "ModelTeamMemberRelationshipConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      meetingAttendees?:  {
        __typename: "ModelMeetingAttendeeConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      locations?:  {
        __typename: "ModelProfileLocationConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      devices?:  {
        __typename: "ModelProfileDeviceConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      images?:  {
        __typename: "ModelProfileImageConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      questionResponses?:  {
        __typename: "ModelQuestionResponseConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      importJobs?:  {
        __typename: "ModelImportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      exportJobs?:  {
        __typename: "ModelExportJobConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      privacyEnabled: boolean,
      owner?: string | null,
      meetingInvites?:  {
        __typename: "ModelMeetingInviteConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sortByFullName?: number | null,
      transcriptionLogs?:  {
        __typename: "ModelTranscriptionEventAuditLogConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      timeZone?: string | null,
      forwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      sellerForwardEmails?:  {
        __typename: "ModelForwardEmailConnection",
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      organizationProfilesId?: string | null,
    },
    profileId: string,
    authorProfileId?: string | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    profileTranscriptionLogsId?: string | null,
    meetingInviteTranscriptionLogsId?: string | null,
  } | null,
};
