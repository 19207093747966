/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getProductQuestion = /* GraphQL */ `
  query GetProductQuestion($id: ID!) {
    getProductQuestion(id: $id) {
      id
      question
      product {
        id
        name
        nameLowerCase
        description
        serviceInformation
        images {
          nextToken
          startedAt
          __typename
        }
        questions {
          nextToken
          startedAt
          __typename
        }
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        salesTeam {
          id
          name
          nameLowerCase
          description
          labels
          privacyEnabled
          privacyEnableOtherTeams
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        salesTeamId
        meetings {
          nextToken
          startedAt
          __typename
        }
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        labels
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProductsId
        teamProductsId
        __typename
      }
      productId
      responses {
        items {
          id
          response
          privateNotes
          questionId
          profileId
          meetingId
          meetingInviteId
          owner
          contactRequestTimeframe
          contactRequestDateTime
          contactPhoneNumber
          contactEmail
          contactName
          providePersonalEmail
          providePersonalPhoneNumber
          textOnly
          followupMeetingRequested
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productQuestionResponsesId
          profileQuestionResponsesId
          meetingQuestionResponsesId
          meetingInviteQuestionResponsesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      productQuestionsId
      __typename
    }
  }
`;
export const listProductQuestions = /* GraphQL */ `
  query ListProductQuestions(
    $filter: ModelProductQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductQuestions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        question
        product {
          id
          name
          nameLowerCase
          description
          serviceInformation
          organizationId
          salesTeamId
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProductsId
          teamProductsId
          __typename
        }
        productId
        responses {
          nextToken
          startedAt
          __typename
        }
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productQuestionsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncProductQuestions = /* GraphQL */ `
  query SyncProductQuestions(
    $filter: ModelProductQuestionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncProductQuestions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        question
        product {
          id
          name
          nameLowerCase
          description
          serviceInformation
          organizationId
          salesTeamId
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProductsId
          teamProductsId
          __typename
        }
        productId
        responses {
          nextToken
          startedAt
          __typename
        }
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productQuestionsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listProductQuestionsByProductId = /* GraphQL */ `
  query ListProductQuestionsByProductId(
    $productId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProductQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductQuestionsByProductId(
      productId: $productId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        question
        product {
          id
          name
          nameLowerCase
          description
          serviceInformation
          organizationId
          salesTeamId
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProductsId
          teamProductsId
          __typename
        }
        productId
        responses {
          nextToken
          startedAt
          __typename
        }
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productQuestionsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getQuestionResponse = /* GraphQL */ `
  query GetQuestionResponse($id: ID!) {
    getQuestionResponse(id: $id) {
      id
      response
      privateNotes
      question {
        id
        question
        product {
          id
          name
          nameLowerCase
          description
          serviceInformation
          organizationId
          salesTeamId
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProductsId
          teamProductsId
          __typename
        }
        productId
        responses {
          nextToken
          startedAt
          __typename
        }
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productQuestionsId
        __typename
      }
      questionId
      profile {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      profileId
      meeting {
        id
        title
        titleLowerCase
        startDateTime
        endDateTime
        status
        description
        preferredRoomProvider {
          id
          name
          componentName
          description
          configuration
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        preferredRoomProviderId
        actualStartDateTime
        actualEndDateTime
        room {
          id
          name
          meetingId
          providerId
          providerMeetingId
          accessCode
          status
          startDateTime
          endDateTime
          actualStartDateTime
          actualEndDateTime
          roomCreatedAt
          roomDestroyedAt
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          isReadinessTest
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          meetingRoomProviderMeetingRoomsId
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        videoEnabled
        transcriptionEnabled
        product {
          id
          name
          nameLowerCase
          description
          serviceInformation
          organizationId
          salesTeamId
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProductsId
          teamProductsId
          __typename
        }
        productId
        isReadinessTest
        labels
        owner
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        transcriptions {
          nextToken
          startedAt
          __typename
        }
        sellerOrganizationName
        sellerTeamName
        buyerTeamName
        sellerOrganizationNameLowerCase
        sellerTeamNameLowerCase
        buyerTeamNameLowerCase
        externalId
        metrics
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productMeetingsId
        meetingRoomProviderMeetingsId
        meetingRoomId
        __typename
      }
      meetingId
      meetingInvite {
        id
        meetingId
        provider {
          id
          name
          componentName
          description
          configuration
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        providerId
        title
        startDateTime
        endDateTime
        status
        description
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        videoEnabled
        transcriptionEnabled
        product {
          id
          name
          nameLowerCase
          description
          serviceInformation
          organizationId
          salesTeamId
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProductsId
          teamProductsId
          __typename
        }
        productId
        isReadinessTest
        meetingRoomId
        providerSessionId
        providerJoinData
        sessionStatus
        actualStartDateTime
        actualEndDateTime
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        owner
        meetingInvitationType
        teamId
        isInterested
        feedbackSummary
        contactRequestTimeframe
        contactPhoneNumber
        contactEmail
        sellerOrganizationName
        sellerTeamName
        buyerTeamName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productMeetingInvitesId
        profileMeetingInvitesId
        meetingRoomProviderMeetingInvitesId
        __typename
      }
      meetingInviteId
      owner
      contactRequestTimeframe
      contactRequestDateTime
      contactPhoneNumber
      contactEmail
      contactName
      providePersonalEmail
      providePersonalPhoneNumber
      textOnly
      followupMeetingRequested
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      productQuestionResponsesId
      profileQuestionResponsesId
      meetingQuestionResponsesId
      meetingInviteQuestionResponsesId
      __typename
    }
  }
`;
export const listQuestionResponses = /* GraphQL */ `
  query ListQuestionResponses(
    $filter: ModelQuestionResponseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionResponses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        response
        privateNotes
        question {
          id
          question
          productId
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productQuestionsId
          __typename
        }
        questionId
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        meeting {
          id
          title
          titleLowerCase
          startDateTime
          endDateTime
          status
          description
          preferredRoomProviderId
          actualStartDateTime
          actualEndDateTime
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          productId
          isReadinessTest
          labels
          owner
          sellerOrganizationName
          sellerTeamName
          buyerTeamName
          sellerOrganizationNameLowerCase
          sellerTeamNameLowerCase
          buyerTeamNameLowerCase
          externalId
          metrics
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productMeetingsId
          meetingRoomProviderMeetingsId
          meetingRoomId
          __typename
        }
        meetingId
        meetingInvite {
          id
          meetingId
          providerId
          title
          startDateTime
          endDateTime
          status
          description
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          productId
          isReadinessTest
          meetingRoomId
          providerSessionId
          providerJoinData
          sessionStatus
          actualStartDateTime
          actualEndDateTime
          profileId
          owner
          meetingInvitationType
          teamId
          isInterested
          feedbackSummary
          contactRequestTimeframe
          contactPhoneNumber
          contactEmail
          sellerOrganizationName
          sellerTeamName
          buyerTeamName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productMeetingInvitesId
          profileMeetingInvitesId
          meetingRoomProviderMeetingInvitesId
          __typename
        }
        meetingInviteId
        owner
        contactRequestTimeframe
        contactRequestDateTime
        contactPhoneNumber
        contactEmail
        contactName
        providePersonalEmail
        providePersonalPhoneNumber
        textOnly
        followupMeetingRequested
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productQuestionResponsesId
        profileQuestionResponsesId
        meetingQuestionResponsesId
        meetingInviteQuestionResponsesId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncQuestionResponses = /* GraphQL */ `
  query SyncQuestionResponses(
    $filter: ModelQuestionResponseFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncQuestionResponses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        response
        privateNotes
        question {
          id
          question
          productId
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productQuestionsId
          __typename
        }
        questionId
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        meeting {
          id
          title
          titleLowerCase
          startDateTime
          endDateTime
          status
          description
          preferredRoomProviderId
          actualStartDateTime
          actualEndDateTime
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          productId
          isReadinessTest
          labels
          owner
          sellerOrganizationName
          sellerTeamName
          buyerTeamName
          sellerOrganizationNameLowerCase
          sellerTeamNameLowerCase
          buyerTeamNameLowerCase
          externalId
          metrics
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productMeetingsId
          meetingRoomProviderMeetingsId
          meetingRoomId
          __typename
        }
        meetingId
        meetingInvite {
          id
          meetingId
          providerId
          title
          startDateTime
          endDateTime
          status
          description
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          productId
          isReadinessTest
          meetingRoomId
          providerSessionId
          providerJoinData
          sessionStatus
          actualStartDateTime
          actualEndDateTime
          profileId
          owner
          meetingInvitationType
          teamId
          isInterested
          feedbackSummary
          contactRequestTimeframe
          contactPhoneNumber
          contactEmail
          sellerOrganizationName
          sellerTeamName
          buyerTeamName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productMeetingInvitesId
          profileMeetingInvitesId
          meetingRoomProviderMeetingInvitesId
          __typename
        }
        meetingInviteId
        owner
        contactRequestTimeframe
        contactRequestDateTime
        contactPhoneNumber
        contactEmail
        contactName
        providePersonalEmail
        providePersonalPhoneNumber
        textOnly
        followupMeetingRequested
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productQuestionResponsesId
        profileQuestionResponsesId
        meetingQuestionResponsesId
        meetingInviteQuestionResponsesId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listQuestionResponsesByQuestionId = /* GraphQL */ `
  query ListQuestionResponsesByQuestionId(
    $questionId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionResponseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionResponsesByQuestionId(
      questionId: $questionId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        response
        privateNotes
        question {
          id
          question
          productId
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productQuestionsId
          __typename
        }
        questionId
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        meeting {
          id
          title
          titleLowerCase
          startDateTime
          endDateTime
          status
          description
          preferredRoomProviderId
          actualStartDateTime
          actualEndDateTime
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          productId
          isReadinessTest
          labels
          owner
          sellerOrganizationName
          sellerTeamName
          buyerTeamName
          sellerOrganizationNameLowerCase
          sellerTeamNameLowerCase
          buyerTeamNameLowerCase
          externalId
          metrics
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productMeetingsId
          meetingRoomProviderMeetingsId
          meetingRoomId
          __typename
        }
        meetingId
        meetingInvite {
          id
          meetingId
          providerId
          title
          startDateTime
          endDateTime
          status
          description
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          productId
          isReadinessTest
          meetingRoomId
          providerSessionId
          providerJoinData
          sessionStatus
          actualStartDateTime
          actualEndDateTime
          profileId
          owner
          meetingInvitationType
          teamId
          isInterested
          feedbackSummary
          contactRequestTimeframe
          contactPhoneNumber
          contactEmail
          sellerOrganizationName
          sellerTeamName
          buyerTeamName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productMeetingInvitesId
          profileMeetingInvitesId
          meetingRoomProviderMeetingInvitesId
          __typename
        }
        meetingInviteId
        owner
        contactRequestTimeframe
        contactRequestDateTime
        contactPhoneNumber
        contactEmail
        contactName
        providePersonalEmail
        providePersonalPhoneNumber
        textOnly
        followupMeetingRequested
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productQuestionResponsesId
        profileQuestionResponsesId
        meetingQuestionResponsesId
        meetingInviteQuestionResponsesId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listQuestionResponsesByProfileId = /* GraphQL */ `
  query ListQuestionResponsesByProfileId(
    $profileId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionResponseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionResponsesByProfileId(
      profileId: $profileId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        response
        privateNotes
        question {
          id
          question
          productId
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productQuestionsId
          __typename
        }
        questionId
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        meeting {
          id
          title
          titleLowerCase
          startDateTime
          endDateTime
          status
          description
          preferredRoomProviderId
          actualStartDateTime
          actualEndDateTime
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          productId
          isReadinessTest
          labels
          owner
          sellerOrganizationName
          sellerTeamName
          buyerTeamName
          sellerOrganizationNameLowerCase
          sellerTeamNameLowerCase
          buyerTeamNameLowerCase
          externalId
          metrics
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productMeetingsId
          meetingRoomProviderMeetingsId
          meetingRoomId
          __typename
        }
        meetingId
        meetingInvite {
          id
          meetingId
          providerId
          title
          startDateTime
          endDateTime
          status
          description
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          productId
          isReadinessTest
          meetingRoomId
          providerSessionId
          providerJoinData
          sessionStatus
          actualStartDateTime
          actualEndDateTime
          profileId
          owner
          meetingInvitationType
          teamId
          isInterested
          feedbackSummary
          contactRequestTimeframe
          contactPhoneNumber
          contactEmail
          sellerOrganizationName
          sellerTeamName
          buyerTeamName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productMeetingInvitesId
          profileMeetingInvitesId
          meetingRoomProviderMeetingInvitesId
          __typename
        }
        meetingInviteId
        owner
        contactRequestTimeframe
        contactRequestDateTime
        contactPhoneNumber
        contactEmail
        contactName
        providePersonalEmail
        providePersonalPhoneNumber
        textOnly
        followupMeetingRequested
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productQuestionResponsesId
        profileQuestionResponsesId
        meetingQuestionResponsesId
        meetingInviteQuestionResponsesId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listQuestionResponsesByMeetingId = /* GraphQL */ `
  query ListQuestionResponsesByMeetingId(
    $meetingId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionResponseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionResponsesByMeetingId(
      meetingId: $meetingId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        response
        privateNotes
        question {
          id
          question
          productId
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productQuestionsId
          __typename
        }
        questionId
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        meeting {
          id
          title
          titleLowerCase
          startDateTime
          endDateTime
          status
          description
          preferredRoomProviderId
          actualStartDateTime
          actualEndDateTime
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          productId
          isReadinessTest
          labels
          owner
          sellerOrganizationName
          sellerTeamName
          buyerTeamName
          sellerOrganizationNameLowerCase
          sellerTeamNameLowerCase
          buyerTeamNameLowerCase
          externalId
          metrics
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productMeetingsId
          meetingRoomProviderMeetingsId
          meetingRoomId
          __typename
        }
        meetingId
        meetingInvite {
          id
          meetingId
          providerId
          title
          startDateTime
          endDateTime
          status
          description
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          productId
          isReadinessTest
          meetingRoomId
          providerSessionId
          providerJoinData
          sessionStatus
          actualStartDateTime
          actualEndDateTime
          profileId
          owner
          meetingInvitationType
          teamId
          isInterested
          feedbackSummary
          contactRequestTimeframe
          contactPhoneNumber
          contactEmail
          sellerOrganizationName
          sellerTeamName
          buyerTeamName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productMeetingInvitesId
          profileMeetingInvitesId
          meetingRoomProviderMeetingInvitesId
          __typename
        }
        meetingInviteId
        owner
        contactRequestTimeframe
        contactRequestDateTime
        contactPhoneNumber
        contactEmail
        contactName
        providePersonalEmail
        providePersonalPhoneNumber
        textOnly
        followupMeetingRequested
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productQuestionResponsesId
        profileQuestionResponsesId
        meetingQuestionResponsesId
        meetingInviteQuestionResponsesId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listQuestionResponsesByMeetingInviteId = /* GraphQL */ `
  query ListQuestionResponsesByMeetingInviteId(
    $meetingInviteId: ID!
    $questionId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionResponseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionResponsesByMeetingInviteId(
      meetingInviteId: $meetingInviteId
      questionId: $questionId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        response
        privateNotes
        question {
          id
          question
          productId
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productQuestionsId
          __typename
        }
        questionId
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        meeting {
          id
          title
          titleLowerCase
          startDateTime
          endDateTime
          status
          description
          preferredRoomProviderId
          actualStartDateTime
          actualEndDateTime
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          productId
          isReadinessTest
          labels
          owner
          sellerOrganizationName
          sellerTeamName
          buyerTeamName
          sellerOrganizationNameLowerCase
          sellerTeamNameLowerCase
          buyerTeamNameLowerCase
          externalId
          metrics
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productMeetingsId
          meetingRoomProviderMeetingsId
          meetingRoomId
          __typename
        }
        meetingId
        meetingInvite {
          id
          meetingId
          providerId
          title
          startDateTime
          endDateTime
          status
          description
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          productId
          isReadinessTest
          meetingRoomId
          providerSessionId
          providerJoinData
          sessionStatus
          actualStartDateTime
          actualEndDateTime
          profileId
          owner
          meetingInvitationType
          teamId
          isInterested
          feedbackSummary
          contactRequestTimeframe
          contactPhoneNumber
          contactEmail
          sellerOrganizationName
          sellerTeamName
          buyerTeamName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productMeetingInvitesId
          profileMeetingInvitesId
          meetingRoomProviderMeetingInvitesId
          __typename
        }
        meetingInviteId
        owner
        contactRequestTimeframe
        contactRequestDateTime
        contactPhoneNumber
        contactEmail
        contactName
        providePersonalEmail
        providePersonalPhoneNumber
        textOnly
        followupMeetingRequested
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productQuestionResponsesId
        profileQuestionResponsesId
        meetingQuestionResponsesId
        meetingInviteQuestionResponsesId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getProductImage = /* GraphQL */ `
  query GetProductImage($id: ID!) {
    getProductImage(id: $id) {
      id
      title
      alt
      width
      height
      image {
        bucket
        region
        key
        __typename
      }
      product {
        id
        name
        nameLowerCase
        description
        serviceInformation
        images {
          nextToken
          startedAt
          __typename
        }
        questions {
          nextToken
          startedAt
          __typename
        }
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        salesTeam {
          id
          name
          nameLowerCase
          description
          labels
          privacyEnabled
          privacyEnableOtherTeams
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        salesTeamId
        meetings {
          nextToken
          startedAt
          __typename
        }
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        labels
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProductsId
        teamProductsId
        __typename
      }
      productId
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      productImagesId
      __typename
    }
  }
`;
export const listProductImages = /* GraphQL */ `
  query ListProductImages(
    $filter: ModelProductImageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductImages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        alt
        width
        height
        image {
          bucket
          region
          key
          __typename
        }
        product {
          id
          name
          nameLowerCase
          description
          serviceInformation
          organizationId
          salesTeamId
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProductsId
          teamProductsId
          __typename
        }
        productId
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productImagesId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncProductImages = /* GraphQL */ `
  query SyncProductImages(
    $filter: ModelProductImageFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncProductImages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        alt
        width
        height
        image {
          bucket
          region
          key
          __typename
        }
        product {
          id
          name
          nameLowerCase
          description
          serviceInformation
          organizationId
          salesTeamId
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProductsId
          teamProductsId
          __typename
        }
        productId
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productImagesId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listProductImagesByProductId = /* GraphQL */ `
  query ListProductImagesByProductId(
    $productId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProductImageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductImagesByProductId(
      productId: $productId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        alt
        width
        height
        image {
          bucket
          region
          key
          __typename
        }
        product {
          id
          name
          nameLowerCase
          description
          serviceInformation
          organizationId
          salesTeamId
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProductsId
          teamProductsId
          __typename
        }
        productId
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productImagesId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      name
      nameLowerCase
      description
      serviceInformation
      images {
        items {
          id
          title
          alt
          width
          height
          productId
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productImagesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      questions {
        items {
          id
          question
          productId
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productQuestionsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      organization {
        id
        externalId
        name
        nameLowerCase
        description
        url
        phoneNumber
        locations {
          nextToken
          startedAt
          __typename
        }
        profiles {
          nextToken
          startedAt
          __typename
        }
        products {
          nextToken
          startedAt
          __typename
        }
        labels
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      organizationId
      salesTeam {
        id
        name
        nameLowerCase
        description
        memberRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingRelationships {
          nextToken
          startedAt
          __typename
        }
        products {
          nextToken
          startedAt
          __typename
        }
        labels
        privacyEnabled
        privacyEnableOtherTeams
        images {
          nextToken
          startedAt
          __typename
        }
        transcriptions {
          nextToken
          startedAt
          __typename
        }
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      salesTeamId
      meetings {
        items {
          id
          title
          titleLowerCase
          startDateTime
          endDateTime
          status
          description
          preferredRoomProviderId
          actualStartDateTime
          actualEndDateTime
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          productId
          isReadinessTest
          labels
          owner
          sellerOrganizationName
          sellerTeamName
          buyerTeamName
          sellerOrganizationNameLowerCase
          sellerTeamNameLowerCase
          buyerTeamNameLowerCase
          externalId
          metrics
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productMeetingsId
          meetingRoomProviderMeetingsId
          meetingRoomId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      meetingInvites {
        items {
          id
          meetingId
          providerId
          title
          startDateTime
          endDateTime
          status
          description
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          productId
          isReadinessTest
          meetingRoomId
          providerSessionId
          providerJoinData
          sessionStatus
          actualStartDateTime
          actualEndDateTime
          profileId
          owner
          meetingInvitationType
          teamId
          isInterested
          feedbackSummary
          contactRequestTimeframe
          contactPhoneNumber
          contactEmail
          sellerOrganizationName
          sellerTeamName
          buyerTeamName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productMeetingInvitesId
          profileMeetingInvitesId
          meetingRoomProviderMeetingInvitesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      labels
      owner
      sortByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      organizationProductsId
      teamProductsId
      __typename
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        nameLowerCase
        description
        serviceInformation
        images {
          nextToken
          startedAt
          __typename
        }
        questions {
          nextToken
          startedAt
          __typename
        }
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        salesTeam {
          id
          name
          nameLowerCase
          description
          labels
          privacyEnabled
          privacyEnableOtherTeams
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        salesTeamId
        meetings {
          nextToken
          startedAt
          __typename
        }
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        labels
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProductsId
        teamProductsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncProducts = /* GraphQL */ `
  query SyncProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncProducts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        nameLowerCase
        description
        serviceInformation
        images {
          nextToken
          startedAt
          __typename
        }
        questions {
          nextToken
          startedAt
          __typename
        }
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        salesTeam {
          id
          name
          nameLowerCase
          description
          labels
          privacyEnabled
          privacyEnableOtherTeams
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        salesTeamId
        meetings {
          nextToken
          startedAt
          __typename
        }
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        labels
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProductsId
        teamProductsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listProductsByName = /* GraphQL */ `
  query ListProductsByName(
    $name: String!
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductsByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        nameLowerCase
        description
        serviceInformation
        images {
          nextToken
          startedAt
          __typename
        }
        questions {
          nextToken
          startedAt
          __typename
        }
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        salesTeam {
          id
          name
          nameLowerCase
          description
          labels
          privacyEnabled
          privacyEnableOtherTeams
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        salesTeamId
        meetings {
          nextToken
          startedAt
          __typename
        }
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        labels
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProductsId
        teamProductsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listProductsByNameLowerCase = /* GraphQL */ `
  query ListProductsByNameLowerCase(
    $nameLowerCase: String!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductsByNameLowerCase(
      nameLowerCase: $nameLowerCase
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        nameLowerCase
        description
        serviceInformation
        images {
          nextToken
          startedAt
          __typename
        }
        questions {
          nextToken
          startedAt
          __typename
        }
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        salesTeam {
          id
          name
          nameLowerCase
          description
          labels
          privacyEnabled
          privacyEnableOtherTeams
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        salesTeamId
        meetings {
          nextToken
          startedAt
          __typename
        }
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        labels
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProductsId
        teamProductsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listProductsByOrganizationId = /* GraphQL */ `
  query ListProductsByOrganizationId(
    $organizationId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductsByOrganizationId(
      organizationId: $organizationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        nameLowerCase
        description
        serviceInformation
        images {
          nextToken
          startedAt
          __typename
        }
        questions {
          nextToken
          startedAt
          __typename
        }
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        salesTeam {
          id
          name
          nameLowerCase
          description
          labels
          privacyEnabled
          privacyEnableOtherTeams
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        salesTeamId
        meetings {
          nextToken
          startedAt
          __typename
        }
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        labels
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProductsId
        teamProductsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listProductsBySalesTeamId = /* GraphQL */ `
  query ListProductsBySalesTeamId(
    $salesTeamId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductsBySalesTeamId(
      salesTeamId: $salesTeamId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        nameLowerCase
        description
        serviceInformation
        images {
          nextToken
          startedAt
          __typename
        }
        questions {
          nextToken
          startedAt
          __typename
        }
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        salesTeam {
          id
          name
          nameLowerCase
          description
          labels
          privacyEnabled
          privacyEnableOtherTeams
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        salesTeamId
        meetings {
          nextToken
          startedAt
          __typename
        }
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        labels
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProductsId
        teamProductsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listProductsBySortByName = /* GraphQL */ `
  query ListProductsBySortByName(
    $sortByName: Int!
    $nameLowerCase: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductsBySortByName(
      sortByName: $sortByName
      nameLowerCase: $nameLowerCase
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        nameLowerCase
        description
        serviceInformation
        images {
          nextToken
          startedAt
          __typename
        }
        questions {
          nextToken
          startedAt
          __typename
        }
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        salesTeam {
          id
          name
          nameLowerCase
          description
          labels
          privacyEnabled
          privacyEnableOtherTeams
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        salesTeamId
        meetings {
          nextToken
          startedAt
          __typename
        }
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        labels
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProductsId
        teamProductsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getOrganizationLocation = /* GraphQL */ `
  query GetOrganizationLocation($id: ID!) {
    getOrganizationLocation(id: $id) {
      id
      title
      address
      city
      state
      country
      organization {
        id
        externalId
        name
        nameLowerCase
        description
        url
        phoneNumber
        locations {
          nextToken
          startedAt
          __typename
        }
        profiles {
          nextToken
          startedAt
          __typename
        }
        products {
          nextToken
          startedAt
          __typename
        }
        labels
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      organizationId
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      organizationLocationsId
      __typename
    }
  }
`;
export const listOrganizationLocations = /* GraphQL */ `
  query ListOrganizationLocations(
    $filter: ModelOrganizationLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizationLocations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        address
        city
        state
        country
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationLocationsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncOrganizationLocations = /* GraphQL */ `
  query SyncOrganizationLocations(
    $filter: ModelOrganizationLocationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOrganizationLocations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        address
        city
        state
        country
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationLocationsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listOrganizationLocationsByOrganizationId = /* GraphQL */ `
  query ListOrganizationLocationsByOrganizationId(
    $organizationId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOrganizationLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizationLocationsByOrganizationId(
      organizationId: $organizationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        address
        city
        state
        country
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationLocationsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getOrganization = /* GraphQL */ `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      id
      externalId
      name
      nameLowerCase
      description
      url
      phoneNumber
      locations {
        items {
          id
          title
          address
          city
          state
          country
          organizationId
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationLocationsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      profiles {
        items {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      products {
        items {
          id
          name
          nameLowerCase
          description
          serviceInformation
          organizationId
          salesTeamId
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProductsId
          teamProductsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      labels
      owner
      sortByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listOrganizations = /* GraphQL */ `
  query ListOrganizations(
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        externalId
        name
        nameLowerCase
        description
        url
        phoneNumber
        locations {
          nextToken
          startedAt
          __typename
        }
        profiles {
          nextToken
          startedAt
          __typename
        }
        products {
          nextToken
          startedAt
          __typename
        }
        labels
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncOrganizations = /* GraphQL */ `
  query SyncOrganizations(
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOrganizations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        externalId
        name
        nameLowerCase
        description
        url
        phoneNumber
        locations {
          nextToken
          startedAt
          __typename
        }
        profiles {
          nextToken
          startedAt
          __typename
        }
        products {
          nextToken
          startedAt
          __typename
        }
        labels
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listOrganizationsByExternalId = /* GraphQL */ `
  query ListOrganizationsByExternalId(
    $externalId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizationsByExternalId(
      externalId: $externalId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        externalId
        name
        nameLowerCase
        description
        url
        phoneNumber
        locations {
          nextToken
          startedAt
          __typename
        }
        profiles {
          nextToken
          startedAt
          __typename
        }
        products {
          nextToken
          startedAt
          __typename
        }
        labels
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listOrganizationsByName = /* GraphQL */ `
  query ListOrganizationsByName(
    $name: String!
    $sortDirection: ModelSortDirection
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizationsByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        externalId
        name
        nameLowerCase
        description
        url
        phoneNumber
        locations {
          nextToken
          startedAt
          __typename
        }
        profiles {
          nextToken
          startedAt
          __typename
        }
        products {
          nextToken
          startedAt
          __typename
        }
        labels
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listOrganizationsByNameLowerCase = /* GraphQL */ `
  query ListOrganizationsByNameLowerCase(
    $nameLowerCase: String!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizationsByNameLowerCase(
      nameLowerCase: $nameLowerCase
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        externalId
        name
        nameLowerCase
        description
        url
        phoneNumber
        locations {
          nextToken
          startedAt
          __typename
        }
        profiles {
          nextToken
          startedAt
          __typename
        }
        products {
          nextToken
          startedAt
          __typename
        }
        labels
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listOrganizationsBySortByName = /* GraphQL */ `
  query ListOrganizationsBySortByName(
    $sortByName: Int!
    $nameLowerCase: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizationsBySortByName(
      sortByName: $sortByName
      nameLowerCase: $nameLowerCase
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        externalId
        name
        nameLowerCase
        description
        url
        phoneNumber
        locations {
          nextToken
          startedAt
          __typename
        }
        profiles {
          nextToken
          startedAt
          __typename
        }
        products {
          nextToken
          startedAt
          __typename
        }
        labels
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getProfileLocation = /* GraphQL */ `
  query GetProfileLocation($id: ID!) {
    getProfileLocation(id: $id) {
      id
      title
      address
      city
      state
      country
      profile {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      profileId
      isPrimary
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      profileLocationsId
      __typename
    }
  }
`;
export const listProfileLocations = /* GraphQL */ `
  query ListProfileLocations(
    $filter: ModelProfileLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfileLocations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        address
        city
        state
        country
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        isPrimary
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        profileLocationsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncProfileLocations = /* GraphQL */ `
  query SyncProfileLocations(
    $filter: ModelProfileLocationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncProfileLocations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        address
        city
        state
        country
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        isPrimary
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        profileLocationsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listProfileLocationsByProfileId = /* GraphQL */ `
  query ListProfileLocationsByProfileId(
    $profileId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProfileLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfileLocationsByProfileId(
      profileId: $profileId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        address
        city
        state
        country
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        isPrimary
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        profileLocationsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getProfileImage = /* GraphQL */ `
  query GetProfileImage($id: ID!) {
    getProfileImage(id: $id) {
      id
      title
      alt
      width
      height
      image {
        bucket
        region
        key
        __typename
      }
      profile {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      profileId
      isDefault
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      profileImagesId
      __typename
    }
  }
`;
export const listProfileImages = /* GraphQL */ `
  query ListProfileImages(
    $filter: ModelProfileImageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfileImages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        alt
        width
        height
        image {
          bucket
          region
          key
          __typename
        }
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        isDefault
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        profileImagesId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncProfileImages = /* GraphQL */ `
  query SyncProfileImages(
    $filter: ModelProfileImageFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncProfileImages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        alt
        width
        height
        image {
          bucket
          region
          key
          __typename
        }
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        isDefault
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        profileImagesId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listProfileImagesByProfileId = /* GraphQL */ `
  query ListProfileImagesByProfileId(
    $profileId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProfileImageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfileImagesByProfileId(
      profileId: $profileId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        alt
        width
        height
        image {
          bucket
          region
          key
          __typename
        }
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        isDefault
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        profileImagesId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getForwardEmail = /* GraphQL */ `
  query GetForwardEmail($id: ID!) {
    getForwardEmail(id: $id) {
      id
      received
      remaining
      publicEmail
      expireAt
      privateEmail
      publicReplyEmail
      profile {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      profileId
      sellerProfile {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      sellerProfileId
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      profileForwardEmailsId
      profileSellerForwardEmailsId
      __typename
    }
  }
`;
export const listForwardEmails = /* GraphQL */ `
  query ListForwardEmails(
    $filter: ModelForwardEmailFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listForwardEmails(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        received
        remaining
        publicEmail
        expireAt
        privateEmail
        publicReplyEmail
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        sellerProfile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        sellerProfileId
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        profileForwardEmailsId
        profileSellerForwardEmailsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncForwardEmails = /* GraphQL */ `
  query SyncForwardEmails(
    $filter: ModelForwardEmailFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncForwardEmails(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        received
        remaining
        publicEmail
        expireAt
        privateEmail
        publicReplyEmail
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        sellerProfile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        sellerProfileId
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        profileForwardEmailsId
        profileSellerForwardEmailsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listForwardEmailsByPublicEmail = /* GraphQL */ `
  query ListForwardEmailsByPublicEmail(
    $publicEmail: AWSEmail!
    $sortDirection: ModelSortDirection
    $filter: ModelForwardEmailFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listForwardEmailsByPublicEmail(
      publicEmail: $publicEmail
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        received
        remaining
        publicEmail
        expireAt
        privateEmail
        publicReplyEmail
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        sellerProfile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        sellerProfileId
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        profileForwardEmailsId
        profileSellerForwardEmailsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listForwardEmailsByPrivateEmail = /* GraphQL */ `
  query ListForwardEmailsByPrivateEmail(
    $privateEmail: AWSEmail!
    $publicReplyEmail: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelForwardEmailFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listForwardEmailsByPrivateEmail(
      privateEmail: $privateEmail
      publicReplyEmail: $publicReplyEmail
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        received
        remaining
        publicEmail
        expireAt
        privateEmail
        publicReplyEmail
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        sellerProfile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        sellerProfileId
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        profileForwardEmailsId
        profileSellerForwardEmailsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listForwardEmailsByProfileId = /* GraphQL */ `
  query ListForwardEmailsByProfileId(
    $profileId: ID!
    $received: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelForwardEmailFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listForwardEmailsByProfileId(
      profileId: $profileId
      received: $received
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        received
        remaining
        publicEmail
        expireAt
        privateEmail
        publicReplyEmail
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        sellerProfile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        sellerProfileId
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        profileForwardEmailsId
        profileSellerForwardEmailsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listForwardEmailsBySellerProfileId = /* GraphQL */ `
  query ListForwardEmailsBySellerProfileId(
    $sellerProfileId: ID!
    $profileId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelForwardEmailFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listForwardEmailsBySellerProfileId(
      sellerProfileId: $sellerProfileId
      profileId: $profileId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        received
        remaining
        publicEmail
        expireAt
        privateEmail
        publicReplyEmail
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        sellerProfile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        sellerProfileId
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        profileForwardEmailsId
        profileSellerForwardEmailsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getProfile = /* GraphQL */ `
  query GetProfile($id: ID!) {
    getProfile(id: $id) {
      id
      fullName
      fullNameLowerCase
      email
      externalId
      userId
      occupation
      profile
      phoneNumber
      organization {
        id
        externalId
        name
        nameLowerCase
        description
        url
        phoneNumber
        locations {
          nextToken
          startedAt
          __typename
        }
        profiles {
          nextToken
          startedAt
          __typename
        }
        products {
          nextToken
          startedAt
          __typename
        }
        labels
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      organizationId
      auditLogs {
        items {
          id
          date
          author
          resource
          action
          payload
          ipAddress
          meetingRoomId
          profileId
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileAuditLogsId
          meetingRoomAuditlogsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      jobAuditLogs {
        items {
          id
          date
          author
          resource
          action
          payload
          ipAddress
          importJobId
          profileId
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileJobAuditLogsId
          importJobAuditLogsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      exportJobAuditLogs {
        items {
          id
          date
          author
          resource
          action
          payload
          ipAddress
          exportJobId
          profileId
          owner
          identityId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileExportJobAuditLogsId
          exportJobAuditLogsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      teamRelationships {
        items {
          id
          teamId
          memberProfileId
          relationship
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileTeamRelationshipsId
          teamMemberRelationshipsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      meetingAttendees {
        items {
          id
          profileId
          meetingId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileMeetingAttendeesId
          meetingMeetingAttendeesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      locations {
        items {
          id
          title
          address
          city
          state
          country
          profileId
          isPrimary
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileLocationsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      devices {
        items {
          id
          deviceHash
          hasWebcam
          hasMicrophone
          isScreenCapturingSupported
          isWebRTCSupported
          isWebsiteHasMicrophonePermissions
          isWebsiteHasWebcamPermissions
          isWebSocketsSupported
          clockOffset
          lastSeen
          lastLogin
          lastMeetingId
          lastMeetingInviteId
          lastMeetingJoin
          lastMeetingStatus
          meetingData
          userAgent
          userAgentSummary
          micStatus
          cameraStatus
          networkQuality
          profileId
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileDevicesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      images {
        items {
          id
          title
          alt
          width
          height
          profileId
          isDefault
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileImagesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      questionResponses {
        items {
          id
          response
          privateNotes
          questionId
          profileId
          meetingId
          meetingInviteId
          owner
          contactRequestTimeframe
          contactRequestDateTime
          contactPhoneNumber
          contactEmail
          contactName
          providePersonalEmail
          providePersonalPhoneNumber
          textOnly
          followupMeetingRequested
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productQuestionResponsesId
          profileQuestionResponsesId
          meetingQuestionResponsesId
          meetingInviteQuestionResponsesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      importJobs {
        items {
          id
          status
          identityId
          result
          profileId
          lastStartDateTime
          lastEndDateTime
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileImportJobsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      exportJobs {
        items {
          id
          status
          profileId
          meetingInvitationType
          startDateTime
          endDateTime
          owner
          identityId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileExportJobsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      privacyEnabled
      owner
      meetingInvites {
        items {
          id
          meetingId
          providerId
          title
          startDateTime
          endDateTime
          status
          description
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          productId
          isReadinessTest
          meetingRoomId
          providerSessionId
          providerJoinData
          sessionStatus
          actualStartDateTime
          actualEndDateTime
          profileId
          owner
          meetingInvitationType
          teamId
          isInterested
          feedbackSummary
          contactRequestTimeframe
          contactPhoneNumber
          contactEmail
          sellerOrganizationName
          sellerTeamName
          buyerTeamName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productMeetingInvitesId
          profileMeetingInvitesId
          meetingRoomProviderMeetingInvitesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      sortByFullName
      transcriptionLogs {
        items {
          id
          date
          author
          resource
          action
          payload
          ipAddress
          meetingInviteId
          profileId
          authorProfileId
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileTranscriptionLogsId
          meetingInviteTranscriptionLogsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      timeZone
      forwardEmails {
        items {
          id
          received
          remaining
          publicEmail
          expireAt
          privateEmail
          publicReplyEmail
          profileId
          sellerProfileId
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileForwardEmailsId
          profileSellerForwardEmailsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      sellerForwardEmails {
        items {
          id
          received
          remaining
          publicEmail
          expireAt
          privateEmail
          publicReplyEmail
          profileId
          sellerProfileId
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileForwardEmailsId
          profileSellerForwardEmailsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      organizationProfilesId
      __typename
    }
  }
`;
export const listProfiles = /* GraphQL */ `
  query ListProfiles(
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncProfiles = /* GraphQL */ `
  query SyncProfiles(
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncProfiles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listProfilesByFullName = /* GraphQL */ `
  query ListProfilesByFullName(
    $fullName: String!
    $sortDirection: ModelSortDirection
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfilesByFullName(
      fullName: $fullName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listProfilesByFullNameLowerCase = /* GraphQL */ `
  query ListProfilesByFullNameLowerCase(
    $fullNameLowerCase: String!
    $fullName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfilesByFullNameLowerCase(
      fullNameLowerCase: $fullNameLowerCase
      fullName: $fullName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listProfilesByEmail = /* GraphQL */ `
  query ListProfilesByEmail(
    $email: AWSEmail!
    $sortDirection: ModelSortDirection
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfilesByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listProfilesByExternalId = /* GraphQL */ `
  query ListProfilesByExternalId(
    $externalId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfilesByExternalId(
      externalId: $externalId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listProfilesByUserId = /* GraphQL */ `
  query ListProfilesByUserId(
    $userId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfilesByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listProfilesByOrganizationId = /* GraphQL */ `
  query ListProfilesByOrganizationId(
    $organizationId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfilesByOrganizationId(
      organizationId: $organizationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listProfilesBySortByFullName = /* GraphQL */ `
  query ListProfilesBySortByFullName(
    $sortByFullName: Int!
    $fullNameLowerCase: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfilesBySortByFullName(
      sortByFullName: $sortByFullName
      fullNameLowerCase: $fullNameLowerCase
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getProfileDevice = /* GraphQL */ `
  query GetProfileDevice($id: ID!) {
    getProfileDevice(id: $id) {
      id
      deviceHash
      hasWebcam
      hasMicrophone
      isScreenCapturingSupported
      isWebRTCSupported
      isWebsiteHasMicrophonePermissions
      isWebsiteHasWebcamPermissions
      isWebSocketsSupported
      clockOffset
      lastSeen
      lastLogin
      lastMeetingId
      lastMeetingInviteId
      lastMeetingJoin
      lastMeetingStatus
      meetingData
      userAgent
      userAgentSummary
      micStatus
      cameraStatus
      networkQuality
      profile {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      profileId
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      profileDevicesId
      __typename
    }
  }
`;
export const listProfileDevices = /* GraphQL */ `
  query ListProfileDevices(
    $filter: ModelProfileDeviceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfileDevices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        deviceHash
        hasWebcam
        hasMicrophone
        isScreenCapturingSupported
        isWebRTCSupported
        isWebsiteHasMicrophonePermissions
        isWebsiteHasWebcamPermissions
        isWebSocketsSupported
        clockOffset
        lastSeen
        lastLogin
        lastMeetingId
        lastMeetingInviteId
        lastMeetingJoin
        lastMeetingStatus
        meetingData
        userAgent
        userAgentSummary
        micStatus
        cameraStatus
        networkQuality
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        profileDevicesId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncProfileDevices = /* GraphQL */ `
  query SyncProfileDevices(
    $filter: ModelProfileDeviceFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncProfileDevices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        deviceHash
        hasWebcam
        hasMicrophone
        isScreenCapturingSupported
        isWebRTCSupported
        isWebsiteHasMicrophonePermissions
        isWebsiteHasWebcamPermissions
        isWebSocketsSupported
        clockOffset
        lastSeen
        lastLogin
        lastMeetingId
        lastMeetingInviteId
        lastMeetingJoin
        lastMeetingStatus
        meetingData
        userAgent
        userAgentSummary
        micStatus
        cameraStatus
        networkQuality
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        profileDevicesId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listProfileDevicesByClockOffset = /* GraphQL */ `
  query ListProfileDevicesByClockOffset(
    $clockOffset: Int!
    $lastSeen: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProfileDeviceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfileDevicesByClockOffset(
      clockOffset: $clockOffset
      lastSeen: $lastSeen
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        deviceHash
        hasWebcam
        hasMicrophone
        isScreenCapturingSupported
        isWebRTCSupported
        isWebsiteHasMicrophonePermissions
        isWebsiteHasWebcamPermissions
        isWebSocketsSupported
        clockOffset
        lastSeen
        lastLogin
        lastMeetingId
        lastMeetingInviteId
        lastMeetingJoin
        lastMeetingStatus
        meetingData
        userAgent
        userAgentSummary
        micStatus
        cameraStatus
        networkQuality
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        profileDevicesId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listProfileDevicesByLastSeen = /* GraphQL */ `
  query ListProfileDevicesByLastSeen(
    $lastSeen: AWSDateTime!
    $sortDirection: ModelSortDirection
    $filter: ModelProfileDeviceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfileDevicesByLastSeen(
      lastSeen: $lastSeen
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        deviceHash
        hasWebcam
        hasMicrophone
        isScreenCapturingSupported
        isWebRTCSupported
        isWebsiteHasMicrophonePermissions
        isWebsiteHasWebcamPermissions
        isWebSocketsSupported
        clockOffset
        lastSeen
        lastLogin
        lastMeetingId
        lastMeetingInviteId
        lastMeetingJoin
        lastMeetingStatus
        meetingData
        userAgent
        userAgentSummary
        micStatus
        cameraStatus
        networkQuality
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        profileDevicesId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listProfileDevicesByMeetingStatus = /* GraphQL */ `
  query ListProfileDevicesByMeetingStatus(
    $lastMeetingStatus: String!
    $lastMeetingJoin: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProfileDeviceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfileDevicesByMeetingStatus(
      lastMeetingStatus: $lastMeetingStatus
      lastMeetingJoin: $lastMeetingJoin
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        deviceHash
        hasWebcam
        hasMicrophone
        isScreenCapturingSupported
        isWebRTCSupported
        isWebsiteHasMicrophonePermissions
        isWebsiteHasWebcamPermissions
        isWebSocketsSupported
        clockOffset
        lastSeen
        lastLogin
        lastMeetingId
        lastMeetingInviteId
        lastMeetingJoin
        lastMeetingStatus
        meetingData
        userAgent
        userAgentSummary
        micStatus
        cameraStatus
        networkQuality
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        profileDevicesId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listProfileDevicesByMicStatus = /* GraphQL */ `
  query ListProfileDevicesByMicStatus(
    $micStatus: String!
    $lastMeetingJoin: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProfileDeviceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfileDevicesByMicStatus(
      micStatus: $micStatus
      lastMeetingJoin: $lastMeetingJoin
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        deviceHash
        hasWebcam
        hasMicrophone
        isScreenCapturingSupported
        isWebRTCSupported
        isWebsiteHasMicrophonePermissions
        isWebsiteHasWebcamPermissions
        isWebSocketsSupported
        clockOffset
        lastSeen
        lastLogin
        lastMeetingId
        lastMeetingInviteId
        lastMeetingJoin
        lastMeetingStatus
        meetingData
        userAgent
        userAgentSummary
        micStatus
        cameraStatus
        networkQuality
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        profileDevicesId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listProfileDevicesByCameraStatus = /* GraphQL */ `
  query ListProfileDevicesByCameraStatus(
    $cameraStatus: String!
    $lastMeetingJoin: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProfileDeviceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfileDevicesByCameraStatus(
      cameraStatus: $cameraStatus
      lastMeetingJoin: $lastMeetingJoin
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        deviceHash
        hasWebcam
        hasMicrophone
        isScreenCapturingSupported
        isWebRTCSupported
        isWebsiteHasMicrophonePermissions
        isWebsiteHasWebcamPermissions
        isWebSocketsSupported
        clockOffset
        lastSeen
        lastLogin
        lastMeetingId
        lastMeetingInviteId
        lastMeetingJoin
        lastMeetingStatus
        meetingData
        userAgent
        userAgentSummary
        micStatus
        cameraStatus
        networkQuality
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        profileDevicesId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listProfileDevicesByNetworkQuality = /* GraphQL */ `
  query ListProfileDevicesByNetworkQuality(
    $networkQuality: String!
    $lastMeetingJoin: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProfileDeviceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfileDevicesByNetworkQuality(
      networkQuality: $networkQuality
      lastMeetingJoin: $lastMeetingJoin
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        deviceHash
        hasWebcam
        hasMicrophone
        isScreenCapturingSupported
        isWebRTCSupported
        isWebsiteHasMicrophonePermissions
        isWebsiteHasWebcamPermissions
        isWebSocketsSupported
        clockOffset
        lastSeen
        lastLogin
        lastMeetingId
        lastMeetingInviteId
        lastMeetingJoin
        lastMeetingStatus
        meetingData
        userAgent
        userAgentSummary
        micStatus
        cameraStatus
        networkQuality
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        profileDevicesId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listProfileDevicesByProfileId = /* GraphQL */ `
  query ListProfileDevicesByProfileId(
    $profileId: ID!
    $deviceHash: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProfileDeviceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfileDevicesByProfileId(
      profileId: $profileId
      deviceHash: $deviceHash
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        deviceHash
        hasWebcam
        hasMicrophone
        isScreenCapturingSupported
        isWebRTCSupported
        isWebsiteHasMicrophonePermissions
        isWebsiteHasWebcamPermissions
        isWebSocketsSupported
        clockOffset
        lastSeen
        lastLogin
        lastMeetingId
        lastMeetingInviteId
        lastMeetingJoin
        lastMeetingStatus
        meetingData
        userAgent
        userAgentSummary
        micStatus
        cameraStatus
        networkQuality
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        profileDevicesId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getTeamImage = /* GraphQL */ `
  query GetTeamImage($id: ID!) {
    getTeamImage(id: $id) {
      id
      title
      alt
      width
      height
      image {
        bucket
        region
        key
        __typename
      }
      team {
        id
        name
        nameLowerCase
        description
        memberRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingRelationships {
          nextToken
          startedAt
          __typename
        }
        products {
          nextToken
          startedAt
          __typename
        }
        labels
        privacyEnabled
        privacyEnableOtherTeams
        images {
          nextToken
          startedAt
          __typename
        }
        transcriptions {
          nextToken
          startedAt
          __typename
        }
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      teamId
      isDefault
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      teamImagesId
      __typename
    }
  }
`;
export const listTeamImages = /* GraphQL */ `
  query ListTeamImages(
    $filter: ModelTeamImageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeamImages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        alt
        width
        height
        image {
          bucket
          region
          key
          __typename
        }
        team {
          id
          name
          nameLowerCase
          description
          labels
          privacyEnabled
          privacyEnableOtherTeams
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        teamId
        isDefault
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        teamImagesId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncTeamImages = /* GraphQL */ `
  query SyncTeamImages(
    $filter: ModelTeamImageFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTeamImages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        alt
        width
        height
        image {
          bucket
          region
          key
          __typename
        }
        team {
          id
          name
          nameLowerCase
          description
          labels
          privacyEnabled
          privacyEnableOtherTeams
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        teamId
        isDefault
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        teamImagesId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listTeamImagesByTeamId = /* GraphQL */ `
  query ListTeamImagesByTeamId(
    $teamId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTeamImageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeamImagesByTeamId(
      teamId: $teamId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        alt
        width
        height
        image {
          bucket
          region
          key
          __typename
        }
        team {
          id
          name
          nameLowerCase
          description
          labels
          privacyEnabled
          privacyEnableOtherTeams
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        teamId
        isDefault
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        teamImagesId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getTeam = /* GraphQL */ `
  query GetTeam($id: ID!) {
    getTeam(id: $id) {
      id
      name
      nameLowerCase
      description
      memberRelationships {
        items {
          id
          teamId
          memberProfileId
          relationship
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileTeamRelationshipsId
          teamMemberRelationshipsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      meetingRelationships {
        items {
          id
          teamId
          meetingId
          relationshipType
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          teamMeetingRelationshipsId
          meetingTeamRelationshipsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      products {
        items {
          id
          name
          nameLowerCase
          description
          serviceInformation
          organizationId
          salesTeamId
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProductsId
          teamProductsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      labels
      privacyEnabled
      privacyEnableOtherTeams
      images {
        items {
          id
          title
          alt
          width
          height
          teamId
          isDefault
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          teamImagesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      transcriptions {
        items {
          id
          date
          title
          description
          startDateTime
          endDateTime
          sizeInBytes
          numberOfTranscriptions
          isZipFile
          meetingId
          teamId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          teamTranscriptionsId
          meetingTranscriptionsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      owner
      sortByName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listTeams = /* GraphQL */ `
  query ListTeams(
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeams(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        nameLowerCase
        description
        memberRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingRelationships {
          nextToken
          startedAt
          __typename
        }
        products {
          nextToken
          startedAt
          __typename
        }
        labels
        privacyEnabled
        privacyEnableOtherTeams
        images {
          nextToken
          startedAt
          __typename
        }
        transcriptions {
          nextToken
          startedAt
          __typename
        }
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncTeams = /* GraphQL */ `
  query SyncTeams(
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTeams(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        nameLowerCase
        description
        memberRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingRelationships {
          nextToken
          startedAt
          __typename
        }
        products {
          nextToken
          startedAt
          __typename
        }
        labels
        privacyEnabled
        privacyEnableOtherTeams
        images {
          nextToken
          startedAt
          __typename
        }
        transcriptions {
          nextToken
          startedAt
          __typename
        }
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listTeamsByName = /* GraphQL */ `
  query ListTeamsByName(
    $name: String!
    $sortDirection: ModelSortDirection
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeamsByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        nameLowerCase
        description
        memberRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingRelationships {
          nextToken
          startedAt
          __typename
        }
        products {
          nextToken
          startedAt
          __typename
        }
        labels
        privacyEnabled
        privacyEnableOtherTeams
        images {
          nextToken
          startedAt
          __typename
        }
        transcriptions {
          nextToken
          startedAt
          __typename
        }
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listTeamsByNameLowerCase = /* GraphQL */ `
  query ListTeamsByNameLowerCase(
    $nameLowerCase: String!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeamsByNameLowerCase(
      nameLowerCase: $nameLowerCase
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        nameLowerCase
        description
        memberRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingRelationships {
          nextToken
          startedAt
          __typename
        }
        products {
          nextToken
          startedAt
          __typename
        }
        labels
        privacyEnabled
        privacyEnableOtherTeams
        images {
          nextToken
          startedAt
          __typename
        }
        transcriptions {
          nextToken
          startedAt
          __typename
        }
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listTeamsBySortByName = /* GraphQL */ `
  query ListTeamsBySortByName(
    $sortByName: Int!
    $nameLowerCase: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeamsBySortByName(
      sortByName: $sortByName
      nameLowerCase: $nameLowerCase
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        nameLowerCase
        description
        memberRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingRelationships {
          nextToken
          startedAt
          __typename
        }
        products {
          nextToken
          startedAt
          __typename
        }
        labels
        privacyEnabled
        privacyEnableOtherTeams
        images {
          nextToken
          startedAt
          __typename
        }
        transcriptions {
          nextToken
          startedAt
          __typename
        }
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getTeamMemberRelationship = /* GraphQL */ `
  query GetTeamMemberRelationship($id: ID!) {
    getTeamMemberRelationship(id: $id) {
      id
      team {
        id
        name
        nameLowerCase
        description
        memberRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingRelationships {
          nextToken
          startedAt
          __typename
        }
        products {
          nextToken
          startedAt
          __typename
        }
        labels
        privacyEnabled
        privacyEnableOtherTeams
        images {
          nextToken
          startedAt
          __typename
        }
        transcriptions {
          nextToken
          startedAt
          __typename
        }
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      teamId
      member {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      memberProfileId
      relationship
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      profileTeamRelationshipsId
      teamMemberRelationshipsId
      __typename
    }
  }
`;
export const listTeamMemberRelationships = /* GraphQL */ `
  query ListTeamMemberRelationships(
    $filter: ModelTeamMemberRelationshipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeamMemberRelationships(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        team {
          id
          name
          nameLowerCase
          description
          labels
          privacyEnabled
          privacyEnableOtherTeams
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        teamId
        member {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        memberProfileId
        relationship
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        profileTeamRelationshipsId
        teamMemberRelationshipsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncTeamMemberRelationships = /* GraphQL */ `
  query SyncTeamMemberRelationships(
    $filter: ModelTeamMemberRelationshipFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTeamMemberRelationships(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        team {
          id
          name
          nameLowerCase
          description
          labels
          privacyEnabled
          privacyEnableOtherTeams
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        teamId
        member {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        memberProfileId
        relationship
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        profileTeamRelationshipsId
        teamMemberRelationshipsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listTeamMemberRelationshipsByTeamId = /* GraphQL */ `
  query ListTeamMemberRelationshipsByTeamId(
    $teamId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTeamMemberRelationshipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeamMemberRelationshipsByTeamId(
      teamId: $teamId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        team {
          id
          name
          nameLowerCase
          description
          labels
          privacyEnabled
          privacyEnableOtherTeams
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        teamId
        member {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        memberProfileId
        relationship
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        profileTeamRelationshipsId
        teamMemberRelationshipsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listTeamMemberRelationshipsByMemberProfileId = /* GraphQL */ `
  query ListTeamMemberRelationshipsByMemberProfileId(
    $memberProfileId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTeamMemberRelationshipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeamMemberRelationshipsByMemberProfileId(
      memberProfileId: $memberProfileId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        team {
          id
          name
          nameLowerCase
          description
          labels
          privacyEnabled
          privacyEnableOtherTeams
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        teamId
        member {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        memberProfileId
        relationship
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        profileTeamRelationshipsId
        teamMemberRelationshipsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getMeetingRoomProvider = /* GraphQL */ `
  query GetMeetingRoomProvider($id: ID!) {
    getMeetingRoomProvider(id: $id) {
      id
      name
      componentName
      description
      configuration
      meetings {
        items {
          id
          title
          titleLowerCase
          startDateTime
          endDateTime
          status
          description
          preferredRoomProviderId
          actualStartDateTime
          actualEndDateTime
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          productId
          isReadinessTest
          labels
          owner
          sellerOrganizationName
          sellerTeamName
          buyerTeamName
          sellerOrganizationNameLowerCase
          sellerTeamNameLowerCase
          buyerTeamNameLowerCase
          externalId
          metrics
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productMeetingsId
          meetingRoomProviderMeetingsId
          meetingRoomId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      meetingRooms {
        items {
          id
          name
          meetingId
          providerId
          providerMeetingId
          accessCode
          status
          startDateTime
          endDateTime
          actualStartDateTime
          actualEndDateTime
          roomCreatedAt
          roomDestroyedAt
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          isReadinessTest
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          meetingRoomProviderMeetingRoomsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      meetingInvites {
        items {
          id
          meetingId
          providerId
          title
          startDateTime
          endDateTime
          status
          description
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          productId
          isReadinessTest
          meetingRoomId
          providerSessionId
          providerJoinData
          sessionStatus
          actualStartDateTime
          actualEndDateTime
          profileId
          owner
          meetingInvitationType
          teamId
          isInterested
          feedbackSummary
          contactRequestTimeframe
          contactPhoneNumber
          contactEmail
          sellerOrganizationName
          sellerTeamName
          buyerTeamName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productMeetingInvitesId
          profileMeetingInvitesId
          meetingRoomProviderMeetingInvitesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listMeetingRoomProviders = /* GraphQL */ `
  query ListMeetingRoomProviders(
    $filter: ModelMeetingRoomProviderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMeetingRoomProviders(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        componentName
        description
        configuration
        meetings {
          nextToken
          startedAt
          __typename
        }
        meetingRooms {
          nextToken
          startedAt
          __typename
        }
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncMeetingRoomProviders = /* GraphQL */ `
  query SyncMeetingRoomProviders(
    $filter: ModelMeetingRoomProviderFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMeetingRoomProviders(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        componentName
        description
        configuration
        meetings {
          nextToken
          startedAt
          __typename
        }
        meetingRooms {
          nextToken
          startedAt
          __typename
        }
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listMeetingRoomProvidersByComponentName = /* GraphQL */ `
  query ListMeetingRoomProvidersByComponentName(
    $componentName: String!
    $sortDirection: ModelSortDirection
    $filter: ModelMeetingRoomProviderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMeetingRoomProvidersByComponentName(
      componentName: $componentName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        componentName
        description
        configuration
        meetings {
          nextToken
          startedAt
          __typename
        }
        meetingRooms {
          nextToken
          startedAt
          __typename
        }
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getTeamMeetingRelationship = /* GraphQL */ `
  query GetTeamMeetingRelationship($id: ID!) {
    getTeamMeetingRelationship(id: $id) {
      id
      team {
        id
        name
        nameLowerCase
        description
        memberRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingRelationships {
          nextToken
          startedAt
          __typename
        }
        products {
          nextToken
          startedAt
          __typename
        }
        labels
        privacyEnabled
        privacyEnableOtherTeams
        images {
          nextToken
          startedAt
          __typename
        }
        transcriptions {
          nextToken
          startedAt
          __typename
        }
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      teamId
      meeting {
        id
        title
        titleLowerCase
        startDateTime
        endDateTime
        status
        description
        preferredRoomProvider {
          id
          name
          componentName
          description
          configuration
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        preferredRoomProviderId
        actualStartDateTime
        actualEndDateTime
        room {
          id
          name
          meetingId
          providerId
          providerMeetingId
          accessCode
          status
          startDateTime
          endDateTime
          actualStartDateTime
          actualEndDateTime
          roomCreatedAt
          roomDestroyedAt
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          isReadinessTest
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          meetingRoomProviderMeetingRoomsId
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        videoEnabled
        transcriptionEnabled
        product {
          id
          name
          nameLowerCase
          description
          serviceInformation
          organizationId
          salesTeamId
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProductsId
          teamProductsId
          __typename
        }
        productId
        isReadinessTest
        labels
        owner
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        transcriptions {
          nextToken
          startedAt
          __typename
        }
        sellerOrganizationName
        sellerTeamName
        buyerTeamName
        sellerOrganizationNameLowerCase
        sellerTeamNameLowerCase
        buyerTeamNameLowerCase
        externalId
        metrics
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productMeetingsId
        meetingRoomProviderMeetingsId
        meetingRoomId
        __typename
      }
      meetingId
      relationshipType
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      teamMeetingRelationshipsId
      meetingTeamRelationshipsId
      __typename
    }
  }
`;
export const listTeamMeetingRelationships = /* GraphQL */ `
  query ListTeamMeetingRelationships(
    $filter: ModelTeamMeetingRelationshipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeamMeetingRelationships(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        team {
          id
          name
          nameLowerCase
          description
          labels
          privacyEnabled
          privacyEnableOtherTeams
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        teamId
        meeting {
          id
          title
          titleLowerCase
          startDateTime
          endDateTime
          status
          description
          preferredRoomProviderId
          actualStartDateTime
          actualEndDateTime
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          productId
          isReadinessTest
          labels
          owner
          sellerOrganizationName
          sellerTeamName
          buyerTeamName
          sellerOrganizationNameLowerCase
          sellerTeamNameLowerCase
          buyerTeamNameLowerCase
          externalId
          metrics
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productMeetingsId
          meetingRoomProviderMeetingsId
          meetingRoomId
          __typename
        }
        meetingId
        relationshipType
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        teamMeetingRelationshipsId
        meetingTeamRelationshipsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncTeamMeetingRelationships = /* GraphQL */ `
  query SyncTeamMeetingRelationships(
    $filter: ModelTeamMeetingRelationshipFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTeamMeetingRelationships(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        team {
          id
          name
          nameLowerCase
          description
          labels
          privacyEnabled
          privacyEnableOtherTeams
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        teamId
        meeting {
          id
          title
          titleLowerCase
          startDateTime
          endDateTime
          status
          description
          preferredRoomProviderId
          actualStartDateTime
          actualEndDateTime
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          productId
          isReadinessTest
          labels
          owner
          sellerOrganizationName
          sellerTeamName
          buyerTeamName
          sellerOrganizationNameLowerCase
          sellerTeamNameLowerCase
          buyerTeamNameLowerCase
          externalId
          metrics
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productMeetingsId
          meetingRoomProviderMeetingsId
          meetingRoomId
          __typename
        }
        meetingId
        relationshipType
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        teamMeetingRelationshipsId
        meetingTeamRelationshipsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listTeamMeetingRelationshipsByTeamId = /* GraphQL */ `
  query ListTeamMeetingRelationshipsByTeamId(
    $teamId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTeamMeetingRelationshipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeamMeetingRelationshipsByTeamId(
      teamId: $teamId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        team {
          id
          name
          nameLowerCase
          description
          labels
          privacyEnabled
          privacyEnableOtherTeams
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        teamId
        meeting {
          id
          title
          titleLowerCase
          startDateTime
          endDateTime
          status
          description
          preferredRoomProviderId
          actualStartDateTime
          actualEndDateTime
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          productId
          isReadinessTest
          labels
          owner
          sellerOrganizationName
          sellerTeamName
          buyerTeamName
          sellerOrganizationNameLowerCase
          sellerTeamNameLowerCase
          buyerTeamNameLowerCase
          externalId
          metrics
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productMeetingsId
          meetingRoomProviderMeetingsId
          meetingRoomId
          __typename
        }
        meetingId
        relationshipType
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        teamMeetingRelationshipsId
        meetingTeamRelationshipsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listTeamMeetingRelationshipsByMeetingId = /* GraphQL */ `
  query ListTeamMeetingRelationshipsByMeetingId(
    $meetingId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTeamMeetingRelationshipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeamMeetingRelationshipsByMeetingId(
      meetingId: $meetingId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        team {
          id
          name
          nameLowerCase
          description
          labels
          privacyEnabled
          privacyEnableOtherTeams
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        teamId
        meeting {
          id
          title
          titleLowerCase
          startDateTime
          endDateTime
          status
          description
          preferredRoomProviderId
          actualStartDateTime
          actualEndDateTime
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          productId
          isReadinessTest
          labels
          owner
          sellerOrganizationName
          sellerTeamName
          buyerTeamName
          sellerOrganizationNameLowerCase
          sellerTeamNameLowerCase
          buyerTeamNameLowerCase
          externalId
          metrics
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productMeetingsId
          meetingRoomProviderMeetingsId
          meetingRoomId
          __typename
        }
        meetingId
        relationshipType
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        teamMeetingRelationshipsId
        meetingTeamRelationshipsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listTeamMeetingRelationshipsByMeetingIdAndRelationshipType = /* GraphQL */ `
  query ListTeamMeetingRelationshipsByMeetingIdAndRelationshipType(
    $meetingId: ID!
    $relationshipType: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTeamMeetingRelationshipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeamMeetingRelationshipsByMeetingIdAndRelationshipType(
      meetingId: $meetingId
      relationshipType: $relationshipType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        team {
          id
          name
          nameLowerCase
          description
          labels
          privacyEnabled
          privacyEnableOtherTeams
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        teamId
        meeting {
          id
          title
          titleLowerCase
          startDateTime
          endDateTime
          status
          description
          preferredRoomProviderId
          actualStartDateTime
          actualEndDateTime
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          productId
          isReadinessTest
          labels
          owner
          sellerOrganizationName
          sellerTeamName
          buyerTeamName
          sellerOrganizationNameLowerCase
          sellerTeamNameLowerCase
          buyerTeamNameLowerCase
          externalId
          metrics
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productMeetingsId
          meetingRoomProviderMeetingsId
          meetingRoomId
          __typename
        }
        meetingId
        relationshipType
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        teamMeetingRelationshipsId
        meetingTeamRelationshipsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listTeamMeetingRelationshipsByRelationshipType = /* GraphQL */ `
  query ListTeamMeetingRelationshipsByRelationshipType(
    $relationshipType: TeamMeetingRelationshipType!
    $sortDirection: ModelSortDirection
    $filter: ModelTeamMeetingRelationshipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeamMeetingRelationshipsByRelationshipType(
      relationshipType: $relationshipType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        team {
          id
          name
          nameLowerCase
          description
          labels
          privacyEnabled
          privacyEnableOtherTeams
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        teamId
        meeting {
          id
          title
          titleLowerCase
          startDateTime
          endDateTime
          status
          description
          preferredRoomProviderId
          actualStartDateTime
          actualEndDateTime
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          productId
          isReadinessTest
          labels
          owner
          sellerOrganizationName
          sellerTeamName
          buyerTeamName
          sellerOrganizationNameLowerCase
          sellerTeamNameLowerCase
          buyerTeamNameLowerCase
          externalId
          metrics
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productMeetingsId
          meetingRoomProviderMeetingsId
          meetingRoomId
          __typename
        }
        meetingId
        relationshipType
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        teamMeetingRelationshipsId
        meetingTeamRelationshipsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getMeetingAttendee = /* GraphQL */ `
  query GetMeetingAttendee($id: ID!) {
    getMeetingAttendee(id: $id) {
      id
      profile {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      profileId
      meeting {
        id
        title
        titleLowerCase
        startDateTime
        endDateTime
        status
        description
        preferredRoomProvider {
          id
          name
          componentName
          description
          configuration
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        preferredRoomProviderId
        actualStartDateTime
        actualEndDateTime
        room {
          id
          name
          meetingId
          providerId
          providerMeetingId
          accessCode
          status
          startDateTime
          endDateTime
          actualStartDateTime
          actualEndDateTime
          roomCreatedAt
          roomDestroyedAt
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          isReadinessTest
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          meetingRoomProviderMeetingRoomsId
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        videoEnabled
        transcriptionEnabled
        product {
          id
          name
          nameLowerCase
          description
          serviceInformation
          organizationId
          salesTeamId
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProductsId
          teamProductsId
          __typename
        }
        productId
        isReadinessTest
        labels
        owner
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        transcriptions {
          nextToken
          startedAt
          __typename
        }
        sellerOrganizationName
        sellerTeamName
        buyerTeamName
        sellerOrganizationNameLowerCase
        sellerTeamNameLowerCase
        buyerTeamNameLowerCase
        externalId
        metrics
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productMeetingsId
        meetingRoomProviderMeetingsId
        meetingRoomId
        __typename
      }
      meetingId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      profileMeetingAttendeesId
      meetingMeetingAttendeesId
      __typename
    }
  }
`;
export const listMeetingAttendees = /* GraphQL */ `
  query ListMeetingAttendees(
    $filter: ModelMeetingAttendeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMeetingAttendees(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        meeting {
          id
          title
          titleLowerCase
          startDateTime
          endDateTime
          status
          description
          preferredRoomProviderId
          actualStartDateTime
          actualEndDateTime
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          productId
          isReadinessTest
          labels
          owner
          sellerOrganizationName
          sellerTeamName
          buyerTeamName
          sellerOrganizationNameLowerCase
          sellerTeamNameLowerCase
          buyerTeamNameLowerCase
          externalId
          metrics
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productMeetingsId
          meetingRoomProviderMeetingsId
          meetingRoomId
          __typename
        }
        meetingId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        profileMeetingAttendeesId
        meetingMeetingAttendeesId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncMeetingAttendees = /* GraphQL */ `
  query SyncMeetingAttendees(
    $filter: ModelMeetingAttendeeFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMeetingAttendees(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        meeting {
          id
          title
          titleLowerCase
          startDateTime
          endDateTime
          status
          description
          preferredRoomProviderId
          actualStartDateTime
          actualEndDateTime
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          productId
          isReadinessTest
          labels
          owner
          sellerOrganizationName
          sellerTeamName
          buyerTeamName
          sellerOrganizationNameLowerCase
          sellerTeamNameLowerCase
          buyerTeamNameLowerCase
          externalId
          metrics
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productMeetingsId
          meetingRoomProviderMeetingsId
          meetingRoomId
          __typename
        }
        meetingId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        profileMeetingAttendeesId
        meetingMeetingAttendeesId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listMeetingAttendeesByProfileId = /* GraphQL */ `
  query ListMeetingAttendeesByProfileId(
    $profileId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMeetingAttendeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMeetingAttendeesByProfileId(
      profileId: $profileId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        meeting {
          id
          title
          titleLowerCase
          startDateTime
          endDateTime
          status
          description
          preferredRoomProviderId
          actualStartDateTime
          actualEndDateTime
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          productId
          isReadinessTest
          labels
          owner
          sellerOrganizationName
          sellerTeamName
          buyerTeamName
          sellerOrganizationNameLowerCase
          sellerTeamNameLowerCase
          buyerTeamNameLowerCase
          externalId
          metrics
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productMeetingsId
          meetingRoomProviderMeetingsId
          meetingRoomId
          __typename
        }
        meetingId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        profileMeetingAttendeesId
        meetingMeetingAttendeesId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listMeetingAttendeesByMeetingId = /* GraphQL */ `
  query ListMeetingAttendeesByMeetingId(
    $meetingId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMeetingAttendeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMeetingAttendeesByMeetingId(
      meetingId: $meetingId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        meeting {
          id
          title
          titleLowerCase
          startDateTime
          endDateTime
          status
          description
          preferredRoomProviderId
          actualStartDateTime
          actualEndDateTime
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          productId
          isReadinessTest
          labels
          owner
          sellerOrganizationName
          sellerTeamName
          buyerTeamName
          sellerOrganizationNameLowerCase
          sellerTeamNameLowerCase
          buyerTeamNameLowerCase
          externalId
          metrics
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productMeetingsId
          meetingRoomProviderMeetingsId
          meetingRoomId
          __typename
        }
        meetingId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        profileMeetingAttendeesId
        meetingMeetingAttendeesId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getMeeting = /* GraphQL */ `
  query GetMeeting($id: ID!) {
    getMeeting(id: $id) {
      id
      title
      titleLowerCase
      startDateTime
      endDateTime
      status
      description
      preferredRoomProvider {
        id
        name
        componentName
        description
        configuration
        meetings {
          nextToken
          startedAt
          __typename
        }
        meetingRooms {
          nextToken
          startedAt
          __typename
        }
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      preferredRoomProviderId
      actualStartDateTime
      actualEndDateTime
      room {
        id
        name
        meeting {
          id
          title
          titleLowerCase
          startDateTime
          endDateTime
          status
          description
          preferredRoomProviderId
          actualStartDateTime
          actualEndDateTime
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          productId
          isReadinessTest
          labels
          owner
          sellerOrganizationName
          sellerTeamName
          buyerTeamName
          sellerOrganizationNameLowerCase
          sellerTeamNameLowerCase
          buyerTeamNameLowerCase
          externalId
          metrics
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productMeetingsId
          meetingRoomProviderMeetingsId
          meetingRoomId
          __typename
        }
        meetingId
        provider {
          id
          name
          componentName
          description
          configuration
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        providerId
        providerMeetingId
        accessCode
        status
        startDateTime
        endDateTime
        actualStartDateTime
        actualEndDateTime
        roomCreatedAt
        roomDestroyedAt
        auditlogs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        videoEnabled
        transcriptionEnabled
        isReadinessTest
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        meetingRoomProviderMeetingRoomsId
        __typename
      }
      questionResponses {
        items {
          id
          response
          privateNotes
          questionId
          profileId
          meetingId
          meetingInviteId
          owner
          contactRequestTimeframe
          contactRequestDateTime
          contactPhoneNumber
          contactEmail
          contactName
          providePersonalEmail
          providePersonalPhoneNumber
          textOnly
          followupMeetingRequested
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productQuestionResponsesId
          profileQuestionResponsesId
          meetingQuestionResponsesId
          meetingInviteQuestionResponsesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      privacyEnabled
      videoEnabled
      transcriptionEnabled
      product {
        id
        name
        nameLowerCase
        description
        serviceInformation
        images {
          nextToken
          startedAt
          __typename
        }
        questions {
          nextToken
          startedAt
          __typename
        }
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        salesTeam {
          id
          name
          nameLowerCase
          description
          labels
          privacyEnabled
          privacyEnableOtherTeams
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        salesTeamId
        meetings {
          nextToken
          startedAt
          __typename
        }
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        labels
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProductsId
        teamProductsId
        __typename
      }
      productId
      isReadinessTest
      labels
      owner
      teamRelationships {
        items {
          id
          teamId
          meetingId
          relationshipType
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          teamMeetingRelationshipsId
          meetingTeamRelationshipsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      meetingAttendees {
        items {
          id
          profileId
          meetingId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileMeetingAttendeesId
          meetingMeetingAttendeesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      transcriptions {
        items {
          id
          date
          title
          description
          startDateTime
          endDateTime
          sizeInBytes
          numberOfTranscriptions
          isZipFile
          meetingId
          teamId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          teamTranscriptionsId
          meetingTranscriptionsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      sellerOrganizationName
      sellerTeamName
      buyerTeamName
      sellerOrganizationNameLowerCase
      sellerTeamNameLowerCase
      buyerTeamNameLowerCase
      externalId
      metrics
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      productMeetingsId
      meetingRoomProviderMeetingsId
      meetingRoomId
      __typename
    }
  }
`;
export const listMeetings = /* GraphQL */ `
  query ListMeetings(
    $filter: ModelMeetingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMeetings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        titleLowerCase
        startDateTime
        endDateTime
        status
        description
        preferredRoomProvider {
          id
          name
          componentName
          description
          configuration
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        preferredRoomProviderId
        actualStartDateTime
        actualEndDateTime
        room {
          id
          name
          meetingId
          providerId
          providerMeetingId
          accessCode
          status
          startDateTime
          endDateTime
          actualStartDateTime
          actualEndDateTime
          roomCreatedAt
          roomDestroyedAt
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          isReadinessTest
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          meetingRoomProviderMeetingRoomsId
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        videoEnabled
        transcriptionEnabled
        product {
          id
          name
          nameLowerCase
          description
          serviceInformation
          organizationId
          salesTeamId
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProductsId
          teamProductsId
          __typename
        }
        productId
        isReadinessTest
        labels
        owner
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        transcriptions {
          nextToken
          startedAt
          __typename
        }
        sellerOrganizationName
        sellerTeamName
        buyerTeamName
        sellerOrganizationNameLowerCase
        sellerTeamNameLowerCase
        buyerTeamNameLowerCase
        externalId
        metrics
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productMeetingsId
        meetingRoomProviderMeetingsId
        meetingRoomId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncMeetings = /* GraphQL */ `
  query SyncMeetings(
    $filter: ModelMeetingFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMeetings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        titleLowerCase
        startDateTime
        endDateTime
        status
        description
        preferredRoomProvider {
          id
          name
          componentName
          description
          configuration
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        preferredRoomProviderId
        actualStartDateTime
        actualEndDateTime
        room {
          id
          name
          meetingId
          providerId
          providerMeetingId
          accessCode
          status
          startDateTime
          endDateTime
          actualStartDateTime
          actualEndDateTime
          roomCreatedAt
          roomDestroyedAt
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          isReadinessTest
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          meetingRoomProviderMeetingRoomsId
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        videoEnabled
        transcriptionEnabled
        product {
          id
          name
          nameLowerCase
          description
          serviceInformation
          organizationId
          salesTeamId
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProductsId
          teamProductsId
          __typename
        }
        productId
        isReadinessTest
        labels
        owner
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        transcriptions {
          nextToken
          startedAt
          __typename
        }
        sellerOrganizationName
        sellerTeamName
        buyerTeamName
        sellerOrganizationNameLowerCase
        sellerTeamNameLowerCase
        buyerTeamNameLowerCase
        externalId
        metrics
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productMeetingsId
        meetingRoomProviderMeetingsId
        meetingRoomId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listMeetingsByTitle = /* GraphQL */ `
  query ListMeetingsByTitle(
    $title: String!
    $startDateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMeetingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMeetingsByTitle(
      title: $title
      startDateTime: $startDateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        titleLowerCase
        startDateTime
        endDateTime
        status
        description
        preferredRoomProvider {
          id
          name
          componentName
          description
          configuration
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        preferredRoomProviderId
        actualStartDateTime
        actualEndDateTime
        room {
          id
          name
          meetingId
          providerId
          providerMeetingId
          accessCode
          status
          startDateTime
          endDateTime
          actualStartDateTime
          actualEndDateTime
          roomCreatedAt
          roomDestroyedAt
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          isReadinessTest
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          meetingRoomProviderMeetingRoomsId
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        videoEnabled
        transcriptionEnabled
        product {
          id
          name
          nameLowerCase
          description
          serviceInformation
          organizationId
          salesTeamId
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProductsId
          teamProductsId
          __typename
        }
        productId
        isReadinessTest
        labels
        owner
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        transcriptions {
          nextToken
          startedAt
          __typename
        }
        sellerOrganizationName
        sellerTeamName
        buyerTeamName
        sellerOrganizationNameLowerCase
        sellerTeamNameLowerCase
        buyerTeamNameLowerCase
        externalId
        metrics
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productMeetingsId
        meetingRoomProviderMeetingsId
        meetingRoomId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listMeetingsByTitleLowerCase = /* GraphQL */ `
  query ListMeetingsByTitleLowerCase(
    $titleLowerCase: String!
    $title: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMeetingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMeetingsByTitleLowerCase(
      titleLowerCase: $titleLowerCase
      title: $title
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        titleLowerCase
        startDateTime
        endDateTime
        status
        description
        preferredRoomProvider {
          id
          name
          componentName
          description
          configuration
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        preferredRoomProviderId
        actualStartDateTime
        actualEndDateTime
        room {
          id
          name
          meetingId
          providerId
          providerMeetingId
          accessCode
          status
          startDateTime
          endDateTime
          actualStartDateTime
          actualEndDateTime
          roomCreatedAt
          roomDestroyedAt
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          isReadinessTest
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          meetingRoomProviderMeetingRoomsId
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        videoEnabled
        transcriptionEnabled
        product {
          id
          name
          nameLowerCase
          description
          serviceInformation
          organizationId
          salesTeamId
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProductsId
          teamProductsId
          __typename
        }
        productId
        isReadinessTest
        labels
        owner
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        transcriptions {
          nextToken
          startedAt
          __typename
        }
        sellerOrganizationName
        sellerTeamName
        buyerTeamName
        sellerOrganizationNameLowerCase
        sellerTeamNameLowerCase
        buyerTeamNameLowerCase
        externalId
        metrics
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productMeetingsId
        meetingRoomProviderMeetingsId
        meetingRoomId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listMeetingsByStatus = /* GraphQL */ `
  query ListMeetingsByStatus(
    $status: MeetingStatus!
    $startDateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMeetingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMeetingsByStatus(
      status: $status
      startDateTime: $startDateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        titleLowerCase
        startDateTime
        endDateTime
        status
        description
        preferredRoomProvider {
          id
          name
          componentName
          description
          configuration
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        preferredRoomProviderId
        actualStartDateTime
        actualEndDateTime
        room {
          id
          name
          meetingId
          providerId
          providerMeetingId
          accessCode
          status
          startDateTime
          endDateTime
          actualStartDateTime
          actualEndDateTime
          roomCreatedAt
          roomDestroyedAt
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          isReadinessTest
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          meetingRoomProviderMeetingRoomsId
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        videoEnabled
        transcriptionEnabled
        product {
          id
          name
          nameLowerCase
          description
          serviceInformation
          organizationId
          salesTeamId
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProductsId
          teamProductsId
          __typename
        }
        productId
        isReadinessTest
        labels
        owner
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        transcriptions {
          nextToken
          startedAt
          __typename
        }
        sellerOrganizationName
        sellerTeamName
        buyerTeamName
        sellerOrganizationNameLowerCase
        sellerTeamNameLowerCase
        buyerTeamNameLowerCase
        externalId
        metrics
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productMeetingsId
        meetingRoomProviderMeetingsId
        meetingRoomId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listMeetingsByPreferredRoomProviderId = /* GraphQL */ `
  query ListMeetingsByPreferredRoomProviderId(
    $preferredRoomProviderId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMeetingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMeetingsByPreferredRoomProviderId(
      preferredRoomProviderId: $preferredRoomProviderId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        titleLowerCase
        startDateTime
        endDateTime
        status
        description
        preferredRoomProvider {
          id
          name
          componentName
          description
          configuration
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        preferredRoomProviderId
        actualStartDateTime
        actualEndDateTime
        room {
          id
          name
          meetingId
          providerId
          providerMeetingId
          accessCode
          status
          startDateTime
          endDateTime
          actualStartDateTime
          actualEndDateTime
          roomCreatedAt
          roomDestroyedAt
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          isReadinessTest
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          meetingRoomProviderMeetingRoomsId
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        videoEnabled
        transcriptionEnabled
        product {
          id
          name
          nameLowerCase
          description
          serviceInformation
          organizationId
          salesTeamId
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProductsId
          teamProductsId
          __typename
        }
        productId
        isReadinessTest
        labels
        owner
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        transcriptions {
          nextToken
          startedAt
          __typename
        }
        sellerOrganizationName
        sellerTeamName
        buyerTeamName
        sellerOrganizationNameLowerCase
        sellerTeamNameLowerCase
        buyerTeamNameLowerCase
        externalId
        metrics
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productMeetingsId
        meetingRoomProviderMeetingsId
        meetingRoomId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listMeetingsByProductId = /* GraphQL */ `
  query ListMeetingsByProductId(
    $productId: ID!
    $startDateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMeetingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMeetingsByProductId(
      productId: $productId
      startDateTime: $startDateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        titleLowerCase
        startDateTime
        endDateTime
        status
        description
        preferredRoomProvider {
          id
          name
          componentName
          description
          configuration
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        preferredRoomProviderId
        actualStartDateTime
        actualEndDateTime
        room {
          id
          name
          meetingId
          providerId
          providerMeetingId
          accessCode
          status
          startDateTime
          endDateTime
          actualStartDateTime
          actualEndDateTime
          roomCreatedAt
          roomDestroyedAt
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          isReadinessTest
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          meetingRoomProviderMeetingRoomsId
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        videoEnabled
        transcriptionEnabled
        product {
          id
          name
          nameLowerCase
          description
          serviceInformation
          organizationId
          salesTeamId
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProductsId
          teamProductsId
          __typename
        }
        productId
        isReadinessTest
        labels
        owner
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        transcriptions {
          nextToken
          startedAt
          __typename
        }
        sellerOrganizationName
        sellerTeamName
        buyerTeamName
        sellerOrganizationNameLowerCase
        sellerTeamNameLowerCase
        buyerTeamNameLowerCase
        externalId
        metrics
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productMeetingsId
        meetingRoomProviderMeetingsId
        meetingRoomId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listMeetingsByExternalId = /* GraphQL */ `
  query ListMeetingsByExternalId(
    $externalId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelMeetingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMeetingsByExternalId(
      externalId: $externalId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        titleLowerCase
        startDateTime
        endDateTime
        status
        description
        preferredRoomProvider {
          id
          name
          componentName
          description
          configuration
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        preferredRoomProviderId
        actualStartDateTime
        actualEndDateTime
        room {
          id
          name
          meetingId
          providerId
          providerMeetingId
          accessCode
          status
          startDateTime
          endDateTime
          actualStartDateTime
          actualEndDateTime
          roomCreatedAt
          roomDestroyedAt
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          isReadinessTest
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          meetingRoomProviderMeetingRoomsId
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        videoEnabled
        transcriptionEnabled
        product {
          id
          name
          nameLowerCase
          description
          serviceInformation
          organizationId
          salesTeamId
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProductsId
          teamProductsId
          __typename
        }
        productId
        isReadinessTest
        labels
        owner
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        transcriptions {
          nextToken
          startedAt
          __typename
        }
        sellerOrganizationName
        sellerTeamName
        buyerTeamName
        sellerOrganizationNameLowerCase
        sellerTeamNameLowerCase
        buyerTeamNameLowerCase
        externalId
        metrics
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productMeetingsId
        meetingRoomProviderMeetingsId
        meetingRoomId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getMeetingInvite = /* GraphQL */ `
  query GetMeetingInvite($id: ID!) {
    getMeetingInvite(id: $id) {
      id
      meetingId
      provider {
        id
        name
        componentName
        description
        configuration
        meetings {
          nextToken
          startedAt
          __typename
        }
        meetingRooms {
          nextToken
          startedAt
          __typename
        }
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      providerId
      title
      startDateTime
      endDateTime
      status
      description
      questionResponses {
        items {
          id
          response
          privateNotes
          questionId
          profileId
          meetingId
          meetingInviteId
          owner
          contactRequestTimeframe
          contactRequestDateTime
          contactPhoneNumber
          contactEmail
          contactName
          providePersonalEmail
          providePersonalPhoneNumber
          textOnly
          followupMeetingRequested
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productQuestionResponsesId
          profileQuestionResponsesId
          meetingQuestionResponsesId
          meetingInviteQuestionResponsesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      privacyEnabled
      videoEnabled
      transcriptionEnabled
      product {
        id
        name
        nameLowerCase
        description
        serviceInformation
        images {
          nextToken
          startedAt
          __typename
        }
        questions {
          nextToken
          startedAt
          __typename
        }
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        salesTeam {
          id
          name
          nameLowerCase
          description
          labels
          privacyEnabled
          privacyEnableOtherTeams
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        salesTeamId
        meetings {
          nextToken
          startedAt
          __typename
        }
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        labels
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProductsId
        teamProductsId
        __typename
      }
      productId
      isReadinessTest
      meetingRoomId
      providerSessionId
      providerJoinData
      sessionStatus
      actualStartDateTime
      actualEndDateTime
      profile {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      profileId
      owner
      meetingInvitationType
      teamId
      isInterested
      feedbackSummary
      contactRequestTimeframe
      contactPhoneNumber
      contactEmail
      sellerOrganizationName
      sellerTeamName
      buyerTeamName
      transcriptionLogs {
        items {
          id
          date
          author
          resource
          action
          payload
          ipAddress
          meetingInviteId
          profileId
          authorProfileId
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileTranscriptionLogsId
          meetingInviteTranscriptionLogsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      productMeetingInvitesId
      profileMeetingInvitesId
      meetingRoomProviderMeetingInvitesId
      __typename
    }
  }
`;
export const listMeetingInvites = /* GraphQL */ `
  query ListMeetingInvites(
    $filter: ModelMeetingInviteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMeetingInvites(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        meetingId
        provider {
          id
          name
          componentName
          description
          configuration
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        providerId
        title
        startDateTime
        endDateTime
        status
        description
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        videoEnabled
        transcriptionEnabled
        product {
          id
          name
          nameLowerCase
          description
          serviceInformation
          organizationId
          salesTeamId
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProductsId
          teamProductsId
          __typename
        }
        productId
        isReadinessTest
        meetingRoomId
        providerSessionId
        providerJoinData
        sessionStatus
        actualStartDateTime
        actualEndDateTime
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        owner
        meetingInvitationType
        teamId
        isInterested
        feedbackSummary
        contactRequestTimeframe
        contactPhoneNumber
        contactEmail
        sellerOrganizationName
        sellerTeamName
        buyerTeamName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productMeetingInvitesId
        profileMeetingInvitesId
        meetingRoomProviderMeetingInvitesId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncMeetingInvites = /* GraphQL */ `
  query SyncMeetingInvites(
    $filter: ModelMeetingInviteFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMeetingInvites(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        meetingId
        provider {
          id
          name
          componentName
          description
          configuration
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        providerId
        title
        startDateTime
        endDateTime
        status
        description
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        videoEnabled
        transcriptionEnabled
        product {
          id
          name
          nameLowerCase
          description
          serviceInformation
          organizationId
          salesTeamId
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProductsId
          teamProductsId
          __typename
        }
        productId
        isReadinessTest
        meetingRoomId
        providerSessionId
        providerJoinData
        sessionStatus
        actualStartDateTime
        actualEndDateTime
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        owner
        meetingInvitationType
        teamId
        isInterested
        feedbackSummary
        contactRequestTimeframe
        contactPhoneNumber
        contactEmail
        sellerOrganizationName
        sellerTeamName
        buyerTeamName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productMeetingInvitesId
        profileMeetingInvitesId
        meetingRoomProviderMeetingInvitesId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listMeetingInvitesByMeetingId = /* GraphQL */ `
  query ListMeetingInvitesByMeetingId(
    $meetingId: ID!
    $profileId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMeetingInviteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMeetingInvitesByMeetingId(
      meetingId: $meetingId
      profileId: $profileId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        meetingId
        provider {
          id
          name
          componentName
          description
          configuration
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        providerId
        title
        startDateTime
        endDateTime
        status
        description
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        videoEnabled
        transcriptionEnabled
        product {
          id
          name
          nameLowerCase
          description
          serviceInformation
          organizationId
          salesTeamId
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProductsId
          teamProductsId
          __typename
        }
        productId
        isReadinessTest
        meetingRoomId
        providerSessionId
        providerJoinData
        sessionStatus
        actualStartDateTime
        actualEndDateTime
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        owner
        meetingInvitationType
        teamId
        isInterested
        feedbackSummary
        contactRequestTimeframe
        contactPhoneNumber
        contactEmail
        sellerOrganizationName
        sellerTeamName
        buyerTeamName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productMeetingInvitesId
        profileMeetingInvitesId
        meetingRoomProviderMeetingInvitesId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listMeetingInvitesByMeetingIdAndMeetingInvitationType = /* GraphQL */ `
  query ListMeetingInvitesByMeetingIdAndMeetingInvitationType(
    $meetingId: ID!
    $meetingInvitationType: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMeetingInviteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMeetingInvitesByMeetingIdAndMeetingInvitationType(
      meetingId: $meetingId
      meetingInvitationType: $meetingInvitationType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        meetingId
        provider {
          id
          name
          componentName
          description
          configuration
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        providerId
        title
        startDateTime
        endDateTime
        status
        description
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        videoEnabled
        transcriptionEnabled
        product {
          id
          name
          nameLowerCase
          description
          serviceInformation
          organizationId
          salesTeamId
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProductsId
          teamProductsId
          __typename
        }
        productId
        isReadinessTest
        meetingRoomId
        providerSessionId
        providerJoinData
        sessionStatus
        actualStartDateTime
        actualEndDateTime
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        owner
        meetingInvitationType
        teamId
        isInterested
        feedbackSummary
        contactRequestTimeframe
        contactPhoneNumber
        contactEmail
        sellerOrganizationName
        sellerTeamName
        buyerTeamName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productMeetingInvitesId
        profileMeetingInvitesId
        meetingRoomProviderMeetingInvitesId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listMeetingInvitesByProviderId = /* GraphQL */ `
  query ListMeetingInvitesByProviderId(
    $providerId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMeetingInviteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMeetingInvitesByProviderId(
      providerId: $providerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        meetingId
        provider {
          id
          name
          componentName
          description
          configuration
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        providerId
        title
        startDateTime
        endDateTime
        status
        description
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        videoEnabled
        transcriptionEnabled
        product {
          id
          name
          nameLowerCase
          description
          serviceInformation
          organizationId
          salesTeamId
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProductsId
          teamProductsId
          __typename
        }
        productId
        isReadinessTest
        meetingRoomId
        providerSessionId
        providerJoinData
        sessionStatus
        actualStartDateTime
        actualEndDateTime
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        owner
        meetingInvitationType
        teamId
        isInterested
        feedbackSummary
        contactRequestTimeframe
        contactPhoneNumber
        contactEmail
        sellerOrganizationName
        sellerTeamName
        buyerTeamName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productMeetingInvitesId
        profileMeetingInvitesId
        meetingRoomProviderMeetingInvitesId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listMeetingInvitesByTitle = /* GraphQL */ `
  query ListMeetingInvitesByTitle(
    $title: String!
    $startDateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMeetingInviteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMeetingInvitesByTitle(
      title: $title
      startDateTime: $startDateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        meetingId
        provider {
          id
          name
          componentName
          description
          configuration
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        providerId
        title
        startDateTime
        endDateTime
        status
        description
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        videoEnabled
        transcriptionEnabled
        product {
          id
          name
          nameLowerCase
          description
          serviceInformation
          organizationId
          salesTeamId
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProductsId
          teamProductsId
          __typename
        }
        productId
        isReadinessTest
        meetingRoomId
        providerSessionId
        providerJoinData
        sessionStatus
        actualStartDateTime
        actualEndDateTime
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        owner
        meetingInvitationType
        teamId
        isInterested
        feedbackSummary
        contactRequestTimeframe
        contactPhoneNumber
        contactEmail
        sellerOrganizationName
        sellerTeamName
        buyerTeamName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productMeetingInvitesId
        profileMeetingInvitesId
        meetingRoomProviderMeetingInvitesId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listMeetingInvitesByStatus = /* GraphQL */ `
  query ListMeetingInvitesByStatus(
    $status: MeetingStatus!
    $startDateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMeetingInviteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMeetingInvitesByStatus(
      status: $status
      startDateTime: $startDateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        meetingId
        provider {
          id
          name
          componentName
          description
          configuration
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        providerId
        title
        startDateTime
        endDateTime
        status
        description
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        videoEnabled
        transcriptionEnabled
        product {
          id
          name
          nameLowerCase
          description
          serviceInformation
          organizationId
          salesTeamId
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProductsId
          teamProductsId
          __typename
        }
        productId
        isReadinessTest
        meetingRoomId
        providerSessionId
        providerJoinData
        sessionStatus
        actualStartDateTime
        actualEndDateTime
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        owner
        meetingInvitationType
        teamId
        isInterested
        feedbackSummary
        contactRequestTimeframe
        contactPhoneNumber
        contactEmail
        sellerOrganizationName
        sellerTeamName
        buyerTeamName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productMeetingInvitesId
        profileMeetingInvitesId
        meetingRoomProviderMeetingInvitesId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listMeetingInvitesByProductId = /* GraphQL */ `
  query ListMeetingInvitesByProductId(
    $productId: ID!
    $startDateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMeetingInviteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMeetingInvitesByProductId(
      productId: $productId
      startDateTime: $startDateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        meetingId
        provider {
          id
          name
          componentName
          description
          configuration
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        providerId
        title
        startDateTime
        endDateTime
        status
        description
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        videoEnabled
        transcriptionEnabled
        product {
          id
          name
          nameLowerCase
          description
          serviceInformation
          organizationId
          salesTeamId
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProductsId
          teamProductsId
          __typename
        }
        productId
        isReadinessTest
        meetingRoomId
        providerSessionId
        providerJoinData
        sessionStatus
        actualStartDateTime
        actualEndDateTime
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        owner
        meetingInvitationType
        teamId
        isInterested
        feedbackSummary
        contactRequestTimeframe
        contactPhoneNumber
        contactEmail
        sellerOrganizationName
        sellerTeamName
        buyerTeamName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productMeetingInvitesId
        profileMeetingInvitesId
        meetingRoomProviderMeetingInvitesId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listMeetingInvitesByMeetingRoomId = /* GraphQL */ `
  query ListMeetingInvitesByMeetingRoomId(
    $meetingRoomId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMeetingInviteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMeetingInvitesByMeetingRoomId(
      meetingRoomId: $meetingRoomId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        meetingId
        provider {
          id
          name
          componentName
          description
          configuration
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        providerId
        title
        startDateTime
        endDateTime
        status
        description
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        videoEnabled
        transcriptionEnabled
        product {
          id
          name
          nameLowerCase
          description
          serviceInformation
          organizationId
          salesTeamId
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProductsId
          teamProductsId
          __typename
        }
        productId
        isReadinessTest
        meetingRoomId
        providerSessionId
        providerJoinData
        sessionStatus
        actualStartDateTime
        actualEndDateTime
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        owner
        meetingInvitationType
        teamId
        isInterested
        feedbackSummary
        contactRequestTimeframe
        contactPhoneNumber
        contactEmail
        sellerOrganizationName
        sellerTeamName
        buyerTeamName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productMeetingInvitesId
        profileMeetingInvitesId
        meetingRoomProviderMeetingInvitesId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listMeetingInvitesBySessionStatus = /* GraphQL */ `
  query ListMeetingInvitesBySessionStatus(
    $sessionStatus: MeetingRoomSessionStatus!
    $startDateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMeetingInviteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMeetingInvitesBySessionStatus(
      sessionStatus: $sessionStatus
      startDateTime: $startDateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        meetingId
        provider {
          id
          name
          componentName
          description
          configuration
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        providerId
        title
        startDateTime
        endDateTime
        status
        description
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        videoEnabled
        transcriptionEnabled
        product {
          id
          name
          nameLowerCase
          description
          serviceInformation
          organizationId
          salesTeamId
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProductsId
          teamProductsId
          __typename
        }
        productId
        isReadinessTest
        meetingRoomId
        providerSessionId
        providerJoinData
        sessionStatus
        actualStartDateTime
        actualEndDateTime
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        owner
        meetingInvitationType
        teamId
        isInterested
        feedbackSummary
        contactRequestTimeframe
        contactPhoneNumber
        contactEmail
        sellerOrganizationName
        sellerTeamName
        buyerTeamName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productMeetingInvitesId
        profileMeetingInvitesId
        meetingRoomProviderMeetingInvitesId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listMeetingInvitesByProfileId = /* GraphQL */ `
  query ListMeetingInvitesByProfileId(
    $profileId: ID!
    $startDateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMeetingInviteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMeetingInvitesByProfileId(
      profileId: $profileId
      startDateTime: $startDateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        meetingId
        provider {
          id
          name
          componentName
          description
          configuration
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        providerId
        title
        startDateTime
        endDateTime
        status
        description
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        videoEnabled
        transcriptionEnabled
        product {
          id
          name
          nameLowerCase
          description
          serviceInformation
          organizationId
          salesTeamId
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProductsId
          teamProductsId
          __typename
        }
        productId
        isReadinessTest
        meetingRoomId
        providerSessionId
        providerJoinData
        sessionStatus
        actualStartDateTime
        actualEndDateTime
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        owner
        meetingInvitationType
        teamId
        isInterested
        feedbackSummary
        contactRequestTimeframe
        contactPhoneNumber
        contactEmail
        sellerOrganizationName
        sellerTeamName
        buyerTeamName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productMeetingInvitesId
        profileMeetingInvitesId
        meetingRoomProviderMeetingInvitesId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listMeetingInvitesByProfileIdAndDates = /* GraphQL */ `
  query ListMeetingInvitesByProfileIdAndDates(
    $profileId: ID!
    $startDateTimeEndDateTime: ModelMeetingInviteMeetingInvitesByProfileIdAndDatesCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMeetingInviteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMeetingInvitesByProfileIdAndDates(
      profileId: $profileId
      startDateTimeEndDateTime: $startDateTimeEndDateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        meetingId
        provider {
          id
          name
          componentName
          description
          configuration
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        providerId
        title
        startDateTime
        endDateTime
        status
        description
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        videoEnabled
        transcriptionEnabled
        product {
          id
          name
          nameLowerCase
          description
          serviceInformation
          organizationId
          salesTeamId
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProductsId
          teamProductsId
          __typename
        }
        productId
        isReadinessTest
        meetingRoomId
        providerSessionId
        providerJoinData
        sessionStatus
        actualStartDateTime
        actualEndDateTime
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        owner
        meetingInvitationType
        teamId
        isInterested
        feedbackSummary
        contactRequestTimeframe
        contactPhoneNumber
        contactEmail
        sellerOrganizationName
        sellerTeamName
        buyerTeamName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productMeetingInvitesId
        profileMeetingInvitesId
        meetingRoomProviderMeetingInvitesId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listMeetingInvitesByTeamId = /* GraphQL */ `
  query ListMeetingInvitesByTeamId(
    $teamId: ID!
    $startDateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMeetingInviteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMeetingInvitesByTeamId(
      teamId: $teamId
      startDateTime: $startDateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        meetingId
        provider {
          id
          name
          componentName
          description
          configuration
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        providerId
        title
        startDateTime
        endDateTime
        status
        description
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        videoEnabled
        transcriptionEnabled
        product {
          id
          name
          nameLowerCase
          description
          serviceInformation
          organizationId
          salesTeamId
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProductsId
          teamProductsId
          __typename
        }
        productId
        isReadinessTest
        meetingRoomId
        providerSessionId
        providerJoinData
        sessionStatus
        actualStartDateTime
        actualEndDateTime
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        owner
        meetingInvitationType
        teamId
        isInterested
        feedbackSummary
        contactRequestTimeframe
        contactPhoneNumber
        contactEmail
        sellerOrganizationName
        sellerTeamName
        buyerTeamName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productMeetingInvitesId
        profileMeetingInvitesId
        meetingRoomProviderMeetingInvitesId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listMeetingInvitesByTeamIdAndStatus = /* GraphQL */ `
  query ListMeetingInvitesByTeamIdAndStatus(
    $teamId: ID!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMeetingInviteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMeetingInvitesByTeamIdAndStatus(
      teamId: $teamId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        meetingId
        provider {
          id
          name
          componentName
          description
          configuration
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        providerId
        title
        startDateTime
        endDateTime
        status
        description
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        videoEnabled
        transcriptionEnabled
        product {
          id
          name
          nameLowerCase
          description
          serviceInformation
          organizationId
          salesTeamId
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProductsId
          teamProductsId
          __typename
        }
        productId
        isReadinessTest
        meetingRoomId
        providerSessionId
        providerJoinData
        sessionStatus
        actualStartDateTime
        actualEndDateTime
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        owner
        meetingInvitationType
        teamId
        isInterested
        feedbackSummary
        contactRequestTimeframe
        contactPhoneNumber
        contactEmail
        sellerOrganizationName
        sellerTeamName
        buyerTeamName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productMeetingInvitesId
        profileMeetingInvitesId
        meetingRoomProviderMeetingInvitesId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getMeetingRoom = /* GraphQL */ `
  query GetMeetingRoom($id: ID!) {
    getMeetingRoom(id: $id) {
      id
      name
      meeting {
        id
        title
        titleLowerCase
        startDateTime
        endDateTime
        status
        description
        preferredRoomProvider {
          id
          name
          componentName
          description
          configuration
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        preferredRoomProviderId
        actualStartDateTime
        actualEndDateTime
        room {
          id
          name
          meetingId
          providerId
          providerMeetingId
          accessCode
          status
          startDateTime
          endDateTime
          actualStartDateTime
          actualEndDateTime
          roomCreatedAt
          roomDestroyedAt
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          isReadinessTest
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          meetingRoomProviderMeetingRoomsId
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        videoEnabled
        transcriptionEnabled
        product {
          id
          name
          nameLowerCase
          description
          serviceInformation
          organizationId
          salesTeamId
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProductsId
          teamProductsId
          __typename
        }
        productId
        isReadinessTest
        labels
        owner
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        transcriptions {
          nextToken
          startedAt
          __typename
        }
        sellerOrganizationName
        sellerTeamName
        buyerTeamName
        sellerOrganizationNameLowerCase
        sellerTeamNameLowerCase
        buyerTeamNameLowerCase
        externalId
        metrics
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productMeetingsId
        meetingRoomProviderMeetingsId
        meetingRoomId
        __typename
      }
      meetingId
      provider {
        id
        name
        componentName
        description
        configuration
        meetings {
          nextToken
          startedAt
          __typename
        }
        meetingRooms {
          nextToken
          startedAt
          __typename
        }
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      providerId
      providerMeetingId
      accessCode
      status
      startDateTime
      endDateTime
      actualStartDateTime
      actualEndDateTime
      roomCreatedAt
      roomDestroyedAt
      auditlogs {
        items {
          id
          date
          author
          resource
          action
          payload
          ipAddress
          meetingRoomId
          profileId
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileAuditLogsId
          meetingRoomAuditlogsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      privacyEnabled
      videoEnabled
      transcriptionEnabled
      isReadinessTest
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      meetingRoomProviderMeetingRoomsId
      __typename
    }
  }
`;
export const listMeetingRooms = /* GraphQL */ `
  query ListMeetingRooms(
    $filter: ModelMeetingRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMeetingRooms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        meeting {
          id
          title
          titleLowerCase
          startDateTime
          endDateTime
          status
          description
          preferredRoomProviderId
          actualStartDateTime
          actualEndDateTime
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          productId
          isReadinessTest
          labels
          owner
          sellerOrganizationName
          sellerTeamName
          buyerTeamName
          sellerOrganizationNameLowerCase
          sellerTeamNameLowerCase
          buyerTeamNameLowerCase
          externalId
          metrics
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productMeetingsId
          meetingRoomProviderMeetingsId
          meetingRoomId
          __typename
        }
        meetingId
        provider {
          id
          name
          componentName
          description
          configuration
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        providerId
        providerMeetingId
        accessCode
        status
        startDateTime
        endDateTime
        actualStartDateTime
        actualEndDateTime
        roomCreatedAt
        roomDestroyedAt
        auditlogs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        videoEnabled
        transcriptionEnabled
        isReadinessTest
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        meetingRoomProviderMeetingRoomsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncMeetingRooms = /* GraphQL */ `
  query SyncMeetingRooms(
    $filter: ModelMeetingRoomFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMeetingRooms(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        meeting {
          id
          title
          titleLowerCase
          startDateTime
          endDateTime
          status
          description
          preferredRoomProviderId
          actualStartDateTime
          actualEndDateTime
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          productId
          isReadinessTest
          labels
          owner
          sellerOrganizationName
          sellerTeamName
          buyerTeamName
          sellerOrganizationNameLowerCase
          sellerTeamNameLowerCase
          buyerTeamNameLowerCase
          externalId
          metrics
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productMeetingsId
          meetingRoomProviderMeetingsId
          meetingRoomId
          __typename
        }
        meetingId
        provider {
          id
          name
          componentName
          description
          configuration
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        providerId
        providerMeetingId
        accessCode
        status
        startDateTime
        endDateTime
        actualStartDateTime
        actualEndDateTime
        roomCreatedAt
        roomDestroyedAt
        auditlogs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        videoEnabled
        transcriptionEnabled
        isReadinessTest
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        meetingRoomProviderMeetingRoomsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listMeetingRoomsByMeetingId = /* GraphQL */ `
  query ListMeetingRoomsByMeetingId(
    $meetingId: ID!
    $providerId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMeetingRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMeetingRoomsByMeetingId(
      meetingId: $meetingId
      providerId: $providerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        meeting {
          id
          title
          titleLowerCase
          startDateTime
          endDateTime
          status
          description
          preferredRoomProviderId
          actualStartDateTime
          actualEndDateTime
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          productId
          isReadinessTest
          labels
          owner
          sellerOrganizationName
          sellerTeamName
          buyerTeamName
          sellerOrganizationNameLowerCase
          sellerTeamNameLowerCase
          buyerTeamNameLowerCase
          externalId
          metrics
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productMeetingsId
          meetingRoomProviderMeetingsId
          meetingRoomId
          __typename
        }
        meetingId
        provider {
          id
          name
          componentName
          description
          configuration
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        providerId
        providerMeetingId
        accessCode
        status
        startDateTime
        endDateTime
        actualStartDateTime
        actualEndDateTime
        roomCreatedAt
        roomDestroyedAt
        auditlogs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        videoEnabled
        transcriptionEnabled
        isReadinessTest
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        meetingRoomProviderMeetingRoomsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listMeetingRoomsByProviderId = /* GraphQL */ `
  query ListMeetingRoomsByProviderId(
    $providerId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMeetingRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMeetingRoomsByProviderId(
      providerId: $providerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        meeting {
          id
          title
          titleLowerCase
          startDateTime
          endDateTime
          status
          description
          preferredRoomProviderId
          actualStartDateTime
          actualEndDateTime
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          productId
          isReadinessTest
          labels
          owner
          sellerOrganizationName
          sellerTeamName
          buyerTeamName
          sellerOrganizationNameLowerCase
          sellerTeamNameLowerCase
          buyerTeamNameLowerCase
          externalId
          metrics
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productMeetingsId
          meetingRoomProviderMeetingsId
          meetingRoomId
          __typename
        }
        meetingId
        provider {
          id
          name
          componentName
          description
          configuration
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        providerId
        providerMeetingId
        accessCode
        status
        startDateTime
        endDateTime
        actualStartDateTime
        actualEndDateTime
        roomCreatedAt
        roomDestroyedAt
        auditlogs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        videoEnabled
        transcriptionEnabled
        isReadinessTest
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        meetingRoomProviderMeetingRoomsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listMeetingRoomsByAccessCode = /* GraphQL */ `
  query ListMeetingRoomsByAccessCode(
    $accessCode: String!
    $sortDirection: ModelSortDirection
    $filter: ModelMeetingRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMeetingRoomsByAccessCode(
      accessCode: $accessCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        meeting {
          id
          title
          titleLowerCase
          startDateTime
          endDateTime
          status
          description
          preferredRoomProviderId
          actualStartDateTime
          actualEndDateTime
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          productId
          isReadinessTest
          labels
          owner
          sellerOrganizationName
          sellerTeamName
          buyerTeamName
          sellerOrganizationNameLowerCase
          sellerTeamNameLowerCase
          buyerTeamNameLowerCase
          externalId
          metrics
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productMeetingsId
          meetingRoomProviderMeetingsId
          meetingRoomId
          __typename
        }
        meetingId
        provider {
          id
          name
          componentName
          description
          configuration
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        providerId
        providerMeetingId
        accessCode
        status
        startDateTime
        endDateTime
        actualStartDateTime
        actualEndDateTime
        roomCreatedAt
        roomDestroyedAt
        auditlogs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        videoEnabled
        transcriptionEnabled
        isReadinessTest
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        meetingRoomProviderMeetingRoomsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getMeetingRoomEventAuditLog = /* GraphQL */ `
  query GetMeetingRoomEventAuditLog($id: ID!) {
    getMeetingRoomEventAuditLog(id: $id) {
      id
      date
      author
      resource
      action
      payload
      ipAddress
      meetingRoom {
        id
        name
        meeting {
          id
          title
          titleLowerCase
          startDateTime
          endDateTime
          status
          description
          preferredRoomProviderId
          actualStartDateTime
          actualEndDateTime
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          productId
          isReadinessTest
          labels
          owner
          sellerOrganizationName
          sellerTeamName
          buyerTeamName
          sellerOrganizationNameLowerCase
          sellerTeamNameLowerCase
          buyerTeamNameLowerCase
          externalId
          metrics
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productMeetingsId
          meetingRoomProviderMeetingsId
          meetingRoomId
          __typename
        }
        meetingId
        provider {
          id
          name
          componentName
          description
          configuration
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        providerId
        providerMeetingId
        accessCode
        status
        startDateTime
        endDateTime
        actualStartDateTime
        actualEndDateTime
        roomCreatedAt
        roomDestroyedAt
        auditlogs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        videoEnabled
        transcriptionEnabled
        isReadinessTest
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        meetingRoomProviderMeetingRoomsId
        __typename
      }
      meetingRoomId
      profile {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      profileId
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      profileAuditLogsId
      meetingRoomAuditlogsId
      __typename
    }
  }
`;
export const listMeetingRoomEventAuditLogs = /* GraphQL */ `
  query ListMeetingRoomEventAuditLogs(
    $filter: ModelMeetingRoomEventAuditLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMeetingRoomEventAuditLogs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        author
        resource
        action
        payload
        ipAddress
        meetingRoom {
          id
          name
          meetingId
          providerId
          providerMeetingId
          accessCode
          status
          startDateTime
          endDateTime
          actualStartDateTime
          actualEndDateTime
          roomCreatedAt
          roomDestroyedAt
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          isReadinessTest
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          meetingRoomProviderMeetingRoomsId
          __typename
        }
        meetingRoomId
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        profileAuditLogsId
        meetingRoomAuditlogsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncMeetingRoomEventAuditLogs = /* GraphQL */ `
  query SyncMeetingRoomEventAuditLogs(
    $filter: ModelMeetingRoomEventAuditLogFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMeetingRoomEventAuditLogs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        date
        author
        resource
        action
        payload
        ipAddress
        meetingRoom {
          id
          name
          meetingId
          providerId
          providerMeetingId
          accessCode
          status
          startDateTime
          endDateTime
          actualStartDateTime
          actualEndDateTime
          roomCreatedAt
          roomDestroyedAt
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          isReadinessTest
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          meetingRoomProviderMeetingRoomsId
          __typename
        }
        meetingRoomId
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        profileAuditLogsId
        meetingRoomAuditlogsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listMeetingRoomEventAuditLogsByMeetingRoomId = /* GraphQL */ `
  query ListMeetingRoomEventAuditLogsByMeetingRoomId(
    $meetingRoomId: ID!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMeetingRoomEventAuditLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMeetingRoomEventAuditLogsByMeetingRoomId(
      meetingRoomId: $meetingRoomId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        author
        resource
        action
        payload
        ipAddress
        meetingRoom {
          id
          name
          meetingId
          providerId
          providerMeetingId
          accessCode
          status
          startDateTime
          endDateTime
          actualStartDateTime
          actualEndDateTime
          roomCreatedAt
          roomDestroyedAt
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          isReadinessTest
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          meetingRoomProviderMeetingRoomsId
          __typename
        }
        meetingRoomId
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        profileAuditLogsId
        meetingRoomAuditlogsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listMeetingRoomEventAuditLogsByProfileId = /* GraphQL */ `
  query ListMeetingRoomEventAuditLogsByProfileId(
    $profileId: ID!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMeetingRoomEventAuditLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMeetingRoomEventAuditLogsByProfileId(
      profileId: $profileId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        author
        resource
        action
        payload
        ipAddress
        meetingRoom {
          id
          name
          meetingId
          providerId
          providerMeetingId
          accessCode
          status
          startDateTime
          endDateTime
          actualStartDateTime
          actualEndDateTime
          roomCreatedAt
          roomDestroyedAt
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          isReadinessTest
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          meetingRoomProviderMeetingRoomsId
          __typename
        }
        meetingRoomId
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        profileAuditLogsId
        meetingRoomAuditlogsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getExportJob = /* GraphQL */ `
  query GetExportJob($id: ID!) {
    getExportJob(id: $id) {
      id
      status
      profile {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      profileId
      auditLogs {
        items {
          id
          date
          author
          resource
          action
          payload
          ipAddress
          exportJobId
          profileId
          owner
          identityId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileExportJobAuditLogsId
          exportJobAuditLogsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      meetingInvitationType
      startDateTime
      endDateTime
      owner
      identityId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      profileExportJobsId
      __typename
    }
  }
`;
export const listExportJobs = /* GraphQL */ `
  query ListExportJobs(
    $filter: ModelExportJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExportJobs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        status
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        meetingInvitationType
        startDateTime
        endDateTime
        owner
        identityId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        profileExportJobsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncExportJobs = /* GraphQL */ `
  query SyncExportJobs(
    $filter: ModelExportJobFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncExportJobs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        status
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        meetingInvitationType
        startDateTime
        endDateTime
        owner
        identityId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        profileExportJobsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listExportJobsByStatus = /* GraphQL */ `
  query ListExportJobsByStatus(
    $status: JobStatus!
    $startDateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelExportJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExportJobsByStatus(
      status: $status
      startDateTime: $startDateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        status
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        meetingInvitationType
        startDateTime
        endDateTime
        owner
        identityId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        profileExportJobsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listExportJobsByProfileId = /* GraphQL */ `
  query ListExportJobsByProfileId(
    $profileId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelExportJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExportJobsByProfileId(
      profileId: $profileId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        status
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        meetingInvitationType
        startDateTime
        endDateTime
        owner
        identityId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        profileExportJobsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getExportJobAuditLog = /* GraphQL */ `
  query GetExportJobAuditLog($id: ID!) {
    getExportJobAuditLog(id: $id) {
      id
      date
      author
      resource
      action
      payload
      ipAddress
      exportJob {
        id
        status
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        meetingInvitationType
        startDateTime
        endDateTime
        owner
        identityId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        profileExportJobsId
        __typename
      }
      exportJobId
      profile {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      profileId
      owner
      source {
        bucket
        region
        key
        __typename
      }
      identityId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      profileExportJobAuditLogsId
      exportJobAuditLogsId
      __typename
    }
  }
`;
export const listExportJobAuditLogs = /* GraphQL */ `
  query ListExportJobAuditLogs(
    $filter: ModelExportJobAuditLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExportJobAuditLogs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        author
        resource
        action
        payload
        ipAddress
        exportJob {
          id
          status
          profileId
          meetingInvitationType
          startDateTime
          endDateTime
          owner
          identityId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileExportJobsId
          __typename
        }
        exportJobId
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        owner
        source {
          bucket
          region
          key
          __typename
        }
        identityId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        profileExportJobAuditLogsId
        exportJobAuditLogsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncExportJobAuditLogs = /* GraphQL */ `
  query SyncExportJobAuditLogs(
    $filter: ModelExportJobAuditLogFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncExportJobAuditLogs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        date
        author
        resource
        action
        payload
        ipAddress
        exportJob {
          id
          status
          profileId
          meetingInvitationType
          startDateTime
          endDateTime
          owner
          identityId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileExportJobsId
          __typename
        }
        exportJobId
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        owner
        source {
          bucket
          region
          key
          __typename
        }
        identityId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        profileExportJobAuditLogsId
        exportJobAuditLogsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listExportJobAuditLogsByExportJobId = /* GraphQL */ `
  query ListExportJobAuditLogsByExportJobId(
    $exportJobId: ID!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelExportJobAuditLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExportJobAuditLogsByExportJobId(
      exportJobId: $exportJobId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        author
        resource
        action
        payload
        ipAddress
        exportJob {
          id
          status
          profileId
          meetingInvitationType
          startDateTime
          endDateTime
          owner
          identityId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileExportJobsId
          __typename
        }
        exportJobId
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        owner
        source {
          bucket
          region
          key
          __typename
        }
        identityId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        profileExportJobAuditLogsId
        exportJobAuditLogsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listExportJobAuditLogsByProfileId = /* GraphQL */ `
  query ListExportJobAuditLogsByProfileId(
    $profileId: ID!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelExportJobAuditLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExportJobAuditLogsByProfileId(
      profileId: $profileId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        author
        resource
        action
        payload
        ipAddress
        exportJob {
          id
          status
          profileId
          meetingInvitationType
          startDateTime
          endDateTime
          owner
          identityId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileExportJobsId
          __typename
        }
        exportJobId
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        owner
        source {
          bucket
          region
          key
          __typename
        }
        identityId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        profileExportJobAuditLogsId
        exportJobAuditLogsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getImportJob = /* GraphQL */ `
  query GetImportJob($id: ID!) {
    getImportJob(id: $id) {
      id
      status
      source {
        bucket
        region
        key
        __typename
      }
      identityId
      result
      profile {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      profileId
      auditLogs {
        items {
          id
          date
          author
          resource
          action
          payload
          ipAddress
          importJobId
          profileId
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileJobAuditLogsId
          importJobAuditLogsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      lastStartDateTime
      lastEndDateTime
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      profileImportJobsId
      __typename
    }
  }
`;
export const listImportJobs = /* GraphQL */ `
  query ListImportJobs(
    $filter: ModelImportJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listImportJobs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        status
        source {
          bucket
          region
          key
          __typename
        }
        identityId
        result
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        lastStartDateTime
        lastEndDateTime
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        profileImportJobsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncImportJobs = /* GraphQL */ `
  query SyncImportJobs(
    $filter: ModelImportJobFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncImportJobs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        status
        source {
          bucket
          region
          key
          __typename
        }
        identityId
        result
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        lastStartDateTime
        lastEndDateTime
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        profileImportJobsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listImportJobsByStatus = /* GraphQL */ `
  query ListImportJobsByStatus(
    $status: JobStatus!
    $lastStartDateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelImportJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listImportJobsByStatus(
      status: $status
      lastStartDateTime: $lastStartDateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        status
        source {
          bucket
          region
          key
          __typename
        }
        identityId
        result
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        lastStartDateTime
        lastEndDateTime
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        profileImportJobsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listImportJobsByProfileId = /* GraphQL */ `
  query ListImportJobsByProfileId(
    $profileId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelImportJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listImportJobsByProfileId(
      profileId: $profileId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        status
        source {
          bucket
          region
          key
          __typename
        }
        identityId
        result
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        lastStartDateTime
        lastEndDateTime
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        profileImportJobsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getImportJobAuditLog = /* GraphQL */ `
  query GetImportJobAuditLog($id: ID!) {
    getImportJobAuditLog(id: $id) {
      id
      date
      author
      resource
      action
      payload
      ipAddress
      importJob {
        id
        status
        source {
          bucket
          region
          key
          __typename
        }
        identityId
        result
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        lastStartDateTime
        lastEndDateTime
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        profileImportJobsId
        __typename
      }
      importJobId
      profile {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      profileId
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      profileJobAuditLogsId
      importJobAuditLogsId
      __typename
    }
  }
`;
export const listImportJobAuditLogs = /* GraphQL */ `
  query ListImportJobAuditLogs(
    $filter: ModelImportJobAuditLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listImportJobAuditLogs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        author
        resource
        action
        payload
        ipAddress
        importJob {
          id
          status
          identityId
          result
          profileId
          lastStartDateTime
          lastEndDateTime
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileImportJobsId
          __typename
        }
        importJobId
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        profileJobAuditLogsId
        importJobAuditLogsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncImportJobAuditLogs = /* GraphQL */ `
  query SyncImportJobAuditLogs(
    $filter: ModelImportJobAuditLogFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncImportJobAuditLogs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        date
        author
        resource
        action
        payload
        ipAddress
        importJob {
          id
          status
          identityId
          result
          profileId
          lastStartDateTime
          lastEndDateTime
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileImportJobsId
          __typename
        }
        importJobId
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        profileJobAuditLogsId
        importJobAuditLogsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listImportJobAuditLogsByImportJobId = /* GraphQL */ `
  query ListImportJobAuditLogsByImportJobId(
    $importJobId: ID!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelImportJobAuditLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listImportJobAuditLogsByImportJobId(
      importJobId: $importJobId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        author
        resource
        action
        payload
        ipAddress
        importJob {
          id
          status
          identityId
          result
          profileId
          lastStartDateTime
          lastEndDateTime
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileImportJobsId
          __typename
        }
        importJobId
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        profileJobAuditLogsId
        importJobAuditLogsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listImportJobAuditLogsByProfileId = /* GraphQL */ `
  query ListImportJobAuditLogsByProfileId(
    $profileId: ID!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelImportJobAuditLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listImportJobAuditLogsByProfileId(
      profileId: $profileId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        author
        resource
        action
        payload
        ipAddress
        importJob {
          id
          status
          identityId
          result
          profileId
          lastStartDateTime
          lastEndDateTime
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileImportJobsId
          __typename
        }
        importJobId
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        profileJobAuditLogsId
        importJobAuditLogsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      date
      title
      payload
      read
      dateRead
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        date
        title
        payload
        read
        dateRead
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncNotifications = /* GraphQL */ `
  query SyncNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncNotifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        date
        title
        payload
        read
        dateRead
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listNotificationsByRead = /* GraphQL */ `
  query ListNotificationsByRead(
    $read: Int!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotificationsByRead(
      read: $read
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        title
        payload
        read
        dateRead
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getTranscription = /* GraphQL */ `
  query GetTranscription($id: ID!) {
    getTranscription(id: $id) {
      id
      date
      title
      description
      startDateTime
      endDateTime
      result {
        bucket
        region
        key
        __typename
      }
      sizeInBytes
      numberOfTranscriptions
      isZipFile
      meeting {
        id
        title
        titleLowerCase
        startDateTime
        endDateTime
        status
        description
        preferredRoomProvider {
          id
          name
          componentName
          description
          configuration
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        preferredRoomProviderId
        actualStartDateTime
        actualEndDateTime
        room {
          id
          name
          meetingId
          providerId
          providerMeetingId
          accessCode
          status
          startDateTime
          endDateTime
          actualStartDateTime
          actualEndDateTime
          roomCreatedAt
          roomDestroyedAt
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          isReadinessTest
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          meetingRoomProviderMeetingRoomsId
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        videoEnabled
        transcriptionEnabled
        product {
          id
          name
          nameLowerCase
          description
          serviceInformation
          organizationId
          salesTeamId
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProductsId
          teamProductsId
          __typename
        }
        productId
        isReadinessTest
        labels
        owner
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        transcriptions {
          nextToken
          startedAt
          __typename
        }
        sellerOrganizationName
        sellerTeamName
        buyerTeamName
        sellerOrganizationNameLowerCase
        sellerTeamNameLowerCase
        buyerTeamNameLowerCase
        externalId
        metrics
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productMeetingsId
        meetingRoomProviderMeetingsId
        meetingRoomId
        __typename
      }
      meetingId
      team {
        id
        name
        nameLowerCase
        description
        memberRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingRelationships {
          nextToken
          startedAt
          __typename
        }
        products {
          nextToken
          startedAt
          __typename
        }
        labels
        privacyEnabled
        privacyEnableOtherTeams
        images {
          nextToken
          startedAt
          __typename
        }
        transcriptions {
          nextToken
          startedAt
          __typename
        }
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      teamId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      teamTranscriptionsId
      meetingTranscriptionsId
      __typename
    }
  }
`;
export const listTranscriptions = /* GraphQL */ `
  query ListTranscriptions(
    $filter: ModelTranscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTranscriptions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        date
        title
        description
        startDateTime
        endDateTime
        result {
          bucket
          region
          key
          __typename
        }
        sizeInBytes
        numberOfTranscriptions
        isZipFile
        meeting {
          id
          title
          titleLowerCase
          startDateTime
          endDateTime
          status
          description
          preferredRoomProviderId
          actualStartDateTime
          actualEndDateTime
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          productId
          isReadinessTest
          labels
          owner
          sellerOrganizationName
          sellerTeamName
          buyerTeamName
          sellerOrganizationNameLowerCase
          sellerTeamNameLowerCase
          buyerTeamNameLowerCase
          externalId
          metrics
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productMeetingsId
          meetingRoomProviderMeetingsId
          meetingRoomId
          __typename
        }
        meetingId
        team {
          id
          name
          nameLowerCase
          description
          labels
          privacyEnabled
          privacyEnableOtherTeams
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        teamId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        teamTranscriptionsId
        meetingTranscriptionsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncTranscriptions = /* GraphQL */ `
  query SyncTranscriptions(
    $filter: ModelTranscriptionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTranscriptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        date
        title
        description
        startDateTime
        endDateTime
        result {
          bucket
          region
          key
          __typename
        }
        sizeInBytes
        numberOfTranscriptions
        isZipFile
        meeting {
          id
          title
          titleLowerCase
          startDateTime
          endDateTime
          status
          description
          preferredRoomProviderId
          actualStartDateTime
          actualEndDateTime
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          productId
          isReadinessTest
          labels
          owner
          sellerOrganizationName
          sellerTeamName
          buyerTeamName
          sellerOrganizationNameLowerCase
          sellerTeamNameLowerCase
          buyerTeamNameLowerCase
          externalId
          metrics
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productMeetingsId
          meetingRoomProviderMeetingsId
          meetingRoomId
          __typename
        }
        meetingId
        team {
          id
          name
          nameLowerCase
          description
          labels
          privacyEnabled
          privacyEnableOtherTeams
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        teamId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        teamTranscriptionsId
        meetingTranscriptionsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listTranscriptionsByMeetingId = /* GraphQL */ `
  query ListTranscriptionsByMeetingId(
    $meetingId: ID!
    $startDateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTranscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTranscriptionsByMeetingId(
      meetingId: $meetingId
      startDateTime: $startDateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        title
        description
        startDateTime
        endDateTime
        result {
          bucket
          region
          key
          __typename
        }
        sizeInBytes
        numberOfTranscriptions
        isZipFile
        meeting {
          id
          title
          titleLowerCase
          startDateTime
          endDateTime
          status
          description
          preferredRoomProviderId
          actualStartDateTime
          actualEndDateTime
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          productId
          isReadinessTest
          labels
          owner
          sellerOrganizationName
          sellerTeamName
          buyerTeamName
          sellerOrganizationNameLowerCase
          sellerTeamNameLowerCase
          buyerTeamNameLowerCase
          externalId
          metrics
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productMeetingsId
          meetingRoomProviderMeetingsId
          meetingRoomId
          __typename
        }
        meetingId
        team {
          id
          name
          nameLowerCase
          description
          labels
          privacyEnabled
          privacyEnableOtherTeams
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        teamId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        teamTranscriptionsId
        meetingTranscriptionsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listTranscriptionsByTeamId = /* GraphQL */ `
  query ListTranscriptionsByTeamId(
    $teamId: ID!
    $startDateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTranscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTranscriptionsByTeamId(
      teamId: $teamId
      startDateTime: $startDateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        title
        description
        startDateTime
        endDateTime
        result {
          bucket
          region
          key
          __typename
        }
        sizeInBytes
        numberOfTranscriptions
        isZipFile
        meeting {
          id
          title
          titleLowerCase
          startDateTime
          endDateTime
          status
          description
          preferredRoomProviderId
          actualStartDateTime
          actualEndDateTime
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          productId
          isReadinessTest
          labels
          owner
          sellerOrganizationName
          sellerTeamName
          buyerTeamName
          sellerOrganizationNameLowerCase
          sellerTeamNameLowerCase
          buyerTeamNameLowerCase
          externalId
          metrics
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productMeetingsId
          meetingRoomProviderMeetingsId
          meetingRoomId
          __typename
        }
        meetingId
        team {
          id
          name
          nameLowerCase
          description
          labels
          privacyEnabled
          privacyEnableOtherTeams
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        teamId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        teamTranscriptionsId
        meetingTranscriptionsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getTranscriptionJob = /* GraphQL */ `
  query GetTranscriptionJob($id: ID!) {
    getTranscriptionJob(id: $id) {
      id
      date
      status
      result {
        bucket
        region
        key
        __typename
      }
      meetingInviteId
      auditLogs {
        items {
          id
          date
          author
          resource
          action
          payload
          ipAddress
          transcriptionJobId
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          transcriptionJobAuditLogsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      owner
      identityId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listTranscriptionJobs = /* GraphQL */ `
  query ListTranscriptionJobs(
    $filter: ModelTranscriptionJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTranscriptionJobs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        status
        result {
          bucket
          region
          key
          __typename
        }
        meetingInviteId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        owner
        identityId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncTranscriptionJobs = /* GraphQL */ `
  query SyncTranscriptionJobs(
    $filter: ModelTranscriptionJobFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTranscriptionJobs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        date
        status
        result {
          bucket
          region
          key
          __typename
        }
        meetingInviteId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        owner
        identityId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listTranscriptionJobsByStatus = /* GraphQL */ `
  query ListTranscriptionJobsByStatus(
    $status: JobStatus!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTranscriptionJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTranscriptionJobsByStatus(
      status: $status
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        status
        result {
          bucket
          region
          key
          __typename
        }
        meetingInviteId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        owner
        identityId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listTranscriptionJobsByMeetingInviteId = /* GraphQL */ `
  query ListTranscriptionJobsByMeetingInviteId(
    $meetingInviteId: String!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTranscriptionJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTranscriptionJobsByMeetingInviteId(
      meetingInviteId: $meetingInviteId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        status
        result {
          bucket
          region
          key
          __typename
        }
        meetingInviteId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        owner
        identityId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getTranscriptionJobAuditLog = /* GraphQL */ `
  query GetTranscriptionJobAuditLog($id: ID!) {
    getTranscriptionJobAuditLog(id: $id) {
      id
      date
      author
      resource
      action
      payload
      ipAddress
      transcriptionJob {
        id
        date
        status
        result {
          bucket
          region
          key
          __typename
        }
        meetingInviteId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        owner
        identityId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      transcriptionJobId
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      transcriptionJobAuditLogsId
      __typename
    }
  }
`;
export const listTranscriptionJobAuditLogs = /* GraphQL */ `
  query ListTranscriptionJobAuditLogs(
    $filter: ModelTranscriptionJobAuditLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTranscriptionJobAuditLogs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        author
        resource
        action
        payload
        ipAddress
        transcriptionJob {
          id
          date
          status
          meetingInviteId
          owner
          identityId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        transcriptionJobId
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        transcriptionJobAuditLogsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncTranscriptionJobAuditLogs = /* GraphQL */ `
  query SyncTranscriptionJobAuditLogs(
    $filter: ModelTranscriptionJobAuditLogFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTranscriptionJobAuditLogs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        date
        author
        resource
        action
        payload
        ipAddress
        transcriptionJob {
          id
          date
          status
          meetingInviteId
          owner
          identityId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        transcriptionJobId
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        transcriptionJobAuditLogsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listTranscriptionJobAuditLogsByTranscriptionJobId = /* GraphQL */ `
  query ListTranscriptionJobAuditLogsByTranscriptionJobId(
    $transcriptionJobId: ID!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTranscriptionJobAuditLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTranscriptionJobAuditLogsByTranscriptionJobId(
      transcriptionJobId: $transcriptionJobId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        author
        resource
        action
        payload
        ipAddress
        transcriptionJob {
          id
          date
          status
          meetingInviteId
          owner
          identityId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        transcriptionJobId
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        transcriptionJobAuditLogsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getTranscriptionAdminJob = /* GraphQL */ `
  query GetTranscriptionAdminJob($id: ID!) {
    getTranscriptionAdminJob(id: $id) {
      id
      date
      status
      result {
        bucket
        region
        key
        __typename
      }
      meetingId
      meetingInviteId
      auditLogs {
        items {
          id
          date
          author
          resource
          action
          payload
          ipAddress
          transcriptionAdminJobId
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          transcriptionAdminJobAuditLogsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      owner
      identityId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listTranscriptionAdminJobs = /* GraphQL */ `
  query ListTranscriptionAdminJobs(
    $filter: ModelTranscriptionAdminJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTranscriptionAdminJobs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        status
        result {
          bucket
          region
          key
          __typename
        }
        meetingId
        meetingInviteId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        owner
        identityId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncTranscriptionAdminJobs = /* GraphQL */ `
  query SyncTranscriptionAdminJobs(
    $filter: ModelTranscriptionAdminJobFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTranscriptionAdminJobs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        date
        status
        result {
          bucket
          region
          key
          __typename
        }
        meetingId
        meetingInviteId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        owner
        identityId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listTranscriptionAdminJobsByStatus = /* GraphQL */ `
  query ListTranscriptionAdminJobsByStatus(
    $status: JobStatus!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTranscriptionAdminJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTranscriptionAdminJobsByStatus(
      status: $status
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        status
        result {
          bucket
          region
          key
          __typename
        }
        meetingId
        meetingInviteId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        owner
        identityId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listTranscriptionAdminJobsByMeetingId = /* GraphQL */ `
  query ListTranscriptionAdminJobsByMeetingId(
    $meetingId: String!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTranscriptionAdminJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTranscriptionAdminJobsByMeetingId(
      meetingId: $meetingId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        status
        result {
          bucket
          region
          key
          __typename
        }
        meetingId
        meetingInviteId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        owner
        identityId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getTranscriptionAdminJobAuditLog = /* GraphQL */ `
  query GetTranscriptionAdminJobAuditLog($id: ID!) {
    getTranscriptionAdminJobAuditLog(id: $id) {
      id
      date
      author
      resource
      action
      payload
      ipAddress
      transcriptionAdminJob {
        id
        date
        status
        result {
          bucket
          region
          key
          __typename
        }
        meetingId
        meetingInviteId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        owner
        identityId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      transcriptionAdminJobId
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      transcriptionAdminJobAuditLogsId
      __typename
    }
  }
`;
export const listTranscriptionAdminJobAuditLogs = /* GraphQL */ `
  query ListTranscriptionAdminJobAuditLogs(
    $filter: ModelTranscriptionAdminJobAuditLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTranscriptionAdminJobAuditLogs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        author
        resource
        action
        payload
        ipAddress
        transcriptionAdminJob {
          id
          date
          status
          meetingId
          meetingInviteId
          owner
          identityId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        transcriptionAdminJobId
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        transcriptionAdminJobAuditLogsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncTranscriptionAdminJobAuditLogs = /* GraphQL */ `
  query SyncTranscriptionAdminJobAuditLogs(
    $filter: ModelTranscriptionAdminJobAuditLogFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTranscriptionAdminJobAuditLogs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        date
        author
        resource
        action
        payload
        ipAddress
        transcriptionAdminJob {
          id
          date
          status
          meetingId
          meetingInviteId
          owner
          identityId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        transcriptionAdminJobId
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        transcriptionAdminJobAuditLogsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listTranscriptionAdminJobAuditLogsByTranscriptionAdminJobId = /* GraphQL */ `
  query ListTranscriptionAdminJobAuditLogsByTranscriptionAdminJobId(
    $transcriptionAdminJobId: ID!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTranscriptionAdminJobAuditLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTranscriptionAdminJobAuditLogsByTranscriptionAdminJobId(
      transcriptionAdminJobId: $transcriptionAdminJobId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        author
        resource
        action
        payload
        ipAddress
        transcriptionAdminJob {
          id
          date
          status
          meetingId
          meetingInviteId
          owner
          identityId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        transcriptionAdminJobId
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        transcriptionAdminJobAuditLogsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getTranscriptionEventAuditLog = /* GraphQL */ `
  query GetTranscriptionEventAuditLog($id: ID!) {
    getTranscriptionEventAuditLog(id: $id) {
      id
      date
      author
      resource
      action
      payload
      ipAddress
      meetingInvite {
        id
        meetingId
        provider {
          id
          name
          componentName
          description
          configuration
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        providerId
        title
        startDateTime
        endDateTime
        status
        description
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        videoEnabled
        transcriptionEnabled
        product {
          id
          name
          nameLowerCase
          description
          serviceInformation
          organizationId
          salesTeamId
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProductsId
          teamProductsId
          __typename
        }
        productId
        isReadinessTest
        meetingRoomId
        providerSessionId
        providerJoinData
        sessionStatus
        actualStartDateTime
        actualEndDateTime
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        owner
        meetingInvitationType
        teamId
        isInterested
        feedbackSummary
        contactRequestTimeframe
        contactPhoneNumber
        contactEmail
        sellerOrganizationName
        sellerTeamName
        buyerTeamName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productMeetingInvitesId
        profileMeetingInvitesId
        meetingRoomProviderMeetingInvitesId
        __typename
      }
      meetingInviteId
      profile {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      profileId
      authorProfileId
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      profileTranscriptionLogsId
      meetingInviteTranscriptionLogsId
      __typename
    }
  }
`;
export const listTranscriptionEventAuditLogs = /* GraphQL */ `
  query ListTranscriptionEventAuditLogs(
    $filter: ModelTranscriptionEventAuditLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTranscriptionEventAuditLogs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        author
        resource
        action
        payload
        ipAddress
        meetingInvite {
          id
          meetingId
          providerId
          title
          startDateTime
          endDateTime
          status
          description
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          productId
          isReadinessTest
          meetingRoomId
          providerSessionId
          providerJoinData
          sessionStatus
          actualStartDateTime
          actualEndDateTime
          profileId
          owner
          meetingInvitationType
          teamId
          isInterested
          feedbackSummary
          contactRequestTimeframe
          contactPhoneNumber
          contactEmail
          sellerOrganizationName
          sellerTeamName
          buyerTeamName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productMeetingInvitesId
          profileMeetingInvitesId
          meetingRoomProviderMeetingInvitesId
          __typename
        }
        meetingInviteId
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        authorProfileId
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        profileTranscriptionLogsId
        meetingInviteTranscriptionLogsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncTranscriptionEventAuditLogs = /* GraphQL */ `
  query SyncTranscriptionEventAuditLogs(
    $filter: ModelTranscriptionEventAuditLogFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTranscriptionEventAuditLogs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        date
        author
        resource
        action
        payload
        ipAddress
        meetingInvite {
          id
          meetingId
          providerId
          title
          startDateTime
          endDateTime
          status
          description
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          productId
          isReadinessTest
          meetingRoomId
          providerSessionId
          providerJoinData
          sessionStatus
          actualStartDateTime
          actualEndDateTime
          profileId
          owner
          meetingInvitationType
          teamId
          isInterested
          feedbackSummary
          contactRequestTimeframe
          contactPhoneNumber
          contactEmail
          sellerOrganizationName
          sellerTeamName
          buyerTeamName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productMeetingInvitesId
          profileMeetingInvitesId
          meetingRoomProviderMeetingInvitesId
          __typename
        }
        meetingInviteId
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        authorProfileId
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        profileTranscriptionLogsId
        meetingInviteTranscriptionLogsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listTranscriptionEventAuditLogsByMeetingInviteId = /* GraphQL */ `
  query ListTranscriptionEventAuditLogsByMeetingInviteId(
    $meetingInviteId: ID!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTranscriptionEventAuditLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTranscriptionEventAuditLogsByMeetingInviteId(
      meetingInviteId: $meetingInviteId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        author
        resource
        action
        payload
        ipAddress
        meetingInvite {
          id
          meetingId
          providerId
          title
          startDateTime
          endDateTime
          status
          description
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          productId
          isReadinessTest
          meetingRoomId
          providerSessionId
          providerJoinData
          sessionStatus
          actualStartDateTime
          actualEndDateTime
          profileId
          owner
          meetingInvitationType
          teamId
          isInterested
          feedbackSummary
          contactRequestTimeframe
          contactPhoneNumber
          contactEmail
          sellerOrganizationName
          sellerTeamName
          buyerTeamName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productMeetingInvitesId
          profileMeetingInvitesId
          meetingRoomProviderMeetingInvitesId
          __typename
        }
        meetingInviteId
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        authorProfileId
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        profileTranscriptionLogsId
        meetingInviteTranscriptionLogsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listTranscriptionEventAuditLogsByProfileId = /* GraphQL */ `
  query ListTranscriptionEventAuditLogsByProfileId(
    $profileId: ID!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTranscriptionEventAuditLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTranscriptionEventAuditLogsByProfileId(
      profileId: $profileId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        author
        resource
        action
        payload
        ipAddress
        meetingInvite {
          id
          meetingId
          providerId
          title
          startDateTime
          endDateTime
          status
          description
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          productId
          isReadinessTest
          meetingRoomId
          providerSessionId
          providerJoinData
          sessionStatus
          actualStartDateTime
          actualEndDateTime
          profileId
          owner
          meetingInvitationType
          teamId
          isInterested
          feedbackSummary
          contactRequestTimeframe
          contactPhoneNumber
          contactEmail
          sellerOrganizationName
          sellerTeamName
          buyerTeamName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productMeetingInvitesId
          profileMeetingInvitesId
          meetingRoomProviderMeetingInvitesId
          __typename
        }
        meetingInviteId
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        authorProfileId
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        profileTranscriptionLogsId
        meetingInviteTranscriptionLogsId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
