
import React, { memo } from "react";
import type { FC } from "react";
import { useRecordContext, sanitizeFieldRestProps } from "react-admin";
import type { FieldProps, TextFieldProps } from "react-admin";
import { Logger } from "@aws-amplify/core";
import Typography from '@material-ui/core/Typography';
import { fieldPropTypes } from "ra-ui-materialui/lib/field/types";


const logger = new Logger("Customfields");

export const LogPayloadField = (props: FieldProps) => {
  let displayText = "";
  const {
    source
  } = props;

  let payload = '{"message": ""}';
  const record = useRecordContext(props);
  if (record && source) {
    payload = record[source] as string;
  }
  try {
    displayText = JSON.parse(payload)["message"];
  } catch(err) {

  }
  return record ? <span>{displayText}</span> : null;
}

LogPayloadField.defaultProps = { label: "Message" };

export const MeetingRelationshipTypeField: FC<TextFieldProps> = memo(props => {
    const { className, source, emptyText, ...rest } = props;
    const record = useRecordContext(props);
    let value = (source && record && record[source]) || "";
    if (value.toUpperCase() === "INVITEE") {
      value = "Buyer";
    } else if (value.toUpperCase() === "ORGANIZER") {
      value = "Seller";
    }
    return (
        <Typography
            component="span"
            variant="body2"
            className={className}
            {...sanitizeFieldRestProps(rest)}
        >
            {value != null && typeof value !== "string"
                ? JSON.stringify(value)
                : value || emptyText}
        </Typography>
    );
});

// what? TypeScript loses the displayName if we don't set it explicitly
MeetingRelationshipTypeField.displayName = "MeetingRelationshipTypeField";

MeetingRelationshipTypeField.defaultProps = {
    addLabel: true,
};

MeetingRelationshipTypeField.propTypes = {
    // @ts-ignore
    ...Typography.propTypes,
    ...fieldPropTypes,
};
