import React, { useContext, useEffect, useState } from "react";
import {
  AutocompleteInput,
  Datagrid,
  DateField,
  EditButton,
  FilterButton,
  ReferenceField,
  ReferenceInput,
  RefreshButton,
  Show,
  ShowButton,
  SimpleShowLayout,
  TextField,
  useDataProvider,
  useNotify,
  useRefresh,
} from "react-admin";
import type { ListProps, ShowProps } from "react-admin";
import { DateTimeInput as MaterialDateTimeInput } from "./MaterialDatePicker";
import {
  RealTimeList,
  RecordListEvent,
  useSubscribe,
} from "@react-admin/ra-realtime";
import { JsonField } from "react-admin-json-view";
import { onCreateImportJobAuditLog } from "../graphql/subscriptions";
import { API, graphqlOperation, Logger } from "aws-amplify";
import { MeetingsStateContext } from "../App";
import { LogPayloadField } from "./CustomFields";
import TopToolbar from "./TopToolbar";


const logger = new Logger("ImportJobAudiLog");
const defaultQuery = "listImportJobAuditLogs";

const filters = [
  <ReferenceInput
    source="profileId"
    reference="profiles"
    label="User"
    filterToQuery={(searchText) => ({
      listProfiles: { fullName: searchText },
    })}
    perPage={700}
    resettable
    alwaysOn
    >
    <AutocompleteInput optionText="fullName" />
  </ReferenceInput>,
  <ReferenceInput
    source="importJobId"
    reference="importJobs"
    label="Import job"
    filterToQuery={(searchText) => ({
      listImportJobs: { id: searchText },
    })}
    perPage={500}
    resettable
    >
    <AutocompleteInput optionText="id" />
  </ReferenceInput>,
  <MaterialDateTimeInput
    label="Begin Date Range"
    source="date"
    options={{ format: 'MM/dd/yyyy, hh:mm a', ampm: true, clearable: true }}
  />,
  <MaterialDateTimeInput
    label="End Date Range"
    source="endDate"
    options={{ format: 'MM/dd/yyyy, hh:mm a', ampm: true, clearable: true }}
  />,
];

const ListActions = (props: any) => {
  const {
    className,
    basePath,
    total,
    resource,
    currentSort,
    filters,
    filterValues,
    displayedFilters,
    showFilter,
  } = props;
  return (
    <TopToolbar className={className}>
      <FilterButton
        resource={resource} 
        filters={filters}
        filterValues={filterValues}
        displayedFilters={displayedFilters}
        showFilter={showFilter}
      />
      <RefreshButton />
    </TopToolbar>
  );
};

export const ImportJobAuditLogList = (props: ListProps) => {
  const [query, setQuery] = useState(defaultQuery);
  const refresh = useRefresh(); // TODO refresh at interval of implement realtime updates
  const notify = useNotify();
  const meetingsState = useContext(MeetingsStateContext);
  const {
    currentProfile,
  } = meetingsState;
  const dataProvider = useDataProvider();

  const handleEventReceived = (event: RecordListEvent) => {
    // const count = get(event, "payload.ids.length", 1);
    logger.info("handleEventReceived event", event);
    // 'List refreshed with %{smart_count} %{name} %{type} by another user'
    // notify("ra-realtime.notification.list.refreshed", "info", event);
    notify("ra-realtime.action.refresh", "info");
    refresh();
  };

  // useSubscribe("resource/importJobAuditLogs", (event) => {
  //  logger.info("useSubscribe event", event);
  // });

  useEffect(
    () => {
      if (currentProfile?.userId) {
        const subscription = API.graphql(
          graphqlOperation(onCreateImportJobAuditLog, { owner: currentProfile?.userId })
        ).subscribe({
          next: ({ value }: any) => {
            logger.info("value.data.onCreateImportJobAuditLog", value.data.onCreateImportJobAuditLog);
            logger.info("value.data.onCreateImportJobAuditLog.id", value.data.onCreateImportJobAuditLog.id);
            dataProvider.publish(
              "resource/importJobAuditLogs",
              {
                type: "created",
                topic: "resource/importJobAuditLogs",
                payload: { ids: [ value.data.onCreateImportJobAuditLog.id ]},
                date: new Date(),
              },
            );
          },
          error: (error: Error) => {
            logger.warn("onCreateImportJobAuditLog failed", error);
          }
        });
        return () => {
          subscription.unsubscribe();
        }
      }
    },
    [ currentProfile?.userId ]
  );
  
  return (
    <RealTimeList
      {...props}
      filters={filters}
      filterDefaultValues={{ profileId: currentProfile?.id }}
      actions={<ListActions />}
      onEventReceived={handleEventReceived}
    >
      <Datagrid>
        <DateField source="date" showTime={true} sortable={true} />
        <TextField source="importJob.id" label="Job ID" sortable={false} />
        <TextField source="resource" sortable={false} />
        <TextField source="action" sortable={false} />
        <LogPayloadField source="payload" label="Message" sortable={false} />
        <ShowButton />
      </Datagrid>
    </RealTimeList>
  );
};

export const ImportJobAuditLogShow = (props: ShowProps) => {

  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="id" label="ID" />
        <DateField source="date" showTime={true} />
        <TextField source="author" />
        <TextField source="resource" />
        <TextField source="action" />
        <JsonField
          source="payload"
          addLabel={true}
          jsonString={true}
          reactJsonOptions={{
            // shapeshifter:inverted
            theme: "shapeshifter",
            name: null,
            collapsed: true,
            enableClipboard: false,
            displayDataTypes: false,
          }}
        />
        <TextField source="ipAddress" label="IP address" />
        <ReferenceField
          source="importJobId"
          reference="importJobs"
          label="Import job"
          link="show"
        >
          <TextField source="id" />
        </ReferenceField>
        <ReferenceField
          source="profileId"
          reference="profiles"
          label="User"
          link="show"
        >
          <TextField source="fullName" />
        </ReferenceField>
        <DateField source="createdAt" showTime={true} />
        <DateField source="updatedAt" showTime={true} />
      </SimpleShowLayout>
    </Show>
  );
}
