import React, { useState } from "react";
import {
  BooleanField,
  Datagrid,
  DateField,
  Link,
  ReferenceField,
  Show,
  ShowButton,
  SimpleShowLayout,
  TextField,
  useRecordContext,
  required,
  ListButton,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
} from "react-admin";
import type {
  ListProps,
  ShowProps,
} from "react-admin";
import { CustomList, ListPagination } from "./CustomList";
import { JsonField } from "react-admin-json-view";
import TopToolbar from "./TopToolbar";

const defaultQuery = "listProfileDevices";
const validateRequired = [required()];

const lastMeetingStatuses = [
  { name: "Joined", id: "joined" },
  { name: "Good", id: "good" },
  { name: "Poor", id: "poor" },
  { name: "Failed", id: "failed" },
  { name: "Error", id: "error" },
];

const cameraStatuses = [
  { name: "Good", id: "good" },
  { name: "Poor", id: "poor" },
  { name: "Camera error", id: "camera-error" },
  { name: "Error", id: "error" },
  { name: "Nonfatal-error", id: "nonfatal-error" },
];

const micStatuses = [
  { name: "Good", id: "good" },
  { name: "Poor", id: "poor" },
  { name: "Camera error", id: "camera-error" },
  { name: "Error", id: "error" },
  { name: "Nonfatal-error", id: "nonfatal-error" },
];

const filters = [
  <ReferenceInput
    source="profileId"
    reference="profiles"
    label="User"
    filterToQuery={(searchText) => ({
      listProfiles: { fullName: searchText },
    })}
    perPage={700}
    resettable
    filter="listProfileDevicesByProfileId"
    >
    <AutocompleteInput optionText="fullName" />
  </ReferenceInput>,
  <SelectInput source="lastMeetingStatus" choices={lastMeetingStatuses} filter="listProfileDevicesByMeetingStatus" />,
  <SelectInput source="cameraStatus" choices={cameraStatuses} filter="listProfileDevicesByCameraStatus" />,
  <SelectInput source="micStatus" choices={micStatuses} filter="listProfileDevicesByMicStatus" />,
];

export const ProfileDeviceList = (props: ListProps) => {
  const [query, setQuery] = useState(defaultQuery);

  return (
    <CustomList
    {...props}
    bulkActionButtons={false}
    pagination={<ListPagination />}
    filters={filters}
  >
    <Datagrid>
      <TextField source="profile.fullName" label="User name" />
      <TextField source="userAgentSummary" sortable={false} />
      <TextField source="lastMeetingStatus" sortable={false} />
      <TextField source="cameraStatus" sortable={false} />
      <TextField source="micStatus" sortable={false} />
      <DateField source="lastSeen" sortable={false} showTime={true} />
      <ShowButton />
    </Datagrid>
  </CustomList>
  );
};


export const ProfileDeviceShow = (props: ShowProps) => {

  const ShowActions = (props: ShowProps) => {
    const {
      className,
      // basePath,
      // id
    } = props;
    const record = useRecordContext();
    if (!record) {
      return <></>;
    }
    return (
      <TopToolbar className={className}>
        <ShowButton
          component={Link}
          to={{
            pathname: `/profiles/${record.profileId}/show`,
          }}
          label="Return to profile"
        />
        <ListButton />
      </TopToolbar>
    );
  };

  return (
    <Show
      {...props}
      actions={<ShowActions {...props} />}
    >
      <SimpleShowLayout>
        <TextField source="id" label="ID" fullWidth />
        <TextField source="deviceHash" fullWidth />
        <BooleanField source="hasWebcam" label="Has webcam?" />
        <BooleanField source="hasMicrophone" label="Has microphone?" />
        <BooleanField source="isScreenCapturingSupported" label="Is screen capturing supported?" />
        <BooleanField source="isWebRTCSupported" label="Is WebRTC supported?" />
        <BooleanField source="isWebsiteHasMicrophonePermissions" label="Has microphone permissions?" />
        <BooleanField source="isWebsiteHasWebcamPermissions" label="Has webcam permissions?" />
        <BooleanField source="isWebSocketsSupported" label="Is websockets supported?" />
        <ReferenceField
          source="lastMeetingRoomProvider"
          reference="meetingRoomProviders"
          label="Last Meeting provider"
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="clockOffset" />
        <DateField source="lastSeen" showTime={true} />
        <DateField source="lastLogin" showTime={true} />
        <DateField source="lastMeetingJoin" showTime={true} />
        <TextField source="lastMeetingId" />
        <TextField source="lastMeetingInviteId" />
        <TextField source="lastMeetingStatus" />
        <JsonField
          source="meetingData"
          addLabel={true}
          jsonString={true}
          reactJsonOptions={{
            theme: "shapeshifter",
            name: null,
            collapsed: true,
            enableClipboard: false,
            displayDataTypes: false,
          }}
        />
        <JsonField
          source="userAgent"
          addLabel={true}
          jsonString={true}
          reactJsonOptions={{
            theme: "shapeshifter",
            name: null,
            collapsed: true,
            enableClipboard: false,
            displayDataTypes: false,
          }}
        />
        <TextField source="userAgentSummary" />
        <TextField source="micStatus" />
        <TextField source="cameraStatus" />
        <TextField source="networkQuality" />
        <ReferenceField
          label="User"
          source="profileId"
          reference="profiles"
          fullWidth
        >
          <TextField source="fullName" fullWidth />
        </ReferenceField>
        <DateField source="createdAt" showTime={true} />
        <DateField source="updatedAt" showTime={true} />
      </SimpleShowLayout>
    </Show>
  );
}

