
import { useAuthenticated } from "react-admin";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {
  Cache,
  Logger,
} from "aws-amplify";
import { MeetingInvite } from "../API";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }),
);

const logger = new Logger("MeetingError");

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

function MeetingErrorPage() {
  useAuthenticated(); // redirects to login if not authenticated
  const classes = useStyles();
  const [ meetingInviteError, setMeetingInviteError ] = useState<null | { meetingInvite: MeetingInvite, message: string}>(
    Cache.getItem("meetingInviteError") ? Cache.getItem("meetingInviteError") as { meetingInvite: MeetingInvite, message: string} : null
  );

  useEffect(() => {
    if (meetingInviteError) {
      Cache.removeItem("meetingInviteError");
      logger.info("cleared meetingInviteError.");
    }
  }, []);

  const errorMessage = meetingInviteError ? meetingInviteError.message : "An unknown error occurred";

  return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
          <Typography variant="h1">
            {errorMessage}
          </Typography>
          </Paper>
        </Grid>
      </Grid>
  );
}

export default MeetingErrorPage;
