import React, { useEffect, useState } from "react";
import { FieldProps, useRecordContext } from "react-admin";
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import ErrorIcon from '@material-ui/icons/Error';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { Tooltip } from "@material-ui/core";
import { MeetingStatus } from "../API";
import VideocamIcon from '@material-ui/icons/Videocam';
import { Logger } from "aws-amplify";
// import { getCurrentDateTime } from "../lib/helpers";
import { DateTime } from "luxon";
import { AWS_TIMESTAMP_AS_LUXON_FORMAT } from "../constants";
// import VideocamOffIcon from '@material-ui/icons/VideocamOff';
// import MicOffIcon from '@material-ui/icons/MicOff';
// import VisibilityIcon from '@material-ui/icons/Visibility';
// import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
// See for customizations: https://v4.mui.com/customization/globals/#default-props

const logger = new Logger("MetricsFields");

type Props = {
  source: string;
  jsonString?: boolean;
  currentDateTime: DateTime;
} & FieldProps;

export const SellersMetricsIconField: React.FC<Props> = ({
  source,
  currentDateTime,
  jsonString = false,
}) => {
  const record = useRecordContext();
  if (!record) {
    return <></>;
  }
  let src = record[source];
  if (!src) {
    return <></>;
  }
  const status = record['status'];
  if (
    !status || (status && (
      status != MeetingStatus.SCHEDULED
      && status != MeetingStatus.STARTED
      && status != MeetingStatus.READY
      && status != MeetingStatus.PENDING
    ))
  ) {
    return <></>;
  }
  const [ meetingAboutToStart, setMeetingAboutToStart ] = useState<boolean>(false);
  if (jsonString) {
    src = JSON.parse(src);
  }
  const {
    sellersLoggedIn,
    sellersWithIssues,
  } = src;

  useEffect(() => {
    if ((!sellersLoggedIn || sellersWithIssues) && record) {
      const meetingStartDateTime = DateTime.fromFormat(
        record.startDateTime,
        AWS_TIMESTAMP_AS_LUXON_FORMAT,
        { zone: "utc" },
      ).toLocal();
      const meetingEndDateTime = DateTime.fromFormat(
        record.endDateTime,
        AWS_TIMESTAMP_AS_LUXON_FORMAT,
        { zone: "utc" },
      ).toLocal();
      const minutesFromNow = currentDateTime.plus({ minutes: 10 });
      logger.info("MeetingMetricsFields - minutesFromNow.toUnixInteger()", minutesFromNow.toUnixInteger());
      logger.info("MeetingMetricsFields - meetingStartDateTime.toUnixInteger()", meetingStartDateTime.toUnixInteger());
      logger.info(
        "MeetingMetricsFields - minutesFromNow difference in minutes",
        Math.ceil(
          (meetingStartDateTime.toUnixInteger() - minutesFromNow.toUnixInteger()) / 60
        )
      );
      const meetingIsCloseAndActive = minutesFromNow.toUnixInteger() > meetingStartDateTime.toUnixInteger() && currentDateTime.toUnixInteger() < meetingEndDateTime.toUnixInteger();
      if (meetingAboutToStart != meetingIsCloseAndActive) {
        logger.info("MeetingMetricsFields - setting meetingAboutToStart", { meetingIsCloseAndActive, meetingAboutToStart });
        setMeetingAboutToStart(meetingIsCloseAndActive);
      }
    }
  }, [
    sellersLoggedIn,
    sellersWithIssues,
    currentDateTime,
    record,
  ]);

  const title = sellersLoggedIn ? (sellersWithIssues ? "error" : "logged in") : "not logged in";
  return <Tooltip title={title}>{sellersLoggedIn ? (
    (sellersWithIssues ? (
      <ErrorIcon color={meetingAboutToStart ? "error" : undefined  } />
    ) : (
      <CheckCircleRoundedIcon />
    ))
  ) : (
    <VisibilityOffIcon color={meetingAboutToStart ? "error" : undefined  } />
  )}</Tooltip>;
};


export const BuyersMetricsIconField: React.FC<Props> = ({
  source,
  currentDateTime,
  jsonString = false,
}) => {
  const record = useRecordContext();
  if (!record) {
    return <></>;
  }
  let src = record[source];
  if (!src) {
    return <></>;
  }
  const status = record['status'];
  if (
    !status || (status && (
      status != MeetingStatus.SCHEDULED
      && status != MeetingStatus.STARTED
      && status != MeetingStatus.READY
      && status != MeetingStatus.PENDING
    ))
  ) {
    return <></>;
  }
  const [ meetingAboutToStart, setMeetingAboutToStart ] = useState<boolean>(false);
  if (jsonString) {
    src = JSON.parse(src);
  }
  const {
    buyersLoggedIn,
    buyersWithIssues,
  } = src;

  useEffect(
    () => {
      if ((!buyersLoggedIn || buyersWithIssues) && record) {
        const meetingStartDateTime = DateTime.fromFormat(
          record.startDateTime,
          AWS_TIMESTAMP_AS_LUXON_FORMAT,
          { zone: "utc" },
        ).toLocal();
        const meetingEndDateTime = DateTime.fromFormat(
          record.endDateTime,
          AWS_TIMESTAMP_AS_LUXON_FORMAT,
          { zone: "utc" },
        ).toLocal();
        const minutesFromNow = currentDateTime.plus({ minutes: 10 });
        logger.info("MeetingMetricsFields - minutesFromNow.toUnixInteger()", minutesFromNow.toUnixInteger());
        logger.info("MeetingMetricsFields - meetingStartDateTime.toUnixInteger()", meetingStartDateTime.toUnixInteger());
        logger.info(
          "MeetingMetricsFields - minutesFromNow difference in minutes",
          Math.ceil(
            (meetingStartDateTime.toUnixInteger() - minutesFromNow.toUnixInteger()) / 60
          )
        );
        const meetingIsCloseAndActive = minutesFromNow.toUnixInteger() > meetingStartDateTime.toUnixInteger() && currentDateTime.toUnixInteger() < meetingEndDateTime.toUnixInteger();
        if (meetingAboutToStart != meetingIsCloseAndActive) {
          logger.info("MeetingMetricsFields - setting meetingAboutToStart", { meetingIsCloseAndActive, meetingAboutToStart });
          setMeetingAboutToStart(meetingIsCloseAndActive);
        }
      }
    }, [
      buyersLoggedIn,
      buyersWithIssues,
      currentDateTime,
      record,
    ]
  );

  const title = buyersLoggedIn ? (buyersWithIssues ? "error" : "logged in") : "not logged in";
  return <Tooltip title={title}>{buyersLoggedIn ? (
    (buyersWithIssues ? (
      <ErrorIcon color={meetingAboutToStart ? "error" : undefined  } />
    ) : (
      <CheckCircleRoundedIcon />
    ))
  ) : (
    <VisibilityOffIcon color={meetingAboutToStart ? "error" : undefined  } />
  )}</Tooltip>;
};

export const MeetingMetricsIconField: React.FC<Props> = ({
  source,
  currentDateTime,
  jsonString = false,
}) => {
  const record = useRecordContext();
  if (!record) {
    return <></>;
  }
  let src = record[source];
  if (!src) {
    return <></>;
  }
  const status = record['status'];
  if (
    !status || (status && (
      status != MeetingStatus.SCHEDULED
      && status != MeetingStatus.STARTED
      && status != MeetingStatus.READY
      && status != MeetingStatus.PENDING
    ))
  ) {
    return <></>;
  }
  const [ meetingAboutToStart, setMeetingAboutToStart ] = useState<boolean>(false);
  if (jsonString) {
    src = JSON.parse(src);
  }
  const {
    sellersLoggedIn,
    buyersLoggedIn,
    sellersInMeeting,
    buyersInMeeting,
    buyersWithIssues,
    sellersWithIssues,
  } = src;
  let meetingIsStarted = false;
  const issueDetected = buyersWithIssues > 0 || sellersWithIssues > 0 || buyersLoggedIn === 0 || sellersLoggedIn === 0; // sellersInMeeting === 0 ||  buyersInMeeting === 0;
  logger.info(
    "MeetingMetricsFields - issueDetected data",
    {
      meetingId: record.id,
      buyersWithIssues,
      sellersWithIssues,
      buyersLoggedIn,
      sellersLoggedIn,
      sellersInMeeting,
      buyersInMeeting,
    }
  );
  logger.info(
    "MeetingMetricsFields - issueDetected",
    {
      meetingId: record.id,
      issueDetected,
    }
  );

  useEffect(() => {
    if (issueDetected && record) {
      const meetingStartDateTime = DateTime.fromFormat(
        record.startDateTime,
        AWS_TIMESTAMP_AS_LUXON_FORMAT,
        { zone: "utc" },
      ).toLocal();
      const meetingEndDateTime = DateTime.fromFormat(
        record.endDateTime,
        AWS_TIMESTAMP_AS_LUXON_FORMAT,
        { zone: "utc" },
      ).toLocal();

      const minutesFromNow = currentDateTime.plus({ minutes: 10 });
      logger.info("MeetingMetricsFields - minutesFromNow.toUnixInteger()", minutesFromNow.toUnixInteger());
      logger.info("MeetingMetricsFields - meetingStartDateTime.toUnixInteger()", meetingStartDateTime.toUnixInteger());
      logger.info(
        "MeetingMetricsFields - minutesFromNow difference in minutes",
        Math.ceil(
          (meetingStartDateTime.toUnixInteger() - minutesFromNow.toUnixInteger()) / 60
        )
      );
      const meetingIsCloseAndActive = minutesFromNow.toUnixInteger() > meetingStartDateTime.toUnixInteger() && currentDateTime.toUnixInteger() < meetingEndDateTime.toUnixInteger();
      if (meetingAboutToStart != meetingIsCloseAndActive) {
        logger.info("MeetingMetricsFields - setting meetingAboutToStart", { meetingIsCloseAndActive, meetingAboutToStart });
        setMeetingAboutToStart(meetingIsCloseAndActive);
      }
    }
  }, [
    sellersLoggedIn,
    buyersLoggedIn,
    sellersInMeeting,
    buyersInMeeting,
    buyersWithIssues,
    sellersWithIssues,
    currentDateTime,
    record
  ]);

  try {
    meetingIsStarted = record['status'] == MeetingStatus.STARTED;
  } catch(err) {}

  const title = meetingIsStarted ? (issueDetected ? "started with errors" : "started") : "";
  return <Tooltip title={title}>{meetingIsStarted ? (
    (issueDetected ? (
      <ErrorIcon color={meetingAboutToStart ? "error" : undefined  } />
    ) : (
      <VideocamIcon />
    ))
  ) : (
    <></>
  )}</Tooltip>;
};

