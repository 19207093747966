import React from "react";
import type { ReactElement } from "react";
import {
    ListItem,
    ListItemText,
    ListItemAvatar,
    Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
    useLocale,
    useRecordContext,
    // useTranslate,
} from "react-admin";
import {
  EventAvatar,
  TimelineGroup,
  // useEventLabel,
} from "@react-admin/ra-audit-log";
import type {
  EventRecord,
  TimelineItemProps,
} from "@react-admin/ra-audit-log";
import classnames from "classnames";
import { useGetActionLink } from "@react-admin/ra-audit-log/esm/src/useGetActionLink";


/**
 * Default component to display an audit logs.
 * @see Timeline
 */
export const CustomTimelineItem = (props: TimelineItemProps): ReactElement => {
  const { className, classes: classesOverride, link, ...rest } = props;
  const classes = useStyles(props);
  // const translate = useTranslate();
  const locale = useLocale();
  const record = useRecordContext<EventRecord>(props.record);
  // const actionLabel = useEventLabel({ record, variant: 'inline' });
  const getActionLink = useGetActionLink();

  if (!record) {
    return (<></>);
  }

  const linkTo = getActionLink(record, link);

  return (
    <ListItem
      // @ts-ignore
      component={linkTo ? Link : 'div'}
      role="listitem"
      to={linkTo}
      className={classnames(classes.root, className)}
      {...rest}
    >
    {false && (
      <ListItemAvatar>
        <EventAvatar
          alt={record.author.fullName}
          src={record.author.avatar}
          fullName={record.author.fullName}
          role="presentation"
    />
      </ListItemAvatar>
    )}
      <ListItemText
        primary={
          <Typography color="textPrimary" className={classes.content}>
            <strong className={classes.author}>
              {record.author.fullName || record.author}
            </strong>
            <span className={classes.action}>{record.action}</span>
          </Typography>
        }
        secondary={new Date(record.date).toLocaleString(locale)}
      />
    </ListItem>
  );
};

const useStyles = makeStyles(
  theme => ({
    root: {
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
      },
    },
    content: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    author: {
      marginRight: theme.spacing(0.5),
    },
    action: {},
  }),
  {
    name: 'RaTimelineItem',
  }
);


export const CustomTimelineGroup = () => {
  return (
    <TimelineGroup>
      <CustomTimelineItem />
    </TimelineGroup>
  );
};

/* export const CustomTimelineItemSimple = (props: TimelineItemProps) => {
  const record = useRecordContext(props.record);

  return (
    <ListItem>
      <ListItemText
        primary={record.author.fullName}
        secondary={<AuditLogLabel />}
      />
    </ListItem>
  );
}; */
