import React, { useState } from "react";
import {
  BooleanField,
  Create,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  Link,
  ReferenceField,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  BooleanInput,
  useRecordContext,
  required,
} from "react-admin";
import type {
  CreateProps,
  EditProps,
  ListProps,
  ShowProps,
} from "react-admin";
import DeleteWithConfirmToolbar from "./DeleteWithConfirmToolbar";
import { CustomList, ListPagination } from "./CustomList";
import TopToolbar from "./TopToolbar";

const defaultQuery = "listProfileLocations";
const validateRequired = [required()];

export const ProfileLocationList = (props: ListProps) => {
  const [query, setQuery] = useState(defaultQuery);

  return (
    <CustomList
    {...props}
    bulkActionButtons={false}
    pagination={<ListPagination />}
  >
      <Datagrid>
        <TextField source="profile.fullName" label="User name" />
        <TextField source="title" sortable={false} />
        <TextField source="city" sortable={false} />
        <TextField source="state" sortable={false} />
        <ShowButton />
        <EditButton />
      </Datagrid>
    </CustomList>
  );
};

export const ProfileLocationShow = (props: ShowProps) => {

  const ShowActions = (props: ShowProps) => {
    const {
      className,
      basePath,
      id
    } = props;
    const record = useRecordContext();
    if (!record) {
      return <></>;
    }
    return (
      <TopToolbar className={className}>
        <ShowButton
          component={Link}
          to={{
            pathname: `/profiles/${record.profileId}/show`,
          }}
          label="Return to profile"
        />
        <EditButton basePath={basePath} label="Edit" record={{ id: id as string }} />
      </TopToolbar>
    );
  };

  return (
    <Show
      {...props}
      actions={<ShowActions {...props} />}
    >
      <SimpleShowLayout>
        <TextField source="id" label="ID" fullWidth />
        <TextField source="title" />
        <TextField source="address" />
        <TextField source="city" />
        <TextField source="state" />
        <TextField source="country" />
        <ReferenceField
          label="User"
          source="profileId"
          reference="profiles"
          fullWidth
        >
          <TextField source="fullName" fullWidth />
        </ReferenceField>
        <BooleanField source="isPrimary" label="Is primary?" defaultValue="No" />
        <DateField source="createdAt" showTime={true} />
        <DateField source="updatedAt" showTime={true} />
      </SimpleShowLayout>
    </Show>
  );
}

export const ProfileLocationEdit = (props: EditProps) => {

  const EditActions = (props: EditProps) => {
    const {
      className,
      basePath,
      id
    } = props;
    const record = useRecordContext();
    if (!record) {
      return <></>;
    }
    // <ListButton basePath={basePath} />
    return (
      <TopToolbar className={className}>
        <ShowButton
          component={Link}
          to={{
            pathname: `/profiles/${record.profileId}/show`,
          }}
          label="Return to profile"
        />
        <ShowButton
          basePath={basePath}
          label="Show"
          record={{ id: id as string }}
        />
      </TopToolbar>
    );
  };

  return (
    <Edit
      {...props}
      actions={<EditActions {...props} />}
      undoable={false}
      mutationMode="pessimistic"
    >
      <SimpleForm
        toolbar={<DeleteWithConfirmToolbar />}
      >
        <TextInput source="id" label="ID" disabled />
        <TextInput
          source="title"
          isRequired
          fullWidth
          validate={validateRequired}
        />
        <TextInput
          source="address"
          isRequired
          fullWidth
          validate={validateRequired}
        />
        <TextInput source="city" fullWidth />
        <TextInput source="state" fullWidth />
        <TextInput source="country" fullWidth />
        <ReferenceInput
          source="profileId"
          reference="profiles"
          label="User"
          filterToQuery={(searchText) => ({
            listProfiles: { fullName: searchText },
          })}
          perPage={700}
          alwaysOn
          isRequired
          validate={validateRequired}
        >
          <AutocompleteInput optionText="fullName" fullWidth />
        </ReferenceInput>
        <BooleanInput
          source="isPrimary"
          label="Is primary?"
          defaultValue={false}
        />
      </SimpleForm>
    </Edit>
  );
};

export const ProfileLocationCreate = (props: CreateProps) => {

  const CreateActions = (props: CreateProps) => {
    const {
      className,
    } = props;
    const record = useRecordContext();
    if (!record) {
      return <></>;
    }
    return (
      <TopToolbar className={className}>
        <ShowButton
          component={Link}
          to={{
            pathname: `/profiles/${record.profileId}/show`,
          }}
          label="Return to profile"
        />
      </TopToolbar>
    );
  };

  return (
    <Create
      {...props}
      actions={<CreateActions {...props} />}
    >
      <SimpleForm>
        <TextInput
          source="title"
          isRequired
          fullWidth
          validate={validateRequired}
        />
        <TextInput
          source="address"
          isRequired
          fullWidth
          validate={validateRequired}
        />
        <TextInput source="city" fullWidth />
        <TextInput source="state" fullWidth />
        <TextInput source="country" fullWidth />
        <ReferenceInput
          source="profileId"
          reference="profiles"
          label="User"
          filterToQuery={(searchText) => ({
            listProfiles: { fullName: searchText },
          })}
          perPage={700}
          alwaysOn
          isRequired
          validate={validateRequired}
        >
          <AutocompleteInput optionText="fullName" fullWidth />
        </ReferenceInput>
        <BooleanInput
          source="isPrimary"
          label="Is primary?"
          defaultValue={false}
        />
      </SimpleForm>
    </Create>
  );
}

