/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateTeamMemberRelationship = /* GraphQL */ `
  subscription OnCreateTeamMemberRelationship($owner: String) {
    onCreateTeamMemberRelationship(owner: $owner) {
      id
      team {
        id
        name
        nameLowerCase
        description
        memberRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingRelationships {
          nextToken
          startedAt
          __typename
        }
        products {
          nextToken
          startedAt
          __typename
        }
        labels
        privacyEnabled
        privacyEnableOtherTeams
        images {
          nextToken
          startedAt
          __typename
        }
        transcriptions {
          nextToken
          startedAt
          __typename
        }
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      teamId
      member {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      memberProfileId
      relationship
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      profileTeamRelationshipsId
      teamMemberRelationshipsId
      __typename
    }
  }
`;
export const onUpdateTeamMemberRelationship = /* GraphQL */ `
  subscription OnUpdateTeamMemberRelationship($owner: String) {
    onUpdateTeamMemberRelationship(owner: $owner) {
      id
      team {
        id
        name
        nameLowerCase
        description
        memberRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingRelationships {
          nextToken
          startedAt
          __typename
        }
        products {
          nextToken
          startedAt
          __typename
        }
        labels
        privacyEnabled
        privacyEnableOtherTeams
        images {
          nextToken
          startedAt
          __typename
        }
        transcriptions {
          nextToken
          startedAt
          __typename
        }
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      teamId
      member {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      memberProfileId
      relationship
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      profileTeamRelationshipsId
      teamMemberRelationshipsId
      __typename
    }
  }
`;
export const onDeleteTeamMemberRelationship = /* GraphQL */ `
  subscription OnDeleteTeamMemberRelationship($owner: String) {
    onDeleteTeamMemberRelationship(owner: $owner) {
      id
      team {
        id
        name
        nameLowerCase
        description
        memberRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingRelationships {
          nextToken
          startedAt
          __typename
        }
        products {
          nextToken
          startedAt
          __typename
        }
        labels
        privacyEnabled
        privacyEnableOtherTeams
        images {
          nextToken
          startedAt
          __typename
        }
        transcriptions {
          nextToken
          startedAt
          __typename
        }
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      teamId
      member {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      memberProfileId
      relationship
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      profileTeamRelationshipsId
      teamMemberRelationshipsId
      __typename
    }
  }
`;
export const onCreateTeamMeetingRelationship = /* GraphQL */ `
  subscription OnCreateTeamMeetingRelationship($owner: String) {
    onCreateTeamMeetingRelationship(owner: $owner) {
      id
      team {
        id
        name
        nameLowerCase
        description
        memberRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingRelationships {
          nextToken
          startedAt
          __typename
        }
        products {
          nextToken
          startedAt
          __typename
        }
        labels
        privacyEnabled
        privacyEnableOtherTeams
        images {
          nextToken
          startedAt
          __typename
        }
        transcriptions {
          nextToken
          startedAt
          __typename
        }
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      teamId
      meeting {
        id
        title
        titleLowerCase
        startDateTime
        endDateTime
        status
        description
        preferredRoomProvider {
          id
          name
          componentName
          description
          configuration
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        preferredRoomProviderId
        actualStartDateTime
        actualEndDateTime
        room {
          id
          name
          meetingId
          providerId
          providerMeetingId
          accessCode
          status
          startDateTime
          endDateTime
          actualStartDateTime
          actualEndDateTime
          roomCreatedAt
          roomDestroyedAt
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          isReadinessTest
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          meetingRoomProviderMeetingRoomsId
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        videoEnabled
        transcriptionEnabled
        product {
          id
          name
          nameLowerCase
          description
          serviceInformation
          organizationId
          salesTeamId
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProductsId
          teamProductsId
          __typename
        }
        productId
        isReadinessTest
        labels
        owner
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        transcriptions {
          nextToken
          startedAt
          __typename
        }
        sellerOrganizationName
        sellerTeamName
        buyerTeamName
        sellerOrganizationNameLowerCase
        sellerTeamNameLowerCase
        buyerTeamNameLowerCase
        externalId
        metrics
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productMeetingsId
        meetingRoomProviderMeetingsId
        meetingRoomId
        __typename
      }
      meetingId
      relationshipType
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      teamMeetingRelationshipsId
      meetingTeamRelationshipsId
      __typename
    }
  }
`;
export const onUpdateTeamMeetingRelationship = /* GraphQL */ `
  subscription OnUpdateTeamMeetingRelationship($owner: String) {
    onUpdateTeamMeetingRelationship(owner: $owner) {
      id
      team {
        id
        name
        nameLowerCase
        description
        memberRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingRelationships {
          nextToken
          startedAt
          __typename
        }
        products {
          nextToken
          startedAt
          __typename
        }
        labels
        privacyEnabled
        privacyEnableOtherTeams
        images {
          nextToken
          startedAt
          __typename
        }
        transcriptions {
          nextToken
          startedAt
          __typename
        }
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      teamId
      meeting {
        id
        title
        titleLowerCase
        startDateTime
        endDateTime
        status
        description
        preferredRoomProvider {
          id
          name
          componentName
          description
          configuration
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        preferredRoomProviderId
        actualStartDateTime
        actualEndDateTime
        room {
          id
          name
          meetingId
          providerId
          providerMeetingId
          accessCode
          status
          startDateTime
          endDateTime
          actualStartDateTime
          actualEndDateTime
          roomCreatedAt
          roomDestroyedAt
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          isReadinessTest
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          meetingRoomProviderMeetingRoomsId
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        videoEnabled
        transcriptionEnabled
        product {
          id
          name
          nameLowerCase
          description
          serviceInformation
          organizationId
          salesTeamId
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProductsId
          teamProductsId
          __typename
        }
        productId
        isReadinessTest
        labels
        owner
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        transcriptions {
          nextToken
          startedAt
          __typename
        }
        sellerOrganizationName
        sellerTeamName
        buyerTeamName
        sellerOrganizationNameLowerCase
        sellerTeamNameLowerCase
        buyerTeamNameLowerCase
        externalId
        metrics
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productMeetingsId
        meetingRoomProviderMeetingsId
        meetingRoomId
        __typename
      }
      meetingId
      relationshipType
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      teamMeetingRelationshipsId
      meetingTeamRelationshipsId
      __typename
    }
  }
`;
export const onDeleteTeamMeetingRelationship = /* GraphQL */ `
  subscription OnDeleteTeamMeetingRelationship($owner: String) {
    onDeleteTeamMeetingRelationship(owner: $owner) {
      id
      team {
        id
        name
        nameLowerCase
        description
        memberRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingRelationships {
          nextToken
          startedAt
          __typename
        }
        products {
          nextToken
          startedAt
          __typename
        }
        labels
        privacyEnabled
        privacyEnableOtherTeams
        images {
          nextToken
          startedAt
          __typename
        }
        transcriptions {
          nextToken
          startedAt
          __typename
        }
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      teamId
      meeting {
        id
        title
        titleLowerCase
        startDateTime
        endDateTime
        status
        description
        preferredRoomProvider {
          id
          name
          componentName
          description
          configuration
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        preferredRoomProviderId
        actualStartDateTime
        actualEndDateTime
        room {
          id
          name
          meetingId
          providerId
          providerMeetingId
          accessCode
          status
          startDateTime
          endDateTime
          actualStartDateTime
          actualEndDateTime
          roomCreatedAt
          roomDestroyedAt
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          isReadinessTest
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          meetingRoomProviderMeetingRoomsId
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        videoEnabled
        transcriptionEnabled
        product {
          id
          name
          nameLowerCase
          description
          serviceInformation
          organizationId
          salesTeamId
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProductsId
          teamProductsId
          __typename
        }
        productId
        isReadinessTest
        labels
        owner
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        transcriptions {
          nextToken
          startedAt
          __typename
        }
        sellerOrganizationName
        sellerTeamName
        buyerTeamName
        sellerOrganizationNameLowerCase
        sellerTeamNameLowerCase
        buyerTeamNameLowerCase
        externalId
        metrics
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        productMeetingsId
        meetingRoomProviderMeetingsId
        meetingRoomId
        __typename
      }
      meetingId
      relationshipType
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      teamMeetingRelationshipsId
      meetingTeamRelationshipsId
      __typename
    }
  }
`;
export const onCreateMeeting = /* GraphQL */ `
  subscription OnCreateMeeting($owner: String) {
    onCreateMeeting(owner: $owner) {
      id
      title
      titleLowerCase
      startDateTime
      endDateTime
      status
      description
      preferredRoomProvider {
        id
        name
        componentName
        description
        configuration
        meetings {
          nextToken
          startedAt
          __typename
        }
        meetingRooms {
          nextToken
          startedAt
          __typename
        }
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      preferredRoomProviderId
      actualStartDateTime
      actualEndDateTime
      room {
        id
        name
        meeting {
          id
          title
          titleLowerCase
          startDateTime
          endDateTime
          status
          description
          preferredRoomProviderId
          actualStartDateTime
          actualEndDateTime
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          productId
          isReadinessTest
          labels
          owner
          sellerOrganizationName
          sellerTeamName
          buyerTeamName
          sellerOrganizationNameLowerCase
          sellerTeamNameLowerCase
          buyerTeamNameLowerCase
          externalId
          metrics
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productMeetingsId
          meetingRoomProviderMeetingsId
          meetingRoomId
          __typename
        }
        meetingId
        provider {
          id
          name
          componentName
          description
          configuration
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        providerId
        providerMeetingId
        accessCode
        status
        startDateTime
        endDateTime
        actualStartDateTime
        actualEndDateTime
        roomCreatedAt
        roomDestroyedAt
        auditlogs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        videoEnabled
        transcriptionEnabled
        isReadinessTest
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        meetingRoomProviderMeetingRoomsId
        __typename
      }
      questionResponses {
        items {
          id
          response
          privateNotes
          questionId
          profileId
          meetingId
          meetingInviteId
          owner
          contactRequestTimeframe
          contactRequestDateTime
          contactPhoneNumber
          contactEmail
          contactName
          providePersonalEmail
          providePersonalPhoneNumber
          textOnly
          followupMeetingRequested
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productQuestionResponsesId
          profileQuestionResponsesId
          meetingQuestionResponsesId
          meetingInviteQuestionResponsesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      privacyEnabled
      videoEnabled
      transcriptionEnabled
      product {
        id
        name
        nameLowerCase
        description
        serviceInformation
        images {
          nextToken
          startedAt
          __typename
        }
        questions {
          nextToken
          startedAt
          __typename
        }
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        salesTeam {
          id
          name
          nameLowerCase
          description
          labels
          privacyEnabled
          privacyEnableOtherTeams
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        salesTeamId
        meetings {
          nextToken
          startedAt
          __typename
        }
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        labels
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProductsId
        teamProductsId
        __typename
      }
      productId
      isReadinessTest
      labels
      owner
      teamRelationships {
        items {
          id
          teamId
          meetingId
          relationshipType
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          teamMeetingRelationshipsId
          meetingTeamRelationshipsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      meetingAttendees {
        items {
          id
          profileId
          meetingId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileMeetingAttendeesId
          meetingMeetingAttendeesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      transcriptions {
        items {
          id
          date
          title
          description
          startDateTime
          endDateTime
          sizeInBytes
          numberOfTranscriptions
          isZipFile
          meetingId
          teamId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          teamTranscriptionsId
          meetingTranscriptionsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      sellerOrganizationName
      sellerTeamName
      buyerTeamName
      sellerOrganizationNameLowerCase
      sellerTeamNameLowerCase
      buyerTeamNameLowerCase
      externalId
      metrics
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      productMeetingsId
      meetingRoomProviderMeetingsId
      meetingRoomId
      __typename
    }
  }
`;
export const onUpdateMeeting = /* GraphQL */ `
  subscription OnUpdateMeeting($owner: String) {
    onUpdateMeeting(owner: $owner) {
      id
      title
      titleLowerCase
      startDateTime
      endDateTime
      status
      description
      preferredRoomProvider {
        id
        name
        componentName
        description
        configuration
        meetings {
          nextToken
          startedAt
          __typename
        }
        meetingRooms {
          nextToken
          startedAt
          __typename
        }
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      preferredRoomProviderId
      actualStartDateTime
      actualEndDateTime
      room {
        id
        name
        meeting {
          id
          title
          titleLowerCase
          startDateTime
          endDateTime
          status
          description
          preferredRoomProviderId
          actualStartDateTime
          actualEndDateTime
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          productId
          isReadinessTest
          labels
          owner
          sellerOrganizationName
          sellerTeamName
          buyerTeamName
          sellerOrganizationNameLowerCase
          sellerTeamNameLowerCase
          buyerTeamNameLowerCase
          externalId
          metrics
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productMeetingsId
          meetingRoomProviderMeetingsId
          meetingRoomId
          __typename
        }
        meetingId
        provider {
          id
          name
          componentName
          description
          configuration
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        providerId
        providerMeetingId
        accessCode
        status
        startDateTime
        endDateTime
        actualStartDateTime
        actualEndDateTime
        roomCreatedAt
        roomDestroyedAt
        auditlogs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        videoEnabled
        transcriptionEnabled
        isReadinessTest
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        meetingRoomProviderMeetingRoomsId
        __typename
      }
      questionResponses {
        items {
          id
          response
          privateNotes
          questionId
          profileId
          meetingId
          meetingInviteId
          owner
          contactRequestTimeframe
          contactRequestDateTime
          contactPhoneNumber
          contactEmail
          contactName
          providePersonalEmail
          providePersonalPhoneNumber
          textOnly
          followupMeetingRequested
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productQuestionResponsesId
          profileQuestionResponsesId
          meetingQuestionResponsesId
          meetingInviteQuestionResponsesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      privacyEnabled
      videoEnabled
      transcriptionEnabled
      product {
        id
        name
        nameLowerCase
        description
        serviceInformation
        images {
          nextToken
          startedAt
          __typename
        }
        questions {
          nextToken
          startedAt
          __typename
        }
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        salesTeam {
          id
          name
          nameLowerCase
          description
          labels
          privacyEnabled
          privacyEnableOtherTeams
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        salesTeamId
        meetings {
          nextToken
          startedAt
          __typename
        }
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        labels
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProductsId
        teamProductsId
        __typename
      }
      productId
      isReadinessTest
      labels
      owner
      teamRelationships {
        items {
          id
          teamId
          meetingId
          relationshipType
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          teamMeetingRelationshipsId
          meetingTeamRelationshipsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      meetingAttendees {
        items {
          id
          profileId
          meetingId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileMeetingAttendeesId
          meetingMeetingAttendeesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      transcriptions {
        items {
          id
          date
          title
          description
          startDateTime
          endDateTime
          sizeInBytes
          numberOfTranscriptions
          isZipFile
          meetingId
          teamId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          teamTranscriptionsId
          meetingTranscriptionsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      sellerOrganizationName
      sellerTeamName
      buyerTeamName
      sellerOrganizationNameLowerCase
      sellerTeamNameLowerCase
      buyerTeamNameLowerCase
      externalId
      metrics
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      productMeetingsId
      meetingRoomProviderMeetingsId
      meetingRoomId
      __typename
    }
  }
`;
export const onDeleteMeeting = /* GraphQL */ `
  subscription OnDeleteMeeting($owner: String) {
    onDeleteMeeting(owner: $owner) {
      id
      title
      titleLowerCase
      startDateTime
      endDateTime
      status
      description
      preferredRoomProvider {
        id
        name
        componentName
        description
        configuration
        meetings {
          nextToken
          startedAt
          __typename
        }
        meetingRooms {
          nextToken
          startedAt
          __typename
        }
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      preferredRoomProviderId
      actualStartDateTime
      actualEndDateTime
      room {
        id
        name
        meeting {
          id
          title
          titleLowerCase
          startDateTime
          endDateTime
          status
          description
          preferredRoomProviderId
          actualStartDateTime
          actualEndDateTime
          privacyEnabled
          videoEnabled
          transcriptionEnabled
          productId
          isReadinessTest
          labels
          owner
          sellerOrganizationName
          sellerTeamName
          buyerTeamName
          sellerOrganizationNameLowerCase
          sellerTeamNameLowerCase
          buyerTeamNameLowerCase
          externalId
          metrics
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productMeetingsId
          meetingRoomProviderMeetingsId
          meetingRoomId
          __typename
        }
        meetingId
        provider {
          id
          name
          componentName
          description
          configuration
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        providerId
        providerMeetingId
        accessCode
        status
        startDateTime
        endDateTime
        actualStartDateTime
        actualEndDateTime
        roomCreatedAt
        roomDestroyedAt
        auditlogs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        videoEnabled
        transcriptionEnabled
        isReadinessTest
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        meetingRoomProviderMeetingRoomsId
        __typename
      }
      questionResponses {
        items {
          id
          response
          privateNotes
          questionId
          profileId
          meetingId
          meetingInviteId
          owner
          contactRequestTimeframe
          contactRequestDateTime
          contactPhoneNumber
          contactEmail
          contactName
          providePersonalEmail
          providePersonalPhoneNumber
          textOnly
          followupMeetingRequested
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productQuestionResponsesId
          profileQuestionResponsesId
          meetingQuestionResponsesId
          meetingInviteQuestionResponsesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      privacyEnabled
      videoEnabled
      transcriptionEnabled
      product {
        id
        name
        nameLowerCase
        description
        serviceInformation
        images {
          nextToken
          startedAt
          __typename
        }
        questions {
          nextToken
          startedAt
          __typename
        }
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        salesTeam {
          id
          name
          nameLowerCase
          description
          labels
          privacyEnabled
          privacyEnableOtherTeams
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        salesTeamId
        meetings {
          nextToken
          startedAt
          __typename
        }
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        labels
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProductsId
        teamProductsId
        __typename
      }
      productId
      isReadinessTest
      labels
      owner
      teamRelationships {
        items {
          id
          teamId
          meetingId
          relationshipType
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          teamMeetingRelationshipsId
          meetingTeamRelationshipsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      meetingAttendees {
        items {
          id
          profileId
          meetingId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileMeetingAttendeesId
          meetingMeetingAttendeesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      transcriptions {
        items {
          id
          date
          title
          description
          startDateTime
          endDateTime
          sizeInBytes
          numberOfTranscriptions
          isZipFile
          meetingId
          teamId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          teamTranscriptionsId
          meetingTranscriptionsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      sellerOrganizationName
      sellerTeamName
      buyerTeamName
      sellerOrganizationNameLowerCase
      sellerTeamNameLowerCase
      buyerTeamNameLowerCase
      externalId
      metrics
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      productMeetingsId
      meetingRoomProviderMeetingsId
      meetingRoomId
      __typename
    }
  }
`;
export const onCreateMeetingInvite = /* GraphQL */ `
  subscription OnCreateMeetingInvite($owner: String) {
    onCreateMeetingInvite(owner: $owner) {
      id
      meetingId
      provider {
        id
        name
        componentName
        description
        configuration
        meetings {
          nextToken
          startedAt
          __typename
        }
        meetingRooms {
          nextToken
          startedAt
          __typename
        }
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      providerId
      title
      startDateTime
      endDateTime
      status
      description
      questionResponses {
        items {
          id
          response
          privateNotes
          questionId
          profileId
          meetingId
          meetingInviteId
          owner
          contactRequestTimeframe
          contactRequestDateTime
          contactPhoneNumber
          contactEmail
          contactName
          providePersonalEmail
          providePersonalPhoneNumber
          textOnly
          followupMeetingRequested
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productQuestionResponsesId
          profileQuestionResponsesId
          meetingQuestionResponsesId
          meetingInviteQuestionResponsesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      privacyEnabled
      videoEnabled
      transcriptionEnabled
      product {
        id
        name
        nameLowerCase
        description
        serviceInformation
        images {
          nextToken
          startedAt
          __typename
        }
        questions {
          nextToken
          startedAt
          __typename
        }
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        salesTeam {
          id
          name
          nameLowerCase
          description
          labels
          privacyEnabled
          privacyEnableOtherTeams
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        salesTeamId
        meetings {
          nextToken
          startedAt
          __typename
        }
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        labels
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProductsId
        teamProductsId
        __typename
      }
      productId
      isReadinessTest
      meetingRoomId
      providerSessionId
      providerJoinData
      sessionStatus
      actualStartDateTime
      actualEndDateTime
      profile {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      profileId
      owner
      meetingInvitationType
      teamId
      isInterested
      feedbackSummary
      contactRequestTimeframe
      contactPhoneNumber
      contactEmail
      sellerOrganizationName
      sellerTeamName
      buyerTeamName
      transcriptionLogs {
        items {
          id
          date
          author
          resource
          action
          payload
          ipAddress
          meetingInviteId
          profileId
          authorProfileId
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileTranscriptionLogsId
          meetingInviteTranscriptionLogsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      productMeetingInvitesId
      profileMeetingInvitesId
      meetingRoomProviderMeetingInvitesId
      __typename
    }
  }
`;
export const onUpdateMeetingInvite = /* GraphQL */ `
  subscription OnUpdateMeetingInvite($owner: String) {
    onUpdateMeetingInvite(owner: $owner) {
      id
      meetingId
      provider {
        id
        name
        componentName
        description
        configuration
        meetings {
          nextToken
          startedAt
          __typename
        }
        meetingRooms {
          nextToken
          startedAt
          __typename
        }
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      providerId
      title
      startDateTime
      endDateTime
      status
      description
      questionResponses {
        items {
          id
          response
          privateNotes
          questionId
          profileId
          meetingId
          meetingInviteId
          owner
          contactRequestTimeframe
          contactRequestDateTime
          contactPhoneNumber
          contactEmail
          contactName
          providePersonalEmail
          providePersonalPhoneNumber
          textOnly
          followupMeetingRequested
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productQuestionResponsesId
          profileQuestionResponsesId
          meetingQuestionResponsesId
          meetingInviteQuestionResponsesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      privacyEnabled
      videoEnabled
      transcriptionEnabled
      product {
        id
        name
        nameLowerCase
        description
        serviceInformation
        images {
          nextToken
          startedAt
          __typename
        }
        questions {
          nextToken
          startedAt
          __typename
        }
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        salesTeam {
          id
          name
          nameLowerCase
          description
          labels
          privacyEnabled
          privacyEnableOtherTeams
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        salesTeamId
        meetings {
          nextToken
          startedAt
          __typename
        }
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        labels
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProductsId
        teamProductsId
        __typename
      }
      productId
      isReadinessTest
      meetingRoomId
      providerSessionId
      providerJoinData
      sessionStatus
      actualStartDateTime
      actualEndDateTime
      profile {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      profileId
      owner
      meetingInvitationType
      teamId
      isInterested
      feedbackSummary
      contactRequestTimeframe
      contactPhoneNumber
      contactEmail
      sellerOrganizationName
      sellerTeamName
      buyerTeamName
      transcriptionLogs {
        items {
          id
          date
          author
          resource
          action
          payload
          ipAddress
          meetingInviteId
          profileId
          authorProfileId
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileTranscriptionLogsId
          meetingInviteTranscriptionLogsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      productMeetingInvitesId
      profileMeetingInvitesId
      meetingRoomProviderMeetingInvitesId
      __typename
    }
  }
`;
export const onDeleteMeetingInvite = /* GraphQL */ `
  subscription OnDeleteMeetingInvite($owner: String) {
    onDeleteMeetingInvite(owner: $owner) {
      id
      meetingId
      provider {
        id
        name
        componentName
        description
        configuration
        meetings {
          nextToken
          startedAt
          __typename
        }
        meetingRooms {
          nextToken
          startedAt
          __typename
        }
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      providerId
      title
      startDateTime
      endDateTime
      status
      description
      questionResponses {
        items {
          id
          response
          privateNotes
          questionId
          profileId
          meetingId
          meetingInviteId
          owner
          contactRequestTimeframe
          contactRequestDateTime
          contactPhoneNumber
          contactEmail
          contactName
          providePersonalEmail
          providePersonalPhoneNumber
          textOnly
          followupMeetingRequested
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          productQuestionResponsesId
          profileQuestionResponsesId
          meetingQuestionResponsesId
          meetingInviteQuestionResponsesId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      privacyEnabled
      videoEnabled
      transcriptionEnabled
      product {
        id
        name
        nameLowerCase
        description
        serviceInformation
        images {
          nextToken
          startedAt
          __typename
        }
        questions {
          nextToken
          startedAt
          __typename
        }
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        salesTeam {
          id
          name
          nameLowerCase
          description
          labels
          privacyEnabled
          privacyEnableOtherTeams
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        salesTeamId
        meetings {
          nextToken
          startedAt
          __typename
        }
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        labels
        owner
        sortByName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProductsId
        teamProductsId
        __typename
      }
      productId
      isReadinessTest
      meetingRoomId
      providerSessionId
      providerJoinData
      sessionStatus
      actualStartDateTime
      actualEndDateTime
      profile {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      profileId
      owner
      meetingInvitationType
      teamId
      isInterested
      feedbackSummary
      contactRequestTimeframe
      contactPhoneNumber
      contactEmail
      sellerOrganizationName
      sellerTeamName
      buyerTeamName
      transcriptionLogs {
        items {
          id
          date
          author
          resource
          action
          payload
          ipAddress
          meetingInviteId
          profileId
          authorProfileId
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          profileTranscriptionLogsId
          meetingInviteTranscriptionLogsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      productMeetingInvitesId
      profileMeetingInvitesId
      meetingRoomProviderMeetingInvitesId
      __typename
    }
  }
`;
export const onCreateExportJobAuditLog = /* GraphQL */ `
  subscription OnCreateExportJobAuditLog($owner: String) {
    onCreateExportJobAuditLog(owner: $owner) {
      id
      date
      author
      resource
      action
      payload
      ipAddress
      exportJob {
        id
        status
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        meetingInvitationType
        startDateTime
        endDateTime
        owner
        identityId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        profileExportJobsId
        __typename
      }
      exportJobId
      profile {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      profileId
      owner
      source {
        bucket
        region
        key
        __typename
      }
      identityId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      profileExportJobAuditLogsId
      exportJobAuditLogsId
      __typename
    }
  }
`;
export const onCreateImportJobAuditLog = /* GraphQL */ `
  subscription OnCreateImportJobAuditLog($owner: String) {
    onCreateImportJobAuditLog(owner: $owner) {
      id
      date
      author
      resource
      action
      payload
      ipAddress
      importJob {
        id
        status
        source {
          bucket
          region
          key
          __typename
        }
        identityId
        result
        profile {
          id
          fullName
          fullNameLowerCase
          email
          externalId
          userId
          occupation
          profile
          phoneNumber
          organizationId
          privacyEnabled
          owner
          sortByFullName
          timeZone
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organizationProfilesId
          __typename
        }
        profileId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        lastStartDateTime
        lastEndDateTime
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        profileImportJobsId
        __typename
      }
      importJobId
      profile {
        id
        fullName
        fullNameLowerCase
        email
        externalId
        userId
        occupation
        profile
        phoneNumber
        organization {
          id
          externalId
          name
          nameLowerCase
          description
          url
          phoneNumber
          labels
          owner
          sortByName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        jobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        exportJobAuditLogs {
          nextToken
          startedAt
          __typename
        }
        teamRelationships {
          nextToken
          startedAt
          __typename
        }
        meetingAttendees {
          nextToken
          startedAt
          __typename
        }
        locations {
          nextToken
          startedAt
          __typename
        }
        devices {
          nextToken
          startedAt
          __typename
        }
        images {
          nextToken
          startedAt
          __typename
        }
        questionResponses {
          nextToken
          startedAt
          __typename
        }
        importJobs {
          nextToken
          startedAt
          __typename
        }
        exportJobs {
          nextToken
          startedAt
          __typename
        }
        privacyEnabled
        owner
        meetingInvites {
          nextToken
          startedAt
          __typename
        }
        sortByFullName
        transcriptionLogs {
          nextToken
          startedAt
          __typename
        }
        timeZone
        forwardEmails {
          nextToken
          startedAt
          __typename
        }
        sellerForwardEmails {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organizationProfilesId
        __typename
      }
      profileId
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      profileJobAuditLogsId
      importJobAuditLogsId
      __typename
    }
  }
`;
export const onCreateNotification = /* GraphQL */ `
  subscription OnCreateNotification($owner: String) {
    onCreateNotification(owner: $owner) {
      id
      date
      title
      payload
      read
      dateRead
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateNotification = /* GraphQL */ `
  subscription OnUpdateNotification($owner: String) {
    onUpdateNotification(owner: $owner) {
      id
      date
      title
      payload
      read
      dateRead
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateTranscriptionJob = /* GraphQL */ `
  subscription OnCreateTranscriptionJob($owner: String) {
    onCreateTranscriptionJob(owner: $owner) {
      id
      date
      status
      result {
        bucket
        region
        key
        __typename
      }
      meetingInviteId
      auditLogs {
        items {
          id
          date
          author
          resource
          action
          payload
          ipAddress
          transcriptionJobId
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          transcriptionJobAuditLogsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      owner
      identityId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateTranscriptionJob = /* GraphQL */ `
  subscription OnUpdateTranscriptionJob($owner: String) {
    onUpdateTranscriptionJob(owner: $owner) {
      id
      date
      status
      result {
        bucket
        region
        key
        __typename
      }
      meetingInviteId
      auditLogs {
        items {
          id
          date
          author
          resource
          action
          payload
          ipAddress
          transcriptionJobId
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          transcriptionJobAuditLogsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      owner
      identityId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateTranscriptionJobAuditLog = /* GraphQL */ `
  subscription OnCreateTranscriptionJobAuditLog($owner: String) {
    onCreateTranscriptionJobAuditLog(owner: $owner) {
      id
      date
      author
      resource
      action
      payload
      ipAddress
      transcriptionJob {
        id
        date
        status
        result {
          bucket
          region
          key
          __typename
        }
        meetingInviteId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        owner
        identityId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      transcriptionJobId
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      transcriptionJobAuditLogsId
      __typename
    }
  }
`;
export const onUpdateTranscriptionJobAuditLog = /* GraphQL */ `
  subscription OnUpdateTranscriptionJobAuditLog($owner: String) {
    onUpdateTranscriptionJobAuditLog(owner: $owner) {
      id
      date
      author
      resource
      action
      payload
      ipAddress
      transcriptionJob {
        id
        date
        status
        result {
          bucket
          region
          key
          __typename
        }
        meetingInviteId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        owner
        identityId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      transcriptionJobId
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      transcriptionJobAuditLogsId
      __typename
    }
  }
`;
export const onCreateTranscriptionAdminJob = /* GraphQL */ `
  subscription OnCreateTranscriptionAdminJob($owner: String) {
    onCreateTranscriptionAdminJob(owner: $owner) {
      id
      date
      status
      result {
        bucket
        region
        key
        __typename
      }
      meetingId
      meetingInviteId
      auditLogs {
        items {
          id
          date
          author
          resource
          action
          payload
          ipAddress
          transcriptionAdminJobId
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          transcriptionAdminJobAuditLogsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      owner
      identityId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateTranscriptionAdminJob = /* GraphQL */ `
  subscription OnUpdateTranscriptionAdminJob($owner: String) {
    onUpdateTranscriptionAdminJob(owner: $owner) {
      id
      date
      status
      result {
        bucket
        region
        key
        __typename
      }
      meetingId
      meetingInviteId
      auditLogs {
        items {
          id
          date
          author
          resource
          action
          payload
          ipAddress
          transcriptionAdminJobId
          owner
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          transcriptionAdminJobAuditLogsId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      owner
      identityId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateTranscriptionAdminJobAuditLog = /* GraphQL */ `
  subscription OnCreateTranscriptionAdminJobAuditLog($owner: String) {
    onCreateTranscriptionAdminJobAuditLog(owner: $owner) {
      id
      date
      author
      resource
      action
      payload
      ipAddress
      transcriptionAdminJob {
        id
        date
        status
        result {
          bucket
          region
          key
          __typename
        }
        meetingId
        meetingInviteId
        auditLogs {
          nextToken
          startedAt
          __typename
        }
        owner
        identityId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      transcriptionAdminJobId
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      transcriptionAdminJobAuditLogsId
      __typename
    }
  }
`;