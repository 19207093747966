import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { Logger } from "aws-amplify";
import {
  Box,
  CircularProgress,
  CircularProgressProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableCell: {
      padding: theme.spacing(0),
      borderBottomWidth: 0,
    },
  }),
);

const logger = new Logger("CircularCountdown");

export interface CircularCountdownProps {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

interface CircularProgressWithLabelProps extends CircularProgressProps {
  value: number;
  unit: string;
  padding: string;
}

function CircularProgressWithLabel(props: CircularProgressWithLabelProps) {
  const {
    padding,
    unit,
  } = props;
  let {
    value,
  } = props;
  const valueDisplay = value < 10 && padding ? `${padding}${value}` : `${value}`;
  const valueDisplayWithUnit = `${valueDisplay}${unit}`;
  if (["m", "s",].includes(unit)) {
    props = {
      ...props,
      ...{
        value: Math.round(value / 60 * 100)
      }
    };
  } else if (["h"].includes(unit)) {
    props = {
      ...props,
      ...{
        value: Math.round(value / 24 * 100)
      }
    };
  } else if (["d"].includes(unit)) {
    props = {
      ...props,
      ...{
        value: Math.round(value / 30 * 100)
      }
    };
  }

  props = {
    ...props,
    ...{
      value: Math.abs(props.value - 100)
    }
  };

  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="h4" color="textSecondary">{valueDisplayWithUnit}</Typography>
      </Box>
    </Box>
  );
}

export const CircularCountdown = (props: CircularCountdownProps) => {

  const { 
    days,
    hours,
    minutes,
    seconds,
  } = props;

  const classes = useStyles();
  // variant="outlined"
  // 
  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableBody>
          <TableRow key="clock">
            {days > 0 && (
            <TableCell
              align="center"
              className={classes.tableCell}
            >
                <CircularProgressWithLabel
                  value={days}
                  unit="d"
                  size={84}
                  padding=" "
                  disableShrink
                />
            </TableCell>
            )}
            {hours > 0 && (
            <TableCell
              align="center"
              className={classes.tableCell}
            >
                <CircularProgressWithLabel
                  value={hours}
                  unit="h"
                  size={84}
                  padding=" "
                  disableShrink
                />
            </TableCell>
            )}
            {(days === 0 && minutes > 0) && (
            <TableCell
              align="center"
              className={classes.tableCell}
            >
                <CircularProgressWithLabel
                  value={minutes}
                  unit="m"
                  size={84}
                  padding="0"
                  disableShrink
                />
            </TableCell>
            )}
            {days === 0 && hours === 0 && (
          <TableCell
            align="center"
            className={classes.tableCell}
          >
                <CircularProgressWithLabel
                  value={seconds}
                  unit="s"
                  size={84}
                  padding="0"
                  disableShrink
                />
            </TableCell>
            )}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
