import React, { useEffect, useMemo, useState } from "react";
import { useTimer } from "react-timer-hook";
import type { MeetingCountdownProps } from "../types";
import { Logger, Cache } from "aws-amplify";
import {
  awsDateTimeStringToDateTime,
  getMeetingInvite,
  getMeetingToken,
  inDateTimeRange,
  logEvent,
  offsetDate,
  privacyEnabledCacheName,
  redirectToDaily
} from "../lib/helpers";
import { CircularCountdown } from "./CircularCountdown";
import { MeetingInvitationType } from "../models";
import type { MeetingInvite } from "../API";

const logger = new Logger("MeetingCountdown");


const MeetingCountdown = (props: MeetingCountdownProps) => {
  const {
    userAgentData,
    clockSyncEnabled,
    cachedServerTimeDifference,
    nextMeetingInvite,
    useEndDateTime,
    onExpire,
    currentProfile,
  } = props;
  const expiryTimestamp = useMemo<Date>(() => {
    return offsetDate(
      new Date(useEndDateTime ?  Date.parse(nextMeetingInvite.endDateTime) : Date.parse(nextMeetingInvite.startDateTime)),
      cachedServerTimeDifference * -1,
      clockSyncEnabled,
    );
  }, [
    useEndDateTime,
    nextMeetingInvite,
    cachedServerTimeDifference,
    clockSyncEnabled,
  ]);
  const defaultOnExpire = async (meetingInvite: MeetingInvite) => {
    logger.info("MeetingCountdown onExpire called with meetingInvite", meetingInvite);
    if (useEndDateTime) {
      logger.info("MeetingCountdown onExpire called with useEndDateTime", { isRunning, expiryTimestamp });
    } else {
      logger.info("MeetingCountdown onExpire called");
      if (!inDateTimeRange(
        nextMeetingInvite,
        cachedServerTimeDifference,
        clockSyncEnabled,
      )) {
        logger.warn("nextMeetingInvite nextMeetingInvite startDate is not in range", { nextMeetingInvite, serverTimeDifference: cachedServerTimeDifference });
      }
      const {
        provider,
        providerId,
        meetingId,
        providerJoinData,
        transcriptionEnabled,
      } = nextMeetingInvite;
      logger.info("provider", provider);
      logger.info("providerId", providerId);
      const endTimestamp = awsDateTimeStringToDateTime(
        nextMeetingInvite.endDateTime,
      ).toUnixInteger();

      const privacyCacheName = privacyEnabledCacheName(nextMeetingInvite.id);
      const privacyEnabled = Cache.getItem(privacyCacheName);

      if (provider && provider.componentName === "Daily") {
        const roomName = meetingId.split("-").shift();
        const roomUrl = `https://physician.daily.co/${roomName}`;
        let token = '';
        if (
          !nextMeetingInvite.providerJoinData
        ) {
          logger.warn("nextMeetingInvite.providerJoinData is empty");
          await logEvent(
            currentProfile,
            "providerJoinData is empty",
            {
              meeting: {
                id: nextMeetingInvite.meetingId,
                invite_id: nextMeetingInvite.id,
              },
              details: nextMeetingInvite,
            },
            'warn',
            cachedServerTimeDifference,
            userAgentData,
          );
          await getMeetingInvite(nextMeetingInvite.id).then(
            async (currentNextMeetingInvite) => {
              if (currentNextMeetingInvite) {
                if (currentNextMeetingInvite.providerJoinData) {
                  if (typeof currentNextMeetingInvite.providerJoinData === "string") {
                    currentNextMeetingInvite.providerJoinData =  JSON.parse(currentNextMeetingInvite.providerJoinData);
                  }
                  const parsedProviderJoinData = currentNextMeetingInvite.providerJoinData as { token?: string };
                  logger.info("parsedProviderJoinData", parsedProviderJoinData);
                  if (parsedProviderJoinData.token) {
                    token = parsedProviderJoinData.token;
                  }
                }
                if (!token) {
                  logger.warn("token is empty");
                  await logEvent(
                    currentProfile,
                    "token is empty",
                    {
                      meeting: {
                        id: currentNextMeetingInvite.meetingId,
                        invite_id: currentNextMeetingInvite.id,
                      },
                      details: currentNextMeetingInvite,
                    },
                    'warn',
                    cachedServerTimeDifference,
                    userAgentData,
                  );
                  token = await getMeetingToken(currentNextMeetingInvite.id).then(
                    async (tokenResponse) => {
                      logger.info("tokenResponse", tokenResponse);
                      if (tokenResponse) {
                        await logEvent(
                          currentProfile,
                          "token is now set",
                          {
                            meeting: {
                              id: currentNextMeetingInvite.meetingId,
                              invite_id: currentNextMeetingInvite.id,
                            },
                            details: tokenResponse,
                          },
                          'info',
                          cachedServerTimeDifference,
                          userAgentData,
                        );
                        return tokenResponse.token;
                      }
                      return '';
                    }
                  ).catch(
                    async (err) => {
                      logger.error("tokenResponse error", err);
                      await logEvent(
                        currentProfile,
                        "tokenResponse error",
                        {
                          meeting: {
                            id: currentNextMeetingInvite.meetingId,
                            invite_id: currentNextMeetingInvite.id,
                          },
                          details: `${err}`,
                        },
                        'error',
                        cachedServerTimeDifference,
                        userAgentData,
                      );
                      return '';
                    }
                  );
                } else {
                  await logEvent(
                    currentProfile,
                    "token retrieved from current invite record",
                    {
                      meeting: {
                        id: currentNextMeetingInvite.meetingId,
                        invite_id: currentNextMeetingInvite.id,
                      },
                      details: currentNextMeetingInvite.providerJoinData,
                    },
                    'info',
                    cachedServerTimeDifference,
                    userAgentData,
                  );
                }
                if (!token) {
                  await logEvent(
                    currentProfile,
                    "set token failed",
                    {
                      meeting: {
                        id: currentNextMeetingInvite.meetingId,
                        invite_id: currentNextMeetingInvite.id,
                      },
                      details: currentNextMeetingInvite,
                    },
                    'warn',
                    cachedServerTimeDifference,
                    userAgentData,
                  );
                }
                const redirectProps = {
                  room: roomUrl,
                  meetingId: currentNextMeetingInvite.meetingId,
                  meetingInviteId: currentNextMeetingInvite.id,
                  meetingRoomId: `${currentNextMeetingInvite.meetingRoomId}`,
                  profileId: `${currentNextMeetingInvite.profileId}`,
                  privacyEnabled,
                  fullName: privacyEnabled ? "" : currentProfile.fullName,
                  transcriptionEnabled: transcriptionEnabled ? "true" : "false",
                  serverTimeDifference: `${cachedServerTimeDifference}`,
                  endTimestamp: `${endTimestamp}`,
                  ...(token && { token:`${token}` }),
                };
                redirectToDaily(redirectProps);
              } else {
                const redirectProps = {
                  room: roomUrl,
                  meetingId: nextMeetingInvite.meetingId,
                  meetingInviteId: nextMeetingInvite.id,
                  meetingRoomId: `${nextMeetingInvite.meetingRoomId}`,
                  profileId: `${nextMeetingInvite.profileId}`,
                  privacyEnabled,
                  fullName: privacyEnabled ? "" : currentProfile.fullName,
                  transcriptionEnabled: transcriptionEnabled ? "true" : "false",
                  serverTimeDifference: `${cachedServerTimeDifference}`,
                  endTimestamp: `${endTimestamp}`,
                  ...(token && { token:`${token}` }),
                };
                redirectToDaily(redirectProps);
              }
            }
          );
        } else {
          logger.info("MeetingCountdown nextMeetingInvite.providerJoinData", nextMeetingInvite.providerJoinData);
          if (nextMeetingInvite.providerJoinData) {
            token = (nextMeetingInvite.providerJoinData as unknown as { token: string }).token;
            logger.info("MeetingCountdown token 1", token);
          }
          logger.info("MeetingCountdown token 2", token);
          const props = {
            room: roomUrl,
            meetingId: nextMeetingInvite.meetingId,
            meetingInviteId: nextMeetingInvite.id,
            meetingRoomId: `${nextMeetingInvite.meetingRoomId}`,
            profileId: `${nextMeetingInvite.profileId}`,
            privacyEnabled,
            fullName: privacyEnabled ? "" : currentProfile.fullName,
            transcriptionEnabled: transcriptionEnabled ? "true" : "false",
            serverTimeDifference: `${cachedServerTimeDifference}`,
            endTimestamp: `${endTimestamp}`,
            ...(token && { token:`${token}` }),
          };
          redirectToDaily(props);
        }
      } else if (provider && provider.componentName === "Signalwire") {
        const roomName = meetingId.split("-").shift();
        const paramsData = {
          r: `${roomName}`,
          u: `${nextMeetingInvite.profileId}`,
          n: "",
          t: `${(nextMeetingInvite.providerJoinData as unknown as { token: string }).token}`,
          m: nextMeetingInvite.meetingInvitationType === MeetingInvitationType.ORGANIZER ? "mod" : "",
          meetingId: nextMeetingInvite.meetingId,
          meetingInviteId: nextMeetingInvite.id,
          meetingRoomId: `${nextMeetingInvite.meetingRoomId}`,
          privacyEnabled: privacyEnabled ? "true" : "false",
          fullName: privacyEnabled ? "" : currentProfile.fullName,
          serverTimeDifference: `${cachedServerTimeDifference}`,
          endTimestamp: `${endTimestamp}`
        };
        logger.info("paramsData", paramsData);
        const params = new URLSearchParams(paramsData);
        const url = `https://video.insightgateway.com/?${params.toString()}`;
        logger.info("MeetingCountdown redirecting to url...", url);
        window.location.href = url;
      } else {
        if (!providerJoinData) {
          logger.warn("providerJoinData is empty");
        }
      }
    }
  };

  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    // start,
    pause,
    // resume,
    restart,
  } = useTimer(
    {
      expiryTimestamp,
      autoStart: false,
      onExpire: onExpire ? () => {
        pause();
        onExpire();
      } : () => {
        pause();
        defaultOnExpire(nextMeetingInvite);
      }
    }
  );

  useEffect(() => {
    logger.info("expiryTimestamp restart timer useeffect called", { expiryTimestamp });
    restart(expiryTimestamp);
  }, [expiryTimestamp]);

  return (
    <CircularCountdown
      days={days}
      hours={hours}
      minutes={minutes}
      seconds={seconds}
    />
  );
};

export default MeetingCountdown;
