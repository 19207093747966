import React, { useContext, useEffect } from "react";
import { Logger } from "aws-amplify";
import { useRedirect } from "react-admin";
import { useParams } from "react-router-dom";
import { logEvent, stopMeeting } from "../lib/helpers";
import { MeetingsStateContext } from "../App";
import { usePreferences } from "@react-admin/ra-preferences";

const logger = new Logger("StopMeetingPage");

function StopMeetingPage() {

  const params: any = useParams();
  const redirect = useRedirect();
  const meetingsState = useContext(MeetingsStateContext);
  const [ cachedServerTimeDifference ] = usePreferences("cachedServerTimeDifference", 0);
  const {
    currentProfile,
    userAgentData,
  } = meetingsState;

  useEffect(() => {
    logger.info("stopMeeting params.roomName", params.roomName);
    if (params.roomName) {
      stopMeeting(params.roomName).then(
        async (stopData) => {
          logger.info("stopData", stopData);
          if (currentProfile) {
            await logEvent(
              currentProfile,
              'meeting stop called',
              {
                details: stopData,
              },
              'info',
              cachedServerTimeDifference,
              userAgentData,
            );
          }
        }
      ).catch(
        async (err) => {
          logger.error("stopMeeting error", err);
          if (currentProfile) {
            await logEvent(
              currentProfile,
              'meeting stop error',
              {},
              'error',
              cachedServerTimeDifference,
              userAgentData,
            );
          }
        }
      ).finally(
        () => {
          redirect("/");
        }
      );
    }
  }, [params.roomName]);

  return (
    <></>
  );
}

export default StopMeetingPage;