import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  AutocompleteInput,
  Datagrid,
  DateField,
  FilterButton,
  ReferenceField,
  ReferenceInput,
  RefreshButton,
  Show,
  ShowButton,
  SimpleShowLayout,
  TextField,
  useDataProvider,
  useNotify,
  useRefresh,
} from "react-admin";
import { Storage } from "@aws-amplify/storage";
import type { ListProps, ShowProps } from "react-admin";
import { AmplifyFileField } from "./AmplifyFileField";
import { DateTimeInput as MaterialDateTimeInput } from "./MaterialDatePicker";
import {
  RealTimeList,
  RecordListEvent,
  useSubscribe,
} from "@react-admin/ra-realtime";
import { JsonField } from "react-admin-json-view";
import { onCreateTranscriptionJobAuditLog } from "../graphql/subscriptions";
import { API, graphqlOperation, Logger } from "aws-amplify";
import { MeetingsStateContext } from "../App";
import { LogPayloadField } from "./CustomFields";
import { useLocation } from "react-router-dom";
import type { TranscriptionJob } from "../API";
import { downloadFileFromS3 } from "../lib/helpers";
import { ListPagination } from "./CustomList";
import TopToolbar from "./TopToolbar";

const logger = new Logger("TranscriptionJobAudiLog");
const defaultQuery = "listTranscriptionJobAuditLogs";
const filters = [
  /* <ReferenceInput
    source="profileId"
    reference="profiles"
    label="User"
    filterToQuery={(searchText) => ({
      listProfiles: { fullName: searchText },
    })}
    perPage={500}
    resettable
    alwaysOn
    >
    <AutocompleteInput optionText="fullName" />
  </ReferenceInput>, */
  <ReferenceInput
    source="transcriptionJobId"
    reference="transcriptionJobs"
    label="Transcription job"
    filterToQuery={(searchText) => ({
      listTranscriptionJobs: { id: searchText },
    })}
    perPage={2000}
    resettable
    >
    <AutocompleteInput optionText="id" />
  </ReferenceInput>,
  <MaterialDateTimeInput
    label="Begin Date Range"
    source="date"
    options={{ format: 'MM/dd/yyyy, hh:mm a', ampm: true, clearable: true }}
  />,
  <MaterialDateTimeInput
    label="End Date Range"
    source="endDate"
    options={{ format: 'MM/dd/yyyy, hh:mm a', ampm: true, clearable: true }}
  />,
];

const ListActions = (props: any) => {
  const {
    className,
    basePath,
    total,
    resource,
    currentSort,
    filters,
    filterValues,
    displayedFilters,
    showFilter,
  } = props;
  return (
    <TopToolbar className={className}>
      <FilterButton
        resource={resource}
        filters={filters}
        filterValues={filterValues}
        displayedFilters={displayedFilters}
        showFilter={showFilter}
      />
      <RefreshButton />
    </TopToolbar>
  );
};

function useUrlQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

export const TranscriptionJobAuditLogList = (props: ListProps) => {
  const [query, setQuery] = useState(defaultQuery);
  const refresh = useRefresh(); // TODO refresh at interval of implement realtime updates
  const notify = useNotify();
  const urlQuery = useUrlQuery();
  const meetingsState = useContext(MeetingsStateContext);
  const {
    currentProfile,
  } = meetingsState;
  const dataProvider = useDataProvider();

  const handleEventReceived = (event: RecordListEvent) => {
    // const count = get(event, "payload.ids.length", 1);
    logger.info("handleEventReceived event", event);
    // 'List refreshed with %{smart_count} %{name} %{type} by another user'
    // notify("ra-realtime.notification.list.refreshed", "info", event);
    notify("ra-realtime.action.refresh", "info");
    refresh();
  };

  useSubscribe("resource/transcriptionJobAuditLogs", (event) => {
      logger.info("useSubscribe event", event);
  });

  useEffect(
    () => {
      if (currentProfile?.userId) {
        const subscription = API.graphql(
          graphqlOperation(onCreateTranscriptionJobAuditLog, { owner: currentProfile?.userId })
        ).subscribe({
          next: ({ value }: any) => {
            logger.info("value.data.onCreateTranscriptionJobAuditLog", value.data.onCreateTranscriptionJobAuditLog);
            logger.info("value.data.onCreateTranscriptionJobAuditLog.id", value.data.onCreateTranscriptionJobAuditLog.id);
            const {
              action,
              transcriptionJobId,
            } = value.data.onCreateTranscriptionJobAuditLog;
            if (action === 'complete') {
              // NOTE: get result (S3Object) from TranscriptionJob
              dataProvider.getOne(
                "transcriptionJobs",
                {
                  id: transcriptionJobId,
                },
              ).then(
                (t) => {
                  logger.info("transcriptionJob results", t);
                  if (t && t.data) {
                    const transcriptionJob = t.data as TranscriptionJob;
                    logger.info("transcriptionJob", transcriptionJob);
                    const transcriptionJobId = urlQuery.get("transcriptionJobId");
                    logger.info("transcriptionJobId", transcriptionJobId);
                    if (transcriptionJob.id === transcriptionJobId) {
                      const {
                        result
                      } = transcriptionJob;
                      if (result) {
                        logger.info("result", result);
                        const {
                          key,
                        } = result;
                        logger.info("key", key);
                        if (key) {
                          downloadFileFromS3(key, Storage, "public");
                        }
                      } else {
                        logger.info("no result yet");
                      }
                    }
                  }
                }
              );
            }
            dataProvider.publish(
              "resource/transcriptionJobAuditLogs",
              {
                type: "created",
                topic: "resource/transcriptionJobAuditLogs",
                payload: { ids: [ value.data.onCreateTranscriptionJobAuditLog.id ]},
                date: new Date(),
              },
            );
          },
          error: (error: Error) => {
            logger.warn("onCreateTranscriptionJobAuditLog failed", error);
          }
        });
        return () => {
          subscription.unsubscribe();
        }
      }
    },
    [ currentProfile?.userId ]
  );

  return (
    <RealTimeList
      {...props}
      filters={filters}
      filterDefaultValues={{ profileId: currentProfile?.id }}
      actions={<ListActions />}
      onEventReceived={handleEventReceived}
      bulkActionButtons={false}
      perPage={200}
      pagination={<ListPagination rowsPerPageOptions={
        [200, 300, 400, 500, 600, 700, 800, 1000]
      } />}
    >
      <Datagrid>
        <DateField source="date" showTime={true} sortable={true} />
        <TextField source="transcriptionJob.id" label="Job ID" sortable={false} />
        <TextField source="resource" sortable={false} />
        <TextField source="action" sortable={false} />
        <LogPayloadField source="payload" label="Message" sortable={false} />
        <ShowButton />
      </Datagrid>
    </RealTimeList>
  );
};

export const TranscriptionJobAuditLogShow = (props: ShowProps) => {

  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="id" label="ID" />
        <DateField source="date" showTime={true} />
        <TextField source="author" />
        <TextField source="resource" />
        <TextField source="action" />
        <JsonField
          source="payload"
          addLabel={true}
          jsonString={true}
          reactJsonOptions={{
            // shapeshifter:inverted
            theme: "shapeshifter",
            name: null,
            collapsed: true,
            enableClipboard: false,
            displayDataTypes: false,
          }}
        />
        <TextField source="ipAddress" label="IP address" />
        <ReferenceField
          source="transcriptionJobId"
          reference="transcriptionJobs"
          label="Transcription job"
          link="show"
        >
          <TextField source="id" />
        </ReferenceField>
        <ReferenceField
          source="profileId"
          reference="profiles"
          label="User"
          link="show"
        >
          <TextField source="fullName" />
        </ReferenceField>
        <AmplifyFileField
          source="source"
          download
          storageOptions={{ level: "public" }}
        />
        <DateField source="createdAt" showTime={true} />
        <DateField source="updatedAt" showTime={true} />
      </SimpleShowLayout>
    </Show>
  );
}
