import React from "react";
import { FieldProps, useRecordContext } from "react-admin";
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import ErrorIcon from '@material-ui/icons/Error';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { Tooltip } from "@material-ui/core";
import { Logger } from "aws-amplify";
import { MeetingStatus } from "../API";
import VideocamIcon from '@material-ui/icons/Videocam';
// import VideocamOffIcon from '@material-ui/icons/VideocamOff';
// import MicOffIcon from '@material-ui/icons/MicOff';
// import VisibilityIcon from '@material-ui/icons/Visibility';
// import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
// See for customizations: https://v4.mui.com/customization/globals/#default-props

const logger = new Logger("ProfileDeviceField");

type Props = {
  source: string;
} & FieldProps;

export const ProfileDeviceIconField: React.FC<Props> = ({
  source,
}) => {
  const record = useRecordContext();
  if (!record) {
    return <></>;
  }
  const {
    id,
    deviceHash,
    hasWebcam,
    hasMicrophone,
    isScreenCapturingSupported,
    isWebRTCSupported,
    isWebsiteHasMicrophonePermissions,
    isWebsiteHasWebcamPermissions,
    isWebSocketsSupported,
    clockOffset,
    lastSeen,
    lastLogin,
    lastMeetingId,
    lastMeetingInviteId,
    lastMeetingJoin,
    lastMeetingStatus,
    meetingData, // AWSJSON
    userAgent, // AWSJSON
    userAgentSummary,
    micStatus,
    cameraStatus,
    networkQuality
  } = record;
  const hasIssues = (micStatus && micStatus.includes("error")) || (cameraStatus && cameraStatus.includes("error"));
  const title = hasIssues ? "error" : "logged in";
  return <Tooltip title={title}>{hasIssues ? (
      <ErrorIcon />
    ) : (
      <CheckCircleRoundedIcon />
    )
  }</Tooltip>;
};

/*
  id: ID!
  deviceHash: String!
  hasWebcam: Boolean
  hasMicrophone: Boolean
  isScreenCapturingSupported: Boolean
  isWebRTCSupported: Boolean
  isWebsiteHasMicrophonePermissions: Boolean
  isWebsiteHasWebcamPermissions: Boolean
  isWebSocketsSupported: Boolean
  clockOffset: Int @index(name: "profileDevicesByClockOffset", queryField: "listProfileDevicesByClockOffset", sortKeyFields: ["lastSeen"])
  lastSeen: AWSDateTime @index(name: "profileDevicesByLastSeen", queryField: "listProfileDevicesByLastSeen")
  lastLogin: AWSDateTime
  lastMeetingId: String
  lastMeetingInviteId: String
  lastMeetingJoin: AWSDateTime
  lastMeetingStatus: String @index(name: "profileDevicesByMeetingStatus", queryField: "listProfileDevicesByMeetingStatus", sortKeyFields: ["lastMeetingJoin"])  # good, poor, failed
  meetingData: AWSJSON
  userAgent: AWSJSON
  userAgentSummary: String
  micStatus: String @index(name: "profileDevicesByMicStatus", queryField: "listProfileDevicesByMicStatus", sortKeyFields: ["lastMeetingJoin"])
  cameraStatus: String @index(name: "profileDevicesByCameraStatus", queryField: "listProfileDevicesByCameraStatus", sortKeyFields: ["lastMeetingJoin"])
  networkQuality: String @index(name: "profileDevicesByNetworkQuality", queryField: "listProfileDevicesByNetworkQuality", sortKeyFields: ["lastMeetingJoin"])  # https://docs.daily.co/reference/daily-js/events/quality-events
  profile: Profile! @belongsTo(fields: ["profileId"])
  profileId: ID! @index(name: "profileDevicesByProfileId", queryField: "listProfileDevicesByProfileId", sortKeyFields: ["deviceHash"])
  owner: String
*/
