import React, {
  useContext,
  useEffect,
  useState,
} from "react";
import * as Sentry from "@sentry/react";
import { DateTime } from "luxon";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { usePreferences } from "@react-admin/ra-preferences";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  Formik,
  Field,
  Form,
  ErrorMessage,
  FieldArray,
} from "formik";
import {
  Box,
  Typography,
  Chip,
  TextField,
  makeStyles,
  ThemeProvider,
  FormGroup,
  Tooltip,
  IconButton,
  Badge,
} from "@material-ui/core";
import {
  Button,
  Link,
  useLogout,
  useNotify,
  useRedirect,
} from "react-admin";
import ExitToApp from "@material-ui/icons/ExitToApp";
import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Logger } from "aws-amplify";
import type { ScheduledMeetingsMenuProps } from "../types";
import { MeetingInvitationType } from "../API";
import {
  getCurrentDateTime,
  startMeeting,
} from "../lib/helpers";
import { Copyright } from "./Copyright";
import {
  customTheme,
} from "../layout/theme";
import { MeetingsStateContext } from "../App";
import { AWS_TIMESTAMP_AS_LUXON_FORMAT } from '../constants';
import { Launch as LaunchIcon } from "@material-ui/icons";

const ReactSwal = withReactContent(Swal);
const useStyles = makeStyles(theme => ({
  menuItem: {
    color: theme.palette.primary.contrastText,
    textDecorationColor: theme.palette.primary.contrastText,
  },
  allProductsLink: {
    color: theme.palette.primary.contrastText,
    textDecorationColor: theme.palette.primary.contrastText,
  },
}));
const logger = new Logger("ScheduledMeetingsMenu");

const ScheduledMeetingsMenu = (props: ScheduledMeetingsMenuProps) => {
  const {
    meetings,
    title,
    isSeller,
    isBuyer,
    showBackToMeetingsLink,
    clockSyncEnabled,
    cachedServerTimeDifference,
  } = props;
  const classes = useStyles();
  const logout = useLogout();
  const [ showPracticeSessionButton, setShowPracticeSessionButton ] = useState<boolean>(true);
  const meetingsState = useContext(MeetingsStateContext);
  const notify = useNotify();
  const redirect = useRedirect();
  const {
    isAdmin,
    currentProfile,
    isApplicationOutdated,
  } = meetingsState;

  useEffect(() => {
    logger.info("nextMeetingCheckerInterval useeffect called...");
    // NOTE: Activates the internal timer for setting the next meeting invitation
    const nextMeetingCheckerInterval = setInterval(
      () => {
        if (
          meetings
        ) {
          const nextMeeting = Array.from(meetings).shift();
          if (nextMeeting) {
            logger.info("nextMeeting", nextMeeting);
            const currentDateTime = getCurrentDateTime(cachedServerTimeDifference, clockSyncEnabled);
            const minutesFromNow = currentDateTime.plus({ minutes: 6 });
            const nextMeetingStartDateTime = DateTime.fromFormat(
              nextMeeting.startDateTime,
              AWS_TIMESTAMP_AS_LUXON_FORMAT,
              { zone: "utc" },
            ).toLocal();
            logger.info("minutesFromNow.toUnixInteger()", minutesFromNow.toUnixInteger());
            logger.info("nextMeetingStartDateTime.toUnixInteger()", nextMeetingStartDateTime.toUnixInteger());
            logger.info(
              "minutesFromNow difference in minutes",
              Math.ceil(
                (nextMeetingStartDateTime.toUnixInteger() - minutesFromNow.toUnixInteger()) / 60
              )
            );
            const nextMeetingIsClose = minutesFromNow.toUnixInteger() > nextMeetingStartDateTime.toUnixInteger();
            logger.info("nextMeetingIsClose", nextMeetingIsClose);
            if (showPracticeSessionButton && nextMeetingIsClose) {
              setShowPracticeSessionButton(false);
            } else if (!showPracticeSessionButton && !nextMeetingIsClose) {
              setShowPracticeSessionButton(true);
            }
          }
        }
      },
      1000 * 60,
    );
    return () => {
      if (nextMeetingCheckerInterval) {
        try {
          clearInterval(nextMeetingCheckerInterval);
        } catch {
          (err: Error) => { }
        }
      }
    }
  }, [
    meetings, clockSyncEnabled, cachedServerTimeDifference,
  ]);

  const showLogoutDialog = async (event: any) => {
    try {
      event.preventDefault();
    } catch(err) { }
    ReactSwal.fire({
      title: "Are you sure?",
      text: isBuyer ? "Have you completed your vendor feedback and follow-up instructions?" : "You are about to logout!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#056839",
      cancelButtonColor: "#39B54A",
      cancelButtonText:  isBuyer ? "No, go to feedback pages." : "Stay",
      confirmButtonText: isBuyer ? "Yes, logout." : "Logout"
    }).then((result: { isConfirmed: boolean }) => {
      if (result.isConfirmed) {
        logout();
      }
      if (isBuyer) {
        redirect("/feedback");
      }
    });
  };

  const initialValues = {
    invitees: [
      {
        name: '',
        email: '',
      },
    ],
  };

  const collectInvitees = async (event: any) => {
    try {
      event.preventDefault();
    } catch(err) { }
    ReactSwal.fire(
      {
        title: "Add Invitees",
        showCancelButton: true,
        showConfirmButton: false,
        // confirmButtonText: "Continue",
        confirmButtonColor: "#056839",
        cancelButtonColor: "#39B54A",
        html: (
          <ThemeProvider // See: https://stackworx.github.io/formik-mui/docs/guide/getting-started
            theme={customTheme}
          >
            <Formik
              initialValues={initialValues}
              onSubmit={async (values, { setSubmitting, }) => {
                logger.info(JSON.stringify(values, null, 2));
                ReactSwal.clickConfirm();
                startProviderMeeting(
                  { invitees: values.invitees }
                );
              }}
            >
              {({ values, handleChange }) => (
                <Form>
                  <FieldArray name="invitees">
                    {({ insert, remove, push }) => (
                      <div>
                        {
                          values.invitees.map((invitee, index) => (
                            <div className="row" key={index}>
                              <FormGroup>
                                <Field
                                  id={`invitees.${index}.name`}
                                  name={`invitees.${index}.name`}
                                  placeholder="Jane Doe"
                                  component={TextField}
                                  type="text"
                                  label="Name"
                                  onChange={handleChange}
                                  // value={values.invitees[index].name}
                                />
                                <ErrorMessage
                                  name={`invitees.${index}.name`}
                                  component="div"
                                  className="field-error"
                                />
                              </FormGroup>
                              <FormGroup>
                                <Field
                                  id={`invitees.${index}.email`}
                                  name={`invitees.${index}.email`}
                                  placeholder="jane@acme.com"
                                  component={TextField}
                                  type="email"
                                  label="Email"
                                  onChange={handleChange}
                                  // value={values.invitees[index].email}
                                />
                                <ErrorMessage
                                  name={`invitees.${index}.name`}
                                  component="div"
                                  className="field-error"
                                />
                              </FormGroup>
                              <FormGroup>
                                <Tooltip title="Remove">
                                    <IconButton
                                      color="inherit"
                                      onClick={() => remove(index)}
                                      aria-label="Remove"
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                              </FormGroup>
                            </div>
                          ))}
                          <Tooltip title="Add Invitee">
                            <Badge
                              overlap="circular"
                              badgeContent={values.invitees.length}
                              max={10}
                              color="secondary"
                            >
                              <IconButton
                                color="inherit"
                                onClick={() => push({ name: '', email: '' })}
                                aria-label="Add Invitee"
                                disabled={values.invitees.length > 10 || (values.invitees.length > 0 && values.invitees[values.invitees.length - 1].email.length == 0) ? true : false}
                              >
                               <AddIcon />
                              </IconButton>
                            </Badge>
                          </Tooltip>
                      </div>
                    )}
                  </FieldArray>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    <Typography>
                      Create Practice Session
                    </Typography>
                  </Button>
                </Form>
              )}
            </Formik>
          </ThemeProvider>
        ),
        /* didOpen: () => {
          Swal.getPopup()?.querySelector('input')?.focus()
        },
        preConfirm: async () => {
          await formikRef?.submitForm()
          if (formikRef?.isValid) {
            ReactSwal.fire({
              title: formikRef.values.location,
              icon: 'success',
            })
          } else {
            ReactSwal.showValidationMessage(JSON.stringify(formikRef?.errors))
          }
        }, */
      },
    ).then(async (result) => {
      logger.info("result", result);
    });
  };

  const startProviderMeeting = async (event: any) => {
    try {
      logger.info(
        "event",
        { event }
      );
      event.preventDefault();
    } catch(err) { }
    const {
      invitees,
    } = event;
    const alertMessage = isBuyer ? "You are about to start an A/V test. Use the leave meeting button at the bottom of the screen to exit the A/V test." : "You are about to to start a practice session. Use the leave meeting button at the bottom of the practice session screen to exit the meeting.";
    ReactSwal.fire({
      title: "Are you sure?",
      text: alertMessage,
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Cancel",
      confirmButtonText: "Start",
      confirmButtonColor: "#056839",
      cancelButtonColor: "#39B54A",
    }).then(async (result: { isConfirmed: boolean }) => {
      if (result.isConfirmed) {
        const userMessage = isBuyer ? "Creating A/V test.  Please wait..." : "Creating practice session.  Please wait...";
        notify(userMessage, { type: "info" });
        let meetingDurationInMinutes = 120;
        if (isBuyer) {
          meetingDurationInMinutes = 5;
        }
        const endTimestamp = getCurrentDateTime().toUnixInteger() + (60 * meetingDurationInMinutes);
        await startMeeting(
          endTimestamp,
          `${currentProfile?.id}`,
          invitees,
        ).then(
          (startData) => {
            logger.info("startData", startData);
            const roomUrl = `https://physician.daily.co/${startData.roomName}`;
            const params = new URLSearchParams(
              {
                "room": roomUrl,
                "roomName": startData.roomName,
                "meetingId": "0",
                "meetingInviteId": "0",
                "serverTimeDifference": "0", // cachedServerTimeDifference ? `${cachedServerTimeDifference}` : '0',
                "endTimestamp": `${endTimestamp}`
              }
            );
            // let url = `${startData.url}`;
            let url = `https://meeting.insightgateway.com/practice.html?${params.toString()}`;
            /* if (isBuyer) {
              url = `https://meeting.insightgateway.com/?${params.toString()}`;
            } */
            logger.info("redirecting to url...", url);
            window.location.href = url;
          }
        ).catch(
          (err) => {
            logger.error("startMeeting error", err);
            const errorMessage = `Meeting creation failed: ${err}`;
            notify(errorMessage, { type: "warning" });
            Sentry.captureException(err);
          }
        );
      }
    });
  };

  return (
    <>
      {isSeller && (
      <Typography variant="h6" align="center">
        <Link to="/seller-upcoming-doctors" className={classes.allProductsLink}>Preview physician profiles</Link>
      </Typography>
      )}
      {isBuyer && (
      <Typography variant="h6" align="center">
        <Link to="/buyer-upcoming-products" className={classes.allProductsLink}>Preview all products</Link>
      </Typography>
      )}
      {showBackToMeetingsLink && (
      <Typography variant="h6" align="center">
        <Link to="/" className={classes.allProductsLink}>Back to meetings</Link>
      </Typography>
      )}
      {(true || !meetings || meetings.length === 0 || isAdmin) && (
      <Typography variant="h6" align="center">
        <Link to="/feedback" className={classes.allProductsLink}>Manage Communication</Link>
      </Typography>
      )}
      {isAdmin && false && (
      <Typography variant="h6" align="center">
        <Link to="/webRtcCheck" className={classes.allProductsLink}>WebRTC Test</Link>
      </Typography>
      )}
      <Typography variant="subtitle2" align="center">{title}</Typography>
      <List dense={false}>
      {meetings.map(meeting => (
        <ListItem
          key={meeting.id}
          button={false}
        >
          <ListItemText
            primary={DateTime.fromFormat(
              meeting.startDateTime,
              AWS_TIMESTAMP_AS_LUXON_FORMAT,
              { zone: "utc" },
            ).toLocal().toFormat("ccc D - t ZZZZ")}
            secondaryTypographyProps={{
              className: classes.menuItem
            }}
            secondary={meeting.meetingInvitationType === MeetingInvitationType.INVITEE ? meeting.sellerTeamName : meeting.buyerTeamName}
          />
        </ListItem>
      ))}
      </List>
      <Box mt={4}>
        {(isBuyer || isSeller) && (
        <Button
          startIcon={<LaunchIcon fontSize="large" color="inherit" />}
          className={classes.allProductsLink}
          variant="contained" color="secondary"
          label={isBuyer ? "A/V Test" : "Start Practice Session"}
          size="large"
          onClick={collectInvitees}
          fullWidth
        />
        )}
      </Box>
      <Box mt={4}>
        <Typography variant="h6" align="center">
          Tech Issue? Call 844-247-4784.
        </Typography>
        <Button
          startIcon={<ExitToApp fontSize="large" color="inherit" />}
          className={classes.allProductsLink}
          variant="text"
          label="Log out"
          size="large"
          onClick={showLogoutDialog}
        />
      </Box>
      <Box mt={8}>
        <Copyright
          variant="body2"
          color="inherit"
          align="center"
        />
      </Box>
      <Box mt={2}>
      {isApplicationOutdated && (
      <Chip
        size="small"
	variant="outlined"
        icon={<SystemUpdateAltIcon />}
        color="secondary"
        onClick={() => { location.reload() }}
	label="Outdated"
      />
      )}
      </Box>
    </>
  );
};

export default ScheduledMeetingsMenu;
