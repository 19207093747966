import React, { useCallback, useState } from "react";
import {
  AutocompleteInput,
  Create,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  FilterButton,
  ReferenceField,
  ReferenceInput,
  required,
  SelectInput,
  setAutomaticRefresh,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  useMutation,
  useNotify,
  useRedirect,
} from "react-admin";
import type { CreateProps, EditProps, ListProps, ShowProps } from "react-admin";
import { listTeamMeetingRelationshipsByMeetingId } from "../graphql/queries";
import { API, graphqlOperation, Logger } from "aws-amplify";
import type {
  ListTeamMeetingRelationshipsByMeetingIdQuery,
  TeamMeetingRelationship,
} from "../API";
import {
  TeamMeetingRelationshipType,
} from "../API";
import type { GraphQLResult } from "@aws-amplify/api";
import type { GraphQLErrorResponse } from "../types";
import { MEETING_INVITATION_TYPE_CHOICES } from "../constants";
import { MeetingRelationshipTypeField } from "./CustomFields";
import DeleteWithConfirmToolbar from "./DeleteWithConfirmToolbar";
import { DeleteWithConfirmButton } from "@react-admin/ra-tree";
import { useDispatch } from "react-redux";
import { CustomList, ListPagination } from "./CustomList";
import { getMeeting, hasConflictingMeetingByTeam } from "../lib/helpers";
import TopToolbar from "./TopToolbar";

const validateRequired = [required()];
const logger = new Logger("TeamMeetingRelationship");
const defaultQuery = "listTeamMeetingRelationships";

const filters = [
  <ReferenceInput
    source="teamId"
    reference="teams"
    label="Team"
    filterToQuery={(searchText) => ({
      listTeams: { name: searchText },
    })}
    perPage={2000}
    resettable
  >
    <AutocompleteInput optionText="name" />
  </ReferenceInput>,
  <ReferenceInput
    source="meetingId"
    reference="meetings"
    label="Meeting"
    filterToQuery={(searchText) => ({
      listMeetings: { title: searchText },
    })}
    // filter={{ listTeamMeetingRelationshipsByTeamId: {} }} TODO try
    perPage={500}
    resettable
  >
    <AutocompleteInput optionText="title" />
  </ReferenceInput>,
  <SelectInput
    label="Type"
    source="relationshipType"
    choices={MEETING_INVITATION_TYPE_CHOICES}
    alwaysOn
    required
  />,
];


const validateTeamMeetingRelationship = async (data: any) => {
  let errors: any = {};

  const {
    meetingId,
    teamId,
    relationshipType,
  } = data;

  logger.info("validateTeamMeetingRelationship data", data);

  if (meetingId) {
    const query = graphqlOperation(
      listTeamMeetingRelationshipsByMeetingId,
      {
        meetingId,
      }
    );
    await (API.graphql(query) as Promise<GraphQLResult<ListTeamMeetingRelationshipsByMeetingIdQuery>>)
      .then(
        async (result: GraphQLResult<ListTeamMeetingRelationshipsByMeetingIdQuery>) => {
          if (
            result.data &&
            result.data.listTeamMeetingRelationshipsByMeetingId &&
            result.data.listTeamMeetingRelationshipsByMeetingId.items.length > 0
          ) {
            const {
              items
            } = result.data.listTeamMeetingRelationshipsByMeetingId;
            logger.info("result.data.listTeamMeetingRelationshipsByMeetingId.items", items);
            if (items && items.length > 0) {
              for (let i = 0; i < items.length; i = i + 1) {
                const teamMeetingRelationship = items[i];
                if (teamMeetingRelationship && teamMeetingRelationship.teamId === teamId) {
                  logger.info("teamMeetingRelationship.teamId", teamMeetingRelationship.teamId);
                  logger.info("teamId", teamId);
                  const errorMessage = "Relationship already exists.";
                  errors = { teamId: errorMessage };
                  break;
                } else if (teamMeetingRelationship && teamMeetingRelationship.relationshipType === relationshipType) {
                  const relationshipTypeDisplay = relationshipType === TeamMeetingRelationshipType.ORGANIZER ? "Seller" : "Buyer";
                  const errorMessage = `${relationshipTypeDisplay} already exists.`;
                  errors = { relationshipType: errorMessage };
                  break;
                }
              }
            }
          }
        }
      )
      .catch((err: GraphQLErrorResponse) => {
        if (err.errors && err.errors.length > 0) {
          const { message } = err.errors[0];
          const errorMessage = message || "GraphQL API error";
          errors = { meetingId: errorMessage };
        } else {
          errors = { meetingId: err.message };
        }
      }
    );

    if (Object.keys(errors).length === 0) {
      logger.info("no validation errors found");
      const meeting = await getMeeting(meetingId);
      logger.info("meeting", meeting);
      if (meeting) {
        const hasConflict = await hasConflictingMeetingByTeam(
          teamId,
          meeting,
        );
        logger.info("hasConflict", hasConflict);
        if (hasConflict) {
          errors = {
            teamId: "Team has a conflicting meeting"
          }
        }
      }
    }
  }

  logger.info("validateTeamMeetingRelationship errors", errors);
  return errors;
};


const ListActions = (props: any) => {
  const {
    className,
  } = props;
  return (
    <TopToolbar className={className}>
      <FilterButton />
    </TopToolbar>
  );
};

export const TeamMeetingRelationshipList = (props: ListProps) => {
  const [query, setQuery] = useState(defaultQuery);
  return (
    <CustomList
      {...props}
      filters={filters}
      actions={<ListActions />}
      bulkActionButtons={false}
      pagination={<ListPagination />}
    >
      <Datagrid>
        <TextField source="team.name" sortable={false} label="Team name" />
        <TextField source="meeting.title" sortable={false} label="Meeting title" />
        <MeetingRelationshipTypeField source="relationshipType" sortable={false} label="Seller / Buyer" />
        <DateField source="updatedAt" showTime={true} sortable={false} />
        <DeleteWithConfirmButton
          redirect={
            (deleteResults) => {
              // TODO redirect to `/meetings/${data.meetingId}/show`;
              return `/meetings`;
            }
          }
        />
        <ShowButton />
        <EditButton />
      </Datagrid>
    </CustomList>
  );
};

export const TeamMeetingRelationshipShow = (props: ShowProps) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" label="ID" fullWidth />
      <MeetingRelationshipTypeField source="relationshipType" />
      <ReferenceField
        source="teamId"
        reference="teams"
        label="Team"
        link="show"
        fullWidth
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        source="meetingId"
        reference="meetings"
        label="Meeting"
        link="show"
        fullWidth
      >
        <TextField source="title" />
      </ReferenceField>
      <DateField source="createdAt" showTime={true} />
      <DateField source="updatedAt" showTime={true} />
    </SimpleShowLayout>
  </Show>
);

export const TeamMeetingRelationshipEdit = (props: EditProps) => {

  const notify = useNotify();
  const redirect = useRedirect();
  const [mutate] = useMutation();
  const dispatch = useDispatch();
  dispatch(setAutomaticRefresh(false));
  const onSuccess = (teamMeetingRelationshipData: { data: TeamMeetingRelationship }) => {
    logger.info("teamMeetingRelationshipData", teamMeetingRelationshipData);
    const {
      data,
    } = teamMeetingRelationshipData;
    let message = "Buyer updated";
    if (data.relationshipType === TeamMeetingRelationshipType.ORGANIZER) {
      message = "Seller updated";
    }
    notify(message, { type: "success" });
    redirect(`/meetings/${data.meetingId}`);
  };
  // See: https://marmelab.com/react-admin/doc/3.19/CreateEdit.html#submission-validation
  const save = useCallback(
    async (data: any) => {
      const errors = await validateTeamMeetingRelationship(data);
      if (Object.keys(errors).length > 0) {
        logger.warn("found validation errors", errors);
        return errors;
      }
      await mutate(
        {
          type: "update",
          resource: "teamMeetingRelationships",
          payload: { data },
        },
        {
          returnPromise: true,
          onSuccess
        }
      );
    },
    [mutate],
  );

  return (
    <Edit
      {...props} // TODO redirect to meeting after delete
      onSuccess={
        (teamMeetingRelationshipData: { data: TeamMeetingRelationship }) => {
          logger.info("teamMeetingRelationshipData", teamMeetingRelationshipData);
          const {
            data,
          } = teamMeetingRelationshipData;
          let message = "Buyer updated";
          if (data.relationshipType === TeamMeetingRelationshipType.ORGANIZER) {
            message = "Seller updated";
          }
          notify(message, { type: "success" });
          redirect(`/meetings/${data.meetingId}`);
        }
      }
      undoable={false}
      mutationMode="pessimistic"
    >
      <SimpleForm
        save={save}
        toolbar={<DeleteWithConfirmToolbar />}
      >
        <TextInput source="id" label="ID" disabled fullWidth />
        <SelectInput
          source="relationshipType"
          choices={MEETING_INVITATION_TYPE_CHOICES}
          isRequired
          validate={validateRequired}
        />
        <ReferenceInput
          source="teamId"
          reference="teams"
          label="Team"
          filterToQuery={(searchText) => ({
            listTeams: { name: searchText },
          })}
          perPage={2000}
          alwaysOn
          isRequired
          validate={validateRequired}
          disabled
          fullWidth
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          source="meetingId"
          reference="meetings"
          label="Meeting"
          filterToQuery={(searchText) => ({
            listMeetings: { title: searchText },
          })}
          perPage={500}
          alwaysOn
          isRequired
          validate={validateRequired}
          disabled
          fullWidth
        >
          <AutocompleteInput optionText="title" />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
}

export const TeamMeetingRelationshipCreate = (props: CreateProps) => {

  const notify = useNotify();
  const redirect = useRedirect();
  const [mutate] = useMutation();
  const dispatch = useDispatch();
  dispatch(setAutomaticRefresh(false));

  const onSuccess = (teamMeetingRelationshipData: { data: TeamMeetingRelationship }) => {
    logger.info("teamMeetingRelationshipData", teamMeetingRelationshipData);
    const {
      data,
    } = teamMeetingRelationshipData;
    let message = "Buyer added";
    if (data.relationshipType === TeamMeetingRelationshipType.ORGANIZER) {
      message = "Seller added";
    }
    notify(message, { type: "success" });
    redirect(`/meetings/${data.meetingId}`);
  };
  // See: https://marmelab.com/react-admin/doc/3.19/CreateEdit.html#submission-validation
  const save = useCallback(
    async (data: any) => {
      const errors = await validateTeamMeetingRelationship(data);
      if (Object.keys(errors).length > 0) {
        logger.warn("found validation errors", errors);
        return errors;
      }
      await mutate(
        {
          type: "create",
          resource: "teamMeetingRelationships",
          payload: { data },
        },
        {
          returnPromise: true,
          onSuccess
        }
      );
    },
    [mutate],
  );

  return (
    <Create
      {...props}
    >
      <SimpleForm
        save={save}
      >
        <SelectInput
          source="relationshipType"
          choices={MEETING_INVITATION_TYPE_CHOICES}
          isRequired
          validate={validateRequired}
        />
        <ReferenceInput
          source="teamId"
          reference="teams"
          label="Team"
          filterToQuery={(searchText) => ({
            listTeams: { name: searchText },
          })}
          perPage={2000}
          alwaysOn
          isRequired
          validate={validateRequired}
          fullWidth
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          source="meetingId"
          reference="meetings"
          label="Meeting"
          filterToQuery={(searchText) => ({
            listMeetings: { title: searchText },
          })}
          perPage={2000}
          alwaysOn
          isRequired
          validate={validateRequired}
          fullWidth
        >
          <AutocompleteInput optionText="title" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
}
