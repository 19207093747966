import { Logger } from "@aws-amplify/core";
import merge from "lodash/merge";

const sortOperators = ["eq", "le", "lt", "ge", "gt", "beginsWith", "between"];
const bannedKeyNames = ["sortDirection", "limit", "nextIndex"];

const logger = new Logger("Filter");

export class Filter {
  static getQueryName(
    queries: Record<string, string>,
    filter: Record<string, unknown>
  ): string | null {
    const keys = Object.keys(filter);
    const numberOfKeys = keys.length;
    if (numberOfKeys === 0) {
      return null;
    }
    const queryName: string = keys[0];
    if (numberOfKeys > 0) {
      // TODO find the best match based on available queries and [filter]?
      //   for now assume the first queryName is primary
    }
    // The filter unique field is the query name
    // const queryName = Object.keys(filter)[0];
    // logger.info("static getQueryName queryName", queryName);
    if (!queries[queryName]) {
      logger.info(`Could not find query ${queryName}`);
      throw new Error("Data provider error");
    }
    return queryName;
  }

  static getQueryVariables(
    filter: Record<string, unknown>
  ): Record<string, unknown> | null {
    /* if (!this.isObjectOfLength(filter, 1)) {
      return null;
    } */
    const keys = Object.keys(filter);
    const numberOfKeys = keys.length;
    if (numberOfKeys === 0) {
      return null;
    }

    let queryParams = Object.values(filter)[0] as Record<string, unknown>;
    // logger.info("getQueryVariables queryParams 1", queryParams);

    if (numberOfKeys === 2) {
      queryParams = Object.values(filter).slice(0, 2).reduce(
        (previousValue, currentValue) => {
          return merge(previousValue, currentValue);
        }
      ) as Record<string, unknown>;
      // logger.info("getQueryVariables queryParams 2", queryParams);
    }
  
    // logger.info("getQueryVariables filter", filter);

    for (const bannedKeyName of bannedKeyNames) {
      delete queryParams[bannedKeyName];
    }

    // Case when there is only the hash key
    if (this.isObjectOfLength(queryParams, 1)) {
      const onlyParam = Object.values(queryParams)[0];
      // logger.info("onlyParam", onlyParam);
      // logger.info("this.isHashKeyValid(onlyParam)", this.isHashKeyValid(onlyParam));
      if (this.isHashKeyValid(onlyParam)) {
        return queryParams;
      }
      return null;
    }

    // Case when there are the hash key and the sort key
    if (this.isObjectOfLength(queryParams, 2)) {
      const firstParam = Object.values(queryParams)[0];
      const secondParam = Object.values(queryParams)[1];
      const firstKey = Object.keys(queryParams)[0];
      // logger.info("firstKey", firstKey);
      // logger.info("firstParam", firstParam);
      // logger.info("secondKey", Object.keys(queryParams)[1]);
      // logger.info("secondParam", secondParam);


      if (this.isHashKeyValid(firstParam)) {
        // logger.info("firstKey this.isHashKeyValid(firstParam)");
        if (this.isSortKeyValid(secondParam)) {
          return queryParams;
        }

        return {
          [Object.keys(queryParams)[0]]: firstParam,
        };
      }

      if (this.isHashKeyValid(secondParam)) {
        // logger.info("firstKey this.isHashKeyValid(secondParam)");
        if (this.isGqlFilter(firstKey)) {
          // logger.info("firstKey this.isGqlFilter(firstKey)", this.isGqlFilter(firstKey));
        }
        if (this.isSortKeyValid(firstParam) || this.isGqlFilter(firstKey)) {
          return queryParams;
        }
        // logger.info("firstKey !this.isSortKeyValid(firstParam)");
        return {
          [Object.keys(queryParams)[1]]: secondParam,
        };
      }

      // logger.info("firstKey will return null", Object.keys(queryParams)[0]);
    }

    // logger.info("firstKey null 2");
    return null;
  }

  static isObject(obj: unknown): boolean {
    return obj !== null && typeof obj === "object";
  }

  static isObjectOfLength(obj: unknown, length = 0): boolean {
    if (!this.isObject(obj)) {
      return false;
    }

    return Object.keys(obj as Record<string, unknown>).length === length;
  }

  static isString(str: unknown): boolean {
    return typeof str === "string" && str !== "";
  }

  static isGqlFilter(key: unknown): boolean {
    return `${key}` === "filter";
  }

  static isHashKeyValid(key: unknown): boolean {
    return this.isString(key) || typeof key === "number";
  }

  static isSortKeyValid(obj: unknown): boolean {
    if (!this.isObjectOfLength(obj, 1)) {
      return false;
    }

    const key = obj as Record<string, unknown>;

    if (!sortOperators.includes(Object.keys(key)[0])) {
      return false;
    }

    const keyInput = Object.values(key)[0] as Record<string, unknown>;

    if (this.isHashKeyValid(keyInput)) {
      return true;
    }

    if (this.isObject(keyInput) && Object.keys(keyInput).length > 1) {
      for (const sortField in keyInput) {
        if (!this.isHashKeyValid(keyInput[sortField])) {
          return false;
        }
      }

      return true;
    }

    return false;
  }
}
