import React, { useState } from "react";
import {
  AutocompleteInput,
  ChipField,
  Create,
  CreateButton,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  Link,
  ListButton,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  RefreshButton,
  Show,
  ShowButton,
  SimpleForm,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  required,
  SingleFieldList,
} from "react-admin";
import type {
  CreateProps,
  EditProps,
  ListProps,
  ShowProps,
 } from "react-admin";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import { AmplifyImageField } from "./AmplifyImageField";
import DeleteWithConfirmToolbar from "./DeleteWithConfirmToolbar";
import { CustomList, ListPagination } from "./CustomList";
import NoDeleteToolbar from "./NoDeleteToolbar";
import TopToolbar from "./TopToolbar";

const defaultQuery = "listProducts";
const validateRequired = [required()];

const filters = [
  <TextInput label="Name" source="name" alwaysOn resettable />,
  <ReferenceInput
    source="organizationId"
    reference="organizations"
    label="Company"
    filterToQuery={(searchText) => ({
      listOrganizations: { name: searchText },
    })}
    perPage={2000}
    alwaysOn
    resettable
  >
    <AutocompleteInput optionText="name" />
  </ReferenceInput>
];

const AddNewProductImageButton = (props: ShowProps | EditProps) => (
  <CreateButton
    component={Link}
    to={{
      pathname: "/productImages/create",
      state: { record: { productId: props.id as string } },
      search: `?source=${JSON.stringify({ productId: props.id as string })}`,
    }}
    label="Add image"
  >
    <ContactMailIcon />
  </CreateButton>
);

const AddNewProductQuestionButton = (props: ShowProps | EditProps) => (
  <CreateButton
    component={Link}
    to={{
      pathname: "/productQuestions/create",
      state: { record: { productId: props.id as string } },
      search: `?source=${JSON.stringify({ productId: props.id as string })}`,
    }}
    label="Add question"
  >
    <ContactMailIcon />
  </CreateButton>
);

const ShowActions = (props: ShowProps) => {
  const {
    className,
    basePath,
    id
  } = props;
  return (
    <TopToolbar className={className}>
      <ListButton basePath={basePath} />
      <RefreshButton />
      <AddNewProductImageButton {...props} />
      <AddNewProductQuestionButton {...props} />
      <EditButton basePath={basePath} label="Edit" record={{ id: id as string }} />
    </TopToolbar>
  );
};

const EditActions = (props: EditProps) => {
  const {
    className,
    basePath,
    id
  } = props;
  return (
    <TopToolbar className={className}>
      <ListButton basePath={basePath} />
      <AddNewProductImageButton {...props} />
      <AddNewProductQuestionButton {...props} />
      <ShowButton basePath={basePath} label="Show" record={{ id: id as string }} />
    </TopToolbar>
  );
};

export const ProductList = (props: ListProps) => {
  const [query, setQuery] = useState(defaultQuery);

  return (
    <CustomList
      {...props}
      filters={filters}
      bulkActionButtons={false}
      perPage={300}
      pagination={<ListPagination rowsPerPageOptions={
        [300, 400, 500, 600, 700, 800, 1000]
      } />}
    >
      <Datagrid>
        <TextField source="name" sortable={false} />
        <TextField source="organization.name" label="Company" />
        <TextField source="salesTeam.name" label="Sales team" />
        <DateField source="updatedAt" showTime={true} sortable={false} />
        <ShowButton />
        <EditButton />
      </Datagrid>
    </CustomList>
  );
};

export const ProductShow = (props: ShowProps) => (
  <Show
    {...props}
    actions={<ShowActions {...props} />}
  >
    <TabbedShowLayout>
      <Tab label="Details">
        <TextField source="id" label="ID" />
        <TextField source="name" />
        <TextField source="description" />
        <TextField source="serviceInformation" />
        <ReferenceField
          source="organizationId"
          reference="organizations"
          label="Company"
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          source="salesTeamId"
          reference="teams"
          label="Sales team"
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceManyField
          reference="productImages"
          target="productId"
          perPage={10}
          label="Images"
          fullWidth
          filter={{ listProductImagesByProductId: {} }}
        >
          <Datagrid>
            <TextField source="title" />
            <AmplifyImageField source="image" title="title" />
            <DateField source="createdAt" showTime={true} />
            <DateField source="updatedAt" showTime={true} />
            <ShowButton />
            <EditButton />
          </Datagrid>
        </ReferenceManyField>
        <DateField source="createdAt" showTime={true} />
        <DateField source="updatedAt" showTime={true} />
      </Tab>
      <Tab label="Questions">
        <ReferenceManyField
            reference="productQuestions"
            target="productId"
            label="Feedback questions"
            perPage={25}
            fullWidth
            filter={{ listProductQuestionsByProductId: {} }}
          >
          <SingleFieldList>
            <ChipField source="question" />
          </SingleFieldList>
        </ReferenceManyField>
        <AddNewProductQuestionButton {...props} />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export const ProductEdit = (props: EditProps) => (
  <Edit
    {...props}
    actions={<EditActions {...props} />}
    undoable={false}
    mutationMode="pessimistic"
  >
    <SimpleForm
      toolbar={<NoDeleteToolbar />}
    >
      <TextInput source="id" label="ID" disabled />
      <TextInput
        source="name"
        isRequired
        fullWidth
        validate={validateRequired}
      />
      <TextInput
        source="description"
        fullWidth
        multiline={true}
        rows={4}
      />
      <TextInput source="serviceInformation" fullWidth />
      <ReferenceInput
        source="organizationId"
        reference="organizations"
        label="Company"
        filterToQuery={(searchText) => ({
          listOrganizations: { name: searchText },
        })}
        perPage={2000}
        isRequired
        fullWidth
        validate={validateRequired}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        source="salesTeamId"
        reference="teams"
        label="Team"
        filterToQuery={(searchText) => ({
          listTeams: { name: searchText },
        })}
        perPage={2000}
        isRequired
        alwaysOn
        fullWidth
        validate={validateRequired}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);

export const ProductCreate = (props: CreateProps) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput
        source="name"
        isRequired
        fullWidth
        validate={validateRequired}
      />
      <TextInput
        source="description"
        fullWidth
        multiline={true}
        rows={4}
      />
      <TextInput source="serviceInformation" fullWidth />
      <ReferenceInput
        source="organizationId"
        reference="organizations"
        label="Company"
        filterToQuery={(searchText) => ({
          listOrganizations: { name: searchText },
        })}
        perPage={2000}
        isRequired
        fullWidth
        validate={validateRequired}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        source="salesTeamId"
        reference="teams"
        label="Team"
        filterToQuery={(searchText) => ({
          listTeams: { name: searchText },
        })}
        perPage={2000}
        isRequired
        alwaysOn
        fullWidth
        validate={validateRequired}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);
