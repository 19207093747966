import React, { ReactElement } from 'react';
import {
    useCheckMinimumRequiredProps,
    useListController,
    ListContextProvider,
} from 'ra-core';
import { List, ListView, Pagination } from 'ra-ui-materialui';
import type { ListProps, PaginationProps } from 'ra-ui-materialui';

const ListPagination = (props: PaginationProps): JSX.Element => <Pagination rowsPerPageOptions={[100, 150, 200, 250]} {...props} />;

const CustomList = (props: ListProps & { children: ReactElement }): ReactElement => {
    useCheckMinimumRequiredProps('CustomList', ['children'], props);
    const controllerProps = useListController(props);
    return (
        <ListContextProvider value={controllerProps}>
            <ListView {...props} {...controllerProps} />
        </ListContextProvider>
    );
};

CustomList.propTypes = List.propTypes;

CustomList.defaultProps = {
    filter: {},
    perPage: 100,
};

export { CustomList, ListPagination };
