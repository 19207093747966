import React, { useCallback, useState } from "react";
import {
  AutocompleteInput,
  Create,
  Datagrid,
  DateField,
  DeleteWithConfirmButton,
  Edit,
  EditButton,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  useMutation,
  useNotify,
  useRedirect,
  required,
} from "react-admin";
import type {
  CreateProps,
  EditProps,
  ListProps,
  ShowProps,
} from "react-admin";
import { API, graphqlOperation, Logger } from "aws-amplify";
import type { GraphQLResult } from "@aws-amplify/api";
import { ListTeamMemberRelationshipsByMemberProfileIdQuery, TeamMemberRelationship } from "../API";
import { listTeamMemberRelationshipsByMemberProfileId } from "../graphql/queries";
import { GraphQLErrorResponse } from "../types";
import DeleteWithConfirmToolbar from "./DeleteWithConfirmToolbar";
import { CustomList, ListPagination } from "./CustomList";

const teamMemberRelationshipStatuses = [
  { name: "Leader", id: "LEADER" },
  { name: "Member", id: "MEMBER" },
  { name: "Manager", id: "MANAGER" },
  { name: "Guest", id: "GUEST" },
];

const logger = new Logger("TeamMemberRelationship");
const defaultQuery = "listTeamMemberRelationships";
const validateRequired = [required()];

const validateTeamMemberRelationship = async (data: any) => {
  let errors: any = {};
  const {
    teamId,
    memberProfileId,
  } = data;
  logger.info("validateTeamMemberRelationship data", data);
  const query = graphqlOperation(
    listTeamMemberRelationshipsByMemberProfileId,
    {
      memberProfileId,
      filter: {
        teamId: {
          eq: teamId,
        },
      }
    });
  const relationshipExists = await (API.graphql(query) as Promise<GraphQLResult<ListTeamMemberRelationshipsByMemberProfileIdQuery>>)
    .then(
      (result: GraphQLResult<ListTeamMemberRelationshipsByMemberProfileIdQuery>) => {
        if (
          result.data &&
          result.data.listTeamMemberRelationshipsByMemberProfileId &&
          result.data.listTeamMemberRelationshipsByMemberProfileId.items.length > 0
        ) {
          return true;
        }
        return false;
      }
    )
    .catch((err: GraphQLErrorResponse) => {
      if (err.errors && err.errors.length > 0) {
        const { message } = err.errors[0];
        const errorMessage = message || "Unknown error";
        errors = { memberProfileId: errorMessage };
      }
      errors = { memberProfileId: err.message };
      return false;
    }
  );
  if (relationshipExists) {
    const errorMessage = "Relationship already exists.";
    errors = { memberProfileId: errorMessage };
  }
  logger.info("validateTeamMemberRelationship errors", errors);
  return errors;
};

export const TeamMemberRelationshipList = (props: ListProps) => {
  const [query, setQuery] = useState(defaultQuery);
  return (
    <CustomList
      {...props}
      bulkActionButtons={false}
      pagination={<ListPagination />}
    >
      <Datagrid>
        <TextField source="team.name" sortable={false} label="Team name" />
        <TextField
          source="member.fullName"
          sortable={false}
          label="Member name"
        />
        <TextField source="relationship" sortable={false} label="Team role" />
        <DateField source="updatedAt" showTime={true} sortable={false} />
        <DeleteWithConfirmButton />
        <ShowButton />
        <EditButton />
      </Datagrid>
    </CustomList>
  );
};

export const TeamMemberRelationshipShow = (props: ShowProps) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" label="ID" fullWidth />
      <TextField source="relationship" />
      <ReferenceField
        source="teamId"
        reference="teams"
        label="Team"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        source="memberProfileId"
        reference="profiles"
        label="User"
        link="show"
      >
        <TextField source="fullName" />
      </ReferenceField>
      <DateField source="createdAt" showTime={true} />
      <DateField source="updatedAt" showTime={true} />
    </SimpleShowLayout>
  </Show>
);

export const TeamMemberRelationshipEdit = (props: EditProps) => {

  const notify = useNotify();
  const redirect = useRedirect();
  const [mutate] = useMutation();

  const onSuccess = (teamMemberRelationshipData: { data: TeamMemberRelationship }) => {
    const {
      data,
    } = teamMemberRelationshipData;
    const message = "Member updated";
    notify(message, { type: "success" });
    redirect(`/teams/${data.teamId}/show/3`);
  };
  // See: https://marmelab.com/react-admin/doc/3.19/CreateEdit.html#submission-validation
  const save = useCallback(
    async (data: any) => {
      const errors = await validateTeamMemberRelationship(data);
      if (Object.keys(errors).length > 0) {
        logger.warn("found validation errors", errors);
        return errors;
      }
      await mutate(
        {
          type: "update",
          resource: "teamMemberRelationships",
          payload: { data },
        },
        {
          returnPromise: true,
          onSuccess
        }
      );
    },
    [mutate],
  );

  return (
    <Edit
      {...props}
      undoable={false}
      mutationMode="pessimistic"
    >
      <SimpleForm
        toolbar={<DeleteWithConfirmToolbar />}
        save={save}
      >
        <TextInput source="id" label="ID" disabled fullWidth />
        <SelectInput
          source="relationship"
          choices={teamMemberRelationshipStatuses}
        />
        <ReferenceInput
          source="teamId"
          reference="teams"
          label="Team"
          filterToQuery={(searchText) => ({
            listTeams: { name: searchText },
          })}
          perPage={2000}
          alwaysOn
          isRequired
          disabled
          validate={validateRequired}
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          source="memberProfileId"
          reference="profiles"
          label="User"
          filterToQuery={(searchText) => ({
            listProfiles: { fullName: searchText },
          })}
          perPage={700}
          alwaysOn
          isRequired
          disabled
          validate={validateRequired}
        >
          <AutocompleteInput optionText="fullName" />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
}

export const TeamMemberRelationshipCreate = (props: CreateProps) => {

  const notify = useNotify();
  const redirect = useRedirect();
  const [mutate] = useMutation();

  const onSuccess = (teamMemberRelationshipData: { data: TeamMemberRelationship }) => {
    const {
      data,
    } = teamMemberRelationshipData;
    const message = "Member added";
    notify(message, { type: "success" });
    redirect(`/teams/${data.teamId}/show/3`);
  };
  // See: https://marmelab.com/react-admin/doc/3.19/CreateEdit.html#submission-validation
  const save = useCallback(
    async (data: any) => {
      const errors = await validateTeamMemberRelationship(data);
      if (Object.keys(errors).length > 0) {
        logger.warn("found validation errors", errors);
        return errors;
      }
      await mutate(
        {
          type: "create",
          resource: "teamMemberRelationships",
          payload: { data },
        },
        {
          returnPromise: true,
          onSuccess
        }
      );
    },
    [mutate],
  );

  return (
    <Create
      {...props}
    >
      <SimpleForm
        initialValues={{ relationship: "MEMBER" }}
        save={save}
      >
        <SelectInput
          source="relationship"
          choices={teamMemberRelationshipStatuses}
          fullWidth
        />
        <ReferenceInput
          source="teamId"
          reference="teams"
          label="Team"
          filterToQuery={(searchText) => ({
            listTeams: { name: searchText },
          })}
          perPage={2000}
          alwaysOn
          isRequired
          validate={validateRequired}
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          source="memberProfileId"
          reference="profiles"
          label="User"
          filterToQuery={(searchText) => ({
            listProfiles: { fullName: searchText },
          })}
          perPage={700}
          alwaysOn
          isRequired
          validate={validateRequired}
        >
          <AutocompleteInput optionText="fullName" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};
