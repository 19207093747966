import * as React from 'react';
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormGroup, { FormGroupProps } from '@material-ui/core/FormGroup';
import Switch, { SwitchProps } from '@material-ui/core/Switch';
import { FieldTitle, useInput, InputProps } from 'ra-core';
import { InputHelperText, InputPropTypes, sanitizeInputRestProps } from 'ra-ui-materialui';


const BooleanCheckboxInput = (props: BooleanCheckboxInputProps) => {
    const {
        format,
        label,
        fullWidth,
        helperText,
        onBlur,
        onChange,
        onFocus,
        options,
        disabled,
        parse,
        resource,
        source,
        validate,
        ...rest
    } = props;
    const {
        id,
        input: { onChange: finalFormOnChange, type, value, ...inputProps },
        isRequired,
        meta: { error, submitError, touched },
    } = useInput({
        format,
        onBlur,
        onChange,
        onFocus,
        parse,
        resource,
        source,
        type: 'checkbox',
        validate,
        ...rest,
    });

    const handleChange = useCallback(
        (event, value) => {
            finalFormOnChange(value);
        },
        [finalFormOnChange]
    );

    /*


        <FormControlLabel
            htmlFor={`${id}_${getChoiceValue(choice)}`}
            key={getChoiceValue(choice)}
            onChange={onChange}
            control={
                <Checkbox
                    id={`${id}_${getChoiceValue(choice)}`}
                    color="primary"
                    className={classes.checkbox}
                    checked={
                        value
                            ? value.find(v => v == getChoiceValue(choice)) !== // eslint-disable-line eqeqeq
                              undefined
                            : false
                    }
                    value={String(getChoiceValue(choice))}
                    {...options}
                    {...rest}
                />
            }
            label={choiceName}
        />


    */

    return (
        <FormGroup {...sanitizeInputRestProps(rest)}>
            <FormControlLabel
                control={
                    <Checkbox
                        id={id}
                        color="primary"
                        onChange={handleChange}
                        {...inputProps}
                        {...options}
                        disabled={disabled}
                    />
                }
                label={
                    <FieldTitle
                        label={label}
                        source={source}
                        resource={resource}
                        isRequired={isRequired}
                    />
                }
            />
            <FormHelperText error={!!(error || submitError)}>
                <InputHelperText
                    touched={touched || false}
                    error={error || submitError}
                    helperText={helperText}
                />
            </FormHelperText>
        </FormGroup>
    );
};

BooleanCheckboxInput.propTypes = {
    ...InputPropTypes,
    // @ts-ignore
    options: PropTypes.shape(Switch.propTypes),
    disabled: PropTypes.bool,
};

BooleanCheckboxInput.defaultProps = {
    options: {},
};

export type BooleanCheckboxInputProps = InputProps<SwitchProps> &
    Omit<FormGroupProps, 'defaultValue' | 'onChange' | 'onBlur' | 'onFocus'>;

export default BooleanCheckboxInput;
