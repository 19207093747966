import React, { Fragment, useContext, useState } from "react";
import {
  AutocompleteInput,
  Datagrid,
  DateField,
  FilterButton,
  ReferenceField,
  ReferenceInput,
  RefreshButton,
  Show,
  ShowButton,
  SimpleShowLayout,
  TextField,
  useDataProvider,
  useNotify,
  useRefresh,
  BulkExportButton,
} from "react-admin";
import type {
  BulkExportButtonProps,
  ListProps,
  ShowProps,
} from "react-admin";
import { DateTimeInput as MaterialDateTimeInput } from "./MaterialDatePicker";
import { JsonField } from "react-admin-json-view";
import { Logger } from "aws-amplify";
import { MeetingsStateContext } from "../App";
import { LogPayloadField } from "./CustomFields";
import { CustomList } from "./CustomList";
import { TranscriptionEventAuditLog } from "../API";
import TopToolbar from "./TopToolbar";

const logger = new Logger("TranscriptionEventAuditLog");
const defaultQuery = "listTranscriptionEventAuditLogs";

const exporter = (transcriptions: TranscriptionEventAuditLog[]) => { // fetchRelatedRecords

  logger.info('transcriptions', transcriptions);

  for (let x = 0; x < transcriptions.length; x++) {
    const transcription = transcriptions[x];
    logger.info('transcription', transcription);
    /* Auth.currentUserCredentials().then(
      async (creds) => {
        const input = {
          meetingInviteId: transcription.meetingInviteId,
          profileId: transcription.profileId, // TODO replace with jobId
          identityId: creds.identityId,
        };
        logger.info('input', input);
        createTranscriptionExport(input).then(
          (transcriptionExportResult) => {
            logger.info('transcriptionExportResult', transcriptionExportResult);
            if (transcriptionExportResult.url) {
              window.open(transcriptionExportResult.url, "_blank");
            } else {
              logger.warn("failed to get download URL");
              // const errorMessage = `Meeting creation failed: ${err}`;
              // notify(errorMessage, { type: "warning" });
            }
          }
        ).catch(
          (err) => {
            logger.error(err);
          }
        );
      }
    ); */
    break;
  }

  /* transcriptions.forEach(
    transcription => {
      logger.info('transcription', transcription);

      break;
    }
  ); */

  /* const postsForExport = posts.map(post => {
      const { backlinks, author, ...postForExport } = post; // omit backlinks and author
      postForExport.author_name = post.author.name; // add a field
      return postForExport;
  });
  jsonExport(postsForExport, {
      headers: ['id', 'title', 'author_name', 'body'] // order fields in the export
  }, (err, csv) => {
      downloadCSV(csv, 'posts'); // download as 'posts.csv` file
  }); */
};

const filters = [
  <ReferenceInput
    source="profileId"
    reference="profiles"
    label="User"
    filterToQuery={(searchText) => ({
      listProfiles: { fullName: searchText },
    })}
    perPage={700}
    resettable
    alwaysOn
    >
    <AutocompleteInput optionText="fullName" />
  </ReferenceInput>,
  <ReferenceInput
    source="meetingInviteId"
    reference="meetingInvites"
    label="Meeting invite"
    filterToQuery={(searchText) => ({
      listMeetingInviteIds: { id: searchText },
    })}
    perPage={2000}
    resettable
    >
    <AutocompleteInput optionText="id" />
  </ReferenceInput>,
  <MaterialDateTimeInput
    label="Begin Date Range"
    source="date"
    options={{ format: 'MM/dd/yyyy, hh:mm a', ampm: true, clearable: true }}
  />,
  <MaterialDateTimeInput
    label="End Date Range"
    source="endDate"
    options={{ format: 'MM/dd/yyyy, hh:mm a', ampm: true, clearable: true }}
  />,
];

const BulkActionButtons = (props: BulkExportButtonProps) => ( // TODO deprecate export
  <Fragment>
      <BulkExportButton {...props} />
  </Fragment>
);


const ListActions = (props: any) => {
  const {
    className,
    resource,
    filters,
    filterValues,
    displayedFilters,
    showFilter,
  } = props;
  return (
    <TopToolbar className={className}>
      <FilterButton
        resource={resource}
        filters={filters}
        filterValues={filterValues}
        displayedFilters={displayedFilters}
        showFilter={showFilter}
      />
      <RefreshButton />
    </TopToolbar>
  );
};

export const TranscriptionEventAuditLogList = (props: ListProps) => {
  const [query, setQuery] = useState(defaultQuery);
  const refresh = useRefresh(); // TODO refresh at interval of implement realtime updates
  const notify = useNotify();
  const meetingsState = useContext(MeetingsStateContext);
  const {
    currentProfile,
  } = meetingsState;
  const dataProvider = useDataProvider();

  return (
    <CustomList
      {...props}
      filters={filters}
      filterDefaultValues={{ profileId: currentProfile?.id }}
      actions={<ListActions />}
      // exporter={exporter}
      // bulkActionButtons={<BulkActionButtons />}
    >
      <Datagrid>
        <DateField source="date" showTime={true} sortable={true} />
        <TextField source="meetingInvite.id" label="Meeting invite ID" sortable={false} />
        <TextField source="resource" sortable={false} />
        <TextField source="action" sortable={false} />
        <LogPayloadField source="payload" label="Message" sortable={false} />
        <ShowButton />
      </Datagrid>
    </CustomList>
  );
};

export const TranscriptionEventAuditLogShow = (props: ShowProps) => {

  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="id" label="ID" />
        <DateField source="date" showTime={true} />
        <TextField source="author" />
        <TextField source="resource" />
        <TextField source="action" />
        <JsonField
          source="payload"
          addLabel={true}
          jsonString={true}
          reactJsonOptions={{
            // shapeshifter:inverted
            theme: "shapeshifter",
            name: null,
            collapsed: true,
            enableClipboard: false,
            displayDataTypes: false,
          }}
        />
        <TextField source="ipAddress" label="IP address" />
        <ReferenceField
          source="meetingInviteId"
          reference="meetingInvites"
          label="Meeting invite"
          link="show"
        >
          <TextField source="id" />
        </ReferenceField>
        <ReferenceField
          source="profileId"
          reference="profiles"
          label="User"
          link="show"
        >
          <TextField source="fullName" />
        </ReferenceField>
        <DateField source="createdAt" showTime={true} />
        <DateField source="updatedAt" showTime={true} />
      </SimpleShowLayout>
    </Show>
  );
}
