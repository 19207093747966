import React, { useState } from "react";
import {
  BooleanField,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  EditProps,
  FilterButton,
  ListButton,
  ReferenceField,
  RefreshButton,
  SelectInput,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  required,
  email as emailValidator,
} from "react-admin";
import type {
  ListProps,
  ListActionsProps,
  ShowProps,
} from "react-admin";
import { DateTimeInput as MaterialDateTimeInput } from "./MaterialDatePicker";
import { Theme, useMediaQuery } from "@material-ui/core";
import { SimpleList } from "@react-admin/ra-preferences/esm/src/no-code";
import {
  CONTACT_REQUEST_TIMEFRAME_CHOICES,
  FEEDBACK_SUMMARY_CHOICES,
  MEETING_INVITATION_TYPE_CHOICES,
  MEETING_STATUS_CHOICES,
} from "../constants";
import { MeetingRelationshipTypeField } from "./CustomFields";
import DeleteWithConfirmToolbar from "./DeleteWithConfirmToolbar";
import { CustomList, ListPagination } from "./CustomList";
import TopToolbar from "./TopToolbar";

const defaultQuery = "listMeetingInvites";
const validateRequired = [required()];

const filters = [
  <TextInput
    label="Title"
    source="title"
    resettable
  />,
  <SelectInput
    label="Status"
    source="status"
    choices={MEETING_STATUS_CHOICES}
    alwaysOn
  />,
  <MaterialDateTimeInput
    label="Begin Date Range"
    source="startDateTime"
    options={{ format: 'MM/dd/yyyy, hh:mm a', ampm: true, clearable: true }}
  />,
  <MaterialDateTimeInput
    label="End Date Range"
    source="endDateTime"
    options={{ format: 'MM/dd/yyyy, hh:mm a', ampm: true, clearable: true }}
  />,
  <SelectInput
    label="Type"
    source="meetingInvitationType"
    choices={MEETING_INVITATION_TYPE_CHOICES}
    alwaysOn
  />,
];

const ListActions = (props: ListActionsProps) => {
  const {
    className,
  } = props;
  return (
    <TopToolbar className={className}>
      <FilterButton />
    </TopToolbar>
  );
};

const ShowActions = (props: ShowProps) => {
  const {
    className,
    basePath,
    id,
    permissions
  } = props;
  return (
    <TopToolbar className={className}>
      <ListButton basePath={basePath} />
      <RefreshButton />
      {(permissions.includes("superuser") || permissions.includes("admin")) && (
        <EditButton basePath={basePath} label="Edit" record={{ id: id as string }} />
      )}
      <ShowButton basePath={basePath} label="Show" record={{ id: id as string }} />
    </TopToolbar>
  );
};

export const MeetingInviteList = (props: ListProps) => {
  const [query, setQuery] = useState(defaultQuery);
  // see: https://marmelab.com/react-admin/Tutorial.html#supporting-mobile-devices
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  return (
    <CustomList
      {...props}
      filters={filters}
      actions={<ListActions />}
      filterDefaultValues={{ status: "SCHEDULED" }}
      bulkActionButtons={false}
      pagination={<ListPagination />}
    >
      {isSmall ? (
        <SimpleList
          primaryText={record => record.title}
          secondaryText={record => new Date(record.startDateTime).toLocaleDateString()}
          tertiaryText={record => new Date(record.endDateTime).toLocaleDateString()}
        />
      ) : (
        <Datagrid>
          <TextField source="title" sortable={false} />
          <MeetingRelationshipTypeField
            source="meetingInvitationType"
            sortable={false}
            label="Type"
          />
          <BooleanField
            source="isReadinessTest"
            label="Test?"
          />
          <ReferenceField
            source="profileId"
            reference="profiles"
            label="User"
            link="show"
          >
            <TextField source="fullName" />
          </ReferenceField>
          <DateField source="startDateTime" showTime={true} sortable={true} />
          <DateField source="endDateTime" showTime={true} sortable={false} />
          <DateField source="updatedAt" showTime={true} sortable={false} />
          <ShowButton />
          <EditButton />
        </Datagrid>
      )}
    </CustomList>
  );
};

export const MeetingInviteShow = (props: ShowProps) => (
  <Show {...props} actions={<ShowActions {...props} />}>
    <SimpleShowLayout>
      <TextField source="id" label="ID" />
      <ReferenceField
        source="profileId"
        reference="profiles"
        label="User"
        link="show"
      >
        <TextField source="fullName" />
        </ReferenceField>
      <TextField source="title" />
      <MeetingRelationshipTypeField
        source="meetingInvitationType"
        sortable={false}
        label="Type"
      />
      <TextField source="status" />
      <TextField source="description" />
      <DateField showTime={true} source="startDateTime" />
      <DateField showTime={true} source="endDateTime" />
      <DateField showTime={true} source="actualStartDateTime" />
      <DateField showTime={true} source="actualEndDateTime" />
      <TextField source="sellerOrganizationName" />
      <TextField source="sellerTeamName" />
      <TextField source="buyerTeamName" />
      <BooleanField
        source="videoEnabled"
        label="Video enabled?"
        defaultValue="No"
      />
      <BooleanField
        source="privacyEnabled"
        label="Enable privacy mode?"
        defaultValue="No"
      />
      <BooleanField
        source="transcriptionEnabled"
        label="Enable transcription?"
        defaultValue="No"
      />
      <BooleanField
        source="isReadinessTest"
        label="Is readiness test?"
        defaultValue="No"
      />
      <BooleanField
        source="isInterested"
        label="Is interested?"
        defaultValue="No"
      />
      <TextField source="feedbackSummary" />
      <TextField source="contactRequestTimeframe" />
      <ReferenceField
        source="providerId"
        reference="meetingRoomProviders"
        label="Room provider"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        source="meetingRoomMeetingId"
        reference="meetingRooms"
        label="Room"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>

      <ReferenceField
        source="productId"
        reference="products"
        label="Product"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>

      <DateField source="createdAt" showTime={true} />
      <DateField source="updatedAt" showTime={true} />
    </SimpleShowLayout>
  </Show>
);

const validateEmail = [emailValidator()];

export const MeetingInviteEdit = (props: EditProps) => (
  <Edit
    {...props}
    undoable={false}
    mutationMode="pessimistic"
  >
    <SimpleForm
      toolbar={<DeleteWithConfirmToolbar />}
    >
      <TextInput source="id" label="ID" disabled fullWidth />
      <MeetingRelationshipTypeField
        source="meetingInvitationType"
        sortable={false}
        label="Type"
      />
      <ReferenceField
        source="profileId"
        reference="profiles"
        label="User"
        link="show"
      >
        <TextField source="fullName" />
      </ReferenceField>
      <TextInput
        source="title"
        isRequired
        fullWidth
        disabled
        validate={validateRequired}
      />
      <SelectInput source="status" choices={MEETING_STATUS_CHOICES} disabled />
      <MaterialDateTimeInput
        source="startDateTime"
        disabled
        options={{ format: 'MM/dd/yyyy, hh:mm a', ampm: true, clearable: true }}
        isRequired
        validate={validateRequired}
      /> 
      <MaterialDateTimeInput
        source="endDateTime"
        disabled
        options={{ format: 'MM/dd/yyyy, hh:mm a', ampm: true, clearable: true }}
        isRequired
        validate={validateRequired}
      />
      <SelectInput
        source="feedbackSummary"
        choices={FEEDBACK_SUMMARY_CHOICES}
        allowEmpty={true}
        resettable={true}
        translateChoice={false}
      />
      <SelectInput
        source="contactRequestTimeframe"
        choices={CONTACT_REQUEST_TIMEFRAME_CHOICES}
        allowEmpty={true}
        resettable={true}
        translateChoice={false}
      />
    </SimpleForm>
  </Edit>
);
