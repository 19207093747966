import React, { useState } from "react";
import {
  Create,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  ReferenceField,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  useRecordContext,
  Link,
  required,
} from "react-admin";
import type {
  CreateProps,
  EditProps,
  ListProps,
  ShowProps,
} from "react-admin";
import DeleteWithConfirmToolbar from "./DeleteWithConfirmToolbar";
import { CustomList, ListPagination } from "./CustomList";
import TopToolbar from "./TopToolbar";

const defaultQuery = "listOrganizationLocations";
const validateRequired = [required()];


export const OrganizationLocationList = (props: ListProps) => {
  const [query, setQuery] = useState(defaultQuery);

  return (
    <CustomList
      {...props}
      bulkActionButtons={false}
      pagination={<ListPagination />}
    >
      <Datagrid>
        <TextField
          source="organization.name"
          label="Organization name"
          sortable={false}
        />
        <TextField source="title" sortable={false} />
        <TextField source="address" sortable={false} />
        <TextField source="city" sortable={false} />
        <ShowButton />
        <EditButton />
      </Datagrid>
    </CustomList>
  );
};

export const OrganizationLocationShow = (props: ShowProps) => {

  const ShowActions = (props: ShowProps) => {
    const {
      basePath,
      className,
      id,
    } = props;
    const record = useRecordContext();
    if (!record) {
      return <></>;
    }
    return (
      <TopToolbar className={className}>
        <ShowButton
          component={Link}
          to={{
            pathname: `/organizations/${record.organizationId}/show`,
          }}
          label="Return to organization"
        />
        <EditButton basePath={basePath} label="Edit" record={{ id: id as string }} />
      </TopToolbar>
    );
  };

  return (
    <Show
      {...props}
      actions={<ShowActions {...props} />}
    >
      <SimpleShowLayout>
        <TextField source="id" label="ID" fullWidth />
        <TextField source="title" />
        <TextField source="address" />
        <TextField source="city" />
        <TextField source="state" />
        <TextField source="country" />
        <ReferenceField
          label="Company"
          source="organizationId"
          reference="organizations"
        >
          <TextField source="name" />
        </ReferenceField>
        <DateField showTime={true} source="createdAt" />
        <DateField showTime={true} source="updatedAt" />
      </SimpleShowLayout>
    </Show>
  );
}

export const OrganizationLocationEdit = (props: EditProps) => {

  const EditActions = (props: EditProps) => {
    const {
      className,
      basePath,
      id
    } = props;
    const record = useRecordContext();
    if (!record) {
      return <></>;
    }
    return (
      <TopToolbar className={className}>
        <ShowButton
          component={Link}
          to={{
            pathname: `/organizations/${record.organizationId}/show`,
          }}
          label="Return to organization"
        />
        <ShowButton
          basePath={basePath}
          label="Show"
          record={{ id: id as string }}
        />
      </TopToolbar>
    );
  };

  return (
    <Edit
      {...props}
      actions={<EditActions {...props} />}
      undoable={false}
      mutationMode="optimistic"
    >
      <SimpleForm
        toolbar={<DeleteWithConfirmToolbar
          mutationMode="optimistic"
          undoable={false}
        />}
      >
        <TextInput source="id" label="ID" disabled />
        <TextInput
          source="title"
          isRequired
          fullWidth
          validate={validateRequired}
        />
        <TextInput
          source="address"
          isRequired
          fullWidth
          validate={validateRequired}
        />
        <TextInput source="city" fullWidth />
        <TextInput source="state" fullWidth />
        <TextInput source="country" fullWidth />
        <ReferenceInput
          source="organizationId"
          reference="organizations"
          label="Company"
          filterToQuery={(searchText) => ({
            listOrganizations: { name: searchText },
          })}
          perPage={2000}
          isRequired
          validate={validateRequired}
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
}

export const OrganizationLocationCreate = (props: CreateProps) => {

  const CreateActions = (props: CreateProps) => {
    const {
      className,
    } = props;
    const record = useRecordContext();
    if (!record) {
      return <></>;
    }
    return (
      <TopToolbar className={className}>
        <ShowButton
          component={Link}
          to={{
            pathname: `/organizations/${record.organizationId}/show`,
          }}
          label="Return to organization"
        />
      </TopToolbar>
    );
  };

  return (
    <Create
      {...props}
      actions={<CreateActions {...props} />}
    >
      <SimpleForm>
        <TextInput
          source="title"
          isRequired
          fullWidth
          validate={validateRequired}
        />
        <TextInput
          source="address"
          isRequired
          fullWidth
          validate={validateRequired}
        />
        <TextInput source="city" fullWidth />
        <TextInput source="state" fullWidth />
        <TextInput source="country" fullWidth />
        <ReferenceInput
          source="organizationId"
          reference="organizations"
          label="Company"
          filterToQuery={(searchText) => ({
            listOrganizations: { name: searchText },
          })}
          perPage={2000}
          isRequired
          validate={validateRequired}
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
}
