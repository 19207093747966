import React, { useEffect, useState } from 'react';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import IconImageEye from '@material-ui/icons/RemoveRedEye';
import IconKeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { Button, EditProps, useNotify, useRedirect } from 'react-admin';
import { API, Logger } from 'aws-amplify';
import { ListMeetingsByStatusQuery, Meeting, MeetingStatus, ModelSortDirection } from '../API';
import { DateTime } from 'luxon';
import { graphqlOperation, GraphQLResult } from '@aws-amplify/api';
import { GraphQLErrorResponse } from '../types';
import { AWS_TIMESTAMP_AS_LUXON_FORMAT_MINUTE_ACCURACY, meetingColumns, } from "../constants";
import { listMeetingsByStatus } from '../graphql/queries';
import { Typography } from '@material-ui/core';
import { DataGrid } from "@mui/x-data-grid";

const logger = new Logger("ShowScheduledMeetingsButton");

const useStyles = makeStyles({
    field: {
        // These styles will ensure our drawer don't fully cover our
        // application when teaser or title are very long
        '& span': {
            display: 'inline-block',
            maxWidth: '20em'
        }
    }
});

const getScheduledMeetings = async (
  hoursAhead: number = 48 * 200, // TODO set to production value
  limit: number = 0,
): Promise<ListMeetingsByStatusQuery["listMeetingsByStatus"]> => {
  const now = DateTime.utc();
  const minutesFromNow = now.plus({ hours: hoursAhead });
  const nowFormatted = now.toFormat(
    AWS_TIMESTAMP_AS_LUXON_FORMAT_MINUTE_ACCURACY
  );
  const minutesFromNowFormatted = minutesFromNow.toFormat(
    AWS_TIMESTAMP_AS_LUXON_FORMAT_MINUTE_ACCURACY
  );
  return (
    API.graphql(
      graphqlOperation(listMeetingsByStatus, {
        status: MeetingStatus.SCHEDULED,
        startDateTime: { between: [nowFormatted, minutesFromNowFormatted] },
        sortDirection: ModelSortDirection.ASC,
        ...(limit && { limit }),
      })
    ) as Promise<GraphQLResult>
  )
    .then((queryResult) => {
      if (queryResult.errors || !queryResult.data) {
        logger.error("queryResult", queryResult);
        throw new Error("Data provider error");
      }
      logger.info("queryResult.data", queryResult.data);
      return (queryResult.data as ListMeetingsByStatusQuery)
        .listMeetingsByStatus;
    })
    .catch((err: GraphQLErrorResponse) => {
      const { errors } = err;
      if (errors && errors.length > 0) {
        const { message } = err.errors[0];
        const errorMessage = message || "Unknown error";
        logger.error(errorMessage);
        throw new Error(errorMessage);
      }
      logger.error(err);
      throw err;
    });
};

export const ShowScheduledMeetingsButton = (props: EditProps) => {
    const [showPanel, setShowPanel] = useState(false);
    const classes = useStyles();
    const [allScheduledMeetings, setAllScheduledMeetings] = useState<Meeting[]>([]);
    const notify = useNotify();
    const redirect = useRedirect();

    useEffect(
      () => {
        getScheduledMeetings().then(
          (scheduledMeetingsResult) => {
            logger.info("scheduledMeetingsResult.items", scheduledMeetingsResult?.items);
            setAllScheduledMeetings(scheduledMeetingsResult?.items ? scheduledMeetingsResult.items as Meeting[] : []);
          }
        ).catch(
          (err) => {
            notify(err.message, { type: "warning" });
            logger.error("getScheduledMeetings error", err);
          }
        );
      },
      []
    );

    const handleClick = () => {
        setShowPanel(true);
    };

    const handleCloseClick = () => {
        setShowPanel(false);
    };

    return (
      <>
        <Button onClick={handleClick} label="Show all scheduled meetings">
            <IconImageEye />
        </Button>
        <Drawer anchor="right" open={showPanel} onClose={handleCloseClick}>
          <div>
            <Button label="Close" onClick={handleCloseClick}>
                <IconKeyboardArrowRight />
            </Button>
          </div>
          <div style={{ minWidth: 1000, margin: '1em' }}>
            <Typography variant="h6">All Scheduled Meetings</Typography>
            {false && (<Typography variant="body2">
              Posts will only be published once an editor approves them
            </Typography>)}
            <DataGrid
              density="compact"
              rows={allScheduledMeetings}
              columns={meetingColumns}
              pageSize={50}
              rowsPerPageOptions={[5]}
              autoHeight={true}
              onRowClick={(params, event) => { redirect(`/scheduler/${params.id}`); }}
              onError={
                (err) => {
                  logger.error("all scheduled meetings grid error", err);
                  notify(`${err}`, { type: "warning" })
                }
              }
            />
          </div>
        </Drawer>
      </>
    );
};
