import React, { useContext, useEffect, useState } from "react";
import DetectRTC from "detectrtc";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import { createOrUpdateProfileDeviceResponse } from "../lib/helpers";
import type { CreateProfileDeviceInput } from "../API";
import { MeetingsStateContext } from "../App";
import { useDataProvider } from "react-admin";


function WebRTCDetectorPage() {
  const [hasWebcam, setHasWebcam] = useState(false);
  const [hasMicrophone, setHasMicrophone] = useState(false);
  const [isScreenCapturingSupported, setIsScreenCapturingSupported] = useState(false);
  const [isWebRTCSupported, setIsWebRTCSupported] = useState(false);
  const [isWebsiteHasMicrophonePermissions, setIsWebsiteHasMicrophonePermissions] = useState(false);
  const [isWebsiteHasWebcamPermissions, setIsWebsiteHasWebcamPermissions] = useState(false);
  const [isWebSocketsSupported, setIsWebSocketsSupported] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const meetingsState = useContext(MeetingsStateContext);
  const dataProvider = useDataProvider();
  const {
    currentProfile,
    userAgentData,
  } = meetingsState;

  const detectRTCCallback = (stream = null) => {
    setHasWebcam(DetectRTC.hasWebcam);         // (has webcam device!)
    setHasMicrophone(DetectRTC.hasMicrophone); // (has microphone device!)
    setIsWebRTCSupported(DetectRTC.isWebRTCSupported);
    setIsWebSocketsSupported(DetectRTC.isWebSocketsSupported);
    setIsScreenCapturingSupported(DetectRTC.isScreenCapturingSupported);               // Chrome, Firefox, Opera, Edge and Android
    setIsWebsiteHasWebcamPermissions(DetectRTC.isWebsiteHasWebcamPermissions);         // getUserMedia allowed for HTTPs domain in Chrome?
    setIsWebsiteHasMicrophonePermissions(DetectRTC.isWebsiteHasMicrophonePermissions); // getUserMedia allowed for HTTPs domain in Chrome?
    if (stream) {
      // @ts-ignore
      stream.getTracks().forEach(
        // @ts-ignore
        (track) => {
          track.stop();
        }
      );
    }
  };

  async function runWebRTCCheck() {
    try {
      const stream = await navigator.mediaDevices.getUserMedia(
        {
          audio: true,
          video: true
        }
      );
      DetectRTC.load(
        () => {
          // @ts-ignore
          detectRTCCallback(stream);
        }
      );
    } catch (err) {
      console.error(err);
      DetectRTC.load(
        () => {
          detectRTCCallback();
        }
      );
    }
    setIsFinished(true);
  };


  useEffect(() => {
    if (currentProfile?.id && userAgentData?.ua && isFinished) {
      const createInput: CreateProfileDeviceInput = {
        hasWebcam: DetectRTC.hasWebcam,
        hasMicrophone: DetectRTC.hasMicrophone,
        isWebRTCSupported: DetectRTC.isWebRTCSupported,
        isWebSocketsSupported: DetectRTC.isWebSocketsSupported,
        isScreenCapturingSupported: DetectRTC.isScreenCapturingSupported,
        isWebsiteHasWebcamPermissions: DetectRTC.isWebsiteHasWebcamPermissions,
        isWebsiteHasMicrophonePermissions: DetectRTC.isWebsiteHasMicrophonePermissions,
        deviceHash: "", // TODO set?
        profileId: currentProfile.id,
        userAgent: JSON.stringify(userAgentData), // TODO userAgentData is required
        userAgentSummary: userAgentData?.ua || undefined,
      };
      createOrUpdateProfileDeviceResponse(
        dataProvider,
        createInput,
        currentProfile,
        userAgentData,
      );
    }
  }, [currentProfile?.id, userAgentData?.ua, isFinished]);



  useEffect(() => {
    if (!isFinished) {
      runWebRTCCheck();
    }
  }, [isFinished]);

  return (
    <table style={{backgroundColor: "#ffffff"}}>
      <tbody>
        <tr>
          <td>WebRTC support</td>
          <td>
            {isWebRTCSupported ?
              <CheckIcon />
              :
              <CloseIcon />
            }
          </td>
        </tr>
        <tr>
          <td>Microphone detection</td>
          <td>
            {hasMicrophone ?
              <CheckIcon />
              :
              <CloseIcon />
            }
          </td>
        </tr>
        <tr>
          <td>Microphone permissions</td>
          <td>
            {isWebsiteHasMicrophonePermissions ?
              <CheckIcon />
              :
              <CloseIcon />
            }
          </td>
        </tr>
        <tr>
          <td>Webcam detection</td>
          <td>
            {hasWebcam ?
              <CheckIcon />
              :
              <CloseIcon />
            }
          </td>
        </tr>
        <tr>
          <td>Webcam permissions</td>
          <td>
            {isWebsiteHasWebcamPermissions ?
              <CheckIcon />
              :
              <CloseIcon />
            }
          </td>
        </tr>
        <tr>
          <td>WebSockets support</td>
          <td>
            {isWebSocketsSupported ?
              <CheckIcon />
              :
              <CloseIcon />
            }
          </td>
        </tr>
        <tr>
          <td>Screen capturing</td>
          <td>
            {isScreenCapturingSupported ?
              <CheckIcon />
              :
              <CloseIcon />
            }
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default WebRTCDetectorPage