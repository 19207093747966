import React, {
  useCallback,
  useContext,
  useState,
  type PropsWithChildren,
} from "react";
import {
  AutocompleteInput,
  Create,
  CreateButton,
  Datagrid,
  DateField,
  DeleteWithConfirmButton,
  FilterButton,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  SelectInput,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  Tab,
  TabbedShowLayout,
  TextField,
  useNotify,
  usePermissions,
  useQuery,
  useRedirect,
  useShowController,
  required,
  useMutation,
} from "react-admin";
import type {
  CreateProps,
  ListActionsProps,
  ListProps,
  ShowProps,
} from "react-admin";
import { DateTimeInput as MaterialDateTimeInput } from "./MaterialDatePicker";
import { ExportJob, JobStatus } from "../API";
import { Logger } from "@aws-amplify/core";
import { Auth } from "aws-amplify";
import { MeetingsStateContext } from "../App";
import { LogPayloadField } from "./CustomFields";
import { Typography } from "@material-ui/core";
import {
  Timeline,
  TimelineSkeleton,
} from "@react-admin/ra-audit-log";
import { CustomTimelineGroup } from "./CustomTimeline";
import { CustomList, ListPagination } from "./CustomList";
import { validateExportJob } from "../lib/helpers";
import TopToolbar from "./TopToolbar";

const defaultQuery = "listExportJobs";
const jobStatuses = [
  { name: "Pending", id: "PENDING" },
  { name: "Processing", id: "PROCESSING" },
  { name: "Failed", id: "FAILED" },
  { name: "Cancelled", id: "CANCELLED" },
  { name: "Completed", id: "COMPLETED" },
];

const logger = new Logger("ExportJob");
const validateRequired = [required()];

interface AsideProps extends ShowProps {
  record?: ExportJob;
}

const filters = [
  <ReferenceInput
    source="profileId"
    reference="profiles"
    label="User"
    filterToQuery={(searchText) => ({
      listProfiles: { fullName: searchText },
    })}
    perPage={700}
    resettable
    >
    <AutocompleteInput optionText="fullName" />
  </ReferenceInput>,
  <SelectInput label="Status" source="status" choices={jobStatuses} filter="listExportJobsByStatus" />,
  <MaterialDateTimeInput
    label="Begin Date Range"
    source="lastStartDateTime"
    options={{ format: 'MM/dd/yyyy, hh:mm a', ampm: true, clearable: true }}
  />,
  <MaterialDateTimeInput
    label="End Date Range"
    source="lastEndDateTime"
    options={{ format: 'MM/dd/yyyy, hh:mm a', ampm: true, clearable: true }}
  />,
];

const ListActions = (props: PropsWithChildren<ListActionsProps>): JSX.Element => {
  const {
    className,
    basePath,
    resource,
    filters,
    filterValues,
    displayedFilters,
    showFilter,
  } = props;
  return (
    <TopToolbar className={className}>
      <FilterButton
        resource={resource} // @ts-ignore
        filters={filters}
        filterValues={filterValues}
        displayedFilters={displayedFilters}
        showFilter={showFilter}
      />
      <CreateButton basePath={basePath} />
    </TopToolbar>
  );
};

export const ExportJobList = (props: ListProps) => {
  const [query, setQuery] = useState(defaultQuery);
  const { loaded, permissions } = usePermissions();

  return (
    <CustomList
      {...props} filters={filters}
      actions={<ListActions />}
      bulkActionButtons={false}
      pagination={<ListPagination />}
    >
      <Datagrid>
        <TextField source="status" sortable={false} />
        <DateField source="startDateTime" showTime={true} sortable={true} />
        <DateField source="endDateTime" showTime={true} sortable={false} />
        <TextField source="profile.fullName" label="Full name" sortable={false} />
        {(loaded && (permissions.includes("superuser") || permissions.includes("admin"))) && (
          <DeleteWithConfirmButton 
            undoable={false}
            mutationMode="optimistic"
          />
        )}
        <ShowButton />
      </Datagrid>
    </CustomList>
  );
};

const Aside = (props: AsideProps) => { 

  const {
    record,
  } = props;
  
  const { data, loading, error } = useQuery(
    { 
      type: "getList",
      resource: "exportJobAuditLogs",
      payload: {
        pagination: { page: 1, perPage: 50 },
        sort: {
          field: "listExportJobAuditLogsByExportJobId",
          order: "ASC"
        },
        filter: {
          listExportJobAuditLogsByExportJobId: {
            exportJobId: record?.id,
          },
        },
      },
    }
  );

  return (
    <SimpleShowLayout>
      <Typography variant="h6">Log</Typography>
      <Timeline
        loaded={!loading && !error && data}
        records={data}
        skeleton={<TimelineSkeleton length={50} />}
      >
        <CustomTimelineGroup />
      </Timeline>
    </SimpleShowLayout>
  );
};

export const ExportJobShow = (props: ShowProps) => {
  
  const { record } = useShowController(props);  
  const propsWithAside = {
    ...props,
    ...(
      (record && record.id) &&
      { aside: <Aside {...props} record={record as ExportJob} /> }
    ),
  };
  return (
    <Show
      {... propsWithAside}
    >
      <TabbedShowLayout>
        <Tab label="Details">
          <TextField source="id" label="ID" fullWidth />
          <TextField source="status" />
          <ReferenceField
            source="profileId"
            reference="profiles"
            label="User"
            link="show"
          >
            <TextField source="fullName" />
          </ReferenceField>
          <DateField source="startDateTime" showTime={true} />
          <DateField source="endDateTime" showTime={true} />
          <DateField source="createdAt" showTime={true} />
          <DateField source="updatedAt" showTime={true} />
        </Tab>
        <Tab label="Log">
          <ReferenceManyField
            reference="exportJobAuditLogs"
            target="exportJobId"
            label="Audit Logs"
            perPage={25}
            fullWidth
            filter={{ listExportJobAuditLogsByExportJobId: {} }}
          >
            <Datagrid>
              <DateField source="date" showTime={true} sortable={false} />
              <TextField source="author" label="Author" sortable={false} />
              <TextField source="resource" label="Resource" sortable={false} />
              <TextField source="action" label="Action" sortable={false} />
              <LogPayloadField source="payload" label="Message" sortable={false} />
              <ShowButton />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
}

export const ExportJobCreate = (props: CreateProps) => {

  const meetingsState = useContext(MeetingsStateContext);
  const notify = useNotify();
  const redirect = useRedirect();
  const [ mutate ] = useMutation();

  const {
    isAdmin,
    currentProfile,
  } = meetingsState;

  const transform = async (data: any) => {
    logger.info("data", data);
    return Auth.currentUserCredentials().then(
      async (creds) => {
        logger.info("creds", creds);
        let updateData = {
          ...data,
          ...{
            status: JobStatus.PROCESSING,
            identityId: creds.identityId,
          }
        };
        let {
          profileId,
        } = data;
        logger.info("profileId", profileId);
        if (!isAdmin || profileId) {
          if (!profileId && !isAdmin) {
            profileId = `${currentProfile?.id}`;
          }
          updateData = {
            ...updateData,
            ...{
              profileId,
            },
          }
        }
        return updateData;
      }
    );
  };

  // See: https://marmelab.com/react-admin/doc/3.19/CreateEdit.html#submission-validation
  const save = useCallback(
    async (jobData: any) => {
      const data = await transform(jobData);
      const errors = validateExportJob(data);
      if (Object.keys(errors).length > 0) {
        logger.warn("found validation errors", errors);
        return errors;
      }
      try {
        if (data["startDateTime"].getSeconds() > 0) {
          data["startDateTime"].setSeconds(0);
        }
        if (data["endDateTime"].getSeconds() > 0) {
          data["endDateTime"].setSeconds(0);
        }
      } catch(err) {
        logger.warn("failed to set seconds");
      }
      try {
        if (data["startDateTime"].getMilliseconds() > 0) {
          data["startDateTime"].setMilliseconds(0);
        }
        if (data["endDateTime"].getMilliseconds() > 0) {
          data["endDateTime"].setMilliseconds(0);
        }
      } catch(err) {
        logger.warn("failed to set milliseconds");
      }
      await mutate(
        {
          type: "create",
          resource: "exportJobs",
          payload: { data },
        },
        { 
          returnPromise: true,
          onSuccess
        }
      );
    },
    [mutate],
  );

  const onSuccess = async (response: any) => {
    const {
      data,
    } = response;
    logger.info("onSuccess.data", data);
    const successMessage = "Export job is processing...";
    notify(successMessage, { type: "success" });
    redirect(`/exportJobAuditLogs?displayedFilters=%7B%22exportJobId%22%3Atrue%7D&filter=%7B%22exportJobId%22%3A%22${data.id}%22%7D&order=ASC&page=1&perPage=10&sort=id`);
  };

  return (
    <Create
      {...props}
    >
      <SimpleForm
        save={save}
      >
        <MaterialDateTimeInput
          source="startDateTime"
          disabled
          options={{ format: 'MM/dd/yyyy, hh:mm a', ampm: true, clearable: true }}
          isRequired
          validate={validateRequired}
        /> 
        <MaterialDateTimeInput
          source="endDateTime"
          disabled
          options={{ format: 'MM/dd/yyyy, hh:mm a', ampm: true, clearable: true }}
          isRequired
          validate={validateRequired}
        />
        {isAdmin && (
        <ReferenceInput
          source="profileId"
          reference="profiles"
          label="User"
          filterToQuery={(searchText) => ({
            listProfiles: { fullName: searchText },
          })}
          perPage={700}
          resettable
          >
          <AutocompleteInput optionText="fullName" />
        </ReferenceInput>
        )}
      </SimpleForm>
    </Create>
  );
};
