import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  AutocompleteInput,
  Datagrid,
  DateField,
  FilterButton,
  ReferenceField,
  ReferenceInput,
  RefreshButton,
  Show,
  ShowButton,
  SimpleShowLayout,
  TextField,
  useDataProvider,
  useNotify,
  useRefresh,
} from "react-admin";
import { Storage } from "@aws-amplify/storage";
import type { ListProps, ShowProps } from "react-admin";
import { AmplifyFileField } from "./AmplifyFileField";
import { DateTimeInput as MaterialDateTimeInput } from "./MaterialDatePicker";
import {
  RealTimeList,
  RecordListEvent,
  useSubscribe,
} from "@react-admin/ra-realtime";
import { JsonField } from "react-admin-json-view";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {
  onCreateTranscriptionAdminJobAuditLog,
  onUpdateTranscriptionAdminJob,
} from "../graphql/subscriptions";
import { API, graphqlOperation, Logger } from "aws-amplify";
import { MeetingsStateContext } from "../App";
import { LogPayloadField } from "./CustomFields";
import { useLocation } from "react-router-dom";
import { JobStatus, TranscriptionAdminJob } from "../API";
import { downloadFileFromS3 } from "../lib/helpers";
import TopToolbar from "./TopToolbar";

const ReactSwal = withReactContent(Swal);

const logger = new Logger("TranscriptionAdminJobAudiLog");
const defaultQuery = "listTranscriptionAdminJobAuditLogs";

const filters = [
  <ReferenceInput
    source="transcriptionAdminJobId"
    reference="transcriptionAdminJobs"
    label="Transcription admin job"
    filterToQuery={(searchText) => ({
      listTranscriptionAdminJobs: { id: searchText },
    })}
    perPage={2000}
    resettable
    >
    <AutocompleteInput optionText="id" />
  </ReferenceInput>,
  <MaterialDateTimeInput
    label="Begin Date Range"
    source="date"
    options={{ format: 'MM/dd/yyyy, hh:mm a', ampm: true, clearable: true }}
  />,
  <MaterialDateTimeInput
    label="End Date Range"
    source="endDate"
    options={{ format: 'MM/dd/yyyy, hh:mm a', ampm: true, clearable: true }}
  />,
];

const ListActions = (props: any) => {
  const {
    className,
    resource,
    filters,
    filterValues,
    displayedFilters,
    showFilter,
  } = props;
  return (
    <TopToolbar className={className}>
      <FilterButton
        resource={resource}
        filters={filters}
        filterValues={filterValues}
        displayedFilters={displayedFilters}
        showFilter={showFilter}
      />
      <RefreshButton />
    </TopToolbar>
  );
};

function useUrlQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

export const TranscriptionAdminJobAuditLogList = (props: ListProps) => {
  const [query] = useState(defaultQuery);
  const refresh = useRefresh();
  const notify = useNotify();
  const urlQuery = useUrlQuery();
  const meetingsState = useContext(MeetingsStateContext);
  const {
    currentProfile,
  } = meetingsState;
  const dataProvider = useDataProvider();

  const handleEventReceived = (event: RecordListEvent) => {
    logger.info("handleEventReceived event", event);
    notify("ra-realtime.action.refresh", "info");
    refresh();
  };

  useSubscribe("resource/transcriptionAdminJobAuditLogs", (event) => {
      logger.info("useSubscribe event", event);
  });

  useEffect(
    () => {
      if (currentProfile?.userId) {
        const subscription = API.graphql(
          graphqlOperation(onCreateTranscriptionAdminJobAuditLog, { owner: currentProfile?.userId })
        ).subscribe({
          next: ({ value }: any) => {
            logger.info("value.data.onCreateTranscriptionAdminJobAuditLog", value.data.onCreateTranscriptionAdminJobAuditLog);
            logger.info("value.data.onCreateTranscriptionAdminJobAuditLog.id", value.data.onCreateTranscriptionAdminJobAuditLog.id);
            const {
              action,
              payload,
              transcriptionAdminJobId,
            } = value.data.onCreateTranscriptionAdminJobAuditLog;
            logger.info("value.data.onCreateTranscriptionAdminJobAuditLog.action", action);
            if (action === 'complete') {
              if (payload) {
                let payloadData = {};
                try {
                  payloadData = JSON.parse(payload);
                } catch(err) {}

                const {
                  message,
                  data
                } = payloadData as any;
                if (message) {
                  logger.info('message', message);
                  if (message !== "success") {
                    notify(message, { type: "success" });
                  } else {
                    notify(message, { type: "warning" });
                  }
                  if (data) {
                    const {
                      meetingId
                    } = data;
                    if (meetingId) {
                      logger.info(`found meetingId ${meetingId}`);
                      // redirect(`/meetings/${meetingId}`);
                    } else {
                      logger.warn('meetingId is empty');
                    }
                  } else {
                    logger.warn('data is empty');
                  }
                } else {
                  logger.warn('message is empty');
                }
              } else {
                logger.info("payload is empty");
                // NOTE: get result (S3Object) from TranscriptionAdminJob
                dataProvider.getOne(
                  "transcriptionAdminJobs",
                  {
                    id: transcriptionAdminJobId,
                  },
                ).then(
                  (transcriptionAdminJob) => {
                    logger.info("transcriptionAdminJob results", transcriptionAdminJob);
                    if (transcriptionAdminJob) {
                      const {
                        result,
                      } = transcriptionAdminJob as unknown as TranscriptionAdminJob;
                      if (result) {
                        logger.info("result", result);
                        const {
                          key,
                        } = result;
                        logger.info("key", key);
                        if (key) {
                          downloadFileFromS3(key, Storage, "public");
                        }
                      } else {
                        logger.info("no result yet");
                        notify("no download is available", { type: "warning" });
                      }
                    } else {
                      logger.warn("transcriptionAdminJob is empty");
                    }
                  }
                );
              }
            }
            dataProvider.publish(
              "resource/transcriptionAdminJobAuditLogs",
              {
                type: "created",
                topic: "resource/transcriptionAdminJobAuditLogs",
                payload: { ids: [ value.data.onCreateTranscriptionAdminJobAuditLog.id ]},
                date: new Date(),
              },
            );
          },
          error: (error: Error) => {
            logger.warn("onCreateTranscriptionAdminJobAuditLog failed", error);
          }
        });
        return () => {
          subscription.unsubscribe();
        }
      }
    },
    [ currentProfile?.userId ]
  );

  useEffect(
    () => {
      if (currentProfile?.userId) {
        const transcriptionJobIdFilter = urlQuery.get("filter");
        logger.info("transcriptionJobIdFilter", transcriptionJobIdFilter);
        let transcriptionAdminJobId = '';
        if (transcriptionJobIdFilter) {
          try {
            transcriptionAdminJobId = JSON.parse(transcriptionJobIdFilter)['transcriptionAdminJobId'];
          } catch(err) {}
        }
        logger.info("transcriptionAdminJobId from transcriptionJobIdFilter", transcriptionAdminJobId);

        const subscription = API.graphql(
          graphqlOperation(onUpdateTranscriptionAdminJob, { owner: currentProfile?.userId })
        ).subscribe({
          next: ({ value }: any) => {
            logger.info("value.data.onUpdateTranscriptionAdminJob", value.data.onUpdateTranscriptionAdminJob);
            logger.info("value.data.onUpdateTranscriptionAdminJob.id", value.data.onUpdateTranscriptionAdminJob.id);
            const {
              id,
              meetingId,
              result,
              status,
            } = value.data.onUpdateTranscriptionAdminJob;
            logger.info("job id", id);
            logger.info("meetingId", meetingId);
            logger.info("result", result);
            logger.info("status", status);
            if (id === transcriptionAdminJobId) {
              if (result && status === JobStatus.COMPLETED) {
                // notify("Job is complete.", { type: "success" });
                const {
                  key,
                } = result;
                logger.info("key", key);
                if (key) {
                  // NOTE: auto download will not work in some browsers
                  ReactSwal.fire(
                    {
                      title: 'Transcription',
                      text: 'Complete!',
                      icon: 'success',
                      showCancelButton: true,
                      confirmButtonText: 'Yes, download!',
                      confirmButtonColor: "#056839",
                      cancelButtonColor: "#39B54A",
                    }
                  ).then((result) => {
                    if (result.isConfirmed) {
                      downloadFileFromS3(key, Storage, "public");
                    }
                  });
                } else {
                  notify("Transcription is not available.", { type: "warning" });
                }
              } else {
                logger.info("status is not completed", status);
                notify(`Job status is ${status}`, { type: "warning" });
              }
              dataProvider.publish(
                "resource/transcriptionAdminJobs",
                {
                  type: "updated",
                  topic: "resource/transcriptionAdminJobs",
                  payload: { ids: [ value.data.onUpdateTranscriptionAdminJob.id ]},
                  date: new Date(),
                },
              );
            } else {
              logger.info("job id mismatch", { id, transcriptionAdminJobId });
            }
          },
          error: (err: Error) => {
            logger.warn("onUpdateTranscriptionAdminJob failed", err);
            notify(err.message, { type: "warning" });
          }
        });
        return () => {
          subscription.unsubscribe();
        }
      }
    },
    [ currentProfile?.userId ]
  );

  return (
    <RealTimeList
      {...props}
      filters={filters}
      filterDefaultValues={{ profileId: currentProfile?.id }}
      actions={<ListActions />}
      onEventReceived={handleEventReceived}
    >
      <Datagrid>
        <DateField source="date" showTime={true} sortable={true} />
        <TextField source="transcriptionAdminJob.id" label="Job ID" sortable={false} />
        <TextField source="resource" sortable={false} />
        <TextField source="action" sortable={false} />
        <LogPayloadField source="payload" label="Message" sortable={false} />
        <ShowButton />
      </Datagrid>
    </RealTimeList>
  );
};

export const TranscriptionAdminJobAuditLogShow = (props: ShowProps) => {

  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="id" label="ID" />
        <DateField source="date" showTime={true} />
        <TextField source="author" />
        <TextField source="resource" />
        <TextField source="action" />
        <JsonField
          source="payload"
          addLabel={true}
          jsonString={true}
          reactJsonOptions={{
            // shapeshifter:inverted
            theme: "shapeshifter",
            name: null,
            collapsed: true,
            enableClipboard: false,
            displayDataTypes: false,
          }}
        />
        <TextField source="ipAddress" label="IP address" />
        <ReferenceField
          source="transcriptionAdminJobId"
          reference="transcriptionAdminJobs"
          label="Transcription job"
          link="show"
        >
          <TextField source="id" />
        </ReferenceField>
        <ReferenceField
          source="profileId"
          reference="profiles"
          label="User"
          link="show"
        >
          <TextField source="fullName" />
        </ReferenceField>
        <AmplifyFileField
          source="source"
          download
          storageOptions={{ level: "public" }}
        />
        <DateField source="createdAt" showTime={true} />
        <DateField source="updatedAt" showTime={true} />
      </SimpleShowLayout>
    </Show>
  );
}
