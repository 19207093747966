import React, { useCallback, useState } from "react";
import {
  AutocompleteInput,
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  Link,
  NumberField,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  useMutation,
  useNotify,
  useRecordContext,
  useRedirect,
  required,
} from "react-admin";
import type {
  CreateProps,
  EditProps,
  ListProps,
  ShowProps,
} from "react-admin";
import { AmplifyImageField } from "./AmplifyImageField";
import { AmplifyImageInput } from "./AmplifyImageInput";
import { ListProfileImagesByProfileIdQuery, ProfileImage } from "../API";
import { API, graphqlOperation, Logger } from "aws-amplify";
import { listProfileImagesByProfileId } from "../graphql/queries";
import { GraphQLResult } from "@aws-amplify/api";
import { GraphQLErrorResponse } from "../types";
import DeleteWithConfirmToolbar from "./DeleteWithConfirmToolbar";
import { CustomList, ListPagination } from "./CustomList";
import TopToolbar from "./TopToolbar";

const defaultQuery = "listProfileImages";
const logger = new Logger("ProfileImage");
const validateRequired = [required()];

const validateProfileImage = async (data: any) => {
  let errors: any = {};

  const {
    profileId,
    isDefault,
  } = data;

  logger.info("validateProfileImage data", data);

  if (profileId && isDefault) {
    const query = graphqlOperation(
      listProfileImagesByProfileId,
      {
        profileId,
        filter: {
          isDefault: {
            eq: true
          }
        }
      }
    );
    await (API.graphql(query) as Promise<GraphQLResult<ListProfileImagesByProfileIdQuery>>)
      .then(
        async (result: GraphQLResult<ListProfileImagesByProfileIdQuery>) => {
          if (
            result.data &&
            result.data.listProfileImagesByProfileId &&
            result.data.listProfileImagesByProfileId.items.length > 0
          ) {
            const {
              items
            } = result.data.listProfileImagesByProfileId;
            logger.info("result.data.listProfileImagesByProfileId.items", items);
            if (items && items.length > 0) {
              const errorMessage = "A default image already exists.";
              errors = { isDefault: errorMessage };
            }
          }
        }
      )
      .catch((err: GraphQLErrorResponse) => {
        if (err.errors && err.errors.length > 0) {
          const { message } = err.errors[0];
          const errorMessage = message || "GraphQL API error";
          errors = { teamId: errorMessage };
        } else {
          errors = { teamId: err.message };
        }
      }
    );
  }
  logger.info("validateProfileImage errors", errors);
  return errors;
};

export const ProfileImageList = (props: ListProps) => {
  const [query, setQuery] = useState(defaultQuery);

  return (
    <CustomList
      {...props}
      bulkActionButtons={false}
      pagination={<ListPagination />}
    >
      <Datagrid>
        <TextField source="title" sortable={false} />
        <ReferenceField
          source="profileId"
          reference="profiles"
          sortable={false}
        >
          <TextField source="fullName" />
        </ReferenceField>
        <BooleanField source="isDefault" label="Is default?" />
        <DateField source="updatedAt" showTime={true} sortable={false} />
        <ShowButton />
        <EditButton />
      </Datagrid>
    </CustomList>
  );
};

export const ProfileImageShow = (props: ShowProps) => {

  const ShowActions = (props: ShowProps) => {
    const {
      className,
      basePath,
      id
    } = props;
    const record = useRecordContext();
    if (!record) {
      return <></>;
    }
    return (
      <TopToolbar className={className}>
        <ShowButton
          component={Link}
          to={{
            pathname: `/profiles/${record.profileId}/show`,
          }}
          label="Return to profile"
        />
        <EditButton basePath={basePath} label="Edit" record={{ id: id as string }} />
      </TopToolbar>
    );
  };

  return (
    <Show
      {...props}
      actions={<ShowActions {...props} />}
    >
      <SimpleShowLayout>
        <TextField source="id" label="ID" fullWidth />
        <TextField source="title" />
        <TextField source="alt" />
        <NumberField source="width" />
        <NumberField source="height" />
        <AmplifyImageField source="image" title="title" />
        <ReferenceField
          source="profileId"
          reference="profiles"
          label="User"
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>
        <BooleanField source="isDefault" label="Is default?" />
        <DateField source="createdAt" showTime={true} />
        <DateField source="updatedAt" showTime={true} />
      </SimpleShowLayout>
    </Show>
  );
}

export const ProfileImageEdit = (props: EditProps) => {

  const notify = useNotify();
  const redirect = useRedirect();
  const [mutate] = useMutation();

  const onSuccess = (profileImageData: { data: ProfileImage }) => {
    const {
      data,
    } = profileImageData;
    const message = "Image updated";
    notify(message, { type: "success" });
    redirect(`/profiles/${data.profileId}`);
  };
  // See: https://marmelab.com/react-admin/doc/3.19/CreateEdit.html#submission-validation
  const save = useCallback(
    async (data: any) => {
      const errors = await validateProfileImage(data);
      if (Object.keys(errors).length > 0) {
        logger.warn("found validation errors", errors);
        return errors;
      }
      const {
        isDefault,
      } = data;
      data["isDefault"] = isDefault ? true : false;
      await mutate(
        {
          type: "update",
          resource: "profileImages",
          payload: { data },
        },
        {
          returnPromise: true,
          onSuccess
        }
      );
    },
    [mutate],
  );

  const EditActions = (props: EditProps) => {
    const {
      className,
      basePath,
      id
    } = props;
    const record = useRecordContext();
    if (!record) {
      return <></>;
    }
    return (
      <TopToolbar className={className}>
        <ShowButton
          component={Link}
          to={{
            pathname: `/profiles/${record.profileId}/show`,
          }}
          label="Return to profile"
        />
        <ShowButton
          basePath={basePath}
          label="Show"
          record={{ id: id as string }}
        />
      </TopToolbar>
    );
  };

  return (
    <Edit
      {...props}
      actions={<EditActions {...props} />}
      undoable={false}
      mutationMode="pessimistic"
    >
      <SimpleForm
        save={save}
        toolbar={<DeleteWithConfirmToolbar />}
      >
        <TextInput source="id" label="ID" disabled />
        <TextInput
          source="title"
          isRequired
          fullWidth
          validate={validateRequired}
        />
        <TextInput source="alt" fullWidth />
        <NumberInput source="width" />
        <NumberInput source="height" />
        <AmplifyImageInput
          source="image"
          accept="image/*"
          isRequired
          storageOptions={{ level: "public" }}
          validate={validateRequired}
        />
        <ReferenceInput
          source="profileId"
          reference="profiles"
          label="User"
          filterToQuery={(searchText) => ({
            listProfiles: { fullName: searchText },
          })}
          perPage={700}
          alwaysOn
          isRequired
          fullWidth
        >
          <AutocompleteInput optionText="fullName" />
        </ReferenceInput>
        <BooleanInput source="isDefault" label="Is default?" />
      </SimpleForm>
    </Edit>
  );
}

export const ProfileImageCreate = (props: CreateProps) => {

  const notify = useNotify();
  const redirect = useRedirect();
  const [mutate] = useMutation();

  const onSuccess = (profileImageData: { data: ProfileImage }) => {
    const {
      data,
    } = profileImageData;
    const message = "Image added";
    notify(message, { type: "success" });
    redirect(`/profiles/${data.profileId}`);
  };
  // See: https://marmelab.com/react-admin/doc/3.19/CreateEdit.html#submission-validation
  const save = useCallback(
    async (data: any) => {
      const errors = await validateProfileImage(data);
      if (Object.keys(errors).length > 0) {
        logger.warn("found validation errors", errors);
        return errors;
      }
      const {
        isDefault,
      } = data;
      data["isDefault"] = isDefault ? true : false;
      await mutate(
        {
          type: "create",
          resource: "profileImages",
          payload: { data },
        },
        {
          returnPromise: true,
          onSuccess
        }
      );
    },
    [mutate],
  );

  const CreateActions = (props: CreateProps) => {
    const {
      className,
    } = props;
    const record = useRecordContext();
    if (!record) {
      return <></>;
    }
    return (
      <TopToolbar className={className}>
        <ShowButton
          component={Link}
          to={{
            pathname: `/profiles/${record.profileId}/show`,
          }}
          label="Return to profile"
        />
      </TopToolbar>
    );
  };

  return (
    <Create
      {...props}
      actions={<CreateActions {...props} />}
    >
      <SimpleForm
        save={save}
      >
        <TextInput
          source="title"
          isRequired
          fullWidth
          validate={validateRequired}
        />
        <TextInput source="alt" fullWidth />
        <NumberInput source="width" />
        <NumberInput source="height" />
        <AmplifyImageInput
          source="image"
          accept="image/*"
          isRequired
          storageOptions={{ level: "public" }}
          validate={validateRequired}
        />
        <ReferenceInput
          source="profileId"
          reference="profiles"
          label="User"
          filterToQuery={(searchText) => ({
            listProfiles: { fullName: searchText },
          })}
          perPage={700}
          alwaysOn
          isRequired
          fullWidth
          validate={validateRequired}
        >
          <AutocompleteInput optionText="fullName" />
        </ReferenceInput>
        <BooleanInput source="isDefault" label="Is default?" />
      </SimpleForm>
    </Create>
  );
}
