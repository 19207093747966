import React, { useState } from "react";
import {
  AutocompleteInput,
  Create,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  NumberField,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  required,
} from "react-admin";
import type {
  CreateProps,
  EditProps,
  ListProps,
  ShowProps,
} from "react-admin";
import { AmplifyImageField } from "./AmplifyImageField";
import { AmplifyImageInput } from "./AmplifyImageInput";
import type { ProductImage } from "../API";
import DeleteWithConfirmToolbar from "./DeleteWithConfirmToolbar";
import { CustomList, ListPagination } from "./CustomList";

const defaultQuery = "listProductImages";
const validateRequired = [required()];

const redirectAfterSave = (basePath: string, id: string, data: ProductImage) => `/products/${data.productId}/show`;

export const ProductImageList = (props: ListProps) => {
  const [query, setQuery] = useState(defaultQuery);

  return (
    <CustomList
      {...props}
      bulkActionButtons={false}
      pagination={<ListPagination />}
    >
      <Datagrid>
        <TextField source="title" sortable={false} fullWidth />
        <ReferenceField
          source="productId"
          reference="products"
          sortable={false}
          fullWidth
        >
          <TextField source="name" fullWidth />
        </ReferenceField>
        <DateField source="updatedAt" showTime={true} />
        <ShowButton />
        <EditButton />
      </Datagrid>
    </CustomList>
  );
};

export const ProductImageShow = (props: ShowProps) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" label="ID" fullWidth />
      <TextField source="title" fullWidth />
      <TextField source="alt" fullWidth />
      <NumberField source="width" />
      <NumberField source="height" />
      <AmplifyImageField source="image" title="title" />
      <ReferenceField
        source="productId"
        reference="products"
        label="Product"
        link="show"
        fullWidth
      >
        <TextField source="name" fullWidth />
      </ReferenceField>
      <DateField source="createdAt" showTime={true} />
      <DateField source="updatedAt" showTime={true} />
    </SimpleShowLayout>
  </Show>
);

export const ProductImageEdit = (props: EditProps) => (
  <Edit
    {...props}
    undoable={false}
    mutationMode="pessimistic"
  >
    <SimpleForm
      redirect={redirectAfterSave}
      toolbar={<DeleteWithConfirmToolbar />}
    >
      <TextInput source="id" label="ID" disabled />
      <TextInput
        source="title"
        isRequired
        fullWidth
        validate={validateRequired}
      />
      <TextInput source="alt" fullWidth />
      <NumberInput source="width" />
      <NumberInput source="height" />
      <AmplifyImageInput
        source="image"
        accept="image/*"
        storageOptions={{ level: "public" }}
        isRequired
        validate={validateRequired}
      />
      <ReferenceInput
        source="productId"
        reference="products"
        label="Product"
        perPage={2000}
        alwaysOn
        isRequired
        validate={validateRequired}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);

export const ProductImageCreate = (props: CreateProps) => (
  <Create {...props}>
    <SimpleForm
      redirect={redirectAfterSave}
    >
      <TextInput source="title"
        isRequired
        validate={validateRequired}
        fullWidth
      />
      <TextInput source="alt" fullWidth />
      <NumberInput source="width" />
      <NumberInput source="height" />
      <AmplifyImageInput
        source="image"
        accept="image/*"
        storageOptions={{ level: "public" }}
        isRequired
        validate={validateRequired}
      />
      <ReferenceInput
        source="productId"
        reference="products"
        label="Product"
        perPage={2000}
        alwaysOn
        isRequired
        validate={validateRequired}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);
