import React, { HtmlHTMLAttributes } from 'react';
import {
  Tooltip,
  IconButton,
  Badge,
} from '@material-ui/core';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import WatchLaterOutlinedIcon from '@material-ui/icons/WatchLaterOutlined';
import { useTranslate } from 'react-admin';
import { useCookies } from 'react-cookie';
import { Logger } from 'aws-amplify';

const logger = new Logger("ToggleClockSyncButton");

interface ToggleClockSyncButtonProps extends HtmlHTMLAttributes<HTMLDivElement> {
  setRequestServerTimeDifference: React.Dispatch<React.SetStateAction<number>>;
  clockSyncEnabled: boolean;
  setClockSyncEnabled: (value: boolean) => void;
  cachedServerTimeDifference: number;
};

export const ToggleClockSyncButton = (props: ToggleClockSyncButtonProps) => {
  const translate = useTranslate();
  const [ cookies, setCookie ] = useCookies(["autoClockSyncDisabled"]);
  const {
    setRequestServerTimeDifference,
    clockSyncEnabled,
    setClockSyncEnabled,
    cachedServerTimeDifference,
  } = props;
  const handleToggleClockSyncPreferenceSelection = (): void => {
    const updatedClockSyncEnabled = clockSyncEnabled === false ? true : false;
    logger.info("updatedClockSyncEnabled", updatedClockSyncEnabled);
    setClockSyncEnabled(updatedClockSyncEnabled);
    if (!updatedClockSyncEnabled && !cookies.autoClockSyncDisabled) {
      logger.info("setting autoClockSyncDisabled");
      setCookie("autoClockSyncDisabled", "true");
    }
    setRequestServerTimeDifference(new Date().getTime());
    /* const cachedServerTimeDifferenceInSeconds = Math.round((cachedServerTimeDifference / 1000));
    const message = updatedClockSyncEnabled ? `clock offset is ${cachedServerTimeDifferenceInSeconds} seconds` : "clock offset is disabled";
    notify(
      message,
      {
        type: 'info',
      }
    ); */
  };
  const toggleClockSyncTitle = translate('ra-preferences.action.toggle_clock_sync', {
    _: clockSyncEnabled ? "clock offset is enabled" : "clock offset is disabled",
    // _: clockSyncEnabled ? `clock offset is ${Math.round((cachedServerTimeDifference / 1000))} seconds` : "clock offset is disabled",
  });
  return (
    <Tooltip title={toggleClockSyncTitle} enterDelay={300}>
      <Badge
        overlap="circular"
        badgeContent={Math.round((cachedServerTimeDifference / 1000))}
        max={9999}
        color="secondary"
      >
        <IconButton
          color="inherit"
          onClick={handleToggleClockSyncPreferenceSelection}
          aria-label={toggleClockSyncTitle}
        >
          { clockSyncEnabled === false ? <WatchLaterOutlinedIcon /> : <WatchLaterIcon /> }
        </IconButton>
      </Badge>
    </Tooltip>
  );
};
