import React, { useState } from "react";
import {
  Create,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  ReferenceField,
  ReferenceManyField,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  required,
} from "react-admin";
import type {
  CreateProps,
  EditProps,
  ListProps,
  ShowProps,
} from "react-admin";
import DeleteWithConfirmToolbar from "./DeleteWithConfirmToolbar";
import { CustomList, ListPagination } from "./CustomList";
import NoDeleteToolbar from "./NoDeleteToolbar";

const defaultQuery = "listProductQuestions";
const validateRequired = [required()];

const filters = [
  <TextInput label="Question" source="question" alwaysOn resettable />,
  <ReferenceInput
    source="productId"
    reference="products"
    label="Product"
    filterToQuery={(searchText) => ({
      listProducts: { name: searchText },
    })}
    perPage={2000}
    alwaysOn
    resettable
  >
    <AutocompleteInput optionText="name" />
  </ReferenceInput>
];

export const ProductQuestionList = (props: ListProps) => {
  const [query, setQuery] = useState(defaultQuery);

  return (
    <CustomList
      {...props}
      filters={filters}
      bulkActionButtons={false}
      pagination={<ListPagination />}
    >
      <Datagrid>
        <TextField source="question" sortable={false} />
        <TextField source="product.name" sortable={false} label="Product" />
        <DateField source="updatedAt" showTime={false} sortable={false} />
        <ShowButton />
        <EditButton />
      </Datagrid>
    </CustomList>
  );
};

export const ProductQuestionShow = (props: ShowProps) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" label="ID" fullWidth />
      <TextField source="question" fullWidth />
      <ReferenceField
        label="Product"
        source="productId"
        reference="products"
        fullWidth
      >
        <TextField source="name" fullWidth />
      </ReferenceField>
      <ReferenceManyField
        reference="questionResponses"
        target="questionId"
        label="Responses"
        perPage={10}
        fullWidth
        filter={{ listQuestionResponsesByQuestionId: {} }}
      >
        <Datagrid>
          <TextField source="meeting.title" sortable={false} label="Meeting" />
          <TextField
            source="profile.fullName"
            sortable={false}
            label="User name"
          />
          <TextField source="response" sortable={false} />
          <DateField source="updatedAt" showTime={true} sortable={false} />
          <ShowButton />
          <EditButton />
        </Datagrid>
      </ReferenceManyField>
      <DateField showTime={true} source="createdAt" />
      <DateField showTime={true} source="updatedAt" />
    </SimpleShowLayout>
  </Show>
);

/* const EditActions = (props: EditProps) => {
  const {
    className,
    basePath,
    id
  } = props;
  return (
    <TopToolbar className={className}>
      <ListButton basePath={basePath} />
      <ShowButton
        basePath={basePath}
        label="Show"
        record={{ id: id as string }}
      />
    </TopToolbar>
  );
};


const EditToolbar = (props: ToolbarProps) => {
  const record = useRecordContext();
  return (
    <Toolbar {...props}>
      <SaveButton/>
      <DeleteWithConfirmButton
        confirmContent="You will not be able to recover this record. Are you sure?"
        // translateOptions={{ name: record.name }}
      />
    </Toolbar>
  );
}; */

export const ProductQuestionEdit = (props: EditProps) => (
  <Edit
    {...props}
    undoable={false}
    mutationMode="pessimistic"
  >
    <SimpleForm
      toolbar={<NoDeleteToolbar />}
    >
      <TextInput source="id" label="ID" disabled fullWidth />
      <ReferenceInput
        source="productId"
        reference="products"
        label="Product"
        perPage={2000}
        alwaysOn
        isRequired
        validate={validateRequired}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <TextInput
        source="question"
        isRequired
        fullWidth
        validate={validateRequired}
      />
    </SimpleForm>
  </Edit>
);

export const ProductQuestionCreate = (props: CreateProps) => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput
        source="productId"
        reference="products"
        label="Product"
        perPage={2000}
        alwaysOn
        isRequired
        validate={validateRequired}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <TextInput
        source="question"
        isRequired
        fullWidth
        validate={validateRequired}
      />
    </SimpleForm>
  </Create>
);
